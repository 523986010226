import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./uricAcidAdvice.scss";
import TestCode from "../../../enums/testCode.enum";
import QuestionTemplateCode from "../../../enums/questionTemplateCode.enum";
import Medication from "../../../enums/medication.enum";
import { TestChart } from "../testChart/testChart.component";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import ReportSection from "../../../enums/reportSection.enum";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const UricAcidAdvice = ({ report, setSectionMeta, section, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [riskLevel, setRiskLevel] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [isTakingUricAcidMedication, setIsTakingUricAcidMedication] = useState();


  const isFixed = ReportHelper.isFixed(report, ReportSection.URIC_ACID_ADVICE);
  const isBetter = ReportHelper.isBetter(report,ReportSection.URIC_ACID_ADVICE);
  const isWorse = ReportHelper.isWorse(report, ReportSection.URIC_ACID_ADVICE);
  const isStatic = ReportHelper.isStatic(report, ReportSection.URIC_ACID_ADVICE);

  useEffect(() => {
    setRiskLevel(getRiskLevel());
    setIsTakingUricAcidMedication(getIsTakingUricAcidMedication());

  }, [tests, results, report]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [riskLevel, report, isFixed, isBetter, isWorse, isStatic]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  const getIsTakingUricAcidMedication = () => {
    if (!report) return;
    const medications = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_MEDICATIONS);
    return medications?.includes(Medication.ALLOPURINOL)
  }

  const getRiskLevel = () => {
    if (!tests || !results || !report) return null;
    return ReportHelper.getTestRisk(TestCode.URIC_ACID, report, results, tests);
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report) return null;

    return <>
      Uric acid is a waste product produced when the body breaks down
      purines, which are found in certain foods and are also produced
      naturally. Sugar, especially fructose, can also stimulate the
      production of uric acid.

      {(isFixed) && <> Congrats on successfully bringing your uric acid levels to the optimal range.</>}
      {(isBetter) && <> Nice work on bringing your uric acid levels closer to optimal range!</>}
      {(isStatic) && <> Elevated levels increase your risk for gout, kidney stones, diabetes and heart disease.</>}
    </>
    
}
  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !riskLevel) return null;

    if (isStatic) {
      return (
        <>
          Your <span>uric acid</span> levels remain relatively <span>unchanged</span>{isTakingUricAcidMedication && <>, despite taking medication</>}.
        </>
      );
    }

    else if (isBetter) {
      return (
        <>
          Your <span>uric acid</span> is <span>improving</span>, which decreases your risk for gout, kidney stones, diabetes and heart disease.
        </>
      );
    }
    else if (isWorse) {
      return (
        <>
          Your <span>uric acid</span> is <span>increasing</span>, which increases your risk for gout, kidney stones, diabetes and heart disease.
        </>
      );
    }

    else if (isFixed) {
      return (
        <>
          Your <span>uric acid</span> is now <span>optimal</span>, which decreases your risk for gout, kidney stones, diabetes and heart disease.
        </>
      );
    }


    return (
      <>
        Your <span>uric acid</span> is{" "}
        <span>{ReportHelper.getRiskEval(TestCode.URIC_ACID, report, results, tests)}</span>, which increases your risk
        for gout, kidney stones, diabetes and heart disease.
      </>
    );
  };  

  return (
    <div className="vitamin-d-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>

      <Paragraph className="report-template-description">
        {description}
      </Paragraph>

      <TestChart 
        code={TestCode.URIC_ACID} 
        report={report} 
        tests={tests}
        results={results}
        reports={reports}
      />

      <AdviceSection
        report={report}
        results={results}
        tests={tests}
        section={section}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />
    </div>
  );
};
