import { Modal, Typography } from 'antd'
import ReactPlayer from "react-player";
import './tassoModal.scss'
import { useRef, useCallback } from 'react';

const { Paragraph } = Typography

export const TassoModal = ({ open, setOpen }) => {
  const playerRef = useRef()

  const onCancel = () => {
    setOpen(false)
  }

  const onReady = useCallback(() => {
    if (open) {
      playerRef.current.seekTo(6, "seconds");
    }
  }, [open]);

  return open && (
    <Modal
      open={open}
      onCancel={onCancel}
      className="tasso-modal"
      footer={null}
      width={500}
    >
      <Paragraph className="tasso-title">
        Tasso+: A Painless Blood Test
      </Paragraph>
      <Paragraph className="tasso-description">
      This device is a more comfortable and convenient alternative to traditional finger pricks. It uses microneedles and gentle suction to draw blood from the skin’s surface, avoiding the pain and bruising associated with finger pricks.
      </Paragraph>

      <div className="tasso-video">
        <ReactPlayer
          ref={playerRef}
          url={'https://player.vimeo.com/video/409226805#t=1m'}
          controls={true}
          width="100%"
          onReady={onReady}
          playing={false}
          playbackRate={1.33}
        />
      </div>
    </Modal>
  )
}