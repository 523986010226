import React from 'react';
import { Popover, List, Button, Space } from 'antd';
import { EditOutlined, DeleteOutlined, UnorderedListOutlined } from '@ant-design/icons';
import moment from 'moment';
import './annotationList.scss';

export const AnnotationList = ({ 
  annotations = [], 
  onAnnotationUpdate,
  onAnnotationDelete,
  buttonStyle = {},
  test
}) => {
  const sortedAnnotations = annotations.filter(({ tests  }) => {
    return tests.includes(test?._id)
  }).sort((a, b) => 
    new Date(b.occurredAt) - new Date(a.occurredAt)
  );

  const content = (
    <List
      className="annotation-list"
      itemLayout="horizontal"
      dataSource={sortedAnnotations}
      renderItem={(annotation) => (
        <List.Item
          actions={[
            <Button 
              type="text" 
              icon={<EditOutlined />}
              onClick={() => onAnnotationUpdate(annotation)}
            />,
            <Button 
              type="text" 
              danger 
              icon={<DeleteOutlined />}
              onClick={() => onAnnotationDelete(annotation)}
            />
          ]}
        >
          <List.Item.Meta
            title={moment(annotation.occurredAt).format('MMM D, YYYY')}
            description={annotation.content}
          />
        </List.Item>
      )}
    />
  );

  return (
    <Popover 
      content={content} 
      trigger="click"
      placement="bottomRight"
      overlayClassName="annotation-list-popover"
    >
      <Button
        icon={<UnorderedListOutlined />}
        style={buttonStyle}
        className="annotation-list-button"
      >
        Annotations
      </Button>
    </Popover>
  );
}; 