import PhotoHelper from "../../helpers/photo.helper"
import { Typography, Row, Col } from "antd"
import moment from 'moment'
import { useNavigate } from "react-router-dom"
import './pgFooter.scss';

const { Paragraph } = Typography

export const PgFooter = () => {
  const navigate = useNavigate()

  const onChat = () => {
    window.FrontChat("show");
  };

  return (
    <div className="pg-footer">
      {PhotoHelper.format({
        jpg:"/img/green.jpg",
        webp:"/img/green.webp",
        className:"pg-bg",
      })}

      <div className="max-width">
        <Paragraph className="pg-quote">
          To people 50 or 100 years in the future, it will seem barbaric that people in our era waited till they had symptoms to be diagnosed with conditions like heart disease. People in the future will feel as sorry for us as we do for the generations that lived before anesthesia and antibiotics.
        </Paragraph>

        <Row className="pg-row">
          <Col>
            {PhotoHelper.format({
              png:"/img/pg.png",
              webp:"/img/pg.webp",
              className:"pg-img",
            })}
          </Col>

          <Col>
            <Paragraph className="pg-name">
              Paul Graham
            </Paragraph>
            <Paragraph className="pg-blog">
              Founder @ Y Combinator
            </Paragraph>
          </Col>
        </Row>
      </div>

      <div className="footer">
        <span>
          © {moment().format('YYYY')} Instalab
        </span>
        <span>
          <a onClick={() => navigate('/research')}>Research</a>
        </span>

        <span>
          <a onClick={onChat}>Contact</a>
        </span>
        <span>
          <a onClick={() => navigate(`/privacy`)}>Privacy</a>
        </span>
        <span>
          <a onClick={() => navigate('/terms')}>Terms</a>
        </span>
      </div>
    </div>
  )
}