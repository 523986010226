import { Form, Button, Row, Col, Radio, Tabs, Image, Spin } from "antd"
import "./giftSelect.scss"
import { WarningFilled, } from '@ant-design/icons'
import { FlowStepFooter } from "../../flowStep/flowStepFooter.component"
import { useState, useEffect, useRef, useContext } from "react"
import FormHelper from "../../helpers/form.helper"
import RuleHelper from "../../../../helpers/rule.helper"
import { useNavigate, useParams } from "react-router-dom"
import dayjs from 'dayjs'
import { listProductTypes } from "../../../../services/productType.service"
import ProductTypeCode from "../../../../enums/productTypeCode.enum"
import "../singleSelect/singleSelect.scss" // Import the single select styles
import { UserContext } from "../../../../contexts/user.context"

const { Item } = Form

export const GiftSelect = ({
  step,
  onSubmit,
  skeleton,
  form,
  isSubmitting,
  hasAttempt,
  flow,
}) => {
  const navigate = useNavigate()
  const [rules, setRules] = useState({})
  const { flowType } = useParams()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt
  const [productTypes, setProductTypes] = useState()
  const { currentUser } = useContext(UserContext)
  const [activeTab, setActiveTab] = useState('tests')
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    fetchRules()
  }, [step])

  useEffect(() => {
    fetchProductTypes()

    const keyDownHandler = event => {
      if (event.target.classList.contains('__PrivateStripeElement-input')) {
        return;
      }
      
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
      form.resetFields();
    };
  }, []);

  useEffect(() => {
    if (flow?.giftProductTypeCodes) {
      form.setFieldValue('giftProductTypeCodes', flow?.giftProductTypeCodes[0]);
    }
  }, [flow])

  useEffect(() => {
    if (!productTypes) return;
    
    setImagesLoaded(false);
    
    const imageUrls = productTypes
      .filter(pt => pt?.photos?.length > 0)
      .map(pt => pt.photos[0].webp);

    if (imageUrls.length === 0) {
      setImagesLoaded(true);
      return;
    }

    const imagePromises = imageUrls.map(url => {
      return new Promise((resolve) => {
        const img = new Image();
        
        // Set a timeout to resolve after 5 seconds if image hasn't loaded
        const timeoutId = setTimeout(() => {
          resolve();
        }, 5000);

        img.onload = () => {
          clearTimeout(timeoutId);
          resolve();
        };

        img.onerror = () => {
          clearTimeout(timeoutId);
          resolve();
        };

        img.src = url;
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        setImagesLoaded(true);
      })
      .catch(() => {
        setImagesLoaded(true);
      });

    // Cleanup timeout if component unmounts
    return () => setImagesLoaded(false);
  }, [productTypes]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const selectedGift = getProductType(values.giftProductTypeCodes);
      if (selectedGift) {
        onSubmit(values);
      }
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.GIFT_GALLERI,
            ProductTypeCode.GIFT_LONGEVITY_PANEL,
            ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW,
            ProductTypeCode.GIFT_TESTOSTERONE_PANEL,
            ProductTypeCode.GIFT_FERTILITY_PANEL, 
            ProductTypeCode.GIFT_HEART_HEALTH_PANEL,
            ProductTypeCode.GIFT_CORE_MEMBERSHIP,
            ProductTypeCode.GIFT_PREMIUM_MEMBERSHIP,
            ProductTypeCode.GIFT_SLEEP_APNEA_TEST,  
            ProductTypeCode.GIFT_CARD_50,
            ProductTypeCode.GIFT_CARD_100,
            ProductTypeCode.GIFT_CARD_250,
            ProductTypeCode.GIFT_CARD_500,
            ProductTypeCode.GIFT_CARD_1000,
            ProductTypeCode.GIFT_TRUAGE_COMPLETE,
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType?.code === code)
  }

  const fetchRules = () => {
    let r = {}

    defaultFields.forEach(group => {
      group.fields.forEach(field => {
        let fieldRules = []

        if (field.required) {
            fieldRules.push({ ...RuleHelper.isRequired, message: <><WarningFilled />&nbsp; Please select an option</> })
        }

        r[field.name] = fieldRules
      })
    })
    setRules(r)
  }


  const defaultFields = [
    {
      group: 'Choose a gift',
      fields: [
        {
          name: 'giftProductTypeCodes',
          required: true,
          options: [
            {
              title: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.title,
              value: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.code,
              price: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.tagline
            },
            {
              title: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.title,
              value: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.code,
              price: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.tagline
            },
            {
              title: getProductType(ProductTypeCode.GIFT_GALLERI)?.title,
              value: getProductType(ProductTypeCode.GIFT_GALLERI)?.code,
              price: getProductType(ProductTypeCode.GIFT_GALLERI)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_GALLERI)?.tagline
            },
            {
              title: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.title,
              value: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.code,
              price: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.tagline
            },
            {
              title: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.title,
              value: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.code,
              price: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.tagline
            },
            {
              title: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.title,
              value: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.code,
              price: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.tagline
            },
            {
              title: getProductType(ProductTypeCode.GIFT_SLEEP_APNEA_TEST)?.title,
              value: getProductType(ProductTypeCode.GIFT_SLEEP_APNEA_TEST)?.code,
              price: getProductType(ProductTypeCode.GIFT_SLEEP_APNEA_TEST)?.cost,
              tagline: getProductType(ProductTypeCode.GIFT_SLEEP_APNEA_TEST)?.tagline
            }
          ]
        }
      ]
    }
  ]

  const renderField = (field) => {
    return (
      <Radio.Group
        value={form.getFieldValue('giftProductTypeCodes')}
        onChange={(e) => {
          form.setFieldValue('giftProductTypeCodes', e.target.value);
          if (hasAttemptRef.current) {
            FormHelper.fetchHasError(form)
          }
          handleSubmit();           
        }}
      >
        <Row gutter={[18, 18]}>
          {field.options.map(({ value, title, tagline, price }) => (
            <Col sm={24} md={12} key={value} className="product-grid-item">
              <Radio 
                value={value} 
                className={Array.isArray(form.getFieldValue('giftProductTypeCodes')) 
                  ? form.getFieldValue('giftProductTypeCodes')?.includes(value) ? 'selected' : ''
                  : form.getFieldValue('giftProductTypeCodes') === value ? 'selected' : ''
                }
                checked={Array.isArray(form.getFieldValue('giftProductTypeCodes')) 
                  ? form.getFieldValue('giftProductTypeCodes')?.includes(value)
                  : form.getFieldValue('giftProductTypeCodes') === value
                }
              >
                <div className="product-select-label">
                  {getProductType(value)?.photos.length > 0 && (
                    <div className="option-image">
                      <Image
                        src={getProductType(value)?.photos[0]?.webp}
                        alt={title}
                        preview={false}
                        placeholder={
                          <div style={{ 
                            backgroundColor: '#f5f5f5',
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            <Spin />
                          </div>
                        }
                      />
                    </div>
                  )}
                  <div className="option-title-container">
                    <div className="option-title">{title}</div>
                    <div className="option-description">{tagline}</div>
                  </div>
                  <div className="price-button-container">
                    <div className="option-price">${price}</div>
                    <Button 
                      type="default" 
                      className="select-gift-button"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop button click from interfering
                        form.setFieldValue('giftProductTypeCodes', value); // Manually set the radio value
                        if (hasAttemptRef.current) {
                          FormHelper.fetchHasError(form);
                        }
                        handleSubmit(); // Automatically submit after selection
                      }}
                    >
                      Select Gift
                    </Button>
                  </div>
                </div>
              </Radio>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    )
  }

  const giftCategories = {
    tests: [
      {
        title: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.title,
        value: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.code,
        price: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.cost,
        tagline: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.tagline
      },
      {
        title: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.title,
        value: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.code,
        price: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.cost,
        tagline: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.tagline
      },  
      {
        title: getProductType(ProductTypeCode.GIFT_GALLERI)?.title,
        value: getProductType(ProductTypeCode.GIFT_GALLERI)?.code,
        price: getProductType(ProductTypeCode.GIFT_GALLERI)?.cost,
        tagline: getProductType(ProductTypeCode.GIFT_GALLERI)?.tagline
      },
      {
        title: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.title,
        value: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.code,
        price: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.cost,
        tagline: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.tagline
      },
      {
        title: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.title,
        value: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.code,
        price: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.cost,
        tagline: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.tagline
      },
      {
        title: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.title,
        value: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.code,
        price: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.cost,
        tagline: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.tagline
      },
      {
        title: getProductType(ProductTypeCode.GIFT_TRUAGE_COMPLETE)?.title,
        value: getProductType(ProductTypeCode.GIFT_TRUAGE_COMPLETE)?.code,
        price: getProductType(ProductTypeCode.GIFT_TRUAGE_COMPLETE)?.cost,
        tagline: getProductType(ProductTypeCode.GIFT_TRUAGE_COMPLETE)?.tagline
      },
      {
        title: getProductType(ProductTypeCode.GIFT_SLEEP_APNEA_TEST)?.title,
        value: getProductType(ProductTypeCode.GIFT_SLEEP_APNEA_TEST)?.code,
        price: getProductType(ProductTypeCode.GIFT_SLEEP_APNEA_TEST)?.cost,
        tagline: getProductType(ProductTypeCode.GIFT_SLEEP_APNEA_TEST)?.tagline
      }
    ],
    giftCards: [
      {
        title: getProductType(ProductTypeCode.GIFT_CARD_50)?.title,
        value: getProductType(ProductTypeCode.GIFT_CARD_50)?.code,
        tagline: getProductType(ProductTypeCode.GIFT_CARD_50)?.tagline,
        price: getProductType(ProductTypeCode.GIFT_CARD_50)?.cost
      },
      {
        title: getProductType(ProductTypeCode.GIFT_CARD_100)?.title,
        value: getProductType(ProductTypeCode.GIFT_CARD_100)?.code,
        tagline: getProductType(ProductTypeCode.GIFT_CARD_100)?.tagline,
        price: getProductType(ProductTypeCode.GIFT_CARD_100)?.cost
      },
      {
        title: getProductType(ProductTypeCode.GIFT_CARD_250)?.title,
        value: getProductType(ProductTypeCode.GIFT_CARD_250)?.code,
        tagline: getProductType(ProductTypeCode.GIFT_CARD_250)?.tagline,
        price: getProductType(ProductTypeCode.GIFT_CARD_250)?.cost
      },
      {
        title: getProductType(ProductTypeCode.GIFT_CARD_500)?.title,
        value: getProductType(ProductTypeCode.GIFT_CARD_500)?.code,
        tagline: getProductType(ProductTypeCode.GIFT_CARD_500)?.tagline,
        price: getProductType(ProductTypeCode.GIFT_CARD_500)?.cost
      },
      {
        title: getProductType(ProductTypeCode.GIFT_CARD_1000)?.title,
        value: getProductType(ProductTypeCode.GIFT_CARD_1000)?.code,
        tagline: getProductType(ProductTypeCode.GIFT_CARD_1000)?.tagline,
        price: getProductType(ProductTypeCode.GIFT_CARD_1000)?.cost
      }
    ],
    memberships: [
      {
        title: getProductType(ProductTypeCode.GIFT_CORE_MEMBERSHIP)?.title,
        value: getProductType(ProductTypeCode.GIFT_CORE_MEMBERSHIP)?.code,
        price: getProductType(ProductTypeCode.GIFT_CORE_MEMBERSHIP)?.cost,
        tagline: getProductType(ProductTypeCode.GIFT_CORE_MEMBERSHIP)?.tagline
      },
      {
        title: getProductType(ProductTypeCode.GIFT_PREMIUM_MEMBERSHIP)?.title,
        value: getProductType(ProductTypeCode.GIFT_PREMIUM_MEMBERSHIP)?.code,
        price: getProductType(ProductTypeCode.GIFT_PREMIUM_MEMBERSHIP)?.cost,
        tagline: getProductType(ProductTypeCode.GIFT_PREMIUM_MEMBERSHIP)?.tagline
      }
    ],

  }

  return (
    <div className="gift">
      {!imagesLoaded ? (
        <div className="gift-loading">
          <Spin size="large" />
          <div style={{ marginTop: 16 }}>Loading gift options...</div>
        </div>
      ) : (
        <Form
          form={form}
          className="multiple-input-form"
          layout="vertical"
          initialValues={{
            giftActivationDate: dayjs(),
            ...(currentUser?.firstName && currentUser?.lastName && {
              giftSenderName: `${currentUser.firstName} ${currentUser.lastName}`,
              ...(currentUser?.email && {
                giftSenderEmail: currentUser.email
              })
            })
          }}
        >
          <Tabs
            activeKey={activeTab}
            onChange={setActiveTab}
            items={[
              {
                key: 'tests',
                label: 'Tests',
                children: (
                  <div className="field-container selector-container">
                    <Item
                      name="giftProductTypeCodes"
                      className="single-select-item"
                      rules={rules['giftProductTypeCodes']}
                      validateTrigger='onSubmit'
                    >
                      {renderField({ options: giftCategories.tests })}
                    </Item>
                  </div>
                ),
              },
              {
                key: 'giftCards',
                label: 'Gift Cards',
                children: (
                  <div className="field-container selector-container">
                    {renderField({ options: giftCategories.giftCards })}
                  </div>
                ),
              },
              {
                key: 'memberships',
                label: 'Memberships',
                children: (
                  <div className="field-container selector-container">
                    <Item
                      name="giftProductTypeCodes"
                      className="single-select-item"
                      rules={rules['giftProductTypeCodes']}
                      validateTrigger='onSubmit'
                    >
                      {renderField({ options: giftCategories.memberships })}
                    </Item>
                  </div>
                ),
              }
            ]}
          />

          <FlowStepFooter 
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            buttonText="Continue"
          />
        </Form>
      )}
    </div>
  )
}