import React, { useEffect, useState, useRef, useContext } from 'react';
import { startCase, toLower } from 'lodash';
import ShippingHelper from '../../helpers/shipping.helper.js';
import { Button, Tooltip, Typography, Modal, message } from 'antd'
import moment from 'moment'
import UrlHelper from '../../helpers/url.helper';
import { PageHeader } from '../pageHeader/pageHeader.component';
import "./adminPrescriptionsTable.scss"
import { PrescriptionModal } from '../prescriptionModal/prescriptionModal.component';
import { listPrescriptions, clonePrescription, removePrescription, updatePrescriptionStatus } from '../../services/prescription.service';
import { addGogoOrder } from '../../services/gogo.service';
import { TrackingHelper } from "../../helpers/tracking.helper.js"
import classNames from 'classnames';
import { PrescriptionForm } from '../prescriptionForm/prescriptionForm.component';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyOutlined, SendOutlined, EyeOutlined, LockOutlined, UnlockOutlined, DeleteOutlined } from '@ant-design/icons'
import Role from '../../enums/role.enum.js';
import PrescriptionStatus from '../../enums/prescriptionStatus.enum.js';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { UserContext } from '../../contexts/user.context.js';
import StatusUpdateModal from './statusUpdateModal.component.js'; 

const { Text } = Typography

export const AdminPrescriptionsTable = ({ hasPatient=false }) => {
  const navigate = useNavigate()
  const { patientId } = useParams()
  const { currentUser, setCounts } = useContext(UserContext)
  const [prescriptions, setPrescriptions] = useState([])
  const prescriptionsRef = useRef()
  prescriptionsRef.current = prescriptions
  const [prescription, setPrescription] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [cloning, setCloning] = useState()
  const [changingStatus, setChangingStatus] = useState()
  const [openAddPrescription, setOpenAddPrescription] = useState()
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const [prescriptionTypes, setPrescriptionTypes] = useState([])

  const [openStatusUpdateModal, setOpenStatusUpdateModal] = useState(false);
  const [selectedPrescriptionId, setSelectedPrescriptionId] = useState(null);


  useEffect(() => {
    fetchPrescriptions()
  }, [hasPatient, patientId])

  const fetchPrescriptions = async () => {
    setIsLoading(true)
    if (hasPatient && !patientId) return

    let params = {
      select: '_id status type createdAt contents tracking hasRefill daysToRefill isPickupOrder',
      filter: {},
      populate: [],
      sort: '-createdAt'
    }

    if (hasPatient) {
      params.filter = {
        patient: patientId
      }
    } else {
      params.select = `${params.select} patient`
      params.populate = [
        ...params.populate,
        {
          path: 'patient',
          select: '_id firstName lastName'
        }
      ]
    }

    const fetchedPrescriptions = await listPrescriptions(params)
    setPrescriptionTypes([...new Set(fetchedPrescriptions.map(({ type }) => type))].sort())
    setPrescriptions(fetchedPrescriptions)
    setFilteredCount(fetchedPrescriptions.length)
    setIsLoading(false)
  }

  const incrementCount = (amount) => {
    setCounts(cachedCounts => {
      return {
        ...cachedCounts,
        prescriptions: cachedCounts.prescriptions + amount
      }
    })
    setFilteredCount(cachedFilterCount => cachedFilterCount+amount)
  }

  const onAddPrescription = (newPrescription) => {
    setPrescriptions([
      newPrescription,
      ...prescriptions,
    ])
    incrementCount(1)
  }

  const getCustomFilter = (prescription, value) => {
    return {
      patient: () => prescription.patient && `${prescription.patient.firstName?.toLowerCase()} ${prescription.patient.lastName?.toLowerCase()}`.includes(value.toLowerCase())
    }
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this prescription?',
      content: 'This action cannot be undone and will permanently remove the prescription from your system.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removePrescription(_id);
          setPrescriptions(prescriptions.filter(prescription => prescription._id !== _id));
          incrementCount(-1)
          message.info('Prescription removed');
        } catch (err) {
          message.error('Failed to remove prescription');
        }
      }
    });
  }

  const onUpdatePrescriptionStatus = async (prescriptionId, status) => {
    setChangingStatus(true)
    try {
      const updatedPrescription = await updatePrescriptionStatus(prescriptionId, { status })
      message.info(`Prescription ${status === PrescriptionStatus.PAUSED ? 'paused' : 'restarted'}`)
      setPrescriptions(cachedPrescriptions => {
        return cachedPrescriptions.map(p => p._id === prescriptionId ? updatedPrescription : p)
      })
    } catch (err) {
      message.error(`Failed to ${status === PrescriptionStatus.PAUSED ? 'pause' : 'restart'} prescription`)
    }
    setChangingStatus(false)
  }

  const onClonePrescription = async (prescriptionId) => {
    setCloning(true)
    try {
      const clonedPrescription = await clonePrescription(prescriptionId)
      message.info('Cloned prescription')
      setPrescriptions(cachedPrescriptions => {
        return [
          clonedPrescription,
          ...cachedPrescriptions,
        ]
      })
      incrementCount(1)
    } catch (err) {
      message.error(`Failed to clone prescription`)
    }
    setCloning(false)
  }

  const onAddGogoOrder = async (prescriptionId) => {
    setChangingStatus(true)
    try {
      const updatedPrescription = await addGogoOrder({ prescription: prescriptionId })
      message.success('Sent prescription to GoGoMeds')
      setPrescriptions(cachedPrescriptions => {
        return cachedPrescriptions.map(p => p._id === prescriptionId ? updatedPrescription : p)
      })
    } catch (err) {
      message.error('Failed to send prescription to GoGoMeds')
    }
    setChangingStatus(false)
  }

  // Handle opening the modal
  const handleOpenStatusUpdate = (prescriptionId) => {
    setSelectedPrescriptionId(prescriptionId);
    setOpenStatusUpdateModal(true);
  };

  // Handle updating the prescription status
  const handleUpdateStatus = async (prescriptionId, status) => {
    setChangingStatus(true);
    try {
      const updatedPrescription = await updatePrescriptionStatus(prescriptionId, { status });
      message.success(`Prescription updated to ${status}`);
      setPrescriptions((cachedPrescriptions) =>
        cachedPrescriptions.map((p) => (p._id === prescriptionId ? updatedPrescription : p))
      );
    } catch (err) {
      message.error(`Failed to update prescription status`);
    }
    setChangingStatus(false);
  };
  

  const getActionItems = (p) => {
    const { _id, status } = p

    let menuItems = [
      {
        key: 'statusUpdate',
        label: (
          <Button
            onClick={() => handleOpenStatusUpdate(_id)}
            icon={<LockOutlined />}
          >
            Update Status
          </Button>
        ),
      },
      
      {
      key: 'review',
      label: (
        <Button
          onClick={() => {
            setPrescription(p)
            setOpenPrescriptionModal(true)
          }}
          icon={<EyeOutlined />}
        >
          Review Application
        </Button>
      )
    }, {
      key: 'clone',
      label: (
        <Button
          onClick={(e) => {
            e.stopPropagation()
            onClonePrescription(_id)
          }}
          loading={cloning}
          icon={<CopyOutlined />}
        >
          Clone Prescription
        </Button>
      )
    }]
    switch (status) {
      case PrescriptionStatus.PENDING:
        menuItems.push({
          key: 'send',
          label: (
            <Button
              onClick={(e) => {
                e.stopPropagation()
                onAddGogoOrder(_id)
              }}
              // disabled={!patient?.govId}
              loading={changingStatus}
              icon={<SendOutlined />}
            >
              Send Prescription
            </Button>
          )
        })
        break
      case PrescriptionStatus.SENT:
        menuItems.push({
          key: 'pause',
          label: (
            <Button
              onClick={(e) => {
                e.stopPropagation()
                onUpdatePrescriptionStatus(_id, PrescriptionStatus.PAUSED)
              }}
              loading={changingStatus}
              icon={<LockOutlined />}
            >
              Pause Prescription
            </Button>
          )
        })
        break
      case PrescriptionStatus.PAUSED:
        menuItems.push({
          key: 'restart',
          label: (
            <Button
            onClick={(e) => {
              e.stopPropagation()
              onUpdatePrescriptionStatus(_id, PrescriptionStatus.SENT)
            }}
              icon={<UnlockOutlined />}
              loading={changingStatus}
            >
              Restart Prescription
            </Button>
          )
        })
        break
    }
    if (currentUser?.role === Role.ADMIN) {
      menuItems.push({
        type: 'divider'
      })
      menuItems.push({
        key: 'remove',
        label: (
          <Button
            onClick={(e) => {
              e.stopPropagation()
              onRemove(_id)
            }}
            icon={<DeleteOutlined />}
            className="remove-item"
          >
            Remove
          </Button>
        )
      })
    }
    return menuItems
  }

  return (
    <div className="admin-prescriptions-table">
      <PageHeader
        title='Prescriptions'
        count={filteredCount}
        actions={currentUser?.role === Role.ADMIN ? (
          <Button
            type='primary'
            onClick={() => {
              setOpenAddPrescription(true)
            }}
          >
            + Add New Prescription
          </Button>
        ) : null}
      />

      <StatusUpdateModal
        visible={openStatusUpdateModal}
        onClose={() => setOpenStatusUpdateModal(false)}
        prescriptionId={selectedPrescriptionId}
        onUpdate={handleUpdateStatus}
      />
      <PrescriptionForm
        open={openAddPrescription}
        setOpen={setOpenAddPrescription}
        onSuccess={onAddPrescription}
        patientId={patientId}
      />

      <PrescriptionModal 
        open={openPrescriptionModal}
        setOpen={setOpenPrescriptionModal}
        prescription={prescription}
        setPrescription={setPrescription}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={prescriptions}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[{
          title: 'Status',
          dataIndex: 'status',
          width: 100,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(PrescriptionStatus),
          render: status => <Text className={classNames(`${status}-prescription-status`, "prescription-status")}>{status}</Text>
        }, !hasPatient && {
          title: 'Patient',
          dataIndex: 'patient',
          filterDropdownType: FilterDropdownType.INPUT,
          render: patient => {
            if (patient) {
              return (
                <a 
                  onClick={() => navigate(UrlHelper.getPatientProfile(patient._id))}
                >
                  {patient.firstName} {patient.lastName}
                </a>
              )
            }
          }
        }, 

        {
          title: 'Medication',
          dataIndex: 'medication',
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: prescriptionTypes,
          render: (_, { type, contents } ) => (
            <Tooltip title={contents && `${contents.sig}: ${contents.units} x ${contents.type}`}>
              {contents?.type ? contents.type : type}
            </Tooltip>
          )
        },   
        
        {
          title: 'Units',
          dataIndex: 'units',
          render: ((_, { contents }) => contents?.units)
        },  

        {
          title: 'Directions',
          dataIndex: 'directions',
          render: ((_, { contents }) => contents?.sig),
          width: 300
        },  
        {
          title: 'Tracking',
          dataIndex: 'tracking',
          render: (tracking, { _id, isPickupOrder }) => 
          
          isPickupOrder ? <>n/a</> :
          tracking?.trackingNumber ? (
            <Tooltip 
              overlayStyle={{ maxWidth: '500px'}}
              title={ShippingHelper.getImportantEvents(tracking.trackingEvents)?.length ? (
                <ul className="tracking-list">
                  {ShippingHelper.getImportantEvents(tracking.trackingEvents).map(({ eventTimestamp, eventType, eventCity, eventState }, index) => (
                    <li key={`tracking-${_id}-${index}`}>
                      <strong>{moment(eventTimestamp).format('MM/DD/YYYY')}</strong>: {eventType} {(eventCity && eventState) ? ` (${startCase(toLower(eventCity))}, ${eventState})` : ''}
                    </li>
                  ))}
                </ul>
              ) : null}
            >
              {TrackingHelper.getDeliveryService(tracking.trackingNumber).service}: &nbsp;
              <a 
                className="link" 
                href={TrackingHelper.getDeliveryService(tracking.trackingNumber).url} 
                target="_blank"
              >
                {tracking.trackingNumber}
              </a>
            </Tooltip>
          ) : 'None'
        }, 
        
        {
          title: 'Ordered',
          dataIndex: 'createdAt',
          render: createdAt => (
            <Tooltip title={`${Math.round(moment().diff(moment(createdAt), 'weeks', true)*10)/10} weeks ago`}>
              {moment(createdAt).format('MM/DD/YYYY')}
            </Tooltip>
          )
        },
        {
          title: 'Next Refill',
          dataIndex: 'createdAt',
          render: (createdAt, { contents, status, hasRefill, daysToRefill }) => { 
            if (status === PrescriptionStatus.PAUSED) {
              return 'Paused'
            } 
            else if (!hasRefill) {
              return 'None'
            }
            else  {
              const days =  daysToRefill ? daysToRefill : contents ? contents.units/(contents.doseQty ? contents.doseQty : 1)  : 90
              return moment(createdAt).add(days, 'days').format('MM/DD/YYYY') 
            }
          }}
      
      
        ]}
      />
    </div>
  )
}