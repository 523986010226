import React, { useEffect, useState } from 'react';
import { Button, Tooltip, message, Tag } from 'antd'
import { listMembers } from "../../services/patient.service.js"
import './members.scss';
import dayjs from 'dayjs'
import { PageHeader } from '../pageHeader/pageHeader.component.js';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import PatientHelper from '../../helpers/patient.helper.js';
import UrlHelper from '../../helpers/url.helper.js';
import Gender from '../../enums/gender.enum.js';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import MembershipStatus from '../../enums/membershipStatus.enum.js';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum.js';

dayjs.extend(utc)
dayjs.extend(timezone)

export const Members = () => {
  const [patients, setPatients] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [filteredCount, setFilteredCount] = useState();

  useEffect(() => {
    document.title = '[Admin] Instalab | Members'
    fetchMembers()
  }, [])

  const fetchMembers = async () => {
    setIsLoading(true)
    const fetchedPatients = await listMembers({
      select: '_id firstName lastName email phone credits gender dob location isShareable memberships',
      filter: {},
      populate: [{path: 'memberships', populate: [{path: 'membershipType'}]}],
      sort: '-createdAt'
    })
    setPatients(fetchedPatients)
    setFilteredCount(fetchedPatients.length)
    setIsLoading(false)
  }

  const getCustomFilter = (patient, value) => {
    return {
      location: () =>  patient.location?.city && patient.location.city.toLowerCase().includes(value.toLowerCase()),
      firstName: () =>  patient.firstName && `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(value.toLowerCase()),
      phone: () =>  patient.phone && patient.phone.replace(/\D/g,'').includes(value.replace(/\D/g,'')),
      memberships: () => patient.memberships.some(membership => 
        membership.status === MembershipStatus.ACTIVE && 
        membership.membershipType.title.toLowerCase().includes(value.toLowerCase())
      ),
    } 
  }

  return (
    <div className="members">
      <PageHeader
        title='Members'
        count={filteredCount}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={patients}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        columns={[{
          title: 'Name',
          dataIndex: 'firstName',
          render: (firstName, patient) => (
            <span>
              {firstName ? <>
              <a
                onClick={() => {
                  window.open(UrlHelper.getPatientProfile(patient?._id), '_blank');
                }}
              >{firstName} {patient?.lastName}</a></> : 'N/A'}
            </span>
          ),
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Email',
          dataIndex: 'email',
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Phone',
          dataIndex: 'phone',
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Credits',
          dataIndex: 'credits',
          render: credits => {
            if (credits?.length > 0) {
              const groupedCredits = credits.reduce((acc, credit)  => {
                if (acc[credit]) {
                  acc[credit] = acc[credit] + 1
                } else {
                  acc[credit] = 1
                }
                return acc
              }, {})
              
              return Object.keys(groupedCredits).map(credit => {
                return `${credit} x ${groupedCredits[credit]}`
              }).join(', ')
            }
          }
        }, {
          title: 'Sex',
          dataIndex: 'gender',
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(Gender)
        }, {
          title: 'Age',
          dataIndex: 'dob',
          render: dob => PatientHelper.getAge({ dob }),
        }, {
          title: 'Location',
          dataIndex: 'location',
          filterDropdownType: FilterDropdownType.INPUT,
          render: (location) => {
            if (!location) return null
            const { streetAddress, streetAddress2, city, state, postalCode } = location
            const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
            return <Tooltip title={fullAddress}>{city}</Tooltip>
          }
        }, 
        {
          title: 'Type',
          dataIndex: 'memberships',
          filterDropdownType: FilterDropdownType.INPUT,
          render: memberships => memberships.map(membership => 
            membership.status === MembershipStatus.ACTIVE ? 
              <Tag>{membership.membershipType.title.replace('Membership', '').replace("Add-on", "")}</Tag> 
            : null
          ).filter(Boolean)
        },       
        {
          dataIndex: '_id',
          width: 50,
          className: "action-column",
          render: (_id, patient)  => <Button className="impersonate-btn" onClick={() => PatientHelper.onImpersonate(patient, message)}>Impersonate</Button>
        }]}
      />
    </div>
  )
}