import React, { useEffect } from 'react';
import { AdminPrescriptionsTable } from '../adminPrescriptionsTable/adminPrescriptionsTable.component.js';

export const AdminPrescriptions = () => {
  useEffect(() => {
    document.title = 'Instalab | Prescriptions'
  }, [])

  return (
    <AdminPrescriptionsTable />
  )
}