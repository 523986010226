const Panel = Object.freeze({
  BASIC: 'Basic',
  ADVANCED_BASELINE: 'Advanced (Baseline)',
  ADVANCED_FOLLOWUP: 'Advanced (Followup)',
  ATHLETE: 'Athlete',
  TESTOSTERONE: 'Testosterone',
  BIOMETRICS: 'Biometrics',
  ALLERGY: 'Allergy',
  THYROID: 'Thyroid',
  GALLERI: 'Galleri',
  CHOLESTEROL_THERAPY: 'Cholesterol Therapy',
  HEART_HEALTH: 'Heart Health',
  CHOLESTEROL_BALANCE: 'Cholesterol Balance',
  FERTILITY: 'Fertility',
  OMEGA: 'Omega',
  NMR_LIPOPROFILE: 'NMR Lipoprofile',
  CUSTOM: 'Custom',
  DEXA: 'DEXA',
  CAC: 'CAC',
  SLEEP: 'Sleep',
  VO2MAX: 'VO2Max',
  PERFORMANCE: 'Performance',
  CCTA: 'CCTA',
  MYCOTOX: 'Mycotoxins',
  FIT_STOOL: 'FIT Stool'
})

export default Panel;
