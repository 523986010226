import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const uploadFile = async (params) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/gdrive`, params, {headers: ServiceHelper.getHeaders()})
    return response.data
}

export const listFiles = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/gdrive`, {headers: ServiceHelper.getHeaders()});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const listFilesByPatient = async (patientId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/gdrive/patient/${patientId}`, {headers: ServiceHelper.getHeaders()});
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getFileUrl = (id) => {
    return `${process.env.REACT_APP_API_URL}/api/v1/gdrive/${id}`;
};



export const getSignedUrl = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/gdrive/signed-url/${id}`, {
            headers: ServiceHelper.getHeaders(),
        });
        return response.data.signedUrl;
    } catch (error) {
        console.error("Error getting signed URL:", error);
        throw new Error("Failed to get signed URL");
    }
};


export const downloadFile = async (id, fileName) => {
    try {
        // Assuming getSignedUrl is async and fetches the signed URL
        const signedUrl = await getSignedUrl(id); // Ensure this awaits the signed URL correctly

        const response = await fetch(signedUrl, { headers: ServiceHelper.getHeaders() });

        if (response.ok) {
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName); // Consider dynamically setting the filename if possible
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Make sure to remove the link after clicking
            window.URL.revokeObjectURL(downloadUrl);
        } else {
            alert("Failed to download file. You might not have permission.");
        }
    } catch (error) {
        console.error("Error downloading the file:", error);
        alert("An error occurred while downloading the file.");
    }
};


export const deleteFile = async (id) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/gdrive/${id}`, {
            headers: ServiceHelper.getHeaders(),
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting file:", error);
        throw error;
    }
};

export const renameFile = async (id, newName) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/v1/gdrive/rename/${id}`,
            {newName: newName},
            { headers: ServiceHelper.getHeaders() }
        );
        return response.data;
    } catch (error) {
        console.error("Error renaming file:", error);
        throw error; // It's often a good idea to catch this error in the UI and display a meaningful message.
    }
};