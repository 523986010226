import React, { useState, useContext, useEffect } from 'react';
import { Button, Tooltip, Card, Typography, Table, Dropdown } from 'antd'
import { PageHeader } from '../pageHeader/pageHeader.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Step as LongevityStep } from '../longevityConsultFlow/longevityConsultFlow.component'
import { Step as AthleteStep } from '../athleteConsultFlow/athleteConsultFlow.component'
import { Step as HeartHealthStep } from '../heartHealthConsultFlow/heartHealthConsultFlow.component'
import "./consults.scss";
import { CalendarOutlined, VideoCameraOutlined, MoreOutlined } from '@ant-design/icons'
import { UserContext } from '../../contexts/user.context'
import advanced from 'dayjs/plugin/advancedFormat'
import { useNavigate } from "react-router-dom";
import FlowType from '../../enums/flowType.enum'
import ProductTypeCode from '../../enums/productTypeCode.enum'
import { listConsults } from '../../services/consult.service'
import MembershipHelper from '../../helpers/membership.helper'
import MembershipTypeCode from '../../enums/membershipTypeCode.enum'
import ScheduleMenu from '../scheduleMenu/scheduleMenu.component'
import classNames from 'classnames'

const { Text } = Typography;

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

export const Consults = () => {
  const navigate = useNavigate()
  const { currentUser, token, memberships } = useContext(UserContext)
  const [consults, setConsults] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const [isLoading, setIsLoading] = useState()
  const [heartMembership, setHeartMembership] = useState()

  useEffect(() => {
    document.title = 'Instalab | Consultations'
  }, [])

  useEffect(() => {
    fetchConsults()
  }, [token])

  useEffect(() => {
    setHeartMembership(getHeartMembership())
  }, [memberships])

  const getHeartMembership = () => {
    if (!memberships?.length) return null
    const heartHealthMembership = MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.HEART_HEALTH)
    if (heartHealthMembership) {
      return heartHealthMembership
    }
    return null
  }


  const fetchConsults = async () => {
    setIsLoading(true)
    if (token) {
      const fetchedConsults = await listConsults({
        select: '_id name start status remoteLocation',
        populate: [{
          path: 'provider',
          select: 'firstName lastName photo npi'
        }, {
          path: 'flow',
          select: '_id products type',
          populate: {
            path: 'products',
            select: 'productType',
            populate: {
              path: 'productType',
              select: 'code',
            }
          }
        }],
        sort: '-start'
      })

      setConsults(fetchedConsults)
      setFilteredCount(fetchedConsults.length)
    } else {
      setConsults(null)
      setFilteredCount(0)
    }
    setIsLoading(false)
  }


  const getActionItems = (consult) => {
    let menuItems = []

    const isFuture = new Date(consult.start) > new Date()
    
    if (consult.flow && isFuture) {
      const flowStep = {
        [ProductTypeCode.CONSULT_LONGEVITY]: LongevityStep.CONSULT,
        [ProductTypeCode.CONSULT_ATHLETE]: AthleteStep.CONSULT,
        [ProductTypeCode.CONSULT_HEART_HEALTH]: HeartHealthStep.CONSULT,
      }

      if (consult.remoteLocation) {
        menuItems.push({
          key: 'meeting',
          icon: <VideoCameraOutlined />,
          label: 'Go To Meeting',
          onClick: () => window.open(consult.remoteLocation, '_blank')
        })
      }

      if (consult.flow.products?.length) {
        menuItems.push({
          key: 'reschedule',
          icon: <CalendarOutlined />,
          label: 'Reschedule',
          onClick: () => navigate(`/flow/${consult.flow.type}/${flowStep[consult.flow.products[0].productType.code]}/${consult.flow._id}`)
        })
      }
    }
    return menuItems
  }



  const convertTimeZone = (time) => {
    const currentUserTimeZone = currentUser?.location?.timeZoneId || 'UTC'; // Default to UTC if timezone is not provided
    const formattedDate = dayjs(time).tz(currentUserTimeZone);
    return formattedDate
  }


  let productTypes = [];

  if (heartMembership) {
    productTypes.push(
      {
        title: 'Heart Health Consult',
        code: ProductTypeCode.CONSULT_HEART_HEALTH,
        flowType: FlowType.HEART_MEMBER_CONSULT
      })
  }

  if (currentUser?.isAthlete) {
    productTypes.push({
      title: 'Athlete Consult',
      code: ProductTypeCode.CONSULT_HEART_HEALTH,
      flowType: FlowType.ATHLETE_CONSULT
    })
  } else {
    productTypes.push({
      title: 'Longevity Consult',
      code: ProductTypeCode.CONSULT_LONGEVITY,
      flowType: FlowType.LONGEVITY_CONSULT,
    })
  }

  const renderMobileCard = (consult) => {
    const { start, provider, remoteLocation, status } = consult;
    const dropdownItems = getActionItems(consult);
    const formattedTime = start ? 
      convertTimeZone(start).format('MMM D, YYYY @ h:mm a') : 
      'TBD';
    const isFuture = new Date(start) > new Date();

    return (
      <Card 
        key={consult._id} 
        className="consult-card"
        extra={
          dropdownItems.length > 0 && (
            <Dropdown
              menu={{ items: dropdownItems }}
              trigger={['click']}
            >
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          )
        }
      >
        <div className="consult-card-content">
          
          <div className="consult-card-row provider">
            {provider.npi && <>Dr. </>}{provider.firstName} {provider.lastName}
          </div>

          <div className="consult-card-row time">
            <CalendarOutlined /> {formattedTime}
          </div>

          {isFuture && (
            <div className="consult-card-row meeting">
              {remoteLocation ? (
                <><VideoCameraOutlined /> <a href={remoteLocation} className="link" target="_blank" rel="noopener noreferrer">Go To Meeting</a></>
              ) : (
                <Text type="secondary">Check calendar invite for details</Text>
              )}
            </div>
          )}
        </div>
      </Card>
    );
  };

  return (
    <div className="consults">
      <PageHeader 
        title='Consultations' 
        count={filteredCount}
        actions={(
          <ScheduleMenu 
            productTypes={productTypes} 
            currentUser={currentUser} 
            menuText="Book Consultation"
          />
        )}
      />

      <div className="consults-container">
        <Table
          className="desktop-only"
          loading={isLoading}
          dataSource={consults}
          rowKey="_id"
          columns={[
            {
              title: 'Provider',
              dataIndex: 'provider',
              render: provider => <>{provider.npi && <>Dr. </>}{provider.firstName} {provider.lastName}</>,
            }, {
              title: 'Start',
              dataIndex: 'start',
              render: (start, { provider }) => start && convertTimeZone(start, provider).format('MMM D, YYYY')
            }, {
              dataIndex: 'startTime',
              render: (_, { start, provider }) => start && convertTimeZone(start, provider).format('h:mma z')
            }, {
              title: 'Meeting Link',
              dataIndex: 'remoteLocation',
              render: (remoteLocation, record) => {
                const isFuture = new Date(record.start) > new Date();
                if (isFuture) {
                  if (remoteLocation) {
                    return (
                      <Tooltip title="Join Meeting">
                        <Button 
                          type="link" 
                          className="link"
                          icon={<VideoCameraOutlined />}
                          onClick={() => window.open(remoteLocation, '_blank')}
                          style={{ padding: 0 }}
                        >
                          Join Meeting
                        </Button>
                      </Tooltip>
                    )
                  } else {
                    return <Text type="secondary">Check calendar invite for details</Text>
                  }
                }
                return null;
              }
            }, {
              title: '',
              key: 'actions',
              render: (_, record) => {
                const menuItems = getActionItems(record);
                return menuItems.length > 0 ? (
                  <Dropdown
                    menu={{ items: menuItems }}
                    trigger={['click']}
                  >
                    <Button icon={<MoreOutlined />} />
                  </Dropdown>
                ) : null;
              }
            }
          ]}
        />

        <div className="mobile-only">
          {isLoading ? (
            <div className="loading-container">Loading...</div>
          ) : (
            consults?.map(renderMobileCard)
          )}
        </div>
      </div>
    </div>
  )
}