import { Typography } from "antd";
import "./ferritinCheck.scss";
import { TestChart } from "../testChart/testChart.component";
import { useState, useEffect } from "react";
import TestCode from "../../../enums/testCode.enum";
import ReportSection from "../../../enums/reportSection.enum";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const FerritinCheck = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const isFixed = ReportHelper.isFixed(report, ReportSection.FERRITIN_CHECK);
  const isBetter = ReportHelper.isBetter(report, ReportSection.FERRITIN_CHECK);
  const isWorse = ReportHelper.isWorse(report, ReportSection.FERRITIN_CHECK);
  const isStatic = ReportHelper.isStatic(report, ReportSection.FERRITIN_CHECK);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [user, report, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
      
    if (!user || !tests || !report) return null;

    if (isFixed) {
      return (
        <>
          Your <span>ferritin</span> is now <span>optimal</span>.
        </>
      );
    } else if (isBetter) {
      return (
        <>
          Your <span>ferritin</span> is <span>improving</span>.
        </>
      );
    } else if (isWorse) {
      return (
        <>
          Your <span>ferritin</span> is <span>increasing</span>.
      </>
      );
    } else if (isStatic) {
      return (
        <>
          Your <span>ferritin</span> remains relatively <span>unchanged</span>.
      </>
      );
    }

    return <>Your <span>ferritin</span> is <span>unusually high</span> - a protein
    that stores iron in your cells and plays a vital role in maintaining
    healthy cells.</>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!user || !tests || !report) return null;

    if (isFixed) {
      return (
        <>
          Ferritin levels can temporarily increase due to factors such as inflammation, infection, or injury. However, your levels have returned to the optimal range, indicating no cause for concern at this time.
        </>
      );
    } else if (isBetter) {
      return (
        <>
          Ferritin levels can temporarily increase due to factors such as inflammation, infection, or injury. Since your levels are moving towards the optimal range, there is no cause for concern at this time. Let's continue to monitor this in your next blood draw.
        </>
      );
    } else if (isWorse) {
      return (
        <>
          Given your ferritin levels are heading in the wrong direction, it's recommended you talk with your primary care provider soon about what may be the cause of this and appropriate next steps.
        </>
      );
    } else if (isStatic) {
      return (
        <>
          Given your ferritin levels haven't really changed, it's recommended you talk with your primary care provider soon about what may be the cause of this and appropriate next steps.
        </>
      );
    }

    else 
      return <>Ferritin levels can temporarily increase due to inflammation, infection, or injury. But chronically high ferritin levels are uncommon and can indicate a serious issue  so it's important to confirm whether this is temporary or persistent through a follow-up test before drawing conclusions.</>
  }

  return (
    user &&
    tests?.length > 0 && (
      <div className="ferritin-check">
        <Paragraph className="report-template-title">
          {title}
        </Paragraph>
        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        <TestChart 
          report={report} 
          code={TestCode.FERRITIN} 
          tests={tests}
          results={results}
          reports={reports}
        />

        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
        
      </div>
    )
  );
};
