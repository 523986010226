const RecommendationKind = Object.freeze({
    NUTRITION: 0,
    EXERCISE: 1,
    LIFESTYLE: 2,
    SUPPLEMENT: 3,
    MEDICATION: 4,
    PROVIDER: 5,
    TESTING: 6,
    MENTAL: 7
});

export default RecommendationKind;
