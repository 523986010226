import React, { useState, useEffect } from 'react';
import { Form, Button, Upload, message, Card, Space, Typography } from 'antd';
import { UploadOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'; 
import { getOrder, updateOrder, addOrder, uploadRequisitionFile, getRequisitionFileLink  } from  "../../../../services/order.service"

const { Item } = Form;
const { Title, Text } = Typography;

export const MultipleUpload = ({
  onSubmit,
  form,
  isSubmitting,
  flow,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        let fetchedOrders = [];
        
        for (const orderId of flow.appointment.orders) {
          const id = typeof orderId === 'object' ? orderId._id : orderId;
          const orderData = await getOrder(id, {populate: ['patient']});
          fetchedOrders.push(orderData);
        }
        setOrders(fetchedOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
        message.error('Failed to fetch orders');
      }
    };

    if (flow?.appointment?.orders?.length > 0) {
      fetchOrders();
    }
  }, [flow?.appointment?.orders]);

  const handleFileChange = async ({file}, order) => {
    setIsUploading(true);

    try {
      const uploadedResponse = await uploadRequisitionFile(file);
      
      if (!uploadedResponse) {
        message.error("Failed to upload requisition file.");
        setIsUploading(false);
        return;
      }

      const params = {
        patient: order.patient._id,
        appointment: order.appointment,
        reqFileName: uploadedResponse.fileName
      };

      try {
        const response = order ? await updateOrder(order._id, params) : await addOrder(params);

        if (response) {
          message.success('Requisition saved successfully');
          const updatedOrder = await getOrder(order._id, {populate: ['patient']});
          setOrders(orders.map(o => o._id === order._id ? updatedOrder : o));
        }

      } catch (saveError) {
        console.error("Error saving requisition to order:", saveError);
        message.error("Failed to save requisition to order.");
      }

    } catch (error) {
      console.error('Upload failed:', error);
      message.error('Upload failed');
    } finally {
      setIsUploading(false);
    }
  };

  const handleFilePreview = async (order) => {
    try {
      const fileUrl = await getRequisitionFileLink(order.reqFileName);
      window.open(fileUrl, '_blank');
    } catch (error) {
      message.error('Failed to open file preview');
    }
  };

  const handleFileDelete = async (order) => {
    try {
      await updateOrder(order._id, { ...order, reqFileName: null });
      // Refresh orders
      const updatedOrder = await getOrder(order._id, {populate: ['patient']});
      setOrders(orders.map(o => o._id === order._id ? updatedOrder : o));
      message.success('File removed successfully');
    } catch (error) {
      message.error('Failed to remove file');
    }
  };

  return (
    <div className="upload" style={{ maxWidth: 800, margin: '0 auto' }}>
      {(!orders || orders.length === 0) ? (
        <Card className="flat">
          <Text type="secondary">No orders found to upload requisitions.</Text>
        </Card>
      ) : (
        <Form
          form={form}
          className="upload-form"
          layout="vertical"
          onFinish={() => form.submit()}
        >
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            {orders.map((order) => (
              <Card 
                key={order._id}
                className="patient-requisition flat"
                title={
                  <Title level={5}>
                    Requisition for {order.patient.firstName} {order.patient.lastName}
                  </Title>
                }
              >
                <Item>
                  <Upload
                    beforeUpload={() => false}
                    onChange={(info) => handleFileChange(info, order)}
                    accept=".pdf,.doc,.docx,.jpg,.png"
                    multiple={false}
                    isUploading={isUploading}
                    fileList={order.reqFileName ? [{
                      uid: order._id,
                      name: order.reqFileName,
                      status: 'done'
                    }] : []}
                    showUploadList={false}
                  >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {order.reqFileName ? (
                        <Space>
                          <Text>{order.reqFileName}</Text>
                          <Button 
                            type="text" 
                            icon={<EyeOutlined />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFilePreview(order);
                            }}
                          />
                          <Button 
                            type="text" 
                            danger 
                            icon={<DeleteOutlined />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFileDelete(order);
                            }}
                          />
                        </Space>
                      ) : (
                        <Button 
                          icon={<UploadOutlined />}
                          loading={isUploading}
                        >
                          Upload Requisition
                        </Button>
                      )}
                    </Space>
                  </Upload>
                </Item>
              </Card>
            ))}
          </Space>

          <div style={{ marginTop: 24 }}>
            <FlowStepFooter 
              onSubmit={() => onSubmit()}
              isSubmitting={isSubmitting}
              buttonText="Continue"
            />
          </div>
        </Form>
      )}
    </div>
  );
};
