const PlanCode = Object.freeze({
  // Standard
  BASIC: 'Basic',
  ADVANCED: 'Advanced',
  ELITE: 'Elite',
  TESTOSTERONE: 'Testosterone',
  LONGEVITY: 'Longevity Program',
  CUSTOM: 'Custom',
  ATHLETE: 'Athlete',
  ALLERGY: 'Allergy',
  CAC: 'CAC',
  PERFORMANCE: 'Performance',
  HEART_HEALTH: 'Heart Health'
})

export default PlanCode