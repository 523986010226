import {  Typography } from 'antd';
import FlowType from '../../enums/flowType.enum';

const { Text } = Typography

export const ServiceFeeNotice = ({ productType, complex=true}) => {
    return (complex 
            ?          
                <div className="service-fee-notice"> 
                    <Text strong>Service Fee</Text>: Instalab charges a ${productType?.cost} service fee for the physician order, scheduling, and interpretiation of results. This is separate from the cost of the test paid to the facility. <Text underline><a className="cta-link"href={`/flow/${FlowType.MEMBER}`}>Members pay no service fee</a></Text>.
                </div>
            :
                <div className="service-fee-notice"><Text strong>Service Fee</Text>: Instalab charges a ${productType?.cost} service fee to find the best place for your test and schedule your appointment. This is separate from the cost of the test paid directly to the facility. <Text underline><a className="cta-link"href={`/flow/${FlowType.MEMBER}`}>Members pay no service fee</a></Text>.</div>
        )
    }