import React, { useEffect, useState } from 'react';
import { Table, Typography } from 'antd'
import { listInvoices } from "../../services/invoice.service"
import moment from 'moment'
import './adminInvoices.scss';
import classNames from 'classnames';
import { PageHeader } from '../pageHeader/pageHeader.component';

const { Column } = Table
const { Text } = Typography

export const AdminInvoices = () => {
  const [invoices, setInvoices] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    document.title = 'Instalab | Payments'
    fetchInvoices()
  }, [])

  const fetchInvoices = async () => {
    setInvoices(await listInvoices())
    setIsLoading(false)
  }

  return (
    <div className={classNames("admin-invoices", "component-layout")}>
      <PageHeader title='Payments' /> 
      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={invoices}
      >

        <Column
          title='Patient'
          dataIndex='patient'
          render={(patient) => patient ? `${patient.firstName} ${patient.lastName}` : <Text className="deleted-text">Deleted Patient</Text>}
        />

      <Column
          title='Panel'
          dataIndex='plans'
          render={plans => {
            const counts = plans.reduce((acc, plan) => {
              if (acc[plan]) {
                acc[plan] = acc[plan] + 1
              } else {
                acc[plan] = 1
              }
              return acc
            }, {})

            return Object.keys(counts).map(plan => `${plan} x ${counts[plan]}`).join(', ')
          }}
        />

        <Column
          title='Total'
          dataIndex='total'
          render={total => (
            <Text className={"invoice-total"}>
              ${total.toLocaleString()}
            </Text>
          )}
        />

        <Column
          title='Created'
          dataIndex='createdAt'
          render={createdAt => moment(createdAt).format('MMM D, YYYY')}
        />
      </Table>
    </div>
  )
}