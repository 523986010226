import { Modal, Typography } from "antd"
import { useEffect, useState } from "react"
import './testModal.scss'
import ContentHelper from "../../helpers/content.helper";
import { TestChart } from "../universalReport/testChart/testChart.component";
import { TestTable } from "../universalReport/testTable/testTable.component"

const { Paragraph } = Typography

export const TestModal = ({ report, testId, setTestId, open, setOpen, tests, results, reports }) => {
  const [test, setTest] = useState()
  const [childTestId, setChildTestId] = useState()
  const [openChild, setOpenChild] = useState()

  // Update test whenever the `testId` changes
  useEffect(() => {
    if (testId) {
      fetchTest(testId); // Pass `testId` into fetchTest
    }
  }, [testId]);


  const fetchTest = (testId) => {
    if (testId === 'HEALTH_SCORE') {
      setTest({
        code: testId,
        name: 'Longevity Score',
        tagline: 'A measure of your overall health and its potential impact on both your lifespan and healthspan.',
        details: `The longevity score, a scale from 0 to 100, is a comprehensive measure of your overall health and its potential impact on both your lifespan and healthspan. The higher your score, the closer you are to optimal health and the more potential you may have for disease-free years.
        <br/><br/>The score is calculated using a statistical algorithm that considers factors such as your biomarker levels, family and medical history, and current lifestyle. It asks the question: if everything stayed the same from today onward, how much are you at risk for aging-related diseases, including cardiovascular disease, diabetes, hypertension, dementia, chronic kidney disease, and more. By making changes to improve these factors, you can change the trajectory of your longevity score significantly.
        <br/><br/>Your percentile is determined by comparing scores with other Instalab users of the same age and biological sex.`
      });
    } else {
      const targetTest = tests?.find(t => t._id === testId);
      if (targetTest) {
        setTest(targetTest);
      }
    }
  };

  const onCancel = () => {
    if (setTestId)  {
      setTestId(null)
    }
    setOpen(false)
  }


  return test && (
    <Modal 
      open={open} 
      title={test.name}
      className="test-modal"
      onCancel={onCancel}
      footer={null}
    >
      {/* <TestModal
        testId={childTestId}
        setTestId={setChildTestId}
        open={openChild}
        setOpen={setOpenChild}
        report={report}
        tests={tests}
        results={results}
        reports={reports}
      /> */}

      <Paragraph className="test-tagline">
        {test.tagline}
      </Paragraph>

      {test.string === true ? (
        
        <div className="apo-e-value">
          <TestTable 
            code={test?.code}  
            report={report}
            tests={tests}
            results={results}
          />
        </div>
      ) : (
        <div className="test-chart-container">
          <TestChart 
            code={test?.code} 
            yAxisLabel={false}
            hasReadMore={false}
            report={report}
            reports={reports}
            tests={tests}
            results={results}
          />
        </div>
      )}

      <>{report?.result.values.find((value) => value.code === test.code)}</>

      <Paragraph className="test-details">
        {ContentHelper.format(test.details, tests, setChildTestId, setOpenChild)}
      </Paragraph>

      {test.references?.length > 0 && (
        <Paragraph className="test-references">
        <div className="title">References</div>
        <ol>
          {test.references.map(reference => (
            <li key={reference._id}>
              <a href={reference.url} target="_blank">
                {reference.description}
              </a>
            </li>
          ))}
        </ol>
        </Paragraph>
      )}
    </Modal>
  )
}