import { Typography } from "antd"
import './overview.scss';

const { Paragraph } = Typography

export const Overview = ({ title, description }) => {
  return (
    <div className="overview">
      {title && (
        <>
          <Paragraph className="overview-title">
            {title}
          </Paragraph>
          <div className="overview-title-base"></div>
        </>
      )}
      {description && (
        <Paragraph className="overview-description">
          {description}
        </Paragraph>
      )}
    </div>
  )
}