import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listTasks = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tasks`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })

  return response.data
}

export const completeTask = async (id) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/tasks/${id}/complete`, {}, {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const openTask = async (id) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/tasks/${id}/open`, {}, {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeTask = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/tasks/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const removeTaskNote = async (id, noteId) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/tasks/${id}/notes/${noteId}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const addTask = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/tasks`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addTaskNote = async (id, params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/tasks/${id}/notes`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getTask = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tasks/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const getTaskTemplate = async (code) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tasks/templates/${code}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const listTaskTemplates = async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tasks/templates`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const updateTask = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/tasks/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}