import StorageKey from "../enums/storageKey.enum";

const ServiceHelper = {
  getHeaders: () => {
    return {
      'authorization': localStorage.getItem(StorageKey.TOKEN),
    }
  },
  getParams: (params={}) => {
    const tracker = localStorage.getItem(StorageKey.TRACKER);
    if (tracker) {
      params.tracker = tracker
    }
    return params
  }
}

export default ServiceHelper