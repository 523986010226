import React from "react";
import { Outlet } from 'react-router-dom';
import { Col, Affix, Layout, Row } from 'antd';
import "./adminLayout.scss"
import SidebarLayout from './sidebar.layout';
import SidebarScheduleBtnLayout from './sidebarScheduleBtn.layout';
import Breakpoint from '../../enums/breakpoint.enum'
import { Header } from "../header/header.component.js";
import useWidth from "../../hooks/useWidth.hook.js";

const AdminLayout = () => {
  const width = useWidth()

  return  <>
    <Header />
    <Layout className="patient-layout">
        {width > Breakpoint.LG ?
        <Row className="patient-row">
          <Col 
            xs={{ span: 6 }} 
            sm={{ span: 6 }} 
            md={{ span: 6 }} 
            lg={{ span: 6 }} 
            xl={{ span: 5 }} 
            xxl={{ span: 5 }} 
            className="sidebar-column">
            <Affix className="affix-sidebar">
              <SidebarScheduleBtnLayout />
              <SidebarLayout />
            </Affix>
          </Col>
          <Col 
            xs={{ span: 18 }} 
            sm={{ span: 18 }} 
            md={{ span: 18}}           
            lg={{ span: 18 }} 
            xl={{ span: 19 }} 
            xxl={{ span: 19 }}>
            <Outlet />
          </Col>
        </Row>
        : 
        <Row className="patient-row"><Col span={24}><Outlet /></Col></Row>}

    </Layout>
  </>
}

export default AdminLayout