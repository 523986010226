import { TestCode } from "../../../enums/index.enum"
import { TestModal } from "../../testModal/testModal.component";
import { useState } from "react";
import { Reference } from "../reference/reference.component";


export const CardioGeneticBox = ({ report, apoE, hasHighLPA, hasHighCAC, familyHistory, tests, results, reports, isFollowup=false}) => {
    const [testId, setTestId] = useState();
    const [openModal, setOpenModal] = useState();

    const onViewTest = (test) => {
        setTestId(test._id);
        setOpenModal(true);
    };

    const getFamilyHistoryString = (familyHistory) => {
        return familyHistory.map((condition, index) => {
          return ` ${
            familyHistory.length > 1 && index === familyHistory.length - 1
              ? " and "
              : ""
          }${condition.toLowerCase()}${
            index < familyHistory.length - 1 && familyHistory.length > 2
              ? ", "
              : ""
          }`;
        })
      }

    return (apoE || hasHighLPA || hasHighCAC) && tests?.length > 0 && <>  

        <TestModal
          open={openModal}
          setOpen={setOpenModal}
          testId={testId}
          setTestId={setTestId}
          report={report}
          tests={tests}
          results={results}
          reports={reports}
        />


        {hasHighCAC ? 
            <div className="genetics-section">
                <div className="genetics-section-title">Reminder: Your Elevated CAC Score</div>
                <div className="genetics-section-description">
                Your  <a onClick={() => onViewTest(tests.find(({ code }) => code === TestCode.CAC))}>Coronary Artery Calcium (CAC) score</a> is a key indicator of calcified plaque in your coronary arteries, and your elevated score suggests a higher risk for cardiovascular events such as heart attacks. Unlike genetic risks, this score reflects the current state of your arteries, making it essential to act now. Managing your lipids to help reduce plaque buildup should be a priority to prevent future complications.
                </div>
            </div>

            : <div className="genetics-section">

                <div className="genetics-section-title"> <>{isFollowup ? <>Reminder: Your Genetic Risks</> : <>Your Genetic Risks</>}</></div>
                <div className="genetics-section-description">

                    { apoE && !hasHighLPA && 
                        <>
                        Your{" "}
                        <a onClick={() => onViewTest(tests.find(({ code }) => code === TestCode.APO_E))}>APOE genotype</a>{" "}
                        is {apoE}, which means you're genetically predisposed to absorb more cholesterol from your liver and
                        intestines.{" "}
                        <Reference
                            content="This raises your risk for heart disease by up to 46%."
                            url="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5102878/"
                        />{" "}
                        <br />
                        <br />
                        It's important to note this is NOT a deterministic biomarker. It's not guaranteed you will develop heart disease. However,  
                        {familyHistory?.length > 0 &&
                            <> especially given your family history of {getFamilyHistoryString(familyHistory)},</>}
                        {" "}it's imperative to be proactive. By actively managing other cardiovascular risk factors (see below), you can significantly diminish your overall risk.                      
                        </>     
                    }

                    { !apoE && hasHighLPA && 
                        <>Your{" "}
                        <a onClick={() => onViewTest(tests.find(({ code }) => code === TestCode.LIPOPROTEIN_A))}>elevated Lp(a)</a>{" "}levels
                        {familyHistory?.length > 0 &&
                            <> and family history of {getFamilyHistoryString(familyHistory)}</>}
                        {" "}indicate you're at increased risk for cardiovascular disease. Since Lp(a) levels are largely determined by your genetics, these levels typically stay consistent over time.
                        <br />
                        <br />
                        At this point, there are no treatments that have shown to
                        effectively reduce Lp(a) enough to mitigate the risk it
                        imparts. However, taking proactive steps to minimize other
                        cardiovascular disease risk factors (see below) significantly reduces
                        your overall risk.
                        </>   
                    }

                    { apoE && hasHighLPA &&
                        <>  
                        Your&nbsp;
                        <a onClick={() => onViewTest(tests.find(({ code }) => code === TestCode.APO_E))}>APOE genotype</a>
                        {" "} ({apoE}) combined with your{" "}
                        <a onClick={() => onViewTest(tests.find(({ code }) => code === TestCode.LIPOPROTEIN_A))}>elevated Lp(a)</a>
                        {" "} levels indicate you're genetically predisposed to cardiovascular disease. This is due to reduced uptake of lipoproteins from the bloodstream and increased propensity for clot formation.
                        <br />
                        <br />
                        It's important to note these are NOT deterministic biomarkers. It's not guaranteed you'll develop cardiovascular disease. However,  
                        {familyHistory?.length > 0 &&
                            <> especially given your family history of {getFamilyHistoryString(familyHistory)},</>}
                        {" "}it's imperative to be proactive. By actively managing other cardiovascular risk factors (see below), you can significantly diminish your overall risk.                      
                        
                        </> 
                    }


                </div>
        
            </div>
        }

    </>
}