import React from 'react';
import { Typography } from 'antd'
import './pageHeader.scss';

const { Text } = Typography

export const PageHeader = ({ title, description, actions, count }) => {
  return (
    <div className="page-header">
      <div className="page-header-content">
        <Text className="page-header-title">{title}</Text>
        {typeof count === 'number' && (
          <Text className="filtered-count">{count.toLocaleString()}</Text>
        )}
        {description && <Text className="page-header-description">{description}</Text>}
      </div>
      <div className="page-header-actions">
        {actions}
      </div>
    </div>
  )
}