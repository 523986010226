import { Typography, Popover }  from 'antd'
import './memberConfirmed.scss'
import { CloseOutlined } from '@ant-design/icons'
import Breakpoint from "../../../../enums/breakpoint.enum";
import ProductTypeCode from "../../../../enums/productTypeCode.enum"
import { useNavigate } from 'react-router-dom';
import useWidth from '../../../../hooks/useWidth.hook';
import FlowType from '../../../../enums/flowType.enum';
import Panels from "../../../panels/panels.component";

const { Paragraph } = Typography



const MemberConfirmed = ({ footer, instalabMembership }) => {
  const width = useWidth()
  const navigate = useNavigate()

  const drWiniarskiContent = (
    <div style={{ padding: '15px' }}>
      <img 
        src="/img/steven.webp" 
        alt="Dr. Steven Winiarski" 
        style={{ 
          width: '120px', 
          display: 'block',
          margin: '0 auto',
          borderRadius: '50%', 
          marginBottom: '10px' 
        }} 
      />
      <h3 style={{ textAlign: 'center' }}>Dr. Steven Winiarski</h3>
      <p style={{ fontSize: '14px', lineHeight: '1.5', color: '#555' }}>
        Dr. Steven Winiarski is a seasoned longevity physician with over 25 years of experience working with professional athletes, executives and entrepreneurs. His specialties included longevity, lipidology and metabolic health. 
      </p>
    </div>
  );

  return (
    <div 
      className="member-confirmed"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >
      <div 
        className="member-confirmed-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph className="member-title">
          Welcome to {instalabMembership?.membershipType?.title}! 
        </Paragraph>
        
        <div className="welcome-message">
          <Paragraph>
            We're honored to have you as a member. You can reach out anytime to{' '}
            <a style={{color: `gray`}} href="mailto:concierge@instalab.com">concierge@instalab.com</a>
            {' '}with any questions or requests.
          </Paragraph>
        </div>

        <div className="next-steps-container">
          <h2 className="next-steps-title">Next Steps</h2>
          
          <div className="step-card">
            <div className="step-number">1</div>
            <div className="step-content">
              <h4>Complete Your Baseline Assessment</h4>
              <p>If you haven't done comprehensive blood work in the past year, let's get that taken care of first. This step ensures we have the most up-to-date information on your health.</p>
              {footer({ 
                buttonText: "Get Longevity Panel",
                buttonCheck: false,
                buttonStyle: {
                  height: 50,
                  fontSize: 18,
                  width: '100%',
                },
                enterStyle: {
                  display: 'none',
                },
                onCustomSubmit: () => navigate(`/longevity-panel`)
              })}
            </div>
          </div>

          <div className="step-card">
            <div className="step-number">2</div>
            <div className="step-content">
              <h4>Meet Your Longevity Physician</h4>
              <p>
                Schedule your first session with{' '}
                <Popover content={drWiniarskiContent}>
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>Dr. Steven Winiarski</span>
                </Popover>{' '}
                to develop your long-term health plan and answer any questions you have.
              </p>
              {footer({ 
                buttonText: "Schedule Consult",
                buttonCheck: false,
                buttonStyle: {
                  height: 50,
                  fontSize: 18,
                  width: '100%',
                },
                enterStyle: {
                  display: 'none',
                },
                onCustomSubmit: () => navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
              })}
            </div>
          </div>
        </div>

        <Panels />
      </div>
    </div>
  )
}

export default MemberConfirmed