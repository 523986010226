import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Input, Spin, Row, Col, message } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import { getPhlebotomist, updatePhlebotomist } from '../../services/phlebotomist.service';
import { getAdmin, updateAdmin } from '../../services/admin.service';
import { getPatient, updatePatient } from '../../services/patient.service';
import { getProvider, updateProvider } from '../../services/provider.service';
import { getFacility, updateFacility } from '../../services/facility.service';
import { getReferral, updateReferral } from '../../services/referral.service';
import { getStaff, updateStaff } from '../../services/staff.service';
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper'
import classNames from 'classnames'
import {
  setDefaults,
  fromAddress,
} from "react-geocode";
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import ObjectType from '../../enums/objectType.enum'
import { getAppointment, getAppointmentParent, updateAppointment, updateAppointmentParent } from '../../services/appointment.service';
import { useLoadScript } from "@react-google-maps/api";
import "./adminLocationModal.scss"

setDefaults({
  key: process.env.REACT_APP_GOOGLE_MAP_KEY,
  language: "en", // Default language for responses.
  region: "es", // Default region for responses.
})

const { Item } = Form
const { TextArea } = Input

const updateObject = {
  [ObjectType.PHLEBOTOMIST]: updatePhlebotomist,
  [ObjectType.ADMIN]: updateAdmin,
  [ObjectType.PATIENT]: updatePatient,
  [ObjectType.PROVIDER]: updateProvider,
  [ObjectType.APPOINTMENT]: updateAppointment,
  [ObjectType.FACILITY]: updateFacility,
  [ObjectType.STAFF]: updateStaff,
  [ObjectType.APPOINTMENT_PARENT]: updateAppointmentParent,
  [ObjectType.REFERRAL]: updateReferral
}

const getObject = {
  [ObjectType.PHLEBOTOMIST]: getPhlebotomist,
  [ObjectType.ADMIN]: getAdmin,
  [ObjectType.PATIENT]: getPatient,
  [ObjectType.PROVIDER]: getProvider,
  [ObjectType.APPOINTMENT]: getAppointment,
  [ObjectType.FACILITY]: getFacility,
  [ObjectType.STAFF]: getStaff,
  [ObjectType.APPOINTMENT_PARENT]: getAppointmentParent,
  [ObjectType.REFERRAL]: getReferral
}

export const AdminLocationModal = ({ open, setOpen, onSuccess, objectId, objectType, fieldName='location', select, populate=[] }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const inputRef = useRef(null)
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBiXW-QXKQreN_9hQOW4VPunykKt9T3pts",
    libraries: ['places']
  });
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (open) {
      if (objectId && objectType) setFormFields()
    } else {
      form.resetFields()
      setHasAttempt(false)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }


  const setFormFields = async () => {
    try {
      console.log(objectType, objectId)
      const object = await getObject[objectType](objectId)
      const fieldValues = object[fieldName]

      if (fieldValues) {
        form.setFieldsValue(fieldValues)
      }
    } catch (error) {
      console.error('Failed to fetch location data:', error)
      message.error('Failed to load location data')
    }
  }

  const onOk = async () => {
    setIsSubmitting(true)

    try {
      const { 
        streetAddress,
        streetAddress2,
        city,
        state,
        postalCode,
        entryInstructions,
      } = form.getFieldsValue()

      // const { results } = await fromAddress(`${postalCode}, USA`)
      // Construct the full address string
      const fullAddress = `${streetAddress}, ${city}, ${state}, ${postalCode}, USA`;

      // Geocode the full address to get latitude and longitude
      const { results } = await fromAddress(fullAddress);

      const { 
        lat: latitude, 
        lng: longitude,
      } = results[0].geometry.location;

      const response = await updateObject[objectType](objectId, {
        fields: {
          [fieldName]: {
            streetAddress,
            streetAddress2,
            city,
            state,
            postalCode,
            entryInstructions,
            latitude,
            longitude,
          }
        },
        select,
        populate
      })
      if (onSuccess) {
        onSuccess(response)
      }
      message.success('Location saved')
      setOpen(false)
      form.resetFields()
    } catch (err) {
      message.error('Failed to save location')
    }
    setIsSubmitting(false)
  }

  const handlePlacesChange = address => {
    form.setFieldsValue({
      streetAddress: address
    })
  };


  const handlePlacesSelect = address => {
    geocodeByAddress(address)
      .then(async (results) => {
        const streetNumber = getAddressValueByType(results, 'street_number')
        const route = getAddressValueByType(results, 'route')
        let formattedAddress = ''
        if (streetNumber) {
          formattedAddress = streetNumber
        }
        if (route) {
          formattedAddress = `${formattedAddress} ${route}`
        }

        form.setFieldsValue({
          streetAddress: formattedAddress,
          postalCode: getAddressValueByType(results, 'postal_code'),
          city: getAddressValueByType(results, 'locality') || getAddressValueByType(results, 'sublocality_level_1') || getAddressValueByType(results, 'neighborhood'),
          state: getAddressValueByType(results, 'administrative_area_level_1'),
        })
      })
  };

  const getAddressValueByType = (results, type) => {
    const component = results[0].address_components.filter(component => component.types.includes(type))
    if (component.length) {
        return component[0].short_name
    }
    return null
  }

  const onFail = () => {
    setHasAttempt(true)
    message.error('Enter valid location')
  }

  return (
    <Modal 
      open={open} 
      title={'Set Location'}
      onCancel={onCancel}
      footer={null}
      className="admin-location-modal"
    >
      <Form
        form={form}
        onFinish={onOk}
        onFinishFailed={onFail}
        layout='vertical'
      >
        <Item 
          label="Street Address"
          name="streetAddress"
          rules={[
            RuleHelper.isRequired
          ]}
        >
          {isLoaded && <PlacesAutocomplete
            onChange={handlePlacesChange}
            onSelect={handlePlacesSelect}
            debounce={0}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="street-address-container">
              <div className="adm-list-item">
                <div className="adm-list-item-content">
                  <div className="adm-list-item-content-main">
                    <Input
                      {...getInputProps({
                        className: classNames(
                          'street-address-input'
                        ),
                      })}
                      onKeyDown={e => {
                        if (e.which === 13 && suggestions?.length) {
                          e.stopPropagation()
                          handlePlacesSelect(suggestions[0].description)
                          inputRef.current.blur()
                        }
                      }}
                      ref={inputRef}
                      placeholder="Street Address"
                    />
                  </div>
                </div>
              </div>

                <div className="autocomplete-dropdown-container">
                  {loading && (
                    <div className="address-spinner">
                      <Spin size='small' /> Loading...
                    </div>
                  )}
                  {suggestions.map(suggestion => {
                    const style = suggestion.active
                    ? { cursor: 'pointer' }
                    : { cursor: 'pointer' };
                    return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                      className: 'suggestion-item',
                      style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                    );
                  })}
                </div>
              {/* </>} */}

            </div>
            )}
          </PlacesAutocomplete>}
        </Item>

        <Row gutter={12}>
          <Col span={12}>
            <Item 
              label="Apt, Suite, Bldg #"
              name="streetAddress2"
            >
              <Input placeholder="Apt, Suite, Bldg #" />
            </Item>
          </Col>

          <Col span={12}>
            <Item 
              label="City"
              name="city"
              rules={[
                RuleHelper.isRequired,
              ]}
              validateTrigger='onSubmit'
            >
              <Input 
                placeholder="City" 
                onChange={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
              />
          </Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Item 
              label="State"
              name="state"
              rules={[
                RuleHelper.isRequired,
              ]}
              validateTrigger='onSubmit'
            >
              <Input 
                placeholder="State" 
                onChange={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
              />
          </Item>
          </Col>

          <Col span={12}>
            <Item 
              label="Postal Code"
              name="postalCode"
              rules={[
                RuleHelper.isRequired,
                RuleHelper.isPostalCode
              ]}
              validateTrigger='onSubmit'
            >
              <Input 
                placeholder="Postal Code" 
                onChange={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
              />
            </Item>
          </Col>
        </Row>

        <Item 
          label="Parking / Entry Instructions"
          name="entryInstructions"
        >
          <TextArea placeholder="e.g. park in driveway, call me on arrival" />
        </Item>

        <ContinueButton
          text='Save Location'
          isSubmitting={isSubmitting}
        />
      </Form>
    </Modal>
  )
}