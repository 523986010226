import React, { useState, useContext, useEffect } from "react";
import "./universalReport.scss";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import Color from "../../colors.scss";
import { Spin, Row, Col, notification, Drawer } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import { UserContext } from "../../contexts/user.context";
import { VitaminDAdvice } from "./vitaminDAdvice/vitaminDAdvice.component";
import { HomocysteineAdvice } from "./homocysteineAdvice/homocysteineAdvice.component";
import { GeneralExerciseAdvice } from "./generalExerciseAdvice/generalExerciseAdvice.component";
import { NextSteps } from "./nextSteps/nextSteps.component";
import { MultipleAbnormalTests } from "./multipleAbnormalTests/multipleAbnormalTests.component";
import { SingleAbnormalTest } from "./singleAbnormalTest/singleAbnormalTest.component";
import ReportSection from "../../enums/reportSection.enum";
import { MetabolicAdvice } from "./metabolicAdvice/metabolicAdvice.component";
import { LowFerritinAdvice } from "./lowFerritinAdvice/lowFerritinAdvice.component";
import { CardioAdvice } from "./cardioAdvice/cardioAdvice.component";
import { PregnantAdvice } from "./pregnantAdvice/pregnantAdvice.component";
import { useParams } from "react-router-dom";
import { FerritinCheck } from "./ferritinCheck/ferritinCheck.component";
import classNames from "classnames";
import { getMe } from "../../services/user.service.js";
import { BpAdvice } from "./bpAdvice/bpAdvice.component";
import ReportHelper from "../../helpers/report.helper";
import { TableOfContents } from "./tableOfContents/tableOfContents.component";
import { LiverDiseaseAdvice } from "./liverDiseaseAdvice/liverDiseaseAdvice.component";
import { TestosteroneAdvice } from "./testosteroneAdvice/testosteroneAdvice.component";
import { VitaminB12Advice } from "./vitaminB12Advice/vitaminB12Advice.component";
import { VitaminB9Advice } from "./vitaminB9Advice/vitaminB9Advice.component";
import { HypotensionAdvice } from "./hypotensionAdvice/hypotensionAdvice.component";
import { GeneticCardioAdvice } from "./geneticCardioAdvice/geneticCardioAdvice.component";
import { AnemiaAdvice } from "./anemiaAdvice/anemiaAdvice.component";
import { SHBGCheck } from "./shbgCheck/shbgCheck.component";
import { HyperthyroidismAdvice } from "./hyperthyroidismAdvice/hyperthyroidismAdvice.component";
import { HypothyroidismAdvice } from "./hypothyroidismAdvice/hypothyroidismAdvice.component.js";
import { DehydrationAdvice } from "./dehydrationAdvice/dehydrationAdvice.component";
import { LowRhrAdvice } from "./lowRhrAdvice/lowRhrAdvice.component";
import { GilbertsSyndromeAdvice } from "./gilbertsSyndromAdvice/gilbertsSyndromAdvice.component";
import { UricAcidAdvice } from "./uricAcidAdvice/uricAcidAdvice.component";
import { StrengthAdvice } from "./strengthAdvice/strengthAdvice.component";
import { FixedTests } from "./fixedTests/fixedTests.component";
import { CreatineAdvice } from "./creatineAdvice/creatineAdvice.component";
import { FreeT4Advice } from "./freeT4Advice/freeT4Advice.component";
import { SmokingAdvice } from "./smokingAdvice/smokingAdvice.component.js"
import { IntakeModal } from "../intakeModal/intakeModal.component";
import { InflammationCheck } from "./inflammation/inflammationCheck.component";
import { HormoneCheck } from "./hormoneCheck/hormoneCheck.component";
import { HormoneCheckFemale } from "./hormoneCheckFemale/hormoneCheckFemale.component";
import { RhabdomyolysisAdvice } from "./rhabdomyolysisAdvice/rhabdomyolysisAdvice.component";
import { IsolatedTshAdvice } from "./isolatedTshAdvice/isolatedTshAdvice.component";
import { VitaminBAdvice } from "./vitaminBAdvice/vitaminBAdvice.component";
import { ViralInfectionAdvice } from "./viralInfectionAdvice/viralInfection.component";
import { HrtImpactAdvice } from "./hrtImpactAdvice/hrtImpactAdvice.component";
import { MetabolicSyndromeAdvice } from "./metabolicSyndromeAdvice/metabolicSyndromeAdvice.component";
import { SevereTgAdvice } from "./severeTgAdvice/severeTgAdvice.component";
import { CkdAdvice } from "./ckdAdvice/ckdAdvice.component.js";
import { LowWbcAdvice } from "./lowWbcAdvice/lowWbcAdvice.component.js";
import { HyponatremiaAdvice } from "./hyponatremiaAdvice/hyponatremiaAdvice.component.js";
import { ProstateAdvice } from "./prostateAdvice/prostateAdvice.component.js";
import { listAnnotatedReports } from "../../services/report.service.js";
import { listAnnotatedTests } from "../../services/test.service.js";
import { listAnnotatedResults } from "../../services/result.service.js";
import useWidth from "../../hooks/useWidth.hook.js";
import Role from "../../enums/role.enum.js";
import { getPatient } from "../../services/patient.service.js";
import { PlateletAdvice } from "./plateletAdvice/plateletAdvice.component.js";
import { Summary } from "./summary/summary.component.js";
import { SermImpactAdvice } from "./sermImpactAdvice/sermImpactAdvice.component.js";
import { AutoimmuneAdvice } from "./autoimmuneAdvice/autoimmuneAdvice.component.js";
import { VatAdvice } from "./vatAdvice/vatAdvice.component.js";
import { ExerciseImpactAdvice } from "./exerciseImpactAdvice/exerciseImpactAdvice.component.js";
import { CacAdvice } from "./cacAdvice/cacAdvice.component.js";
import { LipidAdvice } from "./lipidAdvice/lipidAdvice.component.js";
import { Omega3Advice } from "./omega3Advice/omega3Advice.component.js";
import { CortisolAdvice } from "./cortisolAdvice/cortisolAdvice.component.js";
import { PregantNutrientsAdvice } from "./pregnantNutrients/pregnantNutrients.component.js";
export const UniversalReport = ({ reportId }) => {
  const { height } = useWindowSize();
  const width = useWidth()
  const {
    token,
    currentUser
  } = useContext(UserContext);
  const { patientId } = useParams()
  const [api, contextHolder] = notification.useNotification();
  const [products, setProducts] = useState();
  const [report, setReport] = useState();
  const [hasConfetti, setHasConfetti] = useState();
  const [sectionMeta, setSectionMeta] = useState({})

  const [tests, setTests] = useState()
  const [results, setResults] = useState()
  const [user, setUser] = useState()
  const [reports, setReports] = useState()
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    fetchUser()
  }, [patientId, currentUser])

  useEffect(() => {
    initConfetti();
  }, [report]);

  useEffect(() => {
    fetchProducts();
  }, [user, report, results, tests]);

  useEffect(() => {
    if (!user || !currentUser) return
    
    document.title = user?._id !== currentUser?._id  ? `${user?.firstName} ${user?.lastName}'s Report` : "Instalab | Report"
  }, [user, currentUser]);
  
  useEffect(() => {
    fetchPatientData()
  }, [user])

  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
  
    if (hash && report) {
      requestAnimationFrame(() => {
        // Optionally, you could also use a setTimeout here 
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    }
  }, [report]);

  const fetchPatientData = async () => {
    if (!user) return
    setTests(await listAnnotatedTests({ patient: user._id }))
    setResults(await listAnnotatedResults({ patient: user._id }))
    
    let reportFilter = {
      patient: user._id
    }
    if (reportId) {
      reportFilter._id = reportId
    }
    const fetchedReports = await listAnnotatedReports({ filter: reportFilter })
    if (fetchedReports?.length) {
      setReport(fetchedReports[0])
      setReports(fetchedReports)
    }
  }

  const onReportChange = () => {
    fetchPatientData()
  }

  const onItemClick = () => {
    setDrawerVisible(false);
  };

  const fetchUser = async () => {
    if (!currentUser) return

    let params = {
      select: null,
      populate: [{
        path: 'customer',
        populate: {
          path: 'cards'
        }
      }, {
        path: 'memberships',
        populate: {
            path: "membershipType"
        }
      }, {
        path: "appointments",
        populate: [
          {
            path: "phlebotomist",
          },
          {
            path: "invites",
          },
          {
            path: "patients",
          },
        ],
      }]
    }

    if (currentUser.role === Role.PATIENT) {
      setUser(await getMe(params))
    }  else if (patientId) {
      setUser(await getPatient(patientId, params))
    }
  }

  const initConfetti = () => {
    if (!report || report.healthScore < 90) return false;
    const healthScoreDelta =
      report.healthScore - report.prevReport?.healthScore;
    if (report.prevReport && healthScoreDelta <= 0) return false;
    setHasConfetti(true);
    setTimeout(() => {
      setHasConfetti(false);
    }, 10000);
  };

  const fetchProducts = () => {
    if (!report || !results || !tests) return;
    setProducts(ReportHelper.getProducts(report, results, tests));
  };

  const getJSX = (section) => {
    // eslint-disable-next-line default-case
    const jsxParams = {
      report,
      setSectionMeta,
      section,
      reports,
      results,
      tests,
      user
    }
    switch (section?.code) {
      case ReportSection.HEALTH_SCORE:
        return <Summary {...jsxParams}/>
      case ReportSection.VITAMIN_D_ADVICE:
        return <VitaminDAdvice {...jsxParams} />
      case ReportSection.VITAMIN_B12_ADVICE:
        return <VitaminB12Advice {...jsxParams} />
      case ReportSection.STRENGTH_ADVICE:
        return <StrengthAdvice {...jsxParams} />
      case ReportSection.BP_ADVICE:
        return <BpAdvice {...jsxParams} />
      case ReportSection.TESTOSTERONE_ADVICE:
        return <TestosteroneAdvice {...jsxParams} />
      case ReportSection.LIVER_ADVICE:
        return <LiverDiseaseAdvice {...jsxParams} />
      case ReportSection.FIXED_TESTS:
        return <FixedTests {...jsxParams} />
      case ReportSection.VITAMIN_B9_ADVICE:
        return <VitaminB9Advice {...jsxParams} />
      case ReportSection.MULTIPLE_ABNORMAL_TESTS:
        return <MultipleAbnormalTests {...jsxParams} />
      case ReportSection.DEHYDRATED_ADVICE:
        return <DehydrationAdvice {...jsxParams} />
      case ReportSection.SINGLE_ABNORMAL_TEST:
        return <SingleAbnormalTest {...jsxParams} />
      case ReportSection.LOW_FERRITIN_ADVICE:
        return <LowFerritinAdvice {...jsxParams} />
      case ReportSection.URIC_ACID_ADVICE:
        return <UricAcidAdvice {...jsxParams} />
      case ReportSection.PROSTATE_ADVICE:
        return <ProstateAdvice {...jsxParams} />
      case ReportSection.LOW_RHR_ADVICE:
        return <LowRhrAdvice {...jsxParams} />
      case ReportSection.GILBERTS_SYNDROME:
        return <GilbertsSyndromeAdvice {...jsxParams} />
      case ReportSection.CKD_ADVICE:
        return <CkdAdvice {...jsxParams} />
      case ReportSection.FERRITIN_CHECK:
        return <FerritinCheck {...jsxParams} />
      case ReportSection.INFLAMMATION_CHECK:
        return <InflammationCheck {...jsxParams} />
      case ReportSection.PREGNANT_ADVICE:
        return <PregnantAdvice {...jsxParams} />
      case ReportSection.HYPOTENSION_ADVICE:
        return <HypotensionAdvice {...jsxParams} />
      case ReportSection.GENERAL_EXERCISE_ADVICE:
        return <GeneralExerciseAdvice {...jsxParams} />
      case ReportSection.VITAMIN_B_ADVICE:
        return <VitaminBAdvice {...jsxParams} />
      case ReportSection.ISOLATED_FREE_T4_ADVICE:
        return <FreeT4Advice {...jsxParams} />
      case ReportSection.HYPOTHYROIDISM_ADVICE:
        return <HypothyroidismAdvice {...jsxParams} />
      case ReportSection.SHBG_CHECK:
        return <SHBGCheck {...jsxParams} />
      case ReportSection.SEVERE_TG_ADVICE:
        return <SevereTgAdvice {...jsxParams} />
      case ReportSection.SMOKING_ADVICE:
        return <SmokingAdvice {...jsxParams} />
      case ReportSection.RHABDOMYOLYSIS_ADVICE:
        return <RhabdomyolysisAdvice {...jsxParams} />
      case ReportSection.HORMONE_CHECK:
        return <HormoneCheck {...jsxParams} />
      case ReportSection.VIRAL_INFECTION_ADVICE:
        return <ViralInfectionAdvice {...jsxParams} />
      case ReportSection.CREATINE_IMPACT:
        return <CreatineAdvice {...jsxParams} />
      case ReportSection.HRT_IMPACT:
        return <HrtImpactAdvice {...jsxParams} />
      case ReportSection.GENETIC_CARDIO_ADVICE:
        return <GeneticCardioAdvice {...jsxParams} />
      case ReportSection.HYPERTHYROIDISM_ADVICE:
        return <HyperthyroidismAdvice {...jsxParams} />
      case ReportSection.ISOLATED_TSH_ADVICE:
        return <IsolatedTshAdvice {...jsxParams} />
      case ReportSection.HORMONE_CHECK_FEMALE:
        return <HormoneCheckFemale {...jsxParams} />
      case ReportSection.LOW_WBC_COUNT:
        return <LowWbcAdvice {...jsxParams} />
      case ReportSection.HYPONATREMIA_ADVICE:
        return <HyponatremiaAdvice {...jsxParams} />
      case ReportSection.METABOLIC_SYNDROME_ADVICE:
        return <MetabolicSyndromeAdvice {...jsxParams} />
      case ReportSection.HOMOCYSTEINE_ADVICE:
        return <HomocysteineAdvice {...jsxParams} />
      case ReportSection.ANEMIA_ADVICE:
        return <AnemiaAdvice {...jsxParams} />
      case ReportSection.NEXT_STEPS:
        return <NextSteps {...jsxParams} />
      case ReportSection.METABOLIC_ADVICE:
        return <MetabolicAdvice {...jsxParams} />
      case ReportSection.CARDIO_ADVICE:
        return <CardioAdvice {...jsxParams} products={products} />
      case ReportSection.PLATELET_ADVICE:
        return <PlateletAdvice {...jsxParams} />
      case ReportSection.LIPID_ADVICE:
        return <LipidAdvice {...jsxParams} />
      case ReportSection.SERM_IMPACT:
        return <SermImpactAdvice {...jsxParams}/>
      case ReportSection.AUTOIMMUNE_ADVICE:
        return <AutoimmuneAdvice {...jsxParams}/>
      case ReportSection.VAT_ADVICE:
        return <VatAdvice {...jsxParams}/>
      case ReportSection.INTENSE_EXERCISE_IMPACT:
        return <ExerciseImpactAdvice {...jsxParams}/>
      case ReportSection.HIGH_CAC_ADVICE:
        return <CacAdvice {...jsxParams}/>
      case ReportSection.OMEGA_3_ADVICE:
        return <Omega3Advice {...jsxParams}/>
      case ReportSection.CORTISOL_ADVICE:
        return <CortisolAdvice {...jsxParams}/>
      case ReportSection.PREGNANT_NUTRIENTS:
        return <PregantNutrientsAdvice {...jsxParams}/>
      default:
        break;
    }
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    (report?.sections?.length>0 || (currentUser?.role === Role.ADMIN || currentUser?.role === Role.PROVIDER)) ?
    <div className="universal-report-container">
      <Row>
        <Col
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 0 }}
          lg={{ span: 0 }}
          xl={{ span: 4 }}
          xxl={{ span: 4 }}
        >
          {report && (
            <TableOfContents 
              sections={report.sections.filter(({show}) => show)} 
              sectionMeta={sectionMeta}
              report={report}
              onReportChange={onReportChange}
            />
          )}
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 20 }}
          xxl={{ span: 20 }}
        >
          <div className="mobile-toc-button mobile-only">
            <a onClick={toggleDrawer}><MenuOutlined /></a>
          </div>

          <Drawer
            title="Table of Contents"
            placement="left"
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
          >
            {report && (
              <TableOfContents 
                sections={report.sections.filter(({show}) => show)} 
                sectionMeta={sectionMeta}
                report={report}
                onReportChange={onReportChange}
                onItemClick={onItemClick}
              />
            )}
          </Drawer>

          <div className="universal-report">
            {token && (
              <IntakeModal autoOpen={false} />
            )}

            {report && (
              <>
                {contextHolder}

                {report.sections.map((section, index) => (section.show=== true && 
                  <div
                    key={`report-section-${index}`}
                    id={section.code}
                    className={classNames(
                      "report-section-container",
                      index === report.sections.length - 1 &&
                        "last-report-section-container"
                    )}
                  >
                    <div className="report-section">{getJSX(section)}</div>
                  </div>
                ))}

                {hasConfetti && (
                  <Confetti
                    width={width}
                    height={height}
                    colors={[Color.success, Color.success_bg]}
                  />
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
    :
    <div className="coming-soon">      
      <Spin tip="Loading Report" size="large" style={{color:"green", marginTop: 50}}>
          <div className="content" />
      </Spin>
    </div>
  );
};
