import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const getData = async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/heart`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const listEvents = async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/heart/events`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const listPatients = async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/heart/patients`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}