import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import { UserContext } from '../../contexts/user.context.js';
import './resetPassword.scss'
import { ForgotPasswordModal } from '../forgotPasswordModal/forgotPasswordModal.component.js';
import { resetPassword } from '../../services/password.service.js';
import StorageKey from '../../enums/storageKey.enum.js';

const { Item } = Form

export const ResetPassword = () => {
  const [form] = Form.useForm()
  const { id } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState()
  const [openModal, setOpenModal] = useState()
  const { token, setToken } = useContext(UserContext)

  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [token])

  const onFinish = async () => {
    setIsLoading(true)
    try {
      const response = await resetPassword(id, form.getFieldsValue())
      localStorage.setItem(StorageKey.TOKEN, response.token)
      setToken(response.token)
      message.info('Password reset')
    } catch (err) {
      console.error(err)
      message.error('Failed to reset password')
    }
    setIsLoading(false)
  };

  return <div className="reset-password">
    <ForgotPasswordModal
      open={openModal}
      setOpen={setOpenModal}
    />

    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className="reset-password-form"
    >
      <Item 
        label="New Password"
        name="password"
        rules={[{ 
          required: true, 
          message: 'Enter password'
        }]}
      >
        <Input placeholder="Enter new password..." type="password" />
      </Item>

      <Item>
        <Button 
          htmlType='submit'
          type="primary" 
          loading={isLoading}
        >
          Reset Password
        </Button>
      </Item>
    </Form>
  </div>
}