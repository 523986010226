import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabBar, ActionSheet } from 'antd-mobile';
import {
  HeartOutline,
  UnorderedListOutline,
  MessageOutline,
  GiftOutline,
  AddCircleOutline
} from 'antd-mobile-icons';
import { UserContext } from "../../contexts/user.context.js";
import FlowType  from "../../enums/flowType.enum.js";
import "./footer.scss";
import MembershipHelper from "../../helpers/membership.helper.js";

export const Footer = ({  hasMobile = true }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(''); // Set no tab active by default
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const { token, counts, instalabMembership, memberships } = useContext(UserContext);
  const [actionSheetHandler, setActionSheetHandler] = useState(null);

  const showHealthActions = async () => {
    if (isActionSheetOpen) {
      actionSheetHandler?.close();
      setIsActionSheetOpen(false);
      return;
    }

    setSelectedTab(''); // Clear selected tab when showing action sheet
    const actions = [ 
      { text: 'Test Results', disabled: counts.results?.toLocaleString() === 0, key: 'results', onClick: () => navigate('/patient/results') },
      { text: 'Latest Report', disabled: counts.reports === 0, key: 'report', onClick: () => navigate('/patient/report') },
      { text: 'Game Plan', disabled: counts.reports === 0, key: 'plan', onClick: () => navigate('/patient/plan') },
      { text: 'Health Profile', key: 'medical', onClick: () => navigate('/patient/medical') },
      { text: 'Health Files', key: 'files', onClick: () => navigate('/patient/files') },
    ];

    setIsActionSheetOpen(true);
    const handler = ActionSheet.show({
      actions,
      closeOnAction: true,
      onClose: () => setIsActionSheetOpen(false),
      safeArea: true,
      cancelText: 'Cancel',
      visible: true
    });
    setActionSheetHandler(handler);
  };

  const showOrdersActions = async () => {
    if (isActionSheetOpen) {
      actionSheetHandler?.close();
      setIsActionSheetOpen(false);
      return;
    }

    setSelectedTab(''); // Clear selected tab when showing action sheet
    const actions = [
      { text: 'Appointments', key: 'appointments', onClick: () => navigate('/patient/appointments') },
      { text: 'Test Kits', key: 'kits', onClick: () => navigate('/patient/kits') },
      { text: 'Consults', key: 'consults', onClick: () => navigate('/patient/consults') },
      { text: 'Prescriptions', key: 'treatments', onClick: () => navigate('/patient/treatments') },
      // { text: ' ', key: 'spacer', onClick: () => {}, disabled: true },
    ];


    setIsActionSheetOpen(true);
    const handler = ActionSheet.show({
      actions,
      closeOnAction: true,
      onClose: () => setIsActionSheetOpen(false),
      cancelText: 'Cancel',
      safeArea: true,
      visible: true
    });
    setActionSheetHandler(handler);
  };


  const onTabChange = (key) => {
    if (key !== 'myHealth' && key !== 'orders') {
      setSelectedTab(key);
    }

    if (key === 'help') {
      navigate('/help');
    } else if (key === 'gift') {
      navigate(`/flow/${FlowType.GIFT}`);
    } else if (key === 'care') {
      navigate('/catalog');
      setSelectedTab('care');
    } else if (key === 'orders') {
      showOrdersActions();
    } else if (key === 'myHealth') {
      showHealthActions();
    }
  };

  return token && hasMobile && (
    <TabBar activeKey={selectedTab} onChange={onTabChange} className="fixed-footer">
      <TabBar.Item
        key="orders"
        icon={<UnorderedListOutline />}
        title="My Orders"
      />
      <TabBar.Item
        key="myHealth"
        icon={<HeartOutline />}
        title="My Health"
      />
      <TabBar.Item
        key="care"
        icon={<AddCircleOutline />}
        title="Order Test"
      />
      <TabBar.Item
        key="gift"
        icon={<GiftOutline />}
        title="Gift"
      />
      <TabBar.Item
        key="help"
        icon={<MessageOutline />}
        title="Help"
      />
    </TabBar>
  );
};