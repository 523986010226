import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Spin, message } from 'antd'
import './impersonate.scss'
import { useEffect } from 'react'
import { loginAsUser } from '../../services/impersonation.service'
import { useContext } from 'react'
import { UserContext } from '../../contexts/user.context'
import StorageKey from '../../enums/storageKey.enum.js';

export const Impersonate = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { setToken, currentUser } = useContext(UserContext)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    onImpersonate()
  }, [id])

  useEffect(() => {
    if (currentUser) {
      const redirect = searchParams.get('redirect')
      navigate(redirect || '/')
    }
  }, [currentUser])

  const onImpersonate = async () => {
    if (!id) return null

    try {
      const response = await loginAsUser(id)
      localStorage.setItem(StorageKey.TOKEN, response.token)
      setToken(response.token)
      message.info('Logged in')
    } catch (err) {
      message.error('Failed to log in')
    }
  }

  return (
    <div className="impersonate">
      <Spin tip='Logging in...' />
    </div>
  )
}