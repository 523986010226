import { ReactComponent as AmexIcon } from '../assets/svg/amex.svg'
import { ReactComponent as DinersClubIcon } from '../assets/svg/diners_club.svg'
import { ReactComponent as DiscoverIcon } from '../assets/svg/discover.svg'
import { ReactComponent as JCBIcon } from '../assets/svg/jcb.svg'
import { ReactComponent as MastercardIcon } from '../assets/svg/mastercard.svg'
import { ReactComponent as VisaIcon } from '../assets/svg/visa.svg'
import { ReactComponent as UnionPayIcon } from '../assets/svg/unionpay.svg'
import { ReactComponent as CartesBancairesIcon } from '../assets/svg/cartes_bancaires.svg'
import { ReactComponent as KlarnaIcon } from '../assets/svg/klarna-white.svg'
import { ReactComponent as ApplePayIcon } from '../assets/svg/apple_pay.svg'
import { ReactComponent as CashAppIcon } from '../assets/svg/cashapp-badge.svg'
import { ReactComponent as CreditCardIcon } from '../assets/svg/card-generic.svg'

export const cardLogos = {
  american_express: <AmexIcon />,
  amex: <AmexIcon />,
  diners_club: <DinersClubIcon />,
  discover: <DiscoverIcon />,
  jcb: <JCBIcon />,
  mastercard: <MastercardIcon />,
  visa: <VisaIcon />,
  unionpay: <UnionPayIcon />,
  cartes_bancaires: <CartesBancairesIcon />,
  klarna: <KlarnaIcon />,
  apple_pay: <ApplePayIcon />,
  cashapp: <CashAppIcon />,
  card: <CreditCardIcon />
} 