import { Button, Image, Col, Row } from "antd";
import { EventType, Breakpoint, MembershipTypeCode, FlowType } from "../../../enums/index.enum";
import { addEvent } from "../../../services/event.service";
import { CalendarOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react';
import { listReferralsByPatientAndType } from "../../../services/referral.service"; 
import "./referralBox.scss";
import MembershipHelper from "../../../helpers/membership.helper";

const width = 800;

export const ReferralBox = ({ referralType, patient, style }) => {
  const [referral, setReferral] = useState(null);

  useEffect(() => {
    const fetchReferral = async () => {
      try {
        const referrals = await listReferralsByPatientAndType(patient._id, referralType);
        if (referrals.length > 0) {
          setReferral(referrals[0]); // Assuming you want the first referral of the given type
        }
      } catch (error) {
        console.error("Failed to fetch referral:", error);
      }
    };

    fetchReferral();
  }, [patient, referralType]);

  const onSchedule = async (url) => {
    await addEvent({ eventType: EventType.CLICK_REFERRAL, meta: { url: url } });
    window.open(url, "_blank");
  };

  const ScheduleButton = ({ scheduleLink, scheduleText }) => (
    <Button
      type="primary"
      icon={(width > Breakpoint.SM) ? <CalendarOutlined /> : null}
      onClick={() => onSchedule(scheduleLink)}
      className="advice-buy-btn"
      style={{ paddingRight: width <= Breakpoint.SM && 20, paddingLeft: width <= Breakpoint.SM && 20 }}
    >
      {scheduleText}
    </Button>
  );

  const isMember =  MembershipHelper.getActiveMembership(patient.memberships, MembershipTypeCode.LONGEVITY)

  return (
    referral ? 
      <div className="product-box" style={style}>
        <div className="product-box-header">{referralType} Referral</div>
        <Row className="product-box-main">
          {referral.image && 
          <Col span={4}>
            <Image src={referral.image} preview={false}/>    
          </Col>}

          <Col span={referral.image ? 18 : 24} push={referral.image ? 2 : 0}>
            <div className="product-title">{referral.provider.npi && "Dr. "} {referral.provider.firstName} {referral.provider.lastName}</div>
            <div className="product-directions">Meet with {referral.provider.npi && "Dr. "} {referral.provider.firstName} {referral.provider.lastName}, who will review your results, answer your questions, and develop a go-forward plan for you.</div>
            <div className="product-buy">
              {referral.provider.scheduleLink && (
                <ScheduleButton scheduleLink={referral.provider.scheduleLink} scheduleText={"Schedule Now"} />
              )}
            </div>
          </Col>
        </Row>
      </div>

      :

     !isMember ? 
      <div className="product-box" style={style}>
        <div className="product-box-header">{referralType} Referral</div>
        <div className="product-box-main">

            <div className="product-title">Need a referral?</div>
            <div className="product-directions">One benefit of Instalab membership is having a dedicated care concierge to help you find and schedule specialist appointments.</div>
            <div className="product-buy">        
                <ScheduleButton scheduleLink={`/flow/${FlowType.REFERRAL}`} scheduleText={"Request Referral"} />            
            </div>
        </div>
      </div>
      :
      <div className="product-box" style={style}>
        <div className="product-box-header">{referralType} Referral</div>
        <div className="product-box-main">

            <div className="product-title">Get Referral from Instalab</div>
            <div className="product-directions">As a member, Instalab will help you find and schedule any specialist appointments. Email concierge and we'll get right on top of it.</div>
            <div className="product-buy">
            <div className="product-buy">
        
                <ScheduleButton scheduleLink={`/flow/${FlowType.REFERRAL}`} scheduleText={"Request Referral"} />            

              
            </div>
        </div>
      </div>
    </div>

  );
};
