import { useState, useEffect } from "react";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { ReportSection, LowercaseTest } from "../../../enums/index.enum";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

import "./lowWbcAdvice.scss";

const converter = require("number-to-words");
const { Paragraph } = Typography;

export const LowWbcAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [wbcTests, setWbcTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setWbcTests(getWbcTests());
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, tests, wbcTests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  const getWbcTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(report, ReportSection.LOW_WBC_COUNT, tests);
  };


  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !tests || !wbcTests) return null;

    return  <>         
      {wbcTests.length === 1 
        ? <>Your <span>{LowercaseTest[wbcTests[0].code]}</span> level indicates</>
        : <>These{" "}<span>{converter.toWords(wbcTests.length)} biomarkers</span>{" "}indicate</>
      }{" "}potential <span>immune deficiency</span>.
    </>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report) return;

    return <>
    White blood cells are a crucial part of your immune system. They fight infections and protect the body against illness and disease. When your levels are low, your body's ability to fight infections is compromised.
    <br/><br/>
    While white blood cell counts can temporarily flucutate from acute factors, a sustained decrease over time can be a symptom of various underlying conditions. This deserves your primary care provider's attention.
</>

  }

  return (
    wbcTests && (
      <div className="low-wbc-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{description}</Paragraph>
        <TestView 
          report={report} 
          tests={wbcTests}
          results={results}
          reports={reports}
          user={user}
        />
        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};