import { useState } from 'react'
import { TestModal } from '../../testModal/testModal.component'

export const TestLink = ({ 
  content, 
  test,
  report,
  tests,
  results,
  reports
}) => {
  const [openModal, setOpenModal] = useState()
  const [testId, setTestId] = useState(test?._id)

  return (
    <>
      <a onClick={() => setOpenModal(true)}>
        {content}
      </a>
      <TestModal
        open={openModal}
        setOpen={setOpenModal}
        testId={testId}
        setTestId={setTestId}
        report={report}
        tests={tests}
        results={results}
        reports={reports}
      />
    </>
  )
}