import React, { useContext, useEffect, useState } from 'react';
import { Button, Avatar, Tooltip, message, Modal } from 'antd'
import { listStaffs, removeStaff } from "../../services/staff.service"
import { KeyOutlined, DeleteOutlined, EditOutlined, LockOutlined, EnvironmentOutlined } from '@ant-design/icons'
import './adminStaffs.scss'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { StaffForm } from '../staffForm/staffForm.component';
import { ChangePasswordModal } from '../changePasswordModal/changePasswordModal.component';
import { addImpersonation } from '../../services/impersonation.service';
import copy from 'copy-to-clipboard';
import ObjectType from '../../enums/objectType.enum';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { UserContext } from '../../contexts/user.context.js';

const select = '_id firstName lastName email location photo'
const populate = []

export const AdminStaffs = () => {
  const [staffs, setStaffs] = useState([])
  const [staffId, setStaffId] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isEditModalOpen, setIsEditModalOpen] = useState()
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState()
  const [isAdminLocationModalOpen, setIsLocalModalOpen] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const { setCounts } = useContext(UserContext)

  useEffect(() => {
    document.title = 'Instalab | Staff'
    fetchStaffs()
  }, [])

  const fetchStaffs = async () => {
    setIsLoading(true)
    const fetchedStaffs = await listStaffs({
      select,
      filter: {},
      populate,
      sort: '-createdAt'
    })
    setStaffs(fetchedStaffs)
    setFilteredCount(fetchedStaffs.length)
    setIsLoading(false)
  }

  const onImpersonate = async (_id) => {
    try {
      const impersonate = await addImpersonation({ user: _id })
      copy(`${process.env.REACT_APP_CLIENT_URL}/impersonate/${impersonate._id}`)
      message.success('Copied login link')
    } catch (err) {
      message.error('Failed to generate login link')   
    }
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this staff?',
      content: 'This action cannot be undone and will permanently remove the staff from your system.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removeStaff(_id);
          setStaffs(staffs.filter(staff => staff._id !== _id));
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              staff: cachedCounts.staff - 1
            }
          })
          setFilteredCount(cachedFilterCount => cachedFilterCount-1)
          message.info('Staff removed');
        } catch (err) {
          message.error('Failed to remove staff');
        }
      }
    });
  }

  const getActionItems = ({ _id }) => [
    {
    key: 'impersonate',
    label: (
      <Button onClick={() => onImpersonate(_id)}>
        <KeyOutlined /> Impersonate
      </Button>
    ),
    }, {
    key: '1',
    label: (
      <Button onClick={() => {
        setStaffId(_id)
        setIsEditModalOpen(true)
      }}>
        <EditOutlined /> Edit
      </Button>
    )
  },{
    key: '2',
    label: (
      <Button onClick={() => {
        setStaffId(_id)
        setIsLocalModalOpen(true)
      }}>
        <EnvironmentOutlined /> Set Location
      </Button>
    )
  }, {
    key: '3',
    label: (
      <Button onClick={() => {
        setStaffId(_id)
        setIsPasswordModalOpen(true)
      }}>
        <LockOutlined /> Change Password
      </Button>
    )
  }, {
    type: 'divider'
  }, {
    key: '4',
    label: (
      <Button 
        onClick={() => onRemove(_id)}
        className="remove-item"
      >
        <DeleteOutlined /> Remove
      </Button>
    )
  }]

  const getCustomFilter = (staff, value) => {
    return {
      location: () => staff.location?.city && staff.location.city.toLowerCase().includes(value.toLowerCase()),
      firstName: () => `${staff.firstName} ${staff.lastName}`.toLowerCase().includes(value.toLowerCase()),
    }
  }

  return (
    <div className="admin-staffs">
      <PageHeader
        title='Staff'
        count={filteredCount}
        actions={(
          <Button
            type='primary'
            onClick={() => {
              setStaffId(null)
              setIsEditModalOpen(true)
            }}
          >
            + Add New Staff
          </Button>
        )}
      />

      <AdminLocationModal
        objectId={staffId}
        objectType={ObjectType.STAFF}
        open={isAdminLocationModalOpen}
        setOpen={setIsLocalModalOpen}
        select={select}
        populate={populate}
        onSuccess={staff => {
          setStaffs(staffs.map(p => p._id === staffId ? staff : p))
        }}
      />

      <StaffForm
        staffId={staffId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        select={select}
        populate={populate}
        onSuccess={staff => {
          if (staffId) {
            setStaffs(staffs.map(p => p._id === staffId ? staff : p))
          } else {
            setStaffs([
              staff,
              ...staffs
            ])
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                staff: cachedCounts.staff + 1
              }
            })
            setFilteredCount(cachedFilterCount => cachedFilterCount+1)
          }
        }}
      />

      <ChangePasswordModal
        objectId={staffId}
        objectType={ObjectType.STAFF}
        open={isPasswordModalOpen}
        setOpen={setIsPasswordModalOpen}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={staffs}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[
        //   {
        //   dataIndex: 'photo',
        //   className: "photo-col",
        //   width: 50,
        //   render: photo => photo && <Avatar className="user-photo" src={`${photo}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} />
        // }, 
        {
          title: 'Name',
          dataIndex: 'firstName',
          render: (firstName, {lastName }) => `${firstName} ${lastName}`,
          filterDropdownType: FilterDropdownType.INPUT,
        }, {
          title: 'Email Address',
          dataIndex: 'email',
          filterDropdownType: FilterDropdownType.INPUT,
        }, {
          title: 'Location',
          dataIndex: 'location',
          filterDropdownType: FilterDropdownType.INPUT,
          render: (location) => {
            if (!location) return null
            const { streetAddress, streetAddress2, city, state, postalCode } = location
            const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
            return <Tooltip title={fullAddress}>{city}</Tooltip>
          }
        }]}
      />
    </div>
  )
}