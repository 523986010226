const LocationHelper = {
  getLocation: (location) => {
    if (!location) return null
    const {
      streetAddress,
      streetAddress2,
      city,
      state,
      postalCode,
    } = location
    let address = streetAddress
    if (streetAddress2) address += ` ${streetAddress2}`
    return `${address}, ${city}, ${state} ${postalCode}`
  }
}

export default LocationHelper