import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listMemberships = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/memberships`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const getMembership = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/memberships/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  
  return response.data
}

export const addMembership = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/memberships`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const cancelMembership = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/memberships/${id}/cancel`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const resumeMembership = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/memberships/${id}/resume`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const approveMembership = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/memberships/${id}/approve`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const changeMembership = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/memberships/change`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders(),
  });
  return response.data;
};

export const getFreeMonthsOnDowngrade = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/memberships/calculate-free-months`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders(),
  });
  return response.data;
};

export const updateSubscriptionCard = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/memberships/update-subscription-card`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders(),
  });
  return response.data;
};