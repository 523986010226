import { Modal, Select, Input, Form, Checkbox, message, Row, Col } from 'antd'
import { listPatients } from '../../services/patient.service';
import PrescriptionType from '../../enums/prescriptionType.enum';
import { useState, useEffect } from 'react'
import './prescriptionForm.scss'
import { addPrescription, getContents, getPrescription, updatePrescription } from '../../services/prescription.service';
import { ContinueButton } from '../continueButton/continueButton.component';
import Org from '../../enums/org.enum';
import DateInput from '../dateInput/dateInput.component';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

const { Item } = Form

export const PrescriptionForm = ({ open, setOpen, onSuccess, patientId, prescriptionId }) => {
  const [patients, setPatients] = useState([])
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()

  useEffect(() => {
    if (!patientId) {
      fetchPatients()
    }
  }, [patientId])

  useEffect(() => {
    if (open && prescriptionId) {
      fetchPrescription()
    } else {
      form.resetFields()
    }
  }, [open, prescriptionId])

  useEffect(() => {
    if (!open) {
      form.resetFields()
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const fetchPatients = async () => {
    const response = await listPatients({
      select: '_id firstName lastName',
      filter: {
        org: Org.INSTALAB,
        firstName: {
          $ne: null
        },
        email: {
          $ne: null
        }
      },
    })
    setPatients(response.filter(({ firstName, lastName }) => firstName && lastName))
  }

  const fetchPrescription = async () => {
    try {
      const prescription = await getPrescription(prescriptionId)
      form.setFieldsValue({
        patient: prescription.patient._id,
        type: prescription.type,
        contentsType: prescription.contents.type,
        units: prescription.contents.units,
        doseQty: prescription.contents.doseQty,
        sig: prescription.contents.sig,
        daysToRefill: prescription.daysToRefill,
        isSelfManaged: prescription.isSelfManaged,
        isGogo: prescription.isGogo,
        hasRefill: prescription.hasRefill,
        isPickupOrder: prescription.isPickupOrder,
        deliveredAt: prescription.deliveredAt ? 
          dayjs(prescription.deliveredAt)
            .tz('America/New_York')
            .format('MM/DD/YYYY') 
          : null
      })
    } catch (err) {
      console.log(err)
      message.error('Failed to load prescription')
    }
  }
  
  const onOk = async () => {
    setIsSubmitting(true)
    try {
      let values = form.getFieldsValue()
      values.contents = {
        type: values.contentsType,
        units: values.units,
        doseQty: values.doseQty,
        sig: values.sig,
      }
      if (patientId) {
        values.patient = patientId
      }
      if (values.deliveredAt) {
        values.deliveredAt = dayjs.tz(values.deliveredAt, 'MM/DD/YYYY', 'America/New_York').toDate()
      }

      let updatedPrescription
      if (prescriptionId) {
        updatedPrescription = await updatePrescription(prescriptionId, values)
        message.success('Prescription updated')
      } else {
        updatedPrescription = await addPrescription(values)
        message.success('Prescription added')
      }

      onSuccess(updatedPrescription)
      setOpen(false)
      form.resetFields()
    } catch (err) {
      console.log(err)
      message.error(prescriptionId ? 'Failed to update prescription' : 'Failed to add prescription')
    }
    setIsSubmitting(false)
  }

  const onPrescriptionTypeSelect = async (prescriptionType) => {
    const contents = await getContents(prescriptionType)
    if (contents) {
      const {
        units,
        doseQty,
        sig,
        type: contentsType,
        daysToRefill,
        isGogo
      } = contents
      form.setFieldsValue({
        units,
        doseQty,
        sig,
        daysToRefill,
        contentsType,
        isGogo
      })
    }
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="prescription-form"
      title={prescriptionId ? "Edit Prescription" : "Add New Prescription"}
      width={500}
      footer={null}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onOk}
      >
        {!patientId && !prescriptionId && (
          <Item
            label="Patient"
            name="patient"
          >
            <Select
              placeholder="Select Patientc"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              options={patients?.map(({ _id, firstName, lastName }) => {
                return {
                  label: `${firstName} ${lastName}`,
                  value: _id,
                }
              })}
            />
          </Item>
        )}
        
        <Item
          name="type"
          label="Formula"
        >
          <Select
            placeholder="Select Formula"
            showSearch
            optionFilterProp="children"
            onSelect={onPrescriptionTypeSelect}
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            options={Object.values(PrescriptionType)
              .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
              .map(prescriptionType => {
                return {
                  label: prescriptionType,
                  value: prescriptionType,
                }
              })}
          />
        </Item>

        <div className="prescription-contents">
          <Row gutter={16}>
            <Col span={12}>
              <Item
                name="contentsType"
                label="Prescription Type"
              >
                <Select
                  placeholder="Select Prescription Type"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => 
                    option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
                  }
                  options={Object.values(PrescriptionType)
                    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                    .map(prescriptionType => {
                      return {
                        label: prescriptionType,
                        value: prescriptionType,
                      }
                    })}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="Units"
                name="units"
              >
                <Input
                  placeholder="Units"
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="Dose Quantity"
                name="doseQty"
              >
                <Input
                  placeholder="Dose Quantity"
                />
              </Item>
            </Col>

            <Col span={12}>
              <Item
                label="Days to Refill"
                name="daysToRefill"
              >
                <Input
                  placeholder="Days to Refill"
                />
              </Item>
            </Col>
          </Row>

          <Item
            label="Directions"
            name="sig"
          >
            <Input
              placeholder="Directions"
            />
          </Item>

        </div>

        <Item 
          label="GoGoMeds" 
          name="isGogo" 
          valuePropName="checked"
        >
          <Checkbox />
        </Item>

        <Item 
          label="Self-managed" 
          name="isSelfManaged" 
          valuePropName="checked"
        >
          <Checkbox />
        </Item>

        <Item 
          label="Has Refill" 
          name="hasRefill" 
          initialValue={true} 
          valuePropName="checked">
          <Checkbox />
        </Item>

        <Item 
          label="Pickup Order" 
          name="isPickupOrder" 
          initialValue={false} 
          valuePropName="checked">
          <Checkbox />
        </Item>

        <Item
          label="Delivery Date"
          name="deliveredAt"
        >
          <DateInput
            value={form.getFieldValue('deliveredAt') || null}
          />
        </Item>

        <ContinueButton
          text='Save Prescription'
          isSubmitting={isSubmitting}
        />
      </Form>
    </Modal>
  )
}