export const dosages = {
  "Alirocumab": [
    '75 mg',
    '150 mg',
  ],
  "Atorvastatin": [
    '10 mg',
    '20 mg',
    '40 mg',
    '80 mg',
  ],
  "Evolocumab": [
    '140 mg',
    '420 mg',
  ],
  "Ezetimibe": [
    '10 mg',
  ],
  "Lovastatin": [
    '20 mg',
    '40 mg',
    '60 mg',
    '80 mg',
  ],
  "Pitavastatin": [
    '1 mg',
    '2 mg',
    '4 mg',
  ],
  "Pravastatin": [
    '10 mg',
    '20 mg',
    '40 mg',
    '80 mg',
  ],
  "Rosuvastatin": [
    '5 mg',
    '10 mg',
    '20 mg',
    '40 mg',
  ],
  "Simvastatin": [
    '5 mg',
    '10 mg',
    '20 mg',
    '40 mg',
  ],
};