import qs from 'qs'

const QueryStripeHelper = {
  format: (params={}) => {
    return qs.stringify(params, { 
      skipNulls: false,
      skipEmptyString: false,
      strictNullHandling: true,
    });
  }
}

export default QueryStripeHelper