import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { UserContext } from "../../../contexts/user.context";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import BlogId from "../../../enums/blogId.enum";
import { BlogData } from "../../../data/blog.data";
import { useContext } from "react";
import { Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";

const { Paragraph } = Typography;

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const StatinPost = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { blogId } = useParams();

  const rosuvastatinData = {
    labels: ['1mg', '2.5mg', '5mg', '10mg', '20mg', '40mg', '80mg'],
    datasets: [
      {
        label: 'LDL Reduction (%)',
        data: [34.3, 40.7, 42.5, 50.5, 57.0, 62.6, 64.8],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'LDL Cholesterol Reduction by Rosuvastatin Dose'
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `LDL reduction: ${context.raw}%`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'LDL Reduction (%)'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Daily Dose'
        }
      }
    }
  };

  return (
    <div className={classNames("blog-post", "statin-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          {BlogData.find(b => b.id === blogId).title}
        </Paragraph>

        <Paragraph className="blog-subheading">
          {BlogData.find(b => b.id === blogId).description}
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          How Do Statins Work?
        </Paragraph>
        <Paragraph className="blog-description">
          Statins lower cholesterol by inhibiting <i>HMG-CoA reductase</i>, an enzyme critical for cholesterol production in the liver. This inhibition triggers an increase in LDL receptor activity, enhancing the liver's ability to clear cholesterol from the bloodstream.
        </Paragraph>

        <Paragraph className="blog-title">
          How Effective Are Statins?
        </Paragraph>
        <Paragraph className="blog-description">
          Statins are remarkably effective at lowering LDL cholesterol, but the relationship between dosage and results is not straightforward. The most significant impact happens with the initial dose—just <b>1 mg of rosuvastatin can reduce LDL levels by about 34%</b>. Beyond that, as the dose increases, the additional cholesterol reduction becomes less pronounced.
        </Paragraph>

        <div style={{ maxWidth: '800px', margin: '20px auto' }}>
          <Bar data={rosuvastatinData} options={options} />
        </div>

        <Paragraph className="blog-description">
          This phenomenon of diminishing returns highlights the value of starting with a low dose, which often achieves substantial results while minimizing the risk of side effects. For those needing further reductions, combining rosuvastatin with other medications like ezetimibe can be a more effective and safer option than increasing the statin dose.
        </Paragraph>

        <Paragraph className="blog-title">
          Muscle Pain
        </Paragraph>
        <Paragraph className="blog-description">
          Muscle pain is the most commonly discussed side effect of statins, but it's important to put the risk into perspective. Despite the attention this issue receives, statin-associated muscle pain affects only a small fraction of users—about <b>5%</b> overall. 
        </Paragraph>
        <Paragraph className="blog-description">
          Even in large clinical trials, the actual risk appears modest. For example, in the <a className="blog-link" onClick={() => window.open('https://doi.org/10.1016/j.amjcard.2009.07.033', '_blank')}>JUPITER trial</a>, muscle pain was reported at a rate of <b>4.1 events</b> per 100 person-years among rosuvastatin users, compared to <b>3.7 events</b> per 100 person-years in the placebo group. This small difference suggests that the vast majority of statin users do not experience significant muscle symptoms.
        </Paragraph>
        <Paragraph className="blog-description">
          Choosing the right statin also matters. <a className="blog-link" onClick={() => window.open('https://doi.org/10.3329/UHJ.V14I1.38481', '_blank')}>Research</a> shows that rosuvastatin is associated with significantly fewer reports of muscle pain than atorvastatin—<b>4.38%</b> versus <b>14.21%</b>, respectively. By starting with a low dose of rosuvastatin and gradually adjusting if necessary, the risk of muscle-related side effects can be minimized even further.
        </Paragraph>

        <Paragraph className="blog-title">
          Diabetes Risk
        </Paragraph>

        <Paragraph className="blog-description">
          Statins are associated with a <b>9% to 13%</b> increased risk of developing new-onset diabetes (NOD).
        </Paragraph>
        <Paragraph className="blog-description">
          Those with existing insulin resistance or prediabetes are particularly vulnerable and may experience an accelerated progression to diabetes. For these high-risk individuals, starting with a low dose of statins—such as <b>2.5 mg</b> of rosuvastatin—can help minimize this risk, as diabetes progression is more pronounced with high-intensity statin therapy. 
        </Paragraph>
        <Paragraph className="blog-description">
          Through careful monitoring of blood sugar trends, those who are predisposed to diabetes can also benefit from the cardiovascular protection of statins while minimizing their impact on metabolic health.
        </Paragraph>

        <Paragraph className="blog-title">
          Liver Effects
        </Paragraph>
        <Paragraph className="blog-description">
          While statins can cause a mild increase in liver enzymes—known as transaminitis—this effect is typically asymptomatic and dose-related. For most patients, these enzyme elevations do not indicate liver damage and often resolve on their own without any changes to the medication.
        </Paragraph>
        
        <Paragraph className="blog-description">
          In fact, <a className="blog-link" onClick={() => window.open('https://doi.org/10.2147/CLEP.S385712', '_blank')}>research</a> found that individuals with elevated liver enzymes due to statin therapy are no more likely to develop severe liver injury than those with normal levels. This underscores that statins are safe for most individuals, regardless of liver enzyme status. Regular monitoring can also provide peace of mind, ensuring that elevated liver enzymes never reach a concerning level.
        </Paragraph>

        <Paragraph className="blog-title">
          Final Thoughts
        </Paragraph>
        <Paragraph className="blog-description">
          Statins are a cornerstone of cardiovascular risk reduction, reducing cardiovascular mortality by <b>31%</b> and offering clear benefits for most patients. While side effects such as muscle pain and increased diabetes risk can occur, they are often manageable through careful dose selection and combination therapy. 
        </Paragraph>
        <Paragraph className="blog-description">
          If you're unsure whether statins are right for you, a personalized approach—like our <a className="blog-link" onClick={() => {
            PosthogHelper.track(currentUser, `[research] click heart health program link: ${blogId}`, true)
            navigate('/heart')
          }}>Heart Health Program</a>—can help you navigate these decisions.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Olsson AG, Pears J, McKellar J, Mizan J, Raza A. Effect of rosuvastatin on low-density lipoprotein cholesterol in patients with hypercholesterolemia. Am J Cardiol. 2001 Sep 1;88(5):504-8. <a onClick={() => window.open('https://doi.org/10.1016/s0002-9149(01)01727-1', '_blank')}>https://doi.org/10.1016/s0002-9149(01)01727-1</a>
            </li>
            <li>
              Chou, R., Dana, T., Blazina, I., Daeges, M., & Jeanne, T. (2016). Statins for Prevention of Cardiovascular Disease in Adults: Evidence Report and Systematic Review for the US Preventive Services Task Force. Journal of Vascular Surgery, 65, 925. <a onClick={() => window.open('https://doi.org/10.1001/jama.2015.15629', '_blank')}>https://doi.org/10.1001/jama.2015.15629</a>
            </li>
            <li>
              Jacobson, T. (2008). Toward "pain-free" statin prescribing: clinical algorithm for diagnosis and management of myalgia. Mayo Clinic proceedings, 83(6), 687-700. <a onClick={() => window.open('https://doi.org/10.4065/83.6.687', '_blank')}>https://doi.org/10.4065/83.6.687</a>
            </li>
            <li>
              Kappagoda, C., & Amsterdam, E. (2009). Another look at the results of the JUPITER trial. The American journal of cardiology, 104(11), 1603-5. <a onClick={() => window.open('https://doi.org/10.1016/j.amjcard.2009.07.033', '_blank')}>https://doi.org/10.1016/j.amjcard.2009.07.033</a>
            </li>
            <li>
              Rashed, M., Banerjee, S., Ahsan, S., Zaman, S., Ahmed, K., Rahman, M., Mahmood, M., Arzu, J., Adhikary, D., Rabbani, M., Amin, M., Haque, M., Mondol, M., Khaled, M., Kabir, M., Hossain, M., Amin, M., Samsuzzaman, S., Alam, M., Abedin, M., Khondoker, M., Hasan, A., & Hoque, H. (2018). A Comparative Study of Muscle Symptoms of Atorvastatin with Rosuvastatin in Patients of Atherosclerotic Cardiovascular Disease. University Heart Journal. <a onClick={() => window.open('https://doi.org/10.3329/UHJ.V14I1.38481', '_blank')}>https://doi.org/10.3329/UHJ.V14I1.38481</a>
            </li>
            <li>
              Mach, F., Ray, K., Wiklund, O., Corsini, A., Catapano, A., Bruckert, E., De Backer, G., Hegele, R., Hovingh, G., Jacobson, T., Krauss, R., Laufs, U., Leiter, L., März, W., Nordestgaard, B., Raal, F., Roden, M., Santos, R., Stein, E., Stroes, E., Thompson, P., Tokgözoğlu, L., Vladutiu, G., Gencer, B., Stock, J., Ginsberg, H., & Chapman, M. (2018). Adverse effects of statin therapy: perception vs. the evidence – focus on glucose homeostasis, cognitive, renal and hepatic function, haemorrhagic stroke and cataract. European Heart Journal, 39, 2526 - 2539. <a onClick={() => window.open('https://doi.org/10.1093/eurheartj/ehy182', '_blank')}>https://doi.org/10.1093/eurheartj/ehy182</a>
            </li>
            <li>
              Verma, S., Huang, J., Hutchinson, H., Estevez, I., Kuang, K., Reynolds, S., & Schneeweiss, S. (2022). Statin Use and Severe Acute Liver Injury Among Patients with Elevated Alanine Aminotransferase. Clinical Epidemiology, 14, 1535 - 1545. <a onClick={() => window.open('https://doi.org/10.2147/CLEP.S385712', '_blank')}>https://doi.org/10.2147/CLEP.S385712</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};