import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import { ReadableRiskLevel, RiskLevel, ReportSection, TestCode, Condition, Supplement, MedicalCondition, QuestionTemplateCode } from "../../../enums/index.enum"
import ReportHelper from "../../../helpers/report.helper";
import { TestView } from "../testView/testView.component";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import "./pregnantNutrients.scss";

const converter = require("number-to-words");
const { Paragraph } = Typography;

export const PregantNutrientsAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [isPregnant, setIsPregnant] = useState();
  const [nutrientTests, setNutrientTests] = useState();

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, isPregnant, nutrientTests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  useEffect(() => {
    setIsPregnant(ReportHelper.hasCondition(report, Condition.PREGNANT));
    setNutrientTests(ReportHelper.getSectionSuboptimalTests(report, ReportSection.PREGNANT_NUTRIENTS, tests));
  }, [tests, results, report]);



  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
    if (!report || !nutrientTests) return null;
    
    return (
      <>
        These{" "}<span>{converter.toWords(nutrientTests?.length)} biomarkers</span>{" "}indicate nutrient deficiencies, which could impact your pregnancy.
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)
    if (!report) return null;

    const content = (
      <>
       Proper nutrient levels are essential during pregnancy as they support essential processes like brain development, bone growth, and immune function for both you and your baby.
        <br/><br/>Key nutrients such as folate, iron, and vitamin D are crucial for healthy fetal development, reducing the risk of birth defects, preterm birth, and low birth weight. Additionally, nutrients like vitamin B12 and ferritin (iron stores) help sustain maternal energy levels and prevent anemia, which is essential for a healthy pregnancy.
      </>
    );

    return (
      <>
        {content}
      </>
    );
  };


  return (
    user &&
     (
      <div className="pregnant-nutrients-advice">

        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        <TestView 
          report={report} 
          tests={nutrientTests}
          results={results}
          reports={reports}
          user={user}
        />


        <AdviceSection 
          report={report} 
          section={section}
          results={results}
          tests={tests}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
