import './comprehensiveTestIntro.scss'
import Breakpoint from "../../../../enums/breakpoint.enum";
import useWidth from '../../../../hooks/useWidth.hook';


const ComprehensiveTestIntro = ({ footer }) => {
  const width = useWidth()

  return (
    <div 
      className="comprehensive-test-intro"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >
      <div 
        className="test-intro-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        {footer({ 
          buttonText: "Let's go",
          buttonCheck: false,
          buttonStyle: {
            height: 50,
            fontSize: 18,
            width: '100%',
            marginTop: 40,
            display: 'block',
          },
          enterStyle: {
            display: 'none',
          }
        })}
      </div>
    </div>
  )
}

export default ComprehensiveTestIntro