import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState, useContext, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"

export const Step = {
  CONSULT: 'schedule',
  CONFIRM: 'confirm',
}

export const HeartMemberConsultFlow = () => {
  const { currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [flow])

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.CONSULT]: {
        type: StepType.CALENDLY,
        nextStep: Step.CONFIRM,
        addConsult: true,
        title: `Schedule your heart health consultation.`,
        description: `This session will be with Dr. Steven Winiarski who specializes in longevity medicine and lipidology. It'll be a 30 minute video call where he advises you on improving your progress with the Heart Health Program and answer any questions you have about implementing your strategy.`,
        showFooter: false,
        url: process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest' : 'https://calendly.com/d/3r9-gjk-xjv/heart-health-consultation',
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `Appointment confirmed!`,
        path: `/`,
        buttonText: 'Back to Dashboard',
        description: <>
          You'll receive an email with a calendar invite and a Zoom link for your consultation with Dr. Winiarski.
          <br/><br/>
          If you have any questions, please <a onClick={onChat}>send us a message</a>.
        </>
      },
    })
  }

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      startIndex={1}
    />
  )
}