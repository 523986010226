import { useState } from "react"
import { Typography } from "antd"
import './gilbertsSyndromeAdvice.scss'
import { TestView } from "../testView/testView.component";
import { useEffect } from "react"
import ReportHelper from "../../../helpers/report.helper"
import ReportSection from "../../../enums/reportSection.enum"
import { TestModal } from '../../testModal/testModal.component';
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography

export const GilbertsSyndromeAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [gilbertsSyndromeTests, setGilbertsSyndromeTests] = useState()
  const [openModal, setOpenModal] = useState()
  const [testId, setTestId] = useState()
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setGilbertsSyndromeTests(getGilbertsSyndromeTests())
  }, [report, tests])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report]);

  const getGilbertsSyndromeTests = () => {
    if (!report || !tests) return null
    return ReportHelper.getSectionTests(report, ReportSection.GILBERTS_SYNDROME, tests)
  }

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report) return null;
    return <>
      Your <span>elevated bilirubin</span> may be due to <span>Gilbert's syndrome</span>, a common genetic condition that affects your liver's ability to process bilirubin.
    </>
}

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report) return null;
    return <>
      Gilbert's syndrome involves a deficiency in an enzyme that helps process and remove bilirubin, a byproduct produced by the liver during the breakdown of blood cells. As a result, bilirubin can accumulate in your system, but it typically doesn’t harm the liver or other organs.
      <br/><br/>
      This condition generally requires no medical treatment, but consult with your physician if you want to confirm with a genetic test or if you experience jaundice, a yellowing of your skin or the whites of your eyes.
    </>
  }

  return gilbertsSyndromeTests && (
    <div className="gilberts-syndrome-advice">
      <TestModal
        open={openModal}
        setOpen={setOpenModal}
        testId={testId}
        setTestId={setTestId}
        report={report}
        tests={tests}
        results={results}
        reports={reports}
      />

      <Paragraph className="report-template-title">
        {title}
      </Paragraph>
      <Paragraph className="report-template-description">
        {description}
      </Paragraph>
      
      <TestView 
        report={report} 
        tests={gilbertsSyndromeTests} 
        results={results}
        user={user}
        reports={reports}
      />
    </div>
  )
}