import { useContext } from "react";
import { Typography } from "antd";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../contexts/user.context";
import PosthogHelper from "../../../helpers/posthog.helper";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import { BlogData } from "../../../data/blog.data";

const { Paragraph } = Typography

export const SoftPlaquePost = () => {
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)
  const { blogId } = useParams()

  return (
    <div className={classNames("blog-post", "soft-plaque-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          Why Soft Plaque is Dangerous: Understanding the Silent Threat to Your Heart Health
        </Paragraph>
        <Paragraph className="blog-subheading">
          When we think about heart health, many of us are familiar with the idea of "hardening" arteries or the buildup of calcium in the blood vessels. But there's another, more dangerous culprit lurking within your arteries that often goes unnoticed: soft plaque. Unlike its calcified counterpart, soft plaque can be more unpredictable and potentially life-threatening, even when you feel perfectly healthy.
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          What Is Soft Plaque?
        </Paragraph>
        <Paragraph className="blog-description">
          Soft plaque is a build-up of fatty deposits, cholesterol, and other substances in the walls of your arteries. Unlike hardened, calcified plaque, which can be seen easily on a <a className="blog-link" onClick={() => {
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: schedule cac`, true)
            navigate('/cac')
          }}>Coronary Artery Calcium (CAC) test</a>, soft plaque is more difficult to detect. It hasn't yet hardened with calcium deposits and remains a sticky, fatty mass attached to the artery walls.
        </Paragraph>

        <Paragraph className="blog-title">
          The Dangers of Soft Plaque
        </Paragraph>
        <Paragraph className="blog-description">
          Soft plaque may sound less concerning than calcified plaque, but the truth is, it's more dangerous for several reasons:
        </Paragraph>

        <ol className="blog-list">
          <li>
            <strong>Unpredictable Rupture:</strong> Soft plaque is more prone to rupture than hardened plaque. When it bursts, it can lead to the formation of a blood clot, which may completely block the artery and cause a heart attack or stroke. This happens suddenly, without warning, and is often the reason behind unexpected cardiovascular events in people with no previous symptoms.
          </li>
          <li>
            <strong>Silent Progression:</strong> One of the biggest dangers of soft plaque is that it can exist without showing up on the more common CAC test. Because it hasn't calcified yet, it's often missed during early screening, giving you a false sense of security while the plaque quietly grows and increases your risk for cardiovascular disease.
          </li>
          <li>
            <strong>Vulnerable to Inflammation:</strong> Soft plaque is more likely to cause inflammation in the arteries. Inflammation weakens the arterial walls, increasing the likelihood of rupture, which can lead to sudden and severe blockages in your heart or brain, resulting in a heart attack or stroke.
          </li>
          <li>
            <strong>Rapid Progression:</strong> While calcified plaque tends to develop slowly over time, soft plaque can progress more rapidly. This means that even if your last test results showed relatively low risk, new soft plaque buildup could elevate your risk within a short period.
          </li>
        </ol>
        <Paragraph className="blog-title">
          Detecting Soft Plaque
        </Paragraph>
        <Paragraph className="blog-description">
          Unlike CAC, which only detects calcified plaques, <a className="blog-link" onClick={() => {
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: schedule ccta`, true)
            navigate('/cleerly')
          }}>Coronary CT Angiography (CCTA)</a> is the best option for detecting soft plaque. CCTA provides a detailed view of both soft and calcified plaques, offering a fuller picture of your cardiovascular health. This test can help catch soft plaque buildup before it leads to severe complications.
        </Paragraph>

        <Paragraph className="blog-title">
          How to Reduce Soft Plaque Risk
        </Paragraph>
        <Paragraph className="blog-description">
          To minimize the risk of soft plaque:
        </Paragraph>

        <ol className="blog-list">
          <li>
            <strong>Adopt a Heart-Healthy Lifestyle:</strong> Regular exercise, a balanced diet, and avoiding smoking can help reduce plaque buildup.
          </li>
          <li>
            <strong>Medication:</strong> Statins and other cholesterol-lowering medications can help stabilize soft plaques and reduce their risk of rupture.
          </li>
          <li>
            <strong>Advanced Testing:</strong> Ask your doctor about more detailed tests like CCTA, which can detect soft plaque early on.
          </li>
        </ol>
      </div>
    </div>
  );
};