import { useState, useEffect, useRef } from 'react'
import { Form, Button, message, Input, Select, Modal } from "antd"
import "./invite.scss"
import { CheckCircleFilled } from '@ant-design/icons'
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { removeAppointmentInvite, removeAppointmentPatient, unjoinAppointment } from '../../../../services/appointment.service'
import { MaskedInput } from 'antd-mask-input'
import copy from 'copy-to-clipboard';
import { PatientForm } from '../../../patientForm/patientForm.component';

const { Item } = Form

export const Invite = ({
  onSubmit,
  form,
  isSubmitting,
  flow,
  step,
  setFlow,
}) => {
  const [removing, setRemoving] = useState([])
  const removingRef = useRef(null)
  removingRef.current = removing
  const [phones, setPhones] = useState([""])
  const inputRef = useRef()
  const [selectedOptions, setSelectedOptions] = useState([""]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  useEffect(() => {
    onRemovePhones()
  }, [flow])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const onRemovePhones = () => {
    if (!flow) return
    form.resetFields()
    setPhones([""])
  }

  const onPhoneChange = () => {
    const values = form.getFieldsValue()
    
    const newPhones = Object.entries(values)
      .filter(([key, value]) => key.startsWith('phone-') && value?.length)
      .map(([_, value]) => value)

    setPhones([
      ...newPhones,
      "",
    ])
  }

  const onRemove = async (inviteId) => {
    try {
      setRemoving([
        ...removingRef.current,
        inviteId
      ])

      const appointment = await removeAppointmentInvite(flow.appointment._id, inviteId, {
        select: '_id start location phlebType status invitePrice patients orders',
        populate: [{
          path: 'invites',
          select: '_id phone status'
        }]
      })

      setFlow(cachedFlow => {
        return {
          ...cachedFlow,
          appointment
        }
      })

    } catch (err) {
      console.log(err)
      message.error('Failed to remove invite')
    }
    setRemoving(removingRef.current.filter(i => i !== inviteId))
  }

  const inviteLink = `${process.env.REACT_APP_CLIENT_URL}/appointments/${flow.appointment?._id}/join`;
  const onCopyInviteLink = () => {  
    copy(inviteLink);
    message.info('Appointment invite link copied') 
  }

  const onSelectChange = (value, index) => {
    form.setFieldValue(`inviteOption-${index}`, value);
    
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = value;
    
    const currentlySelected = newSelectedOptions.filter(opt => opt !== "");
    const hasMoreOptions = step.inviteOptions.length > currentlySelected.length;
    
    if (index === selectedOptions.length - 1 && value && hasMoreOptions) {
      newSelectedOptions.push("");
    }
    setSelectedOptions(newSelectedOptions);
  };

  const getSelectedOptionsExcept = (currentIndex) => {
    return selectedOptions.filter((option, index) => 
      index !== currentIndex && option !== ""
    );
  };

  const showModal = () => {
    setIsModalOpen(true);
  };


  const onAddPatientSuccess = (response) => {
    // Add new patient to invite options
    step.inviteOptions = [...step.inviteOptions, response];
    
    // Find the first empty dropdown index
    const firstEmptyIndex = selectedOptions.findIndex(option => !option);
    
    // Create new selected options array
    const newSelectedOptions = [...selectedOptions];
    if (firstEmptyIndex !== -1) {
      // If there's an empty dropdown, use it
      newSelectedOptions[firstEmptyIndex] = response._id;
    } else {
      // If no empty dropdown exists, add to the end
      newSelectedOptions.push(response._id);
    }
    
    setSelectedOptions(newSelectedOptions);
    
    // Update the form field value
    if (firstEmptyIndex !== -1) {
      form.setFieldValue(`inviteOption-${firstEmptyIndex}`, response._id);
    } else {
      form.setFieldValue(`inviteOption-${selectedOptions.length}`, response._id);
    }
    
    setIsModalOpen(false);
  };

  const onRemovePatient = async (patientId) => {
    try {
      setRemoving([
        ...removingRef.current,
        patientId
      ])

      const appointment = await removeAppointmentPatient(flow.appointment._id, patientId, {
        select: '_id start location phlebType status invitePrice patients orders',
        populate: [{
          path: 'patients',
          select: '_id firstName lastName'
        },
        {
          path: 'invites',
          select: '_id phone status'
        }
      ]
      })
      
      message.success('Patient successfully removed')

      setFlow(cachedFlow => ({
        ...cachedFlow,
        appointment
      }))

    } catch (err) {
      console.log(err)
      message.error('Failed to remove patient')
    }
    setRemoving(removingRef.current.filter(i => i !== patientId))
  }

  return (
    <div className="invite">
      <h3 className="invite-section">Share this invite link:</h3>     
      <Item className="invite-item">
        <Input 
          type="text" 
          value={inviteLink} 
          readOnly 
          style={{width: "75%"}}
          className="invite-field"
        />
        <Button 
          onClick={onCopyInviteLink}
          className="invite-log"
          style={{width:"24%", display: "inline-block", marginLeft: "1%"}}
        >
          Copy
        </Button>
      </Item>
      


      <h3 className="invite-section">Send the invite link via SMS:</h3>

      <Form
        form={form}
        className="invite-item"
      >
        {flow?.appointment?.invites?.map(invite => (
          <div 
            key={`invite-${invite._id}`}
            className="invite-log"
          >
            <div className="invite-log-phone">
              <CheckCircleFilled className="invite-log-icon" /> 
              {invite.phone}
            </div>

            <Button
              className="invite-log-btn"
              onClick={() => onRemove(invite._id)}
              loading={removingRef.current?.some(inviteId => inviteId === invite._id)}
            >
              Remove
            </Button>
          </div>
        ))}
        {phones.map((phone, index) => (
          <Item 
            key={`phone-${index}`}
            name={`phone-${index}`}
            className="invite-item"
            validateTrigger='onSubmit'
          >
            <MaskedInput
              inputMode="numeric"
              placeholder="Type phone number here..."
              ref={index === 0 ? inputRef : null}
              className="invite-field"
              mask={'(000) 000-0000'}
              onChange={onPhoneChange}
            />
          </Item>
        ))}
 

      { step.inviteOptions && (
        <div className="invite-options">
          <h3 className="invite-section">Add your clients directly:</h3>

          {flow?.appointment?.patients?.length > 1 && (
          <>
            {flow.appointment.patients.slice(1).map(patient => (
              <div 
                key={`patient-${patient._id}`}
                className="invite-log"
              >
                <div className="invite-log-phone">
                  <CheckCircleFilled className="invite-log-icon" /> 
                  {patient.firstName} {patient.lastName}
                </div>

                <Button
                  className="invite-log-btn"
                  onClick={() => onRemovePatient(patient._id)}
                  loading={removingRef.current?.some(id => id === patient._id)}
                >
                  Remove
                </Button>
              </div>
            ))}
              </>
          )}

            {selectedOptions.map((selectedOption, index) => (
              <Item 
                key={`inviteOption-${index}`} 
                name={`inviteOption-${index}`}
                className="invite-item"
              >
                <div style={{ display: 'flex', gap: '8px' }}>
                  {step.inviteOptions && (
                    <Select
                      style={{ flex: 1 }}
                      showSearch
                      placeholder="Select invitee"
                      onChange={(value) => onSelectChange(value, index)}
                      value={selectedOption || undefined}
                      allowClear={!!selectedOption}
                    >
                      {step.inviteOptions.filter(option => {
                        const selectedValues = getSelectedOptionsExcept(index);
                        return !selectedValues.includes(option._id);
                      }).map(option => (
                        <Select.Option key={option._id} value={option._id}>
                          {option.firstName} {option.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  )}

                </div>
              </Item>
            ))}

            <Button 
              type="link"
              className="primary-link"
              onClick={showModal}
              style={{ padding: 0 }}
            >
              + Add New Client
            </Button>


            <PatientForm 
              open={isModalOpen}
              setOpen={setIsModalOpen}
              onSuccess={onAddPatientSuccess}
              requiredFields={step.requiredFields ? step.requiredFields : ['firstName', 'lastName', 'dob', 'gender', 'email']}
              title={"Add Client"}
            />
          
          
        </div>
        
      )}
      </Form>

      <FlowStepFooter 
        onSubmit={() => onSubmit()}
        isSubmitting={isSubmitting}
        buttonText={
          phones.some(phone => phone?.length) && selectedOptions.some(option => option?.length)
            ? "Send & Add"
            : phones.some(phone => phone?.length) 
              ? `Send ${phones.filter(phone => phone?.length).length === 1 ? 'Invite' : 'Invites'}`
              : selectedOptions.some(option => option?.length)
                ? "Add to Appointment"
                : "Continue"
        }
      />
    </div>
  )
}