import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState, useEffect } from "react"
import UltimateMemberConfirmed from "./ultimateMemberConfirmed.component"
import { getMembershipTypeByCode } from "../../services/membershipType.service"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { completeFlow } from "../../services/flow.service"

export const Step = {
  PAY: 'pay',
  ACCOUNT: 'account',
  NAME: 'name',
  PHONE: 'phone',
  PASSWORD: 'password',
  CONFIRM: 'confirm',
}

export const UltimateMemberFlow = () => {
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipType, setMembershipType] = useState()
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchMembershipType()
  }, [])

  useEffect(() => {
    fetchSkeleton()
  }, [membershipType, flow, hasPass, initialUser])

  const fetchMembershipType = async () => {
    setMembershipType(await getMembershipTypeByCode(MembershipTypeCode.ULTIMATE))
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return

    setSkeleton({
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.ACCOUNT,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.ULTIMATE,
        title: <>Pay <span className="true-price">${membershipType?.cost}/month</span> for your Ultimate membership.</>,
        description: <>Includes unlimited blood testing, longevity physician consults, and more. Very easy to cancel anytime.</>,
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `Create your Instalab acccount.`,
        description: `This will help you manage your orders, results and reports.`,
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this to set up your account.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.CONFIRM]: {
        type: StepType.CUSTOM,
        content: ({ footer }) => <UltimateMemberConfirmed footer={footer} />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }


  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  )
}