import React, { useState, useEffect } from 'react'
import { Modal, Form, Select, DatePicker, message, Input } from 'antd'
import { listPatients } from '../../services/patient.service';
import { listAssignees } from '../../services/user.service';
import { ContinueButton } from '../continueButton/continueButton.component'
import { addTask, getTask, updateTask } from '../../services/task.service';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from "dayjs";
import TaskCategory from '../../enums/taskCategory.enum';
import "./taskForm.scss";

const { Item } = Form

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const TaskForm = ({ open, setOpen, onSuccess, taskId }) => {
  const [patients, setPatients] = useState([])
  const [assignees, setAssignees] = useState([])
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    fetchPatients()
    fetchAssignees()
  }, [])

  useEffect(() => {
    if (open) {
      if (taskId) setTaskFields()
    } else {
      form.resetFields()
    }
  }, [open])

  const fetchPatients = async () => {
    const response = await listPatients()
    setPatients(response.filter(({ firstName, lastName }) => firstName && lastName))
  }

  const fetchAssignees = async () => {
    const response = await listAssignees()
    setAssignees(response)
  }

  const onCancel = () => {
    setOpen(false)
  }

  const setTaskFields = async () => {
    const {
      title,
      timeEstimate,
      category,
      assignee,
      patient,
      description, 
      deadline,
    } = await getTask(taskId)

    form.setFieldsValue({
      title,
      timeEstimate,
      category,
      assignee: assignee?._id || null,
      patient: patient?._id || null,
      description, 
      deadline: deadline ? dayjs(deadline) : null // Use moment to parse the date string if present

    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        title,
        timeEstimate,
        category,
        assignee,
        description,
        patient,
        deadline,
      } = form.getFieldsValue()
      
      const params = {
        title,
        timeEstimate,
        category,
        assignee,
        description,
        patient,
        deadline: deadline ? deadline.toISOString() : undefined, // Format the date to ISO string if selected
      }
      const response = taskId ? await updateTask(taskId, params) : await addTask(params)
      setOpen(false)
      message.success(taskId ? 'Task updated' : 'Task added')
      if (!taskId) {
        form.resetFields()
      }
      if (onSuccess) {
        onSuccess(response)
      }
    } catch (err) {
      let msg = taskId ? 'Failed to update task' : 'Failed to add task'
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  return (
    <div className="task-form">
      <Modal 
        className="task-form-modal"
        open={open} 
        title={taskId ? 'Edit Task' : 'Add Task'}
        onCancel={onCancel}
        onOk={onOk}
        okText='Save'
        footer={[
          <ContinueButton
            onClick={onOk}
            text='Save Task'
            isSubmitting={isSubmitting}
          />
        ]}
      >
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
        >
          <Item
            label="Patient"
            name="patient"
            required
          >
            <Select
              placeholder="Select patient"
              showSearch
              optionFilterProp="children"
              className="patient-select"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              options={patients?.map(({ _id, firstName, lastName }) => {
                return {
                  label: `${firstName} ${lastName}`,
                  value: _id,
                }
              })}
            />
          </Item>

          <Item 
            label="Title"
            name="title"
            required
          >
            <Input />
          </Item>

          <Item
            label="Deadline"
            name="deadline"
          >
            <DatePicker 
              style={{ width: '100%' }} 
              format="MM/DD/YYYY" 
              placeholder="Select deadline"
            />
          </Item>

          <Item 
            label="Assignee"
            name="assignee"
          >
            <Select 
              placeholder='Select assignee'
              showSearch
              optionFilterProp="children"
              className="assignee-select"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              options={assignees?.map(({ _id, firstName, lastName }) => {
                return {
                  label: `${firstName} ${lastName}`,
                  value: _id,
                }
              })}
            />
          </Item>

          <Item 
            label="Category"
            name="category"
          >
            <Select 
              placeholder='Select category'
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              options={Object.values(TaskCategory).map(category => {
                return {
                  label: category,
                  value: category,
                }
              })}
            />
          </Item>

          <Item 
            label="Expected Time (Minutes)"
            name="timeEstimate"
          >
            <Input type='number' />
          </Item>

          <Item 
            label="Description"
            name="description"
          >
            <ReactQuill theme="snow" modules={modules} />
          </Item>
        </Form>
      </Modal>
    </div>
  )
}