import copy from 'copy-to-clipboard'


const AppointmentParentHelper = {
  copyJoinLink:  async (appointmentParentId, message) => {
    try {
      copy(`${process.env.REACT_APP_CLIENT_URL}/events/${appointmentParentId}/join`)
      message.success('Copied join link')
    } catch (err) {
      message.error('Failed to generate join link')   
    }
  }
}

export default AppointmentParentHelper
