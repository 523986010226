import { Typography, Popover, Table } from 'antd';
import './biomarkerPopover.scss';
import useWidth from '../../hooks/useWidth.hook';
import Breakpoint from '../../enums/breakpoint.enum';

const { Paragraph } = Typography
const { Column } = Table

export const BiomarkerPopover = ({ trigger }) => {
  const width = useWidth()

  const biomarkers = [{
    name: `ApoB`,
    description: `Tracks the number of atherogenic particles in your blood, a stronger predictor of heart disease than just LDL cholesterol alone.`
  }, {
    name: `Lipoprotein(a)`,
    description: `A genetic marker that indicates your long-term risk of heart disease. Elevated levels can increase the risk of heart attacks or strokes.`
  }, {
    name: `hs-CRP`,
    description: `A marker of inflammation that signals whether your arteries may be inflamed, which is closely tied to heart disease risk.`
  }, {
    name: `Total Cholesterol`,
    description: `Provides a general overview of the cholesterol in your blood, helping us understand your overall risk level.`
  }, {
    name: `HDL Cholesterol`,
    description: `Known as the “good” cholesterol, higher levels of HDL help remove bad cholesterol from your bloodstream, reducing the risk of heart disease.`
  }, {
    name: `LDL Cholesterol`,
    description: `Known as the “bad” cholesterol, high levels contribute to plaque buildup in the arteries, which can lead to heart attacks or strokes.`
  }, {
    name: `VLDL Cholesterol`,
    description: `VLDL carries triglycerides through the bloodstream and contributes to plaque buildup in the arteries. Monitoring this helps us track your risk of heart disease.`
  }, {
    name: `Triglycerides`,
    description: `High levels of these fats can increase your risk of heart disease and are often linked to lifestyle factors like diet.`
  }, {
    name: `HbA1c`,
    description: `Measures your average blood sugar levels over the past few months and is a key indicator for diabetes risk, which can significantly affect heart health.`
  }, {
    name: `Blood Pressure`,
    description: `High blood pressure strains your heart and arteries, leading to long-term damage if not controlled. `
  }, {
    name: `ALT`,
    description: `This liver enzyme helps assess how well your liver is functioning, which is important for managing fat and cholesterol in your body.`
  }]

  return (
    <Popover 
      overlayClassName="biomarker-layover"
      overlayStyle={{
        maxWidth: width < Breakpoint.SM ? '100%' : 700
      }}
      content={(
        <div className="biomarker-popover">
          <Paragraph className="biomarker-title">
            Track What Really Matters
          </Paragraph>
          <Paragraph className="biomarker-subtitle">
            These {biomarkers.length} biomarkers give a complete understanding of your heart health, allowing us to personalize your care plan based on your unique needs.
          </Paragraph>

          <Table
            pagination={false}
            dataSource={biomarkers}
            size='small'
            rowKey='name'
            showHeader={false}
          >
            <Column
              dataIndex='name'
              render={name => <Paragraph className="biomarker-name">{name}</Paragraph>}
              width={150}
            />
            <Column
              dataIndex='description'
              render={name => <Paragraph className="biomarker-description">{name}</Paragraph>}
            />
          </Table>
        </div>
      )}
      placement='right'
    >
      {trigger}
    </Popover>
  )
}