import { Modal, Typography, Table } from 'antd'
import { useEffect, useState } from 'react'
import { listNotesByPatient } from '../../services/note.service'
import parse from 'html-react-parser';
import moment from 'moment'
import './adminNotesModal.scss'

const { Column } = Table
const { Paragraph } = Typography

// BE CAREFUL - DO NOT SHOW THIS IF NOT ADMIN OR PROVIDER

export const AdminNotesModal = ({ patientId, open, setOpen, title }) => {
  const [notes, setNotes] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const onCancel = () => {
    setOpen(false)
  }

  useEffect(() => {
    fetchNotes()
  }, [])

  const fetchNotes = async () => {
    setNotes(await listNotesByPatient(patientId))
    setIsLoading(false)
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="admin-notes-modal"
      title={title}
      footer={null}
      width={950}
    >
      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={notes}
      >
        <Column
          title='Note'
          dataIndex='text'
          render={note=> <Paragraph className="noteText">{parse(note)}</Paragraph>}
        />

        {!patientId && (
          <Column
            title='Patient'
            dataIndex='patient'
            render={patient => `${patient.firstName} ${patient.lastName}`}
          />
        )}

        <Column
          title='Author'
          dataIndex='author'
          render={author => `${author.firstName} ${author.lastName}`}
        />

        <Column
          title='Date'
          dataIndex='createdAt'
          render={createdAt => moment(createdAt).format('MM/DD/YYYY')}
        />
      </Table>
    </Modal>
  )
}