import { WarningFilled } from '@ant-design/icons'
import { isUserUnique } from '../services/user.service';
import ValidateHelper from './validate.helper';
import StorageKey from '../enums/storageKey.enum';

const isValidDate = (dateString) => {
  // First check for the pattern
  if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
      return false;

  // Parse the date parts to integers
  var parts = dateString.split("/");
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if(year < 1000 || year > 3000 || month === 0 || month > 12)
      return false;

  var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  // Adjust for leap years
  if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
      monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

const RuleHelper = {
  isRequired: { 
    required: true, 
    message: <><WarningFilled />&nbsp; Please fill this in</>,
  },
  isDate: {
    message: <><WarningFilled />&nbsp; Enter a valid date</>,
    validator: (_, value='') => {
      value = value.trim()
      if (!value || isValidDate(value, 'MM/DD/YYYY')) {
        return Promise.resolve();
      } else {
        return Promise.reject('Enter valid date');
      }
    }
  },
  isPostalCode: {
    message: <><WarningFilled />&nbsp; Enter a valid postal code</>,
    validator: (_, value='') => {
      value = value.trim()
      const regex = /^\d{5}(-\d{4})?$/;
      if (!value || regex.test(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject('Enter postal code');
      }
    }
  },
  isPhone: {
    message: <><WarningFilled />&nbsp; Enter a valid phone number</>,
    validator: (_, value='') => {
      value = value.trim()
      if (!value || value.replace(/[^0-9]/g,"").length === 10) {
        return Promise.resolve();
      } else {
        return Promise.reject('Enter valid phone number');
      }
    }
  },
  isEmail: {
    message: <><WarningFilled />&nbsp; Hmm... that email doesn't look right</>,
    validator: (_, value='') => {
      value = value.trim()
      if (!value || ValidateHelper.email(value.trim())) {
        return Promise.resolve();
      } else {
        return Promise.reject('Enter valid email address');
      }
    }
  },
  hasCustomValidation: ({ message, validator }) => {
    return {
      message: <><WarningFilled />&nbsp; {message}</>,
      validator,
    }
  },
  isUniqueEmail: ({ fieldName, navigate, redirectUrl, userId, uniqueError }) => {
    return {
      message: uniqueError || <>
        <WarningFilled />&nbsp; Email already taken.
        {redirectUrl && <>
          &nbsp;<a className="error-link" onClick={() => navigate(`/login?redirect=${redirectUrl}`)}>Log in.</a>
        </>}
      </>,
      validator: async (_, value='') => {
        value = value.trim()
        if (!value || !ValidateHelper.email(value) || (await isUserUnique({
          tracker: localStorage.getItem(StorageKey.TRACKER),
          field: fieldName,
          value,
          userId,
        }))) {
          return Promise.resolve();
        } else {
          return Promise.reject('Email already taken');
        }
      }
    }
  },
  isUniquePhone: ({ fieldName, navigate, redirectUrl, userId, uniqueError }) => {
    return {
      message: uniqueError || <>
        <WarningFilled />&nbsp; Phone number already taken. 
        {redirectUrl && <>
          &nbsp;<a className="error-link" onClick={() => navigate(`/login?redirect=${redirectUrl}`)}>Log in.</a>
        </>}
      </>,
      validator: async (_, value='') => {
        value = value.trim()
        if (!value || !ValidateHelper.phone(value) || (await isUserUnique({
          tracker: localStorage.getItem(StorageKey.TRACKER),
          field: fieldName,
          value,
          userId
        }))) {
          return Promise.resolve();
        } else {
          return Promise.reject('Phone number already taken');
        }
      }
    }
  },
  isValidOption: (options) => ({
    message: <><WarningFilled />&nbsp; Please select an option</>,
    validator: (_, value) => {
      if (!value) return Promise.resolve();
      
      // Handle array values (from multi-select)
      if (Array.isArray(value)) {
        const allValid = value.every(val => 
          options.some(option => option.value === val)
        );
        return allValid ? Promise.resolve() : Promise.reject('Invalid option selected');
      }
      
      // Handle single value
      if (options.some(option => option.value === value)) {
        return Promise.resolve();
      }
      return Promise.reject('Invalid option selected');
    }
  })
}

export default RuleHelper