import './reference.scss'

export const Reference = ({ content, url }) => {
  return (
    <a 
      className="reference"
      href={url}
      target="_blank"
    >
      {content}
    </a>
  )
}