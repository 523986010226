import axios from 'axios'
import UserHelper from '../helpers/user.helper'
import { posthog } from 'posthog-js'
import ServiceHelper from '../helpers/service.helper'

export const requestPasswordReset = async (params) => {
  await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/password/request`, ServiceHelper.getParams(params))
}

export const resetPassword = async (id, params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/password/reset/${id}`, ServiceHelper.getParams(params))
  const token = response.data.token
  localStorage.setItem('token', token)
  const isAdmin = UserHelper.isAdmin(response.data.user)
  localStorage.setItem('isAdmin', isAdmin)

  if (window.location.hostname !== "localhost") {
    posthog.people.set({ email: response.data.user.email })
  }

  return {
    token,
    isAdmin
  }
}