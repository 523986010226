import { useContext } from "react";
import { Table, Typography } from "antd";
import classNames from "classnames";
import { UserContext } from "../../../contexts/user.context";
import { BlogData } from "../../../data/blog.data";
import PosthogHelper from "../../../helpers/posthog.helper";
import { useNavigate, useParams } from "react-router-dom";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";

const { Paragraph } = Typography


export const CacPost = () => {
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)
  const { blogId } = useParams()

  return (
    <div className={classNames("blog-post", "cac-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          {BlogData.find(b => b.id === blogId).title}
        </Paragraph>

        <Paragraph className="blog-subheading">
          {BlogData.find(b => b.id === blogId).description}
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <img 
          src="/img/cac.jpg" 
          className="blog-image"
        />

        <Paragraph className="blog-title">
          Why is the CAC Test So Important?
        </Paragraph>
        <Paragraph className="blog-description">
          Traditional cholesterol tests can't always tell the full story about your heart health. A 2018 study published in JAMA Cardiology found that people with a CAC score above zero were 4.5 times more likely to experience a cardiovascular event than those with a score of zero, even when their cholesterol levels appeared “normal.”
        </Paragraph>
        <Paragraph className="blog-description">
          In another study from The New England Journal of Medicine, researchers discovered that nearly 15% of individuals with healthy lipid levels (like LDL cholesterol) still had detectable calcification in their arteries, putting them at a heightened risk for heart disease.
        </Paragraph>

        <Paragraph className="blog-title">
          A Real-Life Example: Solid Lipid Levels but a Non-Zero CAC Score
        </Paragraph>
        <Paragraph className="blog-description">
          Take the case of Jasper, a 47-year-old patient at Instalab with no known health concerns. His lipid levels, including LDL cholesterol and ApoB, were well within the optimal range. Yet, due to his family history of early heart disease—his father had a heart attack at 58—he decided to undergo a CAC test. The result: a score of 127, placing him in the moderate-risk category. Although his cholesterol levels were healthy, the calcified plaque revealed early signs of atherosclerosis.
        </Paragraph>
        <Paragraph className="blog-description">
          Jasper's case highlights why relying solely on cholesterol levels doesn't provide a complete view of cardiovascular health. The CAC test pinpointed his elevated risk, prompting him to take proactive steps, including lifestyle changes like improving his diet, exercising more, and eventually discussing prescription medications with his doctor.
        </Paragraph>

        <Paragraph className="blog-title">
          Who Should Get a CAC Test?
        </Paragraph>
        <Paragraph className="blog-description">
          CAC testing is especially valuable for people over 40, particularly those uncertain about their heart disease risk. If you have a family history of heart disease or other risk factors like high blood pressure or diabetes, the CAC test could offer life-saving insights.
        </Paragraph>
        <Paragraph className="blog-description">
          While lipid tests are useful, they don't always tell the full story. A non-zero CAC score means calcified plaque is already present in your arteries, raising your heart disease risk. If you're considering starting medications like statins, the CAC test can be a crucial factor in deciding whether they are necessary.
        </Paragraph>
        <Paragraph className="blog-description">
          According to the American College of Cardiology guidelines:
        </Paragraph>

        <Table
          className="blog-table"
          dataSource={[{
            cacScore: '0',
            risk: 'Low Risk',
            recommendedAction: 'No immediate need for prescription medication'
          }, {
            cacScore: '1-100',
            risk: 'Mild Plaque Buildup',
            recommendedAction: 'Focus on lifestyle modifications'
          }, {
            cacScore: 'Over 100',
            risk: 'Moderate to High Risk',
            recommendedAction: 'Prescription medications recommended with lifestyle changes'
          }]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column title="Score" dataIndex="cacScore" />
          <Table.Column title="Risk Level" dataIndex="risk" />
          <Table.Column title="Recommended Action" dataIndex="recommendedAction" />
        </Table>

        <Paragraph className="blog-title">
          What If My CAC Score Is Zero?
        </Paragraph>
        <Paragraph className="blog-description">
          If your CAC score is zero, the immediate focus should be on lifestyle improvements, such as adopting a balanced diet, regular physical activity, and potentially adding natural supplements like Berberine to your routine. Research shows that individuals with a zero CAC score have a very low risk (less than 1%) of experiencing a heart attack within the next five years. By maintaining a healthy lifestyle, you can keep that risk low.
        </Paragraph>

        <Paragraph className="blog-title">
          The Cost of a CAC Test
        </Paragraph>
        <Paragraph className="blog-description">
          Unfortunately, many insurance plans don't cover CAC testing, although states like Texas are starting to offer exceptions. The cost of a CAC test typically ranges between $100 and $150, depending on your location. At Instalab, we work with our members to identify affordable testing options and can assist you in scheduling your appointment at a nearby facility.
        </Paragraph>
        <Paragraph className="blog-description">
          To order a CAC test, you can either request one through your primary care physician or <a className="blog-link" onClick={() => {
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: schedule`, true)
            navigate(`/cac`)
          }}>click here to book directly with Instalab.</a>
        </Paragraph>

        <Paragraph className="blog-title">
          The Bottom Line: Don't Rely on Lipid Levels Alone
        </Paragraph>
        <Paragraph className="blog-description">
          Even if your cholesterol levels are within the normal range, you could still be at risk for heart disease. A CAC test offers an additional, critical layer of information that helps you and your doctor make more informed decisions about your heart health. For someone like Jasper, with solid lipid levels but a non-zero CAC score, the test was a game changer. It allowed him to take proactive measures to lower his cardiovascular risk and potentially prevent a life-threatening event.
        </Paragraph>
        <Paragraph className="blog-description">
          If you're over 40, have risk factors for heart disease, or simply want a more complete picture of your heart health, a CAC test could be the key to catching problems before they become serious.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              JAMA Cardiology (2018), “Coronary Artery Calcium and Cardiovascular Risk.”
            </li>
            <li>
              The New England Journal of Medicine (2015), “Cholesterol Levels and Coronary Artery Disease.”
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}