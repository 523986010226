import { useState, useEffect } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { 
  Gender, 
  Ethnicity, 
  MedicalCondition, 
  Diet, 
  SleepQuality, 
  Tracker, 
  MenustralCycle, 
  Menopause, 
  ConditionCategoryCode,
  FlowType } from "../../enums/index.enum"
import { ConfirmIntake } from "./confirmIntake.component"
import { useParams } from 'react-router-dom'
import { listConditionCategories } from "../../services/condition.service.js"
import { completeFlow } from "../../services/flow.service.js"

export const Step = {
  ACCOUNT: 'account',
  PHONE: 'phone',
  INTRO: 'intro',
  NAME: 'name',
  ADDRESS: 'address',

  // BASIC
  GENDER: 'gender',
  DOB: 'dob',
  ETHNICITY: 'ethnicity',

  // MEDICAL CONDITIONS
  MEDICAL_CONDITIONS_CATEGORIES: 'medical-conditions-categories',
  MEDICAL_CONDITIONS: 'medical-conditions',
  AUTOIMMUNE: 'autoimmune-conditions',
  CANCER: 'cancer-conditions',
  CARDIOVASCULAR: 'cardiovascular-conditions',
  DIGESTIVE: 'digestive-conditions',
  INFECTIOUS: 'infectious-conditions',
  MENTAL_HEALTH: 'mental-health-conditions',
  METABOLIC: 'metabolic-conditions',
  MUSCULOSKELATAL: 'musculoskelatal-conditions',
  NEUROLOGICAL: "neurological-conditions", // to do
  REPRODUCTIVE: 'reproductive-conditions', // to do
  RESPIRATORY: 'respiratory-conditions', // to do
  SKIN: 'skin-conditions', // to do
  OTHER_MEDICAL_CONDITIONS: 'other-medical-conditions', 
  PREVIOUS_CONDITIONS: 'previous-conditions',
  HAS_SURGERIES: 'has-surgeries',
  SURGERIES: 'surgeries',
  HAS_ALLERGIES: 'has-allerges',
  ALLERGIES: 'allergies',
  BIOMETRICS: 'biometrics',

  // SUPPLEMENTS + MEDICATIONS
  HAS_MEDICATIONS: 'has-medications',
  MEDICATIONS: 'medications',
  HAS_SUPPLEMENTS: 'has-supplements',
  SUPPLEMENTS: 'supplements',

  // FAMILY HISTORY
  FAMILY_HISTORY: 'family-history',
  FAMILY_HISTORY_CANCER: 'family-history-cancer',

  // LIFESTYLE
  DIET: 'diet',
  SMOKING: 'smoking',
  CURRENT_SMOKING: 'current-smoking',
  ALCOHOL: 'alcohol',
  EXERCISE: 'exercise',
  STRENGTH: 'strength',
  SLEEP_HOURS: 'sleep-hours',
  SLEEP_QUALITY: 'sleep-quality',
  STRESS: 'stress',
  MEDITATE: 'meditate',
  PLANNING_CHILDREN: 'planning-children',

  // FEMALE
  PREGNANCY: 'pregnancy', // to do: multiple choice - want to have kids + for men too
  BIRTH_CONTROL: 'birth-control',
  MENOPAUSE: 'menopause',
  MENSTRUAL_CYCLE: 'menstrual-cycle',

  // OTHER
  TRACKERS: 'trackers',
  FILES: 'files',
  CONFIRM: 'confirm',
}

export const IntakeFlow = () => {
  const { flowId } = useParams()
  const [flow, setFlow] = useState()
  const [conditionCategories, setConditionCategories] = useState()
  const [isLoading, setLoading] = useState(true);  // Loading state
  const [initialUser, setInitialUser] = useState()
  const [skeleton, setSkeleton] = useState()
  const [hasPass, setHasPass] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [initialUser, hasPass])

  useEffect(() => {
    const fetchConditionCategories = async () => {
      try {
        setLoading(true);  
        const categories = await listConditionCategories();
        setConditionCategories(categories);
        setLoading(false);  
      } catch (error) {
        console.error('Failed to fetch condition categories:', error);
        setLoading(false);  
      }
    };

    fetchConditionCategories();
  }, []);


  useEffect(() => {
    resetFlowId()
  }, [flowId])

  const resetFlowId = () => {
    if (!flowId) return 
  }

  const pastConditions = [
    MedicalCondition.AORTIC_ANEURYSM,
    MedicalCondition.ANGINA,
    MedicalCondition.BLOCKED_ARTERY,
    MedicalCondition.GESTATIONAL_DIABETES,
    MedicalCondition.HEART_ATTACK,
    MedicalCondition.HEART_DISEASE_REQUIRING_STENT_OR_SURGERY,
    MedicalCondition.STROKE,
    MedicalCondition.TIA
  ]

  const cardiovascularConditions = [
    MedicalCondition.CAD,
    MedicalCondition.HIGH_BLOOD_PRESSURE,
    MedicalCondition.HIGH_CHOLESTEROL,
    MedicalCondition.FAMILIAL_HYPERCHOLESTEROLEMIA,
    MedicalCondition.FAMILIAL_HYPERTRIGLYCERIDEMIA,
    MedicalCondition.HYPERTIGLYCERIDEMIA,
    MedicalCondition.ARRHYTHMIA,
    MedicalCondition.PERIPHERAL_ARTERY_DISEASE,
  ]

  const metabolicConditions = [
    MedicalCondition.TYPE_1_DIABETES,
    MedicalCondition.TYPE_2_DIABETES,
    MedicalCondition.HYPERTHYROIDISM,
    MedicalCondition.HYPOTHYROIDISM,
    MedicalCondition.NAFLD,
    MedicalCondition.PCOS,
    MedicalCondition.METABOLIC_SYNDROME,
    MedicalCondition.OBESITY,
    MedicalCondition.GOUT,
    MedicalCondition.PREDIABETES
  ]

  const autoimmuneConditions = [
    MedicalCondition.TYPE_1_DIABETES,
    MedicalCondition.IBS,
    MedicalCondition.RHEUMATOID_ARTHRITIS,
    MedicalCondition.LUPUS,
    MedicalCondition.MULTIPLE_SCLEROSIS,
    MedicalCondition.PSORIASIS,
    MedicalCondition.HASHIMOTO,
    MedicalCondition.GRAVES,
    MedicalCondition.SJOGREN,
    MedicalCondition.CELIAC
  ]

  const cancerConditions = [
    MedicalCondition.CANCER_BREAST,
    MedicalCondition.CANCER_COLORECTAL,
    MedicalCondition.CANCER_ENDOMETRIAL,
    MedicalCondition.CANCER_KIDNEY,
    MedicalCondition.CANCER_OVARIAN,
    MedicalCondition.CANCER_PANCREATIC,
    MedicalCondition.CANCER_PROSTATE,
    MedicalCondition.CANCER_SKIN,
    MedicalCondition.CANCER_THYROID
  ]

  const digestiveConditions = [
    MedicalCondition.ACID_REFLUX,
    MedicalCondition.CHRONS,
    MedicalCondition.ULCERATIVE_COLITIS,
    MedicalCondition.CELIAC,
    MedicalCondition.DIVERTICULITIS,
    MedicalCondition.STOMACH_ULCERS,
    MedicalCondition.GALLSTONES,
    MedicalCondition.IBS,
    MedicalCondition.LACOTSE_INTOLERANCE,
    MedicalCondition.HEPATITIS_C,
    MedicalCondition.SIBO
  ]
  
  const infectiousConditions = [
    MedicalCondition.HIV_AIDS,
    MedicalCondition.HEPATITIS_B,
    MedicalCondition.HEPATITIS_C,
    MedicalCondition.TUBERCULOSIS,
    MedicalCondition.LYME,
    MedicalCondition.HPV,
    MedicalCondition.SYPHILIS,
    MedicalCondition.GONORRHEA,
    MedicalCondition.HERPES,
    MedicalCondition.EBV
  ]

  const musculoskelatalCondtions = [
    MedicalCondition.OSTEOPENIA,
    MedicalCondition.OSTEOPOROSIS,
    MedicalCondition.ARTHRITIS,
    MedicalCondition.FIBROMYALGIA,
    MedicalCondition.MUSCULAR_DYSTROPHY,
    MedicalCondition.TENDONITIS,
    MedicalCondition.GOUT,
    MedicalCondition.PAGETS,
    MedicalCondition.SCOLIOSIS
  ]

  const mentalConditions = [
    MedicalCondition.DEPRESSION,
    MedicalCondition.ANXIETY,
    MedicalCondition.BIPOLAR,
    MedicalCondition.SCHIZOPHRENIA,
    MedicalCondition.PTSD,
    MedicalCondition.OCD,
    MedicalCondition.PANIC_DISORDER,
    MedicalCondition.SOCIAL_ANXIETY_DISORDER,
    MedicalCondition.ADHD,
    MedicalCondition.EATING_DISORDER
  ]

  const familyHistoryConditions = [
    MedicalCondition.BLOCKED_ARTERY,
    MedicalCondition.CANCER,
    MedicalCondition.DEMENTIA,
    MedicalCondition.DIABETES,
    MedicalCondition.HEART_ATTACK,
    MedicalCondition.HEART_DISEASE,
    MedicalCondition.HIGH_BLOOD_PRESSURE,
    MedicalCondition.HIGH_CHOLESTEROL,
    MedicalCondition.OSTEOPOROSIS,
    MedicalCondition.PERIPHERAL_ARTERY_DISEASE,
    MedicalCondition.STROKE,
  ]

  const showOtherMedicalConditions = (patientConditionCodes, patient) => {

    return patientConditionCodes.includes(ConditionCategoryCode.OTHER) 
    || patient?.currentMedicalConditions?.includes("Other Autoimmune")
    || patient?.currentMedicalConditions?.includes("Other Cancer")
    || patient?.currentMedicalConditions?.includes("Other Cardiovascular") 
    || patient?.currentMedicalConditions?.includes("Other Digestive") 
    || patient?.currentMedicalConditions?.includes("Other Infectious") 
    || patient?.currentMedicalConditions?.includes("Other Mental") 
    || patient?.currentMedicalConditions?.includes("Other Metabolic") 
    || patient?.currentMedicalConditions?.includes("Other Musculoskeletal") 
    || patient?.currentMedicalConditions?.includes("Other Neurological")
    || patient?.currentMedicalConditions?.includes("Other Reproductive") 
    || patient?.currentMedicalConditions?.includes("Other Respiratory")
    || patient?.currentMedicalConditions?.includes("Other Skin") 

  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: <>First, let's create your Instalab acccount.</>,
        description: <>Already have an account? <a href={`/login?redirect=/flow/${FlowType.INTAKE}`}>Login</a>.</>,
        fields: [
          {
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        },
        buttonText: "Create Account"
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.INTRO,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.INTRO]: {
        type: StepType.STATIC,
        title: `Tell us about you! (it takes less than 2 minutes)`,
        description: `Answer a few quick questions so we can learn more about you. This will help us make the best recommendations for you.`,
        nextStep: Step.NAME,
        buttonText: "Let's get started!",
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return (initialUser?.firstName && initialUser?.lastName)
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your biological sex?`,
        field: {
          name: 'gender',
          required: true,
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.MEDICAL_CONDITIONS_CATEGORIES,
        buildUser: true,
        title: `What's your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },

      [Step.MEDICAL_CONDITIONS_CATEGORIES]: {
        type: StepType.MULTIPLE_SELECT,
        onNextStep: async (patient) => {
          if (!conditionCategories || !patient?.currentMedicalConditionCategories) {
            return Step.PREVIOUS_CONDITIONS; // Fallback if condition categories are not loaded
          }      
          const patientConditionCodes = patient.currentMedicalConditionCategories
            .map(id => conditionCategories.find(category => category._id === id)?.code);
        
          if (patientConditionCodes.includes(ConditionCategoryCode.AUTOIMMUNE)) return Step.AUTOIMMUNE
          else if (patientConditionCodes.includes(ConditionCategoryCode.CANCER)) return Step.CANCER
          else if (patientConditionCodes.includes(ConditionCategoryCode.CARDIOVASCULAR)) return Step.CARDIOVASCULAR
          else if (patientConditionCodes.includes(ConditionCategoryCode.DIGESTIVE)) return Step.DIGESTIVE
          else if (patientConditionCodes.includes(ConditionCategoryCode.INFECTIOUS)) return Step.INFECTIOUS
          else if (patientConditionCodes.includes(ConditionCategoryCode.MENTAL_HEALTH)) return Step.MENTAL_HEALTH
          else if (patientConditionCodes.includes(ConditionCategoryCode.METABOLIC)) return Step.METABOLIC
          else if (patientConditionCodes.includes(ConditionCategoryCode.MUSCULOSKELATAL)) return Step.MUSCULOSKELATAL
          else if (showOtherMedicalConditions(patientConditionCodes,patient)) return Step.OTHER_MEDICAL_CONDITIONS;
          else return Step.PREVIOUS_CONDITIONS
        },
        buildUser: true,
        title: `What kind of medical conditions do you currently have?`,
        description: 'Select all that apply.',
        field: {
          name: 'currentMedicalConditionCategories',
          options: conditionCategories ? [
            ...conditionCategories
              .filter(category => category.name !== 'Other') // Exclude "Other" from sorting
              .sort((a, b) => a.name.localeCompare(b.name)) // Sort the rest alphabetically
              .map(category => ({
                label: <>{category.name} {category.caption ? <span style={{color: 'gray'}}> ({category.caption})</span> : null}</>,
                value: category._id
              })),
            ...conditionCategories
              .filter(category => category.name === 'Other') // Add "Other" at the end
              .map(category => ({label: category.name, value: category._id}))
          ] : [] 
        },
      },
      [Step.AUTOIMMUNE]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        title: `What autoimmune conditions do you currently have?`,
        description: 'Select all that apply.',
        field: {
          name: 'currentMedicalConditions',
          options: [...Object.values(MedicalCondition)
                          .filter(value => autoimmuneConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label)), {label:'Other', value: 'Other Autoimmune'}]
        },
        onNextStep: async (patient) => {
          if (!conditionCategories || !patient?.currentMedicalConditionCategories) {
            return Step.PREVIOUS_CONDITIONS; // Fallback if condition categories are not loaded
          }      
          const patientConditionCodes = patient.currentMedicalConditionCategories
            .map(id => conditionCategories.find(category => category._id === id)?.code);
        
          if (patientConditionCodes.includes(ConditionCategoryCode.CANCER)) return Step.CANCER
          else if (patientConditionCodes.includes(ConditionCategoryCode.CARDIOVASCULAR)) return Step.CARDIOVASCULAR
          else if (patientConditionCodes.includes(ConditionCategoryCode.DIGESTIVE)) return Step.DIGESTIVE
          else if (patientConditionCodes.includes(ConditionCategoryCode.INFECTIOUS)) return Step.INFECTIOUS
          else if (patientConditionCodes.includes(ConditionCategoryCode.MENTAL_HEALTH)) return Step.MENTAL_HEALTH
          else if (patientConditionCodes.includes(ConditionCategoryCode.METABOLIC)) return Step.METABOLIC
          else if (patientConditionCodes.includes(ConditionCategoryCode.MUSCULOSKELATAL)) return Step.MUSCULOSKELATAL
          else if (showOtherMedicalConditions(patientConditionCodes,patient)) return Step.OTHER_MEDICAL_CONDITIONS
          else return Step.PREVIOUS_CONDITIONS
        },
      },
      [Step.CANCER]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        title: `Which cancers do you currently have?`,
        description: 'Select all that apply.',
        field: {
          name: 'currentMedicalConditions',
          options: [...Object.values(MedicalCondition)
                          .filter(value => cancerConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label)), {label:'Other', value: 'Other Cancer'}]
        },
        onNextStep: async (patient) => {
          if (!conditionCategories || !patient?.currentMedicalConditionCategories) return Step.PREVIOUS_CONDITIONS
  
          const patientConditionCodes = patient.currentMedicalConditionCategories
            .map(id => conditionCategories.find(category => category._id === id)?.code)
        
          if (patientConditionCodes.includes(ConditionCategoryCode.CARDIOVASCULAR)) return Step.CARDIOVASCULAR
          else if (patientConditionCodes.includes(ConditionCategoryCode.DIGESTIVE)) return Step.DIGESTIVE
          else if (patientConditionCodes.includes(ConditionCategoryCode.INFECTIOUS)) return Step.INFECTIOUS
          else if (patientConditionCodes.includes(ConditionCategoryCode.MENTAL_HEALTH)) return Step.MENTAL_HEALTH
          else if (patientConditionCodes.includes(ConditionCategoryCode.METABOLIC)) return Step.METABOLIC
          else if (patientConditionCodes.includes(ConditionCategoryCode.MUSCULOSKELATAL)) return Step.MUSCULOSKELATAL
          else if (showOtherMedicalConditions(patientConditionCodes,patient)) return Step.OTHER_MEDICAL_CONDITIONS
          else return Step.PREVIOUS_CONDITIONS
        },
      },
      [Step.CARDIOVASCULAR]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        title: `What cardiovascular conditions do you currently have?`,
        description: 'Select all that apply.',
        field: {
          name: 'currentMedicalConditions',
          options: [...Object.values(MedicalCondition)
                          .filter(value => cardiovascularConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label)), {label:'Other', value: 'Other Cardiovascular'}]
        },
        onNextStep: async (patient) => {
          if (!conditionCategories || !patient?.currentMedicalConditionCategories) {
            return Step.PREVIOUS_CONDITIONS; // Fallback if condition categories are not loaded
          }      
          const patientConditionCodes = patient.currentMedicalConditionCategories
            .map(id => conditionCategories.find(category => category._id === id)?.code);
        
          if (patientConditionCodes.includes(ConditionCategoryCode.DIGESTIVE)) return Step.DIGESTIVE
          else if (patientConditionCodes.includes(ConditionCategoryCode.INFECTIOUS)) return Step.INFECTIOUS
          else if (patientConditionCodes.includes(ConditionCategoryCode.MENTAL_HEALTH)) return Step.MENTAL_HEALTH
          else if (patientConditionCodes.includes(ConditionCategoryCode.METABOLIC)) return Step.METABOLIC
          else if (patientConditionCodes.includes(ConditionCategoryCode.MUSCULOSKELATAL)) return Step.MUSCULOSKELATAL
          else if (showOtherMedicalConditions(patientConditionCodes,patient)) return Step.OTHER_MEDICAL_CONDITIONS
          else return Step.PREVIOUS_CONDITIONS
  
        },
      },
      [Step.DIGESTIVE]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        title: `What digestive conditions do you currently have?`,
        description: 'Select all that apply.',
        field: {
          name: 'currentMedicalConditions',
          options: [...Object.values(MedicalCondition)
                          .filter(value => digestiveConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label)), {label:'Other', value: 'Other Digestive'}]
        },
        onNextStep: async (patient) => {
          if (!conditionCategories || !patient?.currentMedicalConditionCategories) {
            return Step.PREVIOUS_CONDITIONS; // Fallback if condition categories are not loaded
          }      
          const patientConditionCodes = patient.currentMedicalConditionCategories
            .map(id => conditionCategories.find(category => category._id === id)?.code);
        
          if (patientConditionCodes.includes(ConditionCategoryCode.INFECTIOUS)) return Step.INFECTIOUS
          else if (patientConditionCodes.includes(ConditionCategoryCode.MENTAL_HEALTH)) return Step.MENTAL_HEALTH
          else if (patientConditionCodes.includes(ConditionCategoryCode.METABOLIC)) return Step.METABOLIC
          else if (patientConditionCodes.includes(ConditionCategoryCode.MUSCULOSKELATAL)) return Step.MUSCULOSKELATAL
          else if (showOtherMedicalConditions(patientConditionCodes,patient)) return Step.OTHER_MEDICAL_CONDITIONS
          else return Step.PREVIOUS_CONDITIONS
  
        },
      },
      [Step.INFECTIOUS]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        title: `What infectious conditions do you currently have?`,
        description: 'Select all that apply.',
        field: {
          name: 'currentMedicalConditions',
          options: [...Object.values(MedicalCondition)
                          .filter(value => infectiousConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label)), {label:'Other', value: 'Other Infectious'}]
        },
        onNextStep: async (patient) => {
          if (!conditionCategories || !patient?.currentMedicalConditionCategories) {
            return Step.PREVIOUS_CONDITIONS; // Fallback if condition categories are not loaded
          }      
          const patientConditionCodes = patient.currentMedicalConditionCategories
            .map(id => conditionCategories.find(category => category._id === id)?.code);
        
          if (patientConditionCodes.includes(ConditionCategoryCode.MENTAL_HEALTH)) return Step.MENTAL_HEALTH
          else if (patientConditionCodes.includes(ConditionCategoryCode.METABOLIC)) return Step.METABOLIC
          else if (patientConditionCodes.includes(ConditionCategoryCode.MUSCULOSKELATAL)) return Step.MUSCULOSKELATAL
          else if (showOtherMedicalConditions(patientConditionCodes,patient)) return Step.OTHER_MEDICAL_CONDITIONS
          else return Step.PREVIOUS_CONDITIONS
  
        },
      },
      [Step.MENTAL_HEALTH]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        title: `What mental health conditions do you currently have?`,
        description: 'Select all that apply.',
        field: {
          name: 'currentMedicalConditions',
          options: [...Object.values(MedicalCondition)
                          .filter(value => mentalConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label)), {label:'Other', value: 'Other Mental'}]
        },
        onNextStep: async (patient) => {
          if (!conditionCategories || !patient?.currentMedicalConditionCategories) {
            return Step.PREVIOUS_CONDITIONS; // Fallback if condition categories are not loaded
          }      
          const patientConditionCodes = patient.currentMedicalConditionCategories
            .map(id => conditionCategories.find(category => category._id === id)?.code);
        
          if (patientConditionCodes.includes(ConditionCategoryCode.METABOLIC)) return Step.METABOLIC
          else if (patientConditionCodes.includes(ConditionCategoryCode.MUSCULOSKELATAL)) return Step.MUSCULOSKELATAL
          else if (showOtherMedicalConditions(patientConditionCodes,patient)) return Step.OTHER_MEDICAL_CONDITIONS
          else return Step.PREVIOUS_CONDITIONS
  
        },
      },
      [Step.METABOLIC]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        title: `What metabolic conditions do you currently have?`,
        description: 'Select all that apply.',
        field: {
          name: 'currentMedicalConditions',
          options: [...Object.values(MedicalCondition)
                          .filter(value => metabolicConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label)), {label:'Other', value: 'Other Metabolic'}]
        },
        onNextStep: async (patient) => {
          if (!conditionCategories || !patient?.currentMedicalConditionCategories) {
            return Step.PREVIOUS_CONDITIONS; // Fallback if condition categories are not loaded
          }
  
          const patientConditionCodes = patient.currentMedicalConditionCategories
            .map(id => conditionCategories.find(category => category._id === id)?.code);
        
          if (patientConditionCodes.includes(ConditionCategoryCode.MUSCULOSKELATAL)) return Step.MUSCULOSKELATAL
          else if (showOtherMedicalConditions(patientConditionCodes,patient)) return Step.OTHER_MEDICAL_CONDITIONS
          else return Step.PREVIOUS_CONDITIONS
  
        },
      },
      [Step.MUSCULOSKELATAL]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        title: `What bone/muscle conditions do you currently have?`,
        description: 'Select all that apply.',
        field: {
          name: 'currentMedicalConditions',
          options: [...Object.values(MedicalCondition)
                          .filter(value => musculoskelatalCondtions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label)), {label:'Other', value: 'Other Musculoskeletal'}]
        },
        onNextStep: async (patient) => {
          if (!conditionCategories || !patient?.currentMedicalConditionCategories) {
            return Step.PREVIOUS_CONDITIONS; // Fallback if condition categories are not loaded
          }
  
          const patientConditionCodes = patient.currentMedicalConditionCategories
            .map(id => conditionCategories.find(category => category._id === id)?.code);
        
          if (showOtherMedicalConditions(patientConditionCodes, patient)) return Step.OTHER_MEDICAL_CONDITIONS
          else return Step.PREVIOUS_CONDITIONS
        },
      },
      [Step.OTHER_MEDICAL_CONDITIONS]: {
        type: StepType.TEXTAREA,
        nextStep: Step.PREVIOUS_CONDITIONS,
        buildUser: true,
        title: `Please list the other specific medical conditions you haven't mentioned yet that you currently have.`,
        description: `Separate each condition with a new line.`,
        field: {
          name: 'otherCurrentMedicalConditions',
          placeholder: 'Type conditions here ...',
        },
      },
      [Step.PREVIOUS_CONDITIONS]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.HAS_SURGERIES,
        buildUser: true,
        title: `Have you previously had any of these medical conditions?`,
        description: 'Select all that apply.',
        field: {
          name: 'allMedicalConditions',
          options: Object.values(MedicalCondition)
                          .filter(value => pastConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label))
        },
      },
      [Step.HAS_SURGERIES]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.hasSurgeries) {
            return Step.SURGERIES
          } 
          else {
            return Step.HAS_ALLERGIES
          }
        },
        title: `Have you had any surgeries?`,
        buildUser: true,
        field: {
          name: 'hasSurgeries',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.SURGERIES]: {
        type: StepType.TEXTAREA,
        nextStep: Step.HAS_ALLERGIES,
        buildUser: true,
        title: `Please list your surgeries.`,
        description: `Separate each surgery with a new line.`,
        field: {
          name: "surgeries",
          placeholder: 'Type surgeries here ...'
        },  
      },
      [Step.HAS_ALLERGIES]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.hasAllergies) {
            return Step.ALLERGIES
          } 
          else {
            return Step.HAS_MEDICATIONS
          }
        },
        buildUser: true,
        title: 'Do you currently have any allergies?',
        field: {
          name: "hasAllergies",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.ALLERGIES]: {
        type: StepType.TEXTAREA,
        nextStep: Step.HAS_MEDICATIONS,
        buildUser: true,
        title: `Please list your allergies.`,
        description: `Separate each allergy with a new line.`,
        field: {
          name: 'allergies',
          placeholder: '',
        },
      },
      [Step.HAS_MEDICATIONS]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: (patient) => {
          if (patient?.hasMedications) {
            return Step.MEDICATIONS
          } 
          else {
            return Step.HAS_SUPPLEMENTS
          }
        },
        buildUser: true,
        title: 'Are you taking any prescribed medications?',
        field: {
          name: "hasMedications",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.MEDICATIONS]: {
        type: StepType.TEXTAREA,
        nextStep: Step.HAS_SUPPLEMENTS,
        buildUser: true,
        title: `Please list each prescribed medication you currently take.`,
        description: `Separate each one with a new line. Include dosage if you know it.`,
        field: {
          name: 'freeformMedications',
          placeholder: 'Type medications here ...',
        },
      },
      [Step.HAS_SUPPLEMENTS]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: (patient) => {
          if (patient?.hasSupplements) {
            return Step.SUPPLEMENTS
          } 
          else {
            return Step.BIOMETRICS
          }
        },
        buildUser: true,
        title: 'Are there any vitamins, supplements, or over-the-counter medications you take regularly?',
        description: 'e.g. creatine, advil, vitamin D, protein powder etc.',
        field: {
          name: "hasSupplements",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.SUPPLEMENTS]: {
        type: StepType.TEXTAREA,
        nextStep: Step.BIOMETRICS,
        buildUser: true,
        title: `Please list each vitamin, supplement, or over-the-counter medication you currently take.`,
        description: 'Separate each one with a new line. Include dosage if you know it.',
        field: {
          name: 'freeformSupplements',
          placeholder: 'Type supplements here ...',
        }
      },
      [Step.BIOMETRICS]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.FAMILY_HISTORY,
        buildUser: true,
        title: `What's your current height and weight?`,
        description: `Please list the reading of when you were last measured.`,
        fields: [{
          name: 'height',
          label: 'Height (inches)',
          placeholder: 'Type your height here...',
        }, {
          name: 'weight',
          label: 'Weight (lbs)',
          placeholder: 'Type your weight here...',
        }],
        skipIf: () => {
          return initialUser?.height &&  initialUser?.weight
        }
      },
      [Step.FAMILY_HISTORY]: {
        type: StepType.MULTIPLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.familyHistory?.includes(MedicalCondition.CANCER)) {
            return Step.FAMILY_HISTORY_CANCER
          }
          else {
            return Step.ETHNICITY
          }
        },
        buildUser: true,
        title: <>Which medical conditions has your immediate family been diagnosed with <u>before age 60</u>?</>,
        description: 'This includes any grandparents, parents, or siblings. Select all that apply.',
        field: {
          name: 'familyHistory',
          options: Object.values(MedicalCondition)
                          .filter(value => familyHistoryConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label))
        },
      },
      [Step.FAMILY_HISTORY_CANCER]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.ETHNICITY,
        buildUser: true,
        title: `What cancers has your immediate family been diagnosed with?`,
        description: 'Select all that apply.',
        field: {
          name: 'familyHistory',
          options: [...Object.values(cancerConditions)
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label)), {label:'Other', value: 'Other'}]
        },
      },
      [Step.ETHNICITY]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.DIET,
        buildUser: true,
        title: `What is your ethnicity?`,
        description: 'Select all that apply.',
        field: {
          name: 'ethnicity',
          options: Object.values(Ethnicity).sort((a, b) => a.localeCompare(b)).map(value => ({label: value, value: value}))
        },
        skipIf: () => {
          return initialUser?.ethnicity
        }
      },
      [Step.DIET]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.SMOKING,
        buildUser: true,
        title: `What diets do you current follow?`,
        description: 'Select all that apply.',
        field: {
          name: 'diet',
          options: Object.values(Diet)
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label))
        },
      },
      [Step.SMOKING]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        onNextStep: async (patient) => {
          if (patient?.hasPreviousSmoking) {
            return Step.CURRENT_SMOKING
          } else {
            return Step.ALCOHOL
          }
        },
        title: `Have you ever smoked or vaped nicotine regularly?`,
        field: {
          name: 'hasPreviousSmoking',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      [Step.CURRENT_SMOKING]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.ALCOHOL,
        title: `Do you currently smoke or vape nicotine regularly?`,
        field: {
          name: 'hasSmoking',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      [Step.ALCOHOL]: {
        type: StepType.SINGLE_INPUT,
        buildUser: true,
        nextStep: Step.EXERCISE,
        title: `How many alcoholic drinks do you usually consume per week?`,
        description: `1 alcoholic drink is equivalent to 1 medium glass of wine, 1 can of regular strength beer, or 1 shot of liquor.`,
        field: {
          name: 'alcoholQty',
          placeholder: 'Type quantity here ...',
        },
      },
      [Step.EXERCISE]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.STRENGTH,
        title: `Do you regularly exercise at least 3 hours per week?`,
        field: {
          name: 'exercises150PerWeek',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      [Step.STRENGTH]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.SLEEP_HOURS,
        title: `Do you regularly strength train with weights?`,
        field: {
          name: 'strength',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      [Step.SLEEP_HOURS]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.SLEEP_QUALITY,
        buildUser: true,
        title: `How many hours do you typically sleep per night?`,
        field: {
          name: 'sleepHours',
          placeholder: 'Type hours here ...',
        },
      },
      [Step.SLEEP_QUALITY]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.STRESS,
        title: `How would you characterize your sleep quality?`,
        field: {
          name: 'sleepQuality',
          options:Object.values(SleepQuality).map(value => ({label: value, value: value}))
        }
      },
      [Step.STRESS]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.MEDITATE,
        title: `Would you consider yourself a stressed person?`,
        field: {
          name: 'stressed',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      [Step.MEDITATE]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.TRACKERS,
        title: `Do you meditate regularly?`,
        field: {
          name: 'meditate',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },

      [Step.TRACKERS]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        nextStep: Step.PLANNING_CHILDREN,
        title: `What trackers do you currently use?`,
        description: 'Select all that apply',
        field: {
          name: 'trackers',
          options: Object.values(Tracker).map(value => ({label: value, value: value}))
        }
      },
      [Step.PLANNING_CHILDREN]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        onNextStep: async (patient) => {
          if (patient?.gender === Gender.FEMALE) {
            return Step.PREGNANCY
          } else {
            return Step.FILES
          }
        },
        title: `Are you planning to have children naturally?`,
        field: {
          name: 'planningChildren',
          options: [{
            label: 'Yes',
            value: true,
          }, {
          label: 'No',
            value: false,
          }]
        }
      },
      [Step.PREGNANCY]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.isPregnant) {
            return Step.FILES
          } else {
            return Step.MENOPAUSE
          }
        },
        buildUser: true,
        title: `Are you currently pregnant?`,
        field: {
          name: 'isPregnant',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.MENOPAUSE]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.menopause === "Postmenopause") {
            return Step.FILES
          } else {
            return Step.MENSTRUAL_CYCLE
          }
        },
        buildUser: true,
        title: 'How would you describe your current menstrual status?',
        field: {
          name: 'menopause',
          options: Object.values(Menopause).map(value => ({label: value, value: value}))
        }
      },
      [Step.MENSTRUAL_CYCLE]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.FILES,
        title: `Which of the following best describes your menstrual cycles?`,
        field: {
          name: 'menstrualCycle',
          options: Object.values(MenustralCycle).map(value => ({label: value, value: value}))
        }
      },
  
      [Step.FILES]: {
        type: StepType.UPLOAD,
        title: 'Last step: Upload previous test results (optional)',
        description: 'Please upload any health documents from the past year, such as lab results or DEXA scans, that could give us context on your current health so we can provide the best recommendations.',
        nextStep: Step.CONFIRM
      },
  
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `Done!`,
        description: `Thanks for filling this out. This will help us prepare for any future appointments and recommendations on how to improve your health.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmIntake />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return !isLoading && (
    <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      startIndex={0}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  )
}