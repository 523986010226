import FlowType from "../../enums/flowType.enum"
import StepType from "../flow/enums/stepType.enum"
import { Step as IntakeStep } from '../intakeFlow/intakeFlow.component'
import { Flow } from "../flow/flow.component"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import LongevityConsultIntro from "./custom/longevityConsultIntro/longevityConsultIntro.component"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service"
import MembershipHelper from "../../helpers/membership.helper"
import { useNavigate } from "react-router-dom"
import ProductHelper from "../../helpers/product.helper"

export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',
  SELECT_MEMBERSHIP: 'select-membership',
  PAY_MEMBERSHIP: 'pay-memership',
  PAY: 'pay',
  NAME: 'name',
  PHONE: 'phone',
  REASON: 'reason',
  CONSULT: 'schedule',
  CONFIRM: 'confirm',
}

export const LongevityConsultFlow = () => {
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [initialUser, setInitialUser] = useState()
  const navigate = useNavigate()


  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, productTypes, instalabMembership, flow, hasPass, initialUser])

  useEffect(() => {
    const fetchMembershipTypes = async () => {
      setMembershipTypes(await listMembershipTypes({
        filter: {
          code: {
            $in: [
              MembershipTypeCode.LONGEVITY,
              MembershipTypeCode.PREMIUM,
              MembershipTypeCode.PREMIUM_12MO,
              MembershipTypeCode.LONGEVITY_12MO
            ]
          }
        }
      }))
    }
    
    const fetchProductTypes = async () => {
      setProductTypes(await listProductTypes({
        filter: {
          code: {
            $in: [
              ProductTypeCode.CONSULT_LONGEVITY,
            ]
          }
        }
      }))
    }

    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }
  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return
    
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <LongevityConsultIntro footer={footer} productType={getProductType(ProductTypeCode.CONSULT_LONGEVITY)} />
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.SELECT_MEMBERSHIP,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Create your account to manage your sessions. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.LONGEVITY_CONSULT}`)}>Log in</a>.</>,
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass 
        }
      },
      [Step.SELECT_MEMBERSHIP]: {
        type: StepType.PRODUCT_SELECT,
        title: `Select your Instalab membership option.`,
        description: `Membership is required to access longevity physicians. Both plans include physician access, test discounts, care concierge and more. Core is pay-per-consult, while Premium covers all consults.`,
        nextStep: Step.PAY_MEMBERSHIP,
        buildFlow: true,
        field: {
          name: 'membershipTypeCode',
          required: true,
          options: [
            {
              title: getMembershipType(MembershipTypeCode.LONGEVITY)?.title,
              value: MembershipTypeCode.LONGEVITY,
              description: "Gives you longevity physician access ($99/session).",
              // description: getMembershipType(MembershipTypeCode.LONGEVITY)?.tagline,
              price: `$${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month`
            },
            {
              title: getMembershipType(MembershipTypeCode.PREMIUM)?.title,
              value: MembershipTypeCode.PREMIUM,
              description: "Gives you unlimited longevity physician access.",
              // description: getMembershipType(MembershipTypeCode.PREMIUM)?.tagline,
              price: `$${getMembershipType(MembershipTypeCode.PREMIUM)?.cost}/month`
            },
          ],
        },
        skipIf: () => {
          return instalabMembership || initialUser?.credits?.includes(ProductTypeCode.CONSULT_LONGEVITY)
        }
      },
      [Step.PAY_MEMBERSHIP]: {
        type: StepType.PAY,
        onNextStep: () => {
          if (flow?.membershipTypeCode === MembershipTypeCode.PREMIUM) return Step.NAME;
          return Step.PAY;
        },
        addSubscription: true,
        subscriptionType: flow?.membershipTypeCode,
        title: () => { 
          const selectedMembershipType = getMembershipType(flow?.membershipTypeCode);
          if (selectedMembershipType)
            return <>Activate your {selectedMembershipType?.title} for ${selectedMembershipType?.cost}/month.</>
          return 'Activate your membership'
        },
        description: <>Includes access to your own longevity physician, exclusive access to advanced diagnostics, dedicated care concierge and more. Easy to cancel anytime.</>,
        skipIf: () => {
          return instalabMembership || initialUser?.credits?.includes(ProductTypeCode.CONSULT_LONGEVITY);
        }
      },

      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.NAME,
        addProduct: true,
        productType: ProductTypeCode.CONSULT_LONGEVITY,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.CONSULT_LONGEVITY)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your longevity consultation.</>
        },
        description: `This is for a 30-minute video consultation with a longevity physician. You'll schedule your appointment in the next steps.`,
        skipIf: () => {
          return MembershipHelper.isPremium(instalabMembership)
        }
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.REASON,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or sessions.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.REASON]: {
        type: StepType.TEXTAREA,
        nextStep: Step.CONSULT,
        buildFlow: true,
        title: `What are questions or topics do you want to focus on during your session?`,
        description: 'This will help your longevity physician prepare for your consultation.',
        field: {
          name: 'consultReason',
          placeholder: 'Type here ...',
        }
      },

      [Step.CONSULT]: {
        type: StepType.CALENDLY,
        nextStep: Step.CONFIRM,
        addConsult: true,
        title: `Schedule your longevity consultation.`,
        description: `This session will be a 30-minute video call with Dr. Steven Winiarski who specializes in longevity medicine.`,
        showFooter: false,
        url: (process.env.REACT_APP_ENV === "local") ? 'https://calendly.com/instalab/devtest' : 'https://calendly.com/d/z2x-3dv-qxk',
      },

      // TO DO: share what you want to talk about

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `Appointment confirmed!`,
        path: `/flow/${FlowType.INTAKE}/${IntakeStep.GENDER}`,
        buttonText: 'Continue',
        description: `You'll receive an email with a calendar invite and a Zoom link for your session. Next we just need a little more information to help your physician prepare for your appointment.`,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  )
}