const QuestionTemplateCode = Object.freeze({
    GENDER: "GENDER",
    DOB: "DOB",
    ETHNICITY: "ETHNICITY",
    FAMILY_HISTORY: "FAMILY_HISTORY",
    CURRENT_MEDICAL_CONDITIONS: "CURRENT_MEDICAL_CONDITIONS",
    OTHER_CURRENT_MEDICAL_CONDITIONS: "OTHER_CURRENT_MEDICAL_CONDITIONS",
    ALL_MEDICAL_CONDITIONS: "ALL_MEDICAL_CONDITIONS",
    FREEFORM_MEDICATIONS: "FREEFORM_MEDICATIONS",
    FREEFORM_SUPPLEMENTS: "FREEFORM_SUPPLEMENTS",
    ALLERGIES: "ALLERGIES",
    SURGERIES: "SURGERIES",

    DIET: "DIET",
    SMOKING: "SMOKING",
    PREVIOUS_SMOKING: "PREVIOUS_SMOKING",
    EXERCISE: "EXERCISE",
    STRENGTH: "STRENGTH",
    SLEEP_HOURS: "SLEEP_HOURS",
    SLEEP_QUALITY: "SLEEP_QUALITY",
    STRESS: "STRESS",
    TRACKERS: "TRACKERS",
    ALCOHOL_QTY: "ALCOHOL_QTY",
    MEDITATE: "MEDITATE",
    PLANNING_CHILDREN: "PLANNING_CHILDREN",
    
    // Female only
    PREGNANCY: "PREGNANCY",
    MENOPAUSE: "MENOPAUSE",
    MENUSTRAL_CYCLE: "MENUSTRAL_CYCLE",
    BIRTH_CONTROL: "BIRTH_CONTROL",

    // Hazardous exposure
    HAZARDOUS_EXPOSURE: "HAZARDOUS_EXPOSURE",
    POSSIBLE_TOXIC_EXPOSURE: "POSSIBLE_TOXIC_EXPOSURE",
    POSSIBLE_MOLD_EXPOSURE: "POSSIBLE_MOLD_EXPOSURE",

    // STAFF ONLY
    VERY_MUSCULAR: "VERY_MUSCULAR",
    RECENT_INJURY: "RECENT_INJURY",
    ALCOHOL: "ALCOHOL",
    LAST_PERIOD_DATE: "LAST_PERIOD_DATE",
    RECENT_VIRAL_INFECTION: "RECENT_VIRAL_INFECTION",
    CURRENT_SUPPLEMENTS: "CURRENT_SUPPLEMENTS", 
    CURRENT_MEDICATIONS: "CURRENT_MEDICATIONS",


});

export default QuestionTemplateCode;
