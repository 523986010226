import React, { useContext, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { addImpersonation } from '../../services/impersonation.service';
import { Button, Tooltip, message, Avatar, Modal } from 'antd'
import { listPhlebotomists, removePhlebotomist } from "../../services/phlebotomist.service"
import { DeleteOutlined, EditOutlined, KeyOutlined, EnvironmentOutlined, CalendarOutlined, LockOutlined } from '@ant-design/icons'
import './phlebotomists.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { PhlebotomistForm } from '../phlebotomistForm/phlebotomistForm.component';
import { AvailabilityModal } from '../availabilityModal/availabilityModal.component';
import { ChangePasswordModal } from '../changePasswordModal/changePasswordModal.component';
import ObjectType from '../../enums/objectType.enum';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component';
import { UserContext } from '../../contexts/user.context.js';
import Role from '../../enums/role.enum.js';

const select = '_id firstName lastName email phone location photo'
const populate = []

export const Phlebotomists = () => {
  const [phlebotomists, setPhlebotomists] = useState([])
  const [phlebotomistId, setPhlebotomistId] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isEditModalOpen, setIsEditModalOpen] = useState()
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState()
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState()
  const [isAdminLocationModalOpen, setIsLocalModalOpen] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const { setCounts, currentUser } = useContext(UserContext)

  useEffect(() => {
    document.title = 'Instalab | Phlebotomists'
    fetchPhlebotomists()
  }, [])

  const fetchPhlebotomists = async () => {
    setIsLoading(true)
    const fetchedPhlebotomists = await listPhlebotomists({
      select,
      filter: {},
      populate,
      sort: '-createdAt'
    })
    setPhlebotomists(fetchedPhlebotomists)
    setFilteredCount(fetchedPhlebotomists.length)
    setIsLoading(false)
  }

  const onImpersonate = async (_id) => {
    try {
      const impersonate = await addImpersonation({ user: _id })
      copy(`${process.env.REACT_APP_CLIENT_URL}/impersonate/${impersonate._id}`)
      message.success('Copied login link')
    } catch (err) {
      message.error('Failed to generate login link')   
    }
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this phlebotomist?',
      content: 'Deleting this phlebotomist will remove them permanently from your system.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removePhlebotomist(_id);
          setPhlebotomists(phlebotomists.filter(phlebotomist => phlebotomist._id !== _id));
          message.info('Phlebotomist removed');
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              phlebotomists: cachedCounts.phlebotomists - 1
            }
          })
          setFilteredCount(cachedFilterCount => cachedFilterCount-1)
        } catch (err) {
          message.error('Failed to remove phlebotomist');
        }
      }
    });
  }

  const getActionItems = ({ _id }) => [{
    key: '1',
    label: (
      <Button onClick={() => {
        setPhlebotomistId(_id)
        setIsAvailabilityModalOpen(true)
      }}>
        <CalendarOutlined /> Availability
      </Button>
    )
  }, {
    key: '2',
    label: (
      <Button onClick={() => {
        setPhlebotomistId(_id)
        setIsLocalModalOpen(true)
      }}>
        <EnvironmentOutlined /> Set Location
      </Button>
    )
  }, 
  (currentUser?.role === Role.ADMIN) && {
    key: 'impersonate',
    label: (
      <Button onClick={() => onImpersonate(_id)}>
        <KeyOutlined /> Impersonate
      </Button>
    ),
  }, {
    key: '3',
    label: (
      <Button onClick={() => {
        setPhlebotomistId(_id)
        setIsPasswordModalOpen(true)
      }}>
        <LockOutlined /> Change Password
      </Button>
    )
  }, {
    key: '4',
    label: (
      <Button onClick={() => {
        setPhlebotomistId(_id)
        setIsEditModalOpen(true)
      }}>
        <EditOutlined /> Edit
      </Button>
    )
  }, {
    type: 'divider'
  }, 
  
  (currentUser?.role === Role.ADMIN) && {
    key: '6',
    label: (
      <Button 
        onClick={() => onRemove(_id)}
        className="remove-item"
      >
        <DeleteOutlined /> Remove
      </Button>
    )
  }]

  const getCustomFilter = (phlebotomist, value) => {
    return {
      location: () => phlebotomist.location?.city && phlebotomist.location.city.toLowerCase().includes(value.toLowerCase()),
      firstName: () => `${phlebotomist.firstName} ${phlebotomist.lastName}`.toLowerCase().includes(value.toLowerCase()),
      phone: () => phlebotomist.phone && phlebotomist.phone.replace(/\D/g,'').includes(value.replace(/\D/g,'')),
    }
  }

  return (
    <div className="phlebotomists">
      <PageHeader
        title='Phlebotomists'
        count={filteredCount}
        actions={(
          <Button
            type='primary'
            onClick={() => {
              setPhlebotomistId(null)
              setIsEditModalOpen(true)
            }}
          >
            + Add New Phlebotomist
          </Button>
        )}
      />

      <PhlebotomistForm
        phlebotomistId={phlebotomistId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        select={select}
        populate={populate}
        onSuccess={phlebotomist => {
          if (phlebotomistId) {
            setPhlebotomists(phlebotomists.map(p => p._id === phlebotomistId ? phlebotomist : p))
          } else {
            setPhlebotomists([
              phlebotomist,
              ...phlebotomists
            ])
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                phlebotomists: cachedCounts.phlebotomists + 1
              }
            })
            setFilteredCount(cachedFilterCount => cachedFilterCount+1)
          }
        }}
      />

      <AdminLocationModal
        objectId={phlebotomistId}
        objectType={ObjectType.PHLEBOTOMIST}
        open={isAdminLocationModalOpen}
        setOpen={setIsLocalModalOpen}
        select={select}
        populate={populate}
        onSuccess={phlebotomist => {
          setPhlebotomists(phlebotomists.map(p => p._id === phlebotomistId ? phlebotomist : p))
        }}
      />

      <AvailabilityModal
        phlebotomistId={phlebotomistId}
        open={isAvailabilityModalOpen}
        setOpen={setIsAvailabilityModalOpen}
      />

      <ChangePasswordModal
        objectId={phlebotomistId}
        objectType={ObjectType.PHLEBOTOMIST}
        open={isPasswordModalOpen}
        setOpen={setIsPasswordModalOpen}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={phlebotomists}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[{
          dataIndex: 'photo',
          className: "photo-col",
          width: 50,
          render: photo => photo && <Avatar className="user-photo" src={`${photo}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} />
        }, {
          title: 'Name',
          dataIndex: 'firstName',
          render: (firstName, { lastName }) => `${firstName} ${lastName}`,
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Email Address',
          dataIndex: 'email',
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Phone Number',
          dataIndex: 'phone',
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Location',
          dataIndex: 'location',
          filterDropdownType: FilterDropdownType.INPUT,
          render: (location) => {
            if (!location) return null
            const { streetAddress, streetAddress2, city, state, postalCode } = location
            const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
            return <Tooltip title={fullAddress}>{city}</Tooltip>
          }
        }]}
      />
    </div>
  )
}