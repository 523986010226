import React from "react";
import { Typography, Button } from 'antd'
import { ArrowLeftOutlined, LeftOutlined } from '@ant-design/icons';
import { ContinueButton } from "../continueButton/continueButton.component";
import "./bookAppointmentNav.scss";

const { Text } = Typography

export const BookAppointmentNav = ({ 
  previous=false,
  onPrevStep,
  isSubmitting,
  onContinue,
  continueText='Continue'
}) => {
  return (
    <div className="book-appointment-nav">
      {previous && (
        <span className="previous-actions">
          <span className="hide-mobile">
            <Button 
              onClick={onPrevStep}
              className="prev-btn"
            >
              <ArrowLeftOutlined className="arrow-icon" />
              <Text className="prev-btn-text">
                Previous
              </Text>
            </Button>
          </span>

          <span className="hide-desktop">
            <a 
              onClick={onPrevStep}
              className="previous-link"
            >
              <LeftOutlined />
              Previous
            </a>
          </span>
        </span>
      )}

      <ContinueButton
        isSubmitting={isSubmitting}
        text={continueText}
        onClick={onContinue}
      />      
    </div>
  )
}