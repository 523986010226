import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Input, message } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import { addStaff, getStaff, updateStaff } from '../../services/staff.service';
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper';
import "./staffForm.scss"

const { Item } = Form

export const StaffForm = ({ open, setOpen, onSuccess, staffId, select, populate=[] }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (open) {
      if (staffId) setStaffFields()
    } else {
      form.resetFields()
      setHasAttempt(false)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const onFail = () => {
    setHasAttempt(true)
    message.error('Enter valid staff data')
  }

  const setStaffFields = async () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      slack,
    } = await getStaff(staffId, {
      select: 'firstName lastName email phone slack',
      populate: []
    })

    form.setFieldsValue({
      firstName,
      lastName,
      email,
      phone,
      slack,
    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {      
      const params = {
        fields: form.getFieldsValue(),
        select,
        populate,
      }
      const response = staffId ? await updateStaff(staffId, params) : await addStaff(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(staffId ? 'Staff updated' : 'Staff added')
      setOpen(false)
      if (!staffId) {
        form.resetFields()
      }
    } catch (err) {
      let msg = staffId ? 'Failed to update staff' : 'Failed to add staff'
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email'
      }
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  return (
    <Modal 
      open={open} 
      title={staffId ? 'Edit Staff' : 'Add Staff'}
      onCancel={onCancel}
      footer={null}
      className="staff-form"
    >
      <Form
        form={form}
        onFinish={onOk}
        onFinishFailed={onFail}
        layout='vertical'
      >
        <Item 
          label="First Name"
          name="firstName"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="First Name" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Last Name"
          name="lastName"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Last Name" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Email Address"
          name="email"
          onInput={e => e.target.value = e.target.value.toLowerCase()}
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isEmail,
            RuleHelper.isUniqueEmail({
              fieldName: 'email',
              userId: staffId,
            })
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Email Address" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Phone Number"
          name="phone"
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isPhone,
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Slack"
          name="slack"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Slack" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <ContinueButton
          text='Save Staff'
          isSubmitting={isSubmitting}
        />
      </Form>
    </Modal>
  )
}