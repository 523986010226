import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Select, Typography } from 'antd'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import Color from '../../colors.scss'
import annotationPlugin from "chartjs-plugin-annotation";
import RiskLevel from '../../enums/riskLevel.enum';
import PatientHelper from '../../helpers/patient.helper';
import "./patientSpreadModal.scss"
import classNames from 'classnames';
import Gender from '../../enums/gender.enum';
import UrlHelper from '../../helpers/url.helper';

const { Text } = Typography

ChartJS.register(
  annotationPlugin,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const PatientSpreadModal = ({ open, setOpen, data, setData, tests, tableData }) => {
  const [chartOptions, setChartOptions] = useState()
  const [chartData, setChartData] = useState()
  const [legend, setLegend] = useState()
  const [abnormalBreakdown, setAbnormalBreakdown] = useState()
  const [patientId, setPatientId] = useState()

  useEffect(() => {
    setChartOptions(getChartOptions())
    setLegend(getLegend())
    setAbnormalBreakdown(getAbnormalBreakdown())
  }, [data])

  useEffect(() => {
    setChartData(getChartData())
  }, [data, patientId])

  const getChartAnnotations = () => {
    if (data?.hasGenderSpecificRisk) return null
    
    return data?.risk?.map(({ 
      level,
      lessThan, 
      lessThanOrEqual, 
      greaterThanOrEqual, 
      greaterThan 
    }) => {
      const min = greaterThan || greaterThanOrEqual || 0
      const max = lessThan || lessThanOrEqual || getMax()
      
      return {
        type: 'box',
        xMin: 10,
        xMax: 80,
        yMin: min,
        yMax: max,
        borderColor: 'rgba(0,0,0,0)',
        backgroundColor: riskBackground[level],
      }
    })
  }

  const getLegend = () => {
    if (!data) return null
    const patients = getPatients()
    return Object.values(RiskLevel).filter(level => {
      return patients.some(({ risk }) => risk === level)
    }).map(level => {
      const count = patients.filter(({ risk }) => risk === level).length
      const percentage = Math.round(count/patients.length*100)
      return {
        level,
        count,
        percentage
      }
    })
  }

  const getMax = () => {
    if (!data) return null

    const { abnormalPatients = [], healthyPatients = [] } = data;
    let patientMax = 0
    
    for (const { value } of abnormalPatients) {
      patientMax = value > patientMax ? value : patientMax
    }
    for (const { value } of healthyPatients) {
      patientMax = value > patientMax ? value : patientMax
    }

    let rangeMax = 0
    for (const { greaterThan, greaterThanOrEqual, lessThan, lessThanOrEqual } of data?.risk || []) {
      const localMax = lessThan || lessThanOrEqual || greaterThan || greaterThanOrEqual
      if (localMax > rangeMax) {
        rangeMax = localMax
      }
    }

    const maximum = rangeMax > patientMax ? rangeMax : patientMax
    return maximum * 1.1
  }

  const riskColor = {
    [RiskLevel.ABNORMAL]: Color.error,
    [RiskLevel.HIGH]: Color.error,
    [RiskLevel.OPTIMAL]: Color.success,
    [RiskLevel.MODERATE]: Color.warning,
  }

  const riskBackground = {
    [RiskLevel.ABNORMAL]: Color.error_bg_transparent,
    [RiskLevel.HIGH]: Color.error_bg_transparent,
    [RiskLevel.OPTIMAL]: Color.success_bg_transparent,
    [RiskLevel.MODERATE]: Color.warning_bg_transparent,
  }

  const getPatients = () => {
    const abnormalPatients = data?.abnormalPatients || []; // Default to an empty array if undefined
    const healthyPatients = data?.healthyPatients || [];   // Default to an empty array if undefined
    
    return [...abnormalPatients, ...healthyPatients];
  };

  const getChartData = () => {
    if (!data) return null
    const patients = getPatients()
    
    return {
      datasets: [{
        label: '1',
        data: patients.map(patient => {
          return {
            x: PatientHelper.getAge(patient),
            y: patient.value
          }
        }),
        backgroundColor: patients.map(patient => {
          return patient._id === patientId ? riskColor[patient.risk] : riskBackground[patient.risk]
        }),
        pointRadius: patients.map(patient => {
          return patient._id === patientId ? 4 : 2
        }),
        pointHoverRadius: patients.map(patient => {
          return patient._id === patientId ? 6 : 4
        }),
        borderColor: patients.map(({ risk }) => {
          return riskColor[risk]
        }),
        borderWidth: 1,
      }]
    }
  }

  const tooltipLabel = (context) => {
    const patients = getPatients()
    const { dataIndex, parsed } = context
    const patient = patients[dataIndex]
    const { firstName, lastName } = patient
    const { x: age, y: testValue } = parsed
    return `${testValue}: ${firstName} ${lastName} (${age} yr)`
  }

  const getChartOptions = () => {
    if (!data) return null
    const annotations = getChartAnnotations()
    const max = getMax()
    return {
      responsive: true,
      onClick: (event, element) => {
        if (!element?.length) return null
        const { index } = element[0]
        const patients = getPatients()
        window.open(UrlHelper.getPatientProfile(patients[index]._id, 'Results'))
      },
      plugins: {
        legend: {
          display: false
        },
        annotation: {
          annotations
        },
        tooltip: {
          callbacks: {
            label: tooltipLabel,
          }
        },
      },
      scales: {
        // Test value
        y: {
          min: 0,
          max,
          border: {
            dash: [2,4],
          },
          afterTickToLabelConversion: function(scaleInstance) {
            scaleInstance.ticks[scaleInstance.ticks.length - 1].label = '';
          }
        },
        // Age
        x: {
          min: 10,
          max: 80,
          grid: {
            color: 'rgba(0,0,0,0)'
          },
        }
      }
    }
  }

  const onCancel = () => {
    setOpen(false)
  }

  const getOptimalMiddle = (gender) => {
    let { risk, maleRisk, femaleRisk, hasGenderSpecificRisk } = data
    if (hasGenderSpecificRisk) {
      risk = gender === Gender.MALE ? maleRisk : femaleRisk
    }
    const {
      greaterThan,
      greaterThanOrEqual,
      lessThan,
      lessThanOrEqual
    } = risk.find(({ level }) => level === RiskLevel.OPTIMAL)
    const optimalMin = greaterThan || greaterThanOrEqual || 0
    const optimalMax = lessThan || lessThanOrEqual || optimalMin
    return (optimalMin + optimalMax) / 2
  }

  const getAbnormalBreakdown = () => {
    if (!data) return null
    let { risk, maleRisk, hasGenderSpecificRisk, abnormalPatients=[] } = data
    risk = hasGenderSpecificRisk ? maleRisk : risk
    const levels = risk?.filter(({ level }) => level === RiskLevel.ABNORMAL) || []
    if (levels.length <= 1) return null
    const patients = getPatients()

    const low = abnormalPatients.filter(({ gender, value }) => {
      const optimalMiddle = getOptimalMiddle(gender)
      return value <= optimalMiddle
    }).length
    const high = abnormalPatients.length - low
    return { 
      high: {
        count: high,
        percentage: Math.round(high/patients.length*100)
      }, 
      low: {
        count: low,
        percentage: Math.round(low/patients.length*100)
      }
    }
  }

  return (
    <Modal 
      className="patient-spread-modal"
      open={open} 
      title={data && (
        <>
          <Select 
            placeholder="Select test..."
            value={data._id}
            options={tests?.map(test => {
              return {
                label: test.name,
                value: test._id
              }
            })}
            optionFilterProp="children"
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            onChange={testId => setData(tableData.find(({ _id }) => _id === testId))}
            className="test-select"
            showSearch
          />
          <Select 
            placeholder="Select patient..."
            options={getPatients()?.map(patient => {
              return {
                label: `${patient.firstName} ${patient.lastName}`,
                value: patient._id
              }
            })}
            optionFilterProp="children"
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            onChange={value => setPatientId(value)}
            className="patient-select"
            showSearch
          />
        </>
      )}
      onCancel={onCancel}
      okText='Save'
      footer={null}
      width={1100}
    >
      <Row>
        <Col span={21}>
          {chartOptions && chartData && (
            <Scatter
              options={chartOptions}
              data={chartData}
              height={150}
            />
          )}
        </Col>

        <Col span={3} className="legend-col">
          {legend?.map(({ level, count, percentage }, index) => (
            <div key={`legend-${index}`}>
              <div className={classNames("legend-item", `${level}-legend-item`)}>
                <div 
                  className="legend-color"
                  style={{
                    backgroundColor: riskColor[level]
                  }}
                >
                  {count}
                </div>
                <Text className="legend-title">
                  {level}
                </Text>
                <Text className="legend-percentage">
                  {percentage}%
                </Text>
              </div>
              {level === RiskLevel.ABNORMAL && abnormalBreakdown && (
                <div className="abnormal-breakdown">
                  <div className={classNames("legend-item", `breakdown-legend-item`)}>
                    <div 
                      className="legend-color"
                      style={{
                        backgroundColor: Color.error_bg,
                        color: Color.error,
                      }}
                    >
                      {abnormalBreakdown.high.count}
                    </div>
                    <Text className="legend-title">
                      high
                    </Text>
                    <Text className="legend-percentage">
                      {abnormalBreakdown.high.percentage}%
                    </Text>
                  </div>

                  <div className={classNames("legend-item", `breakdown-legend-item`)}>
                    <div 
                      className="legend-color"
                      style={{
                        backgroundColor: Color.error_bg,
                        color: Color.error,
                      }}
                    >
                      {abnormalBreakdown.low.count}
                    </div>
                    <Text className="legend-title">
                      low
                    </Text>
                    <Text className="legend-percentage">
                      {abnormalBreakdown.low.percentage}%
                    </Text>
                  </div>
                </div>
              )}
            </div>
          ))}
        </Col>
      </Row>
    </Modal>
  )
}