import { Typography, Row, Col, Tag }  from 'antd'
import './panels.scss'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import PhotoHelper from '../../helpers/photo.helper';
import { listProductTypes } from '../../services/productType.service';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import { UserContext } from '../../contexts/user.context';
import { useContext } from 'react';
import { getMe } from '../../services/user.service';
const { Paragraph } = Typography

const Panels = ({ productTypeCodes: initialProductTypeCodes, excludeCodes = [] }) => {
    
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [productTypes, setProductTypes] = useState()
  const [currentUser, setCurrentUser] = useState()
  const [activeProductTypeCodes, setActiveProductTypeCodes] = useState([
    ProductTypeCode.HEART_HEALTH_TEST_KIT,
    ProductTypeCode.LONGEVITY_PANEL,
    ProductTypeCode.GALLERI,
    ProductTypeCode.WATCHPAT_ONE,
    ProductTypeCode.CTCALCIUM
  ])

  useEffect(() => {
    setActiveProductTypeCodes(initialProductTypeCodes || [
      ProductTypeCode.HEART_HEALTH_TEST_KIT,
      ProductTypeCode.LONGEVITY_PANEL,
      ProductTypeCode.GALLERI,
      ProductTypeCode.WATCHPAT_ONE,
      ProductTypeCode.CTCALCIUM
    ])
    fetchCurrentUser()
    fetchProductTypes()
  }, [initialProductTypeCodes])

  const fetchCurrentUser = async () => {
    if (!token) return
    setCurrentUser(await getMe({
      select: '_id credits'
    }))
  }

  const fetchProductTypes = async () => {


    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: activeProductTypeCodes.filter(code => !excludeCodes.includes(code))
        }
      },
  
    }))
  }

  return (
    productTypes && <div className="panels">

      <div className="tests-header">
        <div className="tests-header-border"></div>
        <Paragraph className="tests-header-text">
          Explore Popular Tests
        </Paragraph>
        <div className="tests-header-border"></div>
      </div>
      <Row gutter={12}>
        {productTypes?.sort((a, b) => {
          return activeProductTypeCodes.findIndex(code => code === a.code) - activeProductTypeCodes.findIndex(code => code === b.code)
        }).slice(0, 4).map(({ code, path, flowType, title, tagline, cost, photos }) => (
          <Col 
            key={`panel-${code}`}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <div 
              className="panel"
              onClick={() => navigate(path ? path : `/flow/${flowType}`)}
            >
              <div className="panel-img-container">
                {PhotoHelper.format({
                  jpg: photos[0].jpg,
                  webp: photos[0].webp,
                  className:"panel-img"
                })}

                <ArrowRightOutlined className="panel-icon" />
              </div>

              <Paragraph className="panel-title">
                {title} 
              </Paragraph>

              {currentUser?.credits?.includes(code) && <Paragraph className="panel-credit-tag">
                <Tag color="blue">
            {currentUser.credits.filter(credit => credit === code).length} free credit{currentUser.credits.filter(credit => credit === code).length > 1 ? 's' : ''}
          </Tag>
                </Paragraph>
              }

              <Paragraph className="panel-description">
                {tagline} 
              </Paragraph>
              {/* <Text className="panel-discount-price">
                ${ProductHelper.getMemberPrice(cost)}
              </Text> */}
              {/* <Text className="panel-original-price">
                ${cost}
              </Text> */}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Panels;