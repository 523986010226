import React from "react";
import "./productDetail.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import { useMediaQuery } from 'react-responsive'; 
import { ProductTestimonials } from "./productTestimonials/productTestimonials.component";
import { ProductHero } from "./productHero/productHero.component";
import { ProductQuestions } from "./productQuestions/productQuestions.component";
import { ProductOverview } from "./productOverview/productOverview.component";
import { BiomarkerPanel } from "./biomarkerPanel/biomarkerPanel.component";
import { ProductDirections } from "./productDirections/productDirections.component";
import { XPanel } from "./xPanel/xPanel.component";
import { ProductTimeline } from "./productTimeline/productTimeline.component";
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { SmallBiomarkerPanel } from "./smallBiomarkerPanel/smallBiomarkerPanel.component";
import { XGrid } from "./xGrid/xGrid.component";
import { FeaturePost } from "./featurePost/featurePost.component";
export const ProductDetail = ({sections, className}) => {

    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 990px)' });
    const onChat = () => { window.FrontChat("show"); };

    const getBackgroundClass = (index, isMobileOrTablet) => {
        if (index === 0) return isMobileOrTablet ? "no-bg" : "dark-bg";
    
        return isMobileOrTablet 
            ? (index % 2 === 0 ? "no-bg" : "gray-bg")
            : (index % 2 === 0 ? "gray-bg" : "no-bg") 
    };
    return (<div className={`product-detail ${className}`}>

        {sections.map((section,index)=>
            section.type && (
            <div key={`section-${section.type}-${index}`} className={`section ${getBackgroundClass(index, isMobileOrTablet)} section-${section.type}`}>
                {section.type === ProductDetailSection.HERO && 
                    <div className="section-container">
                        <ProductHero product={section.data} isMobileOrTablet={isMobileOrTablet}/>
                    </div>
                }

                {section.type === ProductDetailSection.TESTIMONIALS && 
                    <div className="section-container product-detail-testimonial-section-container">
                        <ProductOverview title={section.title ? <>{section.title}</> : <>Hear it from real customers</>}/>
                        <ProductTestimonials testimonials={section.data}/> 
                    </div>                    
                }

                {section.type === ProductDetailSection.DIRECTIONS && 
                    <div className="section-container product-detail-directions-section-container">
                        <ProductOverview title={<>How it works</>}/>
                        <ProductDirections steps={section.data}/>
                    </div>
                }

                {section.type === ProductDetailSection.FAQ && 
                    <div className="section-container">
                        <ProductOverview title={section.title ? <>{section.title}</> : <>Frequently asked questions</>} description={<>If you have any questions,  <a onClick={onChat}>we'd love to chat</a>.</>}/>
                        <ProductQuestions questions={section.data}/> 
                    </div>
                }

                {section.type === ProductDetailSection.PANEL && 
                    <div className="section-container" ref={section.data.panelRef}>
                        <ProductOverview title={<>{section.data.title? section.data.title : "Tests included"}</>}/>
                        <BiomarkerPanel 
                            panel={section.data.panel}
                            lookup={section.data.lookup}/>
                    </div>
                }

                {section.type === ProductDetailSection.XPANEL && 
                    <div className="section-container product-detail-xpanels-section-container">
                        <ProductOverview title={section.title} description={section.description}/>
                        <XPanel panels={section.data}/>
                    </div>
                }

                {section.type === ProductDetailSection.XGRID && 
                    <div className="section-container product-detail-grids-section-container">
                        <ProductOverview title={section.title} description={section.description}/>
                        <XGrid panels={section.data} className={section.className}/>
                    </div>
                }

                {section.type === ProductDetailSection.TIMELINE && 
                    <div className="section-container product-detail-timeline-section-container">
                        <ProductTimeline title={section.title} description={section.description} panels={section.data}/>
                    </div>
                }

                {section.type === ProductDetailSection.SMALLPANEL && 
                    <div className="section-container" ref={section.data.panelRef}>
                        <ProductOverview title={<>{section.title ? section.title : "Tests included"}</>} description={section.data.panel.description} />
                        <SmallBiomarkerPanel panel={section.data.panel}/>
                    </div>
                }

                {section.type === ProductDetailSection.FEATURE_POST && 
                    <div className="section-container">
                        <FeaturePost blogId={section.data.blogId} blogImage={section.data.blogImage} />
                    </div>
                }


            </div>)
            
        )}

        { isMobileOrTablet && <div className={`footer-buffer ${getBackgroundClass(sections.length-1, true)}`}></div> }


    </div>)
}