import LatestPosts from "../latestPosts/latestPosts.component.js"
import { Typography, Row, Col }  from 'antd'
import Breakpoint from '../../enums/breakpoint.enum.js'
import useWidth from "../../hooks/useWidth.hook.js"
import Panels from '../panels/panels.component.js'
const { Text, Paragraph } = Typography

export const ConfirmReferral = () => {
  const width = useWidth()

  const instructions = [
    {
      sections: [{
        title: 'Specialist Matching',
        description: <>Our concierge team will carefully research and identify the most qualified specialists in your area based on your needs.</>
      }]
    }, 
    {
      sections: [{
        title: 'Coordinated Scheduling',
        description: <>Our concierge team will handle all appointment logistics and coordinate with your chosen specialist's office.</>
      }]
    },
    {
      sections: [{
        title: 'Comprehensive Follow-Up Care',
        description: <>We'll stay engaged throughout, helping review results, coordinate follow-up appointments, and ensure all your medical needs are met.</>
      }]
    }
  ]

  return (
    <div className="confirm-step">

      <div className="next-steps-title">
        Next steps:
      </div>

      {instructions?.length > 0 && (
        <div className="instruction-list">
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}

      <Panels />

      <LatestPosts />
    </div>
  )
}