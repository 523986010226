import { Typography, Row, Col, Tag }  from 'antd'
import Breakpoint from "../../enums/breakpoint.enum";
import useWidth from '../../hooks/useWidth.hook';

const { Text, Paragraph } = Typography

const AthleteConsultIntro = ({ footer, productType }) => {
  const width = useWidth()

  const instructions = [{
    sections: [{
      title: 'Book Your Session',
      description: <>Select a date and time that fits your schedule for a consultation.</>
    }, 
  ]
  }, 
  {
    sections: [{
      title: 'Meet With Physician',
      description: <>Connect with your physician via Zoom or phone. We’ll take care of the notes and can provide you with a summary afterward.
</>
    }]
  }, 

  {
    sections: [{
      title: 'Ongoing Support',
      description: <>Schedule follow-ups or reach out to your physician as needed to review progress, adjust your plan, and ensure you’re on track with your long-term goals.</>
    }]
  }, 
  ]
  return (
    <div 
      className="flow-intro"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >

      <div 
        className="flow-intro-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
      <Paragraph 
          className="flow-title"
          style={{
            fontSize: width > Breakpoint.SM ? 32 : 28,
          }}
        >
           {productType?.title} 
        </Paragraph>

        <Paragraph 
          className="flow-description"
          style={{
            fontSize: width > Breakpoint.SM ? 16 : 14,
          }}
        >
         Collaborate with a physician experienced in working with professional athletes to evaluate your current health and create a personalized, long-term health plan.

        </Paragraph>

        <div 
          className="instruction-list"
          style={{
            marginTop: width > Breakpoint.SM ? 50 : 30,
            marginBottom: width > Breakpoint.SM ? -15 : -25,
            paddingTop: width > Breakpoint.SM ? 25 : 15,
          }}
        >
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>

        <div className="flow-intro-btn-container">
          <div className="flow-intro-btn">
            {footer({ 
              buttonText: "Let's go",
              buttonCheck: false,
              buttonStyle: {
                height: 50,
                fontSize: 18,
                width: '100%',
                display: 'block',
              },
              enterStyle: {
                display: 'none',
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AthleteConsultIntro