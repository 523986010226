import { useEffect } from 'react'
import { Form } from "antd"
import { useNavigate } from 'react-router-dom'
import "./static.scss"
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'
import _ from 'lodash'


export const Static = ({
  step,
  onSubmit,
  form,
  isSubmitting,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        step.path ? navigate(step.path) : onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div className="static">
      <Form
        form={form}
        className="static-form"
      >
        {step.content}

        {step.showFooter !== false && (
          <FlowStepFooter 
            onSubmit={() => step.path ? navigate(step.path) : onSubmit()} 
            isSubmitting={isSubmitting}
            buttonText={step.buttonText}  
            buttonStyle={step.buttonStyle}
            enterStyle={step.enterStyle}
          /> 
        )}
      </Form>
    </div>
  )
}