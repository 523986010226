import React, { useContext, useEffect, useState } from 'react';
import { startCase, toLower } from 'lodash';
import { Table, Tooltip, Typography, Card } from 'antd'
import moment from 'moment'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { TrackingHelper } from "../../helpers/tracking.helper.js"
import "./testkits.scss"
import { UserContext } from '../../contexts/user.context.js';
import ShippingHelper from '../../helpers/shipping.helper.js';
import { listProducts } from '../../services/product.service.js';
import classNames from 'classnames';
import ScheduleMenu from '../scheduleMenu/scheduleMenu.component.js';
import { getMe } from '../../services/user.service.js';
import ProductTypeCode from '../../enums/productTypeCode.enum.js';
import { listProductTypes} from '../../services/productType.service.js';

const { Column } = Table
const { Text } = Typography

export const Testkits = () => {
  const { token } = useContext(UserContext)
  const [testKits, setTestKits] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [products, setProducts] = useState()
  const [currentUser, setCurrentUser] = useState()
  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {
    document.title = 'Instalab | Test Kits'
  }, [])

  useEffect(() => {
    fetchCurrentUser()
    fetchPatientData()
  }, [token])

  useEffect(() => {
    fetchProductTypes(); 
  }, [currentUser])

  useEffect(() => {
    if (!products) return
    fetchTestKits()
  }, [products])

  const fetchCurrentUser = async () => {
    if (!token) return
    setCurrentUser(await getMe({
      select: '_id credits isAthlete'
    }))
  }

  const fetchPatientData = async () => {
    if (token) {
      setProducts(await listProducts({
        filter: {
          isRemoved: {
            $ne: true
          }
        },
        select: 'hasKit type tracking trackingNumber createdAt status',
        populate: [{
          path: 'productType',
          select: 'title'
        }]
      }))
    } else {
      setProducts(null)
    }
  }

  const fetchTestKits = () => {
    if (!products) return
    setTestKits(products.filter(product => {
      return product.hasKit
    }))
    setIsLoading(false)
  }

    // Fetch product types for scheudle menu
    const fetchProductTypes = async () => {
      if (!currentUser) return;
    
      // Define a filter to only fetch specific product codes
      let filter = {
        code: { $in: [
          ProductTypeCode.TRUAGE_COMPLETE,
          ProductTypeCode.BIOBEAT,
          ProductTypeCode.FERTILITY,
          ProductTypeCode.GALLERI,
          ProductTypeCode.HEART_HEALTH_TEST_KIT,
          ProductTypeCode.HEART_HEALTH_GENETIC_TEST,
          ProductTypeCode.MYCOTOX_PROFILE,
          ProductTypeCode.WATCHPAT_ONE,
          ProductTypeCode.TESTOSTERONE_PANEL_KIT,
          ProductTypeCode.TOXIC_METALS_URINE_TEST,
        
        ]}
      };
    
  
      try {
        const types = await listProductTypes({ filter, sort:'title' }); // Pass the filter when calling API
        setProductTypes(types);
      } catch (error) {
        console.error("Error fetching product types:", error);
      }
    };

  const renderMobileCard = (testKit) => {
    const { productType, createdAt, status, tracking, trackingNumber } = testKit;
    
    return (
      <Card 
        key={testKit._id} 
        className="testkit-card"
      >
        <div className="testkit-card-content">
          <div className="testkit-card-row">
            <div className="status-container">
              <Text className={classNames('testkit-status', `${status || 'fulfilling'}-status`)}>
                {status || 'fulfilling'}
              </Text>
            </div>
          </div>
          
          <div className="testkit-card-row test-name">
            {productType ? productType.title : testKit.type}
          </div>

          <div className="testkit-card-row ordered">
            Ordered: {moment(createdAt).format('MM/DD/YYYY')}
          </div>

          {(tracking?.trackingEvents?.length > 0) ? (
            <div className="testkit-card-row tracking">
              <Tooltip 
                overlayStyle={{ maxWidth: '500px'}}
                title={ShippingHelper.getImportantEvents(tracking?.trackingEvents)?.length ? (
                  <ul className="tracking-list">
                    {ShippingHelper.getImportantEvents(tracking?.trackingEvents).map(({ eventTimestamp, eventType, eventCity, eventState }, index) => (
                      <li key={`tracking-${testKit._id}-${index}`}>
                        <strong>{moment(eventTimestamp).format('MM/DD/YYYY')}</strong>: {eventType} {(eventCity && eventState) ? ` (${startCase(toLower(eventCity))}, ${eventState})` : ''}
                      </li>
                    ))}
                  </ul>
                ) : null}
              >
                {TrackingHelper.getDeliveryService(tracking?.trackingNumber).service}: &nbsp;
                <a 
                  className="link" 
                  href={TrackingHelper.getDeliveryService(tracking?.trackingNumber).url} 
                  target="_blank"
                >
                  {tracking?.trackingNumber}
                </a>
              </Tooltip>
            </div>
          ) : trackingNumber ? (
            <div className="testkit-card-row tracking">
              {TrackingHelper.getDeliveryService(trackingNumber).service}: &nbsp;
              <a 
                className="link" 
                href={TrackingHelper.getDeliveryService(trackingNumber).url} 
                target="_blank"
              >
                {trackingNumber}
              </a>
            </div>
          ) : null}
        </div>
      </Card>
    );
  };

  return (
    <div className="testkits">
      <PageHeader 
        title='Test Kits'
        count={testKits.length}
        actions={(
          <ScheduleMenu  
            productTypes={productTypes} 
            currentUser={currentUser} 
            menuText={"Get Test Kit"}
          />
        )}
        />

      <div className="testkits-container">
        {/* Desktop View */}
        <div className="desktop-view">
          <Table
            loading={isLoading}
            size='small'
            className='primary-table'
            pagination={false}
            rowKey='_id'
            dataSource={testKits}
            scroll={{ x: 'max-content' }}
          >


            <Column
              title='Test'
              dataIndex='productType'
              render={(_, record) => record.productType ? <>{record.productType.title}</> : <>{record.type}</>}
              fixed="left"

            />

            <Column
              title='Ordered'
              dataIndex='createdAt'
              render={createdAt => moment(createdAt).format('MM/DD/YYYY')}
            />

            <Column
              title='Status'
              dataIndex='status'
              render={(status, { _id }) => {
                return status ? <Text className={classNames(`${status}-status`, 'testkit-status')}>{status ? status : 'fulfilling'}</Text> : <Text className={classNames(`fulfilling-status`, 'testkit-status')}>fulfilling</Text>
              }}
            />

            <Column
              title='Tracking'
              dataIndex='tracking'
              render={(tracking, { _id, trackingNumber }) => (
                tracking?.trackingEvents?.length > 0 ? (
                <Tooltip 
                  overlayStyle={{ maxWidth: '500px'}}
                  title={ShippingHelper.getImportantEvents(tracking?.trackingEvents)?.length ? (
                    <ul className="tracking-list">
                      {ShippingHelper.getImportantEvents(tracking?.trackingEvents).map(({ eventTimestamp, eventType, eventCity, eventState }, index) => (
                        <li key={`tracking-${_id}-${index}`}>
                          <strong>{moment(eventTimestamp).format('MM/DD/YYYY')}</strong>: {eventType} {(eventCity && eventState) ? ` (${startCase(toLower(eventCity))}, ${eventState})` : ''}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                >
                  {TrackingHelper.getDeliveryService(tracking?.trackingNumber).service}: &nbsp;
                  <a 
                    className="link" 
                    href={TrackingHelper.getDeliveryService(tracking?.trackingNumber).url} 
                    target="_blank"
                  >
                    {tracking?.trackingNumber}
                  </a>
                </Tooltip>
                ) : trackingNumber ? (
                <div className="testkit-card-row tracking">
                  {TrackingHelper.getDeliveryService(trackingNumber).service}: &nbsp;
                  <a 
                    className="link" 
                    href={TrackingHelper.getDeliveryService(trackingNumber).url} 
                    target="_blank"
                  >
                    {trackingNumber}
                  </a>
                </div>
              ) : null
            )}
            />
          </Table>
        </div>

        {/* Mobile View */}
        <div className="mobile-view">
          {isLoading ? (
            <div className="loading-container">Loading...</div>
          ) : (
            testKits.map(renderMobileCard)
          )}
        </div>
      </div>
    </div>
  )
}