import { Modal, message } from 'antd'
import { CardForm } from '../cardForm/cardForm.component'
import { useState, useContext, useEffect } from 'react'
import { UserContext } from '../../contexts/user.context'
import { addMembership, changeMembership, getFreeMonthsOnDowngrade, updateSubscriptionCard } from '../../services/membership.service'
import MembershipHelper from '../../helpers/membership.helper'
import UserHelper from '../../helpers/user.helper'
import './payMembershipModal.scss'

const membershipSelect = '_id status startAt endAt monthlyPrice price card'
const membershipPopulate = [{
  path: 'membershipType',
  select: '_id code isAnnual title'
}, {
  path: 'card',
  select: '_id brand last4'
}]

export const PayMembershipModal = ({ 
    membershipType,
    currentMembership,
    payMembershipModalVisible, 
    setPayMembershipModalVisible, 
    onMembershipPaymentUpdate,
}) => {
    const { currentUser } = useContext(UserContext)
    const [isPaying, setIsPaying] = useState(false);
    const [freeMonths, setFreeMonths] = useState(0);
    const isUpgrade = MembershipHelper.isUpgrade(membershipType, currentMembership);
    const isDowngrade = MembershipHelper.isDowngrade(membershipType, currentMembership);
    const [activeCard, setActiveCard] = useState(null)
    
    useEffect(() => {
        const fetchFreeMonths = async () => {
            if (isDowngrade && payMembershipModalVisible && currentMembership) {
                try {
                    const response = await getFreeMonthsOnDowngrade({
                        subscriptionId: currentMembership?.stripeId,
                        newMembershipTypeCode: membershipType?.code,
                    });
                    setFreeMonths(response.freeMonths);
                } catch (error) {
                    console.error('Error fetching free months:', error);
                    setFreeMonths(0);
                }
            }
        };

        fetchFreeMonths();
    }, [isDowngrade, payMembershipModalVisible, currentMembership, membershipType]);

    const onCardSuccess = async(card) => {

        if (card) {
            // Keep loading state active until membership is added
            setIsPaying(true);

            try {
                let membership;
                const params = {
                    stripeId: card.stripeId,
                    type: membershipType.code,
                    patient: currentUser._id,
                    currentMembership: currentMembership?._id,
                    ...card
                }
                
                if (currentMembership && (isUpgrade || isDowngrade)) {
                    membership = await changeMembership({
                        fields: params,
                        select: membershipSelect,
                        populate: membershipPopulate
                    })
                }

                else if (currentMembership) {
                    membership = await updateSubscriptionCard({
                        fields: params,
                        select: membershipSelect,
                        populate: membershipPopulate
                    })
                }

                else {
                    membership = await addMembership({
                        fields: params,
                        select: membershipSelect,
                        populate: membershipPopulate
                    })
                }

                if (membership) {
                    await onMembershipPaymentUpdate();
                    message.success('Payment method updated')
                }
            } catch (error) {
                message.error('There was an error processing your payment. Please try again.')
            } finally {
                setIsPaying(false);
                setPayMembershipModalVisible(false);
            }
        }
        else {
            message.error('There was an error processing your card. Please try again.')
            setIsPaying(false);
            setPayMembershipModalVisible(false);
        }
    }

    // Add this to prevent unnecessary rerenders
    const cardFormProps = {
        currentMembership,
        onSuccess: onCardSuccess,
        isLoading: isPaying,
        setIsLoading: setIsPaying,
        buttonText: isUpgrade || isDowngrade 
            ? "Confirm Membership Change" 
            : currentMembership 
                ? "Update Payment" 
                : "Get Membership",
        activeCard: activeCard,
        setActiveCard: setActiveCard,
        disclaimerText: isUpgrade 
            ? `Once confirmed, this plan will start immediately. You will be charged the prorated difference for the remainder of your current billing period.` 
            : isDowngrade 
                ? `Once confirmed, this plan will start immediately. You will receive ${freeMonths} free months based on your previous payments.` 
                : ""
    }

    // Only render the CardForm when the modal is visible
    return (
        <Modal
            open={payMembershipModalVisible}
            onCancel={() => setPayMembershipModalVisible(false)}
            footer={null}
            className="pay-membership-modal"
        >
            <h2>{ (isUpgrade || isDowngrade) ? "Change your membership" : currentMembership ? "Update your payment method" : "Confirm your membership"}</h2>

            <div className="pay-membership-modal-description">
                <div className="pay-membership-modal-description-left">
                    <div className="pay-membership-modal-description-item"><strong>{membershipType?.title}</strong></div>
                    <div className="pay-membership-modal-description-item">{membershipType?.isAnnual ? 'Annual Plan' : 'Monthly Plan'}</div>  
                    <div className="pay-membership-modal-description-item">Starting {new Date().toLocaleDateString()}</div>
                </div>

                <div className="pay-membership-modal-description-price">
                    {UserHelper.hasCredit(currentUser, membershipType.code) ? '$0' : `$${membershipType?.cost}`}
                </div>
            </div>

            {UserHelper.hasCredit(currentUser, membershipType.code) && (
                <div className="pay-membership-modal-description-credit">
                    <span className="credit-icon">🎉</span>
                    <span className="credit-message">
                        Great news! You have a complimentary 1-year membership credit available. A credit card is required for setup, but you can easily cancel anytime before renewal.
                    </span>
                </div>
            )}

            {payMembershipModalVisible && (
                <CardForm {...cardFormProps} />
            )}
        </Modal>
    )
}