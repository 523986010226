import { Row, Col, Typography } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import "./membershipPoints.scss";

const { Paragraph } = Typography

export const MembershipPoints = ({membershipType}) => {

  const features = membershipType.features

  return features && (
    <div className="membership-points">
      <div className="point-list">
        <Row gutter={10}>
          {features.map((point) => (
            <div className="point-item">
              <Row>
                <Col className="point-col">
                  <CheckCircleOutlined className="point-icon" />
                </Col>
                <Col className="text-col">
                  <Paragraph className="point-title">
                    {point.title}
                  </Paragraph>
                  <Paragraph className="point-description">
                    {point.description}
                  </Paragraph>
                </Col>
              </Row>
            </div>
          ))}
        </Row>
      </div>
    </div>
  )
}