import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { LdctIntro } from "./ldctIntro.component.js"
import { ConfirmLdct } from "./confirmLdct.component.js"
import { listProductTypes } from '../../services/productType.service'
import MembershipTypeCode from "../../enums/membershipTypeCode.enum.js"
import { getMembershipTypeByCode} from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service.js"
import FlowType from "../../enums/flowType.enum.js"
import { useNavigate } from "react-router-dom"
import ProductHelper from "../../helpers/product.helper"
import { FileTypeCode } from "../../enums/index.enum.js"

export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',
  LOCATION: 'location',
  FACILITY: 'facility',
  NAME: 'name',
  GENDER: 'gender',
  PREGNANCY: 'pregnancy',
  DOB: 'dob',
  INSURANCE: 'insurance',
  PHONE: 'phone',
  PAY: 'pay',
  CONFIRM: 'confirm',
}

export const LdctFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [membershipType, setMembershipType] = useState()
  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, membershipType, productTypes, initialUser, instalabMembership, flow])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])


  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.LDCT,
          ]
        }
      }
    }))
  }

  const fetchMembershipType = async () => {
    setMembershipType(await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const instructions = [
    {
      sections: [{
        title: 'Schedule Your Low-Dose CT Scan',
        description: <>Our concierge team will help you book an appointment at an imaging center near you.</>
      }]
    },
    {
      sections: [{
        title: 'Prepare for Your Scan',
        description: <>No special preparation is needed for a low-dose CT scan. Wear comfortable, metal-free clothing. The scan typically takes about 5-10 minutes.</>
      }]
    },
    {
      sections: [{
        title: 'Review Test Results',
        description: <>Your results will be reviewed by a radiologist and usually uploaded within 48 hours. Instalab physicians will provide insights and follow-up recommendations based on your scan.</>
      }]
    }
  ];


  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser || !productTypes) return
    const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.LDCT)], instalabMembership, currentUser)
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <LdctIntro productType={getProductType(ProductTypeCode.LDCT)} footer={footer} instructions={instructions}/>
        
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.LOCATION,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.MAMMOGRAM}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },

      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        buildProduct:true,
        nextStep: Step.NAME,
        title: `What's your current address?`,
        description: `We'll search for imaging centers near this address.`,
        field: {
          name: 'location'
        },
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },

      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },

      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.INSURANCE,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },

      [Step.INSURANCE]: {
        type: StepType.INSURANCE_UPLOAD,
        onNextStep: () => { return Step.PAY },
        buildUser: true,
        title: 'Upload your insurance card (optional)',
        description: 'Health insurance sometimes covers the cost of an LDCT. Upload your card now if you want us to check your coverage prior to booking your appointment. If not covered, we\'ll find the best price and quality option for you.',
        hasSkip: true,
        fields: [
          {
            name: 'frontInsuranceCard',
            fileTypeCode: FileTypeCode.INSURANCE_CARD_FRONT,
            upload: true,
            title: 'Front of your insurance card',
          },
          {
            name: 'backInsuranceCard',
            fileTypeCode: FileTypeCode.INSURANCE_CARD_BACK,
            upload: true,
            title: 'Back of your insurance card',
          }
        ],
        skipIf: () => {
          return initialUser?.frontInsuranceCard && initialUser?.backInsuranceCard
        }
      },


      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.PHONE,
        addProduct: true,
        productType: ProductTypeCode.LDCT,
        title: <>Pay <span className="true-price">${totalCost}</span> for the Instalab service fee.</>,
        description: <>This fee covers researching the best imaging center for your needs, scheduling your appointment and handling any follow ups after you receive your results.<p><i>Note: This doesn't include any insurance co-pay or additional costs the imaging center may directly charge you for the procedure.</i></p></>,
        skipIf: () => {
          return instalabMembership 
        }
      },

      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only send you urgent notifications about your account or results here.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },


      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `All set! We'll email you within 1 business day.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmLdct instructions={instructions} productType={getProductType(ProductTypeCode.LDCT)}/>,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }

  return <>
    <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      productTypeCode={ProductTypeCode.LDCT}
    />


  </>
}