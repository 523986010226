import { useState } from "react";
import { Typography } from "antd";
import { TestCode, QuestionTemplateCode, Medication, Condition, Diet, RiskLevel, ReportSection } from "../../../enums/index.enum"
import "./bpAdvice.scss";
import { TestView } from "../testView/testView.component"
import { useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const BpAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [riskLevel, setRiskLevel] = useState();
  const [bpTests, setBpTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [recParams, setRecParams] = useState();

  const isBetter = ReportHelper.isBetter(report, ReportSection.BP_ADVICE);
  const isWorse = ReportHelper.isWorse(report, ReportSection.BP_ADVICE);
  const isFixed = ReportHelper.isFixed(report, ReportSection.BP_ADVICE);
  const isStatic = ReportHelper.isStatic(report, ReportSection.BP_ADVICE);

  useEffect(() => {
    setRiskLevel(getRiskLevel());
  }, [tests, results, report]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, riskLevel, isBetter, isWorse, isFixed, isStatic]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  useEffect(() => {
    if (isFixed || isBetter) 
      setBpTests(tests.filter(({code}) => [TestCode.BP_D, TestCode.BP_S].includes(code)));
      // setBpTests(ReportHelper.getSectionImprovedTests(report, ReportSection.BP_ADVICE, tests))

    else
      setBpTests(ReportHelper.getSectionTests(report, ReportSection.BP_ADVICE, tests))
    // setBpTests(tests.filter(({code}) => [TestCode.BP_D, TestCode.BP_S].includes(code)));
  }, [report, tests]);

  useEffect(() => {
    setRecParams(getRecParams());
  }, [report]);

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !riskLevel) return null;

    if (isBetter || isFixed) {
      return (
        <>
          Your <span>blood pressure</span> is <span>improving</span>, which
          decreases your risk for heart disease, stroke, and kidney disease.
        </>
      );
    }
    if (isWorse) {
      return (
        <>
          Your <span>blood pressure</span> is <span>increasing</span>, which
          multiplies your risk for heart disease, stroke, and kidney disease.
        </>
      );
    }
    // if (isFixed) {
    //   return (
    //     <>
    //       Your <span>blood pressure</span> is now <span>optimal</span>, which
    //       decreases your risk for heart disease, stroke, and kidney disease.
    //     </>
    //   );
    // }
    if (isStatic) {
      return (
        <>
          Your <span>blood pressure</span> remains relatively <span>unchanged</span> since your last reading. 
        </>
      );
    }   

  
    else if (ReportHelper.getSectionIndex(report, ReportSection.BP_ADVICE)===1)
      return (
        <>
          Let's focus on where you can make the most progress:{" "}
          <span>your blood pressure</span>.
        </>
      );

    // if (isTakingBPMedication) {
    //   return (<>
    //     Your <span>blood pressure</span> is{" "}
    //     <span>{ReadableRiskLevel[riskLevel]}</span>, despite taking medication to lower it.
    //   </>)      
    // }



    return (
      <>
        Your <span>blood pressure</span> is{" "}
        <span>{ReportHelper.getRiskEval(TestCode.BP, report, results, tests)}</span>, which multiplies your risk
        for heart disease, stroke, and kidney disease.
      </>
    );
  };


  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)
      
    if (!report || !riskLevel) return null;

    if (isStatic) {
      return (
        <>
        Blood pressure measures the force of blood against artery walls. Your persistent high blood pressure strains your heart and can eventually damage the smaller vessels of your
        kidney, increasing your risk of heart disease, stroke, and kidney issues.
        </>
      );      
    }

    let content = (
      <>
        Blood pressure is the force of your blood pushing against the walls of
        your arteries. When your blood pressure stays high over time, it causes
        your heart to work harder and can damage the smaller vessels of your
        kidney.
      </>
    );
    if (isBetter) {
      return (
        <>
          {content}
          <br />
          <br />
          Nice work on the progress you've made with your blood pressure since your last blood draw! Your efforts are paying off. To reach optimal levels, it's important to stick with the game plan.
        </>
      );
    }
    // if (isWorse) {
    //   return (
    //     <>
    //       {content}
    //       <br />
    //       <br />
    //       By working a few simple changes into your diet, you may be able to
    //       improve your blood pressure by your next blood draw. Consistency is
    //       key.
    //     </>
    //   );
    // }
    if (isFixed) {
      return (
        <>
          {content}
          <br />
          <br />
          Congratulations on improving your blood pressure! Continue following
          your game plan and make sure your blood pressure remains optimal over
          multiple readings.
        </>
      );
    }

    return (
      <>
        {content}
      </>
    );
  };

  const getRecParams = () => {
    if (!report) return null;

    const { result } = report

    const exercises150PerWeek = ReportHelper.getAnswer(report, QuestionTemplateCode.EXERCISE)
    const medications = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_MEDICATIONS)
    const optimalBP = !result?.conditions?.includes(Condition.ELEVATED_BLOOD_PRESSURE)
    const strengthTrains =  ReportHelper.getAnswer(report, QuestionTemplateCode.STRENGTH)
    const diet = ReportHelper.getAnswer(report, QuestionTemplateCode.DIET);

    let params = {
      exercises150PerWeek: exercises150PerWeek === true || exercises150PerWeek === "true",
      isTakingBPMedication: medications?.includes(Medication.HIGH_BLOOD_PRESSURE),
      optimalBP: optimalBP,
      strengthTrains: strengthTrains,
      isVegan: diet?.includes(Diet.VEGAN),
      isVegetarian: diet?.includes(Diet.VEGETARIAN),

    }

    return params

  };
  const getRiskLevel = () => {
    if (!results || !tests || !report) return null;
    const diastolicRisk = ReportHelper.getTestRisk(TestCode.BP_D, report, results, tests);
    const systolicRisk = ReportHelper.getTestRisk(TestCode.BP_S, report, results, tests);
    if (diastolicRisk === RiskLevel.HIGH || systolicRisk === RiskLevel.HIGH) {
      return RiskLevel.HIGH;
    }
    return RiskLevel.MODERATE;
  };


  return ( 
    riskLevel &&
    bpTests && recParams && (
      <div className="bp-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        <TestView 
          tests={bpTests} 
          report={report}
          results={results}
          reports={reports}
          user={user}
        />

        <AdviceSection
          section={section}
          report={report}
          results={results}
          tests={tests}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
