const Breakpoint = Object.freeze({
  XSM: 440,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1600,
});

export default Breakpoint;
