import moment from 'moment'
import * as emailValidator from 'email-validator'

const ValidateHelper = {
  phone: (value) => {
    if (!value) return false
    value = value.replace(/\D/g, '')
    return value?.length === 10
  },
  dob: (value) => {
    if (!value) return false
    return moment(value, 'MM/DD/YYYY', true).isValid()
  },
  email: value => {
    if (!value)  return false
    return emailValidator.validate(value)
  }
}

export default ValidateHelper
