import "./mobileHeaderLayout.scss"
import { useContext, useEffect, useState } from 'react';
import { ReactComponent as LogoIcon } from "../../assets/svg/logo.svg";
import { MenuOutlined } from '@ant-design/icons'
import { Button, Drawer, Typography } from "antd";
import SidebarLayout from "./sidebar.layout";
import { useLocation, useNavigate } from "react-router-dom";
import Color from '../../colors.scss';
import SidebarScheduleBtnLayout from "./sidebarScheduleBtn.layout";
import { UserContext } from "../../contexts/user.context";
import { UserOutlined } from '@ant-design/icons'
import { AnnouncementBanner } from '../announcementBanner/announcementBanner.component';
const { Text } = Typography

const MobileHeaderLayout = ({ isPro=false }) => {
  const [openDrawer, setOpenDrawer] = useState()
  const { token, currentUser } = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setOpenDrawer(false)
  }, [location])
  
  const onDrawerClose = () => {
    setOpenDrawer(false)
  }

  const onLogin = async () => {
    navigate('login')
  }

  const MobileDrawer = () => (
    <Drawer
      placement="left"
      title={currentUser?.firstName ? `${currentUser.firstName} ${currentUser.lastName}` : null}
      onClose={onDrawerClose}
      open={openDrawer}
      className="mobile-drawer"
      width={300}
    >
      <SidebarScheduleBtnLayout />
      <SidebarLayout />
    </Drawer>
  )

  return <>
    <div className="mobile-header-padding" />
    <AnnouncementBanner />
    <div className="mobile-header">
      <Button
        icon={<MenuOutlined />}
        className="menu-btn"
        onClick={() => setOpenDrawer(true)}
      />
      <div className="logo-container" onClick={() => navigate('/')}>
        <LogoIcon className="logo-icon" />
        <Text className="logo-text">
          {isPro ? <>Instalab<span style={{ color: Color.success }}>Pro</span></> : 'Instalab'}
        </Text>
      </div>
      
      {!token && <div className="login">
        <UserOutlined  onClick={onLogin}/>
      </div>}
      
    </div>

    <MobileDrawer />


  </>
}

export default MobileHeaderLayout