import { useState, useEffect } from "react";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { ReportSection, LowercaseTest } from "../../../enums/index.enum";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const converter = require("number-to-words");
const { Paragraph } = Typography;

export const ProstateAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [prostateTests, setProstateTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setProstateTests(getProstateTests());
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, tests, prostateTests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  const getProstateTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(report, ReportSection.PROSTATE_ADVICE, tests);
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !tests || !prostateTests) return null;

    return  <>         
      {prostateTests.length === 1 
        ? <>Your <span>{LowercaseTest[prostateTests[0].code]}</span> level indicates</>
        : <>These{" "}<span>{converter.toWords(prostateTests.length)} biomarkers</span>{" "}indicate</>
      }{" "}a potential <span>prostate issue</span>.
    </>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report) return;

    return <>
        PSA levels naturally increase with age. However, elevated PSA levels combined with a low free PSA ratio can sometimes be associated with prostate conditions such as benign prostatic hyperplasia (BPH), prostatitis, or potentially more serious conditions like prostate cancer. While these results do not confirm a specific diagnosis, they do highlight the importance of further evaluation.
    </>

  }

  return (
    prostateTests && (
      <div className="low-wbc-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{description}</Paragraph>
        <TestView 
          report={report} 
          tests={prostateTests}
          results={results}
          reports={reports}
          user={user}
        />
        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};