import { Typography, Button, Row, Col }  from 'antd'
import './galleriApproval.scss'
import Breakpoint from '../../../../enums/breakpoint.enum.js'
import useWidth from '../../../../hooks/useWidth.hook.js'
import ProductTypeCode from "../../../../enums/productTypeCode.enum"
import Panels from "../../../panels/panels.component.js"

const { Text, Paragraph } = Typography

const GalleriApproval = () => {
  const width = useWidth()

  const productTypeCodes = [
    ProductTypeCode.CTCALCIUM,
    ProductTypeCode.LONGEVITY_PANEL,
    ProductTypeCode.HEART_HEALTH_TEST_KIT,
    ProductTypeCode.WATCHPAT_ONE,
  ]

  const instructions = [{
    sections: [{
      title: 'Receive Test Kit',
      description: <>You'll get the Galleri kit in the mail in approximately one week. This contains all the materials needed for the blood draw.</>
    }, 
  ]
  }, 
  {
    sections: [{
      title: 'Get Blood Drawn',
      description: <>Once you've received your kit, you'll be given 2 options: have a phlebotomist come to you or visit any of Galleri's patient service centers (2,000+ locations).</>
    }]
  }, {
    sections: [{
      title: 'Get Test Results',
      description: <>Test results are sent two to three weeks after your sample arrives at Galleri's lab.</>
    }]
  }]

  return (
    <div className="prescription-approval">


      {instructions?.length > 0 && (
        <div className="instruction-list">
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}

      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <Panels productTypeCodes={productTypeCodes} />
    </div>
  )
}

export default GalleriApproval