import { Popover, Typography } from 'antd'
import Breakpoint from '../../enums/breakpoint.enum'
import useWidth from '../../hooks/useWidth.hook'
import './tassoPopover.scss'

const { Paragraph } = Typography

export const TassoPopover = ({ trigger }) => {
  const width = useWidth()

  return (
    <Popover 
      overlayStyle={{
        maxWidth: width < Breakpoint.SM ? '100%' : 550
      }}
      content={(
        <div className="tasso-popover">
          <img 
            src="/img/tasso-grid.png" 
            className="tasso-img"
          />
          <Paragraph className="tasso-title">
            Tasso+: A Truly Painless Way to Collect Blood
          </Paragraph>
          <Paragraph className="tasso-description">
            Unlike fingerprick tests that can be uncomfortable, Tasso+ gently draws your blood with virtually no sensation. It's designed to make the process seamless and pain-free, so you can focus on your health without the stress of needles or pricks.
          </Paragraph>
        </div>
      )}
      placement='right'
    >
      {trigger}
    </Popover>
  )
}