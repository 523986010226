import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import ReportHelper from "../../../helpers/report.helper";
import { TestView } from "../testView/testView.component"
import {ReportSection, TestCode} from "../../../enums/index.enum"
import "./hormoneCheckFemale.scss";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const HormoneCheckFemale = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [hormoneTests, setHormoneTests] = useState();

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, hormoneTests]);

  useEffect(() => {
    setHormoneTests(getHormoneTests())
  }, [report, tests])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  const getHormoneTests = () => {
    if (!report || !tests) return null
    return ReportHelper.getSectionTests(report, ReportSection.HORMONE_CHECK_FEMALE, tests)
  }

  
  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !hormoneTests) return null;

    return <>Your <span>testosterone</span> is <span>{ReportHelper.getRiskEval(TestCode.TOTAL_TESTOSTERONE, report, results, tests)}</span>, a hormone
    that plays a vital role in muscle health, libido and cognitive function.</>
    
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report || !hormoneTests) return null;

    return <>Testosterone is an essential hormone in women, but its excess can disrupt the normal balance and functioning of the body. High testosterone levels in women can be indicative of certain health conditions such as insulin resistance and polycystic ovary syndrome (PCOS).</>
  }
    


  return (
    hormoneTests && <div className="free-t4-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">{description}</Paragraph>
      <TestView 
        report={report} 
        tests={hormoneTests}
        results={results}
        reports={reports}
        user={user}
      />
      <AdviceSection 
        report={report}  
        results={results}
        tests={tests}
        section={section}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />

    </div>
  );
};
