import { useState, useContext } from 'react'
import { Row, Col, Typography, Progress, Tooltip, Select, Button } from 'antd'
import moment from 'moment'
import { TypeAnimation } from 'react-type-animation';
import { UserContext } from '../../contexts/user.context'
import { ReactComponent as SpinnerIcon } from '../../assets/svg/spinner.svg'
import './testosteroneProjection.scss'
import { useEffect } from 'react'
import { InfoCircleFilled } from '@ant-design/icons'
import { listAnnotatedResults } from '../../services/result.service'
import { useNavigate } from 'react-router-dom'
import { listAnnotatedTests } from '../../services/test.service'
import TestCode from '../../enums/testCode.enum'
import { Line } from 'react-chartjs-2';
import { listPatients } from '../../services/patient.service'
import 'chartjs-adapter-date-fns';
import { useSearchParams } from 'react-router-dom';

import RiskLevel from '../../enums/riskLevel.enum'
import PatientHelper from '../../helpers/patient.helper'
import Gender from '../../enums/gender.enum'
import Role from '../../enums/role.enum'
import { getMe } from '../../services/user.service'
import PosthogHelper from '../../helpers/posthog.helper'
import Org from '../../enums/org.enum'
import PhotoHelper from '../../helpers/photo.helper'

const { Paragraph } = Typography
const { Option } = Select

const loadingTime = new Date()

export const TestosteroneProjection = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [patientId, setPatientId] = useState(searchParams.get('patientId') || null);
  const { token, currentUser } = useContext(UserContext)
  const [patient, setPatient] = useState()
  const [tests, setTests] = useState([])
  const [results, setResults] = useState(null)
  const [test, setTest] = useState()
  const [filteredResults, setFilteredResults] = useState([])
  const [abnormalRisk, setAbnormalRisk] = useState()
  const [patients, setPatients] = useState([])
  const [showSecondAnimation, setShowSecondAnimation] = useState(false);
  const [showThirdAnimation, setShowThirdAnimation] = useState(false);
  const [showFourthAnimation, setShowFourthAnimation] = useState(false);
  const [showFifthAnimation, setShowFifthAnimation] = useState(false);
  const [showFirstTestImage, setShowFirstTestImage] = useState(false);
  const [showLoginLink, setShowLoginLink] = useState(false);

  useEffect(() => {
    document.title = `Instalab | Predicting Your Future Testosterone Levels`
  }, [])

  useEffect(() => {
    if (test && results) {
      setFilteredResults(results.filter(result => {
        return result.values.some(value => value.test === test._id && value.value)
      }).sort((a, b) => new Date(b.collectedAt) - new Date(a.collectedAt)))
      
      // Wait 3s before showing
      if (loadingTime) {
        const loadDuration = Date.now() - loadingTime;
        if (loadDuration > 2000) {
          setIsLoading(false);
        } else {
          const timer = setTimeout(() => {
            setIsLoading(false);
          }, 2000 - loadDuration);
        
          return () => clearTimeout(timer);
        }
      }
    }
  }, [test, results])
  
  useEffect(() => {
    if (currentUser) {
      fetchPatientData()
    }
  }, [patientId, currentUser, patients])

  useEffect(() => {
    if (currentUser) {
      fetchTestData()
      fetchResultData()
    }
  }, [patientId, currentUser])
  
  useEffect(() => {
    if (currentUser) {
      logPosthog()
    }
    if (currentUser?.role === Role.ADMIN || currentUser?.role === Role.PROVIDER) {
      fetchPatients()
    }
  }, [currentUser])

  useEffect(() => {
    if (tests.length) {
      const fetchedTest = tests.find(test => test.code === TestCode.FREE_TESTOSTERONE)
      setTest(fetchedTest)
      let risk = fetchedTest.risk
      if (patient?.gender === Gender.FEMALE) {
        risk = fetchedTest.femaleRisk
      } else if (patient?.gender === Gender.MALE) {
        risk = fetchedTest.maleRisk
      }
      setAbnormalRisk(risk.find(({ level, lessThan }) => level === RiskLevel.ABNORMAL && lessThan)?.lessThan)
    }
  }, [tests, patient])

  const fetchPatients = async () => {
    setPatients(await listPatients({
      select: 'gender dob _id firstName lastName',
      filter: {
        org: Org.INSTALAB,
        firstName: {
          $exists: true
        },
        lastName: {
          $exists: true
        },
        email: {
          $exists: true
        }
      }
    }))
  }

  const currentAge = PatientHelper.getAge(patient)


  const logPosthog = () => {
    PosthogHelper.track(currentUser, `[tool] testosterone projection`, true)
  }
  
  const fetchPatientData = async () => {
    if (currentUser.role === Role.PATIENT) {
      setPatient(await getMe())
    } else if (patientId && patients?.length) {
      setPatient(patients.find(({ _id }) => _id === patientId))
    }
  }

  const fetchTestData = async () => {
    setTests(await listAnnotatedTests({ patient: patientId }))
  }
  
  const fetchResultData = async () => {
    setResults(await listAnnotatedResults({ patient: patientId }))
  }
  
  // Get the most recent result for projections
  const currentTestosteroneLevel = filteredResults[0]?.values?.find(v => v.test === test?._id)?.value
  const lastResultDate = filteredResults?.length ? new Date(filteredResults[0].collectedAt) : null
  const firstResultDate = filteredResults?.length ? new Date(filteredResults[filteredResults.length - 1].collectedAt) : null

  const ageAtLastResult = (currentAge && lastResultDate) ? currentAge - ((new Date().getTime() - lastResultDate.getTime()) / (365 * 24 * 60 * 60 * 1000)) : null

  const calculateProjections = () => {
    const isFemale = patient?.gender === Gender.FEMALE;
    
    // Define exact midpoints for each bracket
    const bracketMidpoints = isFemale
      ? [25, 35, 45, 55, 65, 75]  // female ends at 75
      : [25, 35, 45, 55, 65, 75, 85];  // male ends at 85
    
    // Age brackets with consistent ranges
    const ageRanges = isFemale
      ? ['20-30', '30-40', '40-50', '50-60', '60-70', '70-80']
      : ['20-30', '30-40', '40-50', '50-60', '60-70', '70-80', '80-90'];

    // Select appropriate values based on gender
    const medianValues = isFemale
      ? [0.346, 0.248, 0.219, 0.222, 0.228, 0.196]  // female median
      : [10.20, 9.03, 6.79, 4.61, 3.39, 2.54, 2.54];  // male median

    // Handle patients under 20
    if (ageAtLastResult < 20) {
      const projections = {
        median: [Number(currentTestosteroneLevel)],
        ages: [ageAtLastResult]
      };

      // For patients under 20, assume stability until 25, then continue with regular brackets
      const firstMidpoint = bracketMidpoints[0]; // 25

      // Maintain current level until 25
      projections.median.push(Number(currentTestosteroneLevel));
      projections.ages.push(firstMidpoint);

      // Add remaining bracket midpoints and their corresponding values
      for (let i = 1; i < ageRanges.length; i++) { // Start from i = 1 since we handled first point
        const midpoint = bracketMidpoints[i];
        
        // Use standard multipliers for all subsequent points
        const medianMultiplier = medianValues[i] / medianValues[i - 1];
        const lastMedian = projections.median[projections.median.length - 1];
        projections.median.push(Number((lastMedian * medianMultiplier).toFixed(2)));
        projections.ages.push(midpoint);
      }

      return projections;
    }

    // Original logic for patients 20 and older...
    const startBracketIndex = ageRanges.findIndex(range => {
      const [start, end] = range.split('-').map(Number);
      return ageAtLastResult >= start && ageAtLastResult < end;
    });

    if (startBracketIndex === -1) return null;

    const projections = {
      median: [Number(currentTestosteroneLevel)],
      ages: [ageAtLastResult]
    };

    // For each future bracket, calculate the projected value based on the rate of change
    for (let i = startBracketIndex + 1; i < ageRanges.length; i++) {
      // Use predefined midpoint instead of calculating it
      const midpoint = bracketMidpoints[i];
      
      // Calculate multiplier based on the reference data
      const medianMultiplier = medianValues[i] / medianValues[i - 1];

      // Apply multiplier to previous value
      const lastMedian = projections.median[projections.median.length - 1];
      projections.median.push(Number((lastMedian * medianMultiplier).toFixed(2)));
      projections.ages.push(midpoint);
    }

    return projections;
  };

  // Calculate projections if we have the necessary data
  const chartProjections = currentTestosteroneLevel && currentAge ? calculateProjections() : null

  const calculateAgeAtRisk = () => {
    if (!chartProjections) return null;

    const tension = 0.4; // Chart.js default tension

    // Helper function to calculate Y value at any point on the curve
    const getYValueAtAge = (age) => {
      const points = chartProjections.ages.map((age, i) => ({
        age,
        y: Number(chartProjections.median[i])
      }));

      // Find the segment containing our target age
      for (let i = 0; i < points.length - 1; i++) {
        if (age >= points[i].age && age <= points[i + 1].age) {
          const p0 = i > 0 ? points[i - 1] : points[i];
          const p1 = points[i];
          const p2 = points[i + 1];
          const p3 = i < points.length - 2 ? points[i + 2] : points[i + 1];

          const t = (age - p1.age) / (p2.age - p1.age);
          const t2 = t * t;
          const t3 = t2 * t;

          const prevTangent = tension * (p2.y - p0.y);
          const nextTangent = tension * (p3.y - p1.y);

          const c1 = 2 * t3 - 3 * t2 + 1;
          const c2 = -(2 * t3) + 3 * t2;
          const c3 = t3 - 2 * t2 + t;
          const c4 = t3 - t2;

          // Calculate the value and ensure it's not negative
          const value = c1 * p1.y + c2 * p2.y + c3 * prevTangent + c4 * nextTangent;
          return Math.max(0, value);
        }
      }
      return null;
    };

    // Binary search to find crossing point
    let left = chartProjections.ages[0];
    let right = chartProjections.ages[chartProjections.ages.length - 1];
    const epsilon = 0.01; // Precision threshold
    const maxIterations = 50;
    let iterations = 0;

    // First check if we start below threshold
    if (getYValueAtAge(left) <= abnormalRisk) {
      return left;
    }

    while (iterations < maxIterations && right - left > epsilon) {
      const mid = (left + right) / 2;
      const midValue = getYValueAtAge(mid);
    

      if (Math.abs(midValue - abnormalRisk) < epsilon) {
        return Number(mid.toFixed(1));
      }

      if (midValue > abnormalRisk) {
        left = mid;
      } else {
        right = mid;
      }
      
      iterations++;
    }

    // If we found a crossing point
    if (iterations < maxIterations) {
      return Number(((left + right) / 2).toFixed(1));
    }

    return null;
  };

  const ageAtRisk = calculateAgeAtRisk()

  const chartData = {
    datasets: [{
      label: 'Abnormal Range',
      data: [
        { x: firstResultDate || null, y: abnormalRisk },
        { x: lastResultDate ? new Date(lastResultDate.getTime() + ((patient?.gender === Gender.FEMALE ? 75 : 85) - ageAtLastResult) * 365 * 24 * 60 * 60 * 1000) : null, y: abnormalRisk }
      ],
      borderColor: 'rgba(255, 0, 0, 0)',
      backgroundColor: 'rgba(255, 0, 0, 0.1)',
      fill: {
        target: {
          value: 0
        }
      },
      pointRadius: 0,
      tension: 0.4
    }, {
      label: 'Free Testosterone',
      data: filteredResults.map(result => ({
        x: new Date(result.collectedAt),
        y: result.values.find(v => v.test === test?._id)?.value || null
      })).filter(point => point.y !== null),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      tension: 0.4
    },
    // Only add projection dataset if we have projections
    ...(chartProjections ? [
      {
        label: 'Projected',
        data: chartProjections.ages.map((age, i) => ({
          x: new Date(lastResultDate.getTime() + (age - ageAtLastResult) * 365 * 24 * 60 * 60 * 1000),
          y: chartProjections.median[i]
        })),
        borderColor: 'rgb(53, 162, 235)',
        borderDash: [5,5],
        tension: 0.4
      }
    ] : []),
    ]
  }; 


  // Get all values (both actual results and projections)
  const allValues = [
    ...chartData.datasets[1].data.map(d => d.y),  // Actual results
    ...chartProjections?.median || []  // Projected values
  ];

  // Calculate the age at which the patient is expected to become at risk

  const yearsAboveRisk = (chartProjections && ageAtRisk) ? chartProjections.ages[chartProjections.ages.length - 1] - ageAtRisk : 0
  const totalProjectedYears = chartProjections ? 
    chartProjections.ages[chartProjections.ages.length - 1] - chartProjections.ages[0] : 0;
  const percentage = Math.round(100 - (yearsAboveRisk / totalProjectedYears) * 100);

  // Determine status based on current and future values
  const currentValue = allValues[0];  // Most recent test result
  const willBeLowInFuture = allValues.some(value => value <= abnormalRisk);

  const isRiskImminent = ageAtRisk && (ageAtRisk - currentAge <= 10);

  let progressStatus;
  if (currentValue <= abnormalRisk) {
    progressStatus = 'exception';  // Red (current levels are low)
  } else if (willBeLowInFuture && isRiskImminent) {
    progressStatus = 'exception';  // Red (will be low soon)
  } else if (willBeLowInFuture) {
    progressStatus = 'warning';    // Yellow (will be low in future)
  } else {
    progressStatus = 'success';    // Green (never projected to be low)
  }

  // Get the last projected date
  const getLastProjectedDate = () => {
    if (!chartProjections || !chartProjections.ages.length) return null;
    const lastAge = chartProjections.ages[chartProjections.ages.length - 1];
    return new Date(lastResultDate.getTime() + (lastAge - ageAtLastResult) * 365 * 24 * 60 * 60 * 1000)
  };

  const getAgeFromDate = (date) => {
    if (!patient?.dob || !date) return '';
    return moment(date).diff(moment(patient.dob, 'MM/DD/YYYY'), 'years')
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,  // Allow custom height
    aspectRatio: 1.5,  // Width:Height ratio (adjust this value to your needs)
    height: '100%',
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const age = getAgeFromDate(context[0].raw.x);
            const date = new Date(context[0].raw.x).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            });
            return age ? `${age} years old (${date})` : '';
          },
          label: function(context) {
            const isProjected = context.dataset.label === 'Projected';
            return isProjected 
              ? ` Projected: ${context.raw.y} ${test?.unit}`
              : ` Free Testosterone: ${context.raw.y} ${test?.unit}`;
          }
        }
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'year',
          stepSize: 5
        },
        title: {
          display: true,
          text: 'Age'
        },
        max: getLastProjectedDate(),
        ticks: {
          callback: function(value) {
            return getAgeFromDate(value);
          }
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: `Free Testosterone (${test?.unit})`
        },
        ticks: {
          callback: function(value) {
            return value.toFixed(2);
          }
        }
      }
    },
    clip: false,
  };

  const handlePatientChange = (value) => {
    setPatient(null)
    setResults(null)
    setFilteredResults([])
    setShowSecondAnimation(false)
    setShowThirdAnimation(false)
    setShowFourthAnimation(false)
    setShowFifthAnimation(false)
    setShowFirstTestImage(false)
    setShowLoginLink(false)
    setPatientId(value);
    navigate(`?patientId=${value}`, { replace: true });
  };

  const onTestosteronePanelClick = () => {
    navigate('/testosterone')
    if (currentUser?.role === Role.PATIENT) {
      PosthogHelper.track(currentUser, `[testosterone projection] click testosterone test kit cta`, true)
    }
  }

  const getTooltipTitle = () => {
    if (percentage === 100) {
      return `This score reflects your future risk of developing low testosterone levels. A score of ${percentage}% suggests you are unlikely to experience a decline to levels deemed too low in your lifetime.`
    } else if (percentage > 0) {
      return `This score reflects your future risk of developing low testosterone levels. A score of ${percentage}% suggests that you will likely experience low testosterone levels in your ${Math.floor(ageAtRisk/10)*10}s.`
    } else {
      return `This score reflects your future risk of developing low testosterone levels. Since you already have low free testosterone levels, your score is 0%, as testosterone levels typically decline with age.`
    }
  }

  return (isLoading || !currentUser || !results || !test || !abnormalRisk) ? (
    <div className="preloader">
      <SpinnerIcon />
    </div>
  ) : (
    <div className="testosterone-projection">
      <Row>
        <Col 
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={16}
        >
          <div className="testosterone-chart">
            <Line data={chartData} options={options} />
          </div>
        </Col>
        <Col 
          xs={24}
          sm={24}
          md={24} 
          lg={10}
          xl={8} 
          className="analysis-col"
        >
          <div 
            className="analysis-content"
            style={{
              paddingTop: (currentUser?.role === Role.ADMIN || currentUser?.role === Role.PROVIDER) ? 80 : 30
            }}
          >
            {(currentUser?.role === Role.ADMIN || currentUser?.role === Role.PROVIDER) && patients?.length > 0 && (
              <Select
                showSearch
                className="projection-patient-select"
                placeholder="Select a patient"
                optionFilterProp="children"
                onChange={handlePatientChange}
                filterOption={(input, option) => {
                  const patient = patients.find(p => p._id === option.key);
                  const fullName = `${patient.firstName} ${patient.lastName}`.trim().toLowerCase();
                  return fullName.includes(input.trim().toLowerCase());
                }}
                value={patientId}
              >
                {patients.map(patient => (
                  <Option key={patient._id} value={patient._id} className="projection-patient-option">
                    {patient.firstName} {patient.lastName}
                  </Option>
                ))}
              </Select>
            )}

            <Paragraph className="projection-heading">
              Free Testosterone
            </Paragraph>
            <Paragraph className="projection-subheading">
              A visual projection of your free testosterone levels as you age.
            </Paragraph>
            
            
            {filteredResults?.length > 0 && <>
              <Paragraph className="projection-title">
                Future Testosterone Score
                <Tooltip 
                  title={getTooltipTitle()}
                >
                  <InfoCircleFilled className="info-icon" />
                </Tooltip>
              </Paragraph>

              <Progress
                percent={percentage}
                status={progressStatus}
                size="large"
              />

              <Paragraph className="projection-title">
                

                <TypeAnimation
                    sequence={[
                      `Analysis`,
                      500,
                      () => setShowSecondAnimation(true), 
                    ]}
                    speed={90}
                    wrapper="div"
                    cursor={false}
                    className="projection-title"
                  />
              </Paragraph>

              {percentage === 100 && <>
                {showSecondAnimation && (
                  <Paragraph>
                    <TypeAnimation
                      sequence={[
                        `Congratulations! You are not currently projected to experience low testosterone levels based on the average decline of testosterone levels in ${patient.gender === Gender.FEMALE ? 'women' : 'men'}. This is excellent news, as healthy testosterone levels play a vital role in maintaining energy, mood, and libido.`,
                        500,
                        () => setShowThirdAnimation(true), 
                      ]}
                      speed={90}
                      wrapper="div"
                      cursor={false}
                      className="projection-description"
                    />
                  </Paragraph>
                )}

                {showThirdAnimation && (
                  <Paragraph>
                    <TypeAnimation
                      sequence={[
                        `Note: it's important to remember that this projection is not a guarantee. While your current trajectory looks favorable, genetics and lifestyle changes can influence future testosterone levels as well.`,
                        500,
                        () => setShowFourthAnimation(true), 
                      ]}
                      wrapper="div"
                      speed={90}
                      cursor={false}
                      className="projection-description"
                    />
                  </Paragraph>
                )}
              </>}



              {percentage < 100 && percentage > 0 && <>
                {showSecondAnimation && (
                  <Paragraph>
                    <TypeAnimation
                      sequence={[
                        isRiskImminent ? 
                          `You are projected to have low testosterone levels by age ${Math.floor(ageAtRisk)}—${Math.round(ageAtRisk -currentAge) < 1 ? 'less than a year' : 'just '+ Math.round(ageAtRisk - currentAge) + ' ' + (Math.round(ageAtRisk - currentAge) === 1 ? 'year' : 'years')} away. This is a critical time to take action by improving your exercise routine and sleep habits. Making these changes now can help you avoid the challenges associated with low testosterone and ensure better energy, mood, and libido in the years ahead. Your future self will thank you for acting now.` :
                          `You are projected to have low free testosterone levels by age ${Math.floor(ageAtRisk)}.  While this isn't an urgent concern, it's a potential issue you can likely address now by improving your exercise routine and sleep habits. Your future self will thank you for boosting your energy, mood, and libido in the years to come.`,
                        500,
                        () => setShowThirdAnimation(true), 
                      ]}
                      speed={90}
                      wrapper="div"
                      cursor={false}
                      className="projection-description"
                    />
                    {}
                  </Paragraph>
                )}

                {showThirdAnimation && (
                  <Paragraph>
                    <TypeAnimation
                      sequence={[
                        `Note: it's important to remember that this projection is not a guarantee. While your current trajectory suggests you may reach low testosterone levels in your ${Math.floor(ageAtRisk/10)*10}s, factors such as genetics and lifestyle changes can influence your future testosterone levels as well.`,
                        500,
                        () => setShowFourthAnimation(true), 
                      ]}
                      wrapper="div"
                      speed={90}
                      cursor={false}
                      className="projection-description"
                    />
                  </Paragraph>
                )}
              </>}
            </>}


            {percentage === 0 && <>
              {showSecondAnimation && (
                <Paragraph>
                  <TypeAnimation
                    sequence={[
                      `Your testosterone levels are currently low, making this a crucial time to take action. By improving your exercise routine and sleep habits, you may be able to increase your testosterone levels back to optimal range. Taking these steps now can lead to better energy, mood, and libido in the years to come.`,
                      500,
                      () => setShowThirdAnimation(true),
                    ]}
                    speed={90}
                    wrapper="div"
                    cursor={false}
                    className="projection-description"
                  />
                </Paragraph>
              )}

              {showThirdAnimation && (
                <Paragraph>
                  <TypeAnimation
                    sequence={[
                      `Note: it's important to remember that this projection is not a guarantee. While your current trajectory looks favorable, genetics and lifestyle changes can influence future testosterone levels as well.`,
                      500,
                      () => setShowFourthAnimation(true),
                    ]}
                    speed={90}
                    wrapper="div"
                    cursor={false}
                    className="projection-description"
                  />
                </Paragraph>
              )}
            </>}

            {lastResultDate && ((new Date().getTime() - lastResultDate.getTime()) <= (365 * 24 * 60 * 60 * 1000)) && (
              <div className="retest-reminder">
                {showFourthAnimation && (
                  <Paragraph>
                    <TypeAnimation
                      sequence={[
                        `Take a Follow-Up Test`,
                        500,
                        () => setShowFifthAnimation(true),
                      ]}
                      speed={90}
                      wrapper="div"
                      cursor={false}
                      className="projection-title"
                    />
                  </Paragraph>
                )}

                {showFifthAnimation && (
                  <Paragraph>
                    <TypeAnimation
                      sequence={[
                        `You've had a testosterone test within the past year, which is great! If you're actively working to optimize your levels, follow-up testing every 2-3 months is a smart way to track progrees and ensure you're improving. You may be surprised by how quickly you can change your levels.`,
                        500,
                        () => setShowFirstTestImage(true),
                      ]}
                      speed={90}
                      wrapper="div"
                      cursor={false}
                      className="projection-description"
                    />
                  </Paragraph>
                )}

                <div
                  onClick={onTestosteronePanelClick}
                  className={`retest-kit-container ${showFirstTestImage ? 'show' : ''}`}
                >
                  {PhotoHelper.format({
                    className: "retest-kit-img",
                    webp: "/img/testosterone-panel.webp",
                    png: "/img/testosterone-panel.png",
                  })}
                </div>

                <Button
                  type="primary"
                  size="large"
                  className={`retest-cta-btn ${showFirstTestImage ? 'show' : ''}`}
                  onClick={onTestosteronePanelClick}
                >
                  Order Your Testosterone Panel
                </Button>
              </div>
            )}

            {lastResultDate && ((new Date().getTime() - lastResultDate.getTime()) > (365 * 24 * 60 * 60 * 1000)) && (
              <div className="retest-reminder">
                {showFourthAnimation && (
                  <Paragraph>
                    <TypeAnimation
                      sequence={[
                        `Time for Your Follow-up`,
                        500,
                        () => setShowFifthAnimation(true),
                      ]}
                      speed={90}
                      wrapper="div"
                      cursor={false}
                      className="projection-title"
                    />
                  </Paragraph>
                )}

                {showFifthAnimation && (
                  <Paragraph>
                    <TypeAnimation
                      sequence={[
                        `It's been more than a year since your last testosterone test. We recommend getting tested again to ensure your free testosterone levels are ${percentage === 100 ? 'stable' : 'improving'}:`,
                        500,
                        () => setShowFirstTestImage(true),
                      ]}
                      speed={90}
                      wrapper="div"
                      cursor={false}
                      className="projection-description"
                    />
                  </Paragraph>
                )}

                <div
                  onClick={onTestosteronePanelClick}
                  className={`retest-kit-container ${showFirstTestImage ? 'show' : ''}`}
                >
                  {PhotoHelper.format({
                    className: "retest-kit-img",
                    webp: "/img/testosterone-panel.webp",
                    png: "/img/testosterone-panel.png",
                  })}
                </div>

                <Button
                  type="primary"
                  size="large"
                  className={`retest-cta-btn ${showFirstTestImage ? 'show' : ''}`}
                  onClick={onTestosteronePanelClick}
                >
                  Order Your Testosterone Panel
                </Button>
              </div>
            )}

            {!lastResultDate && (
              <div className="retest-reminder">

                <Paragraph className="projection-title">
                  <TypeAnimation
                    sequence={[
                      `Take Your First Testosterone Test`,
                      500,
                      () => setShowSecondAnimation(true),
                    ]}
                    speed={90}
                    wrapper="div"
                    cursor={false}
                    className="projection-title"
                  />
                </Paragraph>

                {showSecondAnimation && (
                  <Paragraph>
                    <TypeAnimation
                      sequence={[
                        `At Instalab, we've made it simple to check your testosterone levels from the comfort of your home. Our test kit measures both total and free testosterone, along with related proteins like albumin and sex hormone-binding globulin (SHBG).`,
                        500,
                        () => token ? setShowFirstTestImage(true) : setShowThirdAnimation(true),
                      ]}
                      speed={90}
                      wrapper="div"
                      cursor={false}
                      className="projection-description"
                    />
                  </Paragraph>
                )}

                {showThirdAnimation && !token && (
                  <Paragraph>
                    <TypeAnimation
                      sequence={[
                        `If you already have an Instalab account with free testosterone test results, `,
                        () => setShowLoginLink(true),
                      ]}
                      speed={90}
                      wrapper="span"
                      cursor={false}
                      className="projection-description"
                    />
                    {showLoginLink && (
                      <>
                        <span 
                          className="login-link" 
                          onClick={() => navigate(`/login?redirect=/testosterone-projection`)}
                        >
                          <TypeAnimation
                            sequence={['log in now']}
                            speed={90}
                            wrapper="span"
                            cursor={false}
                          />
                        </span>
                        <span className="login-suffix">
                          <TypeAnimation
                            sequence={[
                              ' to use this tool.',
                              500,
                              () => setShowFirstTestImage(true),
                            ]}
                            speed={90}
                            wrapper="span"
                            cursor={false}
                          />
                        </span>
                      </>
                    )}
                  </Paragraph>
                )}

                <div
                  onClick={onTestosteronePanelClick}
                  className={`retest-kit-container ${showFirstTestImage ? 'show' : ''}`}
                >
                  {PhotoHelper.format({
                    className: "retest-kit-img",
                    webp: "/img/testosterone-panel.webp",
                    png: "/img/testosterone-panel.png",
                  })}
                </div>

                <Button
                  type="primary"
                  size="large"
                  className={`retest-cta-btn ${showFirstTestImage ? 'show' : ''}`}
                  onClick={onTestosteronePanelClick}
                >
                  Order Your Testosterone Panel
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}