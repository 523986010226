const excludeEvents = [
  `In Transit to Next Facility`,
  `Arrived at USPS Regional Facility`,
  `Departed USPS Regional Facility`,
]

const ShippingHelper = {
  getImportantEvents: (events) => {
    if (!events?.length) return []
    return events
      .filter(event => {
        return !excludeEvents.some(key => event.eventType.includes(key))
      })
      .sort((a, b) => new Date(b.eventTimestamp) - new Date(a.eventTimestamp));
  }
}

export default ShippingHelper