export const Step = Object.freeze({
  PREGNANCY: 'pregnancy',
  ETHNICITY: 'ethnicity',
  FAMILY_HISTORY: 'family-history',
  CURRENT_MEDICAL_CONDITIONS: 'current-medical-conditions',
  ALL_MEDICAL_CONDITIONS: 'all-medical-conditions',
  CURRENT_MEDICATIONS: 'current-medications',
  CURRENT_SUPPLEMENTS: 'current-supplements',
  ALCOHOL: 'alcohol',
  DIET: 'diet',
  SMOKING: 'smoking',
  EXERCISE: 'exercise',
  GENDER: 'gender',
  DOB: 'dob',
  PRT: 'prt',
  BIRTH_CONTROL: 'birth-control'
})

export default Step