import { Row, Col } from 'antd';

import "./xGrid.scss";

export const XGrid = ({ panels, className }) => {
  return (
    panels?.length > 0 && (
      <div className={`product-details-x-grid ${className}`}>
        <Row gutter={[12,0]}>
          {panels.map(({ title, text, image }, index) => (
            <Col
              key={index}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              className="x-grid-item"
            >
              <div className="x-grid-item-content">
                <div className="x-grid-item-text">
                  <div className="grid-title">{title}</div>
                  <div className="grid-description">{text}</div>
                </div>

                <div className="x-grid-item-image">
                  <img src={image} alt={title} />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    )
  );
};
