import { Typography } from "antd";
import { TestItemList } from "../testItemLlist/testItemList.component";
import { useEffect, useState } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { TestChart } from "../testChart/testChart.component";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { TestCode, ReportSection } from "../../../enums/index.enum"
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import "./geneticCardioAdvice.scss";

const { Paragraph } = Typography;

const primaryCodes =[
  TestCode.LIPOPROTEIN_A,
  TestCode.APO_E
]

export const GeneticCardioAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [cardioTests, setCardioTests] = useState();
  const [hasRecs, setHasRecs] = useState();
  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  const [secondaryDescription, setSecondaryDescription] = useState();

  useEffect(() => {
    setHasRecs(ReportHelper.getRecommendations(report, ReportSection.GENETIC_CARDIO_ADVICE).length>0)
    setTitle(getTitle())
  }, [report]);

  useEffect(() => {
    setCardioTests(getCardioTests());
  }, [report, tests]);

  const getCardioTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(report, ReportSection.GENETIC_CARDIO_ADVICE, tests).filter(({code}) => primaryCodes.includes(code));
  };

  useEffect(() => {
    setPrimaryDescription(getPrimaryDescription())
    setSecondaryDescription(getsSecondaryDescription())
  }, [report, cardioTests])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
          secondaryDescription: renderToString(secondaryDescription),
          recs
        }
      }
    })
  }, [title, primaryDescription, secondaryDescription, recs])

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
    if (!report) return null;
    return <>You are genetically predisposed to <span>cardiovascular disease</span>.</>
  }

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription)
      
    if (!report || !cardioTests) return null;
    
    if (cardioTests?.length === 1 && cardioTests[0].code === TestCode.LIPOPROTEIN_A) {
      return <>Your Lp(a) is a genetically-determined biomarker that strongly indicates an increased risk of cardiovascular conditions like heart disease or stroke due to an increased propensity for clot formation.</>
    } else if (cardioTests?.length === 1 && cardioTests[0].code === TestCode.APO_E) {
      return <>Your APOE genotype is a genetic biomarker that strongly indicates an increased risk of cardiovascular conditions like heart disease or stroke due to a dysfunctional protein that decreases the uptake of atherogenic lipoproteins from the bloodstream.</>
    } else if (cardioTests?.length === 2) {
      return <>These genetic biomarkers strongly indicate an increased risk of cardiovascular conditions like heart disease or stroke due to reduced uptake of lipoproteins from the bloodstream and increased propensity for clot formation.</> 
    }
  }

  const getsSecondaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.secondaryDescription)
      
    if (!report || !cardioTests) return null;

    let content

    if (cardioTests?.length === 1 && cardioTests[0].code === TestCode.APO_E) {
      content = <>That said, it's important to note this is NOT a deterministic gene. It's not guaranteed you will develop cardiovascular disease.</>
    } else if (cardioTests?.length === 1 && cardioTests[0].code === TestCode.LIPOPROTEIN_A) {
      content = <>That said, it's important to note this is NOT a deterministic biomarker. It's not guaranteed you will develop cardiovascular disease.</>
    } else if (cardioTests?.length === 2)  {
      content = <>That said, it's important to note these are NOT deterministic biomarkers. It's not guaranteed you will develop cardiovascular disease.</> 
    }

    content = <>{content} 
      {" "}But it does mean you should be proactive in reducing your risk.
      <br/><br/>
      The good news is that your lipid biomarkers are in good shape at the moment. It's
      advised to test often to make sure they're never going in the wrong direction.
    </>

    return content
  }

  return (
    report && cardioTests && (
      <div className="genetic-cardio-advice">
        <Paragraph className="report-template-title">
          {title}
        </Paragraph>
        <Paragraph className="report-template-description">
          {primaryDescription}
        </Paragraph>

        {cardioTests?.length === 1 &&
        cardioTests[0].code === TestCode.LIPOPROTEIN_A ? (
          <TestChart 
            code={TestCode.LIPOPROTEIN_A} 
            report={report} 
            tests={tests}
            results={results}
            reports={reports}
          />
        ) : (
          <TestItemList
            report={report}
            testIds={cardioTests.map(({ _id }) =>  _id)}
            tests={tests}
            results={results}
            user={user}
            reports={reports}
          />
        )}
        <Paragraph className="report-template-description">
          {secondaryDescription}
        </Paragraph>

        <TestItemList
          report={report}
          testIds={[
            TestCode.APO_B,
            TestCode.DIRECT_LDL,
            TestCode.TG,
            TestCode.VLDL,
            TestCode.TC,
            TestCode.HDL,
          ].map((code) => tests.find((test) => test.code === code)?._id)}
          tests={tests}
          results={results}
          user={user}
          reports={reports}
        />

        { hasRecs && 
          <><br/><br/>
          <AdviceSection 
            report={report} 
            results={results}
            tests={tests}
            section={section}
            recs={recs}
            setRecs={setRecs}
            reports={reports}
          />
          </>

        }
      </div>
    )
  );
};
