import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listMembershipTypes = async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/membership-types`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getMembershipType = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/membership-types/${id}`, {
    params: ServiceHelper.getParams({
      idType: '_id'
    }),
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getMembershipTypeByCode = async (code) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/membership-types/code/${code}`, {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addMembershipType = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/membership-types`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updateMembershipType = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/membership-types/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeMembershipType = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/membership-types/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}