import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Dropdown, message, Modal, Typography, Tooltip } from 'antd'
import { 
  listAppointmentParents, 
  removeAppointmentParent 
} from "../../services/appointment.service"
import { SettingOutlined, DeleteOutlined, EditOutlined, EnvironmentOutlined, LinkOutlined } from '@ant-design/icons'
import './adminAppointmenParents.scss'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { AppointmentParentForm } from '../appointmentParentForm/appointmentParentForm.component.js';
import ObjectType from '../../enums/objectType.enum';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import AppointmentParentHelper from '../../helpers/appointmentParent.helper.js';
import classNames from 'classnames';
import { UserContext } from '../../contexts/user.context.js';

const { Column } = Table
const { Text } = Typography
dayjs.extend(utc)
dayjs.extend(timezone)

const select = 'status start end title location'
const populate = [{
  path: 'phlebotomist',
  select: 'firstName'
}]

export const AdminAppointmentParents = () => {
  const [appointmentParents, setAppointmentParents] = useState([])
  const [appointmentParentId, setAppointmentParentId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isEditModalOpen, setIsEditModalOpen] = useState()
  const [isAdminLocationModalOpen, setIsLocalModalOpen] = useState()
  const { currentUser, setCounts } = useContext(UserContext)

  useEffect(() => {
    document.title = 'Instalab | Events'
    fetchAppointmentParents()
  }, [])

  const fetchAppointmentParents = async () => {
    setAppointmentParents(await listAppointmentParents({
      select,
      populate,
    }))
    setIsLoading(false)
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this event?',
      content: 'This action cannot be undone and will permanently remove the event from your system.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removeAppointmentParent(_id);
          setAppointmentParents(appointmentParents.filter(appointmentParent => appointmentParent._id !== _id));
          message.info('AppointmentParent removed');
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              events: cachedCounts.events - 1
            }
          })
        } catch (err) {
          message.error('Failed to remove appointmentParent');
        }
      }
    });
  }

  const items = (_id) => [
    {
      key: '0',
      label: (
        <a onClick={() => AppointmentParentHelper.copyJoinLink(_id, message)}>
          <LinkOutlined style={{ marginRight: 5 }} /> Join Link
        </a>
      ),
      adminOnly: true
    },
    {
      type: 'divider'
    }, 
    {
    key: '1',
    label: (
      <a onClick={() => {
        setAppointmentParentId(_id)
        setIsEditModalOpen(true)
      }}>
        <EditOutlined style={{ marginRight: 5 }} /> Edit
      </a>
    )
  },{
    key: '2',
    label: (
      <a onClick={() => {
        setAppointmentParentId(_id)
        setIsLocalModalOpen(true)
      }}>
        <EnvironmentOutlined style={{ marginRight: 5 }} /> Set Location
      </a>
    )
  }, 
  {
    type: 'divider'
  }, {
    key: '4',
    label: (
      <a 
        onClick={() => onRemove(_id)}
        className="remove-item"
      >
        <DeleteOutlined style={{ marginRight: 5 }} /> Remove
      </a>
    )
  }]

  const convertTimeZone = (time) => {
    const currentUserTimeZone = currentUser?.location?.timeZoneId || 'UTC'; // Default to UTC if timezone is not provided
    const formattedDate = dayjs(time).tz(currentUserTimeZone);
    return formattedDate
  }

  return (
    <div className="admin-events">
      <PageHeader
        title='Events'
        count={appointmentParents.length}
        actions={(
          <Button
            type='primary'
            onClick={() => {
              setAppointmentParentId(null);
              setIsEditModalOpen(true)
            }}
          >
            + Add New Event
          </Button>
        )}
      />

      <AdminLocationModal
        objectId={appointmentParentId}
        objectType={ObjectType.APPOINTMENT_PARENT}
        open={isAdminLocationModalOpen}
        setOpen={setIsLocalModalOpen}
        select={select}
        populate={populate}
        onSuccess={appointmentParent => {
          setAppointmentParents(appointmentParents.map(p => p._id === appointmentParentId ? appointmentParent : p))
        }}
      />

      <AppointmentParentForm
        appointmentParentId={appointmentParentId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        select={select}
        populate={populate}
        onSuccess={appointmentParent => {
          if (appointmentParentId) {
            setAppointmentParents(appointmentParents.map(p => p._id === appointmentParentId ? appointmentParent : p))
          } else {
            setAppointmentParents([
              appointmentParent,
              ...appointmentParents
            ])
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                events: cachedCounts.events + 1
              }
            })
          }
        }}
      />


      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={appointmentParents}
      >

        <Column
          title='Status'
          dataIndex='status'
          width={105}
          render={status => <Text className={classNames(`${status}-status`, "appointment-status")}>{status}</Text>}
        />

        <Column
          title='Event'
          dataIndex='title'
        />


        <Column
          title='Location'
          dataIndex='location'
          render={location => {
            if (!location) return null
            const { streetAddress, streetAddress2, city, state, postalCode } = location
            const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
            return <Tooltip title={fullAddress}>{city}</Tooltip>
          }}
        />


        <Column
          title='Phleb'
          dataIndex='phlebotomist'
          render={phlebotomist => phlebotomist && phlebotomist.firstName}
        />
        
        <Column
          title="#"
          dataIndex='appointments'
          render={(appointments) => appointments?.length}
        />
        
      
        <Column
          title='Start'
          dataIndex='start'
          render={(start, { location }) => start && convertTimeZone(start, location).format('MMM D, YYYY')}
        />

        <Column
          dataIndex='startTime'
          render={(_, { start, location }) => start && convertTimeZone(start, location).format('h:mma z')}
        />

        <Column
          title='End'
          dataIndex='end'
          render={(end, { location }) => end && convertTimeZone(end, location).format('MMM D, YYYY')}
        />

        <Column
          dataIndex='endTime'
          render={(_, { end, location }) => end && convertTimeZone(end, location).format('h:mma z')}
        />

        <Column
          dataIndex='action-items'
          width={80}
          className="action-column"
          render={(_, { _id }) => (
            <Dropdown 
              menu={{ items: items(_id) }}
              placement='bottomRight'
            >
              <Button>
                <SettingOutlined />
              </Button>
            </Dropdown>
          )}
        />
      </Table>
    </div>
  )
}