import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Button, Empty, Typography, Statistic } from 'antd';
import { CalendarOutlined, ExpandOutlined, EnvironmentOutlined, ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { BlogData } from '../../data/blog.data';
import { UserContext } from '../../contexts/user.context';
import { SupportSidebar } from '../supportSidebar/supportSidebar.component';
import { TestCatalog } from '../testCatalog/testCatalog.component';
import { listAppointments } from "../../services/appointment.service";
import { listConsults } from '../../services/consult.service';
import AppointmentStatus  from '../../enums/appointmentStatus.enum';
import dayjs from 'dayjs';
import './dashboard.scss';
import { ReactComponent as DoctorIcon } from '../../assets/svg/doctor-2.svg';
import ScheduleMenu from '../scheduleMenu/scheduleMenu.component';
import { listProductTypes } from '../../services/productType.service';
import ProductTypeCode from "../../enums/productTypeCode.enum.js";
import FlowType  from '../../enums/flowType.enum';
import MembershipHelper  from '../../helpers/membership.helper';
import MembershipTypeCode  from '../../enums/membershipTypeCode.enum';
import { getMe } from '../../services/user.service';
import { ResearchCard } from '../researchCard/researchCard.component';
import AddFundsModal from '../addFundsModal/addFundsModal.component';
import { Spin } from 'antd';
import TestHelper from '../../helpers/test.helper';
import RecommendationHelper from '../../helpers/recommendation.helper';
const { Title, Paragraph, Text } = Typography;

const appointmentSelect = '_id status location start type products facility';
const appointmentPopulate = [{
  path: 'products',
  populate: [{
    path: 'productType'
  }]
}, {
  path: 'facility'
}];

const consultSelect = '_id name start status remoteLocation reason';
const consultPopulate = [{
  path: 'provider',
  select: 'firstName lastName photo npi'
}, {
  path: 'flow',
  select: '_id products type',
  populate: {
    path: 'products',
    select: 'productType',
    populate: {
      path: 'productType',
      select: 'code title',
    }
  }
}];

const collectionMethods = [
  ProductTypeCode.MOBILE_BLOOD_DRAW,
  ProductTypeCode.PRO_MOBILE_BLOOD_DRAW,
  ProductTypeCode.LAB_VISIT,
]

export const Dashboard = () => {
  const { currentUser, memberships, instalabMembership } = useContext(UserContext);
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState([]);
  const [consults, setConsults] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [cashBalance, setCashBalance] = useState(null);
  const [openAddFundsModal, setOpenAddFundsModal] = useState(false);
  const [isBalanceAnimating, setIsBalanceAnimating] = useState(false);
  const [recommendedTests, setRecommendedTests] = useState([]);

  useEffect(() => {
    document.title = `Home | Instalab`;
    fetchCashBalance();
    fetchAppointments();
    fetchConsults();
    fetchProductTypes();
  }, []);

  useEffect(() => {
    const recommendations = RecommendationHelper.getRecommendations(currentUser);
    setRecommendedTests(recommendations);
  }, [currentUser]);

  const fetchCashBalance = async () => {
    const response = await getMe({
      select: 'cashBalance',
    });
    setCashBalance(response.cashBalance || 0);
  }

  const fetchAppointments = async () => {
    try {
      const appts = await listAppointments({ 
        filter: {
          status: {
            $in: [AppointmentStatus.CONFIRMED, AppointmentStatus.PENDING]  // Only show upcoming appointments
          },
          products: { $exists: true, $not: { $size: 0 } }
        },
        select: appointmentSelect,
        populate: appointmentPopulate,
        sort: 'start',
      });
      setAppointments(appts);
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
    }
  };

  const fetchConsults = async () => {
    try {
      const fetchedConsults = await listConsults({
        select: consultSelect,
        populate: consultPopulate,
        sort: 'start',
        filter: {
          start: { $gt: new Date() }  // Only fetch future consults
        }
      });
      setConsults(fetchedConsults);
    } catch (error) {
      console.error('Failed to fetch consults:', error);
    }
  };

  const fetchProductTypes = async () => {
    if (!currentUser) return;

    let filter = {
      code: { $in: [
        ProductTypeCode.LONGEVITY_PANEL, 
        ProductTypeCode.CTCALCIUM,
        ProductTypeCode.CLEERLY,
        ProductTypeCode.DEXA_BODYCOMP,
        ProductTypeCode.DEXA_BONE,
        ProductTypeCode.TESTOSTERONE_PANEL,
        ProductTypeCode.PRENUVO,
        ProductTypeCode.VO2MAX,
      ] }
    };

    try {
      let types = await listProductTypes({ filter });

      // Sort the test types
      types = types.sort((a, b) => {
        if (a.code === ProductTypeCode.ATHLETE_PANEL) return -1;
        if (b.code === ProductTypeCode.ATHLETE_PANEL) return 1;
        if (a.code === ProductTypeCode.LONGEVITY_PANEL) return -1;
        if (b.code === ProductTypeCode.LONGEVITY_PANEL) return 1;
        return a.title.localeCompare(b.title);
      });

      // Add athlete panel at the beginning if user is an athlete
      if (currentUser?.isAthlete) {
        types.unshift({
          code: ProductTypeCode.ATHLETE_PANEL,
          title: 'Athlete Panel'
        });
      }

      // Add consult options
      let consultTypes = [];

      // Add Heart Health Consult if user has active heart membership
      const heartHealthMembership = MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.HEART_HEALTH);
      if (heartHealthMembership) {
        consultTypes.push({
          title: 'Heart Health Consult',
          code: ProductTypeCode.CONSULT_HEART_HEALTH,
          flowType: FlowType.HEART_MEMBER_CONSULT
        });
      }

      // Add appropriate consult based on user type
      if (currentUser?.isAthlete) {
        consultTypes.push({
          title: 'Athlete Consult',
          code: ProductTypeCode.CONSULT_HEART_HEALTH,
          flowType: FlowType.ATHLETE_CONSULT
        });
      } else {
        consultTypes.push({
          title: 'Longevity Consult',
          code: ProductTypeCode.CONSULT_LONGEVITY,
          flowType: FlowType.LONGEVITY_CONSULT,
        });
      }
      
      // Add a divider object between tests and consults
      setProductTypes([
        ...types, 
        { isDivider: true },
        ...consultTypes
      ]);
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  const getLatestResearch = () => {
    return BlogData.slice(0, 4);
  };

  const AppointmentCard = ({ appointment }) => (
    <Card 
      className="appointment-card"
      hoverable
      bordered={true}
    >
      <div className="appointment-card-content">
        <Row justify="space-between" align="top">
          <Col flex="1">
            <div className="tests">
              {appointment.products?.filter(product => !collectionMethods.includes(product.productType?.code)).map(product => product.productType?.title).join(', ')}
            </div>

            <div className="time">
              <CalendarOutlined /> 
              {appointment.start ? 
                dayjs(appointment.start)
                  .tz(appointment.facility?.location?.timeZoneId || appointment.location?.timeZoneId)
                  .format('MMM D, YYYY @ h:mm a z') 
                : 'TBD'
              }
            </div>

            <div className="location">
              <EnvironmentOutlined />
              <span>
                {appointment.facility ? 
                  `${appointment.facility.name}` : 
                  appointment.location ? 
                    `${appointment.location?.city}, ${appointment.location?.state}` :
                    'TBD'
                }
              </span>
            </div>
          </Col>
          <Col>
            <Button 
              type="default"
              onClick={() => navigate('/patient/appointments')}
            >
              View Details
            </Button>
          </Col>
        </Row>
      </div>
    </Card>
  );

  const onAddFunds = async (amount) => {
    setIsBalanceAnimating(true);
    setCashBalance(cashBalance + amount);
    setOpenAddFundsModal(false);
    
    // Remove animation class after animation completes
    setTimeout(() => {
      setIsBalanceAnimating(false);
    }, 1000);
  }

  const BalanceCard = () => {
    return (
      <Card 
        className="section-card flat balance-card" 
        style={{ marginBottom: 24 }}
        title="Instalab Cash"
        actions={[
          <Button 
          type="link"
          size="middle"
          className="gray-link"
          icon={<PlusOutlined />}
          onClick={() => setOpenAddFundsModal(true)}
        >
          Add Funds
        </Button>
        ]}
      >

        <div className="balance-content">
          <Row align="top" justify="space-between" gutter={[16, 16]}>
            <Col>
              <Text 
                className={`balance-amount ${isBalanceAnimating ? 'balance-updated' : ''}`} 
              >
                {cashBalance !== null ? `$${cashBalance.toFixed(2)}` : <Spin />}
              </Text>
              <Text type="secondary" className="balance-description">
                Pre-fund your account for tests or services. HSA/FSA eligible.
              </Text>
            </Col>

          </Row>
        </div>
      </Card>
    )
  }


  return currentUser && (
    <div className="dashboard">
      <AddFundsModal
        open={openAddFundsModal}
        setOpen={setOpenAddFundsModal}
        onSuccess={onAddFunds}
      />

      <Row gutter={[24, 24]}>
        {/* Welcome Section */}
        <Col span={24}>
          <Card className="section-card flat" style={{ marginBottom: 0 }}>
            <Title level={2}>
              {currentUser.firstName ? 
                dayjs().diff(dayjs(currentUser.createdAt), 'day') > 0 ?
                  `Welcome back, ${currentUser.firstName}.` :
                  `Welcome, ${currentUser.firstName}.` :
                'Welcome!'}
            </Title>
          <Paragraph>Let us know what you'd like to do next.</Paragraph>
          </Card>
        </Col>


        {/* Biomarker Status Section */}
        {currentUser?.stats && currentUser?.stats?.totalTests > 0 && <Col span={24}>
          <Card 
            className="section-card flat biomarker-status-card" 
            style={{ marginBottom: 0 }}
            title="Your Health Status"
            actions={[
              <Button type="gray-link" onClick={() => navigate('/patient/results')}>
                View All Results <ArrowRightOutlined />
              </Button>
            ]}
          >


            {/* Longevity Score Card */}
            {currentUser.stats.longevityScore && (
              <Card className={`longevity-score-card ${TestHelper.getLongevityRisk(currentUser.stats.longevityScore)}`} bordered={false}>
                <Row align="middle">
                  <Col xs={24} md={14}>
                    <Title level={4} style={{ marginTop: 0, marginBottom: 8 }}>Longevity Score</Title>
                    <Text type="secondary" style={{ fontSize: '14px', lineHeight: '1.5' }}>
                    A higher score reflects a healthier trajectory based on your biomarker levels. Positive changes in diet, exercise, and habits can boost your score over time.
                    </Text>
                  </Col>
                  <Col xs={24} md={10}>
                    <div className={`score-container`} onClick={() => navigate(`/patient/results`)}>
                      <Statistic 
                        value={currentUser.stats.longevityScore}
                        suffix="/100"
                        style={{ fontWeight: 600 }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            )}

            {/* Biomarker Stats Cards */}
            <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
              <Col xs={24} sm={8}>
                <Card 
                  className="stat-card optimal" 
                  bordered={false}
                  onClick={() => navigate('/patient/results?filter=low-risk')}
                  style={{ cursor: 'pointer' }}
                >
                  <Statistic 
                    title="Optimal" 
                    value={currentUser.stats.optimal}
                    suffix="Biomarkers"
                  />
                  <Text type="secondary">Within healthy range</Text>
                </Card>
              </Col>

              <Col xs={24} sm={8}>
                <Card 
                  className="stat-card borderline" 
                  bordered={false}
                  onClick={() => navigate('/patient/results?filter=high-moderate-risk')}
                  style={{ cursor: 'pointer' }}
                >
                  <Statistic 
                    title="Borderline" 
                    value={currentUser.stats.borderline}
                    suffix="Biomarkers"
                  />
                  <Text type="secondary">Near optimal range</Text>
                </Card>
              </Col>

              <Col xs={24} sm={8}>
                <Card 
                  className="stat-card at-risk" 
                  bordered={false}
                  onClick={() => navigate('/patient/results?filter=high-risk')}
                  style={{ cursor: 'pointer' }}
                >
                  <Statistic 
                    title="Needs Attention" 
                    value={currentUser.stats.atRisk}
                    suffix="Biomarkers"
                  />
                  <Text type="secondary">Outside optimal range</Text>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>}

  
        {/* Main Content Area */}
        <Col xs={24} lg={15}>

          {/* Appointments Section */}
          {(appointments.length > 0  || consults.length > 0) && 
          <Card className="section-card flat" title="Upcoming Appointments" style={{ marginBottom: 24 }}>

            {(appointments.length > 0 || consults.length > 0) ? (
              <Row gutter={[24, 24]}>
                {appointments.map((appointment) => (
                  <Col xs={24} key={appointment._id}>
                    <AppointmentCard appointment={appointment} />
                  </Col>
                ))}
                
                {consults.map((consult) => (
                  <Col xs={24} key={consult._id}>
                    <Card 
                      className="appointment-card"
                      bordered={false}
                    >
                      <div className="appointment-card-content">
                        <Row justify="space-between" align="top">
                          <Col flex="1">
                            <div className="tests">
                              {consult?.name || consult.flow?.products?.[0]?.productType?.title || 'Consultation'}
                            </div>

                            <div className="time">
                              <CalendarOutlined /> 
                              {consult.start ? 
                                dayjs(consult.start)
                                  .tz(currentUser?.location?.timeZoneId || 'UTC')
                                  .format('MMM D, YYYY @ h:mm a') 
                                : 'TBD'
                              }
                            </div>

                            <div className="provider">
                              <DoctorIcon style={{ marginRight: 8, width: 16, height: 16 }} />
                              <span>
                                {consult.provider ? 
                                  `Dr. ${consult.provider.firstName} ${consult.provider.lastName}` :
                                  'Provider TBD'
                                }
                              </span>
                            </div>
                          </Col>
                          <Col>
                            <Button 
                              type="default"
                              onClick={() => navigate('/patient/consults')}
                            >
                              View Details
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="compact-empty"
                description={
                  <div className="empty-appointments">
                    <Text>No upcoming appointments</Text>
                    <ScheduleMenu
                      productTypes={productTypes}
                      currentUser={currentUser}
                      buttonType="default"
                    >
                    </ScheduleMenu>
                  </div>
                }
              />
            )}
          </Card>}

            {/* Test Catalog Section */}
            
            {recommendedTests.length >= 2 ? ( 
              <Card 
                className="section-card flat" 
                title="Recommended Tests"
                actions={[
                  <Button type="gray-link" onClick={() => navigate('/catalog?category=recommended')}>
                    View All <ArrowRightOutlined />
                  </Button>
                ]}
              >
                <TestCatalog 
                  showAll={false}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={12}
                  xxl={12}
                  viewMore={false}
                  hideCategories={true}
                  hidePrices={true}
                  count={4}
                  noFrills={true}
                  defaultCategory="recommended"
                /> 
              </Card>
            ) : (
              <Card className="section-card flat" title="Recommended Tests">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  className="compact-empty"
                  description={
                    <div className="empty-appointments">
                      <Text>No Recommended Tests Yet</Text>
                    <Button type="primary" size="middle" onClick={() => navigate('/start')}>
                      Get Started <ArrowRightOutlined />
                    </Button>
                      </div>
                  }
                />

              </Card>

            )}
         

          {/* Latest Research Section */}
          <Card className="section-card flat" 
            style={{ marginTop: 24 }} 
            title="Latest Research"
            actions={[
              <Button type="gray-link" onClick={() => navigate('/research')}>
                View All <ArrowRightOutlined />
              </Button>
            ]}
          >
            <Row gutter={[16, 16]}>
              {getLatestResearch().map(({ id, title, description, publishedAt, readTime }) => (
                <Col xs={24} xl={12} key={id}>
                  <ResearchCard
                    title={title}
                    description={description}
                    readTime={readTime}
                    publishedAt={publishedAt}
                    onClick={() => navigate(`/research/${id}`)}
                  />
                </Col>
              ))}
            </Row>
          </Card>
        </Col>

        {/* Right Sidebar */}
        <Col xs={24} lg={9}>
   
          <Row>
            <Col xs={24} lg={24}>
              <BalanceCard />
            </Col>
          </Row>

          { <SupportSidebar />} 
          

        </Col>
      </Row>
    </div>
  );
};