import React from "react";
import "./sermImpactAdvice.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { LowercaseTest, ReportSection, Condition, TestCode } from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
const converter = require("number-to-words");

const { Paragraph } = Typography;

export const SermImpactAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [possibleSERMUsage, setPossibleSERMUsage] = useState();
  const [hasElevatedLHFSH, setHasElevatedLHFSH] = useState();
  const [hormoneTests, setHormoneTests] = useState([]);
  const [improvedTests, setImprovedTests] = useState([]);
  const [worseTests, setWorseTests] = useState([]);
  const [staticTests, setStaticTests] = useState([]);

  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  const [secondaryDescription, setSecondaryDescription] = useState();
  
  useEffect(() => {
    categorizeTests();
    setPossibleSERMUsage(report?.result?.conditions.includes(Condition.POSSIBLE_SERM_USAGE));
  }, [report, tests]);

  useEffect(() => {
    if (!hormoneTests) return;
    const hasElevatedLHFSH = hormoneTests.find(hormoneTest => hormoneTest.code === TestCode.LH || hormoneTest.code === TestCode.FSH);
    setHasElevatedLHFSH(hasElevatedLHFSH);
  }, [hormoneTests]);

  useEffect(() => {
    setTitle(getTitle());
    setPrimaryDescription(getPrimaryDescription());
    setSecondaryDescription(getSecondaryDescription());
  }, [report, hormoneTests, hasElevatedLHFSH, results, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
          secondaryDescription: renderToString(secondaryDescription),
        }
      };
    });
  }, [title, primaryDescription, secondaryDescription]);

  const categorizeTests = () => {
    if (!report || !tests) return null;
    setHormoneTests(ReportHelper.getSectionTests(report, ReportSection.SERM_IMPACT, tests) || []);
    setImprovedTests(ReportHelper.getSectionImprovedTests(report, ReportSection.SERM_IMPACT, tests) || []);
    setWorseTests(ReportHelper.getSectionWorseTests(report, ReportSection.SERM_IMPACT, tests) || []);
    setStaticTests(ReportHelper.getSectionStaticTests(report, ReportSection.SERM_IMPACT, tests) || []);
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title);

    if (!report || !results || !tests || !hormoneTests) return null;
  
    if (possibleSERMUsage) {
      return <>These <span>{converter.toWords(hormoneTests.length)} biomarkers</span> are commonly seen with <span>SERM usage</span>.</>;
    }

    let content = (
      <>
        {hormoneTests?.length === 1
          ? <>Your <span>{LowercaseTest[hormoneTests[0].code]}</span> level is <span>abnormally high</span></>
          : <>These <span>{converter.toWords(hormoneTests?.length)} biomarkers</span> are <span>abnormal</span></>
        }, likely due your use of a SERM.
      </>
    );

    return content;
  };

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription);
    if (!report || !hormoneTests) return null;

    if (possibleSERMUsage) {
      return (
        <>
          SERMs, such as clomiphene, block estrogen receptors in the hypothalamus, leading to increased production of LH and FSH. This stimulation helps raise testosterone levels in men and support ovulation in women by enhancing natural hormone production.
        </>
      );
    }

    return (
      <>
        {hasElevatedLHFSH ? <>Selective Estrogen Receptor Modulators (SERMs), such as clomiphene and enclomiphene, are known to increase LH and FSH, which in turn stimulate your body’s natural testosterone production.
        {/* They also promote ovulation in women by increasing FSH and LH levels. This process supports fertility and natural hormone balance. */}
        <br/><br/>While this elevation is generally not a cause for concern, it’s important that the healthcare provider that prescribed your medication is aware of these levels. They can assess whether any adjustments to your dosage are necessary to optimize your treatment.</> : 

        <>SERMs, such as clomiphene, work by increasing LH and FSH, helping to support natural testosterone production in men and ovulation in women. If you're using SERMs to address fertility or hormone balance, be aware of the potential side effects related to abnormal hormone levels.
        <br/><br/>Consult your healthcare provider to monitor hormone levels and adjust therapy as needed.</>}
      </>
    );
  };

  const getSecondaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.secondaryDescription);
    if (!report || !hormoneTests) return null;

    if (possibleSERMUsage) {
      return (
        <>
          If you're using SERMs, it's essential to monitor your LH, FSH, and testosterone levels to avoid imbalances. If you're using SERMs for fertility, speak with your healthcare provider about optimizing the treatment for your specific needs.
          <br/><br/>If you are not taking SERMs and have elevated LH or FSH levels, consult a healthcare provider to determine the underlying causes and appropriate treatments.
        </>
      );
    }

    return null;
  };

  return (
    hormoneTests && (
      <div className="serm-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {primaryDescription}
        </Paragraph>
        <TestView 
          report={report} 
          tests={hormoneTests}
          results={results}
          reports={reports}
          user={user}
        />
        {secondaryDescription && (
          <Paragraph className="report-template-description">
            {secondaryDescription}
          </Paragraph>
        )}
      </div>
    )
  );
};