import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import { Reference } from "../reference/reference.component";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import "./smokingAdvice.scss"

const { Paragraph } = Typography;

export const SmokingAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [user, report]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])


  // this pulls up if user was smoking but so need something for congrats on quitting smoking!
  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!user || !report) return null;

    return (
      <>
        <span>Smoking cessation</span> is essential.
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!user || !report) return null;

    return (
      <>
      Choosing to stop smoking or vaping nicotine is one of the most impactful actions you can take to prevent chronic diseases and increase healthspan. Even light smoking, e.g. on average just 1 cigarette per day, <Reference
          content="increases the risk of premature death by 64%"
          url="https://jamanetwork.com/journals/jamainternalmedicine/fullarticle/2588812"
        />.
      <br/><br/>
      Thankfully, recent scientific evidence supports various effective options to help you quit smoking, including prescribed medications, nicotine replacement therapies and cognitive behavaioral therapy. 
      </>
    );
  };

  return (
    <div className="smoking-advice"> 
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">
        {description}
      </Paragraph>

      <AdviceSection 
        report={report}
        results={results}
        tests={tests}
        section={section}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />
    </div>
  )
};
