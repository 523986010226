import { useState } from 'react'
import { Modal } from "antd"
import classNames from "classnames";
import './moreInfoModal.scss'

export const MoreInfoModal = ({ linkText, modalTitle, modalContent, food }) => {
  const [open, setOpen] = useState()

  const onCancel = () => {
    setOpen(false)
  }

  return (
    <>
      <a 
        className={classNames("more-info-link", food ? "food" : null)}
        onClick={() => setOpen(true)}
      >
        {linkText}
      </a>

      <Modal 
        open={open} 
        title={modalTitle}
        className="more-info-modal"
        onCancel={onCancel}
        footer={null}
      >
        <div className="more-info-content">
          {modalContent}
        </div>
      </Modal>
    </>
  )
}