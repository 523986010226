import { Typography } from "antd";
import ReactDOM from "react-dom";
import {TestView} from "../testView/testView.component"
import { useState, useEffect } from "react";
import { TestCode, ReportSection, RiskLevel } from "../../../enums/index.enum"
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import "./hyperthyroidismAdvice.scss";

const converter = require("number-to-words");

const { Paragraph } = Typography;

const primaryCodes = [TestCode.TSH, TestCode.FREET4, TestCode.FREET3]

export const HyperthyroidismAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [primaryTests, setPrimaryTests] = useState();
  const [secondaryTests, setSecondaryTests] = useState();
  const [allTests, setAllTests] = useState();
  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  const [secondaryDescription, setSecondaryDescription] = useState();
  const [isManagingCondition, setIsManagingCondition] = useState();

  useEffect(() => {
    categorizeTests()
  }, [report, tests]);

  useEffect(() => {
    setIsManagingCondition(getIsManagingCondition())
  }, [report, tests, results, primaryTests]);

  useEffect(() => {
    setTitle(getTitle())
    setPrimaryDescription(getPrimaryDescription())
    setSecondaryDescription(getSecondaryDescription())
  }, [report, results, primaryTests, secondaryTests, isManagingCondition]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
          secondaryDescription: renderToString(secondaryDescription),
          recs
        }
      }
    })
  }, [title, primaryDescription, secondaryDescription, recs])

  useEffect(() => {
    replaceTestViewPrimaryTests()
  }, [primaryDescription])

  useEffect(() => {
    replaceTestViewSecondaryTests()
  }, [secondaryDescription])

  const categorizeTests = () => {
    if (!report || !tests) return null;
    const thyroidTests= ReportHelper.getSectionTests(report, ReportSection.HYPERTHYROIDISM_ADVICE, tests);
    setPrimaryTests(thyroidTests.filter(({ code }) => primaryCodes.includes(code)))
    setSecondaryTests(thyroidTests.filter(({ code }) => !primaryCodes.includes(code)))
    setAllTests(ReportHelper.getSectionAllTests(report, ReportSection.HYPERTHYROIDISM_ADVICE, tests).filter(({ code }) => primaryCodes.includes(code)))
  };



  const getIsManagingCondition = () => {
    if (!report || !tests | !results || !primaryTests) return;
    
    for (const test of primaryTests) {
      const risk = ReportHelper.getTestRisk(test.code, report, results, tests)
      if (risk !== RiskLevel.OPTIMAL && risk !== RiskLevel.MODERATE) return false
    }

    return true
  } 

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !results || !primaryTests) return;
    
    if (isManagingCondition) {
      return (   
        <>
        These{" "}<span>{converter.toWords(allTests?.length)} biomarkers</span>{" "}
        indicate you are managing your <span>hyperthyroidism</span>.
        </>
      )
    }

    return  <>These{" "}<span>{converter.toWords(primaryTests?.length)} biomarkers</span>{" "}for thyroid function indicate possible <span>hyperthyroidism</span>.</>
  }

  const replaceTestViewPrimaryTests = () => {
    const target = document.getElementById('test-view-primary-tests')
    if (!target || report === undefined) return
    const viewTests = isManagingCondition ? allTests : primaryTests
    if (!viewTests?.length) return

    ReactDOM.render(
      <TestView
        user={user} 
        report={report} 
        tests={viewTests}
        results={results}
        reports={reports}
      />,
      target
    );
  }

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription)

    if (!report || !primaryTests) return;
    
    return <>
      Hyperthyroidism, a condition where the thyroid gland overproduces hormones like T3 and T4, can accelerate metabolism, leading to symptoms such as rapid heartbeat, weight loss, anxiety, and more. 
      {isManagingCondition && <> Good job at getting these biomarkers to optimal levels!</>} 
      <div id="test-view-primary-tests" />
    </>
  }


  const replaceTestViewSecondaryTests = () => {
    const target = document.getElementById('test-view-secondary-tests')
    if (!target || report === undefined || !secondaryTests?.length) return

    ReactDOM.render(
      <TestView
        user={user} 
        report={report} 
        tests={secondaryTests}
        results={results}
        reports={reports}
      />,
      target
    );
  }

  const getSecondaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.secondaryDescription)

    if (!report || !secondaryTests || secondaryTests?.length<=0) return;

    return   <>
      <Paragraph className="report-template-description">
        The elevated levels of these thyroid antibodies biomarkers could be due to possible Graves' disease and/or a broader autoimmune disorder. They primarily signal autoimmune activity rather than direct harm. This underscores the importance of regularly monitoring your thyroid function.
      </Paragraph>
      <div id="test-view-secondary-tests" />
    </>
  }

  return (
    primaryTests && (
      <div className="hyperthyroidism-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>

        <Paragraph className="report-template-description">{primaryDescription}</Paragraph>

        <Paragraph className="report-template-description">{secondaryDescription}</Paragraph>


        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
