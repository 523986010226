import { BlogData } from "../../../../data/blog.data"
import { Typography, Row, Col, Divider } from "antd"
import './recommendedBlogs.scss'
import ReactDOMServer from 'react-dom/server'
import { useParams } from "react-router-dom"

const { Paragraph } = Typography

export const RecommendedBlogs = () => {
  const { blogId } = useParams()

  // Simple word overlap similarity function
  function calculateSimilarity(text1, text2) {
    const words1 = new Set(text1.split(/\W+/))
    const words2 = new Set(text2.split(/\W+/))
    const intersection = new Set([...words1].filter(word => words2.has(word)))
    return intersection.size
  }

  const listRecommneded = (blogs) => {
    const currentBlog = BlogData.find(({ id }) => id === blogId)
    const currentContent = (currentBlog?.title + ' ' + currentBlog?.description).toLowerCase()

    return blogs
      .filter(({ id, img }) => img && id !== blogId)
      .sort((a, b) => {
        const contentA = (a.title + ' ' + a.description).toLowerCase()
        const contentB = (b.title + ' ' + b.description).toLowerCase()
        
        // Calculate similarity scores
        const scoreA = calculateSimilarity(currentContent, contentA)
        const scoreB = calculateSimilarity(currentContent, contentB)
        
        return scoreB - scoreA // Sort descending
      })
      .slice(0, 4)
  }

  const getString = jsx => {
    const temp = document.createElement('div')
    temp.innerHTML = ReactDOMServer.renderToStaticMarkup(jsx)
    return temp.textContent || temp.innerText
  }

  return <>
    <Divider className="recommended-blogs-header">
      More Research
    </Divider>
    <div className="recommended-blogs">
      <Row gutter={48}>
        {listRecommneded(BlogData).map(({ id, title, description, publishedAt, readTime, img }) => (
          <Col 
            key={`recommended-blog-${id}`}
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <a href={`/research/${id}`}>
              <div className="recommended-blog">
                <img 
                  src={img}
                  className="recommended-blog-image"
                />

                <Paragraph 
                  className="recommended-blog-title"
                  ellipsis={{ rows: 1 }}
                >
                  {title}
                </Paragraph>
                <Paragraph 
                  className="recommended-blog-description"
                  ellipsis={{ rows: 3 }}
                >
                  {getString(description)}
                </Paragraph>
              </div>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  </>
}