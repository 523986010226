import { Input } from 'antd';
import React from 'react';

const DateInput = ({ value='', onChange, placeholder = 'MM/DD/YYYY' }) => {

  const formatDateString = (value) => {
    const cleaned = value.replace(/\D/g, '').slice(0, 8);
    const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
    if (match) {
      return `${match[1] ? match[1] : ''}${match[2] ? '/' + match[2] : ''}${match[3] ? '/' + match[3] : ''}`;
    }
    return value;
  };

  const handleChange = (e) => {
    const formattedValue = formatDateString(e.target.value);
    onChange(formattedValue);
  };

  return (
    <Input
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      inputMode="numeric"
    />
  );
};

export default DateInput;