import React, { useState, useEffect } from 'react'
import { Modal, Form, message, Input } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import "./membershipTypeForm.scss"
import { addMembershipType, getMembershipType, updateMembershipType } from '../../services/membershipType.service'
import RuleHelper from '../../helpers/rule.helper'

const { Item } = Form

export const MembershipTypeForm = ({ open, setOpen, onSuccess, membershipTypeId, setMembershipTypeId }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()

  useEffect(() => {
    if (membershipTypeId) {
      setFields()
    } else {
      form.resetFields()
    }
  }, [membershipTypeId])

  useEffect(() => {
    if (!open) {
      setMembershipTypeId(null)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const setFields = async () => {
    const {
      cost,
      title
    } = await getMembershipType(membershipTypeId)

    form.setFieldsValue({
      cost,
      title
    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        cost,
        title
      } = form.getFieldsValue()
      
      const params = {
        cost,
        title
      }

      const response = membershipTypeId ? await updateMembershipType(membershipTypeId, params) : await addMembershipType(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(membershipTypeId ? 'Membership type updated' : 'Membership type added')
      onCancel()
      form.resetFields()
    } catch (err) {
      let msg = membershipTypeId ? 'Failed to update membership type' : 'Failed to add membership type'
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  return (
    <div className="membership-type-form">
      <Modal 
        open={open} 
        title={membershipTypeId ? 'Edit Membership Type' : 'Add Membership Type'}
        onCancel={onCancel}
        onOk={onOk}
        okText='Save'
        width={600}
        footer={[
          <ContinueButton
            onClick={onOk}
            text='Save Membership Type'
            isSubmitting={isSubmitting}
          />
        ]}
      >
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
        >
          <Item 
            label="Title" 
            name="title"
            rules={[
              RuleHelper.isRequired,
            ]}
          >
            <Input placeholder="Title" />
          </Item>
          <Item 
            label="Cost" 
            name="cost"
            rules={[
              RuleHelper.isRequired,
            ]}
          >
            <Input placeholder="Cost" />
          </Item>
        </Form>
      </Modal>
    </div>
  )
}