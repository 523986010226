const RiskLevel = Object.freeze({
  PEAK: "peak",
  OPTIMAL: "optimal",
  ABNORMAL: "abnormal",
  MODERATE: "moderate",
  HIGH: "high",
  UNKNOWN: "unknown",
});

export default RiskLevel;
