import React from "react";
import "./autoimmuneAdvice.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { LowercaseTest, ReportSection, Condition, TestCode } from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import { AdviceSection } from "../adviceSection/adviceSection.component";
import parse from 'html-react-parser';
const converter = require("number-to-words");

const { Paragraph } = Typography;

export const AutoimmuneAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [autoimmuneTests, setAutoimmuneTests] = useState([]);
  const [recs, setRecs] = useState()

  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  
  useEffect(() => {
    categorizeTests();
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setPrimaryDescription(getPrimaryDescription());
  }, [report, autoimmuneTests, results, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
        }
      };
    });
  }, [title, primaryDescription]);

  const categorizeTests = () => {
    if (!report || !tests) return null;
    setAutoimmuneTests(ReportHelper.getSectionTests(report, ReportSection.AUTOIMMUNE_ADVICE, tests) || []);
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title);

    if (!report || !results || !tests || !autoimmuneTests) return null;
  
    let content = (
      <>
       These <span>{converter.toWords(autoimmuneTests?.length)} biomarkers</span> suggest you may be at risk for an <span>autoimmune condition</span>.
      </>
    );

    return content;
  };

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription);
    if (!report || !autoimmuneTests) return null;

    return (
      <>
      The ANA test detects antibodies that target the nucleus of your cells, which could indicate an autoimmune condition. These antibodies may cause inflammation and damage in various organs, potentially leading to diseases such as lupus or rheumatoid arthritis.
      <br/><br/>That said, many people with the ANA pattern and titer similar to yours do not develop these conditions. Further investigation is needed to make a more definitive call and is strongly recommended if you’re experiencing symptoms like fatigue, joint pain, or unexplained inflammation.
      </>
    );
  };

  

  return (
    autoimmuneTests && (
      <div className="autoimmune-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {primaryDescription}
        </Paragraph>
        <TestView 
          report={report} 
          tests={autoimmuneTests}
          results={results}
          reports={reports}
          user={user}
        />
        <AdviceSection 
        report={report} 
        results={results}
        tests={tests}
        section={section}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />

      </div>
    )
  );
};