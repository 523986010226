import axios from 'axios';
import ServiceHelper from '../helpers/service.helper';

// Create Payment Intent for Stripe
export const createPaymentIntent = async (params = {}) => {

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/stripe/create-payment-intent`, {
        headers: ServiceHelper.getHeaders(),
        params: ServiceHelper.getParams(params),
    });

  return response.data;
};

// Store the Payment Intent in your system
export const storePaymentIntent = async (params = {}) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/v1/stripe/store-payment-intent`, {
      headers: ServiceHelper.getHeaders(),
      params: ServiceHelper.getParams(params),
  });

  return response.data;
};

export const getPaymentIntent = async (params = {}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/stripe/get-payment-intent/${params.paymentIntentId}`, {
    headers: ServiceHelper.getHeaders(),
  });
  return response.data;
};