import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./vitaminB9Advice.scss";
import { TestCode, ReportSection } from "../../../enums/index.enum";
import ReportHelper from "../../../helpers/report.helper";
import { TestChart } from "../testChart/testChart.component";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const VitaminB9Advice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [riskLevel, setRiskLevel] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [bTests, setBTests] = useState();

  // progress
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  
  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.VITAMIN_B9_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.VITAMIN_B9_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.VITAMIN_B9_ADVICE));
  }, [report]);

  useEffect(() => {
    setRiskLevel(getRiskLevel());
  }, [tests, results, report]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])


  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [riskLevel, report, isFixed, isBetter, isWorse]);

  useEffect(() => {
    setBTests(getBTests());
  }, [report, tests]);

  const getBTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(report, ReportSection.VITAMIN_B9_ADVICE, tests);
  };

  const getRiskLevel = () => {
    if (!tests || !results || !report) return null;
    return ReportHelper.getTestRisk(TestCode.FOLATE, report, results, tests);
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !riskLevel) return null;

    if (isFixed) 
      return <>Your <span>vitamin B9</span> is now <span>optimal</span>, which decreases your long-term risk for heart disease and cognitive issues. 
      </>

    else if (isBetter)
      return <>Your <span>vitamin B9</span> is <span>increasing</span>, which decreases your long-term risk for heart disease and cognitive issues. 
      </>
    
    return (
      <>
        Your <span>vitamin B9</span> is <span>{ReportHelper.getRiskEval(TestCode.FOLATE, report, results, tests)}</span>, which increases your long-term risk for heart disease and cognitive issues. 
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report || !riskLevel) return null;

    if (isFixed) {
      return <>Vitamin B9 is critical to several key processes like DNA synthesis, red blood cell production, maintaining healthy homocysteine levels and much more. Nice work on fixing your vitamin B9 levels!
      </>

    }
    return (
      <>
        Healthy vitamin B9 levels are critical several key processes like DNA synthesis, red blood cell production, maintaining healthy homocysteine levels and much more.
        <br/><br/>Ensuring adequate intake of both this vitamin is crucial for overall health, especially since your body can’t produce them and relies on dietary sources or supplementation.
      </>
    )
  }

  return user && bTests &&(
    <div className="vitamin-b-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">{description}</Paragraph>
      <TestChart 
        report={report} 
        code={TestCode.FOLATE}
        tests={tests}
        results={results}
        reports={reports}
      />
      <AdviceSection 
        report={report}
        section={section}
        results={results}
        tests={tests}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />
    </div>
  );
};
