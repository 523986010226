const StepType = Object.freeze({
  CARD: 'card',
  CUSTOM: 'custom',
  STATIC: 'static',
  SINGLE_INPUT: 'single-input',
  MULTIPLE_INPUT: 'multiple-input',
  SINGLE_SELECT: 'single-select',
  MULTIPLE_SELECT: 'multiple-select',
  PRODUCT_SELECT: 'product-select',
  LOCATION: 'location',
  PAY: 'pay',
  IMAGE_UPLOAD: 'image-upload',
  CALENDLY: 'calendly',
  SCHEDULE: 'schedule',
  INVITE: 'invite',
  TEXTAREA: 'textarea',
  UPSELL: 'upsell',
  UPLOAD: 'upload',
  FACILITY: 'facility',
  KLARNA_PAY: "klarna-pay",
  GIFT_SELECT: "gift-select",
  GIFT_DETAILS: "gift-details",
  MULTIPLE_UPLAOD: 'multiple-upload',
  INSURANCE_UPLOAD: 'insurance-upload'
})

export default StepType;
