import { Tooltip, Typography } from 'antd'
import { useEffect, useState } from 'react'
import ResultHelper from '../../helpers/result.helper'
import './incompleteTestTooltip.scss';

const { Text } = Typography

export const IncompleteTestTooltip = ({ result, label }) => {
  const [data, setData] = useState()

  useEffect(() => {
    fetchData()
  }, [result])

  const fetchData = () => {
    if (!result) return
    setData(ResultHelper.getProgressData(result))
  }

  return label ? (
    <Tooltip
      title={data?.incompleteTests?.length ? (
        <div className="incomplete-tests">
          <Text className="incomplete-tests-label">
            {data.incompleteTests.length} Incomplete {data.incompleteTests?.length === 1 ? 'Test' : 'Tests'}:
          </Text>
          <ul>
            {data.incompleteTests?.map(test => (
              <li
                key={`${result._id}-${test._id}`}
                className="incomplete-test-name"
              >
                {test.name}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    >
      {label}
    </Tooltip>
  ) : null
}