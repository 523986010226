
import { useState, useContext, useEffect } from "react"
import { UserContext } from "../../../contexts/user.context"
import StepType from "../../flow/enums/stepType.enum"
import { Flow } from "../../flow/flow.component"

export const Step = {
  LOCATION: 'location',
  SCHEDULE: 'schedule',
}

export const ProAvaliablityFlow = () => {
  const { currentUser, setCounts } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [flow, initialUser])


  const fetchSkeleton = () => {
    if (!initialUser) return

    setSkeleton({

      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.SCHEDULE,
        title: `Where is your client located?`,
        description: `Our phlebotomist will meet your client here to draw their blood.`,
        field: {
          name: 'location'
        },
      },
      [Step.SCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addAppointment: true,
        title: `Here's our current availability.`,
        description: `If you need an appointment sooner, please email concierge@instalab.com and we'll do our best to accomodate.`
      },

    })
  }

  return <>

    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
    />
  </>
}