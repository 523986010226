import { useEffect, useState } from "react"
import { AdminLocationModal } from "../adminLocationModal/adminLocationModal.component";
import { Button, Table } from 'antd'
import { useParams } from "react-router-dom"
import { getPatient } from "../../services/patient.service"
import { EditOutlined } from '@ant-design/icons'
import "./heartHealthData.scss"
import { PageHeader } from "../pageHeader/pageHeader.component";
import ObjectType from "../../enums/objectType.enum"

const { Column } = Table

export const HeartHealthData = () => {
  const { patientId } = useParams()
  const [data, setData] = useState()
  const [fieldName, setFieldName] = useState(null);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [patient, setPatient] = useState()

  useEffect(() => {
    fetchHeartHealthData()
  }, [patientId])

  const getLocation = (location) => {
    if (!location) return '';
    const { streetAddress, streetAddress2, city, state, postalCode } = location;
    let address = streetAddress;
    if (streetAddress2) address += ` ${streetAddress2}`;
    return `${address}, ${city}, ${state} ${postalCode}`;
  };


  const fetchHeartHealthData = async () => {
    if (!patientId) return
    const fetchedPatient = await getPatient(patientId, {
      select: `email shippingLocation gender dob ethnicity familyHistory heartHealthConditions hasBloodPressureMonitor`
    })
    setPatient(fetchedPatient)
    setData([
      { key: 'Biological sex', value: fetchedPatient.gender },
      { key: 'Date of birth', value: fetchedPatient.dob },
      { key: 'Email', value: fetchedPatient.email },
      { 
        key: 'Shipping Address', 
        value: (
          <>
            {getLocation(fetchedPatient.shippingLocation)}
            <Button
              icon={<EditOutlined />}
              className="edit-btn"
              onClick={() => {
                setFieldName('shippingLocation');
                setIsLocationModalOpen(true);
              }}
            />
          </>
        )
      },
      { key: 'Ethnicity', value: fetchedPatient.ethnicity?.join(', ') },
      { key: 'Family History', value: fetchedPatient.familyHistory?.join(', ') },
      { key: 'Heart Health Conditions', value: fetchedPatient.heartHealthConditions?.join(', ') },
      { key: 'BP Monitor', value: fetchedPatient.hasBloodPressureMonitor ? 'Yes' : 'No' },
    ])
  }

  return patient && data?.length > 0 && (
    <div className="heart-health-data">
      <PageHeader title='Heart Health Data' />

      <AdminLocationModal
        objectId={patient._id}
        objectType={ObjectType.PATIENT}
        fieldName={fieldName}
        open={isLocationModalOpen}
        setOpen={setIsLocationModalOpen}
        onSuccess={setPatient}
      />

      <div className="heart-data-table-container">
        <Table
          loading={!data.length}
          className='primary-table'
          pagination={false}
          rowKey='key'
          dataSource={data}
          showHeader={false}
        >
          <Column dataIndex='key' width={200} />
          <Column dataIndex='value' render={(value) => value} />
        </Table>
      </div>
    </div>
  )
}