import React from "react";
import { Typography, Collapse, Tabs, Table } from "antd";
import "./adviceSection.scss";
import ReactPlayer from "react-player";
import { PlayCircleFilled } from "@ant-design/icons";
import ReportHelper from "../../../helpers/report.helper";
import ReactDOM from "react-dom";
import RecommendationData from "../../../data/recommendation.data"
import { renderToString } from 'react-dom/server'
import { useEffect, useState } from "react";
import { Product } from "../../../data/product.data";
import { ProductBox }from "../productBox/productBox.component"
import classNames from "classnames";
import copy from 'copy-to-clipboard';
import parse from 'html-react-parser';
import { MoreInfoModal } from "../moreInfoModal/moreInfoModal.component";
import { TestLink } from '../testLink/testLink.component';
import { ReferralBox } from "../referralBox/referralBox.component";

const { Paragraph } = Typography;
const { Panel } = Collapse;

export const AdviceSection = ({
  title = "The Game Plan",
  items,
  report,
  results,
  tests,
  section,
  setTestId, 
  setOpenModal,
  recs,
  reports,
  setRecs,
}) => {
  const [reminder, setReminder] = useState();
  const [activeKey, setActiveKey] = useState([]);

  useEffect(() => {
    setReminder(report?.prevReport && report?.prevReport.sections.find(({code}) => code === section.code) && report?.prevReport.sections.find(({code}) => code === section.code).show===true)
  }, [report, section])

  useEffect(() => {
    setRecs(getRecs());
  }, [report, section, items, reminder, setTestId, setOpenModal]);

  useEffect(() => {
    if (!recs) return; 

    const hash = window.location.hash.replace('#', '');
    const matchingKey = recs?.findIndex(item => dashify(item.title) === hash);
    if (matchingKey !== -1) {
      setActiveKey([`rec-${matchingKey}`]);
    }

    const handleHashChange = () => {
      const newHash = window.location.hash.replace('#', '');
      const newMatchingKey = recs?.findIndex(item => dashify(item.title) === newHash);
      if (newMatchingKey !== -1) {
        setActiveKey([`rec-${newMatchingKey}`]);
      } else {
        setActiveKey([]);
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, [recs]);

  const modalData = {
    ISOMETRIC_EXERCISES: {
      title: "Isometric Exercises",
      content: (
        <>Isometric exercise involves sustained static contraction of muscles with no change in the length of the involved muscle groups and without joint movement.  Examples include:
          <ul>
            <li>Handgrip <span>Squeeze a handgrip dynamometer or a similar device with moderate force, maintaining around 30% of your maximum grip strength, without moving your arm.</span></li>
            <li>Wall Squat<span>Stand with your back against a wall, feet shoulder-width apart, lower into a squat position until your thighs are parallel to the floor, and hold this position, aiming to reach 95% of your peak heart rate by the last rep.</span></li> 
            <li>Leg Extension <span>Sit on a leg extension machine or a chair with weights or bands on your ankles, extend your legs out in front of you, hold the position with your legs parallel to the floor, and aim to achieve 95% of your peak heart rate by the last rep.</span></li> 
          </ul>
  
          Here are some 20 isometrics exercises you can do without equipment: <br/><br/>
  
          <iframe width="450" height="300" src="https://www.youtube.com/embed/VH5F-dKoM98?si=VsLXB-0AeRXZXcdU&amp;start=35" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </>
      )
    },
    SODIUM: {
      title: 'Avoid High-salt Foods',
      content: <>
        <ol>
          <li>Processed meats<span>ham, bacon, sausage, deli meats</span></li>
          <li>Canned soups and broths</li>
          <li>Salty snacks<span>potato chips, pretzels, salted popcorn</span></li>
          <li>Fast food items<span>burgers, fries, hot dogs</span></li>
          <li>Condiments and sauces<span>ketchup, soy sauce, salad dressings</span></li>
          <li>Canned vegetables and beans</li>
          <li>Cheese and other dairy products</li>
          <li>Instant noodles and packaged pasta meals</li>
          <li>Pickled foods<span>pickles, sauerkraut, olives</span></li>
          <li>Frozen meals and pre-packaged dinners</li>
        </ol>
      </>
    },
    POTASSIUM: {
      title: 'Increase Potassium Intake',
      content: <>
        <ol>
          <li>Bananas</li>
          <li>Avocados</li>
          <li>Spinach</li>
          <li>Sweet potatoes</li>
          <li>Oranges</li>
          <li>Kiwis</li>
          <li>Tomatoes</li>
          <li>White beans</li>
          <li>Yogurt <span>(plain, low-fat)</span></li>
          <li>Edamame</li>
        </ol>
      </>
    },
  };

  const handleCollapseChange = (key) => {
    setActiveKey(key);
  };

  const previouslyRecommended = (code) => {
    if (!report) return null
    if (!report?.prevReport || !report?.prevReport?.healthScore) return true

    if (report?.prevReport.sections.find(({ recommendations }) => recommendations.includes(code))) return true
    return false
  }

  const getRecs = () => {
    if (report?.lockedAt && section.meta) return section.meta.recs

    const recommendationCodes = ReportHelper.getRecommendations(report, section.code)
    let params = ReportHelper.getRecParams(report, results, tests, setTestId, setOpenModal)

    params.section = section.code

    return recommendationCodes.map(code => {
      const rec = RecommendationData.find(recommendation => recommendation.code === code);

      if (!rec) { 
        return null;
      }

      let product;      
      if (rec.products) {
        if (typeof rec.products === 'function') {
          product = Product[rec.products(params)[0]];
        } else {
          product = Array.isArray(rec.products) && rec.products.length > 0 ? Product[rec.products[0]] : null;
        }
      }

      const title = rec.title(params)
      const description = rec.description(params)
      const referralType = rec.referralType && rec.referralType(params)
      const foodList = rec.foodList ? rec.foodList(params) : null
      
      const data  = {
        code: rec.code,
        title: title ? renderToString(title) : null,
        description: description ? renderToString(description) : null,
        referralType: referralType,
        foodList,
        amazon: rec.amazon,
        product: product,
        buyUrl: product?.buyUrl,
        buyText: product?.buyText,
        repeat: previouslyRecommended(rec.code),
        hideProduct: rec.hideProduct
      };

      return data
    });
  }

  const FoodTable = ({foods, restriction=[]}) => { 
    const filteredFoods = foods.filter(food => !food.exclude?.some(exclude => restriction.includes(exclude)))
  
    return (<Table 
      dataSource={filteredFoods} 
      columns={[{food: 'food', dataIndex:'food'}]}
      pagination={false}
      showHeader={false}
      className="foodTable"
      />) 
  }

  const FoodList = ({items, restriction}) => {
    return (
      <div style={{marginTop:10}}>
  
      <Tabs
        size={"small"}
        tabBarGutter={15}
        items={items.map((item, index) => {
          return {
            label: item.title,
            key: index,
            children: item.supplement ? <ProductBox style={{marginTop:10}} product={Product[item.supplement]}/> : <FoodTable restriction={restriction} foods={item.foods}/>,
          };
        })}
      />
  
      </div>
    )
  
  }

  const RecItem = ({item}) => {
    useEffect(() => {
      populateTestLinks()
      replaceMoreInfoLinks()
      replaceExerciseVideo()
    }, [item])

    const VideoContainer = ({ url }) => {
      return (
        <div className="video-container">
          <ReactPlayer
            url={url}
            className="video-player"
            light={(
              <picture>
                <source srcSet='/img/steven.webp' type="image/webp" />
                <source srcSet='/img/steven.jpg' type="image/jpeg" />
                <img src='/img/steven.jpg' alt="advice giver" style={{ height: '100%', width: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0 }} />
              </picture>
            )}
            controls={true}
            playing
            width="100%"
            height="100%"
            playIcon={
              <div className="play-icon-container">
                <PlayCircleFilled className="play-icon" />
              </div>
            }
          />
        </div>
      );
    };


    const replaceExerciseVideo = () => {
      const target = document.getElementById('exercise-video')
      if (!target) return
        ReactDOM.render(
          <VideoContainer url="https://vimeo.com/817934088" />,
          target
        )
    }

    const replaceMoreInfoLinks = () => {
      const targets = {
        'isometric-exercises-link': {
          linkText: 'isometric exercises',
          modalTitle: modalData.ISOMETRIC_EXERCISES.title,
          modalContent: modalData.ISOMETRIC_EXERCISES.content,
          food: false,
        },
        'high-sodium-link': {
          linkText: 'high-sodium foods',
          modalTitle: modalData.SODIUM.title,
          modalContent: modalData.SODIUM.content,
          food: true,
        },
        'potassium-rich-link': {
          linkText: 'potassium-rich foods',
          modalTitle: modalData.POTASSIUM.title,
          modalContent: modalData.POTASSIUM.content,
          food: true,
        },
      }
      for (const targetId of Object.keys(targets)) {
        const target = document.getElementById(targetId)
        if (target) {
          const {
            linkText,
            modalTitle,
            modalContent,
            food,
          } = targets[targetId]
          ReactDOM.render(
            <MoreInfoModal
              linkText={linkText}
              modalTitle={modalTitle}
              modalContent={modalContent}
              food={food}
            />,
            target
          )
        }
      }
    }

    const populateTestLinks = () => {
      const testLinks = document.getElementsByClassName('view-test')
      for (const testLink of testLinks) {
        const testCode = testLink.id.split('-')[2]
        const test = tests.find(({ code }) => code === Number(testCode))
        ReactDOM.render(
          <TestLink
            test={test}
            content={testLink.getAttribute('text')}
            report={report}
            tests={tests}
            results={results}
            reports={reports}          
          />,
          testLink
        )
      }
    }

    return (
        <div className="advice-item-description">{parse(item.description)}
        {item.referralType && <ReferralBox referralType={item.referralType} patient={report.patient}/>}

        {item.hideProduct ? <></>  : <ProductBox style={{ marginTop: 20}} product={item.product}></ProductBox>}
        {item.foodList ? <FoodList items={item.foodList.items} restriction={item.foodList.restriction} /> : <></>}
        </div>    
    )
  }

  const dashify = (text) => {
    // Recursive function to extract text content from React elements
    const extractText = (element) => {
      if (typeof element === 'string') {
        return element;
      } else if (Array.isArray(element)) {
        return element.map(extractText).join('');
      } else if (React.isValidElement(element)) {
        return extractText(element.props.children);
      } else if (element && typeof element === 'object') {
        return extractText(element.children);
      } else {
        return '';
      }
    };
  
    // Extract text content
    text = extractText(text);
  
    // Ensure text is a string before replacing spaces
    if (typeof text !== 'string') {
      text = String(text);
    }
  
    return text.replace(/\s+/g, '-').toLowerCase();
  };

  const copyToClipboard = (id) => {
    copy(`https://www.instalab.com/patient/report#${id}`);
  };

  return (
    recs && recs.length > 0 && (
      <div className="advice-section">
        <Paragraph className="advice-title">{title}</Paragraph>
        <div className={classNames("advice-list", recs.length === 1 && "advice-list-one")} style={{ paddingLeft: 0, marginLeft: -3 }}>
          <Collapse
            bordered={false}
            expandIconPosition="end"
            className="advice-item-collapse"
            activeKey={activeKey}
            onChange={key => {
              handleCollapseChange(key)
            }}
            accordion={true}
          >
            {recs.map((item, index) => (
              <Panel
                id={dashify(item.title)}
                key={`rec-${index}`}
                header={
                  recs.length > 1 ? (
                    <div className="advice-item-header" onClick={() => copyToClipboard(dashify(parse(item.title)))}>
                      <span className="index">{index + 1}.</span>
                      <span className="item-title">{parse(item.title)}</span>
                    </div>
                  ) : (
                    <>{parse(item.title)}</>
                  )
                }
                className="advice-item-title"
              >
                <RecItem item={item} index={index} />
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    )
  );

};
