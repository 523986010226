import { Typography, Button, Row, Col }  from 'antd'
import Breakpoint from '../../enums/breakpoint.enum.js'
import LatestPosts from '../latestPosts/latestPosts.component.js'
import useWidth from '../../hooks/useWidth.hook.js'

const { Text, Paragraph } = Typography

export const CleerlyConfirm = () => {
  const width = useWidth()

  const instructions = [{
    sections: [
      {
      title: 'Scheduling',
      description: <>An Instalab physician will review your details as required by law. Once approved, our Concierge team will email you to help schedule your CCTA at your preferred imaging center. If presecribed, we’ll also arrange for a beta blocker to be shipped to you.</>
    }, 
  ]}, 
  {
    sections: [{
      title: 'Complete Your Scan',
      description: <>Visit the imaging center for your CCTA. The appointment typically takes less than an hour. If prescribed, take the beta blocker 12 hours and 1 hour before your scan.</>
    }]
  }, 
  {
    sections: [{
      title: 'Receive Results & Report',
      description: <>Once Cleerly analyzes the images, we’ll email your results along with a detailed report. This usually happens 1-2 business days after your scan.</>
    }]
  }]

  return (
    <div className="prescription-approval">

      <div style={{fontWeight:600, marginBottom:10}}>Next steps for CT Coronary Angiogram (CCTA) with Cleerly:</div> 
      {instructions?.length > 0 && (
        <div className="instruction-list">
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}


      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <LatestPosts />
    </div>
  )
}

