import './confirmAppointment.scss'
import AppointmentHelper from '../../../../helpers/appointment.helper'

const ConfirmAppointment = ({ flow }) => {
  return flow?.appointment && (
    <div className="confirm-appointment">
      <div className="detail-list">
        <div className="detail-row">
          <div className="detail-label">
            Location
          </div>
          <div className="detail-value">
            {AppointmentHelper.getLocation(flow.appointment)}
          </div>
        </div>
        
        <div className="detail-row">
          <div className="detail-label">
            Time
          </div>
          <div className="detail-value">
            {AppointmentHelper.getStartTime(flow.appointment).format("MMMM D")} at {AppointmentHelper.getStartTime(flow.appointment).format("h:mm a")}
          </div>
        </div>

        <div className="detail-row">
          <div className="detail-label">
            Phlebotomist
          </div>
          <div className="detail-value">
            {AppointmentHelper.getPhlebotomistName(flow.appointment)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmAppointment