import { Typography } from "antd"
import './hypotensionAdvice.scss'
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import TestCode from "../../../enums/testCode.enum";
import ReportHelper from "../../../helpers/report.helper"
import RiskLevel from "../../../enums/riskLevel.enum";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography
const bpCodes = [TestCode.BP_S, TestCode.BP_D]

export const HypotensionAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [abnormalTests, setAbnormalTests] = useState()
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setAbnormalTests(getAbnormalTests())
  }, [tests, results])

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])

  const getAbnormalTests = () => {
    if (!tests || !results) return null
    return tests.filter(({ code }) => bpCodes.includes(code) && isRisky(code))
  }

  const isRisky = (code) => {
    const riskLevel = ReportHelper.getTestRisk(code, report, results, tests)
    return riskLevel && riskLevel !== RiskLevel.OPTIMAL
  }

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
    return <>Your <span>blood pressure</span> is <span>abnormally low</span>.</>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)
    return <>Although your blood pressure falls outside of normal range for the average individual, you may just have genetically low blood pressure. We recommend that you discuss this reading with your primary care provider if you are feeling fatigued, dizzy, or light headed.</>
  }

  return abnormalTests && (
    <div className="hypotension-advice">
      <Paragraph className="report-template-title">
        {title}
      </Paragraph>
      <Paragraph className="report-template-description"> 
        {description}
      </Paragraph>

      <TestView 
        report={report} 
        tests={abnormalTests}
        results={results}
        reports={reports}
        user={user}
      />
    </div>
  )
}