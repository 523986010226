import './confirmAppointment.scss'
import AppointmentHelper from '../../helpers/appointment.helper'
import InviteStatus from '../../enums/inviteStatus.enum'
import { useEffect, useState } from 'react'
import { Typography, Button, Row, Col }  from 'antd'
import Breakpoint from '../../enums/breakpoint.enum.js'
import useWidth from '../../hooks/useWidth.hook.js'
import { CalendarOutlined} from '@ant-design/icons'
const { Text, Paragraph } = Typography



const ConfirmAppointment = ({ flow }) => {
  const [inviteCount, setInviteCount] = useState()
  const width = useWidth()

  useEffect(() => {
    fetchInviteCount()
  }, [flow])

  const fetchInviteCount = () => {
    if (!flow?.appointment) return
    setInviteCount(flow.appointment.patients.length - 1 + flow.appointment.invites.filter(({ status }) => status === InviteStatus.PENDING).length)
  }

  const instructions = [{
    sections: [
      {
      title: 'Schedule Blood Draw',
      description: <>Use the below link to schedule your blood draw at {flow?.facility?.name}.<p><Button type="default" icon={<CalendarOutlined/>} onClick={() => window.open(flow?.facility?.website, '_blank')}>Schedule Link</Button></p></>
    }, 
    ]
  }, {
    sections: [{
      title: 'Fill Out Intake',
      description: <>Once you’ve scheduled your appointment, hit “Continue” below. We just need a little bit more info to provide accurate analysis of your results.</>
    }]
  }, {
    sections: [{
      title: 'Print Lab Order',
      description: <>You’ll get your lab order via email soon. To ensure a smooth, efficient experience at your appointment, please print out and bring with you.</>
    }]
  }]


  return flow?.appointment ? (
    <div className="confirm-appointment">
      <div className="detail-list">
        <div className="detail-row">
          <div className="detail-label">
            Location
          </div>
          <div className="detail-value">
            {AppointmentHelper.getLocation(flow.appointment)}
          </div>
        </div>
        
        <div className="detail-row">
          <div className="detail-label">
            Time
          </div>
          <div className="detail-value">
            {AppointmentHelper.getStartTime(flow.appointment).format("MMMM D")} at {AppointmentHelper.getStartTime(flow.appointment).format("h:mm a")}
          </div>
        </div>

        {flow?.appointment?.phlebotomist && (
          <div className="detail-row">
            <div className="detail-label">
              Phlebotomist
            </div>
            <div className="detail-value">
              {AppointmentHelper.getPhlebotomistName(flow.appointment)}
            </div>
          </div>
        )}
        {inviteCount > 0 && 
        <div className="detail-row">
          <div className="detail-label">
            Invites
          </div>
          <div className="detail-value">
            {inviteCount} {inviteCount === 1 ? 'invite' : 'invites'}
          </div>
        </div>
        }

      </div>
    </div>
  ) : (
    <div className="confirm-appointment">
    <div className="prescription-approval detail-list">
    {instructions.map(({ sections }, instructionIndex) => (
      <div 
        key={`instruction-${instructionIndex}`} 
        className="instruction-item"
      >
        <Row>
          <Col
            style={{
              width: width > Breakpoint.SM ? 80 : '100%',
              marginBottom: width > Breakpoint.SM ? 0 : 10
            }}
          >
            <Text className="instruction-index">
              Step {instructionIndex+1}
            </Text>
          </Col>

          <Col
            style={{
              width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
            }}
          >
            {sections.map(({ title, description }, sectionIndex) => (
              <div 
                key={`section-${instructionIndex}-${sectionIndex}`} 
                className="section-item"
              >
                <Text className="section-title">
                  {title}
                </Text>
                <Paragraph className="section-description">
                  {description}
                </Paragraph>
              </div>
            ))}
            </Col>
        </Row>
      </div>
    ))}
  </div>
  </div>
  )
}

export default ConfirmAppointment