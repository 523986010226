import React from "react";
import "./creatineAdvice.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { LowercaseTest, ReportSection, Condition } from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
const converter = require("number-to-words");

const { Paragraph } = Typography;

export const CreatineAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [creatineTests, setCreatineTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [isFixed, setIsFixed] = useState();

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.CREATINE_IMPACT));
  }, [report]);

  useEffect(() => {
    setCreatineTests(getCreatineTests());
  }, [report, tests, isFixed]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, creatineTests, results, tests, isFixed]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])

  const getCreatineTests = () => {
    if (!report || !tests) return null;

    if (isFixed) { return ReportHelper.getSectionFixedTests(report, ReportSection.CREATINE_IMPACT, tests); }
    
    return ReportHelper.getSectionTests(report, ReportSection.CREATINE_IMPACT, tests);
  }; 

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !creatineTests) return null;
    
    if (isFixed) {
      return <>Your <span>creatinine</span> is back to <span>optimal</span>.</>
    }

    const hasRhabdomylosis = report.result?.conditions.includes(Condition.RHABDOMYOLYSIS);
  
    let content = (
      <>
        {creatineTests?.length === 1
            ? <>Your <span>{LowercaseTest[creatineTests[0].code]}</span> is <span>high</span> </>
            : <>These <span>{converter.toWords(creatineTests?.length)}</span> biomarkers are <span>suboptimal</span></>
        }
        { hasRhabdomylosis 
          ? <>{" "}possibly due to creatine supplementation.</>
          : <>{" "}likely due to creatine supplementation.</>}
        
      </>
    );

    return content;
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report) return null;
    const { result } = report

    if (isFixed) {
      return <>As discussed before, creatine supplementation can increase creatinine levels without
      affecting your health. That said, with your creatinine levels in the usual healthy range, it's more reassuring your kidneys are functioning well.</>
    }

    const hasRhabdomylosis = result?.conditions.includes(Condition.RHABDOMYOLYSIS);

    let content = (
      <>
        { hasRhabdomylosis ? 
        <>Creatine supplementation can increase creatinine levels without
        affecting your health. However it's unclear this is the case due to other suboptimal liver and kidney results.<br />
        <br />
        To confirm this, stop taking creatine 3 weeks prior to your next
        blood draw to avoid a false positive. </>       
        :
        <>Creatine supplementation can increase creatinine levels without
        affecting your health. Your other kidney markers are healthy so no
        need to worry. <br />
        <br />
        If you'd like to confirm this, stop taking creatine 3 weeks prior to your next
        blood draw to avoid a false positive.</>
      }
      </>
    );

    return content;
  };

  return (
    creatineTests && (
      <div className="creatine-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        <TestView 
          report={report} 
          tests={creatineTests}
          results={results}
          reports={reports}
          user={user}
        />
      </div>
    )
  );
};
