import { Typography } from "antd";
import { MoreInfoModal } from "../moreInfoModal/moreInfoModal.component";
import "./noteSection.scss";

const { Paragraph } = Typography;

export const NoteSection = ({title, summary, content}) => {

  return (

   
      <div className="note-section">
        <Paragraph className="note-title">{title}</Paragraph>
        <Paragraph className="note-description">

        <MoreInfoModal
          linkText={summary}
          modalTitle={title}
          modalContent={content}
        />
      
      
        </Paragraph>
        
      </div>
   
  )

};
