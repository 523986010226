import TestCode from "./testCode.enum";

const LowercaseTest = Object.freeze({
  [TestCode.APO_E]: 'APOE genotype',
  [TestCode.APO_B]: 'apo B',
  [TestCode.LIPOPROTEIN_A]: 'Lp(a)',
  [TestCode.TC]: 'total cholesterol',
  [TestCode.DIRECT_LDL]: 'LDL cholesterol',
  [TestCode.HDL]: 'HDL cholesterol',
  [TestCode.VLDL]: 'VLDL cholesterol',
  [TestCode.TG]: 'triglycerides',
  [TestCode.SD_LDL]: 'sd-LDL cholesterol',
  [TestCode.INSULIN]: 'fasting insulin',
  [TestCode.GLUCOSE]: 'fasting glucose',
  [TestCode.HBA1C]: 'hbA1c',
  [TestCode.HOMAIR]: 'HOMA-IR',
  [TestCode.TG_HDL]: 'TG/HDL cholesterol',
  [TestCode.TSH]: 'TSH',
  [TestCode.FREET4]: 'free T4',
  [TestCode.LP_PLA2]: 'Lp-PLA2 activity',
  [TestCode.HS_CRP]: 'hs-CRP',
  [TestCode.HOMOCYSTEINE]: 'homocysteine',
  [TestCode.URIC_ACID]: 'uric acid',
  [TestCode.VITAMIN_D]: 'vitamin D',
  [TestCode.CALCIUM]: 'calcium',
  [TestCode.FERRITIN]: 'ferritin',
  [TestCode.VITAMIN_B12]: 'vitamin B12',
  [TestCode.TOTAL_TESTOSTERONE]: 'total testosterone',
  [TestCode.FREE_TESTOSTERONE]: 'free testosterone',
  [TestCode.SHBG]: 'SHBG',
  [TestCode.LH]: 'LH',
  [TestCode.FSH]: 'FSH',
  [TestCode.ALBUMIN]: 'albumin',
  [TestCode.ALP]: 'ALP',
  [TestCode.ALT]: 'ALT',
  [TestCode.AST]: 'AST',
  [TestCode.TOTAL_BILIRUBIN]: 'total bilirubin',
  [TestCode.TOTAL_PROTEIN]: 'total protein',
  [TestCode.BUN]: 'BUN',
  [TestCode.CREATININE]: 'creatinine',
  [TestCode.CYSTATIN_C]: 'cystatin C',
  [TestCode.EGFR]: 'eGFR',
  [TestCode.BUN_CREATINE]: 'BUN/creatinine',
  [TestCode.CHLORIDE]: 'chloride',
  [TestCode.SODIUM]: 'sodium',
  [TestCode.POTASSIUM]: 'potassium',
  [TestCode.BICARBONATE]: 'bicarbonate',
  [TestCode.RBC]: 'RBC count',
  [TestCode.HEMOGLOBIN]: 'hemoglobin',
  [TestCode.HEMATOCRIT]: 'hematocrit',
  [TestCode.MCV]: 'MCV',
  [TestCode.MCH]: 'MCH',
  [TestCode.MCHC]: 'MCHC',
  [TestCode.RDW]: 'RDW',
  [TestCode.WBC]: 'WBC count',
  [TestCode.NEUTROPHILS]: 'neutrophil count',
  [TestCode.MONOCYTES]: 'monocyte count',
  [TestCode.LYMPHOCYTES]: 'lymphocyte count',
  [TestCode.EOSINOPHILS]: 'eosinophil count',
  [TestCode.BASOPHILS]: 'basophil count',
  [TestCode.PLATELET]: 'platelet count',
  [TestCode.BMI]: 'BMI',
  [TestCode.BP_S]: 'systolic blood pressure',
  [TestCode.BP_D]: 'diastolic blood pressure',
  [TestCode.PULSE]: 'resting heart rate',
  [TestCode.WAIST]: 'waist circumference',
  [TestCode.ESTRADIOL]: 'estradiol',
  [TestCode.DIRECT_BILIRUBIN]: 'direct bilirubin',
  [TestCode.DHEA_S]: 'DHEA-S',
  [TestCode.FOLATE]: 'vitamin B9',
  [TestCode.IRON_SATURATION]: 'transferrin saturation',
  [TestCode.GGT]: 'GGT',
  [TestCode.CREATINE_KINASE]: 'creatinine kinase',
  [TestCode.CORTISOL]: 'cortisol',
  [TestCode.NMR_LP_IR_SCORE]: 'LP-IR score',
  [TestCode.OMEGA_3_INDEX]: 'omega-3 index',
  [TestCode.URINE_ALBUMIN]: 'albumin (urine)',
  [TestCode.URINALYSIS_PROTEIN]: 'protein (urine)',
  [TestCode.DEXA_VAT_MASS]: 'VAT mass',
  [TestCode.DEXA_ALMI]: 'ALMI',
  [TestCode.GRIP]: 'grip strength',
  [TestCode.GLOBULIN]: 'total globulin',
  [TestCode.NMR_SMALL_LDL_P]: 'small LDL particle',
  [TestCode.NMR_LDL_P]: 'LDL-P',
})

export default LowercaseTest;