import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./vitaminDAdvice.scss";
import { Condition, TestCode, MedicalCondition, RiskLevel } from "../../../enums/index.enum"
import { TestChart } from "../testChart/testChart.component";
import { Reference } from "../reference/reference.component";
import ReportHelper from "../../../helpers/report.helper";
import ReportSection from "../../../enums/reportSection.enum";
import Supplement from "../../../enums/supplement.enum";
import QuestionTemplateCode from "../../../enums/questionTemplateCode.enum";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const medicalConditions = [
  MedicalCondition.HEART_DISEASE,
  MedicalCondition.STROKE,
  MedicalCondition.BLOCKED_ARTERY,
  MedicalCondition.HEART_ATTACK,
];

const { Paragraph } = Typography;

export const VitaminDAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [riskLevel, setRiskLevel] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [recParams, setRecParams] = useState();
  const [hasSIBO, setHasSIBO] = useState();
  const [isPregnant, setIsPregnant] = useState();
  const [isTakingVitaminDSupplement, setIsTakingVitaminDSupplement] = useState();

  // progress
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  const [isStatic, setIsStatic] = useState();
  
  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])


  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.VITAMIN_D_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.VITAMIN_D_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.VITAMIN_D_ADVICE));
    setIsStatic(ReportHelper.isStatic(report, ReportSection.VITAMIN_D_ADVICE));
    setHasSIBO(getHasSIBO());
    setIsPregnant(getIsPregnant());
    setIsTakingVitaminDSupplement(getIsTakingVitaminDSupplement());
  }, [report]);

  useEffect(() => {
    setRiskLevel(getRiskLevel());
  }, [tests, results, report]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [user, report, riskLevel, isFixed, isBetter, isWorse, isStatic, isTakingVitaminDSupplement, isPregnant]);

  useEffect(() => {
    setRecParams(getRecParams());
  }, [report, riskLevel, isTakingVitaminDSupplement, isFixed, isBetter, isWorse, isStatic]);

  const getFamilyHistory = () => {
    return user.familyHistory
      ?.filter((condition) => medicalConditions.includes(condition))
      .map((condition) => condition.toLowerCase());
  };


  const getRiskLevel = () => {
    if (!tests || !results || !report) return null;
    return ReportHelper.getTestRisk(TestCode.VITAMIN_D, report, results, tests);
  };

  const getHasSIBO = () => {
    if (!report) return null;
    const { result } = report 
    return result?.conditions?.includes(Condition.SIBO)
  }

  const getIsPregnant = () => {
    if (!report) return null;
    const { result } = report 
    return result?.conditions?.includes(Condition.PREGNANT)
  }

  const getIsTakingVitaminDSupplement = () => {
    if (!report) return;
    const supplements = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_SUPPLEMENTS);
    return supplements?.includes(Supplement.VITAMIN_D3) || supplements?.includes(Supplement.CALCIFEDIOL)
  }

  const getConditions = () => {
    const { currentMedicalConditions, allMedicalConditions } = user;
    let conditions = [];
    if (currentMedicalConditions?.length) {
      conditions = currentMedicalConditions;
    }
    if (allMedicalConditions?.length) {
      conditions = [...conditions, ...allMedicalConditions];
    }
    return conditions
      ?.filter((condition) => medicalConditions.includes(condition))
      .map((condition) => condition.toLowerCase());
  };

  const getRecParams = () => {
    if (!report) return null;
    const supplements = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_SUPPLEMENTS);
    const vitaminD = ReportHelper.getTestValue(TestCode.VITAMIN_D, report, results, tests);
    const { result } = report
    let params = {
      isTakingVitaminD3: supplements?.includes(Supplement.VITAMIN_D3),
      isTakingCalcifediol: supplements?.includes(Supplement.CALCIFEDIOL),
      optimalVitaminD: riskLevel === RiskLevel.OPTIMAL,
      nearOptimalVitaminD: vitaminD >=30,
      vitaminDIncreasing: isBetter || isFixed,
      highVitaminD: result?.conditions?.includes(Condition.HIGH_VITAMIN_D)
    }
    return params
  };


  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!user || !riskLevel || !report) return null;


    const { result } = report 
    const riskQualifier = ReportHelper.getRiskEval(TestCode.VITAMIN_D, report, results, tests)
    const hasHighVitaminD = result?.conditions?.includes(Condition.HIGH_VITAMIN_D)

    if (isFixed) {
      return (
        <>
          Your <span>vitamin D</span> is now <span>optimal</span>, which decreases your risk of diabetes, heart disease and cognitive decline.
        </>
      );
    } else if (isBetter) {
      return (
        <>
          Your <span>vitamin D</span> is <span>improving</span>, which decreases your risk of diabetes, heart disease and cognitive decline.
        </>
      );
    } else if (isWorse) {

      if (hasHighVitaminD) {
        return (
          <>
            Your <span>vitamin D</span> is{" "}
            <span>{riskQualifier}</span>, likely due to taking a vitamin
            D supplement.
          </>
        );
  
      }

      return (
        <>
          Your <span>vitamin D</span> is <span>decreasing</span>{isTakingVitaminDSupplement ? <>, despite taking a vitamin D supplement.</> : <>, which increases your risk of diabetes, heart disease and cognitive decline.</>}
        </>
      );
    }
    else if (isStatic) {
      return (
        <>
          Your <span>vitamin D</span> is still <span>{ReportHelper.getRiskEval(TestCode.VITAMIN_D, report, results, tests)}</span>{isTakingVitaminDSupplement && `, despite taking a vitamin
          D supplement`}.
        </>
      );
    }


    if (hasHighVitaminD) {
      return (
        <>
          Your <span>vitamin D</span> is{" "}
          <span>{riskQualifier}</span>, likely due to taking a vitamin
          D supplement.
        </>
      );
    }

    else if (isTakingVitaminDSupplement) {
      return (
        <>
          Your <span>vitamin D</span> is{" "}
          <span>{riskQualifier}</span>, despite taking a vitamin
          D supplement.
        </>
      );
    }

    return (
      <>
        Your <span>vitamin D</span> is{" "}
        <span>{riskQualifier}</span>, which increases your risk
        of diabetes, heart disease and cognitive decline.
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!user || !riskLevel || !report) return null;
    const { result } = report
    const hasHighVitaminD = result?.conditions?.includes(Condition.HIGH_VITAMIN_D)


    let followupContent = (
      <>
        Vitamin D is a hormone that plays a critical role in maintaining strong
        bones, supporting immune function and{" "}
        <Reference
          content="reducing the risk of chronic diseases"
          url="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3972416/"
        />.{hasSIBO && <> SIBO can cause low vitamin D levels.</>}
      </>
    );
    if (hasHighVitaminD) {

      return (
        <>
          {followupContent}
          {" "}That said, excessively high levels can be detrimental. It can lead to hypercalcemia (too much calcium) and cause cause side effects like weakeness, nausea, vomiting, and kidney issues. It's recommended to stay within optimal range.

          {!isTakingVitaminDSupplement && <><br/><br/>Usually high vitamin D levels are a result of too much supplementation but if you're not do this, it's recommended to see your physician to understand why your levels are this high.</>}
        </>
      )

    }
    if (isFixed) {
      return (
        <>
          {followupContent}
          <br />
          <br />
          Great job reaching an optimal level since your last blood draw! Make
          sure you stick to a vitamin D supplement regimen, so you can stay at
          an optimal level.
        </>
      );
    }
    if (isBetter) {

      return (
        <>
          {followupContent}

          {isTakingVitaminDSupplement 
            ? <><br/><br/>Good job increasing your vitamin D level! Make sure you stick to your vitamin D supplement regimen, so you can hit an optimal level by your next blood draw.</>
            : <><br/><br/>Good job increasing your vitamin D! 
              {riskLevel === RiskLevel.MODERATE && <> You're getting close to optimal levels.</>} 
              {" "}Starting a supplement will likely help you reach it by your next blood draw.</>
          }
          
        </>
      );
    }
    if (isWorse) {
      return (
        <>
          {followupContent} 
          <br />
          <br />
          Make sure you stick to a vitamin D supplement regimen to reach optimal levels by your next blood draw.
        </>
      );
    }

    if (isTakingVitaminDSupplement) {

      const vitaminD = ReportHelper.getTestValue(TestCode.VITAMIN_D, report, results, tests);

      return (
        <>
          {followupContent}
          <br />
          <br />
          {vitaminD>=35  ?
            `The good news is that you are already close to an optimal vitamin D level so stick with your supplement regimen and you'll get there soon.`: 
            `Ensure you consistently follow your vitamin D supplement regimen to reach optimal levels by your next blood draw.`}
        </>
      );
    }

    let triggers = [];

    const conditions = getConditions();
    if (conditions?.length) {
      triggers.push(`prior ${conditions.slice(0, 2).join(" and ")}`);
    }

    if (!ReportHelper.hasSection(report,ReportSection.HOMOCYSTEINE_ADVICE)) {
      const familyHistory = getFamilyHistory();
      if (familyHistory?.length) {
        triggers.push(
          `family history of ${familyHistory.slice(0, 2).join(" and ")}`
        );
      }
    }

    let prefix = (
      <>
        Vitamin D is a hormone that plays a critical role in maintaining strong
        bones, supporting immune function, and{" "}
        <Reference
          content="reducing the risk of many chronic diseases"
          url="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3972416/"
        />. {isPregnant && <> Additionally, during pregnancy, low levels can also increase your risk of complications like gestational diabetes.</>} {hasSIBO && <> SIBO can cause low vitamin D levels.</>}
      </>
    );

    if (triggers?.length) {
      return (
        <>
          {prefix}<br/>
          <br /> Due to your {triggers.join(" and ")}, it’s recommended you
          start taking action now. Luckily,
          {riskLevel === RiskLevel.MODERATE
            ? ` this is an easy fix and you're not far from optimal`
            : ` you can easily fix your vitamin D level by taking a supplement and spending more time in the sun`}
          .
        </>
      );
    }

    const vitaminD = ReportHelper.getTestValue(TestCode.VITAMIN_D, report, results, tests);

    return (
      <>
        {prefix}<br />
        <br /> 
          {vitaminD>=35 ? `The good news is that you're very close to optimal levels and you can get there by taking a vitamin D supplement or spending more time in the sun. `
        : `Luckily, you can easily fix your vitamin D within weeks by
        taking a vitamin D supplement and spending more time in the sun.`}
      </>
    );
  };

  return user && report && recParams && (
    <div className="vitamin-d-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">
        {description}
      </Paragraph>

      <TestChart 
        code={TestCode.VITAMIN_D} 
        report={report} 
        tests={tests}
        results={results}
        reports={reports}
      />

      <AdviceSection 
        report={report} 
        results={results}
        tests={tests}
        section={section}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />
    </div>
  );
};
