
import { useEffect, useState } from "react";
import { EventCode, FlowType } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { ReactComponent as TacSVG} from '../../assets/svg/heart.svg'; 
import { ReactComponent as CardSVG} from '../../assets/svg/card.svg'; 
import { ReactComponent as DoctorSVG} from '../../assets/svg/doctor-2.svg'; 
import { ReactComponent as NoteSVG} from '../../assets/svg/note-text.svg'; 
import ProductDetailSection from "../../enums/productDetailSection.enum";
import './cac.scss'
import { getProductTypeByCode } from "../../services/productType.service";
import BlogId from "../../enums/blogId.enum";

export const Cac= () => {
  const [productType, setProductType] = useState()
  const [sections, setSections] = useState(null)

  // init + get prices
  useEffect(() => {
    fetchProductType()
  }, []);

  const fetchProductType = async () => {
    setProductType(await getProductTypeByCode(ProductTypeCode.CTCALCIUM))
  };
  
  // set product attributes
  useEffect(() => {
    if (!productType) return

    const faqs = [
      {
        question: "How do I get this test done?",
        answer: "Instalab will handle the physician order and schedule your test at a nearby imaging center. Prices vary by location, and you’ll receive recommendations for convenient options. The test itself takes just 10 minutes, and you’ll receive a detailed results report from Instalab."
      },
      {
        question: "What is a coronary calcium scan?",
        answer: "A coronary calcium scan, also known as a cardiac calcium score test, is a specialized CT scan that detects and measures the amount of calcium in the walls of the coronary arteries. This test helps to assess the risk of coronary artery disease by identifying calcified plaque deposits, which can indicate the presence of atherosclerosis, even before symptoms develop."
      },
      {
        question: "How much does a coronary calcium scan cost?",
        answer: "The price varies by location, usually between $100-150. Instalab will instantly recommend nearby imaging centers to you with various pricing options. You will pay the facility directly at the time of service."
      },
      {
        question: "How is a coronary calcium scan performed?",
        answer: "During the scan, you will lie on a table that slides into the CT scanner. Electrodes will be attached to your chest to monitor your heart rate. The scanner takes detailed images of your heart while you hold your breath for a few seconds at a time. The procedure is non-invasive, quick, and typically takes about 10 minutes to complete."
      },
      {
        question: "What do the results of a coronary calcium scan mean?",
        answer: "The results of a coronary calcium scan are presented as a calcium score, which reflects the amount of calcified plaque in the coronary arteries. A score of zero indicates no detectable plaque, suggesting a low risk of coronary artery disease. Higher scores indicate an increased risk, with specific ranges corresponding to mild, moderate, or severe plaque buildup."
      },
      {
        question: "Who should consider having a coronary calcium scan?",
        answer: "Individuals at moderate risk of coronary artery disease, especially those with risk factors such as family history, high cholesterol, high blood pressure, diabetes, or smoking, will benefit from a coronary calcium scan. It helps guide decisions about preventive measures and treatments."
      },
      {
        question: "Is there any preparation required for a coronary calcium scan?",
        answer: "Generally, no special preparation is needed for a coronary calcium scan. However, you shuld avoid caffeine and smoking for a few hours before the test, as they can affect your heart rate. It is also important to inform the technician of any medications you are taking."
      },
      {
        question: "What are the benefits of a coronary calcium scan?",
        answer: "The scan is a quick and non-invasive way to evaluate your risk of heart disease. It can detect calcified plaque before symptoms occur, enabling early intervention and prevention strategies. It provides valuable information for tailoring personalized treatment plans and lifestyle modifications."
      },
      {
        question: "How does a coronary calcium scan differ from other heart tests?",
        answer: "Unlike other tests such as stress tests or angiograms, a coronary calcium scan specifically measures calcified plaque in the coronary arteries. It does not assess soft plaque or detect blockages directly but provides a useful and relatively cheap risk assessment tool for asymptomatic individuals."
      },
      {
        question: "Are there any risks associated with a coronary calcium scan?",
        answer: "The scan involves a small amount of radiation exposure, similar to other CT scans. However, the risk is very low compared to the potential benefits of early detection of heart disease."
      },
      {
        question: "Will my insurance cover a coronary calcium scan?",
        answer: "For price transparency reasons, Instalab does not accept insurance. However, if you have an HSA/FSA, you may be eligible for reimbursement. We recommend using your HSA/FSA card when purchasing anything at Instalab. If you don’t have one, we can generate an itemized receipt for you to submit."
      },
      {
        question: "Can I discuss my coronary calcium scan results with a healthcare professional?",
        answer: "Yes, you can review your scan results with one of Instalab's physicians who are experts in interpreting results. They can help answer any of your questions and recommend any necessary follow-up tests or treatments based on your risk level and overall health."
      },
    ];

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: productType?.title,
          oneliner: "A quick, non-invasive test to measure calcified plaque in your arteries and estimate your risk of future cardiac events.",
          note: <><b>Service Fee</b>: Instalab charges a ${productType?.cost} service fee for the physician order, scheduling, and detailed results report. This is separate from the scan cost paid to the imaging center. <u>Members pay no service fee</u>.</>,
          points: [
            <><DoctorSVG className="point-icon"/>Required physician order written for scan</>,
            <><TacSVG className="point-icon"/>Scan booked and done at nearby imaging center</>,
            <><NoteSVG className='point-icon' />Results delivered with personalizd report</>,
            <><CardSVG className="point-icon" />HSA and FSA cards accepted</>,
          ],
          cta: {
            text: "Get Started Now",
            url:`/flow/${FlowType.CAC}`
          },
          photos: [
            {
              original: "/img/cac-scanner.webp",
              thumbnail:  "/img/cac-scanner.webp"
            },
            {
              original: "/img/cac-chart.webp", 
              thumbnail: "/img/cac-chart.webp"
            },
            {
                original: "/img/hsa-fsa-eligible-hero.webp",
                thumbnail: "/img/hsa-fsa-eligible-hero.webp"
            }
          ],
          faqs: faqs
        }
      },
      { type: ProductDetailSection.XPANEL,
        title: "What is your personal risk for heart attack?",
        description: <>Heart attack and strokes can be prevented if risk is found early. Standard tests don't detect actual plaque in your coronary arteries, which is crucial for assessing actual risk. The coronary calcium scan does this.</>,
        data: [
          {
            image: "/img/cleerly-50pct.png",
            text: "50% of people who suffer a heart attack will do so without experiencing any symptoms beforehand."
          },
          {
            image: "/img/cleerly-70pct.png",
            text: "70% of heart attack victims are considered low risk by traditional methods of assessing heart disease."
          },
          {
            image: "/img/cleerly-75pct.png",
            text: "75% of lesions causing heart attacks cause mild artery narrowing and are often missed by standard testing."
          }
        ]
      },
      { type: ProductDetailSection.DIRECTIONS,
        data: [
          {
              title: "Schedule your scan",
              description: "Instalab will find the nearest imaging center with best pricing and facilitate the scheduling of your appointment.",
              image: "./img/cleerly-step1.webp"
          },
          {
              title: "Get your scan done",
              description: "Go to the imaging center and get the scan done. The actual scan usually takes around 10 minutes.",
              image: "./img/cac-scanner.webp"
          },
          {
              title: "Receive results",
              description: "Instalab will email you when your results are ready. Physicians are available to answer any questions.",
              image: "./img/man-on-ipad.webp"
          }
      ],
      },
      { type: ProductDetailSection.FEATURE_POST,
        data: {
          blogId: BlogId.CAC
        },
        className: "product-detail-feature-post"
      },
      {
        type: ProductDetailSection.FAQ,
        data: faqs.slice(4)
      }
    ])

  }, [productType])



  return (
    sections && <ProductDetail className="cac" sections={sections} trackCode={EventCode.VISIT_CAC}/>
  )
}
