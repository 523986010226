import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listQuestions = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/questions`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const listQuestionTemplates = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/questions/templates`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}