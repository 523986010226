import { useEffect } from 'react'
import { Form } from "antd"
import "./custom.scss"
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'
import _ from 'lodash'


export const Custom = ({
  step,
  onSubmit,
  form,
  isSubmitting,
}) => {
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div className="custom">
      <Form
        form={form}
        className="custom-form"
      >
        {step.content({ 
          footer: ({ 
            buttonText,
            buttonStyle,
            buttonCheck,
            enterStyle,
            onCustomSubmit
        }) => (
            <FlowStepFooter 
              isSubmitting={isSubmitting}
              onSubmit={() => onCustomSubmit ? onCustomSubmit() : onSubmit()} 
              buttonText={buttonText}  
              buttonStyle={buttonStyle}
              buttonCheck={buttonCheck}
              enterStyle={enterStyle}
            /> 
          )
        })}
      </Form>
    </div>
  )
}