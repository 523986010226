import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listReminders = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/reminders`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })

  return response.data
}

export const addReminder = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/reminders`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getReminder = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/reminders/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const updateReminder = async (id, params={}) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/reminders/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeReminder = async (id, params={}) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/reminders/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params)
  })
  return response.data
}