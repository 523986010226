import { useState, useEffect } from "react";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { ReportSection } from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import ReportHelper from "../../../helpers/report.helper";
import "./viralInfection.scss";

const converter = require("number-to-words");
const { Paragraph } = Typography;

export const ViralInfectionAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [inflammationTests, setInflammationTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [isFixed, setIsFixed] = useState();

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.VIRAL_INFECTION_ADVICE));
  }, [report, tests]);

  useEffect(() => {
    setInflammationTests(getInflammationTests());
  }, [report, tests, isFixed]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, tests, inflammationTests, isFixed]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])


  const getInflammationTests = () => {
    if (!report || !tests) return null;
    if (isFixed)
      return ReportHelper.getSectionFixedTests(report, ReportSection.VIRAL_INFECTION_ADVICE, tests);
    return ReportHelper.getSectionTests(report, ReportSection.VIRAL_INFECTION_ADVICE, tests);
  };


  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !tests || !inflammationTests) return null;

    if (isFixed) {
      return <>The impact of your <span>viral infection</span> on these <span>{converter.toWords(inflammationTests.length)} biomarkers</span> appears resolved.</>
    }

    return (
        <>
        These <span>{converter.toWords(inflammationTests.length)} biomarkers</span> are <span>suboptimal</span> likely due to your recent <span>viral infection</span>. 
        </>
    );

  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report) return;

    if (isFixed) {
      return <>Now that these biomarkers have returned to a healthy range, you can disregard the previous readings. We're happy to see your recovery nearing completion!</>
    }


    return <>
      These biomarkers can fluctuate temporarily in response to acute conditions like infections, injuries, or strenuous exercise, signifying a normal immune reaction. Nonetheless, sustained suboptimal levels could signal a serious issue.
      <br />
      <br />
      It's advisable to re-evaluate these biomarkers with a follow-up test in a few weeks to ascertain whether these changes are temporary or indicative of a longer-term concern.
    </>

  }

  return (
    inflammationTests && (
      <div className="viral-infection-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{description}</Paragraph>

        <TestView 
          report={report} 
          tests={inflammationTests}
          results={results}
          reports={reports}
          user={user}
        />
      </div>
    )
  );
};