import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listCharges = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/charges`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const getPaymentLink = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/charges/payment-link/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const getCharge = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/charges/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const addChargeRefund = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/charges/${id}/refunds`, ServiceHelper.getParams(params),  {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const registerPaymentLink = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/charges/payment-link/${id}/register`, ServiceHelper.getParams(params),  {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addPaymentLink = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/charges/payment-link`, ServiceHelper.getParams(params),  {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const confirmPaymentLink = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/charges/payment-link/${id}/confirm`, ServiceHelper.getParams(params),  {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updatePaymentLink = async (id, params={}) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/charges/payment-link/${id}`, ServiceHelper.getParams(params),  {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeCharge = async (id, params={}) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/charges/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}