const ProductStatus = Object.freeze({
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  FULFILLING: 'fulfilling',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  COMPLETE: 'complete',
  DEACTIVATED: 'deactivated',
  CANCELED: 'canceled',
  FAILED: 'failed'
})

export default ProductStatus