import React, { useState, useEffect, useRef, useContext } from 'react'
import { Modal, Form, Button, message, Input, Select } from 'antd'
import { UserContext } from '../../contexts/user.context'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat';

import RuleHelper from '../../helpers/rule.helper';
import FormHelper from '../flow/helpers/form.helper';

import { getHeartHealthTeammates } from '../../services/user.service';
import { getReminder, updateReminder, addReminder } from '../../services/reminder.service';

import DateInput from '../dateInput/dateInput.component';
import "./adminReminderForm.scss"

const { TextArea } = Input
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

const { Item } = Form

export const ReminderForm = ({ open, setOpen, onSuccess, patientId, reminderId, setReminderId, populate=[], select }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [hasAttempt, setHasAttempt] = useState()
  const [teammates, setTeammates] = useState([])
  const { currentUser } = useContext(UserContext)
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
  if (open) {
    if (reminderId) setFormFields()
  } else {
    setReminderId(null)
    form.resetFields()
    setHasAttempt(false)
  }
  }, [open])

  useEffect(() => {
    if (open && currentUser && !reminderId) {
      form.setFieldsValue({
        assignee: currentUser?._id
      })
    }
  }, [reminderId, currentUser, open])



  useEffect(() => {
    const fetchTeammates = async () => {
      const teammates = await getHeartHealthTeammates()
      setTeammates(teammates)
    }
    fetchTeammates()
  }, [])

  const onCancel = () => {
    setOpen(false)
  }

  const setFormFields = async () => {
    if (reminderId) {
      const fetchedReminder = await getReminder(reminderId, {
        select,
        populate
      })
      form.setFieldsValue({
        text: fetchedReminder.text,
        scheduledAt: dayjs(fetchedReminder.scheduledAt).tz('America/New_York').format('MM/DD/YYYY'),
        assignee: fetchedReminder.assignee?._id
      })
    } else {
      form.resetFields()
    }
  }

  const onFail = () => {
    setHasAttempt(true)
    message.error('Enter valid reminder data')
  }

  const onOk = async () => {
    setIsSubmitting(true)

    try {
      let fetchedReminder
      if (reminderId) {
        fetchedReminder = await onUpdateReminder()
      } else {
        fetchedReminder = await onAddReminder()
      }

      if (onSuccess) {
        onSuccess(fetchedReminder)
      }
      message.success(reminderId ? `Updated reminder` : `Added reminder`)
      setOpen(false)
      if (!reminderId) {
        form.resetFields()
      }
    } catch (err) {
      console.log(err)
      message.error(reminderId ? `Failed to update reminder` : `Failed to add reminder`)
    }
    setIsSubmitting(false)
  }

  const onAddReminder = async () => {
    let {
      text,
      scheduledAt,
      assignee
    } = form.getFieldsValue()

    scheduledAt = dayjs.tz(scheduledAt, 'MM/DD/YYYY', 'America/New_York')

    const newReminder = await addReminder({
      fields: {
        text,
        scheduledAt,
        patient: patientId,
        assignee
      },
      select,
      populate,
    })

    return newReminder
  }

  const onUpdateReminder = async () => {
    let {
      text,
      scheduledAt,
      assignee
    } = form.getFieldsValue()

    scheduledAt = dayjs.tz(scheduledAt, 'MM/DD/YYYY', 'America/New_York')

    const updatedReminder = await updateReminder(reminderId, {
      fields: {
        text,
        scheduledAt,
        assignee
      },
      select,
      populate,
    })

    return updatedReminder
    }

  return (
  <Modal 
    open={open} 
    title={reminderId ? `Edit Reminder` : <>Add Reminder</>}
    onCancel={onCancel}
    onOk={onOk}
    okText='Save'
    footer={null}
    className="admin-reminder-modal"
  >
    <Form
      form={form}
      onFinish={onOk}
      onFinishFailed={onFail}
      layout='vertical'
      className="admin-reminder-form"
    >
      <Item 
        label="Reminder Date"
        name="scheduledAt"
        rules={[
          RuleHelper.isRequired,
          RuleHelper.isDate,
        ]}
        validateTrigger={[]}
      >
        <DateInput 
          onChange={() => {
            if (hasAttemptRef.current) {
              FormHelper.fetchHasError(form)
            }
          }}
        />
      </Item>

      <Item 
        label="Text"
        name="text"
        rules={[
          RuleHelper.isRequired,
        ]}
      >
        <TextArea autoSize rows={1} />
      </Item>

      <Item 
        label="Assigned To"
        name="assignee"
        rules={[RuleHelper.isRequired]}
      >
        <Select>
          {teammates
            .sort((a, b) => {
              const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
              const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
              return nameA.localeCompare(nameB);
            })
            .map(teammate => (
            <Select.Option key={teammate._id} value={teammate._id}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {teammate.photo && (
                  <img 
                    src={teammate.photo} 
                    alt={teammate.firstName}
                    style={{ 
                      width: '24px', 
                      height: '24px', 
                      borderRadius: '50%',
                      objectFit: 'cover'
                    }} 
                  />
                )}
                <span>{teammate.firstName} {teammate.lastName}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Item>

      <Item>
        <Button
          className="submit-btn"
          type='primary'
          htmlType='submit'
          loading={isSubmitting}
        >
          {reminderId ? 'Update Reminder' : 'Create Reminder'}
        </Button>
      </Item>
    </Form>
  </Modal>
  )
}