import { Table } from "antd";
import "./testTable.scss";
import { useEffect, useState } from "react";
import { ReadableRiskLevel, RiskLevel} from "../../../enums/index.enum";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const TestTable = ({code, report, tests, results}) => {
  const [test, setTest] = useState();
  const [tableData, setTableData] = useState();


  useEffect(() => {
    setTest(getTest());
  }, [code, tests]);

  useEffect(() => {
    setTableData(getTableData());
  }, [test, results]);


  const getTest = () => {
    if (!tests) return null;
    return tests.find((t) => t.code === code);
  };


  const getTableData = () => {
    if (!test || !results?.length) return null;

    // Pull test values from results
    const testResults = getTestResults();

    return testResults.map((result) => {
        let testResult = result?.values.find((v) => v.test === test._id);   
        return {
            value: testResult.value, 
            date: dayjs(result.collectedAt, { dateFormat: 'YYYY-MM-DD' }).format('MM/DD/YYYY'), 
            key: result._id,
            risk: testResult.risk,
            details: testResult.details
        }
    });

  };


  const getTestResults = () => {
    return results
      .filter((result) => {
        return (
          !report ||
          new Date(report.result.collectedAt).getTime() >=
            new Date(result.collectedAt).getTime()
        );
      })
      .sort((a, b) => {
        return (
          new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime()
        );
      })
      .filter((result) => {
        return result.values.some((v) => {
          return v.test === test._id && v.value && v.risk
        });
      });
  };


  const columns = [
    {
        title: 'Date Collected',
        dataIndex: 'date',
        className: 'date-col',
        key: 'date',
    },
    {
        title: 'Test Result',
        dataIndex: 'value',
        key: 'value',
        className: 'value-col',
        render: (value, record) => {
          const { risk, details } = record;
          return (
            <>
            <div className={`risk-tag ${risk}-risk`}>
              {risk!== RiskLevel.UNKNOWN && <>{ReadableRiskLevel[risk][0].toUpperCase()}{ReadableRiskLevel[risk].slice(1).toLowerCase()}:</>} {value}
            </div>
            <div className="test-details">{details}</div>
            </>
          );
        },
    }
    ];

  return (
    test &&
    tableData && (
      <div className="test-table">
        <Table
          pagination={false}
          rowKey="key"
          dataSource={tableData}
          columns={columns}
          className="primary-table"
          showHeader={true}
        >

        </Table>
      </div>
    )
  );
};
