const Diet = Object.freeze({
  CARNIORE: "Carnivore",
  GLUTEN_FREE: "Gluten-Free",
  HIGH_PROTEIN: "High Protein",
  INTERMITTENT_FASTING: "Intermittent Fasting",
  LOW_CARB: "Low Carbohydrate",
  KETO_HIGH_FAT: "Keto / High-Fat",
  MEDITERRANEAN: "Mediterranean",
  PESCATARIAN: "Pescatarian",
  VEGAN: "Vegan",
  VEGETARIAN: "Vegetarian",
});

export default Diet;
