import { useContext } from "react";
import { Typography, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../contexts/user.context";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import { BlogData } from "../../../data/blog.data";
const { Paragraph } = Typography

export const GalleriPost = () => {
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)
  const { blogId } = useParams()

  return (
    <div className={classNames("blog-post", "galleri-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          {BlogData.find(b => b.id === blogId).title}
        </Paragraph>
        <Paragraph className="blog-subheading">
          {BlogData.find(b => b.id === blogId).description}
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <img 
          src="/img/galleri.png" 
          className="blog-image"
        />

        <Paragraph className="blog-title">
          The science behind it
        </Paragraph>
        <Paragraph className="blog-description">
          The Galleri test analyzes your blood for cell-free DNA (cfDNA), which are short fragments of DNA released through the natural process of cell death. These fragments can come from cancer and non-cancer cells. Using advanced genomic sequencing and algorithms, the test detects methylation patterns of cfDNA that are indicative of cancer. Upon detecting cancer, the test identifies the most likely tumor type, such as pancreatic cancer, by recognizing patterns unique to different cancers.
        </Paragraph>

        <Paragraph className="blog-title">
          High specificity
        </Paragraph>
        <Paragraph className="blog-description">
          Specificity refers to a test's accuracy in correctly identifying individuals without the disease. In studies, the Galleri test has demonstrated a high specificity of 98.4-99.5%. This means that only in 0.5-1.6% of cases where the test indicated a cancer signal was there actually no cancer present. This accuracy is notably higher than many existing cancer tests, reducing the likelihood of unnecessary follow-ups and their emotional and financial burdens.
        </Paragraph>

        <Paragraph className="blog-title">
          Sensitivity challenges
        </Paragraph>
        <Paragraph className="blog-description">
          Sensitivity measures a test's accuracy in correctly identifying individuals with the disease. With a sensitivity of 51.5-66.3%, the Galleri test shows a substantial ability to detect cancer, but this also means that one-third to half of cancer cases might go undetected. Early detection is crucial for better treatment outcomes. The test's lower sensitivity in detecting stage I cancers (16.8-24.2%) compared to stage IV cancers (90.1-95.3%) limits its effectiveness in early-stage detection and raises questions about its suitability as a standalone screening tool.
        </Paragraph>

        <Paragraph className="blog-title">
          Figure 1: Stage-dependent sensitivity of Galleri test from 2 studies
        </Paragraph>
        <Table
          className="blog-table"
          dataSource={[{
            stage: 'I',
            ccga: '16.8%',
            symplify: '24.2%'
          }, {
            stage: 'II',
            ccga: '40.4%',
            symplify: '57.1%'
          }, {
            stage: 'III',
            ccga: '77%',
            symplify: '85.2%'
          }, {
            stage: 'IV',
            ccga: '90.1%',
            symplify: '95.3%'
          }, {
            stage: 'Overall',
            ccga: '51.5%',
            symplify: '66.3%'
          }]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column title="Stage" dataIndex="stage" />
          <Table.Column title="CCGA3 (2021)" dataIndex="ccga" />
          <Table.Column title="SYMPLIFY (2023)" dataIndex="symplify" />
        </Table>

        <Paragraph className="blog-title">
          Accuracy in identifying cancer type
        </Paragraph>
        <Paragraph className="blog-description">
          The Galleri test, upon detecting a cancer signal, also attempts to pinpoint the cancer's origin. This accuracy is vital to avoid misdirection in the search for the cancer's source. In three studies, the test's accuracy was found to be between 85% and 88.7%, meaning up to 15% of the time, it may incorrectly identify the cancer type, necessitating further testing.
        </Paragraph>

        <Paragraph className="blog-title">
          Comparison with routine screenings
        </Paragraph>
        <Paragraph className="blog-description">
          The recent PATHFINDER study, which used the Galleri test on all participants, found that 29% of cancers detected were identified by Galleri, while 31% were detected through other screening methods. The remaining 40% were diagnosed based on clinical signs or symptoms, e.g. pain, unexplained weight loss or palpable mass. Compared to other non-invasive routine screenings, Galleri doesn't perform better (refer to Figure 2). However, it's crucial to note that Galleri is designed to detect multiple cancer types simultaneously, unlike specific screenings that target only one type. Moreover, Galleri has a higher specificity and can detect many cancers without routine non-invasive screenings. Those that have a sensitivity of over 85% include pancreas, liver/bile-duct, head & neck, esophagus.
        </Paragraph>

        <Paragraph className="blog-title">
          Figure 2: Sensitivity by cancer type of Galleri test vs routine screening methods
        </Paragraph>
        <Table
          className="blog-table"
          dataSource={[{
            type: 'Breast',
            ccga: '30.5%',
            symplify: '-',
            routine: '86.9% (mammogram)'
          }, {
            type: 'Cervical',
            ccga: '80%',
            symplify: '-',
            routine: '94.6% (HPV test)\n55.4% (PAP test)'
          }, {
            type: 'Prostate',
            ccga: '11.2%',
            symplify: '9.1%',
            routine: '93% (PSA test)'
          }, {
            type: 'Colorectal',
            ccga: '82%',
            symplify: '70.8%',
            routine: '92% (Cologuard)'
          }, {
            type: 'Lung',
            ccga: '74.8%',
            symplify: '67.9%',
            routine: '59-100% (LDCT)'
          }]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column title="Cancer Type" dataIndex="type" />
          <Table.Column title="CCGA3 (2021)" dataIndex="ccga" />
          <Table.Column title="SYMPLIFY (2023)" dataIndex="symplify" />
          <Table.Column title="Routine Screening" dataIndex="routine" />
        </Table>

        <Paragraph className="blog-title">
          Instalab's recommendation
        </Paragraph>
        <Paragraph className="blog-description">
          Priced at $949, the Galleri test is a considerable investment. However, especially those with a family history of cancer, it can be a valuable supplement to standard screening methods. It's non-invasive, highly specific, and covers multiple cancers not routinely screened and often detected too late. Just remember that a "no cancer signal detected" result does not mean you are cancer-free.
        </Paragraph>

        <Paragraph className="blog-title">
          How to get the test
        </Paragraph>
        <Paragraph className="blog-description">
          You can now <a className="blog-link" onClick={() => {
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: order`, true)
            navigate('/galleri-test')
          }}>order the test</a> though Instalab for $899.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Multi-cancer early detection test in symptomatic patients referred for cancer investigation in England and Wales (SYMPLIFY): a large-scale, observational cohort study (The Lancet, 2023) <a onClick={() => window.open('https://www.thelancet.com/journals/lanonc/article/PIIS1470-2045(23)00277-2/fulltext', '_blank')}>https://www.thelancet.com/journals/lanonc/article/PIIS1470-2045(23)00277-2/fulltext</a>
            </li>
            <li>
              Clinical validation of a targeted methylation-based multi-cancer early detection test using an independent validation set (Annals of Oncology, 2021) <a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/34176681/', '_blank')}>https://pubmed.ncbi.nlm.nih.gov/34176681/</a>
            </li>
            <li>
              Sensitive and specific multi-cancer detection and localization using methylation signatures in cell-free DNA (Annals of Oncology, 2020) <a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/33506766/', '_blank')}>https://pubmed.ncbi.nlm.nih.gov/33506766/</a>
            </li>
            <li>
              Blood-based tests for multicancer early detection (PATHFINDER): a prospective cohort study (Lancet, 2023) <a onClick={() => window.open('https://www.thelancet.com/pdfs/journals/lancet/PIIS0140-6736(23)01700-2.pdf', '_blank')}>https://www.thelancet.com/pdfs/journals/lancet/PIIS0140-6736(23)01700-2.pdf</a>
            </li>
            <li>
              Multi-cancer early detection from a single blood draw (Grail, 2023) <a onClick={() => window.open('https://grail.com/multi-cancer-early-detection/', '_blank')}>https://grail.com/multi-cancer-early-detection/</a>
            </li>
            <li>
              Systematic review and meta-analysis of the diagnostic accuracy of prostate-specific antigen (PSA) for the detection of prostate cancer in symptomatic patients (BMJ Medicine, 2022) <a onClick={() => window.open('https://bmcmedicine.biomedcentral.com/articles/10.1186/s12916-021-02230-y', '_blank')}>https://bmcmedicine.biomedcentral.com/articles/10.1186/s12916-021-02230-y</a>
            </li>
            <li>
              National Performance Benchmarks for Modern Screening Digital Mammography: Update from the Breast Cancer Surveillance Consortium (Radiology, 2017) <a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/27918707/', '_blank')}>https://pubmed.ncbi.nlm.nih.gov/27918707/</a>
            </li>
            <li>
              Human Papillomavirus DNA versus Papanicolaou Screening Tests for Cervical Cancer (The New England Journal of Medicine, 2007) <a onClick={() => window.open('https://www.nejm.org/doi/full/10.1056/NEJMoa071430', '_blank')}>https://www.nejm.org/doi/full/10.1056/NEJMoa071430</a>
            </li>
            <li>
              Screening for Lung Cancer With Low-Dose Computed Tomography Updated Evidence Report and Systematic Review for the US Preventive Services Task Force (JAMA, 2021) <a onClick={() => window.open('https://jamanetwork.com/journals/jama/fullarticle/2777242', '_blank')}>https://jamanetwork.com/journals/jama/fullarticle/2777242</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};