import { Modal, Typography } from 'antd'
import './membershipPointsModal.scss'
import { MembershipPoints } from '../membershipPoints/membershipPoints.component'

const { Paragraph } = Typography

export const MembershipPointsModal = ({ open, setOpen }) => {
  const onCancel = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="membership-points-modal"
      footer={null}
      width={500}
    >
      <Paragraph className="membership-header">
        Try Instalab Membership
      </Paragraph>
      <Paragraph className="membership-pricing">
        $49/month. Cancel anytime.
      </Paragraph>

      <div className="membership-points-container">
        <MembershipPoints />
      </div>
    </Modal>
  )
}