import React, { useState, useEffect, useContext } from 'react';
import { Form,  Modal, message, Input } from 'antd'
import { addSlack } from '../../services/slack.service';
import { UserContext } from '../../contexts/user.context.js';

const { TextArea } = Input;

export const SendFileModal = ({isSendModalVisible, selectedFile, setIsSendModalVisible}) => {
    const [sendForm] = Form.useForm();
    const [sendDetails, setSendDetails] = useState(null);
    const { currentUser } = useContext(UserContext);

    // Resetting the form fields when the modal is closed.
    useEffect(() => {
      if (!isSendModalVisible) {
        sendForm.resetFields();
      }
    }, [isSendModalVisible, sendForm]);

    const handleSendFileModalOk = async () => {
      try {
          // Triggering form validation and waiting for the result.
          const values = await sendForm.validateFields();
  
          // Directly use values from form validation result
          await handleSendFile(selectedFile, values); // Pass values directly to the function
          setIsSendModalVisible(false); // Closing modal after operation
  
      } catch (error) {
          console.error("Validation failed:", error);
      }
  };
  
    // Modify your handleSendFile function to accept values directly
    const handleSendFile = async (file, sendDetails) => {
      try {

        const sender = currentUser
        const senderName = sender ? `${sender.firstName} ${sender.lastName}` : null

        // Implement the actual sending logic here using 'sendDetails'
        const channel = process.env.REACT_APP_CONCIERGE_SLACK;
        const text = 
            `*From:* ${senderName}\n` +
            `*To:* ${sendDetails.recipient}\n` +
            `*Message:* ${sendDetails.message}\n` +  
            `*Patient id:* ${file.folderName}\n` +
            `*File name:* ${file.name}\n` +
            `*File id:* ${file.id}`;

        await addSlack({message: text, channel: channel});
        message.success('File in queue to fax!');
  
      } catch (error) {
        console.error('Error sending file:', error);
        message.error('Failed to send file');
      }
    };
  
    const handleClose = () => {
      setSendDetails(null)
      setIsSendModalVisible(false);
    };
  
    return (
      <Modal
        title={<>Fax File <br/><span style={{fontWeight:"normal", fontSize: 14}} className="no-underline">Your file will be put in a queue and sent a few minutes after you hit 'Send.'</span></>}
        visible={isSendModalVisible}
        onCancel={handleClose}
        onOk={handleSendFileModalOk}
        okText={"Send"}
      >
        <Form
          form={sendForm}
          layout="vertical"
          initialValues={sendDetails} 
        >
          <Form.Item
            label="Recipient"
            name="recipient"
            extra={"Type fax number."}
            rules={[{ required: true, message: 'Please enter the recipient!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Message"
            name="message"
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    );
};