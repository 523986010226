import React, { useContext, useEffect, useState } from 'react';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { Button, message, Avatar, Modal } from 'antd'
import { listAdmins, removeAdmin } from "../../services/admin.service"
import { DeleteOutlined, EditOutlined, LockOutlined } from '@ant-design/icons'
import './admins.scss';
import moment from 'moment';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { AdminForm } from '../adminForm/adminForm.component';
import { ChangePasswordModal } from '../changePasswordModal/changePasswordModal.component';
import ObjectType from '../../enums/objectType.enum';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component';
import { UserContext } from '../../contexts/user.context.js';

const select = '_id firstName lastName photo email phone createdAt'
const populate = []

export const Admins = () => {
  const [admins, setAdmins] = useState([])
  const [adminId, setAdminId] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState()
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const { setCounts } = useContext(UserContext)

  useEffect(() => {
    fetchAdmins()
  }, [])

  const fetchAdmins = async () => {
    setIsLoading(true)
    const fetchedAdmins = await listAdmins({
      select,
      filter: {},
      populate,
      sort: '-createdAt'
    })
    setAdmins(fetchedAdmins)
    setFilteredCount(fetchedAdmins.length)
    setIsLoading(false)
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this admin?',
      content: 'This action cannot be undone and will permanently remove the admin from your system.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removeAdmin(_id);
          setAdmins(admins.filter(admin => admin._id !== _id));
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              admins: cachedCounts.admins - 1
            }
          })
          setFilteredCount(cachedFilterCount => cachedFilterCount-1)
          message.info('Admin removed');
        } catch (err) {
          message.error('Failed to remove admin');
        }
      }
    });
  }

  const getActionItems = ({ _id }) => [{
    key: '1',
    label: (
      <Button onClick={() => {
        setAdminId(_id)
        setIsModalOpen(true)
      }}>
        <EditOutlined /> Edit
      </Button>
    )
  }, {
    key: '2',
    label: (
      <Button onClick={() => {
        setAdminId(_id)
        setIsPasswordModalOpen(true)
      }}>
        <LockOutlined /> Change Password
      </Button>
    )
  }, {
    type: 'divider'
  }, {
    key: '4',
    label: (
      <Button 
        onClick={() => onRemove(_id)}
        className="remove-item"
      >
        <DeleteOutlined /> Remove
      </Button>
    )
  }]

  const getCustomFilter = (admin, value) => {
    return {
      location: () => admin.location?.city && admin.location.city.toLowerCase().includes(value.toLowerCase()),
      firstName: () => `${admin.firstName} ${admin.lastName}`.toLowerCase().includes(value.toLowerCase()),
      phone: () => admin.phone && admin.phone.replace(/\D/g,'').includes(value.replace(/\D/g,'')),
    }
  }

  return (
    <div className="admins">
      <PageHeader
        title='Admins'
        count={filteredCount}
        actions={(
          <Button
            type='primary'
            onClick={() => {
              setAdminId(null)
              setIsModalOpen(true)
            }}
          >
            + Add New Admin
          </Button>
        )}
      />

      <AdminForm
        adminId={adminId}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        select={select}
        populate={populate}
        onSuccess={admin => {
          if (adminId) {
            setAdmins(admins.map(a => a._id === adminId ? admin : a))
          } else {
            setAdmins([
              admin,
              ...admins
            ])
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                admins: cachedCounts.admins + 1
              }
            })
            setFilteredCount(cachedFilterCount => cachedFilterCount+1)
          }
        }}
      />

      <ChangePasswordModal
        objectId={adminId}
        objectType={ObjectType.ADMIN}
        open={isPasswordModalOpen}
        setOpen={setIsPasswordModalOpen}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={admins}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[
        //   {
        //   dataIndex: 'photo',
        //   className: "photo-col",
        //   width: 50,
        //   render: photo => photo && <Avatar className="user-photo" src={`${photo}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} />
        // }, 
        {
          title: 'Name',
          dataIndex: 'firstName',
          filterDropdownType: FilterDropdownType.INPUT,
          render: (firstName, { lastName }) => `${firstName} ${lastName}`
        }, {
          title: 'Email Address',
          dataIndex: 'email',
          filterDropdownType: FilterDropdownType.INPUT,
        }, {
          title: 'Phone Number',
          dataIndex: 'phone',
          filterDropdownType: FilterDropdownType.INPUT,
        }, {
          title: 'Created',
          dataIndex: 'createdAt',
          render: createdAt => moment(createdAt).format('MMM D. YYYY')
        }]}
      />
    </div>
  )
}