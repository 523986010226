const TaskCategory = Object.freeze({
  BIOBEAT: 'biobeat',
  CHOLESTEROL_TREATMENT: 'cholesterol treatment',
  CCTA: 'ccta',
  CORONARY_CALCIUM_SCAN: 'coronary calcium scan',
  DEXA_BODY_COMP: 'dexa body comp',
  DEXA_BONE: 'dexa bone',
  DIURNAL_CORTISOL_PROFILE: 'diurnal cortisol profile',
  LONGEVITY_PANEL: 'longevity panel',
  GALLERI: 'galleri',
  HEART_HEALTH_GENETIC_TEST: 'heart health genetic test',
  HEAVY_METALS_TEST: 'heavy metals test',
  MEMBER: 'member',
  MOLD_TOXINS: 'mold toxins',
  PRENUVO: 'prenuvo',
  REFERRAL: 'referral',
  RMR: 'rmr',
  SLEEP_APNEA_TEST: 'sleep apnea test',
  SPOT: 'spot',
  TRUAGE: 'truage',
  VO2MAX: 'vo2 max',
  SKIN_CANCER_SCREEN: 'skin cancer screen',
  COLONOSCOPY: 'colonoscopy',
  MAMMOGRAM: 'mammogram',
  PAP_SMEAR: 'pap smear',
  HPV_TEST: 'hpv test',
  LDCT: 'ldct',
})

export default TaskCategory