import CryptoJS from 'crypto-js';

// Secret key for generating userHash
const FRONTCHAT_SECRET = '0d7373b6ca938470921064c302bb6b64';

const FrontChatHelper = {
  /**
   * Identify the user in FrontChat.
   * @param {Object} user - The current user object.
   */
  identifyUser(user) {
    if (user && window.FrontChat) {
      const userHash = CryptoJS.HmacSHA256(user.email, FRONTCHAT_SECRET).toString(CryptoJS.enc.Hex);

      const data = {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        userHash: userHash,
      };

      window.FrontChat('identity', data); // Call FrontChat with the user info
    } else {
      console.error('FrontChat not available or user is missing');
    }
  },

  /**
   * Show the FrontChat window.
   */
  showChat() {
    if (window.FrontChat) {
      window.FrontChat('show');
    } else {
      console.error('FrontChat not available');
    }
  },
};

export default FrontChatHelper;