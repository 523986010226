import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const loginAsUser = async (id) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/impersonations/${id}/login`)
  return response.data
}

export const addImpersonation = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/impersonations`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}