import { Collapse, Typography } from 'antd';
import './questions.scss';

const { Paragraph } = Typography
const { Panel } = Collapse

export const Questions = ({ items }) => {
  return (
    <div className="questions">
      <Collapse
        accordion
        bordered={false}
        expandIconPosition='end'
      >
        {items.map(({ question, answer }, index) => (
          <Panel
            key={`question-${index}`}
            header={question}
          >
            <Paragraph className="answer">
              {answer}
            </Paragraph>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}