import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { HpvIntro } from "./hpvIntro.component.js"
import { ConfirmHpv } from "./confirmHpv.component.js"
import { listProductTypes } from '../../services/productType.service'
import MembershipTypeCode from "../../enums/membershipTypeCode.enum.js"
import { getMembershipTypeByCode} from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service.js"
import FlowType from "../../enums/flowType.enum.js"
import { useNavigate } from "react-router-dom"
import ProductHelper from "../../helpers/product.helper"
import { FileTypeCode } from "../../enums/index.enum.js"

export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',
  LOCATION: 'location',
  FACILITY: 'facility',
  NAME: 'name',
  GENDER: 'gender',
  PREGNANCY: 'pregnancy',
  DOB: 'dob',
  INSURANCE: 'insurance',
  PHONE: 'phone',
  PAY: 'pay',
  CONFIRM: 'confirm',
}

export const HpvFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [membershipType, setMembershipType] = useState()

  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, membershipType, productTypes, initialUser, instalabMembership, flow])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])


  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.HPV_TEST,
          ]
        }
      }
    }))
  }

  const fetchMembershipType = async () => {
    setMembershipType(await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const instructions = [
    {
      sections: [{
        title: 'Schedule Your HPV Test',
        description: <>Our concierge team will assist you in booking an appointment at a trusted gynecology clinic or primary care provider near you.</>
      }]
    },
    {
      sections: [{
        title: 'Prepare for Your Test',
        description: <>Avoid sexual activity, douching, or using vaginal medications 48 hours before the test to ensure accurate results. The procedure is quick and usually takes less than 5 minutes.</>
      }]
    },
    {
      sections: [{
        title: 'Review Your Results',
        description: <>Results are typically available within 3-5 days. Instalab physicians will review your results and provide follow-up recommendations if any high-risk strains are detected.</>
      }]
    }
  ];


  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser || !productTypes) return
    const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.HPV_TEST)], instalabMembership, currentUser)
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <HpvIntro productType={getProductType(ProductTypeCode.HPV_TEST)} footer={footer} instructions={instructions}/>
        
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.LOCATION,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.HPV}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },

      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        buildProduct:true,
        nextStep: Step.NAME,
        title: `What's your current address?`,
        description: `We'll search for highly recommended providers near this address.`,
        field: {
          name: 'location'
        },
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },

      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },

      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.INSURANCE,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },

      [Step.INSURANCE]: {
        type: StepType.INSURANCE_UPLOAD,
        onNextStep: () => { return Step.PHONE },
        buildUser: true,
        title: 'Upload your insurance card (optional)',
        description: 'Health insurance often covers the HPV test. Upload your card now to prioritize finding an in-network provider.',
        hasSkip: true,
        fields: [
          {
            name: 'frontInsuranceCard',
            fileTypeCode: FileTypeCode.INSURANCE_CARD_FRONT,
            upload: true,
            title: 'Front of your insurance card',
          },
          {
            name: 'backInsuranceCard',
            fileTypeCode: FileTypeCode.INSURANCE_CARD_BACK,
            upload: true,
            title: 'Back of your insurance card',
          }
        ],
        skipIf: () => {
          return initialUser?.frontInsuranceCard && initialUser?.backInsuranceCard
        }
      },

      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PAY,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only send you urgent notifications about your account or results here.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },

      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.CONFIRM,
        addProduct: true,
        productType: ProductTypeCode.HPV_TEST,
        title: <>Pay <span className="true-price">${totalCost}</span> for the Instalab service fee.</>,
        description: <>This fee covers researching the best provider for your needs, scheduling your appointment and handling any follow ups after you receive your results.<p><i>Note: This doesn't include any insurance co-pay or costs the provider may directly charge you for the procedure.</i></p></>,
        skipIf: () => {
          return instalabMembership 
        }
      },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `All set! We'll email you within 1 business day.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmHpv instructions={instructions} productType={getProductType(ProductTypeCode.HPV_TEST)}/>,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }

  return <>
    <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass} 
      productTypeCode={ProductTypeCode.HPV_TEST}
    />

  </>
}