import { Button }  from 'antd'
import Panels from "../panels/panels.component.js"
import ProductTypeCode from '../../enums/productTypeCode.enum.js'

export const ConfirmIntake = () => {

  const productTypeCodes = [
    ProductTypeCode.LONGEVITY_PANEL,
    ProductTypeCode.GALLERI,
    ProductTypeCode.CTCALCIUM,
    ProductTypeCode.WATCHPAT_ONE
  ]
  
  return (
    <div className="prescription-approval">


      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <Panels productTypeCodes={productTypeCodes} />

    </div>
  )
}
