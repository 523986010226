import React, { useEffect } from 'react';
import { Avatar, Space, Button, List } from 'antd';
import './support.scss';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import FlowType from '../../enums/flowType.enum';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import FrontChatHelper from '../../helpers/fronchat.helper'
import MembershipHelper from '../../helpers/membership.helper';

export const Support = () => {
  const { currentUser, instalabMembership, memberships } = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    document.title = `Support | Instalab`;
  }, []);

  useEffect(() => {
    FrontChatHelper.identifyUser(currentUser);
  }, [currentUser]);
  
  const onChat = () => {
    FrontChatHelper.showChat();
  };

  const onAdvisoryCall = () => {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/d/4kj-7q2-bnb?hide_gdpr_banner=1' });
  };

  const onBookSession = () => {
    navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
  }

  const onBookHHSession = () => {
    navigate(`/flow/${FlowType.HEART_MEMBER_CONSULT}`)
  }

  const onReferral = () => {
    navigate(`/flow/${FlowType.REFERRAL}`)
  }

  let data = [];
  if (instalabMembership) {
    data = [ 
      {
        title: 'Longevity Physician',
        description: 'Review your results or discuss anything on your long-term health.',
        actions: [
          <Button key="list-loadmore-more" onClick={onBookSession} className='cta-link'>Schedule</Button>,
        ],    
      },
    ]

    if ( MembershipHelper.getActiveHHMembership(memberships)) {
      data.push({
        title: 'Heart Health Physician',
        description: 'Review your heart health progress with your physician.',
        actions: [
          <Button key="list-loadmore-more" onClick={onBookHHSession} className='cta-link'>Schedule</Button>,
        ],    
      })
    }

    data = [
      ...data,
      {
        title: 'Member Concierge',
        description: 'For any health-related requests like scheduling or general support.',
        actions: [
          <Button key="list-loadmore-more" onClick={onChat} className='cta-link'>Start Chat</Button>,
        ],       
        },
      {
        title: 'Specialist Referral',
        description: "Need care beyond Instalab’s scope? Request a referral to top specialists for expert advice and treatment.",
        actions: [
          <Button key="list-loadmore-more" onClick={onReferral} className='cta-link'>Request</Button>,
        ],  
      },

      {
        title: 'Instalab Founders',
        description: 'They are eager to speak with you.',
        actions: [
          <Button key="list-loadmore-more" onClick={onAdvisoryCall} className='cta-link'>Schedule</Button>,
        ],
      },
    ]
    
  }
  else {
    data = [ 
      {
        title: 'Longevity Physician',
        description: 'Review results and get any questions on your health answered.',
        actions: [
          <Button key="list-loadmore-more" onClick={onBookSession} className='cta-link'>Schedule</Button>,
        ],    
      },
      {
        title: 'Instalab Support',
        description: "For any questions on Instalab's services or general support.",
        actions: [
          <Button key="list-loadmore-more" onClick={onChat} className='cta-link'>Start Chat</Button>,
        ],       
      },
      {
        title: 'Instalab Founders',
        description: 'They are eager to speak with you.',
        actions: [
          <Button key="list-loadmore-more" onClick={onAdvisoryCall} className='cta-link'>Schedule</Button>,
        ],
      },]
  }

  return (
    <div className="support-container">
      {/* Team Avatars */}
      <div className="team-section">
        <Space>
        <Avatar src="/img/adora-square.png" size={64} icon={<UserOutlined />} />
        <Avatar src="/img/steven-square.png" size={64} icon={<UserOutlined />} />
          <Avatar src="/img/dan-square.png" size={64} icon={<UserOutlined />} />
        </Space>
        <h1>Your Instalab Care Team<p>From medical advice to technical support</p>
        </h1>
      </div>

      {/* Action List */}
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item actions={item.actions}>
            <List.Item.Meta title={item.title} description={item.description} />
          </List.Item>
        )}
        className="action-list"
      />

      {/* Start a Chat */}
      {/* <div className="start-chat">
        <Button type="primary" onClick={onChat}>Start a Chat</Button>
      </div> */}
    </div>
  );
};