import React, { useEffect } from 'react';
import { AdminMembershipsTable } from '../adminMembershipsTable/adminMembershipsTable.component';

export const AdminMemberships = () => {
  useEffect(() => {
    document.title = 'Instalab | Memberships'
  }, [])

  return (
    <AdminMembershipsTable />
  )
}