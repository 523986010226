import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'
import QueryStringHelper from '../helpers/queryString.helper'

export const getStatsExposureDistribution = async (params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/stats/exposure-distribution?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}
