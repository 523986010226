
import { useState, useContext, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { WarningFilled } from '@ant-design/icons'
import { UserContext } from "../../../contexts/user.context"
import { listMemberships } from "../../../services/membership.service"
import { getAvailability } from "../../../services/schedule.service"
import { getProductTypeByCode } from '../../../services/productType.service'
import { completeFlow, addProxyFlow } from "../../../services/flow.service"
import { listUserCounts } from "../../../services/user.service"
import { listPatients, invitePatient } from "../../../services/patient.service"
import ProductHelper from "../../../helpers/product.helper"
import MembershipHelper from "../../../helpers/membership.helper"
import { advancedPanel } from "../../../data/advancedPanel.data";
import { FlowType, Gender, MembershipTypeCode, Org, PhlebType, ProductTypeCode, Role } from "../../../enums/index.enum";
import StepType from "../../flow/enums/stepType.enum"
import { Flow } from "../../flow/flow.component"
import { PanelModal } from "../../panelModal/panelModal.component"
import ConfirmAppointment from "./static/confirmAppointment/confirmAppointment.component"
import './proLongevityPanelFlow.scss';

export const Step = {
  PATIENT: 'patient',
  LOCATION: 'location',
  DRAW: 'draw',
  PSC: 'psc',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  PAY: 'pay',
  INTEREST: 'interest',
  SCHEDULE: 'schedule',
  APPOINTMENT_CONFIRMATION: 'appointment-confirmation',
  SHIPPING: 'shipping',
}

export const ProLongevityPanelFlow = () => {
  const { currentUser, setCounts } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [openPanel, setOpenPanel] = useState()
  const [phlebType, setPhlebType] = useState()
  const [productType, setProductType] = useState()
  const [payDescription, setPayDescription] = useState()
  const [initialUser, setInitialUser] = useState()
  const [instalabMembership, setLongevityMembereship] = useState()
  const [bloodDraw, setBloodDraw] = useState()
  const [patients, setPatients] = useState([])
  const navigate = useNavigate()

  const panels = {
    [ProductTypeCode.LONGEVITY_PANEL]: advancedPanel,
  }

  const panelTitles = {
    [ProductTypeCode.LONGEVITY_PANEL]: 'The Longevity Panel',
  }

  useEffect(() => {
    fetchPatients()
    fetchProductType()
    fetchLongevityMembership()
    fetchPhlebType()
  }, [flow])

  useEffect(() => {
    fetchPayDescription()
  }, [productType, currentUser])

  useEffect(() => {
    fetchSkeleton()
  }, [productType, instalabMembership, flow, phlebType, payDescription, initialUser, patients])


  const fetchPatients = async () => {
    if (!flow) return
    const fetchedPatients = await listPatients({
      filter: {
        org: Org.INSTALAB,
        email: {
          $ne: null
        }
      }, 
      sort: 'firstName lastName'
    })
    setPatients(fetchedPatients)
  }

  const fetchLongevityMembership = async () => {
    if (!flow?.user) return
    const memberships = await listMemberships({
      filter: {
        patient: flow.user._id
      },
      select: 'status endAt',
      populate: {
        path: 'membershipType',
        satus: 'code'
      }
    })
    setLongevityMembereship(MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.LONGEVITY))
  }

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };
  
  const fetchPayDescription = () => {
    if (!productType || !currentUser) return

    let panelDescription, payDescription
    switch (productType.code) {
      case ProductTypeCode.LONGEVITY_PANEL:
        panelDescription = <>This includes an at-home blood draw, in-depth results for <a onClick={() => setOpenPanel(true)} target="panel-link">60+ biomarkers</a> and a personalized report.</>
        break
      case ProductTypeCode.ATHLETE_PANEL:
        panelDescription = <>This includes an at-home draw, in-depth results for <a className="panel-link" onClick={() => setOpenPanel(true)}>70+ biomarkers</a> and a personalized report.</>
        break
    }
    if (currentUser.chargePersonalCard) {
      payDescription = <>Reminder: Use your own personal card for this purchase.</>
    } else {
      payDescription = <>Reminder: Use your client's card for this purchase.</>
    }

    setPayDescription(<>{panelDescription}<br/><br/>{payDescription}</>)
  }

  const fetchPhlebType = async () => {
    if (!flow?.user?.location) return
    const response = await getAvailability({
      startDate: new Date(),
      location: flow.user.location,
      flowId: flow?._id
    })
    setPhlebType(response.phlebType)
  }

  const fetchProductType = async () => {
    setProductType(await getProductTypeByCode(ProductTypeCode.LONGEVITY_PANEL))
    setBloodDraw(await getProductTypeByCode(ProductTypeCode.MOBILE_BLOOD_DRAW))  
  }

  const fetchSkeleton = () => {
    if (!initialUser) return

    setSkeleton({

      [Step.PATIENT]: {
        type: StepType.SINGLE_SELECT,
        dropdown: true,
        multiple: false,
        buildFlow: true,
        title: `Select patient to order ${productType?.title}.`,
        description: <>If this is a new patient, please first <a href="/provider/patients">add patient</a> to system first.</>,
        field: {
          name: 'user',
          placeholder: 'Select Patient',
          options: patients.map(patient => ({
            label: `${patient.firstName} ${patient.lastName}`,
            value: patient._id
          })),
        },
        nextStep: Step.LOCATION,
        onSucces: async () => {
          const response = await addProxyFlow({
            user: flow?.user,
            type: FlowType.PRO_LONGEVITY_PANEL
          })
          navigate(`/pro-flow/${FlowType.PRO_LONGEVITY_PANEL}/${Object.values(Step)[1]}/${response._id}`)
        },
      },

      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        onNextStep: async (patient) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType
          return phlebType ? Step.NAME : Step.INTEREST
        },
        title: `Where is your client located?`,
        description: `The phlebotomist will meet your client here to draw their blood.`,
        field: {
          name: 'location'
        },
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.EMAIL,
        buildUser: true,
        title: `What is your client's full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return flow?.user?.firstName && flow?.user?.lastName
        }
      },
      [Step.EMAIL]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s email address?`,
        field: {
          name: 'email',
          placeholder: 'Type email address here...',
          email: true,
          required: true,
          uniqueError: <>
            <WarningFilled />&nbsp; Email already taken.
            &nbsp;
            <a className="error-link" onClick={onChat}>Message us</a> to add this client to your account.
          </>
        },
        skipIf: () => {
          return flow?.user?.email
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s phone number?`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type phone number here...',
          required: true,
          unique: true,
          uniqueError: <>
            <WarningFilled />&nbsp; Phone number already taken.
            &nbsp;
            <a className="error-link" onClick={onChat}>Message us</a> to add this client to your account.
          </>
        },
        skipIf: () => {
          return flow?.user?.phone
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return flow?.user?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.DRAW,
        buildUser: true,
        title: `What's ${flow?.user?.gender === Gender.MALE ? 'his' : 'her'} date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return flow?.user?.dob
        }
      },
      [Step.INTEREST]: {
        type: StepType.STATIC,
        buildUser: true,
        title: `Unfortunately, we don't service this area yet...`,
        description: <>If you would like us to find a lab close to {flow?.user.firstName} for {flow?.user?.gender === Gender.MALE ? 'his' : 'her'} blood draw, email us at <a href="mailto:concierge@instalab.com">concierge@instalab.com</a>.</>,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
      },
      [Step.DRAW]: {
        type: StepType.PRODUCT_SELECT,
        title: `Select your blood draw option.`,
        description: `Pricing includes all testing, reporting, and services.`,
        buildFlow: true,
        onNextStep: (patient, flow) => {
          if (flow?.prefersMobileDraw) {
            return Step.PAY;
          }
          else return Step.PSC;
        },
        field: (() => {

          const patient = flow?.user
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType;
          const noLabVisit = ['AZ','HI','NJ','RI'].includes(patient?.location?.state)
          const hasCredit = patient?.credits?.includes(ProductTypeCode.LONGEVITY_PANEL)
          const hasMobileDraw = patient?.credits?.includes(ProductTypeCode.MOBILE_BLOOD_DRAW)

          const mobileDrawCost = hasMobileDraw ? 0 : ProductHelper.getProviderCost(bloodDraw, instalabMembership, currentUser)

          const mobileCost = hasCredit ? mobileDrawCost : mobileDrawCost + ProductHelper.getProviderCost(productType, instalabMembership, currentUser)
          const labCost = hasCredit ? 0 : ProductHelper.getProviderCost(productType, instalabMembership, currentUser)

          const memberMobileCost = hasCredit ? mobileCost : mobileDrawCost + ProductHelper.getProviderCost(productType, instalabMembership, currentUser)
          const memberLabCost = hasCredit ? 0 : ProductHelper.getProviderCost(productType, instalabMembership, currentUser)

          return {
            name: 'prefersMobileDraw',
            required: true,
            options: [
              {
                title: "Lab Visit",
                value: false,
                description: "Visit a nearby patient service center (select location next).",
                price: instalabMembership ? memberLabCost : labCost,
                disabled: noLabVisit,
                tag: noLabVisit && "Unavailable in your state"

              },             
              {
                title: "At-Home Draw",
                value: true,
                description: "A licensed phlebotomist will come to your home or office.",
                price: instalabMembership ? memberMobileCost : mobileCost,
                disabled: phlebType === PhlebType.FACILITY ,
                tag: phlebType === PhlebType.FACILITY && "Unavailable in your area"
              },             
          ] 
        }})(),
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.MOBILE_BLOOD_DRAW].includes(product.productType.code)).length > 0
        }

      },
      [Step.PSC]: {
        type: StepType.FACILITY,
        nextStep: Step.PAY,
        buildFlow: true,
        multiple: false,
        productTypeCode: ProductTypeCode.LONGEVITY_PANEL,
        title: `Select your preferred patient service center.`,
        description: `This is where you'll get your blood drawn.`,
        field: {
          name: 'facility',
          required: true,
        },
      },
      [Step.PAY]: {
        type: StepType.PAY,
        onNextStep: (patient) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType

          if (flow?.prefersMobileDraw) {
            if (phlebType === PhlebType.INSTALAB) {
              return Step.SCHEDULE
            }
              return Step.SHIPPING
          }
          return Step.SCHEDULE
        },
        addProduct: true,
        productType: (() => { 
          const isMobile = flow?.prefersMobileDraw
          return isMobile ? [ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.MOBILE_BLOOD_DRAW] : ProductTypeCode.LONGEVITY_PANEL  
        })(),        
        title: (() => {

          const isMobile = flow?.prefersMobileDraw;

          const mobileDrawCost = isMobile ?  bloodDraw?.cost : 0
          const originalPrice = productType?.cost + mobileDrawCost

          const proMobileDrawCost = isMobile ? ProductHelper.getProviderCost(bloodDraw, instalabMembership, currentUser) : 0;
          const proPrice = ProductHelper.getProviderCost(productType, instalabMembership, currentUser) + proMobileDrawCost;

          return (currentUser?.role === Role.PROVIDER || instalabMembership) ? 
          <>Pay <span className="true-price">${proPrice}</span> <span className="original-price">${originalPrice}</span> for the {productType?.title}.</> :
          <>Pay <span className="true-price">${originalPrice}</span> for the {productType?.title}.</>;
        })(),
        description: payDescription,

      },
      [Step.SHIPPING]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.SCHEDULE,
        title: `Where should we ship your client's blood test kit?`,
        description: `${flow?.user?.firstName} will receive a test kit in the mail containing all the supplies needed for our phlebotomist to complete ${flow?.user?.gender === Gender.MALE ? 'his' : 'her'} blood draw appointment.`,
        field: {
          name: 'shippingLocation'
        },
      },
      [Step.SCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addAppointment: true,
        title: `Schedule your client's blood draw appointment.`,
        description: (() => { 
          const isMobile = flow?.prefersMobileDraw 
          return isMobile && (flow?.appointment?.phlebType  || flow?.user?.phlebType) === PhlebType.GETLABS ? 
              `A phlebotomist from our partner (Getlabs) will arrive during the 2-hour time window you select. The appointment takes about 10 minutes. You must fast 8 hours before your appointment. Water and black coffee are fine. ` :
              `The appointment takes about 10 minutes. You must fast 8 hours beforehand (water and black coffee are fine).`
            })()
      },
      [Step.APPOINTMENT_CONFIRMATION]: {
        type: StepType.STATIC,
        title: `Your client's blood draw appointment is confirmed!`,
        description: flow?.prefersMobileDraw && (flow?.appointment?.phlebType  || flow?.user?.phlebType) === PhlebType.GETLABS ? 
              <>We have sent a text confirmation and calendar invite to {flow?.user?.firstName} to confirm {flow?.user?.gender === Gender.MALE ? 'his' : 'her'} appointment. A test kit will be mailed with all the supplies needed to complete {flow?.user?.gender === Gender.MALE ? 'his' : 'her'} draw. A phlebotomist from our partner (Getlabs) will call or text {flow?.user?.gender === Gender.MALE ? 'him' : 'her'} upon arrival on the day of the appointment.</>  :
                        <>We have sent a text confirmation and calendar invite to {flow?.user?.firstName} to confirm {flow?.user?.gender === Gender.MALE ? 'his' : 'her'} appointment.</>,
        buttonText: 'Back to Patient Profile',
        path: `/patients/${flow?.user?._id}?key=Appointments`,
        content: <ConfirmAppointment flow={flow} />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>
    {productType && (
      <PanelModal
        panel={panels[productType.code]}
        title={panelTitles[productType.code]}
        open={openPanel}
        setOpen={setOpenPanel}
      />
    )}
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      startIndex={1}
    />
  </>
}