import React, { useState, useEffect } from 'react';
import { Input } from 'antd'; // Assuming you are using Ant Design for UI components

// Helper function to format the phone number
const formatPhoneNumber = (value) => {
  const cleaned = ('' + value).replace(/\D/g, '').slice(0, 10);
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return `${match[1] ? '(' + match[1] : ''}${match[2] ? ') ' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
  }
  return value;
};

// Reusable PhoneInput component
const PhoneInput = ({ value = '', onChange, ...rest }) => {
  const [inputValue, setInputValue] = useState(formatPhoneNumber(value));

  const handleChange = (e) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    setInputValue(formattedValue);
    onChange && onChange(formattedValue); // Call the onChange prop if provided
  };

  // Update local state when external value changes
  useEffect(() => {
    setInputValue(formatPhoneNumber(value));
  }, [value]);

  return (
    <Input
      value={inputValue}
      onChange={handleChange}
      placeholder="(XXX) XXX-XXXX"
      inputMode="decimal"
      {...rest} // Spread the rest props to allow customization
    />
  );
};

export default PhoneInput;