import RiskLevel from './riskLevel.enum.js'

const ReadableRiskLevel = Object.freeze({
  [RiskLevel.PEAK]: "peak health",
  [RiskLevel.OPTIMAL]: "optimal",
  [RiskLevel.ABNORMAL]: "at risk",
  [RiskLevel.MODERATE]: "borderline",
  [RiskLevel.HIGH]: "at risk",
  [RiskLevel.UNKNOWN]: "unknown",
});

export default ReadableRiskLevel;
