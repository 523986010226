import { Outlet } from 'react-router-dom';
import { Header } from '../header/header.component';

const ProLayout = () => {
  return (
    <>
      <Header 
        isPro={true}
        hasMobile={false}
      />
      <Outlet />
    </>
  )
};

export default ProLayout