import { Form, Typography, Row, Col, Button } from "antd"
import { CheckCircleFilled, CreditCardFilled, CheckOutlined } from '@ant-design/icons'
import { useContext } from "react"
import { UserContext } from "../../../../contexts/user.context"
import UserHelper from "../../../../helpers/user.helper"
import "./upsell.scss"

const { Text } = Typography

export const Upsell = ({
  step,
  onSubmit,
  form,
  isSubmitting,
  onNextStep
}) => {

  const { currentUser } = useContext(UserContext)
  const hasCredit = UserHelper.hasCredit(currentUser, step?.upsellProductType)

  return (
    <div className="upsell">
      <Form
        form={form}
        className="upsell-form"
      > 
        <div className="point-list">
          {step?.points?.map((point, pointIndex) => (
            <div 
              key={`point-${pointIndex}`}
              className="point-item"
            >
              <Row>
                <Col className="point-col-1">
                  <CheckOutlined />
                </Col>
                <Col className="point-col-2">
                  <Text className="point-item-text">
                    {point}
                  </Text>
                </Col>
              </Row>
            </div>
          ))}
        </div>

        {step?.hasPurchased ? <>
          <div className="paid-status">
            <CreditCardFilled /> Your payment is confirmed.
          </div>

          <Button
            type='primary'
            className="activate-btn"
            onClick={onNextStep}
          >
            Continue <CheckOutlined />
          </Button>
        </> : 
        hasCredit ?
        <>
          <div className="paid-status">
          <CheckCircleFilled /> This is free, because you have a credit.
          </div>
          <>
            <Button
              type='primary'
              className="activate-btn"
              loading={isSubmitting}
              onClick={() => onSubmit()}
            >
              {step?.buttonText} <CheckOutlined />
            </Button>

            <Button
              type='default'
              className="skip-btn"
              onClick={onNextStep}
            >
              No thanks
            </Button>
          </>
        </>
        :
        (
          <>
            <Button
              type='primary'
              className="activate-btn"
              loading={isSubmitting}
              onClick={() => onSubmit()}
            >
              {step?.buttonText} <CheckOutlined />
            </Button>

            <Button
              type='default'
              className="skip-btn"
              onClick={onNextStep}
            >
              No thanks
            </Button>
          </>
        )}
      </Form>
    </div>
  )
}