import ProductTypeCode from "../../enums/productTypeCode.enum"
import Panels from "../panels/panels.component.js"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import FlowType from "../../enums/flowType.enum"
import { GiftOutlined } from "@ant-design/icons"

export const ConfirmGift = () => {
  const navigate = useNavigate()
  const productTypeCodes = [
    ProductTypeCode.HEART_HEALTH_TEST_KIT,
    ProductTypeCode.LONGEVITY_PANEL,
    ProductTypeCode.GALLERI,
    ProductTypeCode.WATCHPAT_ONE
  ]
  return (
    <div className="confirm-gift">
      <Button
        type="primary"
        icon={<GiftOutlined />}
        className="question-btn"
        onClick={() => navigate(`/flow/${FlowType.GIFT}`)}
      >
        Send Another Gift
      </Button>


      <Panels productTypeCodes={productTypeCodes} />
      
    </div>
  )
}