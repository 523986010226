import { useEffect, useState } from 'react'
import { Form, Typography } from "antd"
import { Selector } from 'antd-mobile'
import { CheckOutlined, WarningFilled } from '@ant-design/icons'
import "./multipleSelect.scss"
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'
import _ from 'lodash'

const { Item } = Form
const { Text } = Typography

export const MultipleSelect = ({
  step,
  onSubmit,
  form,
  isSubmitting,
}) => {
  const [rules, setRules] = useState([])
  const letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
  
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);


  useEffect(() => {
    fetchRules()
  }, [step])

  const fetchRules = () => {
    if (!step) return
    if (step.field.required) {
      setRules([{
        required: true,
        message: <><WarningFilled />&nbsp; Select at least one option</>,
      }])
    } else {
      setRules([])
    }
  }


  return (
    <div className="multiple-select">
      <Form
        form={form}
        className="multiple-select-form"
      >
        <Item 
          key={`field-${step.field.name}`}
          name={step.field.name}
          className="multiple-select-item"
          validateTrigger='onSubmit'
          rules={rules}
        >
          <Selector 
            options={step.field?.options?.map(({ label, value }, optionIndex) => {
              return {
                value,
                label: (
                  <div className="multiple-select-label">
                    <div className="multiple-select-abbr">
                      {letters[optionIndex]}
                    </div>

                    <Text className="multiple-select-text">
                      {label}
                    </Text>

                    <CheckOutlined 
                      className="multiple-select-check"
                    />
                  </div>
                ),
              }
            })}
            className="multiple-select-field"
            multiple
          />
        </Item>
        
        <FlowStepFooter 
          onSubmit={() => onSubmit()} 
          isSubmitting={isSubmitting}
        />
      </Form>
    </div>
  )
}