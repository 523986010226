import React, { useContext, useEffect } from 'react';
import './feedback.scss';
import EventCode from "../../enums/eventCode.enum";
import PosthogHelper from '../../helpers/posthog.helper';
import { FilloutStandardEmbed } from "@fillout/react";
import { UserContext } from '../../contexts/user.context';


export const Feedback = () => {
  const { currentUser } = useContext(UserContext)

  useEffect(() => {
    document.title = 'Feedback'
    document.description = 'Thank you for taking the time to give us feedback! It will help inform the future of Instalab.'
  }, [])

  useEffect(() => {
    trackPosthog()
  }, [currentUser])

  const trackPosthog = () => {
    PosthogHelper.track(currentUser, EventCode.VISIT_FEEDBACK)
  }

  return (
  <div className="gift">
    <FilloutStandardEmbed filloutId="pgJUR3SkaNus" dynamicResize/>
  </div>)
}