import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import ReportHelper from "../../../helpers/report.helper";
import { TestItemList } from "../testItemLlist/testItemList.component";
import ReportSection from "../../../enums/reportSection.enum"
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import "./hormoneCheck.scss";

const { Paragraph } = Typography;

export const HormoneCheck = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [hormoneTests, setHormoneTests] = useState()

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report]);

  useEffect(() => {
    setHormoneTests(getHormoneTests())
  }, [report, tests])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])

  const getHormoneTests = () => {
    if (!report || !tests) return null
    return ReportHelper.getSectionTests(report, ReportSection.HORMONE_CHECK, tests)
  }

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report) return null;

    return (
      <>
        Your <span>FSH</span> and <span>LH</span> continue to be {" "}
        <span>elevated</span> and <span>increasing</span>, despite your healthy testosterone levels.
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report) return null;

    return (
      <>
        In some cases, this could signify compensatory activity by the pituitary gland for a deficiency. Consulting a urologist (hormone specialist) is advised to identify the cause and decide on appropriate management.
      </>
    );
  };


  return (
    hormoneTests && <div className="free-t4-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">
        {description}
      </Paragraph>

      <TestItemList  
        report={report} 
        testIds={hormoneTests.map(({ _id }) => _id)} 
        tests={tests}
        results={results}
        user={user}
        reports={reports}
      />
    </div>
  );
};
