
import { Button, Typography } from 'antd'
import { LoadingOutlined, ArrowRightOutlined } from '@ant-design/icons'
import "./continueButton.scss";

const { Text } = Typography

export const ContinueButton = ({ isSubmitting, text='Continue', onClick }) => {
  return (
    <Button 
      htmlType='submit'
      type="primary" 
      loading={isSubmitting}
      onClick={onClick}
      className="continue-button"
    >
      <Text className="continue-button-text">
        {text}
      </Text>
      <LoadingOutlined spin className="loading-icon" />
      <ArrowRightOutlined className="arrow-icon" />
    </Button>
  )
}