import React, { useState, useEffect } from 'react'
import AppointmentStatus from '../../enums/appointmentStatus.enum';
import { Modal, Button, Space, notification, message } from 'antd'
import moment from 'moment-timezone'
import "./availabilityModal.scss"
import { getPhlebotomist, updatePhlebotomist } from '../../services/phlebotomist.service';
import { listAppointments } from '../../services/appointment.service';
import { AppointmentCalendar } from '../appointmentCalendar/appointmentCalendar.component';

const EventType = {
  BLOCK: 'block',
  APPOINTMENT: 'appointment',
}

const select = '_id blocks location'
const populate = []

export const AvailabilityModal = ({ open, setOpen, phlebotomistId }) => {
  const [notificationApi, notificationHolder] = notification.useNotification()
  const [appointments, setAppointments] = useState()
  const [events, setEvents] = useState([])
  const [phlebotomist, setPhlebotomist] = useState()
  const [isLoading, setIsLoading] = useState()
  const [filteredCount, setFilteredCount] = useState()

  useEffect(() => {
    if (phlebotomistId) {
      fetchPhlebotomist()
      fetchAppointments()
    }
  }, [phlebotomistId])

  useEffect(() => {
    if (phlebotomist) {
      fetchEvents()
    }
  }, [phlebotomist, appointments])

  const fetchAppointments = async () => {
    setIsLoading(true)
    let params = {
      select: '_id start patients location',
      filter: {
        phlebotomist: phlebotomistId,
        status: {
          $in: [
            AppointmentStatus.PENDING,
            AppointmentStatus.COLLECTED,
            AppointmentStatus.COMPLETE,
            AppointmentStatus.CONFIRMED,
          ]
        }
      },
      populate: [{
        path: 'patients',
        select: 'firstName lastName'
      }],
      sort: '-start'
    }
    const fetchedAppointments = await listAppointments(params)
    setAppointments(fetchedAppointments)
    setFilteredCount(fetchedAppointments.length)
    setIsLoading(false)
  }

  const onCancel = () => {
    setOpen(false)
  }

  const openNotification = (blockId) => {
    const key = Date.now();
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => notificationApi.destroy()}>
          Cancel
        </Button>
        <Button type="primary" size="small" onClick={async () => {
          const updatedBlocks = phlebotomist.blocks?.filter(block => block._id !== blockId)
          setPhlebotomist(await onSave({ blocks: updatedBlocks }))
          notificationApi.destroy(key)}
        }>
          Remove
        </Button>
      </Space>
    );
    notificationApi.open({
      message: 'Remove block',
      description: 'Removing this block will open availability for blood draw appointments.',
      btn,
      key
    });
  };

  const fetchEvents = () => {
    const blockEvents = phlebotomist.blocks?.map(({ _id, start, end }) => {
      return {
        _id,
        type: EventType.BLOCK,
        start: moment(start).toDate(),
        end: moment(end).toDate(),
        title: 'block'
      }
    }) || []

    setEvents(blockEvents)
  }

  const fetchPhlebotomist = async () => {
    const phleb = await getPhlebotomist(phlebotomistId, {
      select,
      populate,
    })
    setPhlebotomist(phleb)
    const timeZoneId = phleb.location?.timeZoneId
    if (timeZoneId) {
      moment.tz.setDefault(timeZoneId)
    }
  }

  const onSave = async (params) => {
    try {
      const response = await updatePhlebotomist(phlebotomistId, {
        fields: params,
        select,
        populate
      })
      setPhlebotomist(response)
      message.success('Availability updated')
      return response
    } catch (err) {
      message.error('Failed to update availability')
    }
  }

  const onSelectEvent = ({ _id, type }) => {
    if (type === EventType.BLOCK) {
      openNotification(_id)
    }
  }

  const onEventUpdate = async ({ event, start, end }) => {
    const { _id, type } = event
    if (type === EventType.BLOCK) {
      const updatedBlocks = phlebotomist.blocks?.map(block => block._id === _id ? { start, end } : block)
      setPhlebotomist({
        ...phlebotomist,
        blocks: updatedBlocks
      })
      await onSave({ blocks: updatedBlocks })
    }
  }

  const onSelectSlot = async ({ start, end }) => {
    const block = { start, end }
    const updatedBlocks = [block, ...phlebotomist.blocks]
    setPhlebotomist({
      ...phlebotomist,
      blocks: updatedBlocks
    })
    await onSave({ blocks: updatedBlocks })
  }

  const calendarProps = {
    onSelectSlot: onSelectSlot,
    onEventResize: onEventUpdate,
    onEventDrop: onEventUpdate,
    onSelectEvent: onSelectEvent,
  }

  return (
    <div className="availability-modal">
      {notificationHolder}
      <Modal 
        open={open} 
        title='Availability'
        onCancel={onCancel}
        width={1000}
        okText='Save'
        footer={null}
      >
        <AppointmentCalendar
          appointments={appointments}
          customEvents={events}
          calendarProps={calendarProps}
        />
      </Modal>
    </div>
  )
}