import { Typography, Table } from "antd";
import classNames from "classnames";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import { BlogData } from "../../../data/blog.data";
import { useParams } from "react-router-dom";
const { Paragraph } = Typography

export const CumulativeExposurePost = () => {
  const { blogId } = useParams()
  return (
    <div className={classNames("blog-post", "cumulative-exposure-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          Why a More Aggressive Approach to Lowering Cholesterol Is Necessary
        </Paragraph>
        <Paragraph className="blog-subheading">
          Most doctors will tell you a LDL Cholesterol of 100 mg/dL is “good”, leading many of us to believe that we have eliminated the risk of this disease if we hit this number. But this one-size-fits-all approach grossly oversimplifies the complexity of cardiovascular risk and leaves many of us unknowingly at high risk for heart-related events.
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          The truth about LDL: lower is better
        </Paragraph>
        <Paragraph className="blog-description">
          Extensive research, including findings from the <i>Cholesterol Treatment Trialists</i> and major trials like <i>JUPITER</i>, <i>FOURIER</i>, and <i>ODYSSEY Outcomes</i>, have unequivocally demonstrated that consistently lowering LDL-C levels continuously decreases your risk for heart-related issues. Moreover, this is associated with additional health benefits.
        </Paragraph>
        <Paragraph className="blog-description">
          Specifically, lowering LDL-C to below 70 mg/dL is linked to a substantial reduction in cardiovascular disease risk, along with other improved health outcomes such as reduced inflammation, enhanced blood vessel (endothelial) function, and a decreased risk for chronic diseases like Alzheimer's and Parkinson's disease. 
        </Paragraph>
        <Paragraph className="blog-description">
          The research further indicates that achieving very low LDL-C levels, below 20 mg/dL or even under 10 mg/dL, brings about these benefits more significantly. And many studies like the <i>EBBINGHAUS trial</i> have confirmed the safety of very low LDL-C levels, highlighting the benefits of aggressively lowering LDL-C without adverse effects.
        </Paragraph>

        <Paragraph className="blog-title">
          Beyond a single measurement: the concept of "mg-years"
        </Paragraph>
        <Paragraph className="blog-description">
          The risk associated with LDL-C is not merely a function of high levels at any specific time; it's about how much and how long you have had these elevated levels. This is because atherosclerosis, the buildup of plaques in the artery walls, doesn't emerge overnight. Rather it's a process whose speed of development is influenced by the duration and intensity of your blood vessels' exposure to LDL particles.
        </Paragraph>
        <Paragraph className="blog-description">
          Enter the concept of "mg-years," which provides a nuanced way to evaluate risk by accounting for both the intensity and the duration of LDL exposure. Simply put, "mg-years" is a metric that multiplies your LDL-C level by the number of years you've lived with that level. Think of it like the odometer reading on a car; just as the total miles driven reflect the car's wear and tear over time, "mg-years" quantify the extent of exposure your arteries have to LDL-C.
        </Paragraph>
        <Paragraph className="blog-description">
          However, the connection between "mg-years'' and heart disease risk isn't linear – it's exponential (refer to Figure 1). This means that as your "mg-years" increase, your risk doesn't just incrementally rise; it skyrockets. For example, for otherwise healthy individuals, once you reach 5,000 mg-years, your risk of a heart attack is already at 1%. But as "mg-years" climb – to 6,250 and then 7,500 – your risk jumps to 2% and then 8%, respectively.
        </Paragraph>

        <Table
          className="blog-table"
          dataSource={[{
            exposure: '5,000',
            risk: '1%'
          }, {
            exposure: '6,250',
            risk: '2%'
          }, {
            exposure: '7,500',
            risk: '8%'
          }, {
            exposure: '10,000',
            risk: '16%'
          }, {
            exposure: '11,250',
            risk: '32%'
          }, {
            exposure: '12,500',
            risk: '64%'
          }]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column title="Cumulative exposure mg-years" dataIndex="exposure" />
          <Table.Column title="% heart attack risk" dataIndex="risk" />
        </Table>
        <Paragraph className="blog-description">
          <i>Figure 1: Cumulative LDL-C exposure risks</i>
        </Paragraph>

        <Paragraph className="blog-description">
          Let's explore the real-world consequences with a practical example (refer to Figure 2): Imagine you have an LDL level of 125 mg/dL consistently over your lifetime. By the age of 80, this equates to an exposure of 10,000 mg-years, which is linked to a greater than 16% risk of experiencing a heart attack. And this assumes no additional risk factors that can bring down the threshold, which would further magnify the risk. Is that a gamble you're willing to take?
        </Paragraph>

        <img 
          src="https://lh7-us.googleusercontent.com/DlQlauAbDwWbN62gETb2hoHw9iEPMAYeFWiC9WTQcQrI7EawXSEc43eAgJ-7u0yWSfUljJ3skDT5Kv66cV1PjB3lfoXy5DGMPXggP8esZ6Z7_6opsGLycK_q3BdufK1kjbQCGsM2k5mYDuSy3GGOYYg" 
          alt="Effect of Cumulative Exposure on cardiovascular disease risk" 
          className="blog-image"
        />

        <Paragraph className="blog-description">
        <i>Figure 2: Effect of Cumulative Exposure on cardiovascular disease risk</i>
        </Paragraph>

        <Paragraph className="blog-description">
          On the other hand, to reduce your heart attack risk to below 1% by the time you reach 80, you would need to maintain an LDL-C level of around 62 mg/dL throughout your life. This highlights the critical need for a lifelong approach to cholesterol management, rather than sporadic efforts. Determining your LDL-C target involves balancing the level of risk you are comfortable with as you grow older.
        </Paragraph>

        <Paragraph className="blog-title">
          The importance of intervention today
        </Paragraph>
        <Paragraph className="blog-description">
          This concept of cumulative exposure underscores the value of early and sustained intervention efforts to manage LDL cholesterol levels. By initiating cholesterol reduction early in life and diligently maintaining lower levels, you can dramatically decrease lifetime LDL exposure. This proactive strategy can halt or even reverse the progression of atherosclerosis, significantly lowering the risk of cardiovascular events.
        </Paragraph>
        <Paragraph className="blog-description">
          At Instalab, we are challenging the status quo by advocating for a more aggressive, proactive approach to cholesterol management. Our stance is based on this understanding that early and sustained intervention can drastically reduce cardiovascular risk over a lifetime. And if diet and lifestyle can't get you there, medication should be prioritized.
        </Paragraph>
        <Paragraph className="blog-description">
          So the question then becomes, are you willing to accept the status quo, or are you ready to take control of your health with a more informed, proactive stance?
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              O'Keefe, James H Jr et al. "Optimal low-density lipoprotein is 50 to 70 mg/dl: lower is better and physiologically normal." Journal of the American College of Cardiology vol. 43,11 (2004): 2142-6. <a onClick={() => window.open('https://www.sciencedirect.com/science/article/pii/S0735109704007168?via%3Dihub', '_blank')}>https://www.sciencedirect.com/science/article/pii/S0735109704007168?via%3Dihub</a>
            </li>
            <li>
              Sabatine, Marc S et al. "Efficacy and Safety of Further Lowering of Low-Density Lipoprotein Cholesterol in Patients Starting With Very Low Levels: A Meta-analysis." JAMA cardiology vol. 3,9 (2018): 823-828. <a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/30073316/', '_blank')}>https://pubmed.ncbi.nlm.nih.gov/30073316/</a>
            </li>
            <li>
              Ference, Brian A et al. "Impact of Lipids on Cardiovascular Health: JACC Health Promotion Series." Journal of the American College of Cardiology vol. 72,10 (2018): 1141-1156. <a onClick={() => window.open('https://www.sciencedirect.com/science/article/pii/S0735109718353798?via%3Dihub', '_blank')}>https://www.sciencedirect.com/science/article/pii/S0735109718353798?via%3Dihub</a>
            </li>
            <li>
              Bandyopadhyay, Dhrubajyoti et al. "Safety and Efficacy of Extremely Low LDL-Cholesterol Levels and Its Prospects in Hyperlipidemia Management." Journal of lipids vol. 2018 8598054. 23 Apr. 2018 <a onClick={() => window.open('https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5937425/', '_blank')}>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5937425/</a>
            </li>
            <li>
              Benn, Marianne et al. "Low LDL cholesterol, PCSK9 and HMGCR genetic variation, and risk of Alzheimer's disease and Parkinson's disease: Mendelian randomisation study." BMJ (Clinical research ed.) vol. 357 j1648. 24 Apr. 2017 <a onClick={() => window.open('https://www.bmj.com/content/357/bmj.j1648', '_blank')}>https://www.bmj.com/content/357/bmj.j1648</a>
            </li>
            <li>
              Goldstein, Larry B et al. "Aggressive LDL-C Lowering and the Brain: Impact on Risk for Dementia and Hemorrhagic Stroke: A Scientific Statement From the American Heart Association." Arteriosclerosis, thrombosis, and vascular biology vol. 43,10 (2023): e404-e442. <a onClick={() => window.open('https://www.ahajournals.org/doi/full/10.1161/ATV.0000000000000164#d24558847e1', '_blank')}>https://www.ahajournals.org/doi/full/10.1161/ATV.0000000000000164#d24558847e1</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};