import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Typography, Tooltip, message, Modal } from 'antd'
import { addAppointmentCharge, addAppointmentPaymentLink, listAppointments, removeAppointment, cancelAppointment } from "../../services/appointment.service"  
import { CalendarOutlined, CreditCardOutlined, CopyOutlined, LoadingOutlined, EnvironmentOutlined, DeleteOutlined, UserAddOutlined, StopOutlined } from '@ant-design/icons'
import { PageHeader } from '../pageHeader/pageHeader.component';
import ObjectType from '../../enums/objectType.enum';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import "./adminAppointmentsTable.scss";
import AppointmentStatus from '../../enums/appointmentStatus.enum';
import classNames from 'classnames';
import UrlHelper from '../../helpers/url.helper.js';
import { ModalForm } from '../modalForm/modalForm.component.js';
import FlowType from '../../enums/flowType.enum.js';
import { useNavigate, useParams } from 'react-router-dom';
import Role from '../../enums/role.enum.js';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { UserContext } from '../../contexts/user.context.js';
import ChargeStatus from '../../enums/chargeStatus.enum.js';
import { AdminAppointmentForm } from '../adminAppointmentForm/adminAppointmentForm.component.js';
import { listProductTypes} from '../../services/productType.service.js';
import ProductTypeCode from '../../enums/productTypeCode.enum.js';
dayjs.extend(utc)
dayjs.extend(timezone)

const { Text } = Typography

const select = 'status patients location phlebotomist facility start flow'
const populate = [{
  path: 'patients',
  select: '_id firstName lastName'
}, {
  path: 'phlebotomist',
  select: 'firstName'
},{
  path: 'facility',
  select: 'name location fax phone  website'
}, {
  path: 'orders',
  select: 'panel addOns'
}, {
  path: 'products',
  select: '_id',
  populate: [{
    path: 'charge',
    select: 'status'
  },
  {
    path: 'productType'
  },
  ]},
  {
    path: 'flow',
    select: '_id type'
  }]

export const AdminAppointmentsTable = ({ hasPatient=false }) => {
  const { patientId } = useParams()
  const navigate = useNavigate()
  const [appointments, setAppointments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [appointmentId, setAppointmentId] = useState()
  const [isCharging, setIsCharging] = useState()
  const [openModal, setOpenModal] = useState()
  const [isLocationModalOpen, setIsLocationModalOpen] = useState()
  const [fields, setFields] = useState()
  const { currentUser, setCounts } = useContext(UserContext)
  const [openAdminAppointmentForm, setOpenAdminAppointmentForm] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const [allProductTypes, setAllProductTypes] = useState();

  const [canceling, setCanceling] = useState([])
  const cancelingRef = useRef(null)
  cancelingRef.current = canceling


  useEffect(() => {
    fetchAppointments()
    fetchProductTypes()
  }, [hasPatient, patientId])

  const fetchProductTypes = async () => {
    setAllProductTypes(await listProductTypes())
  }

  const fetchAppointments = async () => {
    setIsLoading(true)
    if (hasPatient && !patientId) return

    let params = {
      select,
      filter: {
        // status: {
        //   $in: [
        //       AppointmentStatus.PENDING,
        //       AppointmentStatus.COLLECTED,
        //       AppointmentStatus.COMPLETE,
        //       AppointmentStatus.CONFIRMED,

        //   ]
        // },
        products: { $exists: true, $not: { $size: 0 } }
      },
      populate,
      sort: '-start'
    }

    if (hasPatient) {
      params.filter = {
        patients: {
          $in: [patientId]
        },
        products: { $exists: true, $not: { $size: 0 } }
      }
    }
    const fetchedAppointments = await listAppointments(params)

    // Sort appointments so that PENDING comes first
    const sortedAppointments = fetchedAppointments.sort((a, b) => {
      if (a.status === AppointmentStatus.PENDING && b.status !== AppointmentStatus.PENDING) {
        return -1; // a should come before b
      }
      if (a.status !== AppointmentStatus.PENDING && b.status === AppointmentStatus.PENDING) {
        return 1; // b should come before a
      }
      return new Date(b.start) - new Date(a.start); // Otherwise, sort by start date
    });

    setAppointments(sortedAppointments);
    setFilteredCount(sortedAppointments.length);
    setIsLoading(false);
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this appointment?',
      content: 'Deleting this appointment cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removeAppointment(_id);
          setAppointments(appointments.filter(appointment => appointment._id !== _id));
          message.info('Appointment removed');
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              draws: cachedCounts.draws - 1
            }
          })
          setFilteredCount(cachedFilterCount => cachedFilterCount-1)
        } catch (err) {
          message.error('Failed to remove appointment');
        }
      }
    });
  }

  const onChargeAppointment = async (_id) => {
    setIsCharging(_id)
    try {
      const appointment = await addAppointmentCharge(_id, {
        select,
        populate
      })
      setAppointments(appointments.map(a => a._id === appointment._id ? appointment : a))
      message.success('Card charged')
    } catch (err) {
      message.error('Failed to charge card')
    }
    setIsCharging(null)
  }

  const onCancel = async (_id) => {
    setCanceling([
      ...cancelingRef.current,
      _id
    ])
    try {
      const appointment = await cancelAppointment(_id)
      setAppointments(appointments.map(a => a._id === _id ? appointment : a))
      message.info('Appointment canceled.')
    } catch (err) {
      message.error('Failed to cancel appointment')
    }
    setCanceling(cancelingRef.current.filter(cancelingId => cancelingId !== _id))
  }

  const getActionItems = (appointment) => {
    const { _id, flow, start } = appointment
    let menuItems = [];

    const isFuture = ([AppointmentStatus.CONFIRMED].includes(appointment.status) && new Date(start) > new Date()) || appointment.status === AppointmentStatus.PENDING

    const isToday = (start) => {
      const today = new Date();
      const startDate = new Date(start);
  
      return startDate.getDate() === today.getDate() &&
              startDate.getMonth() === today.getMonth() &&
              startDate.getFullYear() === today.getFullYear();
    }

    // Function to show the confirmation modal
    const showCancelConfirm = (_id) => {
      let content = "Your account will be credited back after you do this so you can schedule another time."

      if (isToday(start)) {
        content = <>Unfortunately canceling the same day will result in a <b>$50 fee</b> due to the impact on our scheduling and business operations. We appreciate your understanding in this. <br/><br/>Your account will be credited with a blood draw after canceling and can be rescheuled.</>
      }

      Modal.confirm({
        title: 'Are you sure you want to cancel appointment?',
        content: content,
        okText: "OK, cancel",
        onOk() {
          onCancel(_id);
        },
        onCancel() {
          console.log('Cancel operation was aborted');
        },
      });

    };
        
    
    if (isFuture) {

      menuItems.push({  
        key: 'invite',
        label: (
          <Button onClick={() => {
            navigate(`/pro-flow/${flow.type}/invite/${flow._id}`)
          }}>
            <UserAddOutlined /> Add Others
          </Button>
        )
      }) 

      if (currentUser?.role === Role.PROVIDER) {
        menuItems.push({  
          key: 'reschedule',
          label: (
            <Button onClick={() => {
              navigate(`/pro-flow/${flow.type}/reschedule/${flow._id}`)
            }}>
              <CalendarOutlined /> Reschedule
            </Button>
          )
        })

        // TODO: need to account for credits back to payer 
        // menuItems.push(
        //   {
        //     type: 'divider'
        //   },
        //   {  
        //   key: 'cancel',
        //   label: (
        //     <Button
        //       onClick={() => showCancelConfirm(_id)}
        //       className="remove-item"
        //     >
        //       <StopOutlined /> Cancel
        //     </Button>
        //   )
        // })  
      }
    }
    if (currentUser?.role === Role.ADMIN || (currentUser?.role === Role.STAFF && ![FlowType.LONGEVITY_TEST, FlowType.ATHLETE_TEST, FlowType.PRO_ATHLETE_PANEL, FlowType.PRO_LONGEVITY_PANEL].includes(flow?.type))) {
      menuItems.push({  
        key: 'edit',
        label: (
          <Button onClick={() => {
            setAppointmentId(_id);
            setOpenAdminAppointmentForm(true)
          }}>
            <CalendarOutlined /> Edit Appointment
          </Button>
        )
      }) 
    }
  
    
    if (appointment.products?.some(product => !product.charge || product.charge.status === ChargeStatus.PENDING)) {
      menuItems.push({  
        key: 'payment-link',
        label: (
          <Button onClick={async () => {
            const response = await addAppointmentPaymentLink(_id, {
              seelct: '_id'
            })
            navigator.clipboard.writeText(response.url)
            message.info('Copied payment link')
          }}>
            <CopyOutlined /> Copy Payment Link
          </Button>
        )
      })
    }

    if (currentUser?.role === Role.ADMIN || currentUser?.role === Role.STAFF) {
      // menuItems.push({
      //   key: 'status',
      //   label: (
      //     <Button onClick={() => {
      //       setFields([{
      //         label: 'Status',
      //         name: 'status',
      //         fieldType: FieldType.SELECT,
      //         rules: [RuleHelper.isRequired],
      //         options: Object.values(AppointmentStatus).map(value => {
      //           return {
      //             label: value,
      //             value,
      //           }
      //         })
      //       }])
      //       setAppointmentId(_id)
      //       setOpenModal(true)
      //     }}>
      //       <EditOutlined /> Change Status
      //     </Button>
      //   )
      // })

      switch (appointment.status) {
        case AppointmentStatus.PENDING:
        case AppointmentStatus.CONFIRMED:
        case AppointmentStatus.CANCELED:
          menuItems.push({
            key: 'location',
            label: (
              <Button onClick={() => {
                setAppointmentId(_id)
                setIsLocationModalOpen(true)
              }}>
                <EnvironmentOutlined /> Set Location
              </Button>
            )
          })

          if (currentUser?.role === Role.ADMIN) {
            menuItems.push({
              type: 'divider'
            })
            menuItems.push({
              key: '5',
              label: (
                <Button
                  onClick={() => onRemove(_id)}
                  className="remove-item"
                >
                  <DeleteOutlined /> Remove
                </Button>
              )
            })
          }
          break
        case AppointmentStatus.COLLECTED:
          if (currentUser?.role === Role.ADMIN) {
            menuItems.push({
              key: 'charge',
              label: (
                <Button onClick={(e) => {
                  e.stopPropagation()
                  if (isCharging !== _id) {
                    onChargeAppointment(_id)
                  }
                }}>
                  {isCharging === _id ? (
                    <Text className="charging-text">
                      <LoadingOutlined style={{marginRight:5}} /> Charging...
                    </Text>
                  ) : <>
                    <CreditCardOutlined style={{marginRight:5}} /> Charge Card
                  </>}
                </Button>
              )
            })
          }
          break;

        default:
            break;
      }
    }
  
    return menuItems;
  }

  const getCustomFilter = (appointment, value) => {
    return {
      patients: () => appointment.patients?.some(patient => `${patient.firstName} ${patient.lastName}`?.toLowerCase().includes(value?.toLowerCase())),
      phlebotomist: () => appointment.phlebotomist && appointment.phlebotomist.firstName.toLowerCase()?.includes(value?.toLowerCase()),
      location: () => appointment.location?.city?.toLowerCase().includes(value?.toLowerCase()),
      products: () => appointment.products?.some(product => product.productType?.title.toLowerCase()?.includes(value?.toLowerCase())) 

    }
  }

  const onAppointmentUpdate = updatedAppointment => {
    if (appointments.some(({ _id }) => _id === updatedAppointment._id)) {
      setAppointments(appointments?.map(a => a._id === updatedAppointment._id ? updatedAppointment : a))
    } else {
      setAppointments([
        updatedAppointment,
        ...appointments,
      ])
    }
  }

  const onAddAppointment = async () => {
    switch (currentUser?.role) {
      case Role.ADMIN:
      case Role.STAFF:
        setOpenAdminAppointmentForm(true)
        break
      default:
        break
      // case Role.PROVIDER:
      //   openProFlow()
      //   break
    }
  }

  // const openProFlow = async () => {
  //   let params = {
  //     type: FlowType.PRO_DRAW
  //   }
    
  //   if (hasPatient) {
  //     params.user = patientId;
  //   }

  //   const response = await addProxyFlow(params)

  //   if (hasPatient) {
  //     navigate(`/pro-flow/${FlowType.PRO_DRAW}/${Object.values(ProDrawStep)[1]}/${response._id}`);
  //   }
    
  //   else {
  //     navigate(`/pro-flow/${FlowType.PRO_DRAW}/${Object.values(ProDrawStep)[0]}/${response._id}`);
  //   }
  // }

  // const handleModalClose = () => {
  //   setOpenProductModal(false);
  // };

  // const handleModalSuccess = () => {
  //   fetchAppointments();
  // };

  return allProductTypes && (
    <div className="admin-appointments-table">
      <ModalForm
        open={openModal}
        setOpen={setOpenModal}
        fields={fields}
        populate={populate}
        select={select}
        objectType={ObjectType.APPOINTMENT}
        objectId={appointmentId}
        onSuccess={onAppointmentUpdate}
      />

      {/* {selectedAppointment && (
        <UpdateProductsModal
          visible={openProductModal}
          appointment={selectedAppointment}
          onClose={handleModalClose}
          onSuccess={handleModalSuccess}
        />
      )} */}

      {(currentUser?.role === Role.ADMIN || currentUser?.role === Role.STAFF) && (
        <AdminAppointmentForm
          open={openAdminAppointmentForm}
          setOpen={setOpenAdminAppointmentForm}
          appointmentId={appointmentId}
          setAppointmentId={setAppointmentId}
          populate={populate}
          select={select}
          onSuccess={onAppointmentUpdate}
        />
      )}

      <AdminLocationModal
        objectId={appointmentId}
        objectType={ObjectType.APPOINTMENT}
        open={isLocationModalOpen}
        populate={populate}
        select={select}
        setOpen={setIsLocationModalOpen}
        onSuccess={onAppointmentUpdate}
      />

      <PageHeader
        title='Appointments'
        count={filteredCount}
        actions={ (currentUser?.role === Role.ADMIN || currentUser?.role === Role.STAFF) &&
          <Button
            type="primary"
            onClick={onAddAppointment}
          >
          + Schedule Appointment
          </Button>
        }
      />

      <FlexibleTable
        isLoading={isLoading}
        records={appointments}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[{
          title: 'Status',
          dataIndex: 'status',
          width: 110,
          render: status => <Text className={classNames(`${status}-status`, "appointment-status")}>{status}</Text>,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(AppointmentStatus)
        }, 
                
        !hasPatient && {
          title: 'Patient(s)',
          dataIndex: 'patients',
          filterDropdownType: FilterDropdownType.INPUT,
          render: (patients) => {
            return patients?.map((patient, index) => {
              return (
                <span key={patient._id}>
                  {index !== 0 && ', '} 
                    <a 
                      className="patient-name"
                      onClick={() => navigate(UrlHelper.getPatientProfile(patient._id, 'Appointments'))}
                    >
                      {patient.firstName} {patient.lastName}
                    </a>
                </span>
              )
            })
          }
        }, 
        {
          title: 'Test(s)',
          dataIndex: 'products',
          render: (products) => {
            const filteredProducts = products?.filter(product => product.productType?.code !== ProductTypeCode.MOBILE_DRAW && product.productType?.code !== ProductTypeCode.PRO_MOBILE_BLOOD_DRAW)
            return filteredProducts?.length > 0 ? filteredProducts?.map(product => product.productType?.title).join(', ') : 'N/A';
          },
          filterDropdownType: FilterDropdownType.INPUT, // Enable filtering by input
          onFilter: (value, appointment) => getCustomFilter(appointment, value).products() // Use custom filter for products
        },

        (currentUser?.role === Role.ADMIN || currentUser?.role === Role.STAFF) ? {
          title: 'Location',
          dataIndex: 'location',
          render: (location, {facility}) => {
            if (!location && !facility?.location) return null
            
            const { streetAddress, streetAddress2, city, state, postalCode } = facility?.location || location
            const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
            return <>
              {facility && facility.website &&<><a href={facility.website} target="_blank">{facility.name}</a><br/></>}
              {facility && !facility.website &&<>{facility.name}<br/></>}

              {fullAddress}
              {facility?.fax && <><br/>Fax: {facility?.fax}</>}
              {facility?.phone && <><br/>Phone: {facility?.phone}</>}
              </>
          },
          filterDropdownType: FilterDropdownType.INPUT
        }:  
        {
          title: 'Location',
          dataIndex: 'location',
          render: (location, {facility}) => {
            if (!location && !facility?.location) return null
            
            const { streetAddress, streetAddress2, city, state, postalCode } = facility?.location || location
            const fullAddress = `${facility ? facility.name + ': ' : ''}${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
            return <Tooltip title={fullAddress}>{city}, {state}</Tooltip>
          },
          filterDropdownType: FilterDropdownType.INPUT
        }, 
        {
          title: 'Phlebotomist',
          dataIndex: 'phlebotomist',
          render: phlebotomist => phlebotomist && phlebotomist.firstName,
          filterDropdownType: FilterDropdownType.INPUT
        }, 
        {
          title: 'Start',
          dataIndex: 'start',
          render: (start, { location }) => {
            if (!start) return 'TBD'
            start = dayjs(start).tz(location?.timeZoneId)
            return <>
              {start.format('M/D/YY')} &nbsp; {start.format('h:mm a z')}
            </>
          }
        }]}
      />
    </div>
  )
}