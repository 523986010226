import { Input, Form, DatePicker, Row, Col } from "antd"
import "./giftDetails.scss"
import { WarningFilled } from '@ant-design/icons'
import { FlowStepFooter } from "../../flowStep/flowStepFooter.component"
import { useState, useEffect, useRef, useContext } from "react"
import FormHelper from "../../helpers/form.helper"
import RuleHelper from "../../../../helpers/rule.helper"
import { useNavigate, useParams } from "react-router-dom"
import dayjs from 'dayjs'
import "../singleSelect/singleSelect.scss" // Import the single select styles
import { UserContext } from "../../../../contexts/user.context"
import { getProductTypeByCode } from "../../../../services/productType.service"

const { Item } = Form
const { TextArea } = Input;

export const GiftDetails = ({
  step,
  onSubmit,
  skeleton,
  form,
  isSubmitting,
  hasAttempt,
  flow,
}) => {
  const navigate = useNavigate()
  const [rules, setRules] = useState({})
  const { flowType } = useParams()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt
  const [productType, setProductType] = useState(null)
  const { currentUser } = useContext(UserContext)
  useEffect(() => {
    fetchRules()
    window.scrollTo(0, 0)
  }, [step])


  useEffect(() => {
    const fetchProductType = async () => {
      if (flow?.giftProductTypeCodes?.[0]) {
        const productType = await getProductTypeByCode(flow?.giftProductTypeCodes?.[0])
        setProductType(productType)
      }
    }
    fetchProductType()
  }, [flow])

  useEffect(() => {

    const keyDownHandler = event => {
      if (event.target.classList.contains('__PrivateStripeElement-input')) {
        return;
      }
      
      if (event.key === 'Enter' && event.target.tagName !== 'TEXTAREA') {
        event.preventDefault();
        onSubmit()
      }
    };
    
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
      form.resetFields();
    };
  }, []);


  useEffect(() => {
    // Define fields to check and set
    const fieldNames = [
      'giftActivationDate',
      'giftMessage',
      'giftRecipientEmail',
      'giftRecipientName',
      'giftSenderEmail',
      'giftSenderName'
    ];

    // Set each field value if it exists in flow
    fieldNames.forEach(fieldName => {
      if (flow?.[fieldName]) {
        // Convert date to dayjs object if it's the activation date field
        const value = fieldName === 'giftActivationDate' 
          ? dayjs(flow[fieldName])
          : flow[fieldName];
        
        form.setFieldValue(fieldName, value);
      }
    });
  }, [flow]);


  const fetchRules = () => {
    let r = {}

    defaultFields.forEach(group => {
      group.fields.forEach(field => {
        let fieldRules = []

        if (field.required) {
          if (field.isSelector) {
            fieldRules.push({ ...RuleHelper.isRequired, message: <><WarningFilled />&nbsp; Please select an option</> })
          } else {
            fieldRules.push(RuleHelper.isRequired)
          }
        }

        if (field.date) {
          fieldRules.push(RuleHelper.isDate)
        }

        if (field.email) {
          fieldRules.push(RuleHelper.isEmail) 
        }

        if (field.email && field.unique) {
          fieldRules.push(RuleHelper.isUniqueEmail({
            fieldName: field.name,
            navigate, 
            redirectUrl: `/flow/${flowType}/${Object.keys(skeleton)[0]}`,
            userId: flow?.user?._id,
            uniqueError: field.uniqueError
          }))
        }

        if (field.phone) {
          fieldRules.push(RuleHelper.isPhone)
        }

        if (field.phone && field.unique) {
          fieldRules.push(RuleHelper.isUniquePhone({
            fieldName: field.name,
            navigate, 
            redirectUrl: `/flow/${flowType}/${Object.keys(skeleton)[0]}`,
            userId: flow?.user?._id,
            uniqueError: field.uniqueError
          }))
        }

        r[field.name] = fieldRules
      })
    })
    setRules(r)
  }
  const getFieldType = field => {
    if (field.password) {
      return 'password'
    } else if (field.number) {
      return 'number'
    }
    return 'default'
  }

  const defaultFields = [

    {
      group: 'To',
      fields: [
        {
          name: 'giftRecipientName',
          placeholder: 'Recipient Name',
          required: true
        },
        {
          name: 'giftRecipientEmail',
          placeholder: 'Recipient Email',
          required: true,
          email: true
        },
        {
          name: 'giftMessage',
          placeholder: 'Personal message',
          isTextArea: true
        }
      ]
    },
    {
      group: 'From',
      fields: [
        {
          name: 'giftSenderName',
          placeholder: 'Sender Name',
          required: true
        },
        {
          name: 'giftSenderEmail',
          placeholder: 'Sender Email',
          required: true,
          email: true
        }
      ],
      shouldShow: !(currentUser?.firstName && currentUser?.lastName && currentUser?.email)
    },
    {
      group: 'Delivery Date',
      fields: [
        {
          name: 'giftActivationDate',
          placeholder: 'MM/DD/YYYY',
          required: true,
          isDatePicker: true,
          initialValue: dayjs().format('MM/DD/YYYY')
        }
      ]
    }
  ]

  const renderField = (field, isFirstField = false) => {


    if (field.isDatePicker) {
      return (
        <DatePicker
          placeholder={field.placeholder}
          className="multiple-input-field"
          format="MM/DD/YYYY"
          disabledDate={(current) => {
            // Only disable past dates
            return current && current < dayjs().startOf('day');
          }}
          showToday={true}
          allowClear={false}
          superNextIcon={null}  // Remove double right arrow (year jump)
          superPrevIcon={null}  // Remove double left arrow (year jump)
          onChange={(date) => {
            if (hasAttemptRef.current) {
              FormHelper.fetchHasError(form)
            }
          }}
        />
      )
    }

    if (field.isTextArea) {
      return (
        <TextArea
          placeholder={field.placeholder}
          className="multiple-input-field"
          autoSize={{
            minRows: 3,
            maxRows: 6,
          }}
          onChange={() => {
            if (hasAttemptRef.current) {
              FormHelper.fetchHasError(form)
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
            }
          }}
        />
      )
    }

    return (
      <Input
        placeholder={field.placeholder}
        type={getFieldType(field)}
        className="multiple-input-field"
        inputMode={field.inputMode || 'text'}
        onChange={() => {
          if (hasAttemptRef.current) {
            FormHelper.fetchHasError(form)
          }
        }}
      />
    )
  }

  return (
    <div className="gift">
      <Row gutter={[24, 24]} align="top">
        <Col xs={24} md={24}>
          <Form
            form={form}
            className="multiple-input-form"
            layout="vertical"
            initialValues={{
              giftActivationDate: dayjs(),
              ...(currentUser?.firstName && currentUser?.lastName && {
                giftSenderName: `${currentUser.firstName} ${currentUser.lastName}`,
                ...(currentUser?.email && {
                  giftSenderEmail: currentUser.email
                })
              })
            }}
          >
            {defaultFields
              .filter(group => group.shouldShow !== false)
              .map((group) => (
                <div key={`group-${group.group}`} className="field-group">
                  <h3 className="group-label">
                    &nbsp;{group.group}
                  </h3>
                  {group.fields.map((field, index) => (
                    <div 
                      key={`field-${field.name}`}
                      className={`field-container ${field.isSelector ? 'selector-container' : ''}`}
                    >
                      <Item 
                        name={field.name}
                        className={field.isSelector ? "single-select-item" : "multiple-input-item"}
                        rules={rules[field.name]}
                        validateTrigger='onSubmit'
                      >
                        {renderField(field, index === 0 && group.group === 'To')}
                      </Item>
                    </div>
                  ))}
                </div>
              ))}

            <FlowStepFooter 
              onSubmit={() => {
                onSubmit();
                // form.resetFields();
              }} 
              isSubmitting={isSubmitting}
              buttonText={"Go to Checkout"}
            />
          </Form>
        </Col>
        
      </Row>
    </div>
  )
}