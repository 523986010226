const UrlHelper = {
  getPatientProfile: (patientId, key) => {
    let url = `/patients/${patientId}`
    if (key) {
      url = `${url}?key=${key}`
    }
    return url
  }
}

export default UrlHelper