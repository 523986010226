import React, { useContext, useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import { Scatter } from 'react-chartjs-2';
import RiskLevel from '../../enums/riskLevel.enum'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import Color from '../../colors.scss'
import { Slider } from 'antd';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Statistic, Row, Col, Modal, Table, Tooltip } from 'antd';
import './exposureDistribution.scss';
import { CrownOutlined, HeartOutlined, HeartFilled, StarOutlined, HourglassOutlined, UserOutlined, TeamOutlined, RadarChartOutlined, AimOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Role from '../../enums/role.enum';
import { ReactComponent as SpinnerIcon } from '../../assets/svg/spinner.svg'
import MembershipTypeCode from '../../enums/membershipTypeCode.enum';
import MembershipStatus from '../../enums/membershipStatus.enum';
import { UserContext } from '../../contexts/user.context';
import { getStatsExposureDistribution } from '../../services/stats.service';
// Register ChartJS components
ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  ChartTooltip,
  Legend,
  annotationPlugin
);

const highRelevanceConditions = [
  'Heart Disease',
  'High Cholesterol',
  'High Blood Pressure',
  'Stroke',
  'Blocked Artery',
  'Heart Attack',
  'Hypertriglyceridemia',
  'Angina/Coronary Angiography',
  'Familial Hypercholesterolemia',
  'Peripheral Artery Disease (PAD)',
  'Hypertension',
]

const moderateRelevanceConditions = [
  'Type 2 Diabetes',
  'Diabetes',
  'Gestational Diabetes',
]

// Add membership type constants
const MEMBERSHIP_TYPES = {
  [MembershipTypeCode.ULTIMATE]: 'Ultimate',
  [MembershipTypeCode.HEART_HEALTH]: 'Heart Health',
  [MembershipTypeCode.PREMIUM]: 'Premium',
  [MembershipTypeCode.LONGEVITY]: 'Longevity'
};

const categorizeConditions = (conditions) => {
  if (!conditions) return { high: [], moderate: [] };
  
  return {
    high: [...new Set(conditions.filter(c => highRelevanceConditions.includes(c)))],
    moderate: [...new Set(conditions.filter(c => moderateRelevanceConditions.includes(c)))]
  };
};

export const ExposureDistribution = () => {
  const navigate = useNavigate();

  const [selectedYear, setSelectedYear] = useState(Number(moment().format('YYYY')));
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalData, setModalData] = useState([]);
  const [selectedAlternative, setSelectedAlternative] = useState(false);
  const { currentUser } = useContext(UserContext)
  const [exposureDistribution, setExposureDistribution] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchExposureDistribution()
  }, [])

  const fetchExposureDistribution = async () => {
    try {
      setLoading(true);
      const distribution = await getStatsExposureDistribution()
      setExposureDistribution(distribution)
    } finally {
      setLoading(false);
    }
  }

  const baseLDLThresholds = [
    { threshold: 5000, risk: 1 },
    { threshold: 6250, risk: 2 },
    { threshold: 7500, risk: 4 },
    { threshold: 8750, risk: 8 },
    { threshold: 10000, risk: 16 },
  ];

  const riskShades = {
    1: 'rgba(255, 99, 71, 0.4)',    // Tomato red, medium opacity
    2: 'rgba(255, 69, 0, 0.5)',     // Red-orange, slightly more intense
    4: 'rgba(255, 0, 0, 0.6)',      // Pure red, higher opacity
    8: 'rgba(220, 20, 60, 0.8)',    // Crimson, more intense red
    16: 'rgba(139, 0, 0, 1)',     // Dark red, highest opacity
  };

  // Add heart attack color constant
  const HEART_ATTACK_COLOR = 'rgba(128, 0, 128, 0.7)'; // Purple to distinguish from risk levels but maintain severity

  // Replace the duplicate color functions with the shared one
  const getPointColor = (exposure, age) => getBasePointColor(exposure, age, riskShades, baseLDLThresholds);
  const getAlternativePointColor = (exposure, age) => getBasePointColor(exposure, age, riskShades, baseLDLThresholds);

  // Add this new function to calculate percentages
  const calculateRiskPercentages = useMemo(() => {
    const validPatients = exposureDistribution.filter(
      patient => (selectedYear - Number(patient.birthYear)) > 0
    );
    const total = validPatients.length;
    
    // Add count for patients over 120
    const overAgePatients = validPatients.filter(
      patient => (selectedYear - Number(patient.birthYear)) > 120
    );
    
    const riskCounts = validPatients.reduce((acc, patient) => {
      const age = selectedYear - Number(patient.birthYear);
      // Skip patients over 120 for risk calculations
      if (age > 120) return acc;
      
      const exposure = patient.yearlyExposures[selectedYear];
      const riskLevel = baseLDLThresholds.reduce((risk, { threshold, risk: r }) => {
        return exposure >= threshold ? r : risk;
      }, 0);
      acc[riskLevel] = (acc[riskLevel] || 0) + 1;
      return acc;
    }, {});

    // Calculate total heart attacks based on risk levels
    const predictedHeartAttacks = Object.entries(riskCounts).reduce((total, [risk, count]) => {
      if (risk !== '0' && risk !== 'dead') {
        return total + (count * (Number(risk) / 100)); // Convert percentage to decimal
      }
      return total;
    }, 0);
    const totalDeaths = Math.round(predictedHeartAttacks) + overAgePatients.length;

    // Those who probably died of heart attacks who are dead
    const heartAttacksOfDeceased = overAgePatients.reduce((total, patient) => {
        // Get the last exposure value
        const lastExposure = Math.max(...Object.values(patient.yearlyExposures));
        
        // Find the risk level based on the last exposure
        const riskLevel = baseLDLThresholds.reduce((acc, { threshold, risk }) => {
          return lastExposure >= threshold ? risk : acc;
        }, 0);
        
        // Add the probability of heart attack (risk level as percentage)
        return total + (riskLevel / 100);
      }, 0);

    const totalHeartAttacks = predictedHeartAttacks + Math.round(heartAttacksOfDeceased);


    return {
      0: {
        percent: riskCounts[0] ? `${((riskCounts[0] || 0) / total * 100).toFixed(1)}%` : '0%' ,
        count: riskCounts[0] || 0
      },
      1: {
        percent: riskCounts[1] ? `${((riskCounts[1] || 0) / total * 100).toFixed(1)}%` : '0%',
        count: riskCounts[1] || 0
      },
      2: {
        percent: riskCounts[2] ? `${((riskCounts[2] || 0) / total * 100).toFixed(1)}%` : '0%',
        count: riskCounts[2] || 0
      },
      4: {
        percent: riskCounts[4] ? `${((riskCounts[4] || 0) / total * 100).toFixed(1)}%` : '0%',
        count: riskCounts[4] || 0
      },
      8: {
        percent: riskCounts[8] ? `${((riskCounts[8] || 0) / total * 100).toFixed(1)}%` : '0%',
        count: riskCounts[8] || 0
      },
      16: {
        percent: riskCounts[16] ? `${((riskCounts[16] || 0) / total * 100).toFixed(1)}%` : '0%',
        count: riskCounts[16] || 0
      },
      heartAttacks: {
        percent: totalHeartAttacks ? `${((totalHeartAttacks || 0) / total * 100).toFixed(1)}%` : '0%',
        count: Math.floor(totalHeartAttacks) || 0
      },
      dead: {
        percent: totalDeaths ? `${((totalDeaths || 0) / total * 100).toFixed(1)}%` : '0%',
        count: Math.floor(totalDeaths) || 0
      },
    };
  }, [selectedYear, exposureDistribution]);

  // Add this new function to calculate percentages
  const calculateAlternativeRiskPercentages = useMemo(() => {
    const validPatients = exposureDistribution.filter(
      patient => (selectedYear - Number(patient.birthYear)) > 0
    );
    const total = validPatients.length;
    
    // Add count for patients over 120
    const overAgePatients = validPatients.filter(
      patient => (selectedYear - Number(patient.birthYear)) > 120
    );
    
    const riskCounts = validPatients.reduce((acc, patient) => {
      const age = selectedYear - Number(patient.birthYear);
      // Skip patients over 120 for risk calculations
      if (age > 120) return acc;
      
      const exposure = patient.alternativeYearlyExposures[selectedYear];
      const riskLevel = baseLDLThresholds.reduce((risk, { threshold, risk: r }) => {
        return exposure >= threshold ? r : risk;
      }, 0);
      acc[riskLevel] = (acc[riskLevel] || 0) + 1;
      return acc;
    }, {});

    // Calculate total heart attacks based on risk levels
    const predictedHeartAttacks = Object.entries(riskCounts).reduce((total, [risk, count]) => {
      if (risk !== '0' && risk !== 'dead') {
        return total + (count * (Number(risk) / 100)); // Convert percentage to decimal
      }
      return total;
    }, 0);
    const totalDeaths = Math.round(predictedHeartAttacks) + overAgePatients.length;

    // Those who probably died of heart attacks who are dead
    const heartAttacksOfDeceased = overAgePatients.reduce((total, patient) => {
        // Get the last exposure value
        const lastExposure = Math.max(...Object.values(patient.alternativeYearlyExposures));
        
        // Find the risk level based on the last exposure
        const riskLevel = baseLDLThresholds.reduce((acc, { threshold, risk }) => {
          return lastExposure >= threshold ? risk : acc;
        }, 0);
        
        // Add the probability of heart attack (risk level as percentage)
        return total + (riskLevel / 100);
      }, 0);

    const totalHeartAttacks = predictedHeartAttacks + Math.round(heartAttacksOfDeceased);

    return {
      0: {
        percent: riskCounts[0] ? `${((riskCounts[0] || 0) / total * 100).toFixed(1)}%` : '0%',
        count: riskCounts[0] || 0
      },
      1: {
        percent: riskCounts[1] ? `${((riskCounts[1] || 0) / total * 100).toFixed(1)}%` : '0%',
        count: riskCounts[1] || 0
      },
      2: {
        percent: riskCounts[2] ? `${((riskCounts[2] || 0) / total * 100).toFixed(1)}%` : '0%',
        count: riskCounts[2] || 0
      },
      4: {
        percent: riskCounts[4] ? `${((riskCounts[4] || 0) / total * 100).toFixed(1)}%` : '0%',
        count: riskCounts[4] || 0
      },
      8: {
        percent: riskCounts[8] ? `${((riskCounts[8] || 0) / total * 100).toFixed(1)}%` : '0%' ,
        count: riskCounts[8] || 0
      },
      16: {
        percent: riskCounts[16] ? `${((riskCounts[16] || 0) / total * 100).toFixed(1)}%` : '0%',
        count: riskCounts[16] || 0
      },
      heartAttacks: {
        percent: totalHeartAttacks ? `${((totalHeartAttacks || 0) / total * 100).toFixed(1)}%` : '0%',
        count: Math.floor(totalHeartAttacks) || 0
      },
      dead: {
        percent: totalDeaths ? `${((totalDeaths || 0) / total * 100).toFixed(1)}%` : '0%',
        count: Math.floor(totalDeaths) || 0
      },
    };
  }, [selectedYear, exposureDistribution]);


  // Move shared constants inside component
  const CHART_OPTIONS = {
    clip: false,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'xy'
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Year',
          font: { 
            size: 14,
            family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
          },
        },
        min: 0,
        max: 120,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
          drawBorder: false,
        },
        ticks: {
          font: {
            family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'ApoB Exposure (mg-years)',
          font: { 
            size: 14,
            family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
          },
        },
        min: 0,
        max: 16000,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
          drawBorder: false,
        },
        ticks: {
          font: {
            family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
          }
        }
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleFont: {
          family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
        },
        bodyFont: {
          family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
        },
        padding: 12,
        cornerRadius: 8,
        callbacks: {
          label: (context) => {
            const point = context.raw;
            return ` ${point?.patient || 'Unknown'}: ${point.y.toLocaleString()} mg-years at age ${point.x}`;
          },
        },
      },
      legend: {
        display: false
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const dataPoint = data.datasets[0].data[elements[0].index];
        if (dataPoint && dataPoint.patientId) {
          window.open(`/patients/${dataPoint.patientId}?key=Results`, '_blank');
        }
      }
    },
    onHover: (event, elements) => {
      event.native.target.style.cursor = elements.length > 0 ? 'pointer' : 'default';
    },
  };

  // Move helper functions inside component where they need access to state/props
  const createDataset = (yearlyExposuresKey) => ({
    datasets: [
      {
        label: undefined,
        display: false,
        data: exposureDistribution
          .map(patient => {
            const age = selectedYear - Number(patient.birthYear);
            if (age <= 0) return null;
            
            const exposure = patient[yearlyExposuresKey][selectedYear];
            return {
              x: age,
              y: exposure,
              patient: patient.patientName,
              patientId: patient._id
            };
          })
          .filter(point => point !== null),
        backgroundColor: exposureDistribution
          .filter(patient => (selectedYear - Number(patient.birthYear)) > 0)
          .map(patient => {
            const age = selectedYear - Number(patient.birthYear);
            return yearlyExposuresKey === 'yearlyExposures' 
              ? getPointColor(patient[yearlyExposuresKey][selectedYear], age)
              : getAlternativePointColor(patient[yearlyExposuresKey][selectedYear], age);
          }),
        pointRadius: 3,
        pointHoverRadius: 5,
        pointStyle: 'circle',
        borderWidth: 0,
      }
    ]
  });

  const renderStatsRow = (percentages, handleClick) => (
    <Row gutter={[16, 16]} className="stats-row">
      <Col span={3} className="stats-col">
        <div onClick={() => handleClick('lowRisk')} style={{ cursor: 'pointer' }}>
          <Tooltip title="No Significant Heart Attack Risk">
            <Statistic 
              title="Low Risk"
              value={percentages[0].percent}
              suffix={<div className="count-suffix">
                {percentages[0].count}
                {percentages[0].count === 1 ? ' patient' : ' patients'}
              </div>}
              valueStyle={{ color: 'rgba(0, 122, 255, 0.7)' }}
            />
          </Tooltip>
        </div>
      </Col>
      {Object.entries(riskShades).map(([risk, color]) => (
        <Col span={3} key={risk} className="stats-col">
          <div onClick={() => handleClick('riskLevel', risk)} style={{ cursor: 'pointer' }}>
            <Tooltip title={`${risk}% Lifetime Heart Attack Risk`}>
              <Statistic 
                title={`Risk Level ${Math.log2(Number(risk)) + 1}`}
                value={percentages[risk].percent}
                suffix={<div className="count-suffix">
                  {percentages[risk].count}
                  {percentages[risk].count === 1 ? ' patient' : ' patients'}
                </div>}
                valueStyle={{ color: color }}
              />
            </Tooltip>
          </div>
        </Col>
      ))}
      <Col span={3} className="stats-col">
        <Statistic 
          title="Heart Attacks"
          value={percentages.heartAttacks.percent}
          suffix={<div className="count-suffix">
            {percentages.heartAttacks.count}
            {percentages.heartAttacks.count === 1 ? ' patient' : ' patients'}
          </div>}
          valueStyle={{ color: HEART_ATTACK_COLOR.replace(/[^,]+\)/, '1)') }}
        />
      </Col>
      <Col span={3} className="stats-col">
        <Statistic 
          title="Deceased"
          value={percentages.dead.percent}
          suffix={<div className="count-suffix">
            {percentages.dead.count}
            {percentages.dead.count === 1 ? ' patient' : ' patients'}
          </div>}
          valueStyle={{ color: 'rgba(0, 0, 0, 0.3)' }}
        />
      </Col>
    </Row>
  );

  // Use the helper functions to create data
  const data = createDataset('yearlyExposures');
  const alternativeData = createDataset('alternativeYearlyExposures');

  const getPatientsInCategory = (category, risk = null) => {
    return exposureDistribution.filter(patient => {
      const age = selectedYear - Number(patient.birthYear);
      if (age <= 0) return false;
      
      const exposure = patient.yearlyExposures[selectedYear];
      
      switch(category) {
        case 'lowRisk':
          return exposure < baseLDLThresholds[0].threshold;
        
        case 'riskLevel':
          const patientRisk = baseLDLThresholds.reduce((acc, { threshold, risk: r }) => {
            return exposure >= threshold ? r : acc;
          }, 0);
          return patientRisk === Number(risk);
        
        case 'heartAttacks':
          // Estimate heart attack probability based on exposure
          const patientRiskLevel = baseLDLThresholds.reduce((acc, { threshold, risk }) => {
            return exposure >= threshold ? risk : acc;
          }, 0);
          return Math.random() < (patientRiskLevel / 100); // Simple probability based on risk level
        
        case 'deceased':
          // Consider patients as deceased if they're over 120 or had a heart attack and didn't survive
          const isOverAge = age > 120;
          const hadFatalHeartAttack = getPatientsInCategory('heartAttacks').includes(patient) && 
                                    Math.random() < 0.41818182; // Death rate from heart attacks
          return isOverAge || hadFatalHeartAttack;
        
        default:
          return false;
      }
    });
  }

  const alternativeGetPatientsInCategory = (category, risk = null) => {
    return exposureDistribution.filter(patient => {
      const age = selectedYear - Number(patient.birthYear);
      if (age <= 0) return false;
      
      const exposure = patient.alternativeYearlyExposures[selectedYear];
      
      switch(category) {
        case 'lowRisk':
          return exposure < baseLDLThresholds[0].threshold;
        
        case 'riskLevel':
          const patientRisk = baseLDLThresholds.reduce((acc, { threshold, risk: r }) => {
            return exposure >= threshold ? r : acc;
          }, 0);
          return patientRisk === Number(risk);
        
        case 'heartAttacks':
          // Estimate heart attack probability based on exposure
          const patientRiskLevel = baseLDLThresholds.reduce((acc, { threshold, risk }) => {
            return exposure >= threshold ? risk : acc;
          }, 0);
          return Math.random() < (patientRiskLevel / 100); // Simple probability based on risk level
        
        case 'deceased':
          // Consider patients as deceased if they're over 120 or had a heart attack and didn't survive
          const isOverAge = age > 120;
          const hadFatalHeartAttack = alternativeGetPatientsInCategory('heartAttacks').includes(patient) && 
                                    Math.random() < 0.41818182; // Death rate from heart attacks
          return isOverAge || hadFatalHeartAttack;
        
        default:
          return false;
      }
    });
  };

  const handleStatisticClick = (category, risk = null) => {
    const patients =  getPatientsInCategory(category, risk);
    setSelectedAlternative(false)
    setModalData(patients);
    setModalTitle(
      category === 'lowRisk' ? 'Low Risk Patients' : `Risk Level ${Math.log2(Number(risk)) + 1}`
    );
    setModalVisible(true);
  };

  const handleAlternativeStatisticClick = (category, risk = null) => {
    const patients = alternativeGetPatientsInCategory(category, risk);
    setSelectedAlternative(true)
    setModalData(patients);
    setModalTitle(
      category === 'lowRisk' ? 'Low Risk Patients' : `Risk Level ${Math.log2(Number(risk)) + 1}`
    );
    setModalVisible(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'patientName',
      key: 'patientName',
      render: (text, record) => (
        <a 
          href={`/patients/${record._id}?key=Results`} 
          target="_blank"
          rel="noopener noreferrer"
          className="exposure-patient-link"
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Current Age',
      key: 'currentAge',
      render: (_, record) => {
        const currentYear = new Date().getFullYear();
        return currentYear - Number(record.birthYear);
      },
    },
    {
      title: 'Projected Age',
      key: 'projectedAge',
      render: (_, record) => {
        const currentYear = new Date().getFullYear();
        const currentAge = currentYear - Number(record.birthYear);
        const yearDifference = selectedYear - currentYear;
        return currentAge + yearDifference;
      }
    },
    {
      title: 'Exposure',
      key: 'exposure',
      render: (_, record) => (
        <a 
          href={`/exposure?patientId=${record._id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="exposure-patient-link"
        >
          {selectedAlternative ? 
            `${record.alternativeYearlyExposures[selectedYear].toLocaleString()} mg-years` :
            `${record.yearlyExposures[selectedYear].toLocaleString()} mg-years`
          }
        </a>
      ),
    },
    {
      title: '% Change',
      key: 'exposureChange',
      render: (_, record) => {
        const firstValue = record.firstValue;
        const lastValue = record.lastValue;
        if (!lastValue) return
        const isDecrease = firstValue > lastValue
        const isStatic = firstValue === lastValue
        
        // Calculate percentage change
        const percentChange = ((Math.abs(lastValue - firstValue) / firstValue) * 100).toFixed(1);
        
        return (
          <span style={{ 
            color: isStatic ? Color.secondary_text : isDecrease ? Color.success : Color.error,
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}>
            <span>
              {isStatic ? '' : isDecrease ? '↓' : '↑'} {percentChange}%
            </span>
          </span>
        );
      },
    },
    {
      title: 'First Test',
      key: 'firstValue',
      render: (_, record) => {
        const firstValue = record.firstValue;
        return `${firstValue.toLocaleString()} mg/dL`
      },
    },
    {
      title: 'Last Test',
      key: 'lastValue',
      render: (_, record) => {
        const lastValue = record.lastValue;
        return lastValue ? `${lastValue.toLocaleString()} mg/dL` : null
      },
    },
    {
      title: 'Conditions',
      key: 'conditions',
      render: (_, record) => {
        // Combine all personal health conditions
        const personalConditions = [
          ...(record.heartHealthConditions || []),
          ...(record.currentMedicalConditions || []),
          ...(record.allMedicationConditions || [])
        ];
        
        // Get family history conditions
        const familyConditions = [
          ...(record.heartHealthFamilyHistory || []),
          ...(record.familyHistory || [])
        ];
        
        // Categorize conditions
        const personal = categorizeConditions(personalConditions);
        const family = categorizeConditions(familyConditions);
        
        // Determine colors
        const personalColor = personal.high.length > 0 ? Color.error : 
                             personal.moderate.length > 0 ? Color.warning : 
                             '#ccc';
        
        const familyColor = family.high.length > 0 ? Color.error : 
                           family.moderate.length > 0 ? Color.warning : 
                           '#ccc';
        
        // New CAC score logic
        const cacPoints = record.cacPoints || [];
        const cacColor = (() => {
          const maxScore = Math.max(...cacPoints.map(test => test.y));
          if (maxScore >= 400) {
            return Color.error;  // Highest risk - dark red
          } else if (maxScore >= 100) {
            return 'rgba(255, 0, 0, 0.7)';  // Moderate-high risk - medium red
          } else if (maxScore >= 1) {
            return Color.warning;  // Moderate risk - yellow/orange
          } else if (maxScore === 0) {
            return Color.success;  // Very low risk - green
          }
          return '#ccc';  // No score available - gray
        })();
        
        const cacTooltipContent = cacPoints.length > 0 ?
          <div>
            Calcium Score:
            {cacPoints.length === 1 ? (
              <span>&nbsp;&nbsp;{cacPoints[0].y}</span>
            ) : (
              <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
                {cacPoints.map((test, index) => (
                  <li key={index}>
                    {moment(test.x).format('MMM D, YYYY')}: {test.y}
                  </li>
                ))}
              </ul>
            )}
          </div> :
          'No calcium score tests';
        
        const getTooltipContent = getConditionsTooltipContent;
        
        return (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Tooltip title={getTooltipContent(personal)}>
              <UserOutlined style={{ color: personalColor, fontSize: '16px' }} />
            </Tooltip>
            <Tooltip title={getTooltipContent(family)}>
              <TeamOutlined style={{ color: familyColor, fontSize: '16px' }} />
            </Tooltip>
            <Tooltip title={cacTooltipContent}>
              <RadarChartOutlined style={{ color: cacColor, fontSize: '16px' }} />
            </Tooltip>
            <Tooltip 
              overlayStyle={{ maxWidth: '500px' }}
              title={Object.values(record.ctPoints).some(tests => tests?.some(test => test.y > 0)) ?
              <div>
                <Table
                  size="small"
                  pagination={false}
                  style={{ marginTop: '8px' }}
                  rootClassName='ct-scan-table'
                  className='ct-scan-table'
                  columns={[
                    {
                      title: 'Test',
                      dataIndex: 'test',
                      key: 'test',
                    },
                    {
                      title: 'Value',
                      dataIndex: 'value',
                      key: 'value',
                    },
                    {
                      title: 'Risk',
                      dataIndex: 'risk',
                      key: 'risk',
                    },
                    {
                      title: 'Date',
                      dataIndex: 'date',
                      key: 'date',
                    },
                  ]}
                  dataSource={Object.entries(record.ctPoints).flatMap(([key, tests]) =>
                    tests.map((test, index) => ({
                      key: `${key}-${index}`,
                      test: index === 0 ? key : '',
                      date: moment(test.x).format('MMM D, YYYY'),
                      value: `${test.y}${key.includes('Stenosis') ? '%' : ''}`,
                      risk: test.risk
                    }))
                  )}
                />
              </div> : 
              Object.values(record.ctPoints).some(tests => tests?.length) ?
              'All CT scan values are 0' :
              'No CT scan data'
            }>
              <AimOutlined 
                style={{ 
                  color: Object.values(record.ctPoints).some(tests => tests?.some(({ risk }) => risk === RiskLevel.HIGH)) ? 
                    Color.error :
                    Object.values(record.ctPoints).some(tests => tests?.some(({ risk }) => risk === RiskLevel.MODERATE)) ?
                    Color.warning :
                    Object.values(record.ctPoints).some(tests => tests?.some(({ risk }) => risk === RiskLevel.OPTIMAL)) ?
                    Color.success :
                    '#ccc',
                  fontSize: '16px' 
                }} 
              />
            </Tooltip>
          </div>
        );
      },

    },
    {
      title: 'Memberships',
      key: 'memberships',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          {Object.entries(MEMBERSHIP_TYPES).map(([key, label]) => {
            let color = '#ccc'
            const status = record.membershipTypes?.[key]
            if (status === MembershipStatus.ACTIVE) {
              color = Color.success
            } else if (status === MembershipStatus.OVERDUE) {
              color = Color.warning
            } else if (status === MembershipStatus.CANCELED) {
              color = Color.error
            }
            let icon;
            
            switch(key) {
              case MembershipTypeCode.ULTIMATE:
                icon = (
                  <CrownOutlined style={{ color: color, fontSize: '18px' }} />
                );
                break;
              case MembershipTypeCode.HEART_HEALTH:
                icon = (
                  <HeartOutlined style={{ color: color, fontSize: '16px' }} />
                );
                break;
              case MembershipTypeCode.PREMIUM:
                icon = (
                  <StarOutlined style={{ color: color, fontSize: '16px' }} />
                );
                break;
              case MembershipTypeCode.LONGEVITY:
                icon = (
                  <HourglassOutlined style={{ color: color, fontSize: '16px' }} />
                );
                break;
              default:
                return null;
            }
            
            return (
              <Tooltip key={key} title={`${label}${status ? `: ${status}` : ''}`}>
                {icon}
              </Tooltip>
            );
          })}
        </div>
      ),
    },

  ];

  const alternativeColumns = [
    {
      title: 'Name',
      dataIndex: 'patientName',
      key: 'patientName',
      render: (text, record) => (
        <a 
          href={`/patients/${record._id}?key=Results`} 
          target="_blank"
          rel="noopener noreferrer"
          className="exposure-patient-link"
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Current Age',
      key: 'currentAge',
      render: (_, record) => {
        const currentYear = new Date().getFullYear();
        return currentYear - Number(record.birthYear);
      },
    },
    {
      title: 'Projected Age',
      key: 'projectedAge',
      render: (_, record) => {
        const currentYear = new Date().getFullYear();
        const currentAge = currentYear - Number(record.birthYear)
        const yearDifference = selectedYear - currentYear
        return currentAge + yearDifference
      }
    },
    {
      title: 'Exposure',
      key: 'exposure',
      render: (_, record) => (
        <a 
          href={`/exposure?patientId=${record._id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="exposure-patient-link"
        >
          {selectedAlternative ? 
            `${record.alternativeYearlyExposures[selectedYear].toLocaleString()} mg-years` :
            `${record.yearlyExposures[selectedYear].toLocaleString()} mg-years`
          }
        </a>
      ),
    },
    {
      title: '% Change',
      key: 'exposureChange',
      render: (_, record) => {
        const firstValue = record.firstValue;
        const lastValue = record.lastValue;
        if (!lastValue) return
        const isDecrease = firstValue > lastValue
        const isStatic = firstValue === lastValue
        
        // Calculate percentage change
        const percentChange = ((Math.abs(lastValue - firstValue) / firstValue) * 100).toFixed(1);
        
        return (
          <span style={{ 
            color: isStatic ? Color.secondary_text : isDecrease ? Color.success : Color.error,
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}>
            <span>
              {isStatic ? '' : isDecrease ? '↓' : '↑'} {percentChange}%
            </span>
          </span>
        );
      },
    },
    {
      title: 'First Test',
      key: 'firstValue',
      render: (_, record) => {
        const firstValue = record.firstValue;
        return `${firstValue.toLocaleString()} mg/dL`
      },
    },
    {
      title: 'Last Test',
      key: 'lastValue',
      render: (_, record) => {
        const lastValue = record.lastValue;
        return lastValue ? `${lastValue.toLocaleString()} mg/dL` : null
      },
    },
    {
      title: 'Conditions',
      key: 'conditions',
      render: (_, record) => {
        // Combine all personal health conditions
        const personalConditions = [
          ...(record.heartHealthConditions || []),
          ...(record.currentMedicalConditions || []),
          ...(record.allMedicationConditions || [])
        ];
        
        // Get family history conditions
        const familyConditions = [
          ...(record.heartHealthFamilyHistory || []),
          ...(record.familyHistory || [])
        ];
        
        // Categorize conditions
        const personal = categorizeConditions(personalConditions);
        const family = categorizeConditions(familyConditions);
        
        // Determine colors
        const personalColor = personal.high.length > 0 ? Color.error : 
                             personal.moderate.length > 0 ? Color.warning : 
                             '#ccc';
        
        const familyColor = family.high.length > 0 ? Color.error : 
                           family.moderate.length > 0 ? Color.warning : 
                           '#ccc';
        
        // New CAC score logic
        const cacPoints = record.cacPoints || [];
        const cacColor = (() => {
          const maxScore = Math.max(...cacPoints.map(test => test.y));
          if (maxScore >= 400) {
            return Color.error;  // Highest risk - dark red
          } else if (maxScore >= 100) {
            return 'rgba(255, 0, 0, 0.7)';  // Moderate-high risk - medium red
          } else if (maxScore >= 1) {
            return Color.warning;  // Moderate risk - yellow/orange
          } else if (maxScore === 0) {
            return Color.success;  // Very low risk - green
          }
          return '#ccc';  // No score available - gray
        })();
        
        const cacTooltipContent = cacPoints.length > 0 ?
          <div>
            Calcium Score:
            {cacPoints.length === 1 ? (
              <span>&nbsp;&nbsp;{cacPoints[0].y}</span>
            ) : (
              <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
                {cacPoints.map((test, index) => (
                  <li key={index}>
                    {moment(test.x).format('MMM D, YYYY')}: {test.y}
                  </li>
                ))}
              </ul>
            )}
          </div> :
          'No calcium score tests';
        
        const getTooltipContent = getConditionsTooltipContent;
        
        return (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Tooltip title={getTooltipContent(personal)}>
              <UserOutlined style={{ color: personalColor, fontSize: '16px' }} />
            </Tooltip>
            <Tooltip title={getTooltipContent(family)}>
              <TeamOutlined style={{ color: familyColor, fontSize: '16px' }} />
            </Tooltip>
            <Tooltip title={cacTooltipContent}>
              <RadarChartOutlined style={{ color: cacColor, fontSize: '16px' }} />
            </Tooltip>
            <Tooltip 
              overlayStyle={{ maxWidth: '500px' }}
              title={Object.values(record.ctPoints).some(tests => tests?.some(test => test.y > 0)) ?
              <div>
                <Table
                  size="small"
                  pagination={false}
                  style={{ marginTop: '8px' }}
                  rootClassName='ct-scan-table'
                  className='ct-scan-table'
                  columns={[
                    {
                      title: 'Test',
                      dataIndex: 'test',
                      key: 'test',
                    },
                    {
                      title: 'Value',
                      dataIndex: 'value',
                      key: 'value',
                    },
                    {
                      title: 'Risk',
                      dataIndex: 'risk',
                      key: 'risk',
                    },
                    {
                      title: 'Date',
                      dataIndex: 'date',
                      key: 'date',
                    },
                  ]}
                  dataSource={Object.entries(record.ctPoints).flatMap(([key, tests]) =>
                    tests.map((test, index) => ({
                      key: `${key}-${index}`,
                      test: index === 0 ? key : '',
                      date: moment(test.x).format('MMM D, YYYY'),
                      value: `${test.y}${key.includes('Stenosis') ? '%' : ''}`,
                      risk: test.risk
                    }))
                  )}
                />
              </div> : 
              Object.values(record.ctPoints).some(tests => tests?.length) ?
              'All CT scan values are 0' :
              'No CT scan data'
            }>
              <AimOutlined 
                style={{ 
                  color: Object.values(record.ctPoints).some(tests => tests?.some(({ risk }) => risk === RiskLevel.HIGH)) ? 
                    Color.error :
                    Object.values(record.ctPoints).some(tests => tests?.some(({ risk }) => risk === RiskLevel.MODERATE)) ?
                    Color.warning :
                    Object.values(record.ctPoints).some(tests => tests?.some(({ risk }) => risk === RiskLevel.OPTIMAL)) ?
                    Color.success :
                    '#ccc',
                  fontSize: '16px' 
                }} 
              />
            </Tooltip>
          </div>
        );
      },

    },
    {
      title: 'Memberships',
      key: 'memberships',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          {Object.entries(MEMBERSHIP_TYPES).map(([key, label]) => {
            let color = '#ccc'
            const status = record.membershipTypes?.[key]
            if (status === MembershipStatus.ACTIVE) {
              color = Color.success
            } else if (status === MembershipStatus.OVERDUE) {
              color = Color.warning
            } else if (status === MembershipStatus.CANCELED) {
              color = Color.error
            }
            let icon;
            
            switch(key) {
              case MembershipTypeCode.ULTIMATE:
                icon = (
                  <CrownOutlined style={{ color: color, fontSize: '18px' }} />
                );
                break;
              case MembershipTypeCode.HEART_HEALTH:
                icon = (
                  <HeartOutlined style={{ color: color, fontSize: '16px' }} />
                );
                break;
              case MembershipTypeCode.PREMIUM:
                icon = (
                  <StarOutlined style={{ color: color, fontSize: '16px' }} />
                );
                break;
              case MembershipTypeCode.LONGEVITY:
                icon = (
                  <HourglassOutlined style={{ color: color, fontSize: '16px' }} />
                );
                break;
              default:
                return null;
            }
            
            return (
              <Tooltip key={key} title={`${label}${status ? `: ${status}` : ''}`}>
                {icon}
              </Tooltip>
            );
          })}
        </div>
      ),
    },

  ];

  // Replace duplicate sort functions with the shared one
  const sortPatients = (a, b) => basePatientSort(a, b, selectedYear, 'yearlyExposures');
  const alternativeSortPatients = (a, b) => basePatientSort(a, b, selectedYear, 'alternativeYearlyExposures');

  if (currentUser && currentUser?.role !== Role.ADMIN) {
    // Redirect to home page
    navigate('/');
    return null;
  }

  // Add loading state check
  if (loading) {
    return (
      <div className="preloader">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <div className="exposure-container">
      <h2 className="exposure-title">
        Real: ApoB Exposure Distribution
      </h2>
      
      <div className="chart-container">
        <Scatter data={data} options={CHART_OPTIONS} />
      </div>

      {renderStatsRow(calculateRiskPercentages, handleStatisticClick)}
      
      <div className="slider-container">
        <Slider
          min={1930}
          max={2130}
          value={selectedYear}
          onChange={setSelectedYear}
          marks={{
            1930: '1930',
            1940: '1940',
            1950: '1950',
            1960: '1960',
            1970: '1970',
            1980: '1980',
            1990: '1990',
            2000: '2000',
            2010: '2010',
            2020: '2020',
            2030: '2030',
            2040: '2040',
            2050: '2050',
            2060: '2060',
            2070: '2070',
            2080: '2080',
            2090: '2090',
            2100: '2100',
            2110: '2110',
            2120: '2120',
            2130: '2130',
          }}
          tooltip={{
            formatter: (value) => `Year: ${value}`
          }}
        />
      </div>

      <Row gutter={[16, 16]} className="stats-row">
        <Col span={3} className="stats-col">
          <div onClick={() => handleAlternativeStatisticClick('lowRisk')} style={{ cursor: 'pointer' }}>
            <Tooltip title="No Significant Heart Attack Risk">
              <Statistic 
                title="Low Risk"
                value={calculateAlternativeRiskPercentages[0].percent}
                suffix={<div className="count-suffix">
                  {calculateAlternativeRiskPercentages[0].count}
                  {calculateAlternativeRiskPercentages[0].count === 1 ? ' patient' : ' patients'}
                </div>}
                valueStyle={{ color: 'rgba(0, 122, 255, 0.7)' }}
              />
            </Tooltip>
          </div>
        </Col>
        {Object.entries(riskShades).map(([risk, color]) => (
          <Col span={3} key={risk} className="stats-col">
            <div onClick={() => handleAlternativeStatisticClick('riskLevel', risk)} style={{ cursor: 'pointer' }}>
              <Tooltip title={`${risk}% Lifetime Heart Attack Risk`}>
                <Statistic 
                  title={`Risk Level ${Math.log2(Number(risk)) + 1}`}
                  value={calculateAlternativeRiskPercentages[risk].percent}
                  suffix={<div className="count-suffix">
                    {calculateAlternativeRiskPercentages[risk].count}
                    {calculateAlternativeRiskPercentages[risk].count === 1 ? ' patient' : ' patients'}
                  </div>}
                  valueStyle={{ color: color }}
                />
              </Tooltip>
            </div>
          </Col>
        ))}
        <Col span={3} className="stats-col">
          <Statistic 
            title="Heart Attacks"
            value={calculateAlternativeRiskPercentages.heartAttacks.percent}
            suffix={<div className="count-suffix">
              {calculateAlternativeRiskPercentages.heartAttacks.count}
              {calculateAlternativeRiskPercentages.heartAttacks.count === 1 ? ' patient' : ' patients'}
            </div>}
            valueStyle={{ color: HEART_ATTACK_COLOR.replace(/[^,]+\)/, '1)') }}
          />
        </Col>
        <Col span={3} className="stats-col">
          <Statistic 
            title="Deceased"
            value={calculateAlternativeRiskPercentages.dead.percent}
            suffix={<div className="count-suffix">
              {calculateAlternativeRiskPercentages.dead.count}
              {calculateAlternativeRiskPercentages.dead.count === 1 ? ' patient' : ' patients'}
            </div>}
            valueStyle={{ color: 'rgba(0, 0, 0, 0.3)' }}
          />
        </Col>
      </Row>

      <div className="chart-container">
        <Scatter data={alternativeData} options={CHART_OPTIONS} />
      </div>

      <h2 className="exposure-title">
        Alternate Reality: ApoB Exposure Distribution
      </h2>

      <Modal
        title={modalTitle}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={1100}
      >
        <Table
          size="small"
          pagination={false}
          dataSource={[...modalData].sort(selectedAlternative ? alternativeSortPatients : sortPatients)}
          columns={selectedAlternative ? alternativeColumns : columns}
        />
      </Modal>
    </div>
  );
};

// Keep these helper functions outside since they don't depend on component state
const getBasePointColor = (exposure, age, riskShades, baseLDLThresholds) => {
  if (exposure === 0) return 'rgba(0, 0, 0, 0.7)';  // Black for zero exposure
  const riskLevel = baseLDLThresholds.reduce((acc, { threshold, risk }) => {
    return exposure >= threshold ? risk : acc;
  }, 0);
  return riskLevel > 0 ? riskShades[riskLevel] : 'rgba(0, 122, 255, 0.7)';
};

const getConditionsTooltipContent = (conditions) => {
  if (conditions.high.length === 0 && conditions.moderate.length === 0) {
    return 'No relevant conditions';
  }
  
  return (
    <div>
      {conditions.high.length > 0 && (
        <div>
          <strong>High Relevance:</strong>
          <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
            {conditions.high.map(c => <li key={c}>{c}</li>)}
          </ul>
        </div>
      )}
      {conditions.moderate.length > 0 && (
        <div>
          <strong>Moderate Relevance:</strong>
          <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
            {conditions.moderate.map(c => <li key={c}>{c}</li>)}
          </ul>
        </div>
      )}
    </div>
  );
};

const basePatientSort = (a, b, selectedYear, exposureKey) => {
  // Helper functions
  const getLatestCACScore = (patient) => {
    const cacPoints = patient.cacPoints || [];
    return cacPoints.length > 0 ? Math.max(...cacPoints.map(test => test.y)) : 0;
  };

  const hasAllZeroCAC = (patient) => {
    const cacPoints = patient.cacPoints || [];
    return cacPoints.length > 0 && cacPoints.every(test => test.y === 0);
  };

  const hasAllZeroCT = (patient) => {
    if (!patient.ctPoints) return false;
    return Object.values(patient.ctPoints).length > 0 && 
           Object.values(patient.ctPoints).every(tests => 
             tests?.length > 0 && tests.every(test => test.y === 0)
           );
  };

  // Get CAC scores and risk levels
  const aCACScore = getLatestCACScore(a);
  const bCACScore = getLatestCACScore(b);
  const aHighCAC = aCACScore >= 400;
  const bHighCAC = bCACScore >= 400;
  const aModerateCAC = aCACScore >= 100 && aCACScore < 400;
  const bModerateCAC = bCACScore >= 100 && bCACScore < 400;

  // Get CT angiogram risk levels
  const aCTHigh = a.ctPoints && Object.values(a.ctPoints).some(tests => 
    tests?.some(({ risk }) => risk === RiskLevel.HIGH)
  );
  const bCTHigh = b.ctPoints && Object.values(b.ctPoints).some(tests => 
    tests?.some(({ risk }) => risk === RiskLevel.HIGH)
  );
  const aCTModerate = a.ctPoints && Object.values(a.ctPoints).some(tests => 
    tests?.some(({ risk }) => risk === RiskLevel.MODERATE)
  );
  const bCTModerate = b.ctPoints && Object.values(b.ctPoints).some(tests => 
    tests?.some(({ risk }) => risk === RiskLevel.MODERATE)
  );

  // 1. High risk CAC scores
  if (aHighCAC !== bHighCAC) return bHighCAC ? 1 : -1;
  
  // 2. High risk CT angiogram
  if (aCTHigh !== bCTHigh) return bCTHigh ? 1 : -1;
  
  // 3. Moderate risk CAC score
  if (aModerateCAC !== bModerateCAC) return bModerateCAC ? 1 : -1;
  
  // 4. Moderate CT angiogram
  if (aCTModerate !== bCTModerate) return bCTModerate ? 1 : -1;

  // Get exposure values and modifiers
  const aExposure = a[exposureKey][selectedYear];
  const bExposure = b[exposureKey][selectedYear];
  
  // Calculate priority scores
  const getExposurePriorityScore = (exposure, patient) => {
    let score = exposure;
    
    // 5a. All zero CT scores penalty
    if (hasAllZeroCT(patient)) {
      score *= 0.3; // Significant penalty
    }
    
    // 5b. All zero CAC scores penalty
    if (hasAllZeroCAC(patient)) {
      score *= 0.7; // Smaller penalty
    }
    
    // 5c. High relevance condition bonus - scales with number of conditions
    const highPersonal = getPersonalConditions(patient).high.length;
    const highFamily = getFamilyConditions(patient).high.length;
    if (highPersonal > 0 || highFamily > 0) {
      score *= (1.0 + (highPersonal + highFamily) * 0.5); // 0.5 bonus per condition
    }
    
    // 5d. Moderate relevance condition bonus - scales with number of conditions
    const modPersonal = getPersonalConditions(patient).moderate.length;
    const modFamily = getFamilyConditions(patient).moderate.length;
    if (modPersonal > 0 || modFamily > 0) {
      score *= (1.0 + (modPersonal + modFamily) * 0.15); // 0.15 bonus per condition
    }
    
    return score;
  };

  const aPriorityScore = getExposurePriorityScore(aExposure, a);
  const bPriorityScore = getExposurePriorityScore(bExposure, b);

  // 5. Compare final priority scores
  return bPriorityScore - aPriorityScore;
};

const getPersonalConditions = (patient) => {
  // Combine all personal health conditions
  const personalConditions = [
    ...(patient.heartHealthConditions || []),
    ...(patient.currentMedicalConditions || []),
    ...(patient.allMedicationConditions || [])
  ];
  
  return categorizeConditions(personalConditions);
};

const getFamilyConditions = (patient) => {
  // Get family history conditions
  const familyConditions = [
    ...(patient.heartHealthFamilyHistory || []),
    ...(patient.familyHistory || [])
  ];
  
  return categorizeConditions(familyConditions);
};