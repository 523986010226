import "./featurePost.scss";
import { BlogData } from "../../../data/blog.data";
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

export const FeaturePost = ({blogId, blogImage=null}) => {
    const navigate = useNavigate();
    const blog = BlogData.find(blog => blog.id === blogId);

    if (!blog) {
        return <div className="feature-post">No content not found</div>;
    }

    return (
        <div className="feature-post">
            <div className="feature-post-content">
                <div className="feature-post-text">
                    <span className="featured-label">Featured Research</span>
                    <h2 className="feature-post-title">{blog.title}</h2>
                    
                    <p className="feature-post-description">{blog.description}</p>
                    <Button 
                        type="default"
                        size="large"
                        className="feature-post-read-more"
                        onClick={() => navigate(`/research/${blog.id}`)}
                    >
                        Read More
                    </Button>
                </div>
                
                <div className="feature-post-img">
                    <img src={blogImage || blog.img} alt={blog.title} />
                </div>
            </div>
        </div>
    );
};