import { Modal, Table } from 'antd'
import './prescriptionModal.scss'
import { useState } from 'react';
import moment from 'moment'
import { useEffect } from 'react';
import { listResults } from '../../services/result.service';
import TestCode from '../../enums/testCode.enum';
import { listTests } from '../../services/test.service';
import Gender from '../../enums/gender.enum';
import PatientHelper from '../../helpers/patient.helper';
import { dosages } from '../../data/dosage.data';

const { Column } = Table

export const PrescriptionModal = ({ open, setOpen, prescription }) => {
  const patient = prescription?.patient
  const [tests, setTests] = useState()
  const [apob, setApob] = useState()
  const [ldl, setLdl] = useState()

  useEffect(() => {
    fetchTests()
  }, [])

  useEffect(() => {
    if (!open) {
      setTests(false)
      setApob(null)
      setLdl(null)
    }
  }, [open])

  useEffect(() => {
    fetchTestValues()
  }, [prescription, patient, tests])

  const onCancel = () => {
    setOpen(false)
  }

  const fetchTests = async () => {
    setTests(await listTests())
  }

  const getLatestTestValue = (results, testCode) => {
    const t =  tests.find(({ code }) => code === testCode)
    const latestResult = results.find(result => {
      return result.values.some(({ test, value }) => value && test === t._id)
    })
    if (!latestResult) return null
    const { value } = latestResult.values.find(({ test }) => test === t._id)
    return {
      value: `${value} ${t.unit}`,
      testedAt: moment(latestResult.collectedAt).format('MM/DD/YYYY')
    }
  }

  const fetchTestValues = async () => {
    if (patient && tests?.length) {
      const {
        _id,
        hasLipidTest,
        lipidTestDate,
        ldl,
        ldlUnit,
        apob,
        apobUnit
      } = patient

      const results = await listResults({ patient: _id })

      if (hasLipidTest && ldl) {
        setLdl({
          value: `${ldl} ${ldlUnit ? ldlUnit : 'mg/dL'}`,
          testedAt: lipidTestDate
        })
      } else {
        const latestLdl = getLatestTestValue(results, TestCode.DIRECT_LDL)
        if (latestLdl) {
          setLdl({
            value: latestLdl.value,
            testedAt: latestLdl.testedAt
          })
        }
      }

      if (hasLipidTest && apob) {
        setApob({
          value: `${apob} ${apobUnit ? apobUnit : 'mg/dL'}`,
          testedAt: lipidTestDate
        })
      } else {
        const latestApob = getLatestTestValue(results, TestCode.APO_B)
        if (latestApob) {
          setApob({
            value: latestApob.value,
            testedAt: latestApob.testedAt
          })
        }
      }
      return
    }

    setApob(null)
    setLdl(null)
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="prescription-modal"
      footer={null}
      width={500}
    >
      {prescription && patient && <>
        <Table
          size='small'
          dataSource={[{
            key: 'Reason',
            value: patient.cholesterolReason ? patient.cholesterolReason.join(', ') : 'None'
          }]}
          pagination={false}
          showHeader={false}
          tableLayout='horizontal'
        >
          <Column dataIndex='key' />
          <Column dataIndex='value' />
        </Table>

        <Table
          size='small'
          dataSource={[{
            key: 'Prescription',
            value: prescription.type
          }, {
            key: 'Test Preference',
            value: patient.testPreference
          }]}
          pagination={false}
          showHeader={false}
          tableLayout='horizontal'
        >
          <Column dataIndex='key' />
          <Column dataIndex='value' />
        </Table>

        <Table
          size='small'
          dataSource={[{
            key: 'Name',
            value: patient.firstName ? `${patient.firstName} ${patient?.lastName}` : 'N/A'
          }, {
            key: 'Gender',
            value: patient.gender
          }, {
            key: 'Date of birth',
            value: `${patient.dob}`
          },{
            key: 'Age',
            value: `${PatientHelper.getAge(patient)}`
          }, {
            key: 'Government ID',
            value: patient.govId ? (
              <img 
                src={`${patient.govId}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`}  
                className="gov-id-img"
              />
            ) : 'N/A'
          }]}
          pagination={false}
          showHeader={false}
          tableLayout='horizontal'
        >
          <Column dataIndex='key' />
          <Column dataIndex='value' />
        </Table>

        {patient.gender === Gender.FEMALE && (
          <Table
            size='small'
            dataSource={[{
              key: 'Pregnancy',
              value: patient.isPregnant ? 'Yes' : 'No'
            }, {
              key: 'Breastfeeding',
              value: patient.isBreastfeeding === undefined ? 'N/A' : patient.isBreastfeeding ? 'Yes' : 'No'
            }]}
            pagination={false}
            showHeader={false}
            tableLayout='horizontal'
          >
            <Column dataIndex='key' />
            <Column dataIndex='value' />
          </Table>
        )}

        <Table
          size='small'
          dataSource={[{
            key: 'LDL',
            value: ldl ? `${ldl.value} - ${ldl.testedAt}` : 'None',
          }, {
            key: 'ApoB',
            value: apob ? `${apob.value} - ${apob.testedAt}` : 'None',
          }]}
          pagination={false}
          showHeader={false}
          tableLayout='horizontal'
        >
          <Column dataIndex='key' />
          <Column dataIndex='value' />
        </Table>

        <Table
          size='small'
          dataSource={[{
            key: 'Cirrhosis / End-stage Liver Disease',
            value: patient.hasEndStageLiverDisease ? 'Yes' : 'No'
          }, {
            key: 'Cholesterol Medications [Listed]',
            value: (patient.cholesterolMedications?.some(c => c !== 'Other')) ? (
              <>
                {patient.cholesterolMedications?.filter(c => c !== 'Other').map(c => (
                  <div key={c}>
                    {(patient.cholesterolCurrent) ? <>
                      <b>{c}</b>: {patient.cholesterolCurrent[c] ? 'current' : 'past'} / {dosages[c]?.length === 1 ? dosages[c][0] : patient.cholesterolDosage[c]} / {patient.cholesterolAdverseReactio === true ? 'adverse' : 'safe'}
                    </> : c}
                  </div>
                ))}
              </>
            ) : 'None'
          },  {
            key: 'Natural Remedies',
            value: patient.naturalCholesterolRemedies?.length  ? patient.naturalCholesterolRemedies.join(', ')  : 'None'
          }, {
            key: 'Cholesterol Medications [Other]',
            value: (patient.cholesterolMedications?.includes('Other') && patient.otherCholesterolMedications?.length) ? patient.otherCholesterolMedications : 'None'
          }, {
            key: 'Medical Conditions',
            value: patient.allMedicalConditions?.length  ? patient.allMedicalConditions.join(', ')  : 'None'
          }, {
            key: 'Family History',
            value: patient.familyHistory?.length  ? patient.familyHistory.join(', ')  : 'None'
          }]}
          pagination={false}
          showHeader={false}
          tableLayout='horizontal'
        >
          <Column dataIndex='key' />
          <Column dataIndex='value' />
        </Table>

        <Table
          size='small'
          dataSource={[{
            key: 'Smoker',
            value: patient.hasSmoking ? 'Yes' : 'No'
          }, {
            key: 'Alcohol',
            value: patient.alcoholQty
          }]}
          pagination={false}
          showHeader={false}
          tableLayout='horizontal'
        >
          <Column dataIndex='key' />
          <Column dataIndex='value' />
        </Table>
        
        {patient.location && (
          <Table
            size='small'
            dataSource={[{
              key: 'Street Address',
              value: `${patient.location.streetAddress} ${patient.location.streetAddress2 || ''}`
            }, {
              key: 'City',
              value: patient.location.city
            }, {
              key: 'State',
              value: patient.location.state
            }, {
              key: 'Postal Code',
              value: patient.location.postalCode
            }]}
            pagination={false}
            showHeader={false}
            tableLayout='horizontal'
          >
            <Column dataIndex='key' />
            <Column dataIndex='value' />
          </Table>
        )}

        <Table
          size='small'
          dataSource={[{
            key: 'Email',
            value: patient.email
          }, {
            key: 'Phone',
            value: patient.phone || `N/A`
          }]}
          pagination={false}
          showHeader={false}
          tableLayout='horizontal'
        >
          <Column dataIndex='key' />
          <Column dataIndex='value' />
        </Table>
      </>}
    </Modal>
  )
}