import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'
import QueryStringHelper from '../helpers/queryString.helper'

export const listAdmins = async (params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admins?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const getAdmin = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admins/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const addAdmin = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admins`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updateAdmin = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/admins/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeAdmin = async (id, params) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/admins/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}