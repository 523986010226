import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./freeT4Advice.scss";
import ReportHelper from "../../../helpers/report.helper";
import QuestionTemplateCode from "../../../enums/questionTemplateCode.enum";
import Supplement from "../../../enums/supplement.enum";
import { TestItemList } from "../testItemLlist/testItemList.component";
import ReportSection from "../../../enums/reportSection.enum"
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const FreeT4Advice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [thyroidTests, setThyroidTests] = useState()

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report]);

  useEffect(() => {
    setThyroidTests(getThyroidTests())
  }, [report, tests])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])

  const getThyroidTests = () => {
    if (!report || !tests) return null
    return ReportHelper.getSectionTests(report, ReportSection.ISOLATED_FREE_T4_ADVICE, tests)
  }

  const isTakingBiotin = () => {
    const answer = ReportHelper.getAnswer(
      report,
      QuestionTemplateCode.CURRENT_SUPPLEMENTS
    );
    return answer?.includes(Supplement.BIOTIN);

  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report) return null;

    return (
      <>
        Your <span>free T4</span> is{" "}
        <span>elevated</span>, which is unusual given your TSH levels are healthy.
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report) return null;

    return (
      <>
        Free T4, a key thyroid hormone, helps regulate metabolism, heart rate, and more. Elevated levels may indicate hyperthyroidism but with normal TSH levels, no immediate concern arises.

        {isTakingBiotin() ? (
          <>
            <br /><br />
            It's recommended you repeat the test in a few weeks to monitor changes. Before the blood draw, avoid taking biotin for 48 hours, as it may cause falsely high T4 levels.
          </>
        ) : (
          <>
            <br /><br />
            It's recommended you repeat this test in a few weeks to see how this evolves. Also if you take biotin (vitamin B7), avoid supplementation 48 hours before the blood draw as it may result in falsely high levels of T4.
          </>
        )}
      </>
    );
  };


  return (
    thyroidTests && <div className="free-t4-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">
        {description}
      </Paragraph>

      <TestItemList  
        report={report} 
        testIds={thyroidTests.map(({ _id }) => _id)} 
        tests={tests}
        results={results}
        user={user}
        reports={reports}
      />

    </div>
  );
};
