import { Button, Typography } from 'antd'
import './flowStepFooter.scss'
import { EnterOutlined, CheckOutlined } from '@ant-design/icons'

const { Text } = Typography

export const FlowStepFooter = ({ 
  onSubmit, 
  isSubmitting,
  buttonText, 
  buttonStyle={},
  buttonCheck=true,
  enterStyle={},
  hasSkip=false,
  skipText="No thanks",
  onNextStep,
}) => {
  return (
    <div className="flow-step-footer">

      <Button
        className="flow-step-submit"
        type='primary'
        onClick={onSubmit}
        style={buttonStyle}
        loading={isSubmitting}
      >
        {buttonText ? buttonText : 'OK' }
        {buttonCheck && <CheckOutlined />}
      </Button>

      {hasSkip ? <Button
              type='default'
              className="skip-btn"
              onClick={onNextStep}
            >
              {skipText}
            </Button> :

      <div 
        className="enter-prompt"
        style={enterStyle}
      >
        <Text className="enter-prompt-text">
          press <strong>Enter</strong>
        </Text>
        <EnterOutlined className="enter-prompt-icon" />
      </div>}
  
    </div>
  )
}