// import './healthScore.scss'
import { Row, Col } from 'antd';
import { HealthScore } from './healthScore.component';
import { NextSteps } from './nextSteps.component';

export const Summary = ({ report, setSectionMeta, section, results, tests, user }) => {
  
    const jsxParams = { 
        report: report, 
        setSectionMeta: setSectionMeta, 
        section: section, 
        user: user,
        tests: tests,
        results: results 
    }

    return jsxParams && (

        <Row gutter={24}>
            <Col span={24}>
                <HealthScore {...jsxParams} />
            </Col>

            <Col span={24}>
                <NextSteps  {...jsxParams}/>
            </Col>
        </Row>
    )
}