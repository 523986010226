import { Typography, Table } from "antd";
import classNames from "classnames";
import { BlogData } from "../../../data/blog.data";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import { useParams } from "react-router-dom";
const { Paragraph } = Typography;

export const BloodPressureDietPost = () => {
  const { blogId } = useParams()
  return (
    <div className={classNames("blog-post", "blood-pressure-diet-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          {BlogData.find(b => b.id === blogId).title}
        </Paragraph>

        <Paragraph className="blog-subheading">
          {BlogData.find(b => b.id === blogId).description}
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          The Power of Sodium and Potassium
        </Paragraph>
        <Paragraph className="blog-description">
          Sodium and potassium aren't just nutrients—they're essential minerals that help regulate your body's fluid balance, nerve function, and even your blood pressure. Here's how they work and why tweaking these can make a big difference.
        </Paragraph>

        <Paragraph className="blog-title">
          Sodium: The "Water Magnet"
        </Paragraph>
        <Paragraph className="blog-description">
          Sodium, which we usually get from salt, acts like a sponge in your body, pulling water into your bloodstream. This water retention is necessary for keeping cells hydrated and allowing nerves and muscles to function. But when there's too much sodium in your system, it can increase the fluid volume in your blood vessels, creating pressure against the vessel walls—this is what raises your blood pressure.
        </Paragraph>
        <Paragraph className="blog-description">
          Think of your blood vessels as a garden hose. The more water you put through, the higher the pressure inside that hose. Lowering sodium is like turning down the water flow—it reduces fluid volume, easing pressure on your blood vessels and lowering your blood pressure.
        </Paragraph>

        <Paragraph className="blog-title">
          Potassium: The "Pressure Release Valve"
        </Paragraph>
        <Paragraph className="blog-description">
          Potassium, on the other hand, is found in foods like bananas, leafy greens, and sweet potatoes. It works as a counterbalance to sodium by helping your kidneys flush out excess sodium through urine. This mineral also relaxes the muscles surrounding blood vessels, helping them widen and allowing blood to flow with less resistance—like releasing pressure from that garden hose.
        </Paragraph>
        <Paragraph className="blog-description">
          When you have enough potassium, it naturally reduces blood pressure by making it easier for blood to flow. But when sodium levels are too high, potassium's balancing effects become limited, and blood pressure rises. That's why balancing both minerals can help create a natural way to support a healthy blood pressure.
        </Paragraph>

        <Paragraph className="blog-title">
          How Much of an Impact Can Dietary Changes Have on Blood Pressure?
        </Paragraph>
        <Paragraph className="blog-description">
          These meta-analyses explored the effects of reducing sodium and increasing potassium on blood pressure. While the results were statistically significant, the changes in blood pressure were modest. It's important to keep this in mind before adjusting your diet with the goal of altering your blood pressure. Expect gradual effects rather than quick, drastic shifts.
        </Paragraph>
        <Table
          className="blog-table"
          dataSource={[
            {
              study: 'He, Li & MacGregor',
              studyUrl: `https://doi.org/10.1136/bmj.f1325`,
              intervention: 'Decrease Sodium',
              systolic: '-4.18 mm Hg',
              diastolic: '-2.06 mm Hg'
            },
            {
              study: 'Aburto et al.',
              studyUrl: `https://doi.org/10.1136/bmj.f1378`,
              intervention: 'Increase Potassium',
              systolic: '-3.49 mm Hg',
              diastolic: '-1.96 mm Hg'
            },
          ]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column 
            title="Study" 
            dataIndex="study" 
            render={(text, record) => (
              <a onClick={() => window.open(record.studyUrl, '_blank')} className="blog-link">{text}</a>
            )}
          />
          <Table.Column title="Intervention" dataIndex="intervention" />
          <Table.Column title="Systolic BP Change" dataIndex="systolic" />
          <Table.Column title="Diastolic BP Change" dataIndex="diastolic" />
        </Table>

        <Paragraph className="blog-description">
          Keep in mind results vary based on individual health factors like ethnicity and genetics.
        </Paragraph>

        <Paragraph className="blog-title">
          Practical Tips for Balancing Sodium and Potassium
        </Paragraph>
        <Paragraph className="blog-description">
          For those of us who don't want to spend time adding up every milligram of salt and potassium, here are simple ways to improve your intake naturally:
        </Paragraph>

        <ol className="blog-list">
          <li>
            <strong>Watch Out for Hidden Sodium: </strong> Check food labels to spot high-sodium sources like processed foods, sauces, and restaurant meals. Choosing lower-sodium options a few times a week can quickly cut hundreds of milligrams.
          </li>

          <li>
            <strong>Swap for Unsalted Snacks: </strong> Opt for unsalted versions of your go-to snacks, like nuts, seeds, or popcorn. This simple swap reduces sodium intake by hundreds of milligrams a week, all without extra planning.
          </li>

          <li>
            <strong>Sneak in Potassium with Easy Add-Ins: </strong> Add potassium-rich foods to meals you already enjoy. Think banana slices in cereal, beans in a salad, or spinach in pasta.
          </li>
        </ol>

        <Paragraph className="blog-description">
          With these quick swaps, you can support better blood pressure control—no calculations required.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Aburto, N. J., et al. (2013). Effect of increased potassium intake on cardiovascular risk factors and disease: systematic review and meta-analyses. BMJ, 346, f1378. <a onClick={() => window.open('https://doi.org/10.1136/bmj.f1378', '_blank')}>https://doi.org/10.1136/bmj.f1378</a>
            </li>
            <li>
              He, F. J., Li, J., & MacGregor, G. A. (2013). Effect of longer term modest salt reduction on blood pressure: Cochrane systematic review and meta-analysis of randomised trials. BMJ, 346, f1325. <a onClick={() => window.open('https://doi.org/10.1136/bmj.f1325', '_blank')}>https://doi.org/10.1136/bmj.f1325</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
