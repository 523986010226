import React from "react";
import "./productDirections.scss";

export const ProductDirections = ({ steps }) => {
    return (
        <div className="product-detail-directions">
            {steps.map((step, index) => (
                <div key={index} className="product-detail-direction">
                    <div className="product-detail-direction-index">0{index + 1}</div>
                    <div className="product-detail-direction-photo">
                        <img src={step.image} alt={`Step ${index + 1}`} />
                    </div>
                    <div className="product-detail-direction-title">
                        {step.title}
                        <span className="product-detail-direction-description">
                            {step.description}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};