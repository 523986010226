import React from 'react'
import { Card, Typography } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import './researchCard.scss'

const { Text } = Typography

export const ResearchCard = ({ title, description, readTime, publishedAt, onClick }) => {
  return (
    <Card 
      className="research-card" 
      hoverable 
      onClick={onClick}
    >
      <div className="research-content">
        <h3 className="research-title">{title}</h3>
        <p className="research-description">{description}</p>
        <div className="research-meta">
          <Text type="secondary">
            {moment(publishedAt).format('MMM D, YYYY')}
          </Text>
          <Text type="secondary" className="read-time">
            <ClockCircleOutlined /> {readTime}
          </Text>
        </div>
      </div>
    </Card>
  )
} 