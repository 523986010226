const ReportSectionStatus = Object.freeze({
  FIXED: 'FIXED',
  SUSTAINED_FIX: 'SUSTAINED_FIX',
  IMPROVED: 'IMPROVED',
  WORSENED: 'WORSENED',
  STATIC: 'STATIC',
  NONE: null
});

export default ReportSectionStatus;
