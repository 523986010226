import React, { useEffect, useState } from 'react';
import { Table } from 'antd'
import { listLabs } from "../../services/lab.service"
import './adminLabs.scss'
import { PageHeader } from '../pageHeader/pageHeader.component';

const { Column } = Table

export const AdminLabs = () => {
  const [labs, setLabs] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchLabs()
  }, [])

  const fetchLabs = async () => {
    setLabs(await listLabs())
    setIsLoading(false)
  }


  return (
    <div className="admin-labs">
      <PageHeader
        title='Labs'
        count={labs.length}
      />

      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={labs}
      >

        <Column
          title='Abbreviation'
          dataIndex='abbreviation'
        />

        <Column
          title='Lab'
          dataIndex='name'
        />

        <Column
          title='CLIA #'
          dataIndex='clia'
        />

        <Column
          title='CAP #'
          dataIndex='cap'
        />

      <Column
          title='Director'
          dataIndex='labDirector'
        />

      </Table>
    </div>
  )
}