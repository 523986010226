import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listNotes = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/notes`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })

  return response.data
}

export const listNotesByPatient = async (patientId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/notes/patient/${patientId}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const addNote = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/notes`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getNote = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/notes/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const updateNote = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/notes/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeNote = async (id, params={}) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/notes/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params)
  })
  return response.data
}