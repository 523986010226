import React from "react";
import "./exerciseImpactAdvice.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { LowercaseTest, ReportSection, Condition, TestCode } from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import { AdviceSection } from "../adviceSection/adviceSection.component";
import parse from 'html-react-parser';
const converter = require("number-to-words");

const { Paragraph } = Typography;

export const ExerciseImpactAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [primaryTests, setprimaryTests] = useState([]);
  const [recs, setRecs] = useState()

  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  
  useEffect(() => {
    categorizeTests();
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setPrimaryDescription(getPrimaryDescription());
  }, [report, primaryTests, results, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
        }
      };
    });
  }, [title, primaryDescription]);

  const categorizeTests = () => {
    if (!report || !tests) return null;
    setprimaryTests(ReportHelper.getSectionTests(report, ReportSection.INTENSE_EXERCISE_IMPACT, tests) || []);
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title);

    if (!report || !results || !tests || !primaryTests) return null;
  
    const content = <>{primaryTests.length === 1 
      ? <>Your <span>elevated {LowercaseTest[primaryTests[0].code]}</span> level suggests</>
      : <>These{" "}<span>{converter.toWords(primaryTests.length)} biomarkers</span>{" "}suggest</>
    }{" "}recent intense physical activity.</>

    return content;
  };

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription);
    if (!report || !primaryTests) return null;

    return <>Elevated {ReportHelper.getTestNames(primaryTests)} levels are typically associated with inflammation and muscle stress. In athletes, these increases are typically due to strenuous exercise rather than any underlying health issues. This exercise-induced rise is usually temporary and not a sign of dysfunction. To be sure, you can get these tested again at a time when you're able to take a break from exercise to avoid false positives.</>   
  };

  

  return (
    primaryTests && (
      <div className="autoimmune-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {primaryDescription}
        </Paragraph>
        <TestView 
          report={report} 
          tests={primaryTests}
          results={results}
          reports={reports}
          user={user}
        />
        <AdviceSection 
        report={report} 
        results={results}
        tests={tests}
        section={section}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />

      </div>
    )
  );
};