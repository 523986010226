import React, { useState, useEffect } from "react";
import "./singleAbnormalTest.scss";
import { Typography } from "antd";
import TestCode from "../../../enums/testCode.enum";
import ReportHelper from "../../../helpers/report.helper";
import ReportSection from "../../../enums/reportSection.enum";
import { TestChart } from "../testChart/testChart.component";
import LowercaseTest from "../../../enums/lowercaseTest.enum";
import RiskLevel from "../../../enums/riskLevel.enum";
import PatientHelper from "../../../helpers/patient.helper";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const SingleAbnormalTest = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [riskLevel, setRiskLevel] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [abnormalTest, setAbnormalTest] = useState();
  const [hasImprovingTrend, setHasImprovingTrend] = useState();

  useEffect(() => {
    setAbnormalTest(getAbnormalTest());
  }, [report, tests]);

  useEffect(() => {
    setRiskLevel(getRiskLevel());
    setHasImprovingTrend(getHasImprovingTrend());
  }, [tests, results, report, abnormalTest]);

  useEffect(() => {
    setDescription(getDescription());
  }, [abnormalTest, riskLevel, user, hasImprovingTrend ]);

  useEffect(() => {
    setTitle(getTitle());
  }, [abnormalTest, riskLevel, report, hasImprovingTrend ]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])

  const getRiskLevel = () => {
    if (!abnormalTest || !tests || !results || !report) return null;
    return ReportHelper.getTestRisk(abnormalTest.code, report, results, tests);
  };

  const getAbnormalTest = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(
      report,
      ReportSection.SINGLE_ABNORMAL_TEST,
      tests
    )[0];
  };

  const getHasImprovingTrend = () => {
    if (!abnormalTest || !tests || !results || !report) return null;
    const isImproving = ReportHelper.hasImprovingTrend(abnormalTest.code, report, results, tests);
    return isImproving;
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!abnormalTest || !riskLevel || !report) return null;

    let content = null;
    const sectionCount = report.sections?.length;
    if (sectionCount > 4) {
      content = "As a last note, your ";
    } else if (sectionCount > 3) {
      content = "Also, your ";
  } else {
      content = "Your ";
    }

    content = (
      <>
        {content}
        <span>{LowercaseTest[abnormalTest.code]}</span> is{" "}
        {hasImprovingTrend ? (
          <span>improving</span>
        ) : (
          <span>{ReportHelper.getRiskEval(abnormalTest.code, report, results, tests)}</span>
        )}
      </>
    );

    switch (abnormalTest.code) {
      case TestCode.HDL: 
        return <>{content}, which is an independent predictor of cardiovascular disease.</>
      case TestCode.LP_PLA2:
        return <>{content}, which is a marker for vascular inflammation.</>;
      case TestCode.HS_CRP:
        return <>{content}, which is a non-specific marker for inflammation.</>;
      case TestCode.TOTAL_BILIRUBIN:
        return <>{content}, which can be a sign of liver issues.</>;
      case TestCode.TOTAL_TESTOSTERONE:
        return (
          <>
            {content}, which plays a vital role in muscle health, sexual desire,
            and cognitive function.
          </>
        );
      case TestCode.URIC_ACID:
        return <>{content}, a byproduct from the creation of DNA and RNA.</>;
      case TestCode.BUN:
        return <>{content}, a waste product created by your liver.</>;
      case TestCode.CHLORIDE:
        return (
          <>
            {content}, an electrolyte that helps maintain your body's salt-water
            balance.
          </>
        );
      case TestCode.TOTAL_PROTEIN:
        return (
          <>
            {content}, which is critical to the health and growth of your body's
            cells.
          </>
        );
      case TestCode.BP_D:
        return (
          <>
            {content}, which measures the force of blood pushing against your
            artery walls.
          </>
        );
      case TestCode.BUN_CREATINE:
        return (
          <>
            {content}, a useful biomarker to determine the cause of kidney
            issues.
          </>
        );
      case TestCode.PULSE:
        return (
          <>
            {content}, the number of times your heart beats when you’re at rest.
          </>
        );
      case TestCode.FERRITIN:
        return (
          <>
            {content}, a protein that plays a vital role in maintaining healthy
            cells.
          </>
        );
      case TestCode.HOMAIR:
        return <>{content}, a standard measure of insulin resistance.</>;
      case TestCode.NMR_LP_IR_SCORE:
        return <>{content}, an early indicator of insulin resistance.</>;
      case TestCode.HEMATOCRIT:
        return <>{content}, which can indicate anemia.</>;
      case TestCode.LYMPHOCYTES:
        return <>{content}, a type of white blood cell that helps fight infections.</>
      default:
        return <>{content}.</>;
    }
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!abnormalTest || !riskLevel || !user) return null;

    switch (abnormalTest.code) {
      case TestCode.HOMAIR:
      case TestCode.NMR_LP_IR_SCORE:

        if (hasImprovingTrend)
          return <>A downward trend is a positive indication that your metabolic health is improving. It's crucial to monitor this regularly, as any reversal in this trend could indicate an increased risk for metabolic issues in the future, such as diabetes.</>
        return <>Keep an eye on this and other metabolic markers, as an upward trend could signal potential metabolic issues that may lead to diabetes. It's advisable to test again soon to ensure it's not heading in the wrong direction.</>

      case TestCode.BP_S:
        return user?.exercises150PerWeek
          ? `This is close to healthy range and likely fine for someone that is physically active. If you are experiencing fatigue, dizziness, or light headiness, we recommend getting this checked by your primary care doctor.`
          : `This is close to healthy range, so no need to worry right now as this biomarker can vary day to day. We'll confirm next time whether this is consistently elevated. Please make sure you're well-rested before your next appointment for accurate readings.`;
          
      case TestCode.BP_D:
        return user?.exercises150PerWeek
          ? `This is close to healthy range and likely fine for someone that is physically active. If you are experiencing fatigue, dizziness, or light headiness, we recommend getting this checked by your primary care doctor.`
          : `This is close to healthy range, so no need to worry right now as this biomarker can vary day to day. We'll confirm next time whether this is consistently elevated. Please make sure you're well-rested before your next appointment for accurate readings.`;

      case TestCode.PULSE:
        if (hasImprovingTrend) {
          return `Since it's getting closer to healthy range, there's nothing to worry about for now. Let's continue to monitor this in your follow up blood draw to make sure the overall trend is going in the right direction.`;
        }

        return `This is close to healthy range, so no need to worry right now as this biomarker can vary day to day. We'll confirm next time whether this is consistently elevated. Please make sure you're well-rested before your next appointment for accurate readings.`;
      case TestCode.BUN_CREATINE:
        return `This is close to healthy range and likely fine given your other kidney markers are healthy. That said, it's worth monitoring with a follow up blood draw to confirm it's not heading in the wrong direction.`;
      case TestCode.LP_PLA2:
        if (PatientHelper.getAge(user) <= 40) {
          return `Elevated levels can also be seen in obesity and periodontal disease. But isolated elevations in young individuals without these conditions (and otherwise healthy lipid and inflammation markers) - like yourself - are non-specific and may not represent disease. Let's monitor this closely in follow-up blood draws.`;
        }
        break;
      
      case TestCode.HBA1C:
        return <>Elevated hbA1c typically signals insulin resistance and high fasting glucose, yet your case diverges from this usual pattern. Causes such as hemoglobin variants and rapid red blood cell turnover might explain this anomaly. Monitoring and further investigation after your next blood draw are crucial if levels rise.</>

      case TestCode.HS_CRP:
        return (
          <>
            There may be temporary increases in hs-CRP due to your body's
            natural response to acute events, which is a normal and beneficial
            process. So one reading isn’t enough to determine if this isolated
            elevation means there's an issue to worry about. <br />
            <br />
            Another blood draw will confirm if your hs-CRP are consistently
            suboptimal and/or heading in the wrong direction.
          </>
        );

      case TestCode.HDL:
        return riskLevel === RiskLevel.MODERATE? 
        (
          <>
            This is close to healthy range and given your other lipid results are fine, there's nothing to worry about right now. Another blood draw will confirm any worrisome trends.
          </>
        ): <>However, low HDL cholesterol levels haven't been causatively linked to this increased risk. Given your other lipid results are fine, there's no need to worry about this time. Another blood draw will confirm any worrisome trends.</>
      
      case TestCode.IRON_SATURATION:
        return <>Given your ferritin and other iron related markers are in healthy range, this reading isn't something we need to worry about for now. Let's continue to monitor and make sure it does not move in the wrong direction in your next blood draw.</>
      case TestCode.GRIP:
        return <>However, it’s close to the optimal range, so there’s no need for concern. With regular strength training, you’ll likely reach the optimal zone soon.</>
      default:
        if (hasImprovingTrend) {
          return <>While still suboptimal, this is trending in the right direction now. Another blood draw will confirm if your levels are consistently improving.</>
        }

        else 
          return riskLevel === RiskLevel.MODERATE ? (
            `This is close to healthy range and one reading isn’t enough to determine if this isolated elevation means there's an issue to worry about. Another blood draw will confirm if your levels are consistently suboptimal and/or heading in the wrong direction.`
          ) : (
            <>
              There isn’t enough evidence yet to indicate a concern. It’s recommended to monitor this with a follow-up test to confirm if it remains suboptimal or consult your physician.
            </>
          );
    }


    return riskLevel === RiskLevel.MODERATE ? (
      <>This is close to healthy range and one reading isn’t enough to determine if this isolated elevation means there's an issue to worry about. Another blood draw will confirm if your levels are consistently suboptimal and/or heading in the wrong direction.</>
    ) : (
      <>
        One reading of this biomarker isn’t enough to determine if there’s
        an issue to worry about. It's recommended to follow-up with another
        blood draw to confirm this is consistently suboptimal and/or follow
        up with your primary care provider.
      </>
    );
  };

  return (
    abnormalTest && (
      <div className="single-abnormal-test">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {description}
        </Paragraph>
        <TestChart 
          report={report} 
          code={abnormalTest.code} 
          tests={tests}
          results={results}
          reports={reports}
        />
      </div>
    )
  );
};
