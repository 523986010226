const ResultHelper = {
  getProgressData: (result) => {
    if (!result) return
    const { tests, values } = result
    if (!tests?.length) return

    // Check if result is more than 1 month old
    const currentDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);
    
    if (new Date(result.collectedAt) < oneMonthAgo) {
      return {
        incompleteTests: [],
        percentageComplete: 100
      }
    }

    const targetTests = tests
    const completeTestIds = values.filter(({ value }) => value).map(({ test }) => test?._id || test)
    const incompleteTests = targetTests.filter(test => !completeTestIds.includes(test?._id || test))
    const percentageComplete = Math.round((1 - incompleteTests?.length / targetTests?.length) * 100)

    return {
      incompleteTests,
      percentageComplete,
    }
  },
  getDirection: (testCode, results, tests) => {
    if (!results || !results.length || !tests || !tests.length) return null;
    
    const test = tests.find((t) => t.code === testCode);
    if (!test) return null; // Test code not found

    // Filter results by the testCode and sort them by date (assuming results have a 'date' field)
    const sortedResults = results
    .filter(({ values }) => values.some(({ test: valueTest }) => valueTest.toString() === test._id.toString()))
    .sort((a, b) => new Date(a.collectedAt) - new Date(b.collectedAt)); 
    if (sortedResults.length < 2) return 'static'; // If there are not enough results to compare, return 'static'

    // Get the first and last result values for the specified test
    const firstResult = sortedResults[0].values.find(({ test: valueTest }) => valueTest.toString() === test._id.toString());
    const lastResult = sortedResults[sortedResults.length - 1].values.find(({ test: valueTest }) => valueTest.toString() === test._id.toString());
  
    if (!firstResult || !lastResult) return null; // Ensure the values exist for comparison
  
    const firstValue = parseFloat(firstResult.value);
    const lastValue = parseFloat(lastResult.value);
  
    // Check if firstValue and lastValue are valid numbers
    if (isNaN(firstValue) || isNaN(lastValue)) return null;
  
    // Determine direction based on comparison
    if (lastValue > firstValue) {
      return 'increasing';
    } else if (lastValue < firstValue) {
      return 'decreasing';
    } else {
      return 'static';
    }
  },
}

export default ResultHelper