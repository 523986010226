import { useState, useEffect, useContext } from 'react';
import { Button, Col, Row, Typography, Tag, Tooltip } from 'antd';
import Gender from '../../enums/gender.enum';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import { listProductTypes } from '../../services/productType.service';
import { ReactComponent as UrineSvg } from "../../assets/svg/urine_sample.svg";
import { ReactComponent as BloodSvg } from "../../assets/svg/blood_drop.svg";
import { ReactComponent as CTSvg } from "../../assets/svg/tac.svg";
import { ReactComponent as BPSvg } from "../../assets/svg/blood_pressure.svg";
import { ReactComponent as SalivaSvg } from "../../assets/svg/saliva.svg"
import { ReactComponent as DoctorSvg } from "../../assets/svg/doctor-3.svg"
import CollectionMethod from '../../enums/collectionMethod.enum';
import Breakpoint from '../../enums/breakpoint.enum';
import PosthogHelper from '../../helpers/posthog.helper';
import EventCode from '../../enums/eventCode.enum';
import './testCatalog.scss';
import useWidth from '../../hooks/useWidth.hook';
import { UserContext } from '../../contexts/user.context';
import { DownOutlined } from "@ant-design/icons";
import { addSlack } from "../../services/slack.service"
import {RightOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Role from '../../enums/role.enum';
import ProductHelper from '../../helpers/product.helper';
import { RiseOutlined } from '@ant-design/icons';
import RecommendationHelper from '../../helpers/recommendation.helper';

const { Paragraph } = Typography;

const getAvailableCategories = (panels) => {
  const categories = new Set();

  panels.forEach(panel => {
    panel.category.forEach(cat => categories.add(cat));
  });

  return Array.from(categories);
};

export const TestCatalog = ({ 
  xs = 24, 
  sm = 12, 
  md = 12, 
  lg = 12, 
  xl = 8, 
  xxl = 8, 
  showAll = false, 
  provider=false,
  viewMore=true,
  hideCategories=false,
  hidePrices=false,
  count=6,
  noFrills=false,
  defaultCategory="all"
}) => {
  const { instalabMembership, currentUser, token } = useContext(UserContext);
  const width = useWidth();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  const [showAllState, setShowAllState] = useState(showAll);
  const [panels, setPanels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [filteredPanels, setFilteredPanels] = useState()
  const [displayPanels, setDisplayPanels] = useState()
  const [availableCategories, setAvailableCategories] = useState([]);
  const [recommendedTests, setRecommendedTests] = useState([])
  
  useEffect(() => {
    trackPosthog();

    if (currentUser) {
      fetchRecommendedTests();
    }
  }, [currentUser]);

  useEffect(() => {
    setSelectedCategory(searchParams.get('category') ? searchParams.get('category') : defaultCategory)

  }, [defaultCategory])

  const fetchRecommendedTests = async () => {
    const answers = await RecommendationHelper.getAnswers(currentUser);
    const recommendations = RecommendationHelper.getRecommendations(answers);
    setRecommendedTests(recommendations);
  }

  const trackPosthog = () => {
    if (currentUser) {
      PosthogHelper.track(currentUser, EventCode.TEST_CATALOG_VIEW);
    }
  };

  let panelCodes = [
    { code: ProductTypeCode.LONGEVITY_PANEL, category: ['all', 'longevity',] },
    { code: ProductTypeCode.CTCALCIUM, category: ['heart'] },
    { code: ProductTypeCode.GALLERI, category: ['cancer', 'longevity'] },
    { code: ProductTypeCode.WATCHPAT_ONE, category: ['heart', 'longevity','fatigue', 'performance'] },
    { code: ProductTypeCode.FIT_STOOL_TEST_KIT, category: ['cancer'] },
    { code: ProductTypeCode.TESTOSTERONE_PANEL, category: ['fatigue', 'performance'] },
    { code: ProductTypeCode.HEART_HEALTH_TEST_KIT, category: ['heart'] },
    { code: ProductTypeCode.HEART_HEALTH_GENETIC_TEST, category: ['heart', 'longevity'] },
    { code: ProductTypeCode.BIOBEAT, category: ['heart'] },
    { code: ProductTypeCode.TOXIC_METALS_URINE_TEST, category: ['fatigue'] },
    { code: ProductTypeCode.MYCOTOX_PROFILE, category: ['fatigue'] },
    { code: ProductTypeCode.CLEERLY, category: ['heart'] },
    { code: ProductTypeCode.PRENUVO, category: ['cancer', 'longevity', 'fatigue'] },
    { code: ProductTypeCode.DIURNAL_CORTISOL_PROFILE, category: ['fatigue'] },
    { code: ProductTypeCode.DEXA_BODYCOMP, category: ['heart', 'longevity'] },
    { code: ProductTypeCode.DEXA_BONE, category: ['all'] },
    { code: ProductTypeCode.VO2MAX, category: ['heart', 'longevity', 'performance'] },
    { code: ProductTypeCode.TRUAGE_COMPLETE, category: ['longevity'] },
    { code: ProductTypeCode.COLONOSCOPY, category: ['cancer'] },
    { code: ProductTypeCode.LDCT, category: ['cancer'] },
    { code: ProductTypeCode.SKIN_CANCER_EXAM, category: ['cancer'] },
    // { code: ProductTypeCode.CONSULT_LONGEVITY, category: ['longevity', 'performance'] }
  ];

  if (currentUser?.role === Role.PROVIDER || provider) {
    panelCodes = [
      { code: ProductTypeCode.LONGEVITY_PANEL, category: ['all', 'longevity'] },
      { code: ProductTypeCode.WATCHPAT_ONE, category: ['heart', 'fatigue'] },
      { code: ProductTypeCode.PRO_MOBILE_BLOOD_DRAW, category: ['all'] },
    ]    
  }

  if (currentUser?.isAthlete || provider)  {
    panelCodes = [{
      code: ProductTypeCode.ATHLETE_PANEL, 
      category: ['all', 'performance', 'longevity', 'recommended'], 
      weight: 100},
      ...panelCodes]
  }

  if (!provider) {
    const genderSpecificPanels = {
      [Gender.FEMALE]: [
        { code: ProductTypeCode.FERTILITY, category: ['longevity'] },
        { code: ProductTypeCode.MAMMOGRAM, category: ['cancer'] },
        { code: ProductTypeCode.PAP_SMEAR, category: ['cancer'] },
        { code: ProductTypeCode.HPV_TEST, category: ['cancer'] },
      ],
      [Gender.MALE]: [
        { code: ProductTypeCode.DRE, category: ['cancer'] },
      ]
    };

    // Add gender-specific panels if gender is known, otherwise add all panels
    const additionalPanels = currentUser?.gender ? 
      genderSpecificPanels[currentUser.gender] || [] : 
      Object.values(genderSpecificPanels).flat();

    panelCodes = [...panelCodes, ...additionalPanels];
  }

  useEffect(() => {
    fetchPanels();
  }, [recommendedTests]);

  useEffect(() => {
    const filtered = panels.filter(panel => 
      selectedCategory === 'all' || panel.category.includes(selectedCategory)
    );

    if (selectedCategory === 'recommended') {
      filtered.sort((a, b) => (b?.weight || 0) - (a?.weight || 0));
    }

    setFilteredPanels(filtered);
    setDisplayPanels(showAllState ? filtered : filtered.slice(0, count));
  }, [selectedCategory, panels, showAllState]);

  const fetchPanels = async () => {
    try {
      const codes = panelCodes.map(panel => panel.code);
      
      const fetchedPanels = await listProductTypes({
        filter: {
          code: {
            $in: codes
          }
        }
      });

      const categorizedPanels = fetchedPanels.map(fetchedPanel => {
        const panelWithCategory = panelCodes.find(panel => panel.code === fetchedPanel.code);
        const categories = [...panelWithCategory.category];
        
        // Add 'recommended' category if test is in recommendedTests
        if (recommendedTests.some(rec => rec.test === fetchedPanel.code)) {
          categories.push('recommended');
          fetchedPanel.weight = recommendedTests.find(rec => rec.test === fetchedPanel.code)?.weight;
        }
        
        return { ...fetchedPanel, category: categories};
      });

      // Sort panels to match the order in panelCodes
      const sortedPanels = panelCodes.map(panelCode =>
        categorizedPanels.find(panel => panel.code === panelCode.code)
      );

      setPanels(sortedPanels);
      setAvailableCategories(getAvailableCategories(sortedPanels)); 
    } catch (error) {
      console.error('Error details:', error.response?.data);
    }
  };

  const TestPanel = ({ test, isMostPopular, isTrending }) => {
    const handlePanelClick = async () => {
      PosthogHelper.track(currentUser, `[${EventCode.TEST_CATALOG_CLICK}] ${test.code}`);
  
      try {
        await addSlack({
          message: currentUser.firstName ? `[${currentUser.firstName} ${currentUser.lastName}] Test Catalog Click: ${test.code}` : `Test Catalog Click: ${test.code}`,
          channel: process.env.REACT_APP_PATIENT_SLACK
        });
      } catch (error) {
        console.error('Failed to log message to Slack:', error);
      }
  
      if (currentUser?.role === Role.PROVIDER) {
        navigate(`/pro-flow/${test.proFlowType}`);
      }
      else if (test.path) {
        navigate(test.path);
      } else if (test.flowType) {
        navigate(`/flow/${test.flowType}`);
      }
    };
  
    return (
      <div
        className={`test-catalog-item ${isMostPopular && selectedCategory !== 'recommended' && !noFrills && !provider ? 'most-popular' : isTrending && selectedCategory !== 'recommended' && !noFrills && !provider ? 'is-trending': ''}`}
        onClick={handlePanelClick} // Make the entire panel clickable
      >
        <div className="test-labels">
          <div className="test-label-tags">
            {isMostPopular && selectedCategory !== 'recommended' && !noFrills && !provider && <Tag className="most-popular-tag">Most Popular</Tag>}
            {isTrending && selectedCategory !== 'recommended' && !noFrills && !provider && <Tag className="is-trending-tag">Trending <RiseOutlined /></Tag>}
            {test.selfCollection ?  <Tag className="self-collect tag">self collect</Tag> : test.atHome ? <Tag className="at-home tag">at home</Tag> : <Tag className="at-office tag">office visit</Tag>}
          </div>
          {!isMostPopular && !isTrending && <div className="test-label-icons">
            {test.collectionMethod === CollectionMethod.BLOOD && <BloodSvg className="test-label-svg blood" />}
            {test.collectionMethod === CollectionMethod.URINE && <UrineSvg className="test-label-svg urine" />}
            {test.collectionMethod === CollectionMethod.CT_SCAN && <CTSvg className="test-label-svg scan" />}
            {test.collectionMethod === CollectionMethod.MRI_SCAN && <CTSvg className="test-label-svg scan" />}
            {test.collectionMethod === CollectionMethod.FDA_DEVICE && <BPSvg className="test-label-svg device" />}
            {test.collectionMethod === CollectionMethod.SALIVA && <SalivaSvg className="test-label-svg saliva"/>}
            {test.collectionMethod === CollectionMethod.STOOL && <SalivaSvg className="test-label-svg stool"/>}
            {test.collectionMethod === CollectionMethod.PROVIDER && <DoctorSvg className="test-label-svg provider"/>}
          </div>}
        </div>
        
        <Paragraph className="test-title" ellipsis={1}>
          {test.title}
        </Paragraph>
        <Paragraph className="test-description" ellipsis={{ rows: width > Breakpoint.SM ? 2 : 3 }}>
          {test.tagline}
        </Paragraph>
        <div className="learn-more-button-container">

        {currentUser?.credits?.includes(test.code) ? 
          <Tag color="blue">
            {currentUser.credits.filter(credit => credit === test.code).length} free credit{currentUser.credits.filter(credit => credit === test.code).length > 1 ? 's' : ''}
          </Tag> :
          token && !hidePrices && <div className="test-price">

            {(!test.cost || test.cost === 0 || test.memberCost === 0) ? (
              <Tooltip title="Price is based on your location. Instalab will find the best price for you.">
                Price varies by location
              </Tooltip>
            ) 
            : (currentUser?.role === Role.PROVIDER) ? 
              <>
              {test.cost !== ProductHelper.getProviderCost(test) && 
                <span className="regular-price">${test.cost.toLocaleString()}</span>
              }
              <span className="true-price">${ProductHelper.getProviderCost(test)}</span>
              </>
            :  instalabMembership && test.cost && test.memberCost && (test.cost > test.memberCost) ? 
            <>
              <span className="regular-price">${test.cost.toLocaleString()}</span>
                <span className="member-price">${test.memberCost.toLocaleString()}</span>
                <>{" "}<Tag className="member-tag">For Members</Tag></>
              </>
            : (
              <><span className="original-price">${test.cost.toLocaleString()}</span> 
            
              {(test.memberCost && (test.memberCost < test.cost)) && <> or <span className="member-price">${test.memberCost.toLocaleString()}</span> <Tag className="member-tag">For Members</Tag></>}</>
            )}
          </div>}
            {(!token || currentUser?.role === Role.PROVIDER) && <a className='secondary-link learn-more-button'>
            {(currentUser?.role === Role.PROVIDER) ? <>Order Now</> : <>Learn More</>} <RightOutlined style={{fontSize:10}}/>
            </a>}
          </div>
      </div>
    );
  };

  return panels.length > 0 && (
    <div className="test-catalog">
      {/* Filter Buttons */}
      {panels.length>0 && !hideCategories && <div className="filter-buttons">

        {availableCategories.includes('all') && (
          <Button className={selectedCategory === 'all' ? 'active' : ''} onClick={() => setSelectedCategory('all')}>All</Button>
        )}

        {availableCategories.includes('recommended') && token && (
          <Button className={selectedCategory === 'recommended' ? 'active' : ''} onClick={() => setSelectedCategory('recommended')}>
            Recommended <Tag style={{borderRadius: 12, margin:0}} className="new-tag">New</Tag>
          </Button>
        )}
        {availableCategories.includes('heart') && (
          <Button className={selectedCategory === 'heart' ? 'active' : ''} onClick={() => setSelectedCategory('heart')}>Heart Disease</Button>
        )}
        {availableCategories.includes('cancer') && (
          <Button className={selectedCategory === 'cancer' ? 'active' : ''} onClick={() => setSelectedCategory('cancer')}>Cancer</Button>
        )}
        {availableCategories.includes('longevity') && (
          <Button className={selectedCategory === 'longevity' ? 'active' : ''} onClick={() => setSelectedCategory('longevity')}>Longevity</Button>
        )}
        {availableCategories.includes('fatigue') && (
          <Button className={selectedCategory === 'fatigue' ? 'active' : ''} onClick={() => setSelectedCategory('fatigue')}>Fatigue</Button>
        )}
      </div>}


    {/* Note for Recommended Category */}
    {selectedCategory === 'recommended' && (
      <div className="recommended-note">
          These tests are based on your <a href="/patient/medical" className="gray-link">health profile</a> and <a href="/patient/results" className="gray-link">test results</a>, selected as the most relevant ones to help you stay on top of your health.
      </div>
    )}

      {/* Test Panels */}
      {filteredPanels.length > 0 ? (
        <Row gutter={24}>
          {displayPanels.map((test, index) => (
            <Col key={`panel-${test.code}`} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
              <TestPanel test={test} isMostPopular={index === 0} isTrending={index !== 0 &&test.code === ProductTypeCode.CTCALCIUM} />
            </Col>
          ))}
        </Row>
      ) : (
        <div>No tests available.</div>
      )}

      {!showAllState && filteredPanels.length > 6 && viewMore && (
        <div className="view-more-wrapper">
          <Button
            className="view-btn"
            onClick={() => setShowAllState(true)}
          >
            View More
            <DownOutlined className="view-btn-icon" />
          </Button>
        </div>
      )}
    </div>
  );
};
