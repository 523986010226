import React from 'react';
import { Modal, Tabs, message } from 'antd';
import { Login } from '../login/login.component';
import { Signup } from '../signup/signup.component';
import './loginModal.scss';

export const LoginModal = ({ open, onCancel, onLoginSuccess }) => {

    return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      width={600}
      className="login-modal"
      destroyOnClose
    >
      <Login 
              isModal 
              redirectPath={window.location.pathname}
              onLoginSuccess={onLoginSuccess}
              title="First let's login to your account"
        />
      {/* <Tabs
        defaultActiveKey="signup"
        items={[
          {
            key: 'login',
            label: 'Sign In',
            children: <Login 
              isModal 
              redirectPath={window.location.pathname}
              onLoginSuccess={onLoginSuccess}
              title="First let's login to your account"
            />,
          },
          {
            key: 'signup',
            label: 'Create Account',
            children: <Signup 
              isModal 
              isSignup 
              redirectPath={window.location.pathname}
              onSignupSuccess={onLoginSuccess}
            />,
          },
        ]}
      /> */}
    </Modal>
  );
}; 