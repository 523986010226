import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Input, Button, message } from 'antd'
import "./refundModal.scss"
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper'
import { addChargeRefund, getCharge } from '../../services/charge.service'

const { Item } = Form

export const RefundModal = ({ open, setOpen, onSuccess, chargeId, setChargeId, select, populate }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (open) {
      if (chargeId) setFields()
    } else {
      setChargeId(null)
      form.resetFields()
      setHasAttempt(false)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch (errors) {
      return true
    }
    return false
  }

  const setFields = async () => {
    const { refunds, amount } = await getCharge(chargeId, {
      select,
      populate
    })

    let leftoverAmount = amount
    if (refunds?.length) {
      leftoverAmount -= refunds.reduce((acc, refund) => {
        acc += refund.amount
        return acc
      }, 0)
    }


    form.setFieldsValue({
      amount: leftoverAmount
    })
  }

  const onOk = async () => {
    setHasAttempt(true)
    setIsSubmitting(true)
    try {
      if (await hasFormError()) {
        message.error('Enter valid patient information')
        setIsSubmitting(false)
        return 
      }

      const { amount } = form.getFieldsValue()
      
      const response = await addChargeRefund(chargeId, {
        fields: {
          amount
        },
        select,
        populate
      })
      setOpen(false)
      message.success('Refund issued')
      if (onSuccess) {
        onSuccess(response)
      }
    } catch (err) {
      message.error(`Refund failed`)
    }
    setIsSubmitting(false)
  }

  return (
    <Modal 
      open={open} 
      title={'Issue Refund'}
      onCancel={onCancel}
      onOk={onOk}
      okText='Save'
      footer={null}
      className="refund-modal"
    >
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
      >
        <Item 
          label="Amount"
          name="amount"
          rules={[
            RuleHelper.isRequired,
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Enter refund amount..." 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Button
          className="submit-btn"
          type='primary'
          onClick={onOk}
          loading={isSubmitting}
        >
          Issue Refund
        </Button>
      </Form>
    </Modal>
  )
}