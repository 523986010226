import React, { useEffect, useState } from 'react';
import { Modal, Form, message, Input, Select, Button, Space, InputNumber, TimeInput } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { updateFacility, addFacility, getFacility } from "../../services/facility.service.js";
import { listProductTypes } from "../../services/productType.service.js";  
import './adminFacilities.scss';
import { ContinueButton } from '../continueButton/continueButton.component';
import FacilityStatus  from '../../enums/facilityStatus.enum.js';

const { Item } = Form;
const select = '_id name location website hours menu status fax phone';
const populate = [{
    path: 'menu',  
    populate:'productType' 
}];

export const FacilityForm = ({ open, setOpen, facilityId, onSuccess }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [productTypes, setProductTypes] = useState([]);  // List of all product types
  const [loading, setLoading] = useState(true);  // Loading state to ensure data is ready

  useEffect(() => {
    if (open) {
      fetchProductTypes();  // Fetch product types when modal opens
      if (facilityId) {
        setFacilityFields();  // Set form fields if editing a facility
      } else {
        form.resetFields();  // Reset form if adding a new facility
      }
    }
  }, [open, facilityId]);

  const fetchProductTypes = async () => {
    try {
      const fetchedProductTypes = await listProductTypes({
        filter: {isActive: true, hasFacility: true},
        sort: 'title'
      });
      setProductTypes(fetchedProductTypes);
      setLoading(false);  // Data is loaded, remove loading state
    } catch (err) {
      message.error('Failed to load product types');
    }
  };


  const setFacilityFields = async () => {
    try {
      const params = {
        select: select,
        populate: populate
      }
      const facility = await getFacility(facilityId, params);
      const { name, website, hours, menu, status, fax, phone } = facility;

      // Map the menu items to set only the productType ID and price for each item
      const formattedMenu = menu.map(item => ({
        productType: item.productType ? item.productType._id : null,  // Ensure only the productType ID is set
        price: item.price,
      }));

      // Set facility name and menu items
      form.setFieldsValue({
        name,
        website,
        hours,
        fax,
        phone,
        status: status || FacilityStatus.ACTIVE,
        menu: formattedMenu || []  // Initialize menu items
      });
    } catch (err) {
      message.error('Failed to load facility details');
    }
  };

  const onCancel = () => {
    setOpen(false);
    form.resetFields();  // Reset fields when modal closes
  };

  const onFinish = async () => {
    setIsSubmitting(true);
    try {
      const { name, website, hours, menu, status, fax, phone } = form.getFieldsValue();
      const params = {
        fields: { name, website, hours, menu, status, fax, phone },
        select,
        populate
      };
      const response = facilityId ? await updateFacility(facilityId, params) : await addFacility(params);

      if (onSuccess) {
        onSuccess(response);  // Callback after success
      }

      message.success(facilityId ? 'Facility updated' : 'Facility added');
      setOpen(false);
      form.resetFields();  // Reset form after successful save
    } catch (err) {
      message.error(facilityId ? 'Failed to update facility' : 'Failed to add facility');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      title={facilityId ? 'Edit Facility' : 'Add Facility'}
      onCancel={onCancel}
      footer={null}
      className="facility-modal"
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter the facility name' }]}
        >
          <Input placeholder="Facility Name" />
        </Item>

        <Item
          label="Status"
          name="status"
          rules={[{ required: true, message: 'Please select the facility status' }]}
        >
          <Select placeholder="Select Status">
            <Select.Option value={FacilityStatus.ACTIVE}>Active</Select.Option>
            <Select.Option value={FacilityStatus.INACTIVE}>Inactive</Select.Option>
          </Select>
        </Item>

        <Item
          label="Website"
          name="website"
        >
          <Input placeholder="https://" />
        </Item>

        <Item
          label="Phone Number"
          name="phone"
        >
          <Input />
        </Item>

        <Item
          label="Fax Number"
          name="fax"
        >
          <Input />
        </Item>

        <Item label="Menu">
        <Form.List name="menu">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 5 }} align="baseline">
                  <Item
                    {...restField}
                    name={[name, 'productType']}
                    fieldKey={[fieldKey, 'productType']}
                    rules={[{ required: true, message: 'Missing product' }]}
                  >
                    <Select
                      placeholder="Select Product"
                      options={productTypes.map(pt => ({
                        value: pt._id,
                        label: pt.title,
                      }))}
                      loading={loading}
                      style={{ width: '300px' }}
                    />
                  </Item>
                  <Item
                    {...restField}
                    name={[name, 'price']}
                    fieldKey={[fieldKey, 'price']}
                    rules={[{ required: true, message: 'Missing price' }]}
                  >
                    <InputNumber placeholder="Price" min={0} />
                  </Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Menu Item
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        </Item>

        <Item label="Hours of Operation">
          <Form.List name="hours">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 5 }} align="baseline">
                    <Item
                      {...restField}
                      name={[name, 'dayOfWeek']}
                      fieldKey={[fieldKey, 'dayOfWeek']}
                      rules={[{ required: true, message: 'Missing day of week' }]}
                    >
                      <Select placeholder="Select Day" style={{ width: 120 }}>
                        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                          <Select.Option key={day} value={day}>{day}</Select.Option>
                        ))}
                      </Select>
                    </Item>
                    <Item
                      {...restField}
                      name={[name, 'openingHour']}
                      fieldKey={[fieldKey, 'openingHour']}
                      rules={[{ required: true, message: 'Missing opening hour' }]}
                    >
                      <Input
                        type="time"
                        placeholder="Opening Time"
                        onChange={(e) => {
                          form.setFieldsValue({
                            [name]: {
                              ...form.getFieldValue(name),
                              openingHour: e.target.value, // Save time as string in "HH:mm"
                            }
                          });
                        }}
                      />
                    </Item>
                    <Item
                      {...restField}
                      name={[name, 'closingHour']}
                      fieldKey={[fieldKey, 'closingHour']}
                      rules={[{ required: true, message: 'Missing closing hour' }]}
                    >
                      <Input
                        type="time"
                        placeholder="Closing Time"
                        onChange={(e) => {
                          form.setFieldsValue({
                            [name]: {
                              ...form.getFieldValue(name),
                              closingHour: e.target.value, // Save time as string in "HH:mm"
                            }
                          });
                        }}
                      />
                    </Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Operating Hours
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Item>


        <ContinueButton
          key="submit"
          text="Save"
          isSubmitting={isSubmitting}
        />
      </Form>
    </Modal>
  );
};