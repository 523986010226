import { useState, useEffect } from 'react';
import { Button, Dropdown, Tooltip, message } from 'antd';
import { DownOutlined, SearchOutlined, PlusOutlined, CalendarOutlined } from '@ant-design/icons';
import { addProduct } from '../../services/product.service';
import { BarcodeOutlined } from '@ant-design/icons';
import moment from 'moment';
import classNames from 'classnames';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum';
import ProductStatus from '../../enums/productStatus.enum';
import TrackingHelper from '../../helpers/tracking.helper';
import { BudgeTestDate } from '../budgeTestDate/budgeTestDate.component';

export const LipidTests = ({ results, patient: defaultPatient, membershipType, appointments=[], kits: defaultKits=[], onUpdatePatient }) => {
  const [showAllResults, setShowAllResults] = useState(false);  
  const [nextTestAt, setNextTestAt] = useState(null)
  const [nextTestClassName, setNextTestClassName] = useState('next-test-default-tag')
  const [nextTestTooltip, setNextTestTooltip] = useState('')
  const [kit, setKit] = useState()
  const [appointment, setAppointment] = useState()
  const [kits, setKits] = useState(defaultKits)
  const [isBudgeTestDateVisible, setIsBudgeTestDateVisible] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [patient, setPatient] = useState(defaultPatient)

  useEffect(() => {
    if (defaultPatient) {
      setPatient(defaultPatient)
    }
  }, [defaultPatient])

  useEffect(() => {
    fetchNextTestAt()
  }, [results,  membershipType, patient])

  useEffect(() => {
    fetchNextTestClassName()
  }, [nextTestAt, appointments, kits])

  const fetchNextTestAt = () => {
    const membershipTypeCodes = [
      MembershipTypeCode.ULTIMATE,
      MembershipTypeCode.HEART_HEALTH
    ]
    if (new Date(patient.nextTestAt) > new Date()) {
      setNextTestAt(patient.nextTestAt)
    } else if (membershipTypeCodes.includes(membershipType.code)) {
      const sortedResults = results.sort((a,b) => new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime())
      const lastResultCollectedAt = sortedResults?.length ? sortedResults[0].collectedAt : null
      setNextTestAt(lastResultCollectedAt ? moment(lastResultCollectedAt).add(6, 'weeks').toDate() : new Date())
    } else {
      setNextTestAt(null)
    }
  }

  const getFutureAppointment = () => {
    return appointments.find(appointment => {
      return new Date(appointment.start) > new Date()
    })
  }

  const getRecentAppointment = () => {
    return appointments.find(appointment => {
      return new Date(appointment.start) < new Date() && new Date(appointment.start) >= new Date(nextTestAt)
    })
  }

  const getPendingKit = () => {
    if (!kits) return
    return kits.filter(kit => {
      return ![
        ProductStatus.CANCELED,
        ProductStatus.DEACTIVATED,
        ProductStatus.FAILED,
        ProductStatus.COMPLETE,
      ].includes(kit.status)
    }).sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]
  }

  const fetchNextTestClassName = () => {
    const futureAppointment = getFutureAppointment()
    const recentAppointment = getRecentAppointment()
    const pendingKit = getPendingKit()
    setKit(pendingKit)
    setAppointment(futureAppointment || recentAppointment)

    if (new Date(nextTestAt) >= new Date()) {
      setNextTestClassName('next-test-future-tag')
      setNextTestTooltip(`Next Test: ${moment(nextTestAt).format('MMM DD, YYYY')}`)
    } else if (futureAppointment) {
      setNextTestClassName('next-test-appointment-tag')
      setNextTestTooltip(`Next Appointment: ${moment(futureAppointment.start).format('MMM DD, YYYY')}`)
    }  else if (recentAppointment) {
      setNextTestClassName('next-test-appointment-tag')
      setNextTestTooltip(`Recent Appointment: ${moment(recentAppointment.start).format('MMM DD, YYYY')}`)
    } else if (pendingKit) {
      setNextTestClassName('next-test-kit-tag')

      if (pendingKit.spotSampleStatus && pendingKit.spotSampleStatus !== 'awaiting_collection') {
        const lastSampleEvent = pendingKit.spotSampleEvents.sort((a,b) => new Date(b.created).getTime() - new Date(a.created).getTime())[0]
        setNextTestTooltip(`Sample ${formatStatus(pendingKit.spotSampleStatus)}: ${moment(lastSampleEvent.created).format('MMM DD, YYYY')}  (${moment().diff(moment(lastSampleEvent.created), 'weeks')}w)`)
      } else if (pendingKit.spotKitStatus) {
        const lastKitEvent = pendingKit.spotKitEvents.sort((a,b) => new Date(b.created).getTime() - new Date(a.created).getTime())[0]
        setNextTestTooltip(`Kit ${formatStatus(pendingKit.spotKitStatus)}: ${moment(lastKitEvent.created).format('MMM DD, YYYY')}  (${moment().diff(moment(lastKitEvent.created), 'weeks')}w)`)
      } else if (pendingKit.status) {
        setNextTestTooltip(`${formatStatus(pendingKit.status)}: ${moment(pendingKit.createdAt).format('MMM DD, YYYY')}  (${moment().diff(moment(pendingKit.createdAt), 'weeks')}w)`)
      }
    } else {
      setNextTestClassName('next-test-due-tag')
      setNextTestTooltip(`Next Test: ${moment(nextTestAt).format('MMM DD, YYYY')}`)
    }
  }

  const formatStatus = (status) => {
    if (!status) return
    return status
      // Split by underscore and capitalize each word
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const onSend = async () => {
    setIsSending(true)
    try {
      const params = {
        fields: {
          free: true,
          type: ProductTypeCode.HEART_HEALTH_TEST_KIT,
          facility: null,
          start: null,
          patient: patient._id
        },
      }
      const response = await addProduct(params)
      if (response) {
        setKit(response.product)
        setKits([
          response.product,
          ...kits
        ])
      }
      message.info('Test kit sent')
    } catch (err) {
      message.error('Failed to send test kit')
    }
    setIsSending(false)
  }

  const budgeTestDate = {
    key: 'budge',
    label: (
      <a onClick={() => {
        setIsBudgeTestDateVisible(true)
      }}>
        <CalendarOutlined style={{ marginRight: 5 }} /> Budge Test Date
      </a>
    ),
  }


  return (
    <div>
      <Button
        className="add-btn"
        loading={isSending}
        onClick={onSend}
        icon={<PlusOutlined />}
      />

      {nextTestAt && <>
        <BudgeTestDate 
          patientId={patient._id}
          title={'Next Test Date'}
          open={isBudgeTestDateVisible}
          setOpen={setIsBudgeTestDateVisible}
          select={'firstName lastName email shippingLocation nextTestAt'}
          onSuccess={onUpdatePatient}
        />

        <Dropdown
          overlayStyle={{
            width: 150
          }}
          menu={{ items: appointment ? [budgeTestDate] :
          [budgeTestDate, kit?.spotId && {
            key: 'kit-spot',
            label: (
              <a onClick={() => {
                window.open(`https://tracking.instalab.com/manage/app/kit/?q=${kit.spotId}`, '_blank')
              }}>
                <SearchOutlined style={{ marginRight: 5 }} /> Open Spot
              </a>
            ),
          }, kit?.trackingNumber && {
            key: 'kit-tracking' ,
            label: (
              <a onClick={() => {
                window.open(TrackingHelper.getDeliveryService(kit.trackingNumber).url, '_blank')
              }}>
                <BarcodeOutlined style={{ marginRight: 5 }} /> Kit Tracking
              </a>
            ),
          }, (kit?.returnTrackingNumber && kit?.spotSampleStatus !== 'awaiting_collection') && {
            key: 'kit-return-tracking' ,
            label: (
              <a onClick={() => {
                window.open(TrackingHelper.getDeliveryService(kit.returnTrackingNumber).url, '_blank')
              }}>
                <BarcodeOutlined style={{ marginRight: 5 }} /> Sample Tracking
              </a>
            ),
            }]
          }}
          placement='bottom'
        >
          <Tooltip
            title={nextTestTooltip}
          >
            <div className="tag-container">
              <div className={classNames("tag", nextTestClassName)}>
                {moment(nextTestAt).format('MMM D, YYYY')}
              </div>
            </div>
          </Tooltip>
        </Dropdown>
      </>  }

      {results.slice(0, showAllResults ? results.length : 2).map((result, index) => (
        <Dropdown
          overlayStyle={{
            width: 150
          }}
          menu={{ items: [] }}
          placement='bottom'
        >
          <Tooltip
            title={`Tested: ${moment().diff(moment(result.collectedAt), 'weeks')} ${moment().diff(moment(result.collectedAt), 'weeks') === 1 ? 'Week' : 'Weeks'} Ago`}
          >
            <div className="tag-container">
              <div className={classNames("tag", `result-tag`)}>
                {moment(result.collectedAt).format('MMM D, YYYY')}
              </div>
            </div>
          </Tooltip>
        </Dropdown>
      ))}
      
      {results.length > 2 && !showAllResults && (
        <Button 
          onClick={() => setShowAllResults(true)}
          type="link"
          className="view-more-button"
        >
          <DownOutlined /> View {results.length - 2} More
        </Button>
      )}
    </div>
  )
}