import React from 'react'
import './multipleAbnormalTests.scss'
import { Typography } from 'antd';
import { TestItemList } from '../testItemLlist/testItemList.component';
import { useState } from 'react';
import { useEffect } from 'react';
import ReportHelper from '../../../helpers/report.helper';
import ReportSection from '../../../enums/reportSection.enum';
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const converter = require('number-to-words');

const { Paragraph } = Typography

export const MultipleAbnormalTests = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [title, setTitle] = useState()
  const [abnormalTests, setAbnormalTests] = useState()

  useEffect(() => {
    setTitle(getTitle())
  }, [report, abnormalTests])

  useEffect(() => {
    setAbnormalTests(getAbnormalTests())
  }, [report, tests])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
        }
      }
    })
  }, [title])

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !abnormalTests) return null

    const sectionCount = report.sections?.length
    let content = null
    if (sectionCount > 4) {
      content = 'As a last note, you '
    } else if (sectionCount > 3) {
      content = 'Also, you '
    } else {
      content = 'You '
    }
    return <>{content} should keep your eye on <span>{converter.toWords(abnormalTests?.length)} suboptimal {abnormalTests?.length === 1 ? 'biomarker' : 'biomarkers'}</span>.</>
  }

  const getAbnormalTests = () => {
    if (!report || !tests) return null
    return ReportHelper.getSectionTests(report, ReportSection.MULTIPLE_ABNORMAL_TESTS, tests)
  }

  return abnormalTests && title && (
    <div className="multiple-abnormal-tests">
      <Paragraph className="report-template-title">
        {title}
      </Paragraph>
      <Paragraph className="report-template-description">
        While these biomarkers may not be optimal, there's not yet enough evidence to confirm there’s a problem. We will keep an eye on them in your next blood draw and determine if there's any follow up required.
      </Paragraph>
      <br/><br/>
      <TestItemList 
        report={report} 
        testIds={abnormalTests.map(({ _id }) => _id)} 
        tests={tests}
        results={results}
        user={user}
        reports={reports}
      />
    </div>
  )
}