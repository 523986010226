import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Input, message, Tooltip } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import "./phlebotomistForm.scss"
import { addPhlebotomist, getPhlebotomist, updatePhlebotomist } from '../../services/phlebotomist.service';
import { PickerInline } from 'filestack-react';
import { DeleteOutlined } from '@ant-design/icons'
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper';
import PhoneInput from '../phoneInput/phoneInput.component';

const { Item } = Form

export const PhlebotomistForm = ({ open, setOpen, onSuccess, phlebotomistId, select, populate }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [photo, setPhoto] = useState(null)
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (open) {
      if (phlebotomistId) setPhlebotomistFields()
    } else {
      form.resetFields()
      setPhoto(null)
      setHasAttempt(false)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
    setPhoto(null)
  }

  const setPhlebotomistFields = async () => {
    const {
      firstName,
      lastName,
      photo: selectedPhoto,
      email,
      phone,
      slack,
      hoursBuffer,
    } = await getPhlebotomist(phlebotomistId, {
      select: 'firstName lastName photo email phone slack hoursBuffer',
      populate: []
    })

    if (selectedPhoto) {
      setPhoto(selectedPhoto)
    }

    form.setFieldsValue({
      firstName,
      lastName,
      photo,
      email,
      phone,
      slack,
      hoursBuffer,
    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        firstName,
        lastName,
        email,
        phone,
        slack,
        hoursBuffer,
      } = form.getFieldsValue()
      
      const params = {
        fields: {
          firstName,
          lastName,
          photo,
          email,
          phone,
          slack,
          hoursBuffer,
        },
        select,
        populate
      }
      const response = phlebotomistId ? await updatePhlebotomist(phlebotomistId, params) : await addPhlebotomist(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(phlebotomistId ? 'Phlebotomist updated' : 'Phlebotomist added')
      setOpen(false)
      if (!phlebotomistId) {
        form.resetFields()
      }
    } catch (err) {
      let msg = phlebotomistId ? 'Failed to update phlebotomist' : 'Failed to add phlebotomist'
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email'
      }
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  const onFail = () => {
    setHasAttempt(true)
    message.error('Enter valid phlebotomist data')
  }

  return (
    <Modal 
      open={open} 
      title={phlebotomistId ? 'Edit Phlebotomist' : 'Add Phlebotomist'}
      onCancel={onCancel}
      footer={null}
      className="phlebotomist-form"
    >
      <Form
        form={form}
        onFinish={onOk}
        onFinishFailed={onFail}
        layout='vertical'
      >
        <Item name="picker">
          {photo && (
            <Tooltip title="Click to remove photo" zIndex={2000} placement="right">
              <div 
                className="provider-form-photo"
                onClick={() => {
                  setPhoto(null)
                }}
              >
                <img 
                  src={`${photo}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} 
                />
                  <div className="provider-photo-overlay">
                    <DeleteOutlined />
                  </div>
              </div>
            </Tooltip>
          )}
          <div id="picker-container" style={{ display: photo ? 'none' : 'block' }}>
            <PickerInline
              apikey={process.env.REACT_APP_FILESTACK_API_KEY}
              onUploadDone={({ filesUploaded }) => {
                if (filesUploaded?.length) {
                  const { url } = filesUploaded[0]
                  setPhoto(url)
                }
              }}
              pickerOptions={{
                accept: ['image/*'],
                fromSources: ['local_file_system'],
                container: 'picker-container'
              }}
              clientOptions={{
                security: {
                  policy: process.env.REACT_APP_FILESTACK_POLICY,
                  signature: process.env.REACT_APP_FILESTACK_SIGNATURE
                }
              }}
            >
              <div className="picker-container"></div>
            </PickerInline>
          </div>
        </Item>

        <Item 
          label="Email Address"
          name="email"
          onInput={e => e.target.value = e.target.value.toLowerCase()}
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isEmail,
            RuleHelper.isUniqueEmail({
              fieldName: 'email',
              userId: phlebotomistId,
            })
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Email Address" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Phone Number"
          name="phone"
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isPhone,
          ]}
          validateTrigger='onSubmit'
        >
          <PhoneInput 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="First Name"
          name="firstName"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="First Name" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Last Name"
          name="lastName"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Last Name" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Slack"
          name="slack"
        >
          <Input 
            placeholder="Slack" 
          />
        </Item>


        <Item 
          label="Hours Buffer"
          name="hoursBuffer"
        >
          <Input 
            placeholder="Hours Buffer" 
          />
        </Item>

        <Item>
          <ContinueButton
            text='Save Phlebotomist'
            isSubmitting={isSubmitting}
          />
        </Item>
      </Form>
    </Modal>
  )
}