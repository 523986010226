import React, { useState, useEffect } from 'react';
import { Modal, Form, Select, Button, message } from 'antd';
import { addProviderToPatient } from '../../services/patient.service'; 
import { listProviders } from "../../services/provider.service";
import Org from '../../enums/org.enum';

export const AddProviderForm = ({ patientId, open, setOpen, onSuccess }) => {
  const [form] = Form.useForm();
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch providers when modal opens
  useEffect(() => {
    if (open) {
      fetchProviders();
    }
  }, [open]);

  // Fetch the list of providers
  const fetchProviders = async () => {
    setLoading(true);
    try {
      const fetchedProviders = await listProviders(); // Fetch all available providers
      const filteredProviders = fetchedProviders
      .filter(provider => 
        provider.firstName && // Ensure firstName exists
        provider.lastName &&  // Ensure lastName exists
        provider.org === Org.INSTALAB // Ensure org is "instalab"
      )
      .sort((a, b) => {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        
        // Sort by full name (firstName + lastName)
        return nameA.localeCompare(nameB);
      });
      
      setProviders(filteredProviders);
    } catch (error) {
      message.error('Failed to fetch providers');
    } finally {
      setLoading(false);
    }
  };

  // Handle the form submission
  const handleAddProvider = async (values) => {
    setIsSubmitting(true);
    try {
      await addProviderToPatient(patientId, {providerId: values.providerId}); // Add the selected provider to the patient
      message.success('Provider added successfully');
      form.resetFields(); // Reset the form
      onSuccess(); // Callback to refresh the parent component (e.g., refresh provider list)
      setOpen(false); // Close the modal
    } catch (error) {
      message.error('Failed to add provider');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Add Provider to Patient"
      visible={open}
      onCancel={() => setOpen(false)} // Close the modal on cancel
      footer={null} // No footer buttons, we will use form buttons instead
    >
      <Form form={form} onFinish={handleAddProvider}>
        <Form.Item
          name="providerId"
          label="Select Provider"
          rules={[{ required: true, message: 'Please select a provider' }]}
        >
          <Select placeholder="Select a provider" loading={loading}>
            {providers.map((provider) => (
              <Select.Option key={provider._id} value={provider._id}>
                {provider.firstName} {provider.lastName} {provider.email && <> - {provider.email}</>}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            Add Provider
          </Button>
          <Button onClick={() => setOpen(false)} style={{ marginLeft: 8 }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};