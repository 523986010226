import { Modal, Typography } from 'antd'
import './panelModal.scss'
import { useEffect, useState } from 'react'
import { listTests } from '../../services/test.service'
import TestCode from '../../enums/testCode.enum'

const { Text } = Typography

export const PanelModal = ({ panel, open, setOpen, title='The Instalab Panel' }) => {
  const [tests, setTests] = useState()

  const onCancel = () => {
    setOpen(false)
  }

  useEffect(() => {
    fetchTests()
  }, [])

  const fetchTests = async () => {
    setTests(await listTests())
  }


  const getTestName = (code) => {
    const test = tests?.find(test => test.code === code)
    if (code === TestCode.BP_S) return "Blood Pressure"
    if (code === TestCode.GRIP_RIGHT) return "Grip Strength"

    return test?.name
  }

  const getTestTagline = (code) => {
    const test = tests?.find(test => test.code === code)
    return test?.tagline
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="panel-modal"
      title={title}
      footer={null}
      width={950}
    >
      {panel && <>
        {Object.keys(panel).filter(category => category !== "Biometrics").map(category => (
          <div 
            key={`category-${category}`}
            className='category-section'
          >
            <Text className="category-title">
              {category}
            </Text>

            <div className="category-test-list">
              {panel[category].map(code => (
                <div 
                  key={`category-test-${code}`}
                  className="category-test-item"
                >
                  <div className="test-name">
                    {getTestName(code)}
                  </div>
                  <div className="test-tagline">
                    {getTestTagline(code)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </>}
    </Modal>
  )
}