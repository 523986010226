import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { EditOutlined,DeleteOutlined } from '@ant-design/icons';
import { message, Button, Popconfirm, Tooltip } from 'antd';
import { listReferrals, removeReferral, listReferralsByPatient, removeReferralFromPatient } from "../../services/referral.service.js";
import { PageHeader } from '../pageHeader/pageHeader.component';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import { UserContext } from '../../contexts/user.context.js';
import { Role, ObjectType } from "../../enums/index.enum.js";
import { useParams } from 'react-router-dom'
import { ReferralForm } from './referralForm.component.js';
import UrlHelper from "../../helpers/url.helper.js"
import './adminReferrals.scss';

const select = '_id location scheduleLink type author patient provider';

export const AdminReferrals = ({ hasPatient=false }) => {
  const { patientId } = useParams()
  const [referrals, setReferrals] = useState([]);
  const [referralId, setReferralId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); 
  const [isAdminLocationModalOpen, setIsAdminLocationModalOpen] = useState();
  const [filteredCount, setFilteredCount] = useState();
  const { setCounts, currentUser } = useContext(UserContext); 
  const navigate = useNavigate()

  useEffect(() => {
    fetchReferrals();
  }, [hasPatient, patientId]);

  const fetchReferrals = async () => {
    setIsLoading(true);
    let fetchedReferrals

    if (hasPatient && !patientId) return

    else if (hasPatient && patientId) {
      fetchedReferrals = await listReferralsByPatient(patientId);
    }

    else if (!hasPatient) {
      fetchedReferrals = await listReferrals();
    }
    setReferrals(fetchedReferrals);
    setFilteredCount(fetchedReferrals.length);
    setIsLoading(false);
  };

  const handleRemoveReferral = async (id) => {
    try {
      await removeReferral(id);
      setReferrals(referrals.filter(referral => referral._id !== id));
      setFilteredCount(cachedFilterCount => cachedFilterCount - 1);
      setCounts(cachedCounts => ({
        ...cachedCounts,
        Referrals: cachedCounts.Referrals - 1
      }));
      message.success('Referral removed');
    } catch (error) {
      message.error('Failed to remove referral');
    }
  };

  const handleRemoveReferralFromPatient = async (referralId) => {
    try {
      const updatedPatient = await removeReferralFromPatient({ patientId, referralId });
      setReferrals(updatedPatient.referrals);
      message.success('Referral removed from patient');
    } catch (error) {
      message.error('Failed to remove referral from patient');
    }
  };

  let getActionItems;
  if (hasPatient && patientId) {

    getActionItems = ({ _id }) => [
      {
        key: 'remove-from-patient',
        label: (
          <Popconfirm
            title="Are you sure to remove this referral from the patient?"
            onConfirm={() => handleRemoveReferralFromPatient(_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <DeleteOutlined /> Remove
            </Button>
          </Popconfirm>
        )
      }
    ];

  }

  else if (!hasPatient) {
    getActionItems = ({ _id }) => [
      {
        key: '1',
        label: (
          <Button onClick={() => {
            setReferralId(_id);
            setIsEditModalOpen(true);
          }}>
            <EditOutlined /> Edit
          </Button>
        )
      },
      // {
      //   key: '2',
      //   label: (
      //     <Button onClick={() => {
      //       setReferralId(_id);
      //       setIsAdminLocationModalOpen(true);
      //     }}>
      //       <EnvironmentOutlined /> Set Location
      //     </Button>
      //   )
      // },
      ([Role.ADMIN, Role.PROVIDER].includes(currentUser?.role)) && {
        key: '3',
        label: (
          <Popconfirm
            title="Are you sure to delete this referral?"
            onConfirm={() => handleRemoveReferral(_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <DeleteOutlined /> Delete
            </Button>
          </Popconfirm>
        )
      }
    ];

  }

  const getCustomFilter = (Referral, value) => {
    const searchValue = value.replace(/\s/g, '').toLowerCase();
    const firstName = Referral.firstName ? Referral.firstName.toString().toLowerCase() : '';
    const lastName = Referral.lastName ? Referral.lastName.toString().toLowerCase() : '';
    const fullName = (firstName + lastName).replace(/\s/g, '');
    
    return {
      name: () => fullName.includes(searchValue),
    };
  };


  // Determine columns to display based on user role
  const columns = [
    !patientId && {
      title: 'Patient',
      dataIndex: 'patient',
      render: (patient) =>  <a onClick={() => navigate(UrlHelper.getPatientProfile(patient._id, 'Referrals'))}>{patient?.firstName} {patient?.lastName}</a>
    },
    {
      title: 'Referral Type',
      dataIndex: 'type',
      render: type => type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
    },
    [Role.ADMIN, Role.STAFF].includes(currentUser?.role) && {
      title: 'Referral Location',
      dataIndex: 'location',
      render: (location, { patient }) => {
        if (!location && !patient?.location) return null
        
        const { streetAddress, streetAddress2, city, state, postalCode } = location || patient?.location
        const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
        return <Tooltip title={fullAddress}>{city}, {state}</Tooltip>
      },
    }, 
    [Role.ADMIN, Role.STAFF].includes(currentUser?.role) && {
      title: 'Reason',
      dataIndex: 'reason'
    }, 
    {
      title: 'Referrered Provider',
      dataIndex: 'provider',
      render: (provider) => {

        let fullAddress;
        if (provider?.location)  {
          const { streetAddress, streetAddress2, city, state, postalCode } = provider?.location
          fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
        }
        return <>
          {provider?.scheduleLink ? <a target="_blank" href={provider.scheduleLink}>{provider?.npi && "Dr. "}{provider?.firstName} {provider?.lastName}</a>
          : 
          <>{provider?.npi && "Dr. "}{provider?.firstName} {provider?.lastName}</>}
          {fullAddress && <><br/>{fullAddress}</>}
          {provider?.phone && <><br/>{provider.phone}</>}
        </>
      }
    },
  ];

  if (currentUser?.role === Role.ADMIN) {
    columns.push({
      title: "Referred By",
      dataIndex: 'author',
      render: author => author ? <>{author.firstName} {author.lastName}</> : 'instalab'
    });
  }


  return (
    <div className="admin-referrals">
      <PageHeader
        title='Referrals'
        count={filteredCount}
        actions={(
          hasPatient && patientId ? (
            <Button
              type='primary'
              onClick={() => {
                setIsEditModalOpen(true); // Open the new Add Referral modal
              }}
            >
              + Add Referral
            </Button>
          ) : (
            <Button
              type='primary'
              onClick={() => {
                setReferralId(null);
                setIsEditModalOpen(true);
              }}
            >
              + Add New Referral
            </Button>
          )
        )}
      />

      <AdminLocationModal
        objectId={referralId}
        objectType={ObjectType.Referral}
        open={isAdminLocationModalOpen}
        setOpen={setIsAdminLocationModalOpen}
        select={select}
        onSuccess={referral => {
          setReferrals(referrals.map(p => p._id === referralId ? referral : p));
        }}
      />

      <ReferralForm
        referralId={referralId}
        patientId={patientId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        onSuccess={referral => {
          if (referralId) {
            setReferrals(referrals.map(p => p._id === referralId ? referral : p));
          } else {
            setReferrals([referral, ...referrals]);
            setFilteredCount(cachedFilterCount => cachedFilterCount + 1);
            setCounts(cachedCounts => ({
              ...cachedCounts,
              Referrals: cachedCounts.Referrals + 1
            }));
          }
        }}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={referrals}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={columns}
        rowClassName={(record) => !record.provider ? 'no-provider' : ''} // Apply the class if no provider is set

      />
    </div>
  );
};
