import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const convertMe = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/users/me/convert`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const getHeartHealthTeammates = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/heart-health-teammates`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const getMe = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/me`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const buildUser = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/users/build`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const isUserPending = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/${id}/pending`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const updateMe = async (params={}) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/users/me`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const listAssignees = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/assignees`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const isUserUnique = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/users/unique`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const listUserCounts = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/counts`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}