import React, { useState, useEffect } from 'react'
import { Modal, Form, message, Input, Checkbox, Select } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import "./productTypeForm.scss"
import { addProductType, getProductType, updateProductType } from '../../services/productType.service'
import RuleHelper from '../../helpers/rule.helper'
import FlowType from '../../enums/flowType.enum'

const { Item } = Form

export const ProductTypeForm = ({ open, setOpen, onSuccess, productTypeId, setProductTypeId }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()

  useEffect(() => {
    if (productTypeId) {
      setFields()
    } else {
      form.resetFields()
    }
  }, [productTypeId])

  useEffect(() => {
    if (!open) {
      setProductTypeId(null)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const setFields = async () => {
    const {
      code,
      title,
      cost,
      tagline,
      path,
      flowType,
      hasKit,
      hasAppointment
    } = await getProductType(productTypeId)

    form.setFieldsValue({
      code,
      title,
      cost,
      tagline,
      path,
      flowType,      
      hasKit,
      hasAppointment

    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        code,
        title,
        cost,
        tagline,
        path,
        flowType,
        hasKit,
        hasAppointment
  
      } = form.getFieldsValue()
      
      const params = {
        code,
        title,
        cost,
        tagline,
        path,
        flowType,
        hasKit,
        hasAppointment
  
      }

      const response = productTypeId ? await updateProductType(productTypeId, params) : await addProductType(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(productTypeId ? 'Product type updated' : 'Product type added')
      onCancel()
      form.resetFields()
    } catch (err) {
      let msg = productTypeId ? 'Failed to update product type' : 'Failed to add product type'
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  const sortedFlowTypes = () => {
    return Object.values(FlowType).sort((a, b) => {
      a = a.toLowerCase();
      b = b.toLowerCase();
  
      if (a < b) {
          return -1;
      }
      if (a > b) {
          return 1;
      }
      return 0;
    })
  }

  return (
    <div className="product-type-form">
      <Modal 
        open={open} 
        title={productTypeId ? 'Edit Product Type' : 'Add Product Type'}
        onCancel={onCancel}
        onOk={onOk}
        okText='Save'
        width={600}
        footer={[
          <ContinueButton
            onClick={onOk}
            text='Save Product Type'
            isSubmitting={isSubmitting}
          />
        ]}
      >
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
        >
          <Item 
            label="Title" 
            name="title"
            rules={[
              RuleHelper.isRequired,
            ]}
          >
            <Input placeholder="Title" />
          </Item>
          
          <Item 
            label="Code" 
            name="code"
            rules={[
              RuleHelper.isRequired,
            ]}
          >
            <Input placeholder="Code" />
          </Item>

          <Item 
            label="Cost" 
            name="cost"
            rules={[
              RuleHelper.isRequired,
            ]}
          >
            <Input placeholder="Cost" />
          </Item>

          <Item 
            label="Tagline" 
            name="tagline"
          >
            <Input />
          </Item>

          <Item 
            label="Path" 
            name="path"
          >
            <Input />
          </Item>

          <Item 
            label="Flow Type" 
            name="flowType"
          >
            <Select
              options={sortedFlowTypes().map(flowType => ({
                key: flowType, 
                label: flowType, 
                value: flowType
              }))}
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              optionFilterProp="children"
              showSearch
            />
          </Item>

          <Item label="Has Kit" name="hasKit" valuePropName="checked">
          <Checkbox />
        </Item>

        <Item label="Has Appointment" name="hasAppointment" valuePropName="checked">
          <Checkbox />
        </Item>


        </Form>
      </Modal>
    </div>
  )
}