import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./vitaminBAdvice.scss";
import ReportHelper from "../../../helpers/report.helper";
import { TestView } from "../testView/testView.component";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import ReportSection from "../../../enums/reportSection.enum";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const VitaminBAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [bTests, setBTests] = useState(null);

  // progress
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  
  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.VITAMIN_B_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.VITAMIN_B_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.VITAMIN_B_ADVICE));
  }, [report]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])


  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, isFixed, isBetter, isWorse, bTests]);

  useEffect(() => {
    setBTests(getBTests());
  }, [report, tests, isFixed]);

  const getBTests = () => {
    if (!report || !tests) return null;
    if (isFixed) {
      return ReportHelper.getSectionFixedTests(report, ReportSection.VITAMIN_B_ADVICE, tests);
    }
    return ReportHelper.getSectionTests(report, ReportSection.VITAMIN_B_ADVICE, tests);
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !bTests) return null;
    

    if (isFixed) {
      return (
        <>
          Your <span>vitamin B9</span> and <span>B12</span> are now <span>optimal</span>, which decreases your long-term risk for anemia, heart disease and cognitive decline. 
        </>
      );
    }

    return (
      <>
        Your <span>vitamin B9</span> and <span>B12</span> are <span>low</span>, which increases your long-term risk for anemia, heart disease and cognitive decline. 
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report || !bTests) return null;

    if (isFixed) {
      return (
        <>
          Great job taking care of your B vitamin status! Vitamin B9 and B12 work together as a powerful team, supporting essential processes like DNA synthesis, red blood cell production, and maintaining healthy homocysteine levels.
          <br/><br/>By maintaining healthy levels of these vitamins, you're giving your body the tools it needs to function at its best. Keep up the good work with your dietary choices and supplementation as needed - your body will thank you!
        </>
      )
    }
      return (
      <>
        Vitamin B9 and B12 work in concert for several key processes like DNA synthesis, red blood cell production, maintaining healthy homocysteine levels and much more.
        <br/><br/>Ensuring adequate intake of both these vitamins is crucial for overall health, especially since your body can’t produce them and relies on dietary sources or supplementation.
      </>
    )
  }

  return user && bTests &&(
    <div className="vitamin-b-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">{description}</Paragraph>

      <TestView
        user={user} 
        report={report} 
        tests={bTests}
        results={results}
        reports={reports}
      />

      <AdviceSection 
        report={report}
        section={section}
        results={results}
        tests={tests}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />
    </div>
  );
};
