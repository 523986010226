
import { useState, useEffect, useContext, useRef } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useNavigate, useParams } from 'react-router-dom'
import { listMembershipTypes } from "../../services/membershipType.service"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import Gender from "../../enums/gender.enum"
import MedicalCondition from "../../enums/medicalCondition.enum"
import Ethnicity from "../../enums/ethnicity.enum"
import { UserContext } from "../../contexts/user.context"
import FlowType from "../../enums/flowType.enum"
import "./heartHealthIntake.scss";
import { TassoPopover } from "../tassoPopover/tassoPopover.component"
import { listAnnotatedResults } from "../../services/result.service"
import { listAnnotatedTests } from "../../services/test.service"
import TestCode from "../../enums/testCode.enum"
import { completeFlow } from "../../services/flow.service"

export const Step = {
  ACCOUNT: 'account',
  PURPOSE: 'purpose',
  PAY: 'pay',
  NAME: 'name',
  PHONE: 'phone',
  CONSULT: 'consult',
  GOV_ID: 'gov-id',
  GENDER: 'gender',
  DOB: 'dob',
  FILES: 'files',
  SMOKING: 'smoking',
  ALCOHOL: 'alcohol',
  ETHNICITY: 'ethnicity',
  MEDICATIONS: 'medications',
  MEDICATION_PREFERENCE: 'medication-preference',
  SUPPLEMENTS: 'supplements',
  FAMILY_HISTORY: 'family-history',
  PREGNANCY: 'pregnancy',
  BREASTFEEDING: 'breastfeeding',
  MEDICAL_CONDITIONS: 'medical-conditions',
  LIVER_DISEASE: 'liver-disease',
  BP_MONITOR: 'bp',
  SHIPPING: 'shipping',
  SCHEDULE: 'schedule',
  CONFIRM: 'confirm'
}

const heartHealthConditions = [
  MedicalCondition.ANGINA,
  MedicalCondition.AORTIC_ANEURYSM,
  MedicalCondition.ARRHYTHMIA,
  MedicalCondition.BLOCKED_ARTERY,
  MedicalCondition.HEART_ATTACK,
  MedicalCondition.HEART_DISEASE,
  MedicalCondition.HIGH_BLOOD_PRESSURE,
  MedicalCondition.METABOLIC_SYNDROME,
  MedicalCondition.STROKE,
  MedicalCondition.HIGH_CHOLESTEROL,
  MedicalCondition.TYPE_1_DIABETES,
  MedicalCondition.TYPE_2_DIABETES,
]

export const HeartHealthIntakeFlow = () => {
  const { flowId } = useParams()
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [hasPass, setHasPass] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const { token, currentUser, instalabMembership } = useContext(UserContext)
  const navigate = useNavigate()
  const [initialUser, setInitialUser] = useState()
  const [hasApob, setHasApob] = useState()
  const hasCompletedFlowRef = useRef(false)
  useEffect(() => {
    fetchHasApoB()
  }, [token])

  useEffect(() => {
    fetchSkeleton()
  }, [initialUser, hasPass, membershipTypes, flow, hasApob, instalabMembership])

  useEffect(() => {
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    resetFlowId()
  }, [flowId])

  const getTestIdByCode = (tests, testCode) => {
    if (!tests?.length) return
    return tests.find(({ code }) => code === testCode)?._id
  }

  const getLatestTestValue = (results, testId) => {
    const latestValue = getLatestResult(results, testId)?.values.find(({ test }) => {
      return test === testId
    })?.value
    return typeof latestValue === 'string' ? Number(latestValue) : latestValue
  }

  const getLatestResult = (results, testId) => {
    return results?.find(result => {
      const { values } = result
      return values?.some(({ value, test }) => {
        return value && test === testId
      })
    })
  }

  const fetchHasApoB = async () => {
    if (token) {
      const tests = await listAnnotatedTests()
      const results = await listAnnotatedResults()
      const apobTestId = getTestIdByCode(tests, TestCode.APO_B)
      const apobValue = getLatestTestValue(results, apobTestId)
      setHasApob(apobValue ? true : false)
    } else {
      setHasApob(null)
    }
  }

  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.HEART_HEALTH,
            MembershipTypeCode.LONGEVITY,
            MembershipTypeCode.PREMIUM,
            MembershipTypeCode.PREMIUM_12MO,
            MembershipTypeCode.LONGEVITY_12MO
          ]
        }
      }
    }))
  }

  const resetFlowId = () => {
    if (!flowId) return 
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PURPOSE,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.HEART_HEALTH_INTAKE}`)}>Log in</a>.</>, 
        buttonText: 'Create Account',
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },

      [Step.PURPOSE]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `Why are you interested in joining the Heart Health Program?`,
        description: 'Select all that apply.',
        field: {
          name: 'heartHealthPurpose',
          required: true,
          options: [{
            label: `Family history of cardiovascular issues`,
            value: `Family history of cardiovascular issues`,
          }, {
            label: `Existing cardiovascular issues`,
            value: `Existing cardiovascular issues`,
          }, {
            label: `Improve my overall heart health`,
            value: `Improve my overall heart health`,
          }, {
            label: 'Other',
            value: 'Other',
          }]
        },
      },

      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.gender === Gender.FEMALE) {
            return Step.PREGNANCY
          } else {
            return Step.ETHNICITY
          }
        },
        buildUser: true,
        title: `What is your biological sex at birth?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },

      [Step.PREGNANCY]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.BREASTFEEDING,
        buildUser: true,
        title: `Are you pregnant or planning to be in the next 12 months?`,
        field: {
          name: 'isPregnant',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },

      [Step.BREASTFEEDING]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.ETHNICITY,
        buildUser: true,
        title: `Are you currently breastfeeding?`,
        field: {
          name: 'isBreastfeeding',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },

      [Step.ETHNICITY]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.FAMILY_HISTORY,
        buildUser: true,
        title: `What is your ethnicity?`,
        description: 'Select all that apply.',
        field: {
          name: 'ethnicity',
          options: Object.values(Ethnicity).map(value => ({label: value, value: value}))
        },
        skipIf: () => {
          return initialUser?.ethnicity
        }
      },

      [Step.FAMILY_HISTORY]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: <>Which heart health conditions has your immediate family been diagnosed with <u>before age 60</u>?</>,
        description: 'This includes any grandparents, parents, or siblings. Select all that apply.',
        field: {
          name: 'heartHealthFamilyHistory',
          options: Object.values(MedicalCondition)
                          .filter(value => heartHealthConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label))
        },
      },

      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.SMOKING,
        buildUser: true,
        title: `What's your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },

      [Step.SMOKING]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.ALCOHOL,
        title: `Do you smoke or vape nicotine?`,
        field: {
          name: 'hasSmoking',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      [Step.ALCOHOL]: {
        type: StepType.SINGLE_INPUT,
        buildUser: true,
        nextStep: Step.MEDICAL_CONDITIONS,
        title: `How many alcoholic drinks do you usually consume per week?`,
        field: {
          name: 'alcoholQty',
          placeholder: 'Type quantity here ...',
        },
      },

      [Step.MEDICAL_CONDITIONS]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.LIVER_DISEASE,
        buildUser: true,
        title: <>Which heart health conditions have you been diagnosed with?</>,
        description: 'Select all that apply.',
        field: {
          name: 'heartHealthConditions',
          options: Object.values(MedicalCondition)
                          .filter(value => heartHealthConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label))
        },
      },

      [Step.LIVER_DISEASE]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.MEDICATIONS,
        buildUser: true,
        title: `Do you have cirrhosis or end-stage liver disease?`,
        field: {
          name: 'hasEndStageLiverDisease',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },

      [Step.MEDICATIONS]: {
        type: StepType.TEXTAREA,
        nextStep: Step.MEDICATION_PREFERENCE,
        buildUser: true,
        title: `Please list any prescribed medications you currently take.`,
        description: `Include dosage if you know it.`,
        field: {
          name: 'freeformMedications',
          placeholder: 'Type medications here ...',
        },
      },

      [Step.MEDICATION_PREFERENCE]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.SUPPLEMENTS,
        buildUser: true,
        title: `How open are you to taking prescribed medication?`,
        field: {
          name: 'medicationPreference',
          options: [{
            label: `I'm comfortable taking medication`,
            value: `comfortable`,
          }, {
            label: `Only if absolutely necessary`,
            value: `if_necessary`,
          }, {
            label: `I prefer not to take medication`,
            value: `avoid`,
          }]
        },
      },

      [Step.SUPPLEMENTS]: {
        type: StepType.TEXTAREA,
        nextStep: Step.PAY,
        buildUser: true,
        title: `Please list any supplements or over-the-counter medications you currently take.`,
        description: 'Include dosage if you know it.',
        field: {
          name: 'freeformSupplements',
          placeholder: 'Type supplements here ...',
        }
      },

      [Step.PAY]: {
        type: StepType.PAY,
        onNextStep: async () => {
          if (hasApob) {
            return Step.CONSULT
          } else {
            return Step.SHIPPING
          }
        },
        addSubscription: true,
        subscriptionType: MembershipTypeCode.HEART_HEALTH,
        paymentTypes: ['card'],
        title: <>
          Great news! You've qualify for the Heart Health Program.&nbsp;
          {instalabMembership ? <>
            To get started, it's just <span className="true-price">${getMembershipType(MembershipTypeCode.HEART_HEALTH)?.cost}/month</span>.
          </> : <>
            Get started today for just <span className="true-price">${getMembershipType(MembershipTypeCode.HEART_HEALTH)?.cost + getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span>.
          </>}
        </>,
        description: hasApob ? <>
          In the following steps, you'll be matched with your Instalab physician and schedule your first consultation.
        </> : <>
          In the following steps, you'll provide the required information to receive your first <TassoPopover trigger={<a className="gray-link">at-home blood test kit</a>} /> to assess your heart health.
        </>
      },

      [Step.CONSULT]: {
        type: StepType.CALENDLY,
        nextStep: Step.SHIPPING,
        addConsult: true,
        title: `Schedule your heart health consultation.`,
        description: <>
          You will be meeting with Dr. Steven Winiarski, an expert in longevity medicine, lipidology, and obesity management. 
          <br/><br/>
          During this 30-minute video consultation, Dr. Winiarski will review your medical data and work with you to create a personalized strategy aimed at optimizing your heart health over the next 3 to 6 months.
        </>,
        showFooter: false,
        url: process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest' : 'https://calendly.com/d/3r9-gjk-xjv/heart-health-consultation',
      },

      [Step.SHIPPING]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.BP_MONITOR,
        title: `What is your shipping address?`,
        description: `You will receive your blood test kits, blood pressure monitor, and medication here.`,
        field: {
          name: 'shippingLocation'
        },
      },

      [Step.BP_MONITOR]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `Do you have a blood pressure monitor?`,
        field: {
          name: 'hasBloodPressureMonitor',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },

      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you important notifications about the Heart Health Program.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GOV_ID,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },

      [Step.GOV_ID]: {
        type: StepType.IMAGE_UPLOAD,
        buildUser: true,
        nextStep: Step.FILES,
        title: 'Upload a government-issued ID to verify your identity.',
        description: `This is required by law to get medication prescribed online. A passport, driver's license, or state identification card qualifies.`,
        field: {
          name: 'govId'
        }
      },

      [Step.FILES]: {
        type: StepType.UPLOAD,
        title: 'One more thing...',
        description: <>
          To ensure your Instalab physician can offer the most accurate recommendations, upload any relevant medical documents from the past year. 
          <br/><br/>
          This includes lab results, DEXA scans, and other records that can help us better understand your heart health.
        </>,
        nextStep: Step.CONFIRM
      },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `Thank you for submitting your medical information.`,
        description: hasApob ? <>
          Your video consultation with Dr. Winiarski is confirmed, and a calendar invite will be sent to you shortly. Please check your email for any follow-up questions from Dr. Winiarski that could help make your consultation more effective.
          <br/><br/>
          After the consultation, we'll follow up with the next steps, and if needed, arrange to have your medication shipped to you promptly.
          <br/><br/>
          If you have any questions, please <a onClick={onChat}>send us a message.</a>
        </> : <>
          Your at-home blood test kit will arrive within a week. 
          <br/><br/>
          After completing the tests, you'll schedule a 30-minute video consultation with your Instalab physician to review your medical data and create a personalized heart health plan.
          <br/><br/>
          If you have any questions, please <a onClick={onChat}>send us a message.</a>
        </>,
        content: null,
        buttonText: 'Back to Dashboard',
        path: `/`,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
            
          }
        }
      }
    })
  }

  return (
    <div className="heart-health-intake">
      <Flow 
        skeleton={skeleton} 
        flow={flow}
        setFlow={setFlow}
        startIndex={1}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    </div>
  )
}