import { Timeline, Typography, Button, Row, Col } from 'antd'
import { RightOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import "./heartHealthV3.scss";
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Questions } from '../questions/questions.component';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/user.context';
import { TassoPopover } from '../tassoPopover/tassoPopover.component';
import { BiomarkerPopover } from '../biomarkerPopover/biomarkerPopover.component';
import FlowType from '../../enums/flowType.enum';
import Breakpoint from '../../enums/breakpoint.enum';
import useWidth from '../../hooks/useWidth.hook';
import PhotoHelper from '../../helpers/photo.helper';
import PosthogHelper from '../../helpers/posthog.helper';
import EventCode from '../../enums/eventCode.enum';

const { Text, Paragraph } = Typography

export const HeartHealthV3 = () => {
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)
  const width = useWidth()

  useEffect(() => {
    if (currentUser) {
      PosthogHelper.track(currentUser, EventCode.VISIT_HEART_HEALTH, true)
    }
  }, [currentUser])

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  }

  const onJoin = () => {
    PosthogHelper.track(currentUser, EventCode.CLICK_HEART_HEALTH_CTA, true)
    window.open(`/flow/${FlowType.HEART_HEALTH_INTAKE}`, '_blank')
  }

  const TimelineDot = () => {
    return PhotoHelper.format({
      jpg: "/img/heart/timeline-dot.jpg",
      webp: "/img/heart/timeline-dot.webp",
      className: "timeline-dot"
    })
  }

  const TimelineItem = ({ label, title, description, img }) => {
    return (
      <div className="timeline-item">
        <Row gutter={48}>
          <Col 
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 14 }}
            lg={{ span: 14 }}
            xl={{ span: 14 }}
            xxl={{ span: 14 }}
          >
            <Paragraph className={classNames("heart-label", "highlight", "timeline-label")}>
              {label}
            </Paragraph>
            <Paragraph className={classNames("text-04", "bold", "timeline-title")}>
              {title}
            </Paragraph>
            <Paragraph className={classNames("heart-description", "timeline-description")}>
              {description}
            </Paragraph>
          </Col>

          <Col 
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 10 }}
            lg={{ span: 10 }}
            xl={{ span: 10 }}
            xxl={{ span: 10 }}
          >
            {PhotoHelper.format({
              jpg: `${img}.jpg`,
              webp: `${img}.webp`,
              className: "timeline-img"
            })}
          </Col>
        </Row>
      </div>
    )
  }

  const ResultItem = ({ title, description, reductionColor, reductionDemographics, reductionValue, reductionUnit, reductionLabel }) => {
    return (
      <div className="result-item">
        <Row gutter={12}>
          <Col className="result-text-col">
            <Paragraph className={classNames("text-04", "bold", "result-title")}>
              {title}
            </Paragraph>
            <Paragraph className={classNames("text-03", "secondary-text", "result-description")}>
              {description}
            </Paragraph>
          </Col>

          <Col 
            className="result-reduction-col"
            style={{
              width: width < Breakpoint.SM ? '100%' : 160
            }}
          >
            <div 
              className="reduction"
              style={{
                backgroundColor: reductionColor
              }}
            >
              <Paragraph className={classNames("heart-label", "reduction-label")}>
                {reductionLabel}
              </Paragraph>
              <div className="reduction-details">
                <Text className="reduction-value">
                  {reductionValue}
                </Text>
                <Text className="reduction-unit">
                  {reductionUnit}
                </Text>

                <Paragraph className="reduction-demographics">
                  {reductionDemographics}
                </Paragraph>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const TestimonialItem = ({ image, name, role, quote }) => {
    return (
      <div className="testimonial-item">
        {PhotoHelper.format({
          jpg: `${image}.jpg`,
          webp: `${image}.webp`,
          className: "testimonial-img"
        })} 

        <Paragraph className={classNames('heart-description', "testimonial-name")}>
          {name}
        </Paragraph>
        <Paragraph className={classNames('heart-description', "testimonial-role")}>
          {role}
        </Paragraph>

        <Paragraph className={classNames("testimonial-quote", "text-03")}>
          {quote}
        </Paragraph>
      </div>
    )
  }

  const OfferingItem = ({ image, title, description }) => {
    return (
      <div className="offering-item">
        {PhotoHelper.format({
          jpg: `${image}.jpg`,
          webp: `${image}.webp`,
          className: "offering-img"
        })}

        <Paragraph className={classNames('heart-description', "offering-title")}>
          {title}
        </Paragraph>

        <Paragraph className={classNames('heart-description', "offering-description")}>
          {description}
        </Paragraph>
      </div>
    )
  }

  return (
    <div className="heart-health-v3">
      <div className="heart-header">
        <a onClick={() => navigate('/heart')}>
          {PhotoHelper.format({
            jpg: "/img/logo_full.jpg",
            webp: "/img/logo_full.webp",
            className: "logo-img"
          })}
        </a>

        <div className="heart-header-actions">
          <a
            onClick={onChat}
            className="header-link"
          >
            Contact
          </a>
          
          <Button
            type='primary'
            className="header-btn"
            onClick={onJoin}
          >
            Join Today!
          </Button>
        </div>
      </div>

      <div className={classNames("heart-section", "intro")}>
        <Row>
          <Col 
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 16 }}
            lg={{ span: 16 }}
            xl={{ span: 16 }}
            xxl={{ span: 16 }}
            className="intro-col"
          >
            <div className="intro-content">
              <Paragraph className={classNames("heart-label", "intro-label")}>
                Join <span className="highlight">2,000+</span> patients who've acheived optimal heart health.
              </Paragraph>
              <Paragraph className={classNames("text-05", "bold", "intro-title")}>
                Get on the fast track to <span className="highlight">optimal heart health</span>.
              </Paragraph>
              <Paragraph className={classNames("text-03", "secondary-text", "intro-description")}>
                Work with a dedicated physician to achieve lasting heart health improvements, with noticeable results in 3 months or less. Frequent advanced blood testing monitors your progress at every step.
              </Paragraph>
              <Button
                type="primary"
                className={classNames("heart-btn", "intro-btn")}
                onClick={onJoin}
              >
                Get Started Today <RightOutlined />
              </Button>
            </div>
          </Col>

          <Col 
            xs={{ span: 0 }}
            sm={{ span: 0 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            {PhotoHelper.format({
              jpg: "/img/heart/msg.jpg",
              webp: "/img/heart/msg.webp",
              className: "intro-img"
            })}
          </Col>
        </Row>
      </div>

      <div className={classNames("heart-section", "offerings-section")}>
        <Row gutter={24}>
          <Col 
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 17 }}
            lg={{ span: 17 }}
            xl={{ span: 17 }}
            xxl={{ span: 17 }}
          >
            <div className="offerings-list">
              <Row gutter={24}>
                {[{
                  image: `/img/heart/offering-01`,
                  title: `Personalized Health Plan`,
                  description: `Your Instalab physician designs a plan to meet your unique heart health needs.`,
                }, {
                  image: `/img/heart/offering-03`,
                  title: `Regular Blood Testing`,
                  description: `Track your progress with at-home tests, ensuring the program is on the right track.`,
                }, {
                  image: `/img/heart/offering-02`,
                  title: `Tailored Medications`,
                  description: `Get treatments optimized for what your body responds to best for maximum results.`,
                }].map((props, index) => (
                  <Col 
                    key={`offering-${index}`}
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                    xxl={{ span: 8 }}
                  >
                    <OfferingItem {...props} />
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          
          <Col 
            xs={{ span: 0 }}
            sm={{ span: 0 }}
            md={{ span: 7 }}
            lg={{ span: 7 }}
            xl={{ span: 7 }}
            xxl={{ span: 7 }}
          >
            <div className="price-container">
              <Text className={classNames("heart-title", "price-value")}>
                $149
              </Text>
              <Text className="price-time">
                / month
              </Text>
              <Paragraph className={classNames("heart-description", "price-description")}>
              Achieve optimal heart health with personalized physician guidance that fits seamlessly into your lifestyle.
              </Paragraph>
              <Button
                type='default'
                className={classNames("heart-btn", "price-btn")}
                onClick={onJoin}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </div>


      <div className={classNames("heart-section", "timeline-section")}>
        <Paragraph className={classNames("heart-title")}>
          How does the Heart Health Program work?
        </Paragraph>
        <div className="heart-title-underline" />
        
        <Timeline
          className="heart-timeline"
          items={[{
            dot: <TimelineDot />,
            children: (
              <TimelineItem 
                img="/img/heart/timeline-01"
                label="Today"
                title="See if you're a good fit"
                description={<>
                  Heart disease is the leading cause of death, but it's also the most preventable. No matter your age, the earlier you start, the better. Find out if our program can help you.
                  <br/>

                  <Button
                    type="primary"
                    className={classNames("heart-btn", "timeline-btn")}
                    onClick={onJoin}
                  >
                    <QuestionCircleOutlined /> Am I a Candidate?
                  </Button>
                </>}
              />
            )
          }, {
            dot: <TimelineDot />,
            children: (
              <TimelineItem 
                img="/img/heart/timeline-02"
                label="Within one week or less"
                title="Establish your baseline"
                description={<>
                  Receive an <TassoPopover trigger={<a className="heart-description-link">at-home blood test kit</a>} /> and blood pressure monitor to track <BiomarkerPopover trigger={<a className="heart-description-link">11 key biomarkers</a>}/> for heart health. Your Instalab physician will create a personalized care plan based on your results, medical history and preferences.
                </>}
              />
            )
          }, {
            dot: <TimelineDot />,
            children: (
              <TimelineItem 
                img="/img/heart/timeline-03"
                label="At your convenience"
                title="Align on your care plan with your physician"
                description={<>
                  In this 30-minute consultation, you'll collaborate with your Instalab physician to develop a care plan tailored to your lifestyle. Together, you'll review your health data, target specific biomarkers, and agree on the most effective treatment approach.
                </>}
              />
            )
          }, {
            dot: <TimelineDot />,
            children: (
              <TimelineItem 
                img="/img/heart/timeline-04"
                label="Right after consultation"
                title="Medications and supplements delivered to your door"
                description={<>
                  If your Instalab physician prescribes you supplements or medications, you can choose home delivery or pharmacy pickup. We’ll also coordinate with your health insurance to help reduce costs.
                </>}
              />
            )
          }, {
            dot: <TimelineDot />,
            children: (
              <TimelineItem 
                img="/img/heart/timeline-05"
                label="Continuous tracking and support"
                title="Optimize your treatment with regular monitoring"
                description={<> 
                  Every 6 weeks, track your heart health with an <TassoPopover trigger={<a className="heart-description-link">at-home blood test kit</a>} /> or a lab visit if you prefer. Your Instalab physician will adjust your care plan based on results, iterating until all biomarkers are fully optimized.
                </>}
              />
            )
          }, {
            dot: <TimelineDot />,
            children: (
              <TimelineItem 
                img="/img/heart/timeline-06"
                label="Within 3-6 months on average"
                title="Graduate to optimal heart health"
                description={<> 
                  Achieving optimal heart health is a major milestone—reducing risks and adding years to your life. After reaching this goal, you can switch to our $49/month maintenance option, which includes prescription management and email access to your physician.
                </>}
              />
            )
          }]}
        />
      </div>

      <div className="grey-container">
        <div className={classNames("heart-section", "results-section")}>
          <Paragraph className={classNames("heart-title")}>
            What results can you expect?
          </Paragraph>
          <div className="heart-title-underline" />

          <Paragraph className={classNames("text-04", "secondary-text", "results-description")}>
            Heart health is very complex and influenced by many factors. The Heart Health Program focuses on optimizing the top drivers to really lower your risk of heart disease and add healthy years back to your life.
            {/* Heart health is complex, influenced by many factors like cholesterol, blood pressure, inflammation, and more. Our program simplifies this complexity by focusing on the key drivers that matter most. By optimizing these factors, you can reduce your risk of serious conditions like heart attack, stroke, and diabetes, while adding healthy years to your life. */}
          </Paragraph>

          <Row gutter={24}>
            {[{
              title: `Lower Cholesterol`, 
              description: `Excess cholesterol clogs arteries, raising your risk of heart attacks and strokes. Lowering cholesterol helps reduce this buildup.`,
              reductionLabel: `ApoB Reduction`, 
              reductionColor: `#D9F5F9`,
              reductionValue: `-52`, 
              reductionUnit: `mg/dL`, 
              reductionDemographics: `Average reduction for members >100 mg/dL`
            }, {
              title: `Lower Blood Pressure`, 
              description: `High blood pressure overworks the heart and damages arteries. Reducing it decreases your risk of heart failure, stroke, and kidney disease.`, 
              reductionLabel: `BP Reduction`, 
              reductionColor: `#F0E6F4`,
              reductionValue: `-18`, 
              reductionUnit: `mmHg`, 
              reductionDemographics: `Average reduction for members >140/90 mmHg`
            }, {
              title: `Lower Insulin Resistance`, 
              description: `Improving insulin sensitivity helps regulate blood sugar and reduces inflammation, lowering the risks tied to poor heart health.`, 
              reductionLabel: `HbA1c Reduction`, 
              reductionColor: `#E4EEFB`,
              reductionValue: `-1.4`, 
              reductionUnit: `%`, 
              reductionDemographics: `Average reduction for members > 5.7%`
            }, {
              title: `Lower Inflammation`, 
              description: `Inflammation damages blood vessels and heightens the risk of heart disease. Reducing it protects your cardiovascular system.`, 
              reductionLabel: `hs-CRP Reduction`, 
              reductionColor: `#F7D7E8`,
              reductionValue: `-2.1`, 
              reductionUnit: `mg/L`, 
              reductionDemographics: `Average reduction for members > 3 mg/L`
            }].map((props, index) => (
              <Col 
                key={`result-${index}`}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <ResultItem {...props} />
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <div className={classNames("heart-section", "testimonials-section")}>
        <Paragraph className={classNames("heart-title")}>
          Hear from Heart Health Program members
        </Paragraph>

        <Paragraph className={classNames("text-04", "secondary-text", "testimonials-description")}>
          Thousands of patients have added healthy years to their life—all without leaving the comfort of home.
        </Paragraph>

        <Row gutter={48}>
          {[{
            image: `/img/heart/santi`,
            name: `Santi Mesa`,
            role: `Business Development`,
            quote: `"Instalab has truly changed my approach to health. My doctor didn’t just rush through my results—he took time to explain everything and showed me exactly where I needed improvement. My cholesterol and blood pressure are already improving, and it’s a relief knowing I have real, ongoing support whenever I need it."`,
          }, {
            image: `/img/heart/kenza`,
            name: `Kenza El Madih`,
            role: `Product Manager`,
            quote: `"The Heart Health Program has been a game-changer for me. My doctor really tailored it to fit my specific health concerns, especially around my cholesterol and inflammation. The regular check-ins and coaching have kept me motivated and, for the first time, I feel confident about managing my heart health."`,
          }, {
            image: `/img/heart/aaron`,
            name: `Aaron Cheung`,
            role: `Founder`,
            quote: `"I had struggled with high blood pressure and cholesterol for years. Instalab’s program gave me the structure I needed to get it under control. My doctor kept tweaking my treatment plan based on my test results until we found what worked. Now, my biomarkers are optimal and I’m grateful for the care and guidance that got me here."`,
          }].map(props => (
            <Col 
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TestimonialItem {...props} />
            </Col>
          ))}
        </Row>
      </div>

      <div className="grey-container">
        <div className={classNames("heart-section", "faq-section")}>
          <Paragraph className={classNames("heart-title")}>
            Heart Health FAQs
          </Paragraph>

          <Paragraph className={classNames("text-04", "secondary-text", "faq-description")}>
          Still have questions?   <a className="heart-description-link" onClick={onChat}>Send us a message</a>.
          </Paragraph>

          <Questions
            items={[{
              question: `How much does the Heart Health Program cost?`,
              answer: <>
                The Heart Health Program is $149 per month and includes personalized physician consultations, advanced blood testing, and prescription management, with automatic refills sent to you. Your Instalab physician will work with you through an iterative process to find the best treatment until you reach optimal heart health. This process typically takes 3-6 months on average.
                <br/><br/>
                Once you've achieved your goals, you can move to a $49 per month maintenance plan, which includes prescription management and continued access to your Instalab physician by email.
              </>,
            }, {
              question: `How do I know if I'm a good candidate for the Heart Health Program?`,
              answer: <>
                Heart disease is the #1 leading cause of preventable death, yet most people don't address these issues until it's too late. At Instalab, we recommend being proactive, whether you're looking to improve your overall heart health or reduce future risks. It just takes a few minutes to find out if you're a good fit—<a className="heart-description-link" onClick={onJoin}>click here</a> to take a short survey.
              </>,
            }, {
              question: `Who will be my dedicated Instalab physician?`,
              answer: <>
                Instalab physicians bring decades of expertise in longevity and heart health. As you work together to optimize your health, your physician will take the time to fully understand your needs. Unlike the rushed 10-minute appointments you might be used to, Instalab ensures your doctor has a comprehensive view of your health, allowing them to make the best decisions and support you at every stage of your journey.
              </>,
            }, {
              question: `Which medications do Instalab physicians consider prescribing?`,
              answer: <>
                Heart health is a complex balance of cholesterol, blood pressure, inflammation, and insulin resistance. Your Instalab physician will identify the key areas that need attention and prescribe treatments that deliver maximum results with minimal side effects. Medications like metformin, statins (e.g., rosuvastatin), cholesterol absorption inhibitors (e.g., ezetimibe), and PCSK9 inhibitors (e.g., alirocumab) are tailored to your unique needs.
                <br/><br/>
                We work with your health insurance to secure coverage, and every 6 weeks, we track your progress with blood tests to confirm your treatment is effective, making adjustments as needed.
              </>
            }, {
              question: `Will my health insurance cover the Heart Health Program?`,
              answer: <>Currently, the program is not covered by health insurance. However, participants can utilize their HSA/FSA cards. We can provide itemized receipt for reimbursement purposes.</>
            }]}
          />
        </div>
      </div>
    </div>
  )
}