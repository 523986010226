import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Input, Button, message, Select } from 'antd'
import "./modalForm.scss"
import FormHelper from '../flow/helpers/form.helper'
import ObjectType from '../../enums/objectType.enum'
import { getAdmin, updateAdmin, addAdmin } from '../../services/admin.service';
import { getPhlebotomist, updatePhlebotomist, addPhlebotomist } from '../../services/phlebotomist.service';
import { getPatient, updatePatient, addPatient } from '../../services/patient.service';
import { getProvider, updateProvider, addProvider } from '../../services/provider.service';
import { getFacility, updateFacility, addFacility } from '../../services/facility.service';
import { getStaff, updateStaff, addStaff } from '../../services/staff.service';
import { getAppointment, getAppointmentParent, updateAppointment, updateAppointmentParent, addAppointment, addAppointmentParent } from '../../services/appointment.service';
import FieldType from './enums/fieldType.enum'

const { Item } = Form

const addObject = {
  [ObjectType.PHLEBOTOMIST]: addPhlebotomist,
  [ObjectType.ADMIN]: addAdmin,
  [ObjectType.PATIENT]: addPatient,
  [ObjectType.PROVIDER]: addProvider,
  [ObjectType.APPOINTMENT]: addAppointment,
  [ObjectType.FACILITY]: addFacility,
  [ObjectType.STAFF]: addStaff,
  [ObjectType.APPOINTMENT_PARENT]: addAppointmentParent,
}

const updateObject = {
  [ObjectType.PHLEBOTOMIST]: updatePhlebotomist,
  [ObjectType.ADMIN]: updateAdmin,
  [ObjectType.PATIENT]: updatePatient,
  [ObjectType.PROVIDER]: updateProvider,
  [ObjectType.APPOINTMENT]: updateAppointment,
  [ObjectType.FACILITY]: updateFacility,
  [ObjectType.STAFF]: updateStaff,
  [ObjectType.APPOINTMENT_PARENT]: updateAppointmentParent,
}

const getObject = {
  [ObjectType.PHLEBOTOMIST]: getPhlebotomist,
  [ObjectType.ADMIN]: getAdmin,
  [ObjectType.PATIENT]: getPatient,
  [ObjectType.PROVIDER]: getProvider,
  [ObjectType.APPOINTMENT]: getAppointment,
  [ObjectType.FACILITY]: getFacility,
  [ObjectType.STAFF]: getStaff,
  [ObjectType.APPOINTMENT_PARENT]: getAppointmentParent
}

export const ModalForm = ({ open, setOpen, onSuccess, objectType, objectId, fields=[], populate=[], select }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (open) {
      if (objectId) setFormFields()
    } else {
      form.resetFields()
      setHasAttempt(false)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch (errors) {
      return true
    }
    return false
  }

  const setFormFields = async () => {
    const object = await getObject[objectType](objectId)
    for (const field of fields) {
      const fieldValue = object[field.name]
      if (fieldValue) {
        form.setFieldsValue({
          [field.name]: fieldValue
        })
      }
    }
  }

  const onOk = async () => {
    setHasAttempt(true)
    setIsSubmitting(true)

    try {
      if (await hasFormError()) {
        message.error('Enter valid data')
        setIsSubmitting(false)
        return 
      }

      const params = {
        fields: form.getFieldsValue(),
        populate,
        select
      }
      const response = objectId ? await updateObject[objectType](objectId, params) : await addObject[objectType](params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(objectId ? `Updated ${objectType}` : `Added ${objectType}`)
      setOpen(false)
      if (!objectId) {
        form.resetFields()
      }
    } catch (err) {
      message.error(objectId ? `Failed to update ${objectType}` : `Failed to add ${objectType}`)
    }
    setIsSubmitting(false)
  }

  return (
    <Modal 
      open={open} 
      title={objectId ? `Edit ${objectType}` : `Add ${objectType}`}
      onCancel={onCancel}
      onOk={onOk}
      okText='Save'
      footer={null}
    >
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
        className="modal-form"
      >
        {fields.map(({
          label,
          name,
          fieldType,
          rules=[],
          options=[],
        }) => (
          <Item 
            label={label}
            name={name}
            rules={rules}
            validateTrigger='onSubmit'
          >
            {fieldType === FieldType.SELECT && (
              <Select 
                onChange={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
                options={options}
              />
            )}

            {fieldType === FieldType.INPUT && (
              <Input 
                onChange={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
                options={options}
              />
            )}
          </Item>
        ))}

        <Button
          className="submit-btn"
          type='primary'
          onClick={onOk}
          loading={isSubmitting}
        >
          Save
        </Button>
      </Form>
    </Modal>
  )
}