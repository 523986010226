import { Button, Image, Col, Row, Tooltip } from "antd";
import { EventType, Breakpoint } from "../../../enums/index.enum";
import { addEvent } from "../../../services/event.service";
import { EyeFilled, AmazonCircleFilled, InfoCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import parse from 'html-react-parser';
import "./productBox.scss";

export const ProductBox = ({product, style}) => {
  const width=800
  const onAmazon = async (url) => {
    await addEvent({ eventType: EventType.CLICK_PRODUCT, meta: {url: url} });
    window.open(url, "_blank");
  };


  const BuyButton = ({ buyUrl, buyText }) => (
    <Button
      type="primary"
      icon={(width > Breakpoint.SM) ? buyUrl.includes("amazon.com")?<AmazonCircleFilled /> : buyUrl.includes("instalab.com") ? <CalendarOutlined /> : <EyeFilled /> : null}
      onClick={() => onAmazon(buyUrl)}
      className="advice-buy-btn"
      style={{paddingRight: width <= Breakpoint.SM && 20, paddingLeft: width <= Breakpoint.SM && 20}}
    >
      {buyText}
    </Button>
  );

  return (
    product &&  (
      <div className="product-box" style={style}>
        {product.image && 
        <div className="product-box-header">Recommended Brand 
          <Tooltip title="Instalab provides independent advice and does not profit from endorsements."><InfoCircleOutlined style={{marginLeft:5}}/></Tooltip>
        </div>}
        <Row className="product-box-main">
          {product.image && 
          <Col span={4}>
            <Image src={product.image} preview={false}/>    
          </Col>}

          <Col span={product.image ? 18 : 24} push={product.image ? 2 : 0 }>
            <div className="product-title">{product.brandName}</div>
            <div className="product-directions">{product.directions ? parse(product.directions) : null}</div>
            <div className="product-buy">
              {product.buyUrl && (
                <BuyButton buyUrl={product.buyUrl} buyText={product.buyText} />
              )}
            </div>

          </Col>
        </Row>
      </div>
    )
  );
};
