import { useEffect, useState } from "react"
import { Row, Col, Button, Typography, Tooltip } from 'antd'
import { advancedPanel } from "../../data/advancedPanel.data"
import { ArrowRightOutlined } from '@ant-design/icons'
import { listTests } from "../../services/test.service"
import { useNavigate } from "react-router-dom";
import { TestCode } from "../../enums/index.enum";
import "./biomarkerPanel.scss"
import { PgFooter } from "../pgFooter/pgFooter.component"
import FlowType from "../../enums/flowType.enum"

const { Paragraph } = Typography

const BiomarkerPanel = () => {
  const navigate = useNavigate()
  const [tests, setTests] = useState()

  useEffect(() => {
    fetchTests()
    setDocumentTitle()
  }, [])

  const setDocumentTitle = () => {
    document.title = 'Longevity Blood Panel | Instalab'
  }

  const fetchTests = async () => {
    setTests(await listTests())
  }

  const getTest = code => {
    return tests.find(t => t.code === code)
  }

  const onScheduleBloodDraw = () => {
    navigate(`/flow/${FlowType.LONGEVITY_TEST}`)
  }

  return tests?.length > 0 && (
    <div className="biomarker-panel">
      <div className="panel-header">
        <Paragraph className="panel-title">
          Longevity Panel
        </Paragraph>

        <Paragraph className="panel-subtitle">
          This panel of 60+ blood biomarkers has been carefully curated by medical experts from diverse specialties, providing a comprehensive assessment of your ever-evolving health. 
        </Paragraph>

        <Button 
          type='primary'
          className="main-cta-btn"
          onClick={onScheduleBloodDraw}
        >
          Schedule Blood Draw <ArrowRightOutlined />
        </Button>
      </div>

      <div className="category-list">
        {Object.keys(advancedPanel).map(category => (
          <div 
            key={`category-${category._id}`}
            className="category-item"
          >
            <div className="category-item-content">
              <Row>
                <Col 
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 10 }}
                  lg={{ span: 10 }}
                  xl={{ span: 10 }}
                  xxl={{ span: 10 }}
                >
                  <Paragraph className="category-title">
                    {category}
                  </Paragraph>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 14 }}
                  lg={{ span: 14 }}
                  xl={{ span: 14 }}
                  xxl={{ span: 14 }}
                >
                  <Row>
                    {advancedPanel[category].map(testCode => (
                      <Col 
                        key={`test-${testCode}`} 
                        span={12}
                      >
                        <Tooltip title={getTest(testCode).tagline} placement='topLeft'>
                          <div className="category-test">
                            {getTest(testCode).name.replace(/\(Right\)/g, '').replace(/\(Systolic\)/g, '')}
                            {testCode === TestCode.PSA_TOTAL && <> (male only)</>}
                          </div>
                        </Tooltip>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        ))}
      </div>

      <PgFooter />
    </div>
  )
}

export default BiomarkerPanel