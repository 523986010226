import { RiskLevel } from "../enums/index.enum.js";
const TestHelper = {
  formatRange: (range) => {
    if (!range) return null
    let {
      lessThan,
      lessThanOrEqual,
      greaterThan,
      greaterThanOrEqual,
      equal
    } = range
    if (equal !== undefined && equal !== null) return equal
    if (lessThanOrEqual && greaterThanOrEqual) return `${greaterThanOrEqual} - ${lessThanOrEqual}`
    if (lessThan && !greaterThan && !greaterThanOrEqual) return `<${lessThan}`
    if (lessThanOrEqual && !greaterThan && !greaterThanOrEqual) return `≤${lessThanOrEqual}`
    if (greaterThan && !lessThan && !lessThanOrEqual) return `>${greaterThan}`
    if (greaterThanOrEqual && !lessThan && !lessThanOrEqual) return `≥${greaterThanOrEqual}`
    if (lessThan && greaterThanOrEqual) return `≥${greaterThanOrEqual} and <${lessThan}`
    if (lessThan && greaterThan) return `>${greaterThan} and <${lessThan}`
    if (greaterThan && lessThanOrEqual) return `>${greaterThan} and ≤${lessThanOrEqual}`
    return null
  },
  getLongevityRisk: (healthScore) => {
    if (!healthScore || typeof healthScore !== 'number') return null
    
    if (healthScore>=90) {
      return RiskLevel.OPTIMAL;
    } else if (healthScore>=75) {
      return RiskLevel.MODERATE;
    } else {
      return RiskLevel.HIGH;
    }

  }
}

export default TestHelper
