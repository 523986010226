import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./vitaminB12Advice.scss";
import TestCode from "../../../enums/testCode.enum";
import Diet from "../../../enums/diet.enum";
import ReportHelper from "../../../helpers/report.helper";
import { TestChart } from "../testChart/testChart.component";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import QuestionTemplateCode from "../../../enums/questionTemplateCode.enum";
import Supplement from "../../../enums/supplement.enum";
import ReportSection from "../../../enums/reportSection.enum";
import Condition from "../../../enums/condition.enum";
import { TestModal } from "../../testModal/testModal.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const VitaminB12Advice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [riskLevel, setRiskLevel] = useState();
  const [isMeatEater, setIsMeatEater] = useState();
  const [isTakingB12, setIsTakingB12] = useState();
  const [hasSIBO, setHasSIBO] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [openModal, setOpenModal] = useState();
  const [testId, setTestId] = useState();

  // progress
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  
  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.VITAMIN_B12_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.VITAMIN_B12_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.VITAMIN_B12_ADVICE));
  }, [report]);

  useEffect(() => {
    setRiskLevel(getRiskLevel());
  }, [tests, results, report]);

  useEffect(() => {
    setIsMeatEater(getIsMeatEater());
    setIsTakingB12(getIsTakingB12());
    setHasSIBO(getHasSIBO());
  }, [user, report]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])


  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [riskLevel, report, isFixed, isBetter, isWorse]);

  const getIsTakingB12 = () => {
    const answer = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_SUPPLEMENTS);
    return answer?.includes(Supplement.VITAMIN_B12);
  };

  const getRiskLevel = () => {
    if (!tests || !results || !report) return null;
    return ReportHelper.getTestRisk(TestCode.VITAMIN_B12, report, results, tests
);
  };

  const getIsMeatEater = () => {
    if (!user) return null;
    const { diet } = report?.patient;
    return !diet?.includes(Diet.VEGAN) && !diet?.includes(Diet.VEGETARIAN);
  };

  const getHasSIBO = () => {
    if (!user || !report) return null;
    const { result } = report 
    return result?.conditions?.includes(Condition.SIBO)
  }

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !riskLevel) return null;
    const { result } = report 
    const hasHighVitaminB12 = result?.conditions?.includes(Condition.HIGH_VITAMIN_B12)

    if (hasHighVitaminB12) {
      return (
        <>Your <span>vitamin B12</span> is <span>too high</span>{isTakingB12 && <>, likely due to your supplementation</>}.</>
      )
    }

    if (isFixed === true) {
      return (
        <>
          Your <span>vitamin B12</span> is now{" "}
          <span>optimal</span>, which decreases your risk
          of anemia, heart disease, and dementia.
        </>
      );
    }

    else if (isBetter) {
      return (
        <>
          Your <span>vitamin B12</span> is{" "}
          <span>improving</span>, which decreases your risk
          of anemia, heart disease, and dementia.
        </>
      );
    }

    else if (isWorse) {

      if (isTakingB12) {
        return (
          <>
            Your <span>vitamin B12</span> is{" "}
            <span>decreasing</span>, despite supplementation.
          </>
        );

      }
      else 
        return (
          <>
            Your <span>vitamin B12</span> is{" "}
            <span>decreasing</span>, which increases your long-term risk
            of anemia, heart disease, and dementia.
          </>
        );
    }

    else if (isTakingB12) {
      return (
        <>
          Your <span>vitamin B12</span> is{" "}
          <span>{ReportHelper.getRiskEval(TestCode.VITAMIN_B12, report, results, tests)}</span>, despite taking a vitamin
          B12 supplement.
        </>
      );
    }

    return (
      <>
        Your <span>vitamin B12</span> is{" "}
        <span>{ReportHelper.getRiskEval(TestCode.VITAMIN_B12, report, results, tests)}</span>, which if left uncorrected for years, may increase your risk of anemia, heart disease, and dementia.
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report || !riskLevel) return null;
    const { result } = report 
    const hasHighVitaminB12 = result?.conditions?.includes(Condition.HIGH_VITAMIN_B12)

    if (hasHighVitaminB12) {
      return <>Vitamin B12 is essential for maintaining brain function, nerve health, and red blood cell production. Unlike some other vitamins, there's no established upper intake limit due to its low toxicity. Excess B12 is usually excreted in urine, making it generally safe even at higher doses.<br/><br/>However, extremely high levels may cause rare side effects such as dizziness, headaches, anxiety, nausea, and vomiting. In some cases, skin issues like acne or rosacea can occur. To avoid potential risks, it’s best to stay within the optimal range for B12.</>
    }

    if (isFixed) {
      if (isTakingB12)
        return <>Congrats on geting your vitamin B12 to optimal levels! Keep up with your supplement reigmen and check back in 3 months to ensure it remains optimal.</>
      else 
        return <>Vitamin B12 is a nutrient that helps convert food into energy for your body and maintain healthy nerve cells. Congrats on geting your vitamin B12 to optimal levels! </>
    }
    
    else if (isBetter) {
      if (isTakingB12)
        return <>Great job increasing your vitamin B12 level! Make sure you stick to a vitamin B12 supplement regimen, so you can hit an optimal level by your next blood draw.</>
    }

  
      return (
      <>Vitamin B12 is a nutrient that helps convert food into energy for your
      body and maintain healthy nerve cells. {isTakingB12 && <>Low levels increase your risk of anemia, heart disease, and dementia</>}{" "}

      {isMeatEater ? (
        <>
        <br/><br/>
        {isTakingB12 ? 
          <>While supplementation is the easiest way to source this nutrient, you can also get it from the food you eat, especially animal products
          like meat, dairy, and eggs.</>
        : 
        <>This nutrient primarily comes from the food you eat, especially animal products
        like meat, dairy, and eggs. You can also boost your vitamin B12 levels by taking a supplement.</>}
        
        </>
      ) : (
        <>
        <br/><br/>
          It's common to have low vitamin B12 levels if you don't consume
          animal products{hasSIBO && <> and have SIBO</>}. {!isTakingB12 ? <>Luckily, you can easily increase your levels by
          taking a supplement.</> : <>Continue supplementation of vitamin b12 and check back in 3 months to confirm improvement.</>}
        </>
      )}</>      
    )
  }

  return user && riskLevel &&(
    <div className="vitamin-b12-advice">
      <TestModal
        open={openModal}
        setOpen={setOpenModal}
        testId={testId}
        report={report}
        tests={tests}
        results={results}
        reports={reports}
      />
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">{description}</Paragraph>

      <TestChart 
        code={TestCode.VITAMIN_B12} 
        report={report} 
        tests={tests}
        results={results}
        reports={reports}
      />

      <AdviceSection 
        report={report}
        section={section}
        results={results}
        tests={tests}
        setTestId={setTestId}
        setOpenModal={setOpenModal}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />
    </div>
  );
};
