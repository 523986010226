import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listConsults = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/consults`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const addConsult = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/consults`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addCalendlyConsult = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/consults/calendly`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updateConsult = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/consults/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeConsult = async (id, params) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/consults/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}