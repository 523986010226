import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Select, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { uploadFile } from '../../services/gdrive.service';

const { Item } = Form;

export const UploadFileModal = ({ isModalVisible, setIsModalVisible, patients, fetchPatientsAndFiles }) => {
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    if (patients && patients.length === 1) {
      form.setFieldsValue({ patient: patients[0]._id });
    }
  }, [patients, form]);

  const onFormFinish = async (values) => {
    setIsLoading(true)
    try {
      if (!fileList.length) {
        message.error("Please select files to upload.");
        return;
      }

      const formValues = form.getFieldsValue();
      const selectedPatientId = formValues.patient;
      const userId = !selectedPatientId && patients && patients.length === 1 
        ? patients[0]._id 
        : selectedPatientId;

      // Upload all files in parallel
      const uploadPromises = fileList.map(file => {
        const formData = new FormData();
        formData.append('file', file.originFileObj);
        formData.append('userId', userId);
        return uploadFile(formData);
      });

      await Promise.all(uploadPromises);
      message.info('Files uploaded successfully');
      setIsModalVisible(false);
      setFileList([]);
      form.resetFields();
      fetchPatientsAndFiles();
    } catch (error) {
      console.error('Upload failed:', error);
      message.error('Upload failed');
    }
    setIsLoading(false)
  };

  const handleBeforeUpload = (file) => false;
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const onRemove = () => setFileList([]);

  return (
    <Modal
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        <Button key="back" onClick={() => setIsModalVisible(false)}>Cancel</Button>,
        <Button key="submit" loading={isLoading} type="primary" onClick={() => form.submit()}>Upload</Button>,
      ]}
    >
      <h2>Add files</h2>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        {patients && patients.length > 1 && (
          <Item label="Select Patient" name="patient" rules={[{ required: true, message: 'Please select a patient!' }]}>
            <Select
              showSearch
              placeholder="Select a patient..."
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {patients.map(patient => (
                <Select.Option key={patient._id} value={patient._id}>{`${patient.firstName} ${patient.lastName}`}</Select.Option>
              ))}
            </Select>
          </Item>
        )}
        <Item name="file" valuePropName="fileList" getValueFromEvent={handleChange}>
          <Upload
            multiple={true}
            onRemove={onRemove}
            beforeUpload={handleBeforeUpload}
            onChange={handleChange}
            fileList={fileList}
          >
            <Button icon={<UploadOutlined />}>Select Files</Button>
          </Upload>
        </Item>
      </Form>
    </Modal>
  );
};
