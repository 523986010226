import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState, useRef } from "react"
import ComprehensiveTestIntro from "./custom/comprehensiveTestIntro/comprehensiveTestIntro.component"
import { completeFlow } from "../../services/flow.service"

export const Step = {
  INTRO: 'intro',
}

export const ComprehensiveTestFlow = () => {
  const [flow, setFlow] = useState()
  const [prices, setPrices] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  const skeleton = {
    [Step.INTRO]: {
      type: StepType.CUSTOM,
      nextStep: Step.EMAIL,
      content: ({ footer }) => <ComprehensiveTestIntro footer={footer} />,
      onLoad: async () => {
        if (flow?._id) {
          await completeFlow(flow._id)
        }
      }
    },
  }

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      setPrices={setPrices}
      prices={prices}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
    />
  )
}