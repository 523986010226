import { Modal, Form, message, Input, Button, Select, Popconfirm } from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import { updateReferral, addReferral, getReferral } from "../../services/referral.service.js";
import { ContinueButton } from '../continueButton/continueButton.component';
import { ReferralType } from "../../enums/index.enum.js";
import { listProviders } from '../../services/provider.service.js';
import { listPatients } from '../../services/patient.service.js';
import RuleHelper from '../../helpers/rule.helper.js';
import { UserContext } from '../../contexts/user.context.js';
import Org from '../../enums/org.enum.js';

const select = '_id location reason type author patient';
const populate = [{path: 'patient'}];
const { Item } = Form;

export const ReferralForm = ({ open, setOpen, referralId, patientId, onSuccess }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [providers, setProviders] = useState([]);
  const [patients, setPatients] = useState([]);
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    fetchProviders(); 
    fetchPatients();
  }, []);

  useEffect(() => {
    if (open && referralId) {
      setReferralFields();
    } else {
      form.resetFields();

      // Automatically set the patient field if patientId is passed
      if (patientId) {
        form.setFieldsValue({ patient: patientId });
      }
    }
  }, [open, referralId, patientId]);

  const fetchProviders = async () => {
    const providerData = await listProviders({filter: {org: Org.INSTALAB}});
    const filteredProviders = providerData.filter(provider => provider.firstName && provider.lastName).sort((a, b) => a.firstName.localeCompare(b.firstName));
    setProviders(filteredProviders);
  };

  const fetchPatients = async () => {
    const patientData = await listPatients();
    const filteredPatients = patientData.filter(patient => patient.firstName && patient.lastName).sort((a, b) => a.firstName.localeCompare(b.firstName));
    setPatients(filteredPatients)
  }

  
  const setReferralFields = async () => {
    const {
      patient,
      type,
      provider,
      reason
     } = await getReferral(referralId, { select, populate });
    form.setFieldsValue({ 
      patient: patient ? patient._id : null,
      type,
      provider,
      reason
    });
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onOk = async () => {
    setIsSubmitting(true);
    try {
      const { 
        patient,
        type,
        provider,
        reason
      } = form.getFieldsValue();

      // Ensure patientId is included if it's provided as a prop
      const selectedPatient = patientId || patient;

      const params = { 
        fields: {
          patient: selectedPatient,
          type,
          provider,
          author: currentUser,
          reason
        },
        select,
        populate
      };
      const response = referralId ? await updateReferral(referralId, params) : await addReferral(params);
      if (onSuccess) {
        onSuccess(response);
      }
      message.success(referralId ? 'Referral updated' : 'Referral added');
      setOpen(false);
      form.resetFields(); // Resetting form fields in both cases
    } catch (err) {
      let msg = referralId ? 'Failed to update Referral' : 'Failed to add Referral';
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email';
      }
      message.error(msg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal 
      open={open}
      title={referralId ? 'Edit Referral' : 'Add Referral'}
      onCancel={onCancel}
      onOk={onOk}
      okText='Save'
      footer={[
        <ContinueButton
          key="submit"
          onClick={onOk}
          text='Save'
          isSubmitting={isSubmitting}
        />
      ]}
    >
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
        className="admin-referrals-form"
      >
       {!referralId && !patientId && (
          <Item 
            label="Patient" 
            name="patient"
            rules={[
              RuleHelper.isRequired
            ]}
            validateTrigger={[]}
          >
            <Select 
              placeholder="Select patient..."
              options={patients?.map(patient => {
                return {
                  label: `${patient.firstName} ${patient.lastName}`,
                  value: patient._id
                }
              })}
              // onChange={() => {
              //   if (hasAttemptRef.current) {
              //     FormHelper.fetchHasError(form)
              //   }
              // }}
              optionFilterProp="children"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              className="single-select"
              showSearch
            />
          </Item>
        )}

        <Item
          name="type"
          label="Referral Type"
          rules={[{
            required: true,
            message: 'Select referral type'
          }]}
        >
          <Select
            placeholder="Select referral type"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            options={Object.values(ReferralType).sort().map(referralType => {
              return {
                label: referralType,
                value: referralType,
              }
            })}
          />
           </Item>

        <Item
          name="provider"
          label="Provider"
        >
        <Select
          placeholder="Select provider"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => 
            option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
          }
          options={providers.map(provider => ({
            label: `${provider.firstName} ${provider.lastName}`,
            value: provider._id // Assuming each provider has a unique ID
          }))}/>
        </Item>

        <Item
          name="reason"
          label="Reason"
        >
            <Input placeholder="Type reason ..."/>
          </Item>
       
      </Form>
    </Modal>
  );
};