import { Typography } from "antd"
import "./terms.scss";
import { PgFooter } from "../pgFooter/pgFooter.component";
import { useEffect } from "react";

const { Text, Paragraph } = Typography

export const Terms = () => {
  useEffect(() => {
    document.title = 'Instalab | Terms and Conditions'
  }, [])

  return <>
    <div className="terms">
      <Text className="terms-header">
        Terms and Conditions
      </Text>
      <Text className="terms-subheader">
        Updated: Jan 1, 2024
      </Text>

      <Paragraph className="terms-paragraph">
        These Terms and Conditions ("Terms and Conditions") form a legal agreement between Instalab, Inc. and you, the individual ("you" and "your") concerning your access to and use of the various services offered through the Websites and the Platforms (as defined below). Use of any of the Websites or Platforms constitutes your acceptance of these Terms and Conditions and the Web and Mobile Privacy Policy.
      </Paragraph>

      <Paragraph className="terms-paragraph">
        Instalab, Inc. on its own behalf and on behalf of its affiliates and/or wholly owned subsidiaries including but not limited to Instamedical, P.C. (collectively referred to as "Instalab", "we", "us", or "our"), Instalab makes certain telehealth related information available to you and/or facilitates your access to telemedicine and expert medical services ("Instalab Services"). Instalab owns and operates (i) various publicly available websites, including without limitation, www.instalab.com, www.instamedical.org (collectively, "Websites") and (ii) various web-based and mobile applications that require you to create an account in order to use a Instalab Service (collectively, "Platforms").
      </Paragraph>

      <Paragraph className="terms-paragraph">
        By using the Websites and/or the Platforms, you expressly agree to these Terms and Conditions. If you do not agree to these Terms and Conditions, you should immediately cease all use of and access to all of the Websites and Platforms. Please print a copy of these Terms and Conditions for your records.
      </Paragraph>

      <Text className="terms-title">
        Modifications to these terms and conditions
      </Text>
      <Paragraph className="terms-paragraph">
        Instalab may in its sole discretion, without prior notice to you, revise these Terms and Conditions at any time. Should these Terms and Conditions change materially, Instalab will update the Effective Date noted above and post a notice regarding the updated Terms and Conditions on the Websites. The amended Terms and Conditions will also appear when the Platforms are accessed by you and you will need to acknowledge your agreement to the amended Terms and Conditions prior to being able to continue to use the Platforms. If you do not agree to the terms of the amended Terms and Conditions, your sole and exclusive remedy is to discontinue your use of the Websites and Platforms and you will be deemed to have terminated these Terms and Conditions. Amended Terms and Conditions will be effective as of the Effective Date unless otherwise stated. By accessing or using the Websites and the Platforms after such changes are posted you agree and consent to all such changes.
      </Paragraph>

      <Text className="terms-title">
        Access to the platforms
      </Text>
      <Paragraph className="terms-paragraph">
        You may access and use the Platforms via remote access connectivity. Instalab grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to use the Platforms in accordance with these Terms and Conditions. In order to use the Platforms, you will be asked to register an account and create login information, including without limitation, username and passwords. You must safeguard your login information that you use to access the Platforms and you must not disclose this information to anyone. You must immediately notify Instalab of any unauthorized use of your user account or of any other breach of security that you become aware of involving and relating to the Platforms by email to privacy@instalab.com.
      </Paragraph>

      <Text className="terms-title">
        Instalab's role
      </Text>
      <Paragraph className="terms-paragraph">
        Instalab's role is to make certain telehealth related information available to you and/or facilitate your access to telemedicine and expert medical services. Instalab is independent from healthcare providers who will be providing such telemedicine services to you through the Platform and is not responsible for such healthcare providers' acts, omissions or for any content of the communications made by them. Instalab does not engage in the practice of medicine or provide any other health services.
      </Paragraph>

      <Text className='terms-title'>Our fullfillment policy</Text>
      <Paragraph className="terms-paragraph">
      Our commitment is to help faciliate high-quality telehealth services. This section outlines our process for service activation, diagnostic testing, consultations, and memberships, including scheduling, cancellations, and refunds.
      <ol>
        <li>Service Activation
            <ul>
              <li>Service Confirmation: Upon selecting a service, you will receive an email confirmation. This confirmation signifies our acknowledgment of your request and provides the next steps for accessing the service.</li>
              <li>Service Review: All service requests are subject to an internal review process. This is to ensure that we meet all necessary criteria for providing you with the best possible care.</li>
              <li>Service Acceptance: We reserve the right, at our discretion, to refuse service if it does not meet our operational or ethical guidelines.</li>              
            </ul>

        </li>
        <li>Diagnostic Testing
        <ul>
              <li>Test Ordering: Upon ordering a test, you will be asked to schedule your blood draw. Blood draws are typically scheduled within 2-3 business days, if not sooner.</li>
              <li>Sample Collection and Results: Following your blood draw, your sample will be immediately processed and sent to a clinical lab. Expect to receive your results securely on our platform within a week.</li>
            </ul>


        </li>
        <li>Consultations
        <ul>
              <li>Booking Consultations: Appointments for consultations can be scheduled through our online platform. Once booked, a confirmation email will provide you with the specifics of your appointment, including the date, time, and instructions for the consultation, ensuring you're well-prepared for your session.</li>
              <li>Cancellations and Rescheduling: Life is unpredictable, and we understand that. Consultations can be conveniently cancelled or rescheduled with a minimum 24-hour notice, offering you the flexibility you need.</li>
            </ul>
       


        </li>
        <li>Memberships
        <ul>
              <li>Membership Activation: Your journey towards peak health begins with the activation of your membership following successful payment. Our website contains the myriad of benefits associated with your Instalab membership. We will also send a detailed email of these benefits as a reminder. </li>
              <li>Renewals and Cancellations: Our memberships enjoy the ease of automatic monthly renewals. Should you wish to cancel, the process is straightforward and can be initiated either through our website or by contacting concierge@instalab.com.
</li>
            </ul>


        </li>
        <li>Refunds and Complaints
        <ul>
              <li>Service Refunds: At Instalab, we value your satisfaction. Should you feel the need to request a refund, our team is ready to assist on a case-by-case basis. Reach out to concierge@instalab.com for personalized support.</li>
              <li>Complaint Resolution: Your feedback is pivotal to our continuous improvement. Any concerns or complaints about our services can be directed to concierge@instalab.com. We guarantee a response within 24 hours.
</li>
            </ul>


        </li>
      </ol>
      </Paragraph>


      <Text className="terms-title">
        Your representations and warranties
      </Text>
      <Paragraph className="terms-paragraph">
        By registering on the Platforms, you represent and warrant the following: (i) you are at least eighteen (18) years of age, (ii) you have the legal ability and authority to enter into these Terms and Conditions with Instalab, (iii) the information you have provided to Instalab in your registration is accurate and complete, (iv) you will comply with any and all laws applicable to your use of the Websites and Platforms, (v) you will not interfere with a third party's use and enjoyment of the Websites and Platforms, (vi) you will not interfere with or disrupt Instalab's or its vendors' security measures, (vii) if any information you provide to Instalab becomes inaccurate, incomplete or otherwise false or misleading, you will immediately notify Instalab, (viii) you acknowledge that access to the healthcare services provided through the Platforms are not "insured services" under any provincial health plans, (ix) you acknowledge that any fees paid by you or by any other persons on your behalf are not provided in exchange for any undertaking by Instalab or its representatives that such healthcare services will be made available to you, and (x) you are accessing the Websites and Platforms for yourself or a child under the age of eighteen for whom you are the legal guardian.
      </Paragraph>

      <Text className="terms-title">
        Termination
      </Text>
      <Paragraph className="terms-paragraph">
        If you violate these Terms and Conditions, your ability to use the Websites and/or Platforms will be terminated. Instalab may, in its sole discretion, terminate your access to the Websites and/or Platforms, or any portion thereof, for any reason whatsoever without prior notice. These actions are in addition to any other right or remedy Instalab may have available at law. Further, Instalab shall not be liable to you or any third party for any such termination or discontinuance. You may terminate these Terms and Conditions by ceasing to access and use the Websites and Platforms. Upon any termination of these Terms and Conditions you must immediately cease use of the Websites and Platforms. To the extent permitted by applicable law, the disclaimers, limitations on liability, termination and your warranties and indemnities shall survive any termination of these Terms and Conditions.
      </Paragraph>

      <Text className="terms-title">
        Prohibited uses
      </Text>
      <Paragraph className="terms-paragraph">
        In using the Websites and Platforms, you agree not to:
      </Paragraph>

      <ul>
        <li>
          Send or otherwise transmit to or through the Websites and Platforms any unlawful, infringing, harmful, harassing, defamatory, threatening, hateful or otherwise objectionable material of any kind, any material that can cause harm or delay to the Websites and Platforms or computers of any kind, and any unsolicited advertising, solicitation or promotional materials;
        </li>
        <li>
          Misrepresent your identity or affiliation in any way;
        </li>
        <li>
          Restrict or inhibit any person from using the Websites and Platforms, disclose personal information obtained from the Websites and Platforms or collect information about users of the Websites and Platforms;
        </li>
        <li>
          Reverse engineer, disassemble or decompile any section or technology on the Websites and Platforms, or attempt to do any of the foregoing;
        </li>
        <li>
          Gain unauthorized access to the Websites and Platforms, to other users' accounts, names, personally identifiable information or other information, or to other computers or websites connected or linked to the Websites and Platforms;
        </li>
        <li>
          Launch or use any automated system, including without limitation, "robots," "spiders," or "offline readers," that access the Websites and Platforms in a manner that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional web browser;
        </li>
        <li>
          Send or otherwise transmit to or through the Websites and Platforms chain letters, unsolicited messages, so-called "spamming" or "phishing" messages, or messages marketing or advertising goods and services;
        </li>
        <li>
          Post, transmit or otherwise make available any virus, worm, spyware or any other computer code, file or program that may or is intended to damage or hijack the operation of any hardware, software or telecommunications equipment;
        </li>
        <li>
          Violate any applicable laws or regulations in any way;
        </li>
        <li>
          Alter or modify any part of the content or services offered on or through the Websites and Platforms;
        </li>
        <li>
          Allow any other person to use the Platforms with your registration or login information;
        </li>
        <li>
          Breach or otherwise circumvent Instalab's security or authentication measures; and
        </li>
        <li>
          Assist or permit any persons in engaging in any of the activities described above.
        </li>
      </ul>

      <Text className="terms-title">
        Intellectual property rights and content
      </Text>
      <Paragraph className="terms-paragraph">
        Instalab is the sole and exclusive owner of the Websites and Platforms, including any and all copyright, patent, trademark, trade secret and other ownership and intellectual property rights, in and to the Websites and Platforms and any related materials and documentation. No title or ownership of the Websites and Platforms or any portion thereof is transferred to you hereunder. Instalab reserves all rights not expressly granted hereunder. You agree not to change or delete any copyright or proprietary notice related to materials downloaded from the Websites and/or Platforms.
      </Paragraph>

      <Paragraph className="terms-paragraph">
        You may provide input, comments or suggestions regarding the Websites and Platforms or Instalab Services ("Feedback"). You acknowledge and agree that Instalab may use any Feedback without any obligation to you and you hereby grant Instalab a worldwide, perpetual, irrevocable, royalty-free, transferable license to reproduce, display, perform, distribute, publish, modify, edit or otherwise use such Feedback as Instalab may deem appropriate, without restriction, for any and all commercial and/or non-commercial purposes, in its sole discretion.
      </Paragraph>

      <Text className="terms-title">
        External links
      </Text>
      <Paragraph className="terms-paragraph">
        The Websites and Platforms may contain links to third-party websites. Linked sites are not under the control of Instalab, and Instalab is not responsible for the content of any linked site.  Links are provided as a convenience only, and a link does not imply that Instalab endorses, sponsors, or is affiliated with the linked site.  Your use of third-party websites is at your own risk and subject to the terms and conditions of use for such sites; these Terms do not apply to other websites. Instalab disclaims any and all liability for any information, including but without limitation, any medical and health treatment information set forth on linked sites.
      </Paragraph>

      <Text className="terms-title">
        No endorsements
      </Text>
      <Paragraph className="terms-paragraph">
        Reference to any product, recording, event, process, publication, service, or offering of any third party by name, trade name, trademark, service mark, company name or otherwise does not constitute or imply the endorsement or recommendation of such by Instalab. Any views expressed by third parties on the Websites and Platforms are solely the views of such third party and Instalab assumes no responsibility for the accuracy or veracity of any statement made by such third party.
      </Paragraph>

      <Text className="terms-title">
        Indemnification and limitation of liability
      </Text>
      <Paragraph className="terms-paragraph">
        You agree to defend, indemnify and hold Instalab, its officers, directors, employees, shareholders, affiliates, third-party contractors, agents, licensors and suppliers (each a Instalab Party and collectively Instalab Parties), harmless from and against any claims, actions or demands, losses, liabilities, damages, costs, expenses and settlements (including without limitation reasonable attorney and accounting fees), resulting from or alleged to result from, directly or indirectly, your (a) violation of these Terms and Conditions; (b) access to or use of the Platform and Websites; and (c) provision of other disclosure to Instalab of any other information or data and the use of same by Instalab or other Instalab Party as contemplated hereunder.
      </Paragraph>

      <Paragraph className="terms-paragraph">
        IN NO EVENT SHALL Instalab BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM ANY LOSS OF USE, LOSS OF PROFITS, LITIGATION, OR ANY OTHER PECUNIARY LOSS, WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE WEBSITES OR PLATFORMS OR THE PROVISION OF OR FAILURE TO MAKE AVAILABLE ANY SUCH PRODUCTS, GOODS, OR SERVICES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </Paragraph>

      <Paragraph className="terms-paragraph">
        Instalab shall not be liable for any failure to perform its obligations hereunder where the failure results from any cause beyond Instalab's reasonable control, including, without limitation, mechanical, electronic, or communications failure or degradation.
      </Paragraph>

      <Paragraph className="terms-paragraph">
        The terms of these Terms and Conditions that limit liability reflect an informed voluntary allocation of risk; such allocation represents a material part of these Terms and Conditions. You agree that the limitations of liabilities set out in these Terms and Conditions are fair and reasonable in the circumstances.
      </Paragraph>

      <Text className="terms-title">
        Disclaimer
      </Text>
      <Paragraph className="terms-paragraph">
        The Websites and Platforms are provided on an "as is" and "as available" basis and without warranties of any kind, either express or implied. To the fullest extent permissible pursuant to applicable law, Instalab disclaims all representations, warranties, and conditions, express or implied, including, but not limited to, implied condition or warranties of merchantability and fitness for a particular purpose. Instalab does not warrant that the Websites and Platforms will be uninterrupted or error-free, that defects will be corrected or that the Websites and Platforms or the server that makes it available are free of viruses or other harmful components.
      </Paragraph>

      <Paragraph className="terms-paragraph">
        Instalab makes no guarantees, and disclaims any implied warranty or representation about its accuracy, relevance, timeliness, completeness, or appropriateness of any content posted on the Website and Platforms for a particular purpose.  Instalab Heath assumes no liability arising from or relating to the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Websites and/or Platforms.
      </Paragraph>

      <Paragraph className="terms-paragraph">
        Applicable law may not allow the exclusion of implied warranties, so the above exclusion may not apply to you.
      </Paragraph>

      <Text className="terms-title">
        Children
      </Text>
      <Paragraph className="terms-paragraph">
        The Websites and Platforms are not intended for children under the age of 13. If you are under 13 years of age, please do not use or access the Websites or Platforms at any time or in any manner. By using the Websites or Platforms, you affirm that you are over the age of 13. Instalab does not seek through the Websites or Platforms to gather personal information from or about persons under the age of 13 without the consent of a parent or guardian.
      </Paragraph>

      <Text className="terms-title">
        Choice of law and jurisdiction
      </Text>
      <Paragraph className="terms-paragraph">
        These Terms and Conditions are governed by the laws of Delaware. The parties irrevocably submit to the exclusive jurisdiction of the courts of the State of Delaware in respect of all matters and disputes arising hereunder, and waive any defense of lack of personal jurisdiction in that jurisdiction. These Terms and Conditions are not governed by the United Nations Convention on Contracts for the International Sale of Goods, the application of which is hereby expressly excluded. If any provision of these Terms and Conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms and Conditions, which shall remain in full force and effect.
      </Paragraph>

      <Text className="terms-title">
        Personal information and privacy policy
      </Text>
      <Paragraph className="terms-paragraph">
        In these Terms and Conditions, "personal information" means any information about an identifiable individual, such as your name, email address, mailing address, gender, date of birth, any personal or protected health information, or, any data about you that you elect to provide electronically through the Websites and Platforms and any other information that identifies who you are. Personal Information will be used by Instalab solely in accordance with these Terms and Conditions, and Instalab's Web and Mobile Privacy Policy (or as otherwise agreed upon between you and Instalab in writing).
      </Paragraph>

      <Paragraph className="terms-paragraph">
        You agree that Instalab has the right to monitor and review your use of the Websites and Platforms from time to time, and to use "cookies", "log files" and your "browsing data" in accordance with the Cookie Policy.
      </Paragraph>

      <Text className="terms-title">
        Waiver
      </Text>
      <Paragraph className="terms-paragraph">
        No delay or omission by Instalab to exercise any right or power it has under these Terms and Conditions or to object to the failure of any covenant of you to be performed in a timely and complete manner, shall impair any such right or power or be construed as a waiver of any succeeding breach or any other covenant. Any waivers by Instalab must be in writing and signed by an authorized representative of Instalab.
      </Paragraph>

      <Text className="terms-title">
        Entire agreement
      </Text>
      <Paragraph className="terms-paragraph">
        These Terms and Conditions constitute the entire agreement between you and Instalab as it relates to the access to, and use of, the Platform and Websites and the subject matter of these Terms and Conditions and supersede all prior or contemporaneous agreements, negotiations, representations and proposals, written or oral between Instalab and you.
      </Paragraph>

      <Text className="terms-title">
        Electronic documents
      </Text>
      <Paragraph className="terms-paragraph">
        This electronic document, and all other electronic documents referred to or incorporated herein, will be: (a) deemed for all purposes to be a "writing" or "in writing", and to comply with all statutory, contractual, and other legal requirements for a writing; and (b) legally enforceable as a signed agreement. A printed version of these Terms and Conditions and any notice given in electronic form shall be admissible in judicial proceedings or administrative proceedings based upon or relating to these Terms and Conditions to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.
      </Paragraph>

      <Text className="terms-title">
        Assignment
      </Text>
      <Paragraph className="terms-paragraph">
        These Terms and Conditions are personal to you, and are not assignable, transferable, or sublicensable by you except with Instalab's prior written consent. Instalab may assign, transfer, or delegate any of its rights and obligations hereunder without your consent.
      </Paragraph>

      <Text className="terms-title">
        Language
      </Text>
      <Paragraph className="terms-paragraph">
        English shall be the language of these Terms and the parties waive any right to use and rely upon any other language or translations*. Il est la volonté expresse des parties que les présentes Conditions d'utilisation et tous les documents qui s'y rapportent soient rédigés en langue anglaise, exception faite des documents pour lesquels la loi exige l'usage exclusif du français.*
      </Paragraph>

      <Text className="terms-title">
        Contact / Notices
      </Text>
      <Paragraph className="terms-paragraph">
        If you have any questions about the Terms and Conditions, or need to provide notice to, or communicate with, Instalab under the Terms and Conditions, please contact Instalab using the following contact details:
      </Paragraph>

      <Paragraph className="terms-paragraph">
        Instalab, Inc.<br/>
        169 Madison Ave #2885 <br/>
        New York, NY 10016<br/>
        917-426-7109<br/>
        <a
        className="privacy-link"
        onClick={() => window.open(`mailto:concierge@instalab.com`)}
      >
        concierge@instalab.com
      </a>

      </Paragraph>


      <Paragraph className="terms-paragraph">
        Instalab may provide notices or communications to you on the Websites and/or Platforms and you agree that such notices shall constitute notice to you whether or not you actually access the notice.
      </Paragraph>
    </div>
    
    <PgFooter />
  </>
}