import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'
import QueryStringHelper from '../helpers/queryString.helper'

export const listStaffs = async (params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/staffs?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const getStaff = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/staffs/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const addStaff = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/staffs`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updateStaff = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/staffs/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeStaff = async (id, params={}) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/staffs/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}