import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Button, Modal, Dropdown, Typography, message, Card } from 'antd'
import { listAppointments } from "../../services/appointment.service"
import { SettingOutlined, UserAddOutlined, StopOutlined, EyeOutlined, CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { cancelAppointment } from '../../services/appointment.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import "./appointments.scss";
import AppointmentStatus from '../../enums/appointmentStatus.enum';
import { Step as LongevityStep } from '../longevityTestFlow/longevityTestFlow.component.js'
import { Step as LongevityPscStep } from '../longevityTestPscFlow/longevityTestPscFlow.component.js'
import { Step as AthleteStep } from '../athleteTestFlow/athleteTestFlow.component.js'
import { Step as CustomStep } from '../customTestFlow/customTestFlow.component.js'
import { Step as InviteStep } from '../inviteFlow/inviteFlow.component.js'
import { Step as TestosteroneStep } from '../testosteronePanelFlow/testosteronePanelFlow.component.js'
import { UserContext } from '../../contexts/user.context.js';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Panel } from "../../enums/index.enum.js"
import FlowType from '../../enums/flowType.enum.js';
import { getMe } from '../../services/user.service.js';
import ProductTypeCode from "../../enums/productTypeCode.enum.js"
import AppointmentHelper from '../../helpers/appointment.helper.js';
import { listProductTypes} from '../../services/productType.service.js';
import ScheduleMenu from '../scheduleMenu/scheduleMenu.component.js';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Column } = Table
const { Text } = Typography

const scheduleStep = {
  [FlowType.LONGEVITY_TEST]: LongevityStep.SCHEDULE,
  [FlowType.PRO_DRAW]: LongevityStep.SCHEDULE,
  [FlowType.PRO_ATHLETE_PANEL]: AthleteStep.SCHEDULE,
  [FlowType.ATHLETE_TEST]: AthleteStep.SCHEDULE,
  [FlowType.LONGEVITY_TEST_PSC]: LongevityPscStep.SCHEDULE,
  [FlowType.CUSTOM_TEST]: CustomStep.SCHEDULE,
  [FlowType.TESTOSTERONE_PANEL]: TestosteroneStep.SCHEDULE,
}

const inviteStep = {
  [FlowType.LONGEVITY_TEST]: LongevityStep.INVITE,
  [FlowType.PRO_DRAW]: LongevityStep.INVITE,
}

const confirmStep = {
  [FlowType.LONGEVITY_TEST]: LongevityStep.APPOINTMENT_CONFIRMATION,
  [FlowType.PRO_DRAW]: LongevityStep.APPOINTMENT_CONFIRMATION,
  [FlowType.ATHLETE_TEST]: AthleteStep.APPOINTMENT_CONFIRMATION,
  [FlowType.LONGEVITY_TEST_PSC]: LongevityPscStep.APPOINTMENT_CONFIRMATION,
  [FlowType.CUSTOM_TEST]: CustomStep.APPOINTMENT_CONFIRMATION,
  [FlowType.TESTOSTERONE_PANEL]: TestosteroneStep.CONFIRM,
}

const collectionMethods = [
  ProductTypeCode.MOBILE_BLOOD_DRAW,
  ProductTypeCode.PRO_MOBILE_BLOOD_DRAW,
  ProductTypeCode.LAB_VISIT,
]

const appointmentSelect = '_id status location start type products facility phlebType'
const appointmentPopulate = [{
  path: 'patients',
  select: '_id'
}, {
  path: 'flow',
  select: '_id type'
}, {
  path: 'orders',
  select: 'panel addOns'
},{
  path: 'products',
  populate: [
    {
      path: 'productType'
    }
  ]
},
{
  path: 'facility'
}]

const renderPendingMessage = () => (
  <div style={{ fontSize: '0.9em', whiteSpace: 'normal', display: 'block', color: '#6c757d' }}>
    Our concierge team is working on your appointment and will be in touch soon.{' '}
    Please email concierge@instalab.com with any questions.
  </div>
);

export const Appointments = () => {
  const navigate = useNavigate()
  const [appointments, setAppointments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [canceling, setCanceling] = useState([])
  const cancelingRef = useRef(null)
  cancelingRef.current = canceling
  const { token } = useContext(UserContext)
  const [currentUser, setCurrentUser] = useState()
  const [productTypes, setProductTypes] = useState([]);


  useEffect(() => {
    document.title = 'Testing Appoinments | Instalab'
    fetchAppointments()
  }, [])

  useEffect(() => {
    fetchCurrentUser()
  }, [token])

  useEffect(() => {
    fetchProductTypes(); 
  }, [currentUser])

  
  const fetchCurrentUser = async () => {
    if (!token) return
    setCurrentUser(await getMe({
      select: '_id credits isAthlete'
    }))
  }

  // Fetch product types for scheudle menu
  const fetchProductTypes = async () => {
    if (!currentUser) return;
  
    // Define a filter to only fetch specific product codes
    let filter = {
      code: { $in: [
        ProductTypeCode.LONGEVITY_PANEL, 
        ProductTypeCode.CTCALCIUM,
        ProductTypeCode.CLEERLY,
        ProductTypeCode.DEXA_BODYCOMP,
        ProductTypeCode.DEXA_BONE,
        ProductTypeCode.TESTOSTERONE_PANEL,
        ProductTypeCode.PRENUVO,
        ProductTypeCode.VO2MAX
      ] }
    };
  
    // If the user is an athlete, include "athlete-panel"
    if (currentUser?.isAthlete) {
      filter.code.$in.unshift(ProductTypeCode.ATHLETE_PANEL); // Add athlete-panel at the top
    }
  
    try {
      let types = await listProductTypes({ filter }); // Pass the filter when calling API

      types = types.sort((a, b) => {
        if (a.code === ProductTypeCode.ATHLETE_PANEL) return -1; // ATHLETE_PANEL should be first
        if (b.code === ProductTypeCode.ATHLETE_PANEL) return 1;
        if (a.code === ProductTypeCode.LONGEVITY_PANEL) return -1; // LONGEVITY_PANEL second
        if (b.code === ProductTypeCode.LONGEVITY_PANEL) return 1;
        
        // Sort the rest alphabetically by title
        return a.title.localeCompare(b.title);
      });
      
      setProductTypes(types);
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  const fetchAppointments = async () => {
    // blood draws
    const appts = await listAppointments({ 
      filter: {
        status: {
          $in: [
            AppointmentStatus.PENDING,
            AppointmentStatus.COLLECTED,
            AppointmentStatus.COMPLETE,
            AppointmentStatus.CONFIRMED,
          ]
        },
        products: { $exists: true, $not: { $size: 0 } }
      },
      select: appointmentSelect,
      populate: appointmentPopulate,
      sort: '-start'
    })

    // Sort by status first (PENDING at the top), then by start date
    appts.sort((a, b) => {
      if (a.status === AppointmentStatus.PENDING && b.status !== AppointmentStatus.PENDING) return -1;
      if (a.status !== AppointmentStatus.PENDING && b.status === AppointmentStatus.PENDING) return 1;
      return new Date(b.start) - new Date(a.start);
    });

    setAppointments(appts);

    setIsLoading(false);
  };

  const onCancel = async (_id) => {
    setCanceling([
      ...cancelingRef.current,
      _id
    ])
    try {
      const appointment = await cancelAppointment(_id, {
        select: appointmentSelect,
        populate: appointmentPopulate,
      })
      setAppointments(appointments.map(a => a._id === _id ? appointment : a))
      message.info('Appointment canceled.')
      fetchCurrentUser()
    } catch (err) {
      message.error('Failed to cancel appointment')
    }
    setCanceling(cancelingRef.current.filter(cancelingId => cancelingId !== _id))
  }

  const getUrlPrefix = (flow) => {
    if (flow?.type === FlowType.PRO_DRAW) {
      return `/flow/${FlowType.LONGEVITY_TEST}/`
    } else if (flow?.type === FlowType.PRO_CONSULT) {
      return `/flow/${FlowType.LONGEVITY_CONSULT}/`
    } else {
      return `/flow/${flow?.type}/`
    }
  }

  const onReschedule = async (_id, flow) => {
    if (!scheduleStep[flow.type]) {
      Modal.info({
        title: 'Contact Concierge',
        content: 'Please email concierge@instalab.com to reschedule this appointment.',
        okText: 'OK'
      });
      return;
    }
    
    navigate(`${getUrlPrefix(flow)}${scheduleStep[flow.type]}/${flow._id}`)
  }

  const onInvite = async (_id, flow) => {
    navigate(`${getUrlPrefix(flow)}${inviteStep[flow.type]}/${flow._id}`)
  }

  // Determine if appointment is same day
  const isToday = (start) => {
    const today = new Date();
    const startDate = new Date(start);

    return startDate.getDate() === today.getDate() &&
            startDate.getMonth() === today.getMonth() &&
            startDate.getFullYear() === today.getFullYear();
  }

  const items = (appointment) => {

    const { _id, flow, status, start, type } = appointment

    if (type === "other") return false;


    // Function to show the confirmation modal
    const showCancelConfirm = (_id) => {
      let content = "Your account will be credited back after you do this so you can schedule another time."

      if (isToday(start)) {
        content = <>Unfortunately canceling the same day will result in a <b>$50 fee</b> due to the impact on our scheduling and business operations. We appreciate your understanding in this. <br/><br/>Your account will be credited with a blood draw after you cancel so you can reschedule another time.</>
      }

      Modal.confirm({
        title: 'Are you sure you want to cancel your appointment?',
        content: content,
        okText: "OK, cancel",
        onOk() {
          onCancel(_id);
        },
        onCancel() {
          console.log('Cancel operation was aborted');
        },
      });

    };

    // Function to show the reschedule modal
    const showRescheduleConfirm = (_id, flow) => {
      if (isToday(start)) {
        Modal.confirm({
          title: 'Are you sure you want to reschedule?',
          content: <>Unfortunately rescheduling the same day will result in a <b>$50 fee</b> due to the impact on our scheduling and business operations. We appreciate your understanding in this.</>,
          okText: "OK, reschedule",
          onOk() {
            onReschedule(_id, flow);
          },
          onCancel() {
            console.log('Reschedule operation was aborted');
          },
        });
      }

      else {
        onReschedule(_id, flow);
      }
    };

    const isMaster = appointment?.patients[0]?._id === currentUser?._id

    let viewUrl = isMaster ? 
      `${getUrlPrefix(flow)}${confirmStep[flow?.type]}/${flow?._id}` :
      `/flow/${FlowType.INVITE}/${InviteStep.CONFIRM}/${flow?._id}`

    const viewItem = flow ? {
      key: '1',
      label: (
        <a 
          onClick={() => navigate(viewUrl)}
          className="error"
        >
          <EyeOutlined style={{marginRight: 5}} /> View Appointment
        </a>
      )
    } : null

    const rescheduleItem = (flow && isMaster) ? {
      key: '2',
      label: (
        <a 
          onClick={() => showRescheduleConfirm(_id, flow)}
          className="error"
        >
          <CalendarOutlined style={{marginRight: 5}} /> Reschedule
        </a>
      )
    } : null

    const inviteItem = ([FlowType.LONGEVITY_TEST, FlowType.PRO_DRAW].includes(flow?.type) && isMaster) ? {
      key: 'invite',
      label: (
        <a 
          onClick={() => onInvite(_id, flow)}
          className="error"
        >
          <UserAddOutlined style={{marginRight: 5}} /> Invite
        </a>
      )
    } : null

    const cancelItem = isMaster ? {
      key: '3',
      label: (
        <a 
          onClick={() => showCancelConfirm(_id)}
          className="remove-item"
        >
          <StopOutlined style={{marginRight: 5}} /> Cancel
        </a>
      )
    } : null


    let menuItems = []

    // visual hack - fix this
    const customPanel = (AppointmentHelper.getPanel(appointment) === Panel.CUSTOM);

    if (status === AppointmentStatus.CONFIRMED) {
      // if (viewItem && !customPanel) {
      //   menuItems.push(viewItem)
      // }
      if (rescheduleItem && !customPanel) {
        menuItems.push(rescheduleItem)
      }
      if (inviteItem && AppointmentHelper.canInvite(appointment)) {
        menuItems.push(inviteItem)
      }
      if (cancelItem && !appointment.appointmentParent) {
        menuItems.push({
          type: 'divider'
        })
        menuItems.push(cancelItem)
      }


    }
    
    return menuItems
  }

  const renderMobileCard = (appointment) => {
    const { start, location, facility, status, products } = appointment;
    const dropdownItems = items(appointment);
    const address = facility?.location || location;
    const formattedTime = start ? 
      dayjs(start).tz(address?.timeZoneId || currentUser?.location?.timeZoneId).format('MMM D, YYYY @ h:mm a') : 
      'TBD';
    
    const { streetAddress, streetAddress2, city, state, postalCode } = address || {};
    const fullAddress = address ? 
      `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}` : 
      'TBD';
    const locationDisplay = facility ? 
      `${facility.name} - ${fullAddress}` : 
      fullAddress;

    return (
      <Card 
        key={appointment._id} 
        className="appointment-card"
        extra={
          dropdownItems.length > 0 && (
            <Dropdown menu={{ items: dropdownItems }} placement='bottomRight'>
              <Button><SettingOutlined /></Button>
            </Dropdown>
          )
        }
      >
        <div className="appointment-card-content">
          <div className="appointment-card-row">
            <Text className={classNames(`${status || 'pending'}-status`, 'appointment-status')}>
              {status || 'pending'}
            </Text>
            {(!status || status === 'pending') && (
              <div className="pending-message">
                {renderPendingMessage()}
              </div>
            )}
          </div>
          
          <div className="appointment-card-row tests">
            {products?.filter(product => !collectionMethods.includes(product.productType?.code)).map(product => product.productType?.title).join(', ')}
          </div>

          <div className="appointment-card-row time">
            <CalendarOutlined /> {formattedTime}
          </div>

          <div className="appointment-card-row location">
            <EnvironmentOutlined style={{ marginTop: 5, marginRight: 10 }} /> {locationDisplay}
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div className="appointments">
      <PageHeader
        title='Appointments'
        count={appointments.length}
        actions={(
          <ScheduleMenu  
            productTypes={productTypes} 
            currentUser={currentUser} 
          />
        )}
      />

      <div className="appointments-container">
        {/* Desktop View */}
        <div className="desktop-view">
          <Table
            loading={isLoading}
            size='small'
            className='primary-table'
            pagination={false}
            rowKey='_id'
            dataSource={appointments}
            tableLayout='auto'

          >
            
            <Column
              title='Tests'
              dataIndex='products'
              // width={250}
              render={(products) => {
                return (
                  <div className="tests-column">
                    {products?.filter(product => !collectionMethods.includes(product.productType?.code)).map(product => product.productType?.title).join(', ')}
                  </div>
                );
              }}
            />
            

            <Column
              title='Start Time'
              dataIndex='start'
              render={(start, { location, facility }) => {
                if (!start) return 'TBD'

                const address = facility?.location || location
                start = dayjs(start).tz(address?.timeZoneId || currentUser?.location?.timeZoneId)
                return (
                  <span className="time-column">
                    {start.format('MMM D, YYYY')}<br/>{start.format('h:mm a z')}
                  </span>
                )
              }}
            />

            <Column
              title='Location'
              dataIndex='location'
              render={(location, { facility }) => {
                if (!location && !facility?.location) return 'TBD'

                const { streetAddress, streetAddress2, city, state, postalCode } = facility?.location || location
                const fullAddress = (
                  <div className="location-column">
                    <div className="address-line">{streetAddress}{streetAddress2 ? ' ' + streetAddress2 : ''}</div>
                    <div className="address-line">{city}, {state} {postalCode}</div>
                  </div>
                )
                
                return facility ? (
                  <div className="location-column">
                    <div className="facility-name">{facility.name}</div>
                    {fullAddress}
                  </div>
                ) : fullAddress
              }}
            />


          <Column
              title='Status'
              dataIndex='status'
              render={(status, { _id }) => {
                if (canceling.includes(_id)) {
                  return <Text className={classNames(`cancel-status`, 'appointment-status')}>canceling...</Text>
                }
                return (
                  <>
                    <Text className={classNames(`${status || 'pending'}-status`, 'appointment-status')}>
                      {status || 'pending'}
                    </Text>
                    {(!status || status === 'pending') && (
                      <div className="pending-message">
                        {renderPendingMessage()}
                      </div>
                    )}
                  </>
                );
              }}
            />

            <Column
              dataIndex='remove'
              width={50}
              className="action-column"
              render={(_, appointment) => {
                const dropdownItems = items(appointment);
                
                return dropdownItems.length > 0 ? (
                  <Dropdown 
                    menu={{ items: dropdownItems }}
                    placement='bottomRight'
                  >
                    <Button>
                      <SettingOutlined />
                    </Button>
                  </Dropdown>
                ) : null;
              }}
            />
          </Table>
        </div>

        {/* Mobile View */}
        <div className="mobile-view">
          {isLoading ? (
            <div className="loading-container">Loading...</div>
          ) : (
            appointments.map(renderMobileCard)
          )}
        </div>
      </div>
    </div>
  )
}