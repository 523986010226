import React from "react";
import "./testosteroneAdvice.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component"
import { Reference } from "../reference/reference.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { TestCode, ReportSection, Condition, LowercaseTest } from "../../../enums/index.enum";
import { TestLink } from "../testLink/testLink.component";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import ReactDOM from "react-dom";
import { TestModal } from "../../testModal/testModal.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const converter = require("number-to-words");
const { Paragraph } = Typography;

export const TestosteroneAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [testosteroneTests, setTestosteroneTests] = useState();
  const [isOverweight, setIsOverweight] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [openModal, setOpenModal] = useState();
  const [testId, setTestId] = useState();
  // progress
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  const [isStatic, setIsStatic] = useState();
  const [improvedTests, setImprovedTests] = useState();

  useEffect(() => {
    replaceTestViewTestosterone()
  }, [description, report, testosteroneTests, user, results, reports])

  useEffect(() => {
    replaceTestViewImproved()
  }, [description, report, improvedTests, user, results, reports])

  useEffect(() => {
    setIsOverweight(getIsOverweight());
  }, [report, tests, isFixed, isBetter, isWorse ]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  useEffect(() => {
    populateTestLinks()
  }, [description])

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.TESTOSTERONE_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.TESTOSTERONE_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.TESTOSTERONE_ADVICE));
    setIsStatic(ReportHelper.isStatic(report, ReportSection.TESTOSTERONE_ADVICE));
  }, [report]);

  useEffect(() => {
    categorizeTests();
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [user, report, isFixed, isBetter, isWorse, testosteroneTests]);

  const replaceTestViewTestosterone = () => {
    const target = document.getElementById('test-view-testosterone')
    if (!target || report === undefined || !testosteroneTests) return
    ReactDOM.render(
      <TestView
        user={user}
        report={report}
        tests={testosteroneTests}
        results={results}
        reports={reports}
      />,
      target
    )
  }

  const replaceTestViewImproved = () => {
    const target = document.getElementById('test-view-improved')
    if (!target || report === undefined || !improvedTests) return
    ReactDOM.render(
      <TestView
        user={user}
        report={report}
        tests={improvedTests}
        results={results}
        reports={reports}
      />,
      target
    )
  }

  const populateTestLinks = () => {
    const testLinks = document.getElementsByClassName('view-test')
    for (const testLink of testLinks) {
      const testCode = testLink.id.split('-')[2]
      const test = tests.find(({ code }) => code === Number(testCode))
      ReactDOM.render(
        <TestLink
          test={test}
          content={testLink.getAttribute('text')}
          report={report}
          tests={tests}
          results={results}
          reports={reports} 
        />,
        testLink
      )
    }
  }


  const categorizeTests = () => {
    if (!report || !tests) return null;
    setTestosteroneTests(ReportHelper.getSectionTests(report, ReportSection.TESTOSTERONE_ADVICE, tests)|| []);
    setImprovedTests(ReportHelper.getSectionImprovedTests(report, ReportSection.TESTOSTERONE_ADVICE, tests)|| [])
  }

  const getIsOverweight = () => {
    return report?.result?.conditions?.includes(Condition.OVERWEIGHT)
  }

  const hasCompensatedHypogonadism = () => {
    const conditions = report?.result?.conditions
    return conditions?.includes(Condition.COMPENSATED_HYPOGONADISM)
  }

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!user || !report || !testosteroneTests) return null;

    if (hasCompensatedHypogonadism()) {
      return <>{testosteroneTests.length > 1 ? <>These <span>{converter.toWords(testosteroneTests?.length)} biomarkers</span></> : `Your ${LowercaseTest[testosteroneTests[0].code]} levels`} suggest you may have <span>compensated hypogonadism</span>.</>
    }

    if (isFixed) {
      return (
        <>
          Your <span>testosterone</span> is now <span>optimal</span>, a hormone
    that plays a vital role in muscle health, libido and cognitive function.
        </>
      );
    } else if (isBetter) {
      return (
        <>
          Your <span>testosterone</span> is <span>improving</span>, a hormone
    that plays a vital role in muscle health, libido and cognitive function.
        </>
      );
    } else if (isWorse) {
      return (
        <>
          Your <span>testosterone</span> is <span>decreasing</span>, a hormone
    that plays a vital role in muscle health, libido and cognitive function.
        </>
      );
    } else if (isStatic) {
      return (
        <>
          Your <span>testosterone</span> remains relatively <span>unchanged</span>, a hormone
    that plays a vital role in muscle health, libido and cognitive function.
        </>
      );
    }

    return <>Your {testosteroneTests && (testosteroneTests?.length ===1 || !testosteroneTests.find(({code}) => code === TestCode.FREE_TESTOSTERONE))
    ? <span>{LowercaseTest[testosteroneTests[0].code]}</span> : <span>testosterone</span>} is <span>{ReportHelper.getRiskEval(TestCode.TOTAL_TESTOSTERONE, report, results, tests)}</span>, a hormone
    that plays a vital role in muscle health, libido and cognitive function.</>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)
    if (!user || !report || !testosteroneTests) return null;

    const lowTT = testosteroneTests.find(({code}) => code === TestCode.TOTAL_TESTOSTERONE)
    const lowFT = testosteroneTests.find(({code}) => code === TestCode.FREE_TESTOSTERONE)

    if (hasCompensatedHypogonadism()) {
      return <>
        Compensated hypogonadism occurs when your body compensates for less efficient testicles. Your pituitary gland increases production of certain hormones (LH and FSH) to keep testosterone levels normal. So, while your <span className="view-test" id={`view-test-${TestCode.TOTAL_TESTOSTERONE}`} text="total testosterone" /> and <span className="view-test" id={`view-test-${TestCode.FREE_TESTOSTERONE}`} text="free testosterone" /> levels appear healthy, it may be because your body is working harder to maintain this balance, shown by elevated LH and FSH levels.
        <br/><br/>
        It's important to monitor your testosterone levels to ensure they remain stable. If there are changes, discussing treatment options with your physician is recommended.
        <div id="test-view-testosterone" />
        </>
    }

    if (isWorse) {

      const badTTTrend = ReportHelper.hasWorseningTrend(TestCode.TOTAL_TESTOSTERONE, report, results, tests)
      const badFTTrend = ReportHelper.hasWorseningTrend(TestCode.FREE_TESTOSTERONE, report, results, tests)
      return <>
        While many men with low testosterone may not experience symptoms, lower levels can lead to a reduction in sex drive, increased fatigue, muscle loss, irritability, sleep disturbances, and fertility issues.

        {(badTTTrend || badFTTrend) && <><br/><br/>Given the consistent suboptimal levels in your recent blood tests, it's advisable to consult with your physician about what may be causing this, especially if you're experiencing symptoms.</> }

        {!(badTTTrend || badFTTrend) && !lowFT && lowTT && 
        <><br/><br/>The good news is your <span id={`view-test-${TestCode.FREE_TESTOSTERONE}`} className="view-test" text="free testosterone" />, a better indicator of active testosterone levels due to its bioavailability, is still within a healthy range. Additionally, testosterone levels can vary significantly from day to day. Let's monitor this closely.</>}
        <div id="test-view-testosterone" />
      </>
    }

    if (isFixed) {
      return <>
        Nice work on getting your testosterone levels up! Optimal testosterone levels can significantly impact overall health, mood, and energy levels. 
        <div id="test-view-improved" />
      </>      
    }

    if (isBetter) {
      return <>
        Nice work on getting your testosterone levels up! This can significantly impact your overall health, mood, and energy levels. 
        <div id="test-view-testosterone" />
      </>      
    }

    const lowLH = testosteroneTests.find(({code}) => code === TestCode.LH)
    const lowFSH = testosteroneTests.find(({code}) => code === TestCode.FSH)

    if (isOverweight) {
      return <>
      
      {!lowFT && lowTT && 
      <>The good news is your <span id={`view-test-${TestCode.FREE_TESTOSTERONE}`} className="view-test" text="free testosterone" />, a better indicator of active testosterone levels because of its bioavailability, is currently within a healthy range.
      
      Nevertheless, it's important to keep an eye on your total testosterone levels, as they can foreshadow potential future shifts in free testosterone.<br/><br/></>}
      
      Small amounts of weight loss can boost testosterone production. And combining weight loss and exercise{" "}
      <Reference content="may increase your testosterone level up to 50%" url="https://www.diabetesincontrol.com/testosterone-levels-increase-50-with-weight-loss-exercise/"/>.
      <div id="test-view-testosterone" />
      </>
    }

    if (lowFT && lowTT) {
      return <>
          Low testosterone can lead to mood disturbances, loss of muscle tone, inadequate erections, poor sexual performance, concentration difficulties, memory loss, and sleep issues.
          <br/><br/>
          Since testosterone levels can vary day to day, it's recommended to repeat these tests to confirm consistently low levels. Testing is ideally done around 8-10am, when testosterone levels are typically at their highest. If confirmed, it's important to consult with your physician to identify the cause of this deficiency and determine the most appropriate treatment approach.
          <div id="test-view-testosterone" />
        </>
    }

    if (lowLH && lowTT && !lowFT) {
      return <>
        Your low total testosterone levels are likely due to your reduced LH levels, which are essential for triggering testosterone production in the testes. 
        {/* In your case, low LH is likely due to your hypothyroidism. */}
        <br/><br/>
        The good news is your <span id={`view-test-${TestCode.FREE_TESTOSTERONE}`} className="view-test" text="free testosterone" />, a better indicator of active testosterone levels because of its bioavailability, is currently within a healthy range. Nevertheless, it's still important to monitor your total testosterone as it can signal future shifts in free testosterone.
        {/* <br/><br/>
        It's recommended to monitor your sex hormone levels, and discuss any changes with your physician, particularly if you're planning for natural conception, since low LH can affect fertility. */}
        <div id="test-view-testosterone" />
      </>
    }

    if (lowTT && !lowFT && !lowLH && !lowFSH) {
      return <>
      The good news is your <span id={`view-test-${TestCode.FREE_TESTOSTERONE}`} className="view-test" text="free testosterone" />, a better indicator of active testosterone levels because of its bioavailability, is currently within a healthy range.
      <br/><br/>
      Nevertheless, it's important to keep an eye on your total testosterone levels, as they can foreshadow potential future shifts in free testosterone. Regular monitoring of your testosterone levels and discussing any changes with your physician is recommended.
      <div id="test-view-testosterone" />
    </>
    }

    return <>
      Testosterone levels vary throughout the day, so it's advised to repeat the test to confirm consistent low levels, ideally around 8-10am when testosterone is peaking.
      <br />
      <br />
      If confirmed, consult your primary care provider to determine the
      underlying cause of your testosterone deficiency and best treatment
      strategy. 
      <div id="test-view-testosterone" />
    </>
  }


  return (
    testosteroneTests && (
      <div className="testosterone-advice">

        <TestModal
          open={openModal}
          setOpen={setOpenModal}
          testId={testId}
          setTestId={setTestId}
          report={report}
          tests={tests}
          results={results}
          reports={reports}
        />

        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{description}</Paragraph>

        <AdviceSection
          report={report}  
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
