import React, { useContext, useEffect, useState } from 'react';
import { Button, Tooltip, message, Typography, Modal } from 'antd'
import { listPatients, removePatient } from "../../services/patient.service"
import { PatientForm } from '../patientForm/patientForm.component';
import { GiftOutlined, CopyOutlined, MedicineBoxOutlined, LockOutlined, DeleteOutlined, EnvironmentOutlined, EditOutlined, KeyOutlined, CloudDownloadOutlined, LoadingOutlined,  } from '@ant-design/icons'
import copy from 'copy-to-clipboard';
import './patients.scss';
import dayjs from 'dayjs'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component';
import { ChangePasswordModal } from '../changePasswordModal/changePasswordModal.component';
import ObjectType from '../../enums/objectType.enum';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import PatientHelper from '../../helpers/patient.helper';
import { addReportForPatient } from '../../services/patient.service';
import { addImpersonation } from '../../services/impersonation.service';
import { TransferModal } from '../transferModal/transferModal.component';
import axios from 'axios'
import fileDownload from 'js-file-download'
import { useNavigate } from 'react-router-dom';
import Org from '../../enums/org.enum.js';
import Role from '../../enums/role.enum.js';
import Gender from '../../enums/gender.enum.js';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { UserContext } from '../../contexts/user.context.js';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Text } = Typography

const select = '_id firstName lastName email gender dob location isShareable credits isAthlete'
const populate = []

export const Patients = () => {
  const navigate = useNavigate()
  const [patientId, setPatientId] = useState()
  const { setCounts, currentUser } = useContext(UserContext)
  const [openTransferModal, setOpenTransferModal] = useState(false)
  const [patients, setPatients] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [userId, setUserId] = useState()
  const [isModalOpen, setIsModalOpen] = useState()
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState()
  const [isAdminLocationModalOpen, setIsLocalModalOpen] = useState()
  const [isDownloading, setIsDownloading] = useState()
  const [filteredCount, setFilteredCount] = useState()

  useEffect(() => {
    document.title = 'Instalab | Patients'
    fetchPatients()
  }, [])


  const fetchPatients = async () => {
    setIsLoading(true)
    const fetchedPatients = await listPatients({
      select,
      filter: {
        org: Org.INSTALAB,
        $or: [
          { email: { $ne: null } },
          { phone: { $ne: null } },
          { firstName: {$ne: null } },
          { lastName: { $ne: null } }
        ]
      },
      populate,
      sort: '-createdAt'
    })
    // TODO: provider filter
    setPatients(fetchedPatients)
    setFilteredCount(fetchedPatients.length)
    setIsLoading(false)
  }

  const onInvite = (patientId) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_CLIENT_URL}/accept/${patientId}`)
    message.info('Invite link copied')
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this patient?',
      content: 'This action cannot be undone and will permanently remove the patient from the system.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removePatient(_id);
          setPatients(patients.filter(patient => patient._id !== _id));
          message.info('Patient removed');
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              patients: cachedCounts.patients - 1
            }
          })
          setFilteredCount(cachedFilterCount => cachedFilterCount-1)
        } catch (err) {
          message.error('Failed to remove patient');
        }
      }
    });
  }

  const onImpersonate = async (_id) => {
    try {
      const impersonate = await addImpersonation({ user: _id })
      copy(`${process.env.REACT_APP_CLIENT_URL}/impersonate/${impersonate._id}`)
      message.success('Copied login link')
    } catch (err) {
      message.error('Failed to generate login link')   
    }
  }

  const onTransferModal = (_id) => {
    setUserId(_id)
    setOpenTransferModal(true);
  }

  const onTransferSuccess = (transfer) => {
    setPatients(patients.map(patient => {
      if (patient._id === transfer.user) {
        patient.credits = patient.credits?.length > 0 ? [...patient.credits, transfer.plan || transfer.productTypeCode] : [transfer.plan || transfer.productTypeCode]
      }
      return patient
    }))
  }

  const onDownload = async (_id) => {
    setIsDownloading(_id)
    const location = await addReportForPatient(_id)
    const filename = location.split('?')[0]

    axios
      .get(location, { responseType: 'blob' })
      .then((res) => {
        fileDownload(res.data, filename)
        message.success('Report downloaded')
        setIsDownloading(null)
      })
  }

  const getActionItems = ({ _id }) => {
    let menuItems = [{
      key: 'medical-data',
      label: (
        <Button
          onClick={() => {
            const url = `/patients/${_id}?tab=Results`;
            navigate(url);
          }}
        >
          <MedicineBoxOutlined /> View Medical Data
        </Button>
      ),
    }, {
      key: 'download-report',
      label: (
        <Button onClick={async (e) => {
          e.stopPropagation()
          if (isDownloading !== _id) {
            await onDownload(_id)
          }
        }}>
          {isDownloading === _id ? (
            <Text className="downloading-text">
              <LoadingOutlined style={{marginRight:5}} /> Downloading...
            </Text>
          ) : <>
            <CloudDownloadOutlined style={{ marginRight: 5 }} /> Download Results
          </>}
        </Button>
      ),
    }, 
    
    // {
    //   key: 'schedule',
    //   label: (
    //     <Button
    //       onClick={async () => {
    //         const response = await addProxyFlow({
    //           user: _id,
    //           type: FlowType.PRO_DRAW
    //         })
    //         navigate(`/pro-flow/${FlowType.PRO_DRAW}/${Object.values(ProDrawStep)[1]}/${response._id}`)
    //       }}
    //     >
    //       <TubeOutlined style={{ height: 16, width: 'auto', marginLeft: -2, marginBottom: -3 }} /> Schedule Blood Draw
    //     </Button>
    //   ),
    // }, 
    
    // {
    //   key: 'patient',
    //   label: (
    //     <Button
    //       onClick={async () => {
    //         const response = await addProxyFlow({
    //           user: _id,
    //           type: FlowType.PRO_CONSULT
    //         })
    //         navigate(`/pro-flow/${FlowType.PRO_CONSULT}/${Object.values(ProConsultStep)[0]}/${response._id}`)
    //       }}
    //     >
    //       <VideoCameraAddOutlined /> Schedule Consultation
    //     </Button>
    //   ),
    // }, 
    {
      key: 'edit',
      label: (
        <Button onClick={() => {
          setPatientId(_id)
          setIsModalOpen(true)
        }}>
          <EditOutlined /> Edit Profile
        </Button>
      ),
    }, {
      key: 'set-location',
      label: (
        <Button onClick={() => {
          setPatientId(_id)
          setIsLocalModalOpen(true)
        }}>
          <EnvironmentOutlined /> Set Location
        </Button>
      ),
    }, {
      key: 'invite',
      label: (
        <Button onClick={() => onInvite(_id)}>
          <CopyOutlined /> Copy Invite Link
        </Button>
      ),
    }, ]
    
    if (currentUser?.role === Role.ADMIN) {
      menuItems = [
        ...menuItems,
        ...[{
          key: 'impersonate',
          label: (
            <Button onClick={() => onImpersonate(_id)}>
              <KeyOutlined /> Impersonate
            </Button>
          ),
        },
        {
          key: 'transfer',
          label: (
            <Button onClick={() => onTransferModal(_id)}>
              <GiftOutlined /> Gift Credit
            </Button>
          ),
        }, {
          key: 'change-password',
          label: (
            <Button onClick={() => {
              setPatientId(_id)
              setIsPasswordModalOpen(true)
            }}>
              <LockOutlined /> Change Password
            </Button>
          ),
        }, {
          type: 'divider',
        }, {
          key: 'delete',
          label: (
            <Button 
              onClick={() => onRemove(_id)}
              className="remove-item"
            >
              <DeleteOutlined /> Remove
            </Button>
          ),
        }]
      ]
    }

    return menuItems
  }

  const getCustomFilter = (patient, value) => {
    return {
      firstName: () => patient.firstName && `${patient.firstName.toLowerCase()} ${patient.lastName.toLowerCase()}`.includes(value.toLowerCase()),
      location: () => patient.location?.city && patient.location.city.toLowerCase().includes(value.toLowerCase()),
    }
  }

  return (
    <div className="patients">
      <PageHeader
        title='Patients'
        count={filteredCount}
        actions={(
          <div className="page-header-actions">
            <Button
              type='primary'
              onClick={() => {
                setPatientId(null)
                setIsModalOpen(true)
              }}
            >
              + New Patient
            </Button>
          </div>
        )}
      />

      <TransferModal
        userId={userId}
        open={openTransferModal}
        setOpen={setOpenTransferModal}
        onSuccess={onTransferSuccess}
      />

      <PatientForm
        patientId={patientId}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        select={select}
        populate={populate}
        onSuccess={async patient => {
          if (patientId) {
            setPatients(patients.map(p => p._id === patientId ? patient : p))
          } else {
            setPatients([patient, ...patients])
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                patients: cachedCounts.patients + 1
              }
            })
            setFilteredCount(cachedFilterCount => cachedFilterCount+1)
          }
        }}
      />

      <ChangePasswordModal
        objectId={patientId}
        objectType={ObjectType.PATIENT}
        open={isPasswordModalOpen}
        setOpen={setIsPasswordModalOpen}
      />

      <AdminLocationModal
        objectId={patientId}
        objectType={ObjectType.PATIENT}
        open={isAdminLocationModalOpen}
        setOpen={setIsLocalModalOpen}
        select={select}
        populate={populate}
        onSuccess={patient => {
          setPatients(patients.map(p => p._id === patientId ? patient : p))
        }}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={patients}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[{
          title: 'Name',
          dataIndex: 'firstName',
          render: (firstName, { _id, lastName, isShareable }) => {
            return firstName ? <>
              <a onClick={() => {
                  const url = `/patients/${_id}?key=Profile`;
                  navigate(url);
                }}
              >
                {firstName} {lastName}
              </a>
            
            </> : <Text className="no-value">N/A</Text>
          },
          filterDropdownType: FilterDropdownType.INPUT
        }, 
        currentUser?.role === Role.ADMIN && {
          title: 'Email',
          dataIndex: 'email',
          filterDropdownType: FilterDropdownType.INPUT
        }, 
        {
          title: 'Sex',
          dataIndex: 'gender',
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(Gender)
        }, {
          title: 'Age',
          dataIndex: 'dob',
          render: dob => PatientHelper.getAge({ dob })
        }, {
          title: 'Location',
          dataIndex: 'location',
          render: (location) => {
            if (!location) return null
            const { streetAddress, streetAddress2, city, state, postalCode } = location
            const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
            return <Tooltip title={fullAddress}>{city}, {state}</Tooltip>
          },
          filterDropdownType: FilterDropdownType.INPUT
        }, 
        currentUser?.role === Role.ADMIN && {
          title: 'Credits',
          dataIndex: 'credits',
          render: credits => {
            if (credits?.length > 0) {
              const groupedCredits = credits.reduce((acc, credit)  => {
                if (acc[credit]) {
                  acc[credit] = acc[credit] + 1
                } else {
                  acc[credit] = 1
                }
                return acc
              }, {})
              
              return Object.keys(groupedCredits).map(credit => {
                return `${credit} x ${groupedCredits[credit]}`
              }).join(', ')
            }
          }
        }]}
      />
    </div>
  )
}