import { Typography } from "antd";
import "./metabolicSyndromeAdvice.scss";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportSection from "../../../enums/reportSection.enum";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const converter = require("number-to-words");

const { Paragraph } = Typography;

export const MetabolicSyndromeAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [metabolicSyndromeTests, setMetabolicSyndromeTests] = useState();
  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  const [secondaryDescription, setSecondaryDescription] = useState();

  useEffect(() => {
    setMetabolicSyndromeTests(getMetabolicSyndromeTests());
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setPrimaryDescription(getPrimaryDescription());
  }, [report, metabolicSyndromeTests]);
  
  useEffect(() => {
    setSecondaryDescription(getSecondaryDescription());
  }, [report]);


  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
          secondaryDescription: renderToString(secondaryDescription),
          recs
        }
      }
    })
  }, [title, primaryDescription, secondaryDescription, recs])

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
    if (!report || !tests || !metabolicSyndromeTests) return null

    if (ReportHelper.getSectionIndex(report, ReportSection.METABOLIC_SYNDROME_ADVICE)===1)
      return <>Let's focus on the most critical area to make progress: <span>reversing metabolic syndrome</span>.</>
    
    return <>         
        These{" "}<span>{converter.toWords(metabolicSyndromeTests.length)} biomarkers</span>{" "}indicate you have <span>metabolic syndrome</span>, which multiplies your risk for heart disease, stroke and type 2 diabetes. 
    </>
  }


  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription)
    if (!report || !tests || !metabolicSyndromeTests) return null

    const position = ReportHelper.getSectionIndex(report, ReportSection.METABOLIC_SYNDROME_ADVICE)
    return <>
      Metabolic syndrome is not a disease per se but a combination of conditions that frequently occur together: high blood pressure, high blood sugar, excess fat around the waist, low HDL cholesterol and high triglycerides. 
      
      <br/><br/>When at least 3 of these 5 conditions coexist, it heightens the risk of future health complications, including a two-fold risk of cardiovascular disease within 5-10 years and five-fold or greater risk of type 2 diabetes.
      
      {position===1 && <>
      <br/><br/>These{" "}<span>{converter.toWords(metabolicSyndromeTests.length)} biomarkers</span>{" "}indicate you have <span>metabolic syndrome</span>.</>
      }

      </>
  }

  const getSecondaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.secondaryDescription)
    if (!report) return null
    return <>The good news is that you’re catching this early before it’s developed into something serious. Adhering to the game plan can effectively reverse these risk factors.</>
  }

  const getMetabolicSyndromeTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(report, ReportSection.METABOLIC_SYNDROME_ADVICE, tests);
  };

  return (
    metabolicSyndromeTests && (
      <div className="metabolic-syndrome-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{primaryDescription}</Paragraph>
        <TestView 
          report={report} 
          tests={metabolicSyndromeTests} 
          results={results}
          reports={reports}
          user={user}
        />
        <Paragraph className="report-template-description">{secondaryDescription}</Paragraph>

        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
