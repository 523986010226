import React, { useState, useRef } from "react";
import { Modal } from 'antd';
import "./productTestimonials.scss";

export const ProductTestimonials = ({ testimonials }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState("");
    const iframeRef = useRef(null);

    const getEmbedUrl = (videoUrl) => {
        if (videoUrl.includes("vimeo.com")) {
            return videoUrl.replace("vimeo.com", "player.vimeo.com/video") + "?autoplay=1";
        } else if (videoUrl.includes("youtube.com") || videoUrl.includes("youtu.be")) {
            const videoId = videoUrl.includes("v=") ? videoUrl.split("v=")[1] : videoUrl.split("/")[3];
            return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        } else {
            console.error("Unsupported video URL:", videoUrl);
            return "";
        }
    };

    const openVideo = (videoUrl) => {
        const embedUrl = getEmbedUrl(videoUrl);

        if (embedUrl && embedUrl.startsWith("http")) {
            setCurrentVideo(embedUrl);
            setIsOpen(true);
        } else {
            console.error("Invalid video URL:", embedUrl);
        }
    };

    const closeVideo = () => {
        setIsOpen(false);
        if (iframeRef.current) {
            iframeRef.current.src = ""; // Clear the src attribute to stop the video
        }
        setTimeout(() => {
            setCurrentVideo(""); // Clear the current video URL
        }, 300); // Delay to allow modal close animation to finish
    };

    return (
        <div className="product-detail-testimonials">
            {testimonials.map((testimonial, index) => (
                <div key={index} className="product-detail-testimonial">
                    {testimonial.video &&
                        <div className="product-detail-testimonial-thumbnail">
                        <img src={testimonial.image} alt={testimonial.name} />
                        {testimonial.video && (
                            <div className="video-thumbnail" onClick={() => openVideo(testimonial.video)}>
                                <img src="/img/play-button.png" alt="Play Video" />
                            </div>
                        )}
                        </div>
                    }

                    {!testimonial.video &&
                        <div className="product-detail-testimonial-photo">
                        <img src={testimonial.image} alt={testimonial.name} />
                        {testimonial.video && (
                            <div className="video-thumbnail" onClick={() => openVideo(testimonial.video)}>
                                <img src="/img/play-button.png" alt="Play Video" />
                            </div>
                        )}
                        </div>
                    }


                    <div className="product-detail-testimonial-name">
                        {testimonial.name}
                        <span className="product-detail-testimonial-title">{testimonial.title}</span>
                    </div>
                    {testimonial.text && (
                        <div className="product-detail-testimonial-quote">"{testimonial.text}"</div>
                    )}
                </div>
            ))}

            <Modal
                visible={isOpen}
                onCancel={closeVideo}
                footer={null}
                className="video-modal"
                centered
            >
                {isOpen && currentVideo && (
                    <div className="video-container">
                        <iframe
                            ref={iframeRef}
                            src={currentVideo}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Testimonial Video"
                        ></iframe>
                    </div>
                )}
            </Modal>
        </div>
    );
};