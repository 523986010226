import { Timeline, Row, Col, Collapse } from 'antd';
import { MinusOutlined, PlusOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import { ProductOverview } from "../productOverview/productOverview.component";
import "./productTimeline.scss";

export const ProductTimeline = ({panels, title, description}) => {
  return panels?.length > 0 && (
    <div className="product-details-timeline">

      <Row gutter={48}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 11 }}
          lg={{ span: 11 }}
          xl={{ span: 11}}
          xxl={{ span: 11 }}
        >
        <ProductOverview title={title} description={description} style={{flex:1}} />
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 13 }}
          lg={{ span: 13 }}
          xl={{ span: 13}}
          xxl={{ span: 13 }}
        >

          <Timeline mode="left" className="timeline" style={{flex:1}}>

          {panels.map((item) => (
            <Timeline.Item  dot={item.dot} color={item.color ? item.color : "green"} label={item.label}>
                  {item.header ? <>{item.title}</> :

                  <Collapse
                    // accordion
                    bordered={false}
                    expandIconPosition='end'
                    className="timeline-collapse"
                    defaultActiveKey={['baseline', 'meet-physician']} 
                    expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{stroke: "black"}} /> : <PlusOutlined style={{stroke: "black"}} />}
                  >
                  
                      <Collapse.Panel
                        header={item.title}
                        key={item.key}
                        className="timeline-title"          
                      >
                        <div className="timeline-description">{item.description}</div>
                      </Collapse.Panel>
                
                  </Collapse>
                }

                </Timeline.Item>

          ))}
          </Timeline>

        </Col>

      </Row>



    </div>
  );
};
