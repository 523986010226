const ObjectType = Object.freeze({
  PHLEBOTOMIST: 'phlebotomist',
  ADMIN: 'admin',
  PATIENT: 'patient',
  PROVIDER: 'provider',
  APPOINTMENT: 'appointment',
  FACILITY: 'facility',
  STAFF: 'staff',
  APPOINTMENT_PARENT: 'appointmentParent',
  REFERRAL: 'referral'
})

export default ObjectType