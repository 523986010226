import React, { useState, useEffect } from 'react'
import "./masterCalendar.scss"
import { listAppointments } from '../../services/appointment.service';
import AppointmentStatus from '../../enums/appointmentStatus.enum';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { AppointmentCalendar } from '../appointmentCalendar/appointmentCalendar.component';

export const MasterCalendar = () => {
  const [appointments, setAppointments] = useState()

  useEffect(() => {
    document.title = 'Instalab | Appointment Calendar'
    fetchAppointments()
  }, [])

  const fetchAppointments = async () => {
    const response = await listAppointments({
      select: '_id start patients location phlebotomist products',
      filter: {
        status: {
          $in: [
            AppointmentStatus.COLLECTED,
            AppointmentStatus.COMPLETE,
            AppointmentStatus.CONFIRMED,
          ]
        }
      },
      populate: [{
        path: 'patients',
        select: 'firstName lastName'
      }, {
        path: 'phlebotomist',
        select: 'firstName'
      },
      {
        path:'products',
        populate: ['productType']
      }]
    })
    setAppointments(response)
  }

  return (
    <div className="master-calendar">
      <PageHeader
        title='Appointment Calendar'
      />
      <AppointmentCalendar appointments={appointments} />
    </div>
  )
}