import { GiftOutlined, ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { addSlack } from "../../services/slack.service"

import FlowType  from '../../enums/flowType.enum';
import "./announcementBanner.scss";

export const AnnouncementBanner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(() => {
    const bannerClosed = localStorage.getItem('instalab-gift-announcementBannerClosed');
    return !bannerClosed && !location.pathname.startsWith('/flow/');
  });

  useEffect(() => {
    const bannerClosed = localStorage.getItem('instalab-gift-announcementBannerClosed');
    if (bannerClosed || location.pathname.startsWith('/flow/')) {
      setIsVisible(false);
    }
    else {
      setIsVisible(true);
    }
  }, [location.pathname]);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('instalab-gift-announcementBannerClosed', 'true');
  };

  const onGift = async() => {
    try {
        try {
          await addSlack({ 
            message: 'User Clicked Announcement Banner - Gift',
            channel: process.env.REACT_APP_PATIENT_SLACK
          });
        } catch (error) {
          console.error('Failed to log message to Slack:', error);
        }
    
        navigate(`/flow/${FlowType.GIFT}`)
    } catch (error) {
        console.error('Failed to log message to Slack:', error);
    }
    
   
  }


  return (
    isVisible && <div className="gift-banner">
      <GiftOutlined className="gift-icon" /> 
      <span className="desktop-message">Give the gift of health to your loved ones this holiday season!</span>
      <span className="mobile-message">Gift health to loved ones!</span>
      {" "}
      <a 
        onClick={onGift} 
        className="gift-button"
      >
        Send a gift <ArrowRightOutlined className="arrow-icon" />
      </a>
      <button onClick={handleClose} className="close-button mobile-spacing">
        <CloseOutlined />
      </button>
    </div>
  );
}; 