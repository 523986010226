import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listFlows = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/flows`, {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getFlow = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/flows/${id}`, {
    params: ServiceHelper.getParams({
      idType: '_id'
    }),
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addFlow = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/flows`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addProxyFlow = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/flows/proxy`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const completeFlow = async (id, params={}) => {
  console.log('completeFlow', id, ServiceHelper.getHeaders())
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/flows/${id}/complete`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updateFlow = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/flows/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}