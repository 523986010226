import { useContext, useState } from "react";
import { Typography, Table } from "antd";
import { useParams } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";
import { UserContext } from "../../../contexts/user.context";
import { SubscribeModal } from "../../subscribeModal/subscribeModal.component";
import { StickyBlogHeader } from "../shared/stickyBlogHeader/stickyBlogHeader.component";
import { BlogData } from "../../../data/blog.data";
const { Paragraph } = Typography;

export const SupplementPost = () => {
  const { currentUser } = useContext(UserContext);
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const { blogId } = useParams();

  return (
    <div className={classNames("blog-post", "supplement-post")}>
      <SubscribeModal
        open={openSubscribe}
        setOpen={setOpenSubscribe}
      />

      <div className="blog-intro">
        <Paragraph className="blog-heading">
          {BlogData.find(b => b.id === blogId).title}
        </Paragraph>

        <Paragraph className="blog-subheading">
          {BlogData.find(b => b.id === blogId).description}
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt={BlogData.find(b => b.id === blogId).publishedAt}
        readTime={BlogData.find(b => b.id === blogId).readTime}
      />

      <div className="blog-body">
        <Paragraph className="blog-description">
          For those who just want the data, here's how each supplement stacks up:
        </Paragraph>

        <Table
          className="blog-table"
          dataSource={[
            {
              study: 'Xu et al., 2019',
              studyUrl: `https://journals.plos.org/plosone/article/file?id=10.1371%2Fjournal.pone.0098611&type=printable`,
              supplement: 'Red Yeast Rice',
              dosage: '1200 mg daily',
              reduction: '20-30%'
            },
            {
              study: 'Ju et al., 2015',
              studyUrl: `https://www.unboundmedicine.com/medline/citation/30466986/Efficacy_and_safety_of_berberine_for_dyslipidaemias%3A_A_systematic_review_and_meta_analysis_of_randomized_clinical_trials_`,
              supplement: 'Berberine',
              dosage: '500-1500 mg/day',
              reduction: '15-20%'
            },
            {
              study: 'AbuMweis et al.',
              studyUrl: `https://foodandnutritionresearch.net/index.php/fnr/article/view/1132`,
              supplement: 'Plant Sterols/Stanols',
              dosage: '1.5-3 g/day',
              reduction: '6-15%'
            }
          ]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column title="Supplement" dataIndex="supplement" render={(supplement, { studyUrl }) => <a onClick={() => window.open(studyUrl, '_blank')} className="blog-link">{supplement}</a>} />
          <Table.Column title="Dosage" dataIndex="dosage" />
          <Table.Column title="LDL Reduction" dataIndex="reduction" />
        </Table>

        <Paragraph className="blog-title">
          1. Red Yeast Rice
        </Paragraph>
        <Paragraph className="blog-description">
          This traditional Chinese supplement naturally contains <i>monacolin K</i>, which helps reduce cholesterol production in the liver. A <a onClick={() => window.open('https://journals.plos.org/plosone/article/file?id=10.1371%2Fjournal.pone.0098611&type=printable', '_blank')} className="blog-link">2019 meta-analysis</a> of multiple randomized controlled trials (RCTs) found that a <b>daily dose of 1200 mg</b> of red yeast rice led to LDL reductions of <strong>20-30%</strong>, comparable to the effects of some statins.
        </Paragraph>

        <Paragraph className="blog-description">
          Red yeast rice may cause mild, statin-like side effects, such as muscle pain or digestive discomfort, though these are less common and less intense than with statins. If you're on medication, consult your healthcare provider before trying red yeast rice.
        </Paragraph>

        <Paragraph className="blog-title">
          2. Berberine
        </Paragraph>
        <Paragraph className="blog-description">
          Berberine, a bright yellow compound found in herbs like goldenseal and barberry, has been used in traditional medicine for centuries. Modern research now shows that berberine effectively lowers cholesterol by increasing LDL receptor activity in the liver, which helps clear LDL cholesterol from the blood. 
        </Paragraph>

        <Paragraph className="blog-description">
          A <a onClick={() => window.open('https://www.unboundmedicine.com/medline/citation/30466986/Efficacy_and_safety_of_berberine_for_dyslipidaemias%3A_A_systematic_review_and_meta_analysis_of_randomized_clinical_trials_', '_blank')} className="blog-link">2015 meta-analysis</a>, reviewing RCTs on berberine's effect on LDL cholesterol, found that <strong>500-1500 mg daily</strong> reduced LDL by <strong>15-20%</strong>. Starting with a lower dose is recommended as to minimize the chances of mild digestive discomfort.
        </Paragraph>

        <Paragraph className="blog-title">
          3. Plant Sterols and Stanols
        </Paragraph>
        <Paragraph className="blog-description">
          Plant sterols and stanols, cholesterol-like compounds found naturally in plants, are well-known for their ability to block cholesterol absorption in the intestines. This mechanism reduces the amount of cholesterol that reaches the bloodstream, lowering LDL levels over time.
        </Paragraph>

        <Paragraph className="blog-description">
          A <a onClick={() => window.open('https://foodandnutritionresearch.net/index.php/fnr/article/view/1132', '_blank')} className="blog-link">2014 RCT</a>, studying the effects of plant sterols and stanols on LDL cholesterol levels, showed that daily doses of <b>1.5-3 grams</b> of plant sterols led to an LDL reduction of <strong>6-15%</strong>.
        </Paragraph>


        <Paragraph className="blog-title">
          Ready to Try These Supplements?
        </Paragraph>
        <Paragraph className="blog-description">
          If you're interested in adding any of these supplements to your routine, check out <a onClick={() => {
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: fullscript`, true)
            window.open('https://us.fullscript.com/welcome/instalab/store-start', '_blank')
          }} className="blog-link">our storefront</a> for access to high-quality options with a  <strong>30% discount</strong> on your purchase.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Xu, Y., et al. (2019). A Meta-Analysis of Red Yeast Rice: An Effective and Relatively Safe Approach for Dyslipidemia. PLOS ONE. Available at: <a onClick={() => window.open('https://journals.plos.org/plosone/article/file?id=10.1371%2Fjournal.pone.0098611&type=printable', '_blank')}>journals.plos.org</a>
            </li>
            <li>
              Ju, R., et al. (2015). Efficacy and Safety of Berberine for Dyslipidemia: A Systematic Review and Meta-Analysis of Randomized Clinical Trials. Unbound Medicine. Available at: <a onClick={() => window.open('https://www.unboundmedicine.com/medline/citation/30466986/Efficacy_and_safety_of_berberine_for_dyslipidaemias%3A_A_systematic_review_and_meta_analysis_of_randomized_clinical_trials_', '_blank')}>unboundmedicine.com</a>
            </li>
            <li>
              AbuMweis, S., et al. (2014). Plant Sterols/Stanols as Cholesterol Lowering Agents: A Meta-Analysis of Randomized Controlled Trials. Food & Nutrition Research. Available at: <a onClick={() => window.open('https://foodandnutritionresearch.net/index.php/fnr/article/view/1132', '_blank')}>foodandnutritionresearch.net</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
