import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd'
import { listInterests } from "../../services/interest.service"
import './interests.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import moment from 'moment'

const { Column } = Table

export const Interests = () => {
  const [interests, setInterests] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    document.title = 'Instalab | Interests'
    fetchInterests()
  }, [])

  const fetchInterests = async () => {
    setInterests(await listInterests())
    setIsLoading(false)
  }

  return (
    <div className="interests">
      <PageHeader
        title='Interests'
      />

      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={interests}
      >

        <Column
          title='Email'
          dataIndex='email'
        />

        <Column
          title='Location'
          dataIndex='location'
          render={location => {
            if (!location) return null
            const { streetAddress, streetAddress2, city, state, postalCode } = location
            const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
            return <Tooltip title={fullAddress}>{city}, {state}</Tooltip>
          }}
        />

        <Column
          title='Created'
          dataIndex='createdAt'
          render={createdAt => moment(createdAt).format('MMM D, YYYY')}
        />
      </Table>
    </div>
  )
}