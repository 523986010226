import { useContext, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { UserContext } from "../../contexts/user.context"

export const Auth = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { token } = useContext(UserContext)

  useEffect(() => {
    if (!token) {
      // Combine pathname and search params for complete redirect URL
      const redirectUrl = `${location.pathname}${location.search}`
      navigate(`/login?redirect=${encodeURIComponent(redirectUrl)}`)
    }
  }, [token])

  return token ? children : null
}