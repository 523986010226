import { Typography } from "antd";
import "./severeTgAdvice.scss";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { TestCode, LowercaseTest, ReportSection } from "../../../enums/index.enum";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

/* 

TODO:
  - if this section is not first  
  - add rec if taking TG lowering medication already
*/

const converter = require("number-to-words");
const { Paragraph } = Typography;
const primaryCodes = [TestCode.TG]

export const SevereTgAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [tgTests, setTgTests] = useState();
  const [primaryTests, setPrimaryTests] = useState();
  const [secondaryTests, setSecondaryTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [secondaryDescription, setSecondaryDescription] = useState();
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  const [isStatic, setIsStatic] = useState();

  useEffect(() => {
    setTgTests(getTgTests());
  }, [report, tests]);

  useEffect(() => {
    categorizeTests();
  }, [tgTests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          secondaryDescription: renderToString(secondaryDescription),
          recs
        }
      }
    })
  }, [title, description, recs])

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.SEVERE_TG_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.SEVERE_TG_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.SEVERE_TG_ADVICE));
    setIsStatic(ReportHelper.isStatic(report, ReportSection.SEVERE_TG_ADVICE));
  }, [report]);

  useEffect(() => {
    setTitle(getTitle());
  }, [report]);

  useEffect(() => {
    setDescription(getDescription());
    setSecondaryDescription(getSecondaryDescription());
  }, [report, tests, primaryTests, secondaryTests, isBetter, isFixed, isWorse, isStatic]);


  const categorizeTests = () => {
    if (!tgTests) return null;
    setPrimaryTests(tgTests.filter(({ code }) => primaryCodes.includes(code)));
    setSecondaryTests(tgTests.filter(({ code }) => !primaryCodes.includes(code)));
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
    if (!report) return null
    return <>Let's focus on the most critical area to make progress: managing your <span>severe hypertriglyceridemia</span>.</>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)
    if (!report || !primaryTests) return null

    return <>
      Triglycerides are a type of fat and vital source of energy but excessively high levels are harmful and can put you at risk for conditions like heart attack, stroke and acute pancreatitis. When triglyceride levels exceed 500 mg/dL, it's considered severe hypertriglyceridemia and requires your full attention. 
      
      {isBetter &&
        <><br/><br/>The good news is that your triglycerides are going down but there’s more you can do to significantly bring it down to a healthier level quicker. Follow the game plan and re-test as soon as 6 weeks to check-in on your progress.</>
      }
      </>
  }

  const getSecondaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.secondaryDescription)

    if (!secondaryTests?.length || !report) return null;

    return (
      <>
        In addition, as you continue to improve your trigylceride levels, you can expect a positive impact on {" "}
        {secondaryTests?.length === 1
          ? `your ${LowercaseTest[secondaryTests[0].code]} level`
          : `these ${converter.toWords(
              secondaryTests?.length
            )} cardiovascular and metabolic biomarkers`}.
      </>
    );
  };

  const getTgTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(report, ReportSection.SEVERE_TG_ADVICE, tests);
  };

  return (
    tgTests && (
      <div className="metabolic-syndrome-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{description}</Paragraph>
        <TestView 
          report={report} 
          tests={primaryTests} 
          results={results} 
          reports={reports} 
          user={user}
        />
        <Paragraph className="report-template-description">{secondaryDescription}</Paragraph>
        <TestView 
          report={report} 
          tests={secondaryTests} 
          results={results} 
          reports={reports} 
          user={user}
        />

        <AdviceSection 
          report={report} 
          section={section}
          results={results}
          tests={tests}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
