const PhotoHelper = {
  format: ({ jpg, png, webp, className, style={}, loading='lazy' }) => {
    return (
      <picture>
        <source srcSet={webp} type="image/webp" />
        {png && <source srcSet={png} type="image/png" />}
        {jpg && <source srcSet={jpg} type="image/jpeg" />}
        <img 
          src={jpg || png} className={className} 
          style={style}
          alt=""
          loading={loading}
        />  
      </picture>
    )
  }
}

export default PhotoHelper
