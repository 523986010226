import { Typography } from "antd";
import "./plateletAdvice.scss";
import { TestChart } from "../testChart/testChart.component";
import { useState, useEffect } from "react";
import TestCode from "../../../enums/testCode.enum";
import ReportSection from "../../../enums/reportSection.enum";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const PlateletAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const isFixed = ReportHelper.isFixed(report, ReportSection.PLATELET_ADVICE);
  const isBetter = ReportHelper.isBetter(report, ReportSection.PLATELET_ADVICE);
  const isWorse = ReportHelper.isWorse(report, ReportSection.PLATELET_ADVICE);
  const isStatic = ReportHelper.isStatic(report, ReportSection.PLATELET_ADVICE);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [user, report, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
      
    if (!user || !tests || !report) return null;

    if (isFixed) {
      return (
        <>
          Your <span>platelet count</span> is now <span>optimal</span>.
        </>
      );
    } else if (isBetter) {
      return (
        <>
          Your <span>platelet count</span> is <span>improving</span>.
        </>
      );
    } else if (isWorse) {
      return (
        <>
          Your <span>platelet count</span> is <span>low</span> and <span>decreasing</span>, which puts you at risk for bleeding issues.
      </>
      );
    } else if (isStatic) {
      return (
        <>
          Your <span>platelet count</span> remains relatively <span>unchanged</span>.
      </>
      );
    }

    return <>Your <span>platelet count</span> is <span>low</span>, which puts you at risk for bleeding issues.</>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!user || !tests || !report) return null;
    
    if (isWorse) {
      return (
        <>
          Given your platelet count levels are heading in the wrong direction, it's recommended you talk with your primary care provider or hematologist soon about what may be the cause of this and appropriate next steps.
        </>
      );
    } else if (isStatic) {
      return (
        <>
          Given your platelet count levels haven't really changed, it's recommended you talk with your primary care provider soon about what may be the cause of this and appropriate next steps.
        </>
      );
    }

    else 
      return <>Chronically low platelet count levels are uncommon and can indicate a serious issue so it's important to confirm whether this is temporary or persistent through a follow-up test before drawing conclusions.</>
  }

  return (
    user &&
    tests?.length > 0 && (
      <div className="platelet-count">
        <Paragraph className="report-template-title">
          {title}
        </Paragraph>
        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        <TestChart 
          report={report} 
          code={TestCode.PLATELET} 
          tests={tests}
          results={results}
          reports={reports}
        />

        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
        
      </div>
    )
  );
};
