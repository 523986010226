import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState, useEffect } from "react"
import { ConfirmProSignup } from './static/confirmProSignup/confirmProSignup.component'
import { completeFlow } from "../../services/flow.service"
import Color from '../../colors.scss';
import FlowType from "../../enums/flowType.enum"
import { PanelModal } from "../panelModal/panelModal.component"
import { advancedPanel } from "../../data/advancedPanel.data"

export const Step = {
  ACCOUNT: 'account',
  NAME: 'name',
  PHONE: 'phone',
  CARD_TYPE: 'card-type',
  CARD: 'card',
  CONFIRM: 'confirm',
}

const CardType = {
  PERSONAL: 'My Own Card',
  CLIENT: `Client's Card`,
}

export const ProSignupFlow = () => {
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [openPanel, setOpenPanel] = useState()
  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [flow])

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: <>Create your Instalab<span style={{ color: Color.success }}>Pro</span> acccount.</>,
        description: `Register to start scheduling at-home blood tests for your clients.`,
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.CARD_TYPE,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll text you notifications about your clients' blood draw appointments and test results.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
      },
      [Step.CARD_TYPE]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        title: `What card will you be charging to pay for blood draw appointments?`,
        field: {
          name: 'chargePersonalCard',
          options: [{
            label: CardType.PERSONAL,
            value: true,
          }, {
            label: CardType.CLIENT,
            value: false
          }]
        },
        onNextStep: async (provider) => {
          if (provider.chargePersonalCard) {
            return Step.CARD
          } else {
            return Step.CONFIRM
          }
        },
      },
      [Step.CARD]: {
        type: StepType.CARD,
        nextStep: Step.CONFIRM,
        addCard: true,
        title: `Save your card to pay for your clients' blood draw appointments.`,
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `Congratulations, you're in!`,
        description: <><strong>Next:</strong> schedule your first blood draw appointment for <a onClick={() => setOpenPanel(true)}>the longevity panel</a> - a comprehensive blood test to early detect your overall health risks and guide you on ways to optimize your long-term health..</>,
        path: `/pro-flow/${FlowType.PRO_DRAW}`,
        buttonText: 'Continue',
        content: <ConfirmProSignup />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
  
    })
  }

  return <>
    <PanelModal
      panel={advancedPanel}
      title='The Longevity Panel'
      open={openPanel}
      setOpen={setOpenPanel}
    />

    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
    />
  </>
}