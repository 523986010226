import React from 'react';
import { Input } from 'antd';

const TimeInput = ({ value = '', onChange }) => {
  // Format the time as the user types
  const formatTime = (value) => {
    // Remove non-numeric and non-AM/PM characters, and limit length
    let cleanedValue = value.replace(/[^0-9APM]/gi, '').slice(0, 6);
    let formattedValue = cleanedValue;

    if (cleanedValue.length > 2) {
      // Insert colon after hours
      formattedValue = cleanedValue.slice(0, 2) + ':' + cleanedValue.slice(2);
    }
    if (cleanedValue.length > 4) {
      // Insert space before AM/PM
      formattedValue = formattedValue.slice(0, 5) + ' ' + formattedValue.slice(5);
    }

    return formattedValue;
  };

  const handleInputChange = (e) => {
    const newValue = formatTime(e.target.value.toUpperCase());
    onChange && onChange(newValue);
  };

  const handleBlur = () => {
    const [hours, minutes, amPm] = value.split(/[:\s]/);
    const validHours = Math.min(Math.max(parseInt(hours || '0', 10), 1), 12).toString().padStart(2, '0');
    const validMinutes = Math.min(parseInt(minutes || '0', 10), 59).toString().padStart(2, '0');
    const validAmPm = ['AM', 'PM'].includes(amPm) ? amPm : 'AM';

    const formattedValue = `${validHours}:${validMinutes} ${validAmPm}`;
    onChange && onChange(formattedValue);
  };

  return (
    <Input
      value={value}
      onChange={handleInputChange}
      onBlur={handleBlur}
      placeholder="HH:MM AM/PM"
    />
  );
};

export default TimeInput;