import { useEffect, useState, useRef } from 'react'
import { Form } from "antd"
import { WarningFilled } from '@ant-design/icons'
import Color from '../../../../colors.scss';
import "./calendly.scss"
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

const { Item } = Form

export const Calendly = ({
  step,
  onSubmit,
  form,
  flow,
}) => {
  const calendlyRef = useRef(null);

  useEffect(() => {
    const handleCalendlyEvent = (e) => {
      if (e.data.event && e.data.event.indexOf("calendly") === 0) {
        if (calendlyRef.current) {
          calendlyRef.current.style.height = `${e.data.payload.height}px`;
        }
      }
    };

    window.addEventListener("message", handleCalendlyEvent);

    return () => {
      window.removeEventListener("message", handleCalendlyEvent);
    };
  }, []);
  const [height, setHeight] = useState(500)
  
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useCalendlyEventListener({
    onEventTypeViewed: () => setHeight(500),
    onDateAndTimeSelected: () => setHeight(965),
    onEventScheduled: async (e) => {
      const { event, invitee } = e.data.payload
      const { uri: eventUri } = event
      const { uri: inviteeUri } = invitee
      form.setFieldsValue({ 
        eventUri,
        inviteeUri,
      })
      onSubmit()
    },
  })

  return (
    <div className="calendly">
      <Form
        form={form}
        className="calendly-form"
      >
        {step?.url && (
          <div className="calendar-container" ref={calendlyRef}>
            <InlineWidget  
              url={`${step?.url}?hide_gdpr_banner=1`} 
              styles={{
                width: '100%',
                height: 750,
                background: "white"
              }}
              prefill={{
                firstName: flow?.user?.firstName ? flow.user.firstName : '',
                lastName: flow?.user?.lastName ? flow.user.lastName : '',
                smsReminderNmber: flow?.user?.phone ? flow.user.phone: null,
                name: flow?.user?.firstName ? `${flow.user.firstName} ${flow.user.lastName}` : '',
                email: flow?.user?.email ? flow.user.email : '',
              }}
              pageSettings={{
                hideEventTypeDetails: true,
                hideLandingPageDetails: true,
              }}
            />
          </div>
        )}

        <Item 
          name="eventUri"
          className="calendly-item"
          validateTrigger='onSubmit'
          rules={[{ 
            required: true, 
            message: <><WarningFilled />&nbsp; Please schedule meeting</>,
          }]}
        >
        </Item>
        <Item 
          name="inviteeUri"
          className="calendly-item"
          validateTrigger='onSubmit'
        >
        </Item>

        {/* <FlowStepFooter 
          onSubmit={() => onSubmit({ [step.field.name]: form.getFieldsValue()[step.field.name] })} 
          isSubmitting={isSubmitting}
          buttonText={step.buttonText}  
          buttonStyle={step.buttonStyle}
          enterStyle={step.enterStyle}
        />  */}
      </Form>
    </div>
  )
}