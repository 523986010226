import { Typography } from "antd";
import { useState, useEffect } from "react";
import { TestItemList } from "../testItemLlist/testItemList.component";
import ReportSection from "../../../enums/reportSection.enum";
import ReportHelper from "../../../helpers/report.helper";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import PatientHelper from "../../../helpers/patient.helper";
import "./rhabdomyolysisAdvice.scss";
const converter = require("number-to-words");

const { Paragraph } = Typography;

export const RhabdomyolysisAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [liverTests, setLiverTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setLiverTests(getLiverTests());
  }, [report, tests]);

  const getLiverTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(report,ReportSection.RHABDOMYOLYSIS_ADVICE,tests);
  };

  useEffect(() => {
    setTitle(getTitle());
  }, [report, liverTests])

  useEffect(() => {
    setDescription(getDescription());
  }, [report])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
      if (!liverTests) return null

    if (PatientHelper.isAthlete(user)) return getAthleteTitle()

    return <>
      These{" "}<span>{converter.toWords(liverTests.length)} biomarkers</span>{" "}
        suggest possible skeletal muscle injury. 
    </>
  }

  const getAthleteTitle = () => {
    if (!report || !tests || !liverTests) return null

    return <>         
      These{" "}<span>{converter.toWords(liverTests.length)} biomarkers</span>{" "}suggest
      recent intense exercise.
    </>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)
      return <>
        It's important to determine the underlying cause behind the spikes observed in your creatine kinase and liver enzyme tests. Consult with your primary care provider
              about what could be causing your results and best management strategy. Additional testing may
              be needed.
      </>
  }

  return (
    liverTests && (
      <div className="liver-disease-advice">
        <Paragraph className="report-template-title">
          {title}
        </Paragraph>

        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        <TestItemList
          report={report}
          testIds={liverTests.map(({ _id }) => _id)}
          tests={tests}
          results={results}
          user={user}
          reports={reports}
        />
      </div>
    )
  );
};
