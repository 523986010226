import { Form, Row, Col, Checkbox, Typography } from "antd"
import "./pay.scss"
import { CheckOutlined, CheckCircleFilled, CreditCardFilled } from '@ant-design/icons'
import { FlowStepFooter } from "../../flowStep/flowStepFooter.component"
import { useState, useEffect, useContext } from "react"
import { UserContext } from "../../../../contexts/user.context"
import Breakpoint from "../../../../enums/breakpoint.enum"
import { updateFlow } from "../../../../services/flow.service"
import { MembershipPointsModal } from "../../../membershipPointsModal/membersipPointsModal.component"
import classNames from "classnames"
import UserHelper from "../../../../helpers/user.helper"
import MembershipStatus from "../../../../enums/membershipStatus.enum"
import useWidth from "../../../../hooks/useWidth.hook"
import { PaymentForm } from "../../../paymentForm/paymentForm.component"
import ProductHelper from "../../../../helpers/product.helper"
import { getProductTypeByCode } from "../../../../services/productType.service"

const { Text, Paragraph } = Typography

export const Pay = ({
  step,
  flow,
  setFlow,
  onSubmit,
  form,
  isSubmitting,
  onNextStep,
  onPrevStep,
  activeCard,
  setActiveCard,
  productTypeCode,
}) => {
  const { instalabMembership, currentUser } = useContext(UserContext)
  const width = useWidth()
  const [openMembershipPointsModal, setOpenMembershipPointsModal] = useState()
  const hasCredit = UserHelper.hasCredit(currentUser, step?.productType)
  const hasPartialCredit = UserHelper.hasPartialCredit(currentUser, step?.productType)
  const hasCashBalance = currentUser?.cashBalance > 0
  const [hasPurchased, setHasPurchased] = useState(null)
  const [isPaying, setIsPaying] = useState(false)
  const [totalCost, setTotalCost] = useState(null)

  useEffect(() => {
    if (!step?.productType) {
      return;
    }

    const productTypeCodes = (!Array.isArray(step?.productType)) ? [step?.productType] : step?.productType
    const fetchProductTypes = async () => {
      const types = await Promise.all(
        productTypeCodes.map(code => getProductTypeByCode(code))
      )

      if (types) {
        setTotalCost(ProductHelper.getTotalCost(types, instalabMembership, currentUser))
      }
    }
    fetchProductTypes()
  }, [step])


  useEffect(() => {
    setHasPurchased(getHasPurchased())
  }, [step, flow])


  const getHasPurchased = () => {
    if (!step || !flow) return false
    if (step.addSubscription) {
      return flow.membership?.membershipType?.code === step.subscriptionType && flow.membership?.status === MembershipStatus.ACTIVE
    }
    if (step.addProduct) {
      const productTypes = Array.isArray(step.productType) ? step.productType : [step.productType];
      return flow.products?.some(product => productTypes.includes(product.productType?.code))
    }
    return false
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onContinue()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [onSubmit, hasPurchased, onNextStep]);


  const onContinue = async (activeCard) => {
    if (activeCard) {
      setActiveCard(activeCard)
    }
    (hasPurchased) ? await onNextStep() : await onSubmit(activeCard)

    setIsPaying(false)
  }

  return (
    hasPurchased!==null && <div className="pay">
      <div className="pay-form">
        {step?.benefits?.length > 0 && (
          <div className="benefit-list">
            {step.benefits.map((benefit, benefitIndex) => (
              <div 
                key={`benefit-${benefitIndex}`}
                className="benefit-item"
              >
                <Row>
                  <Col className="benefit-col-1">
                    <CheckOutlined />
                  </Col>
                  <Col className="benefit-col-2">
                    <Text className="benefit-item-text">
                      {benefit}
                    </Text>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        )}

        {step?.instructions?.length > 0 && (
          <div className="instruction-list">
            {step.instructions.map(({ sections }, instructionIndex) => (
              <div 
                key={`instruction-${instructionIndex}`} 
                className="instruction-item"
              >
                <Row>
                  <Col
                    style={{
                      width: width > Breakpoint.SM ? 80 : '100%',
                      marginBottom: width > Breakpoint.SM ? 0 : 10
                    }}
                  >
                    <Text className="instruction-index">
                      Step {instructionIndex+1}
                    </Text>
                  </Col>

                  <Col
                    style={{
                      width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                    }}
                  >
                    {sections.map(({ title, description }, sectionIndex) => (
                      <div 
                        key={`section-${instructionIndex}-${sectionIndex}`} 
                        className="section-item"
                      >
                        <Text className="section-title">
                          {title}
                        </Text>
                        <Paragraph className="section-description">
                          {description}
                        </Paragraph>
                      </div>
                    ))}
                    </Col>
                </Row>
              </div>
            ))}
          </div>
        )}

        {step.addLongevityMembership && !instalabMembership && !hasCredit && !hasPurchased && (
          <div className="add-membership">
            <Checkbox
              onChange={async e => {
                const newValue = e.target.checked
                setFlow({
                  ...flow, 
                  addLongevityMembership: newValue,
                })
                try {
                  await updateFlow(flow._id, { 
                    addLongevityMembership: newValue,
                  })
                } catch (err) {
                  setFlow({...flow, 
                    addLongevityMembership: !newValue,
                  })
                }
              }}
              className={classNames(
                "add-membership-checkbox", 
                "default-checkbox",
              )}
              checked={flow?.addLongevityMembership}
            >
              <Paragraph className="add-membership-title">
                {step.membershipCTA ? step.membershipCTA : `Join the Instalab Membership`}
              </Paragraph>
              <Paragraph className="add-membership-description">
              Get your own longevity physician, care concierge and exclusive access and discounts to advanced diagnostics for just $49/month. Cancel anytime.

              </Paragraph>
            </Checkbox>

            <a
              onClick={e => {
                e.stopPropagation()
                setOpenMembershipPointsModal(true)
              }}
              className="view-membership-details"
            >
              View Details
            </a>

            <MembershipPointsModal 
              open={openMembershipPointsModal}
              setOpen={setOpenMembershipPointsModal}
            />
          </div>
        )}

        {!hasPurchased && !(hasCredit || totalCost === 0) && (
          <div className="pay-fields">
            <PaymentForm
              flow={flow}
              isLoading={isPaying}
              setIsLoading={setIsPaying}
              onSuccess={onContinue}
              activeCard={activeCard}
              setActiveCard={setActiveCard}
              hasSkip={step.hasSkip}
              onNextStep={onNextStep}
              onPrevStep={onPrevStep}
              productTypeCode={productTypeCode}
              step={step}
            />
          </div>
        )}

        {hasCredit || totalCost === 0 ? (
          <div className="paid-status">
            <CheckCircleFilled /> This is free, because you have credit.
          </div>
        ) : hasPurchased ? (
        <div className="paid-status">
            <CreditCardFilled /> Your payment is confirmed.
          </div>
        ) : (hasPartialCredit || hasCashBalance) ? (
          <div className="paid-status">
            <CheckCircleFilled /> This is discounted, because you have credit.
          </div>
        ) : null}


        {(hasCredit || hasPurchased || totalCost === 0) && <FlowStepFooter 
          onSubmit={() => onContinue()} 
          buttonText={hasPurchased ? 'Continue' :  totalCost ===0 || hasCredit ? 'Activate Credit' : 'Confirm Payment'}
          isSubmitting={isSubmitting}
          enterStyle={{ display: 'none' }}
          hasSkip={step.hasSkip}
          onNextStep={onNextStep}
        />}
      </div>
    </div>
  )
}