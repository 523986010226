import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'


export const uploadFile = async (file, params={}) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('patientId', params.patientId);
  formData.append('fileTypeCode', params.fileTypeCode);

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/files/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: ServiceHelper.getParams(params),
  });
  return response.data;
};

export const getFileById = async (id) => {

  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/files/${id}`, {
    headers: ServiceHelper.getHeaders(),
  });
  return response.data;
};

export const removeFile = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/files/${id}`);
  return response.data;
};



