import { useEffect, useRef, useState, useContext } from "react"
import { useSearchParams } from "react-router-dom"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { ConfirmGift } from "./confirmGift.component"
import { completeFlow } from "../../services/flow.service.js"
import { listProductTypes } from '../../services/productType.service'
import dayjs from "dayjs"
import ProductHelper from "../../helpers/product.helper"
import { UserContext } from "../../contexts/user.context"

export const Step = {
  SELECT: 'select',
  DETAILS: 'details', // ** WARNING: if you change this, you need to update all the marketing components **
  PAY_LONGEVITY_PANEL: 'pay-longevity-panel',
  PAY_LONGEVITY_PANEL_MOBILE_DRAW: 'pay-longevity-panel-mobile-draw',
  PAY_GALLERI: 'pay-galleri',
  PAY_TESTOSTERONE_PANEL: 'pay-testosterone-panel',
  PAY_FERTILITY_PANEL: 'pay-fertility-panel',
  PAY_HEART_HEALTH_PANEL: 'pay-heart-health-panel',
  PAY_CORE_MEMBERSHIP: 'pay-core-membership',
  PAY_PREMIUM_MEMBERSHIP: 'pay-premium-membership', 
  PAY_SLEEP_APNEA_TEST: 'pay-sleep-apnea-test',
  PAY_CARD_50: 'pay-card-50',
  PAY_CARD_100: 'pay-card-100',
  PAY_CARD_250: 'pay-card-250',
  PAY_CARD_500: 'pay-card-500',
  PAY_CARD_1000: 'pay-card-1000',
  PAY_TRUAGE_COMPLETE: 'pay-truage-complete',
  CONFIRM: 'confirm',
}

export const GiftFlow = () => {
  const [flow, setFlow] = useState()
  const [hasPass, setHasPass] = useState()
  const [initialUser, setInitialUser] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [searchParams] = useSearchParams()
  const { currentUser, instalabMembership } = useContext(UserContext)

  useEffect(() => {
    fetchProductTypes()
  }, [])

  useEffect(() => {
    const urlProductTypeCode = searchParams.get('code')
    if (urlProductTypeCode && !flow?.giftProductTypeCodes?.length) {
      setFlow(prev => ({
        ...prev,
        giftProductTypeCodes: [urlProductTypeCode]
      }))
    }
  }, [flow])

  useEffect(() => {
    fetchSkeleton()
  }, [flow, hasPass, initialUser, productTypes, instalabMembership, currentUser])


  useEffect(() => {
    hasCompletedFlowRef.current = false;
    return () => { 
      fetchProductTypes();
      hasCompletedFlowRef.current = false; 
    }; // Reset on unmount
    
 }, [flow?._id]);

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.GIFT_GALLERI,
            ProductTypeCode.GIFT_LONGEVITY_PANEL,
            ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW,
            ProductTypeCode.GIFT_TESTOSTERONE_PANEL,
            ProductTypeCode.GIFT_FERTILITY_PANEL,
            ProductTypeCode.GIFT_HEART_HEALTH_PANEL,
            ProductTypeCode.GIFT_CORE_MEMBERSHIP,
            ProductTypeCode.GIFT_PREMIUM_MEMBERSHIP,
            ProductTypeCode.GIFT_SLEEP_APNEA_TEST,
            ProductTypeCode.GIFT_CARD_50,
            ProductTypeCode.GIFT_CARD_100,
            ProductTypeCode.GIFT_CARD_250,
            ProductTypeCode.GIFT_CARD_500,
            ProductTypeCode.GIFT_CARD_1000,
            ProductTypeCode.GIFT_TRUAGE_COMPLETE,
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType?.code === code)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser ) return

    const selectedProductType = getProductType(flow?.giftProductTypeCodes[0])
    setSkeleton({
      [Step.SELECT]: {
        type: StepType.GIFT_SELECT,
        title: `Choose your gift`,
        description: `Select a gift. You can personalize your gift message in the next step.`,
        buildFlow: true,
        multiple: false,
        nextStep: Step.DETAILS,
        // skipIf: () => {
        //   return selectedProductType
        // }
      },
      [Step.DETAILS]: {
        type: StepType.GIFT_DETAILS,
        title: `Personalize your gift${selectedProductType ? `: ${selectedProductType.title}` : ''}`,
        description: 'Your gift will be emailed on the date of your choosing.',
        buildFlow: true,
        multiple: false,
        onNextStep: () => {
          if (!selectedProductType) return;
          switch (selectedProductType.code) {
            case ProductTypeCode.GIFT_GALLERI:
              return Step.PAY_GALLERI
            case ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW:
              return Step.PAY_LONGEVITY_PANEL_MOBILE_DRAW
            case ProductTypeCode.GIFT_TESTOSTERONE_PANEL:
              return Step.PAY_TESTOSTERONE_PANEL
            case ProductTypeCode.GIFT_FERTILITY_PANEL:
              return Step.PAY_FERTILITY_PANEL
            case ProductTypeCode.GIFT_HEART_HEALTH_PANEL:
              return Step.PAY_HEART_HEALTH_PANEL
            case ProductTypeCode.GIFT_LONGEVITY_PANEL:
              return Step.PAY_LONGEVITY_PANEL
            case ProductTypeCode.GIFT_CORE_MEMBERSHIP:
              return Step.PAY_CORE_MEMBERSHIP
            case ProductTypeCode.GIFT_PREMIUM_MEMBERSHIP:
              return Step.PAY_PREMIUM_MEMBERSHIP
            case ProductTypeCode.GIFT_SLEEP_APNEA_TEST:
              return Step.PAY_SLEEP_APNEA_TEST
            case ProductTypeCode.GIFT_TRUAGE_COMPLETE:
              return Step.PAY_TRUAGE_COMPLETE
            case ProductTypeCode.GIFT_CARD_50:
              return Step.PAY_CARD_50
            case ProductTypeCode.GIFT_CARD_100:
              return Step.PAY_CARD_100  
            case ProductTypeCode.GIFT_CARD_250:
              return Step.PAY_CARD_250
            case ProductTypeCode.GIFT_CARD_500:
              return Step.PAY_CARD_500
            case ProductTypeCode.GIFT_CARD_1000:
              return Step.PAY_CARD_1000
            default:
              return Step.PAY_LONGEVITY_PANEL
        
          }

        },  
        isLocked: () => {
          return flow?.products?.length
        }
      },
      [Step.PAY_LONGEVITY_PANEL]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.title}.</>
        },

      },
      [Step.PAY_LONGEVITY_PANEL_MOBILE_DRAW]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL_MOBILE_DRAW)?.title}.</>
        },
      },
      [Step.PAY_GALLERI]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_GALLERI)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_GALLERI)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_GALLERI)?.title}.</>
        },
      },
      [Step.PAY_TESTOSTERONE_PANEL]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_TESTOSTERONE_PANEL)?.title}.</>
        },
      },
      [Step.PAY_FERTILITY_PANEL]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_FERTILITY_PANEL)?.title}.</>
        },
      },
      [Step.PAY_HEART_HEALTH_PANEL]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_HEART_HEALTH_PANEL)?.title}.</>
        },
      },
      [Step.PAY_CORE_MEMBERSHIP]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_CORE_MEMBERSHIP)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_CORE_MEMBERSHIP)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_CORE_MEMBERSHIP)?.title}.</>
        },
      },
      [Step.PAY_PREMIUM_MEMBERSHIP]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_PREMIUM_MEMBERSHIP)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_PREMIUM_MEMBERSHIP)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_PREMIUM_MEMBERSHIP)?.title}.</>
        },
      },
      [Step.PAY_SLEEP_APNEA_TEST]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_SLEEP_APNEA_TEST)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_SLEEP_APNEA_TEST)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_SLEEP_APNEA_TEST)?.title}.</>
        },
      },
      [Step.PAY_TRUAGE_COMPLETE]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_TRUAGE_COMPLETE)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_TRUAGE_COMPLETE)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_TRUAGE_COMPLETE)?.title}.</>
        },
      },
      [Step.PAY_CARD_50]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_CARD_50)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_CARD_50)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_CARD_50)?.title}.</>
        },
      },
      [Step.PAY_CARD_100]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_CARD_100)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_CARD_100)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_CARD_100)?.title}.</>
        },
      },
      [Step.PAY_CARD_250]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_CARD_250)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_CARD_250)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_CARD_250)?.title}.</>
        },
      },
      [Step.PAY_CARD_500]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_CARD_500)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_CARD_500)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_CARD_500)?.title}.</>
        },
      },
      [Step.PAY_CARD_1000]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_CARD_1000)?.code,
        nextStep: Step.CONFIRM,
        title: () => {
          const totalCost = ProductHelper.getTotalCost([getProductType(ProductTypeCode.GIFT_CARD_1000)], instalabMembership, currentUser)
          return <>Pay <span className="true-price">${totalCost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_CARD_1000)?.title}.</>
        },
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: <>You're all set! 🎉</>,
        description: <>Your gift has been scheduled and will be emailed {
          dayjs(flow?.giftActivationDate).isSame(dayjs(), 'day') 
            ? 'in a few minutes' 
            : `on ${dayjs(flow?.giftActivationDate).format('MMMM D, YYYY')}`
        }.<br/><br/>Thank you for choosing the gift of health – it's one of the most thoughtful and impactful gifts you can give!</>,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmGift />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            try {
               hasCompletedFlowRef.current = true;
               await completeFlow(flow?._id);
            } catch (error) {
               console.error("Error completing flow:", error);
            }
         }
        }
      },
    })
  }
  return (
     <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      setHasPass={setHasPass}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      productTypeCode={flow?.giftProductTypeCodes?.[0]}
      startIndex={1}
    />
  )
}