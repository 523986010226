export const galleriPanel = {

    "Head & Neck": [
      "Larynx",
      "Nasal Cavity and Paranasal Sinuses",
      "Nasopharynx",
      "Oral Cavity",
      "Oropharynx (HPV-Mediated, p16+)",
      "Oropharynx (p16-) and Hypopharynx",
      "Soft Tissue Sarcoma of the Head and Neck"
    ],
  
    "Upper Digestive": [
      "Esophagus and Esophagogastric Junction",
      "Stomach"
    ],
  
    "Lower Digestive": [
      "Colon and Rectum",
      "Small Intestine",
      "Anus",
      "Appendix, Carcinoma"
    ],
  
    "Liver & Biliary": [
      "Bile Ducts, Distal",
      "Bile Ducts, Intrahepatic",
      "Bile Ducts, Perihilar",
      "Gallbladder",
      "Liver"
    ],
  
    "Pancreas & Related": [
      "Pancreas, Exocrine",
      "Ampulla of Vater",
      "Gastrointestinal Stromal Tumor"
    ],
  
    "Urinary": [
      "Adrenal Cortical Carcinoma",
      "Bladder, Urinary",
      "Kidney",
      "Ureter, Renal Pelvis"
    ],
  
    "Male Reproductive": [
      "Penis",
      "Prostate",
      "Testis"
    ],
  
    "Female Reproductive": [
      "Cervix",
      "Ovary, Fallopian Tube, and Primary Peritoneum",
      "Uterus, Carcinoma, and Carcinosarcoma",
      "Uterus, Sarcoma",
      "Vagina",
      "Vulva",
      "Gestational Trophoblastic Neoplasms"
    ],
  
    "Breast": [
      "Breast"
    ],
  
    "Respiratory": [
      "Lung",
      "Mesothelioma, Malignant Pleural"
    ],
  
    "Skeletal": [
      "Bone",
      "Soft Tissue Sarcoma of the Trunk and Extremities",
      "Soft Tissue Sarcoma of the Retroperitoneum",
      "Soft Tissue Sarcoma of the Abdomen and Thoracic Visceral Organs",
      "Soft Tissue Sarcoma, Unusual Histologies and Sites"
    ],
  
    "Skin & Related": [
      "Melanoma of the Skin",
      "Merkel Cell Carcinoma"
    ],
  
    "Blood & Lymphatic": [
      "Leukemia",
      "Lymphoma (Hodgkin and Non-Hodgkin)",
      "Plasma Cell Myeloma and Plasma Cell Disorders"
    ],
  
    "Neuroendocrine Tumors": [
      "Neuroendocrine Tumors of the Appendix",
      "Neuroendocrine Tumors of the Colon and Rectum",
      "Neuroendocrine Tumors of the Pancreas"
    ]
  };