import axios from "axios";
import ServiceHelper from '../helpers/service.helper'

export const addTransfer = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/transfers`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getTransfer = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/transfers/${id}`, {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const redeemTransfer = async (id, params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/transfers/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const listTransfers = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/transfers`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}