import React, { useContext, useEffect, useState } from 'react'
import { Button, Drawer, Form, message, Modal, Typography } from 'antd'
import ReactQuill from 'react-quill'
import moment from 'moment'
import { UserContext } from '../../contexts/user.context'
import { NoteForm } from '../noteForm/noteForm.component'
import parse from 'html-react-parser'
import { addNote } from '../../services/note.service'
import './noteDrawer.scss'

const { Item } = Form
const { Paragraph } = Typography

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const { confirm } = Modal

export const NoteDrawer = ({ notes: defaultNotes, patient, open, setOpen }) => {
    const [openNoteForm, setOpenNoteForm] = useState()
    const [noteForm] = Form.useForm()
    const [isLoading, setIsLoading] = useState()
    const { currentUser } = useContext(UserContext)
    const [notes, setNotes] = useState([])
    const [selectedNoteId, setSelectedNoteId] = useState()

    useEffect(() => {
      setNotes(defaultNotes)
    }, [defaultNotes])

    const onClose = () => {
      setOpen(false);
    };

    const onAddNote = async () => {
      setIsLoading(true)

      try {
        const { text } = noteForm.getFieldsValue()
        const response = await addNote({
          text,
          author: currentUser._id,
          patient: patient?._id
        })
        setNotes([
          response,
          ...notes
        ])
        message.info('Note added')
        noteForm.resetFields()
      } catch (err) {
        message.error('Failed to add note')
      }

      setIsLoading(false)
    }

    const onEditClick = async (noteId) => {
      setSelectedNoteId(noteId)
      setOpenNoteForm(true)
    }

    const onRemoveClick = async (noteId) => {
      confirm({
        title: 'Sure you want to delete this note?',
        content: 'This action cannot be undone.',
        onOk() {
          onRemoveNote(noteId)
        },
        okText: 'Yes',
        cancelText: 'No',
      });
    }
    
    const onRemoveNote = async (noteId) => {
      try {
        setNotes(notes.filter(({ _id }) => _id !== noteId))
        message.info('Note removed')
      } catch (err) {
        message.error('Failed to remove note')
      }
    }

    return <>
      <NoteForm
        open={openNoteForm}
        setOpen={setOpenNoteForm}
        onSuccess={note => {
          setNotes(notes.map(n => n._id === note._id ? note : n))
        }}
        noteId={selectedNoteId}
        patientId={patient?._id}
        currentUserId={currentUser?._id}
      />

      <Drawer 
        title={<>Notes&nbsp;&nbsp;|&nbsp;&nbsp;{patient?.firstName} {patient?.lastName}</>}
        onClose={onClose} 
        open={open}
        width={600}
        className='note-drawer'
      >
        <Form
          form={noteForm}
          onFinish={onAddNote}
          layout='vertical'
        >
          <Item 
            name="text"
            className="text-item"
          >
            <ReactQuill theme="snow" modules={modules} />
          </Item>

          <Item>
            <Button
              htmlType='submit'
              type="primary" 
              loading={isLoading}
            >
              Save Note
            </Button>
          </Item>
        </Form>

        {notes?.map(note => (
          <div 
            key={`note-${note._id}`}
            className="note-item"
          >
            <Paragraph className="note-text">
              {parse(note.text.replace(/<p><br><\/p>/g, ""))}
            </Paragraph>
            <div className="note-footer">
              {note.author.firstName} {note.author.lastName}&nbsp;&nbsp;•&nbsp;&nbsp;{moment(note.createdAt).format('MMM D, YYYY')}&nbsp;&nbsp;•&nbsp;&nbsp;<a className="edit-note-link" onClick={() => onEditClick(note._id)}>Edit</a>&nbsp;&nbsp;•&nbsp;&nbsp;<a className="remove-note-link" onClick={() => onRemoveClick(note._id)}>Remove</a>
            </div>
          </div>
        ))}
      </Drawer>
    </>
  }