import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState } from "react"
import FlowType from "../../enums/flowType.enum"
import { Step as IntakeStep } from '../intakeFlow/intakeFlow.component'
import ConfirmAppointment from "../longevityTestFlow/static/confirmAppointment/confirmAppointment.component"
import { completeFlow } from "../../services/flow.service"

export const Step = {
  CONFIRM: 'confirm',
}

export const InviteFlow = () => {
  const [flow, setFlow] = useState()
  const [initialUser, setInitialUser] = useState()
  
  const skeleton = {
    [Step.CONFIRM]: {
      type: StepType.STATIC,
      path: `/flow/${FlowType.INTAKE}/${IntakeStep.MEDICAL_CONDITIONS_CATEGORIES}`,
      title: `Your blood draw appointment is confirmed!`,
      description: `Now we just need a little more information to conduct your blood draw appointment and interpret your test results correctly.`,
      buttonText: 'Continue',
      content: <ConfirmAppointment flow={flow} />,
      onLoad: async () => {
        if (flow?._id) {
          await completeFlow(flow._id)
        }
      }
    },
  }

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      startIndex={0}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
    />
  )
}