import React, { useEffect, useState, useContext } from 'react'
import { Table, Button, Typography } from 'antd'
import { listNotes, listNotesByPatient } from "../../services/note.service"
import { NoteForm } from '../noteForm/noteForm.component';
import { UserContext } from '../../contexts/user.context.js'
import parse from 'html-react-parser';
import moment from 'moment'
import './adminNotes.scss'
import { PageHeader } from '../pageHeader/pageHeader.component.js';
import UrlHelper from '../../helpers/url.helper.js';
import { useParams } from 'react-router-dom';
import Role from '../../enums/role.enum.js';

const { Column } = Table
const { Paragraph } = Typography

export const AdminNotes = () => {
  const { currentUser } = useContext(UserContext)
  const [notes, setNotes] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isNoteModalOpen, setIsNoteModalOpen] = useState()
  const [noteId, setNoteId] = useState()
  const { patientId } = useParams()

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const isProvider = currentUser?.role === Role.PROVIDER
        const isAdmin = currentUser?.role === Role.ADMIN

        if ((isProvider || isAdmin) && patientId) {
          setNotes(await listNotesByPatient(patientId)) 
        }
  
        else if (isAdmin && !patientId) {
          setNotes(await listNotes())
        }
  
      setIsLoading(false)
  
      }
      catch(err) {
        console.log(err)
      }
  
    }
    fetchNotes()
  }, [currentUser, patientId])

  return <>
    <div className="notes">
      <PageHeader
        title='Notes'
        count={notes.length}
        actions={
          <Button
            type="primary"
            onClick={() => {
              setNoteId(null);
              setIsNoteModalOpen(true);
            }}
          >
            + Add Note
          </Button>
        }
      />

      <NoteForm
          providerId={noteId}
          open={isNoteModalOpen}
          setOpen={setIsNoteModalOpen}     
          currentUserId={currentUser}
          patientId={patientId} 
          onSuccess={note => {
            if (noteId) {
              setNotes(notes.map(p => p._id === noteId ? note : p))
            } else {
              setNotes([
                note,
                ...notes
              ])
            }
          }}
      />


      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={notes}
      >
        {!patientId && 
          <Column
            title='Patient'
            dataIndex='patient'
            width={150}
            render={patient => <a className="link" href={UrlHelper.getPatientProfile(patient._id, 'Results')} target="_blank">{patient.firstName} {patient.lastName}</a>}
          />
        }
        <Column
          title='Note'
          dataIndex='text'
          render={note=> <Paragraph className="noteText">{parse(note)}</Paragraph>}
        />

        <Column
          title='Author'
          dataIndex='author'
          render={author => `${author.firstName} ${author.lastName}`}
        />

        <Column
          title='Date'
          dataIndex='createdAt'
          render={createdAt => moment(createdAt).format('MM/DD/YYYY')}
        />
      </Table>
    </div>
  </>
}