import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Row, Col, message } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import { addNote, getNote, updateNote } from '../../services/note.service';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Item } = Form

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const NoteForm = ({ open, setOpen, onSuccess, noteId, patientId, currentUserId }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (open) {
      if (noteId) setNoteFields()
    } else {
      form.resetFields()
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const setNoteFields = async () => {
    const {text} = await getNote(noteId)

    form.setFieldsValue({
      text
    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        text,
      } = form.getFieldsValue()
      
      let params = {
       text: text,
       patient: patientId,
      }
      const response = noteId ? await updateNote(noteId, params) : await addNote(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(noteId ? 'Note updated' : 'Note added')
      setOpen(false)
      if (!noteId) {
        form.resetFields()
      }
    } catch (err) {
      let msg = noteId ? 'Failed to update note' : 'Failed to add note'
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  return (
    <div className="note-form">
      <Modal 
        open={open} 
        title={noteId ? 'Edit Note' : 'Add Note'}
        onCancel={onCancel}
        onOk={onOk}
        okText='Save'
        footer={[
          <ContinueButton
            onClick={onOk}
            text='Save Note'
            isSubmitting={isSubmitting}
          />
        ]}
      >
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
        >
         
          <Item 
            name="text"
          >
            <ReactQuill theme="snow" modules={modules} />
          </Item>

        </Form>
      </Modal>
    </div>
  )
}