const Ethnicity = Object.freeze({
  CENTRAL_OR_EAST_ASIAN: 'Asian, Central or East',
  AMERICAN_INDIAN_OR_ALASKA_NATIVE: 'American Indian or Alaska Native',
  BLACK_AFRICAN_AMERICAN_OR_AFRICAN: 'Black, African American, or African',
  HISPANIC_LATINO: 'Hispanic or Latino',
  MIDDLE_EASTERN_NORTH_AFRICAN_OR_ARAB: 'Middle Eastern, North African, or Arab',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER: 'Native Hawaiian or other Pacific Islander',
  SOUTH_OR_SOUTHEAST_ASIAN: 'Asian, South or Southeast',
  WHITE: 'White or European American'
})

export default Ethnicity