import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listPhlebotomists = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/phlebotomists`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const getPhlebotomist = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/phlebotomists/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const addPhlebotomist = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/phlebotomists`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updatePhlebotomist = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/phlebotomists/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removePhlebotomist = async (id, params={}) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/phlebotomists/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}