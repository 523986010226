import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from 'antd';
import { CrownOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { FlowType } from '../../enums/index.enum';
import './membershipAd.scss';
import { addSlack } from '../../services/slack.service';
import { UserContext } from '../../contexts/user.context';

const MembershipAd = ({cookieName}) => {
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(true);
  const { currentUser } = useContext(UserContext)

  useEffect(() => {
    const adClosed = localStorage.getItem(cookieName+currentUser._id);
    if (adClosed) {
      setIsVisible(false);
    }
  }, [cookieName]);

  const handleClose = async () => {
    try {
        await addSlack({ 
          message: 'User Clicked Closed Membership Ad - Dashboard',
          channel: process.env.REACT_APP_PATIENT_SLACK
        });
      } catch (error) {
        console.error('Failed to log message to Slack:', error);
    }
    setIsVisible(false);
    localStorage.setItem(cookieName+currentUser._id, 'true');
  };

  const onClick = async () => {
    try {
        await addSlack({ 
          message: 'User Clicked Membership Ad - Dashboard',
          channel: process.env.REACT_APP_PATIENT_SLACK
        });
      } catch (error) {
        console.error('Failed to log message to Slack:', error);
    }

    navigate(`/flow/${FlowType.MEMBER}`)

  }

  if (!isVisible) return null;

  return (
    <Card 
      className="section-card flat membership-ad" 
      style={{ position: 'relative' }}
    >
      {cookieName && <button 
        className="close-button" 
        onClick={handleClose}
      >
        &times;
      </button>}    
      <div className="section-header">
          <CrownOutlined style={{ marginRight: 8, color: '#F0B90B' }} />
          Upgrade to Premium
      </div>
      <div className="membership-content">
        <ul className="membership-benefits">
          <li><CheckCircleOutlined className="benefit-icon" /> Your own longevity physician</li>
          <li><CheckCircleOutlined className="benefit-icon" /> Coordination of tests & treatments</li>
          <li><CheckCircleOutlined className="benefit-icon" /> Special pricing on popular tests</li>
          <li><CheckCircleOutlined className="benefit-icon" /> No additional service fees</li>
        </ul>
        <Button 
          type="default" 
          block 
          size="middle"
          icon={<CrownOutlined />}
          onClick={onClick}
        >
          Learn More
        </Button>
      </div>
    </Card>
  )
  }


  export default MembershipAd