import { Modal, Form, Input, Select, message, Button } from 'antd';
import { useEffect, useState } from 'react'
import { listTests } from '../../services/test.service';
import { listPatients } from '../../services/patient.service';
import { useParams } from 'react-router-dom';
import { createAnnotation, getAnnotation, updateAnnotation } from '../../services/annotation.service';
import moment from 'moment';
import Org from '../../enums/org.enum';
import './annotationForm.scss';

const AnnotationForm = ({ open, setOpen, test, annotationId, onUpdate, onCreate }) => {
  const [tests, setTests] = useState([]);
  const [patients, setPatients] = useState([]);
  const { patientId } = useParams();
  const [loading, setLoading] = useState(false);
  const [annotation, setAnnotation] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTests();
    fetchPatients();
  }, [])

  useEffect(() => {
    fetchAnnotation()
  }, [annotationId])

  useEffect(() => {
    setFormFields()
  }, [annotation, annotationId, test, open])


  const fetchAnnotation = async () => {
    if (annotationId) {
      setAnnotation(await getAnnotation(annotationId));
    } else {
      setAnnotation(null)
    }
  }

  const setFormFields = async () => {
    if (!open) {
      form.resetFields()
      return
    }
    if (annotationId) {
      if (annotation) {
        const {
          occurredAt,
          annotationType,
          content,
          tests,
          patient,
        } = annotation
    
        form.setFieldsValue({
          occurredAt: moment(occurredAt).format('MM/DD/YYYY'),
          annotationType,
          content,
          tests,
          patient,
        });
      }
    } else {
      form.setFieldsValue({
        occurredAt: moment().format('MM/DD/YYYY'),
        tests: [test?._id] || null,
        patient: patientId
      })
    }
  }
  
  const fetchTests = async () => {
    try {
      const response = await listTests();
      const sortedTests = response.sort((a, b) => a.name.localeCompare(b.name));
      setTests(sortedTests);
    } catch (error) {
      console.error('Failed to fetch tests:', error);
      message.error('Failed to load tests');
    }
  };

  const fetchPatients = async () => {
    try {
      const response = await listPatients({
        filter: {
          org: Org.INSTALAB,
          firstName: {
            $exists: true
          },
          lastName: {
            $exists: true
          },
          email: {
            $exists: true
          }
        }
      });
      const sortedPatients = response.sort((a, b) => 
        `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
      );
      setPatients(sortedPatients);
    } catch (error) {
      console.error('Failed to fetch patients:', error);
      message.error('Failed to load patients');
    }
  };

  const formatDate = (value) => {
    // Remove all non-digits
    const numbers = value.replace(/\D/g, '');
    
    // Add slashes
    if (numbers.length <= 2) return numbers;
    if (numbers.length <= 4) return `${numbers.slice(0, 2)}/${numbers.slice(2)}`;
    return `${numbers.slice(0, 2)}/${numbers.slice(2, 4)}/${numbers.slice(4, 8)}`;
  };

  const validateDate = (date) => {
    return moment(date, 'MM/DD/YYYY', true).isValid();
  };

  const handleAddAnnotation = async (values) => {
    try {
      setLoading(true);
      
      // Convert the date string to ISO format
      const formattedDate = moment(values.occurredAt, 'MM/DD/YYYY').toDate()

      const formattedValues = {
        ...values,
        occurredAt: formattedDate,
        tests: values.tests,
        patient: values.patient
      }

      const annotation = annotationId ? await updateAnnotation(annotationId, formattedValues) : await createAnnotation(formattedValues);
      setOpen(false);
      annotationId ? onUpdate(annotation) : onCreate(annotation)
    } catch (error) {
      console.error('Failed to create annotation:', error);
      message.error('Failed to create annotation');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Add Chart Annotation"
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      footer={null}
      wrapClassName='annotation-form-wrap'
      className="annotation-form"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddAnnotation}
      >
        <Form.Item
          name="occurredAt"
          label="Date (MM/DD/YYYY)"
          rules={[
            { required: true, message: 'Please enter a date' },
            {
              validator: async (_, value) => {
                if (value && !validateDate(value)) {
                  throw new Error('Please enter a valid date in MM/DD/YYYY format');
                }
              },
            },
          ]}
          normalize={(value) => {
            if (!value) return value;
            return formatDate(value);
          }}
        >
          <Input 
            placeholder="MM/DD/YYYY"
            maxLength={10}
          />
        </Form.Item>

        <Form.Item
          name="annotationType"
          label="Type"
          rules={[{ required: true, message: 'Please select annotation type' }]}
        >
          <Select>
            <Select.Option value="positive">Positive</Select.Option>
            <Select.Option value="negative">Negative</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="content"
          label="Content"
          rules={[{ required: true, message: 'Please enter annotation content' }]}
        >
          <Input.TextArea rows={2} autoSize />
        </Form.Item>

        <Form.Item
          name="tests"
          label="Tests"
          rules={[{ required: true, message: 'Please select at least one test' }]}
        >
          <Select
            mode="multiple"
            loading={loading}
            placeholder="Select tests"
            className="annotation-select"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '100%' }}
          >
            {tests.map(t => (
              <Select.Option key={t._id} value={t?._id}>
                {t.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        
        <Form.Item
          name="patient"
          label="Patient"
          rules={[{ required: true, message: 'Please select a patient' }]}
        >
          <Select
            loading={loading}
            placeholder="Select patient"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '100%' }}
          >
            {patients.map(patient => (
              <Select.Option key={patient._id} value={patient._id}>
                {`${patient.firstName} ${patient.lastName}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button className="annotation-btn" type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
            {annotationId ? 'Update Annotation' : 'Create Annotation'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AnnotationForm;