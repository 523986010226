import { TestItemList } from "../testItemLlist/testItemList.component";
import { TestChart } from "../testChart/testChart.component";


export const TestView = ({tests, report, user, results, reports }) => {
  // Find the current result
  //const currentResult = results.filter(result => result._id === report.result._id)[0];

  // Find the index of the current result
  //const currentIndex = results.findIndex(result => result._id === currentResult._id);

  // Get the current result and all subsequent results
  //const filteredResults = results.slice(currentIndex);

  // let availableTests = tests?.filter(test => filteredResults[0]?.values.some(value => value.test === test._id) || (filteredResults.length>1 && filteredResults[1].hasRedraw && filteredResults[1]?.values.some(value => value.test === test._id)))


  return (tests && (
    <>
        {(tests.length === 1 || tests.length === 1) ? (
          <TestChart 
            report={report} 
            code={tests[0].code} 
            tests={tests}
            results={results}
            reports={reports}
          />
        ) : (
          <TestItemList
            report={report}
            testIds={tests.map(({ _id }) => _id)}
            tests={tests}
            results={results}
            user={user}
            reports={reports}
          />
        )}    
    </>
  ))
}