import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listOrders = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/orders`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const getOrder = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/orders/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const updateOrder = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/orders/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addOrder = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/orders`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeOrder = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/orders/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const uploadRequisitionFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/orders/upload-requisition-file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const getRequisitionFileLink = async (fileName) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/orders/requisition-file-link/${fileName}`);
  return response.data.link;
};