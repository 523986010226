import React, { useEffect, useState } from 'react'
import { Drawer, Descriptions, Table } from 'antd'
import { getPatient } from '../../services/patient.service'
import { getSignedUrl, listFilesByPatient } from '../../services/gdrive.service';
import Gender from '../../enums/gender.enum'
import moment from 'moment'
import './heartHealthIntakeDrawer.scss'

const { Item } = Descriptions
const { Column } = Table

const preferenceOptions = [{
  label: `I'm comfortable taking medication`,
  value: `comfortable`,
}, {
  label: `Only if absolutely necessary`,
  value: `if_necessary`,
}, {
  label: `I prefer not to take medication`,
  value: `avoid`,
}]

export const HeartHealthIntakeDrawer = ({ patient, open, setOpen }) => {
    const [isLoading, setIsLoading] = useState()
    const [data, setData] = useState()
    const [files, setFiles] = useState([])

    useEffect(() => {
        if (patient) {
          fetchData()
          fetchFiles()
        } else {
          setData(null)
          setFiles([])
        }
    }, [patient])

    const onClose = () => {
      setOpen(false);
    };

    const fetchFiles = async () => {
      setFiles(await listFilesByPatient(patient._id))
    }

    const fetchData = async () => {
      setIsLoading(true)
      const data = await getPatient(patient._id, {
        select: 'heartHealthPurpose gender isPregnant isBreastfeeding ethnicity heartHealthFamilyHistory dob hasSmoking alcoholQty heartHealthConditions hasEndStageLiverDisease freeformMedications medicationPreference freeformSupplements hasBloodPressureMonitor govId'
      })
      setData(data)
      setIsLoading(false)
    }

    const FileListItem = ({ file }) => (
      <div>
        <img src={file.iconLink} style={{ width: 12, marginRight: 5, marginBottom: -1 }} />
        <a className="link" target="_blank" rel="noopener noreferrer">{file.name}</a>
      </div>
    );

    const viewFile = async (fileId) => {
      try {
        const signedUrl = await getSignedUrl(fileId);
        window.open(signedUrl, '_blank');
      } catch (error) {
        console.error("Error viewing file:", error);
        alert("Failed to view file");
      }
    };

    return <>
      <Drawer 
        title={<>Intake&nbsp;&nbsp;|&nbsp;&nbsp;{patient?.firstName} {patient?.lastName}</>}
        onClose={onClose} 
        open={open}
        width={1000}
        loading={isLoading}
        className='heart-health-intake-drawer'
      >
        {data && (
          <Descriptions bordered column={1}>
            <Item label="Purpose">
              <ul className="intake-list">
                {data.heartHealthPurpose?.length === 1 && data.heartHealthPurpose[0]}
                {data.heartHealthPurpose?.length > 1 && data.heartHealthPurpose?.map((purpose, index) => (
                  <li key={`purpose-${index}`}>
                    {purpose}
                  </li>
                ))}
              </ul>
            </Item>
            <Item label="Gender">{data.gender}</Item>
            <Item label="Date of Birth">{data.dob}</Item>
            <Item label="Ethnicity">
              {data.ethnicity?.length === 1 && data.ethnicity[0]}
              {data.ethnicity?.length > 1 && <>
                <ul className="intake-list">
                  {data.ethnicity?.map((ethnicity, index) => (
                    <li key={`ethnicity-${index}`}>
                      {ethnicity}
                    </li>
                  ))}
                </ul>
              </>}
            </Item>
            {data.gender === Gender.FEMALE && <>
              <Item label="Pregnant">{data.isPregnant ? 'Yes' : 'No'}</Item>
              <Item label="Breastfeeding">{data.isBreastfeeding ? 'Yes' : 'No'}</Item>
            </>}
            <Item label="Family History">{data.heartHealthFamilyHistory?.join(', ')}</Item>
            <Item label="Smoking">{data.hasSmoking ? 'Yes' : 'No'}</Item>
            <Item label="Alcohol Quantity">{data.alcoholQty}</Item>
            <Item label="Health Conditions">
                {data.heartHealthConditions?.length === 1 && data.heartHealthConditions[0]}
                <ul className="intake-list">
                  {data.heartHealthConditions?.length > 1 && data.heartHealthConditions?.map((condition, index) => (
                    <li key={`condition-${index}`}>
                      {condition}
                    </li>
                  ))}
                </ul>
            </Item>
            <Item label="End Stage Liver Disease">{data.hasEndStageLiverDisease ? 'Yes' : 'No'}</Item>
            <Item label="Medications">{data.freeformMedications}</Item>
            <Item label="Medication Preference">{data.medicationPreference && preferenceOptions.find(p => p.value === data.medicationPreference)?.label}</Item>
            <Item label="Supplements">{data.freeformSupplements}</Item>
            <Item label="Has Blood Pressure Monitor">{data.hasBloodPressureMonitor ? 'Yes' : 'No'}</Item>
            <Item label="Gov ID">
              {data.govId && <img className="gov-id-photo" src={`${data.govId}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} />}
            </Item>

            {files?.length > 0 && (
              <Item label="Files" className="files-item">
                <Table
                  size="small"
                  pagination={false}
                  rowKey="id" 
                  dataSource={files}
                  showHeader={false}
                  className="file-table"
                  onRow={({ id }) => {
                    return {
                      onClick: () => {
                        viewFile(id)
                      }
                    }
                  }}
                >
                  <Column
                    key="action"
                    render={(_, file) => <FileListItem file={file} />}
                    onCell={() => ({
                      'data-label': 'Filename' // Adding data-label for mobile display
                    })}
                  />
                  <Column
                    key="createdTime"
                    dataIndex="createdTime"
                    render={(text) => moment(text).format('MM/DD/YY')}
                    onCell={() => ({
                      'data-label': 'Date added' // Adding data-label for mobile display
                    })}
                  />
                </Table>  
              </Item>
            )}
          </Descriptions>
        )}
      </Drawer>
    </>
  }