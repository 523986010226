import { useContext } from 'react'
import { Typography, Row, Col, Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'
import PosthogHelper from '../../helpers/posthog.helper'
import { UserContext } from '../../contexts/user.context'
import "./latestPosts.scss"
import { BlogData } from '../../data/blog.data'

const { Paragraph } = Typography

const LatestPosts = () => {
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)

  return (
    <div className="latest-posts">
      <div className="posts-header">
        <Paragraph className="posts-header-text">
          Read Latest Research
        </Paragraph>
      </div>

      <div className="post-list">
        <Row gutter={20}>
          {BlogData.filter(({ isPublished, img }) => isPublished && img).slice(0,4).map(({ id, title, description, img, publishedAt, readTime }) => (
            <Col 
              key={`blog-${id}`}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <div 
                className="post-item"
                onClick={() => {
                  PosthogHelper.track(currentUser, `[research] click on post: ${id}`, true)
                  navigate(`/blog/${id}`)
                }}
              >

                <div className="post-image-container">
                  <img 
                    src={img} 
                    className="post-image"
                  />

                  <Skeleton.Avatar 
                    active
                    shape='square' 
                    className="image-skeleton"
                  />
                </div>

                <Paragraph 
                  className="post-title"
                  ellipsis={{
                    rows: 1
                  }}
                >
                  {title}
                </Paragraph>

                <Paragraph 
                  className="post-content" 
                  ellipsis={{
                    rows: 3
                  }}
                >
                  {description}
                </Paragraph>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

export default LatestPosts