import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listAnnotatedReports = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/reports/annotated`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const listReports = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/reports`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const getReport = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/reports/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const updateReport = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/reports/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

// Function to run all reports for a patient
export const runReports = async (patientId) => {
  try {
    // Call the backend API to run all reports for a specific patient
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/reports/run`, ServiceHelper.getParams({patientId}), {
      headers: ServiceHelper.getHeaders()
    })
    return response.data;
  } catch (error) {
    console.error("Error running all reports:", error);
    throw error;
  }
};