import React, { useEffect, useState } from 'react'
import './testItemList.scss'
import { Typography, Button, Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons'
import ReadableRiskLevel from "../../../enums/readableRiskLevel.enum";
import classNames from "classnames";
import { TestModal } from '../../testModal/testModal.component';
import RiskLevel from '../../../enums/riskLevel.enum';

const { Text, Paragraph } = Typography

export const TestItemList = ({ testIds, report, tests, results, user, reports }) => {
  const [items, setItems] = useState()
  const [openModal, setOpenModal] = useState()
  const [testModalId, setTestModalId] = useState()
  const [showAll, setShowAll] = useState()

  useEffect(() => {
    if (user && tests?.length && results?.length && testIds) {
      setItems(getItems())
    }
  }, [user, tests, results, report, testIds])

  const getItems = () => {
    return testIds.filter(testId => {
      return getRisk(testId)
    }).map(testId => {
      const {
        tagline,
        name,
        _id
      } = getTest(testId)
      return {
        risk: getRisk(testId),
        tagline,
        name,
        _id,
      }
    }).sort((a, b) => {
      const priority = {
        [RiskLevel.HIGH]: 2,
        [RiskLevel.ABNORMAL]: 1,
        [RiskLevel.MODERATE]: 0,
      }
      return priority[getRisk(b._id)] - priority[getRisk(a._id)]
    })
  }

  const getRisk = (testId) => {
    let filteredResults = [...results]
    if (report) {
      filteredResults = filteredResults.filter(result => new Date(result.collectedAt).getTime() <= new Date(report.result.collectedAt).getTime())
    }
    return filteredResults.sort((a, b) => {
      return new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime()
    }).find(result => {
      return result.values.some(value => value.value && value.test === testId)
    })?.values.find(value => {
      return value.test === testId
    }).risk
  }

  const getTest = (testId) => {
    return tests.find(({ _id }) => _id === testId)
  }

  return items && (
    <div className="test-item-list">
      <TestModal
        open={openModal}
        setOpen={setOpenModal}
        testId={testModalId}
        setTestId={setTestModalId}
        report={report}
        tests={tests}
        results={results}
        reports={reports}
      />

      <Row>
        {items.slice(0, showAll ? 100 : 2).map(item => (
          <Col
            key={`test-item-${item._id}`}
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <div 
              className="test-item"
              onClick={() => {
                setTestModalId(item._id)
                setOpenModal(true)
              }}
            >
              <Text className="test-name">
                {item.name}
              </Text>

              <Paragraph
                className="test-details"
                ellipsis={{
                  rows: 3
                }}
              >
                {item.tagline}
              </Paragraph>

              <div className={classNames(
                "risk-tag",
                `${item.risk}-risk-tag`
              )}>
                {ReadableRiskLevel[item.risk]}
              </div>
            </div>
          </Col>
        ))}
      </Row>

        {!showAll && testIds?.length > 2 && (
          <Button
            icon={<DownOutlined />}
            type='primary'
            onClick={() => setShowAll(true)}
            className="show-all-button"
          >
            View All
          </Button>
        )}
    </div>
  )
}