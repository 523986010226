import React, { useEffect, useState } from 'react';
import { Button } from 'antd'
import { listTests } from "../../services/test.service"
import './tests.scss'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { PatientSpreadModal } from '../patientSpreadModal/patientSpreadModal.component';
import { listPatients } from "../../services/patient.service"
import { listResults } from "../../services/result.service"
import { listAbsoluteCategories } from '../../services/absoluteCategory.service';
import RiskLevel from '../../enums/riskLevel.enum';

export const Tests = () => {
  const [tests, setTests] = useState([])
  const [isLoading, setIsLoading] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const [absoluteCategoryNames, setAbsoluteCategoryNames] = useState([])
  const [isSpreadModalOpen, setIsSpreadModalOpen] = useState(false);
  const [spreadData, setSpreadData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [results, setResults] = useState()
  const [patients, setPatients] = useState()
  const [absoluteCategories, setAbsoluteCategories] = useState()


  useEffect(() => {
    document.title = '[Admin] Biomarkers'
    fetchData()
  }, [])

  useEffect(()=> {
    setTableData(getTableData())
  }, [tests, patients, results])

  const fetchData = async () => {
    setIsLoading(true); // Start loading
    await fetchResults();
    await fetchPatients();
    await fetchTests();
    await fetchAbsoluteCategories();
  };

  const formatTableData = (patient, test, latestValue) => {
    if (!patient || !test || !latestValue) return null;
  
    const { risk, value } = latestValue;
    
    return {
      ...patient,
      risk,
      value: typeof value === 'string' ? Number(value.replace(/</g, '')) : value
    };
  };
  
  const getTableData = () => {
    if (!patients || !results || !tests) return [];
  
    return tests.map(test => {
      const abnormalPatients = [];
      const healthyPatients = [];
  
      patients.forEach(patient => {
        const latestValue = getLatestValue(patient._id, test._id);
  
        if (latestValue) {
          const formattedData = formatTableData(patient, test, latestValue);
          if (formattedData) {
            if (latestValue.risk !== RiskLevel.OPTIMAL) {
              abnormalPatients.push(formattedData);
            } else {
              healthyPatients.push(formattedData);
            }
          }
        }
      });
      setIsLoading(false); // Stop loading after data fetching is done

      return {
        ...test,
        abnormalPatients,
        healthyPatients,
      };
    });
  };

  const getLatestValue = (patientId, testId) => {
    if (!results) return null
    return results.find(result => {
      return result.patient._id === patientId && result.values.some(value => value.value && value.test === testId)
    })?.values.find(value => {
      return value.test === testId
    })
  }

  const fetchResults = async () => {
    setResults(await listResults({select: '_id values patient', annotate: true}))
  }

  const fetchPatients = async () => {
    const filter = {
      org: 'instalab',
      firstName: { $exists: true, $ne: null }, // Ensure firstName exists and is not null
      lastName: { $exists: true, $ne: null },  // Ensure lastName exists and is not null
    };
  
    const fetchedPatients = await listPatients({ filter });
    setPatients(fetchedPatients);
  };

  const fetchTests = async () => {
    setIsLoading(true)
    let params = {
      select: '_id name code absoluteCategory',
      filter: {},
      populate: [{
        path: 'absoluteCategory',
        select: 'name',
      }],
      sort: 'name',
      collation: { locale: 'en', strength: 2 }
    }
    const fetchedTests = await listTests(params)
    setAbsoluteCategoryNames([...new Set(fetchedTests.filter(({ absoluteCategory }) => absoluteCategory).map(({ absoluteCategory }) => absoluteCategory.name))].sort())
    setTests(fetchedTests)
    setFilteredCount(fetchedTests.length)
    setIsLoading(false)
  }

  const fetchAbsoluteCategories = async () => {
    setAbsoluteCategories(await listAbsoluteCategories() || [])
  }

  const getCustomFilter = (test, value) => {
    return {
      absoluteCategory: () => test.absoluteCategory?.name === value,
    }
  }

  const openSpreadModal = (data) => {
    setSpreadData(data);
    setIsSpreadModalOpen(true);
  };


  return (
     <div className="tests">
      <PatientSpreadModal 
        open={isSpreadModalOpen}
        setOpen={setIsSpreadModalOpen}
        data={spreadData}
        setData={setSpreadData}
        tests={tests}
        tableData={tableData}
      />


      <PageHeader 
        title='Biomarkers' 
        count={filteredCount}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={tableData}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        columns={[{
          title: 'Name',
          dataIndex: 'name',
          filterDropdownType: FilterDropdownType.INPUT,
        }, {
          title: 'Code',
          dataIndex: 'code',
          width: 90,
          filterDropdownType: FilterDropdownType.INPUT,
        }, {
          title: 'Category',
          dataIndex: 'absoluteCategory',
          width: 150,
          render: absoluteCategory => absoluteCategory?.name,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: absoluteCategoryNames
        }, 
        {
          title: 'Abnormal',
          dataIndex: 'abnormalPatients',
          render: abnormalPatients => abnormalPatients?.length
        },
        {
          title: 'Healthy',
          dataIndex: 'healthyPatients',
          render: healthyPatients => healthyPatients?.length
        },
        {
          title: 'Actions',
          render: (test) => (
            <Button onClick={() => openSpreadModal(test)}>
              View Spread
            </Button>
          ),
        }]}
      />
    </div>
 
  )
}