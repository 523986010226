const Tracker = Object.freeze({
  APPLE_WATCH: "Apple Watch",
  CGM: "Continuous Glucose Monitor",
  EIGHT_SLEEP: "Eight Sleep",
  FITBIT: "Fitbit",
  GARMIN_WATCH: "Garmin Watch",
  OURA: "Oura Ring",
  WHOOP: "Whoop",
  OTHER: "Other"
});

export default Tracker;
