import { Outlet } from 'react-router-dom';
import { Header } from '../header/header.component';
const DefaultLayout = () => {
  return (
    <>
      <Header hasSearch={true} />
      <Outlet />
    </>
  )
};

export default DefaultLayout