import React, { useState } from 'react';
import { Modal, Button, Select } from 'antd';
import PrescriptionStatus from '../../enums/prescriptionStatus.enum';

const StatusUpdateModal = ({ visible, onClose, prescriptionId, onUpdate }) => {
  const [status, setStatus] = useState();

  const handleUpdate = () => {
    onUpdate(prescriptionId, status);
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title="Update Prescription Status"
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="update" type="primary" onClick={handleUpdate}>
          Update
        </Button>,
      ]}
    >
      <Select
        value={status}
        onChange={(value) => setStatus(value)}
        placeholder="Select Status"
        style={{ width: '100%' }}
      >
        <Select.Option value={PrescriptionStatus.PENDING}>pending</Select.Option>
        <Select.Option value={PrescriptionStatus.SENT}>sent</Select.Option>
        <Select.Option value={PrescriptionStatus.PAUSED}>paused</Select.Option>
      </Select>
    </Modal>
  );
};

export default StatusUpdateModal;