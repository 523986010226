import React, { useRef, useState, useEffect } from 'react'
import { Modal, Form, Input, Tooltip, message } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import { addAdmin, getAdmin, updateAdmin } from '../../services/admin.service';
import { PickerInline } from 'filestack-react';
import { DeleteOutlined } from '@ant-design/icons'
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper';
import './adminForm.scss';
import PhoneInput from '../phoneInput/phoneInput.component';

const { Item } = Form

export const AdminForm = ({ open, setOpen, onSuccess, adminId, select, populate=[] }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [photo, setPhoto] = useState(null)
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (open) {
      if (adminId) setAdminFields()
    } else {
      form.resetFields()
      setPhoto(null)
      setHasAttempt(false)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const setAdminFields = async () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      slack,
      photo: selectedPhoto,
    } = await getAdmin(adminId, {
      select: 'firstName lastName email phone slack photo',
      populate: []
    })

    if (selectedPhoto) {
      setPhoto(selectedPhoto)
    }

    form.setFieldsValue({
      firstName,
      lastName,
      email,
      phone,
      slack
    })
  }

  const onFail = () => {
    setHasAttempt(true)
    message.error('Enter valid admin data')
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const params = {
        fields: {
          ...form.getFieldsValue(),
          photo
        },
        select,
        populate,
      }
      const response = adminId ? await updateAdmin(adminId, params) : await addAdmin(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(adminId ? 'Admin updated' : 'Admin added')
      setOpen(false)
      if (!adminId) {
        form.resetFields()
      }
    } catch (err) {
      let msg = adminId ? 'Failed to update admin' : 'Failed to add admin'
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email'
      }
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  return (
    <Modal 
      open={open} 
      title={adminId ? 'Edit Admin' : 'Add Admin'}
      onCancel={onCancel}
      footer={null}
      className="admin-form"
    >
      <Form
        form={form}
        onFinish={onOk}
        onFinishFailed={onFail}
        layout='vertical'
      >
        <Item name="picker">
          {photo && (
            <Tooltip title="Click to remove photo" zIndex={2000} placement="right">
              <div 
                className="staff-form-photo"
                onClick={() => {
                  setPhoto(null)
                }}
              >
                <img 
                  src={`${photo}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} 
                />
                  <div className="staff-photo-overlay">
                    <DeleteOutlined />
                  </div>
              </div>
            </Tooltip>
          )}
          <div id="picker-container" style={{ display: photo ? 'none' : 'block' }}>
            <PickerInline
              apikey={process.env.REACT_APP_FILESTACK_API_KEY}
              onUploadDone={({ filesUploaded }) => {
                if (filesUploaded?.length) {
                  const { url } = filesUploaded[0]
                  setPhoto(url)
                }
              }}
              pickerOptions={{
                accept: ['image/*'],
                fromSources: ['local_file_system'],
                container: 'picker-container'
              }}
              clientOptions={{
                security: {
                  policy: process.env.REACT_APP_FILESTACK_POLICY,
                  signature: process.env.REACT_APP_FILESTACK_SIGNATURE
                }
              }}
            >
              <div className="picker-container"></div>
            </PickerInline>
          </div>
        </Item>

        <Item 
          label="Email Address"
          name="email"
          onInput={e => e.target.value = e.target.value.toLowerCase()}
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isEmail,
            RuleHelper.isUniqueEmail({
              fieldName: 'email',
              userId: adminId,
            })
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Email Address" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Phone Number"
          name="phone"
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isPhone,
          ]}
          validateTrigger='onSubmit'
        >
          <PhoneInput 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="First Name"
          name="firstName"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="First Name" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Last Name"
          name="lastName"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Last Name" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Slack"
          name="slack"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Slack" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <ContinueButton
          text='Save Admin'
          isSubmitting={isSubmitting}
        />
      </Form>
    </Modal>
  )
}