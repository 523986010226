import { useNavigate, useParams } from "react-router-dom";
import "./paymentLinkLogin.scss";
import { Button, Form, Input, message, Typography } from "antd";
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper'
import StorageKey from "../../enums/storageKey.enum";
import { useContext, useRef, useState, useEffect } from "react";
import { UserContext } from "../../contexts/user.context";
import { login } from "../../services/auth.service";

const { Item } = Form
const { Paragraph } = Typography

export const PaymentLinkLogin = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState()
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt
  const { setToken } = useContext(UserContext)

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };  
  }, []);

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch ({ errors, values }) {
      form.setFieldsValue(values)
      return true
    }
    return false
  }

  const onSubmit = async () => {
    setHasAttempt(true)
    setIsLoading(true)
    try {
      if (await hasFormError()) {
        message.error('Correct form errors before proceeding')
        setIsLoading(false)
        return 
      }
      
      const response = await login(form.getFieldsValue())
      setToken(response.token)
      localStorage.setItem(StorageKey.TOKEN, response.token)
      message.info("Logged in")
      navigate(`/payment/${id}`)
    } catch (err) {
      message.error(err.response?.data?.message || 'Failed to log in')
    }
    setIsLoading(false)
  }


  const onChat = () => {
    window.FrontChat("show");
  }

  return (
    <div className="payment-link-login">
      <Paragraph className="login-title">
        Log in to your Instalab account.
      </Paragraph>
      <Paragraph className="login-description">
        You will be redirected to your payment form after you log in. If you have any questions, <a onClick={onChat} className="message-link">send us a message.</a>
      </Paragraph>
      <Form
        form={form}
        layout='vertical'
        className="login-form"
      >
        <Item 
          label="Email Address"
          name="email"
          onInput={e => e.target.value = e.target.value.toLowerCase()}
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isEmail,
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Email Address" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Password"
          name="password"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Input.Password
            placeholder="Password" 
            type="password"
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item>
          <Button
            type='primary'
            loading={isLoading}
            className="submit-btn"
            onClick={onSubmit}
          >
            Log In
          </Button>
        </Item>
      </Form>
    </div>
  )
}