import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listProductTypes = async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/product-types`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders()
  })

  return response.data
}

export const getProductType = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/product-types/${id}`, {
    params: ServiceHelper.getParams({
      idType: '_id'
    }),
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getProductTypeByCode = async (code) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/product-types/code/${code}`, {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addProductType = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/product-types`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updateProductType = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/product-types/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeProductType = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/product-types/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}