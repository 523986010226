import { Typography } from "antd"
import "./privacy.scss";
import { useEffect } from "react";
import { PgFooter } from "../pgFooter/pgFooter.component";

const { Text, Paragraph } = Typography

export const Privacy = () => {
  useEffect(() => {
    document.title = 'Instalab | Privacy Policy'
  }, [])

  return <>
    <div className="privacy">
      <Text className="privacy-header">
        Privacy Policy
      </Text>
      <Text className="privacy-subheader">
        Last updated: Feb 1, 2022
      </Text>

      <Text className="privacy-title">
        Introduction 
      </Text>
      <Paragraph className="privacy-paragraph">
        Instalab, Inc., on its own behalf and its affiliates and/or wholly owned subsidiaries including but not limited to Instamedical, P.C. (collectively referred to as "Instalab", "we", "us", or "our"), makes certain telehealth related information available to you and/or facilitates your access to telemedicine, chronic condition management, and expert medical services ("Instalab Services").
      </Paragraph>
      <Paragraph className="privacy-paragraph">
        This Privacy Policy applies to visitors of the following publicly available websites: www.instalab.com, www.instamedical.org and the publicly available associated domains and mobile applications owned and operated by Instalab (collectively, the "Websites"). For the purposes of this Disclaimer, "you" and "your" means you as the visitor of the Websites.
      </Paragraph>
      
      <Text className="privacy-title">
        Scope of this Web and Mobile Privacy Policy
      </Text>
      <Paragraph className="privacy-paragraph">
        This Privacy Policy describes the types of Personal Information we collect from visitors of our public Websites and our practices for using, maintaining, sharing, and protecting it. It also describes the rights and choices you may have with respect to your Personal Information and how you may contact us.
      </Paragraph>
      <Paragraph className="privacy-paragraph">
        This Privacy Policy does not apply to information collected from visitors who register and log-in ("Members") to the password-protected and secure portions of our sites ("Secure Platforms"). The Secure Platforms allow eligible Members to use Instalab Services. All information collected and stored by Instalab or added by our Members into such Secure Platforms is considered Protected Health Information ("PHI") and/or medical information and is governed by laws that apply to that information, for example the Health Insurance Portability and Accountability Act (HIPAA). How Instalab uses and discloses such PHI is in accordance with the applicable Notice of Privacy Practices. To understand how we use and disclose PHI, for example data you have consented to import from HealthKit into the Secure Platform, you should review our Notice of Privacy Practices. Instalab will not use or disclose information received from HealthKit for advertising, marketing, or other use-based data mining purposes and will not combine it with other data and will not sell it.
      </Paragraph>
      <Paragraph className="privacy-paragraph">
        This Privacy Policy also does not apply to the collection and use of certain employment-related information. If you are a current or former Instalab job applicant, employee, owner, director, officer, or contractor, please contact us at privacy@Instalabhealth.com for the appropriate notice.
      </Paragraph>
      <Paragraph className="privacy-paragraph">
        When using our Websites, you may choose to interact with features from third parties that operate independently from Instalab, such as social media widgets and links to third-party websites. Instalab has no control over and is not responsible for the privacy practices of such third parties. This Privacy Policy does not apply to the extent Instalab does not own or control any linked websites or features you visit or use. We recommend that you familiarize yourself with the privacy practices of those third parties.
      </Paragraph>
      <Paragraph className="privacy-paragraph">
        California residents should read the information available in Section 12 below about the categories of personal information to be collected from them and the purposes for which the personal information will be used.
      </Paragraph>
      <Paragraph className="privacy-paragraph">
        This Privacy Policy is not a contract and does not create any contractual rights or obligations.
      </Paragraph>

      <ol>
        <li>
          <Text className="privacy-section-title">
            TYPES OF PERSONAL INFORMATION WE COLLECT
          </Text>
          <Paragraph className="privacy-paragraph">
            While using our Websites, you may provide us with certain information about you. We or our advertising partners also may automatically collect information through the use of cookies and other tracking technologies (see below).
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            Personal Information that we collect through your use of the Websites may include IP Address, Device ID, and online identifier. We may use that Personal Information and link it to Internet or other electronic network activity information, and we may draw inferences about you from the information we collect. We may also collect your name, title, business contact information, phone number, date of birth, state, country, zip code, your health plan, email address or login identification information only if you provide such information directly to us when you begin registration to use a Secure Platform or complete a web form seeking more information.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            HOW WE COLLECT PERSONAL INFORMATION
          </Text>
          <Paragraph className="privacy-paragraph">
            We may collect Personal Information using the following methods:
          </Paragraph>
          <ul>
            <li>Directly from you when you provide it to us (such as information you enter into web forms, inquiries, responses, activity on the Websites, and during registration to use a Secure Platform).</li>
            <li>From third parties, such as analytics and email marketing service providers.</li>
            <li>Automatically through tracking technologies such as cookies, web beacons (also known as pixels) and log files, including over time and across third-party websites or other online services.</li>
          </ul>
          <Paragraph className="privacy-paragraph">
            "Cookies" are small files that a website stores on a user's computer or device. The Websites may use cookies for various purposes, including to keep the information you enter on multiple pages together. Some of the cookies we use are "session" cookies, meaning that they are automatically deleted from your hard drive after you close your browser at the end of your session. Session cookies are used to optimize performance of the Websites and to limit the amount of redundant data that is downloaded during a single session. We also may use "persistent" cookies, which remain on your computer or device unless deleted by you (or by your browser settings). We may use persistent cookies for various purposes, such as statistical analysis of performance to ensure the ongoing quality of our services. We and third parties may use session and persistent cookies for analytics and advertising purposes, as described herein. Most web browsers automatically accept cookies, but you may set your browser to block certain cookies (see below). In accordance with applicable law, we may obtain your consent separately before collecting information by automated means using cookies or similar devices.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            Our Websites may use Google Analytics, a vendor's service that uses cookies, web beacons, web pixels and/or similar technology to collect and store anonymous information about you. You can learn more about Google Analytics' privacy policy and ways to opt out from Google Analytics tracking by visiting Google Analytics' website.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            For more information about how we use Personal Information collected through tracking technologies and the ways you may be able to manage it, see below.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            HOW WE USE YOUR INFORMATION
          </Text>
          <Paragraph className="privacy-paragraph">
            We may use your Personal Information for the following purposes:
          </Paragraph>
          <ul>
            <li>Operate, maintain, supervise, administer, and enhance the Websites, including monitoring and analyzing the effectiveness of content on the Websites, aggregate site usage data, and other usage of the Websites such as checking your eligibility and assisting you in completing the registration process.</li>
            <li>Provide you with a tailored and user-friendly experience as you navigate our Websites.</li>
            <li>Promote and market our Websites to you.</li>
            <li>To complete the activity you specifically asked for, e.g., register on a Secure Platform, obtain more information, request a demo, or request an RFP.</li>
            <li>Conduct research on users' demographics, interests, and behavior based upon information provided during use of our Websites.</li>
            <li>Anonymize and aggregate information for analytics and reporting.</li>
            <li>Respond to law enforcement requests and court orders and legal process and carry out our legal and contractual obligations and enforce our rights.</li>
            <li>Authenticate use, detect potential fraudulent use, and otherwise maintain the security of the Websites and safety of users.</li>
            <li>Develop, test, improve, and demonstrate the Websites.</li>
            <li>Any other purpose with your consent.</li>
          </ul>
        </li>

        <li>
          <Text className="privacy-section-title">
            HOW WE SHARE YOUR INFORMATION
          </Text>
          <Paragraph className="privacy-paragraph">
            We may share Personal Information with third parties in certain circumstances or for certain purposes, including:
          </Paragraph>
          <ul>
            <li><i>For business purposes.</i> We may share your Personal Information with vendors and service providers, including our data hosting and data storage partners, analytics and advertising providers, technology services and support, and data security. We also may share Personal Information with professional advisors, such as auditors, law firms, and accounting firms. We may disclose your name, email address, date of birth, phone number, and address if you provided it to us via a form on the website or during an incomplete or failed registration to Service Providers.</li>
            <li><i>With your direction or consent.</i> We may share your Personal Information with third parties if you request or direct us to do so. This includes your use of social media widgets on our Websites.</li>
            <li><i>With affiliates within our corporate group.</i> We may share your Personal Information with any subsidiaries within our corporate group.</li>
            <li><i>Compliance with law.</i> We may share your Personal Information to comply with applicable law or any obligations thereunder, including cooperation with law enforcement, judicial orders, and regulatory inquiries.</li>
            <li><i>In the context of a transaction.</i> We may share your Personal Information connection with an asset sale, merger, bankruptcy, or other business transaction.</li>
            <li><i>For other business reasons.</i> We may share your Personal Information to enforce any applicable Legal Disclaimer, and to ensure the safety and security of the Websites and/or our users.</li>
            <li><i>For advertising.</i> Using cookies and web beacons, we may disclose Personal Information regarding your activity on our Websites to our advertising partners to optimize marketing. The Personal Information we share for this purpose is limited to IP address, device ID, and online identifier. We do not share identifying information such as names, email addresses, phone numbers, or any health information.</li>
          </ul>
          <Paragraph className="privacy-paragraph">
            We also may disclose deidentified information. Note that if you make any Personal Information publicly available on the Websites, anyone may see and use such information.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            SOCIAL FEATURES
          </Text>
          <Paragraph className="privacy-paragraph">
            Certain features of the Websites permit you to initiate interactions between the Websites and third-party services or platforms, such as social networks ("Social Features"). Social Features include features that allow you to click and access Instalab's pages on certain third-party platforms, such as Facebook, Twitter and LinkedIn as applicable, and from there to "like" or "share" our content on those platforms. Use of Social Features may entail a third party's collection and/or use of your data. If you use Social Features or similar third-party services, information you post or otherwise make accessible may be publicly displayed by the third-party service you are using. Both Instalab and the third party may have access to information about you and your use of both the Websites and the third-party service.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            THIRD PARTY WEBSITES AND LINKS
          </Text>
          <Paragraph className="privacy-paragraph">
            Our Websites may contain links to other online platforms operated by third parties. We do not control such other online platforms and are not responsible for their content, their privacy policies, or their use of your information. Information you provide on public or semi-public venues, including information you share on third-party social networking platforms (such as Facebook, LinkedIn or Twitter) may also be viewable by other users of the Websites and/or users of those third-party online platforms without limitation as to its use by us or by a third party. Our inclusion of such links does not, by itself, imply any endorsement of the content on such platforms or of their owners or operators except as disclosed on the Websites. We expressly disclaim any and all liability for the actions of third parties, including but without limitation to actions relating to the use and/or disclosure of Personal Information by third parties. Any information submitted by you directly to these third parties is subject to that third party's privacy policy.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            COOKIES AND INFORMATION USED FOR ADVERTISING
          </Text>
          <Paragraph className="privacy-paragraph">
            As discussed above, on our Websites, we may collect and disclose Personal Information about your online activities for use in providing you with advertising about products and services tailored to your individual interests. This section of our Privacy Policy provides details and explains how to exercise your choices. You may see certain ads on other websites because we participate in advertising networks. Ad networks allow us to target our messaging to users through demographic, interest-based and contextual means. These networks track your online activities over time by collecting information through automated means, including through the use of cookies, web server logs, and web beacons (also known as pixels). The networks use this information to show you advertisements that may be tailored to your individual interests. The information our ad networks may collect includes information about your visits to websites that participate in the relevant advertising networks, such as the pages or advertisements you view and the actions you take on the websites. This data collection takes place both on our Websites and on third-party websites that participate in the ad networks. This process also helps us track the effectiveness of our marketing efforts.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            Each type of web browser offers ways to restrict and delete cookies. For more information on how to manage cookies visit the appropriate link below.
          </Paragraph>
          
          <ul>
            <li>
              <a onClick={() => window.open('_blank', 'https://support.microsoft.com/en-us/windows/internet-explorer-help-23360e49-9cd3-4dda-ba52-705336cc0de2')}>
                Microsoft Internet Explorer
              </a>
            </li>
            <li>
              <a onClick={() => window.open('_blank', 'https://www.microsoft.com/en-us/search?q=cookies+microsoft+edge')}>
                Microsoft Edge
              </a>
            </li>
            <li>
              <a onClick={() => window.open('_blank', 'https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer')}>
                Mozilla Firefox
              </a>
            </li>
            <li>
              <a onClick={() => window.open('_blank', 'https://support.google.com/chrome/answer/95647')}>
                Google Chrome
              </a>
            </li>
            <li>
              <a onClick={() => window.open('_blank', 'https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac')}>
                Apple Safari
              </a>
            </li>
          </ul>

          <Paragraph className="privacy-paragraph">
            You may disable or delete browser cookies through your browser settings. Cookies generally are easy to disable or delete, but the method varies between browsers. If you disable or delete cookies, or if you are running third-party software that intercepts or deletes cookies, please note that some parts of our Websites may not work properly.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            You can opt out of third parties collecting your Personal Information for targeted advertising purposes in the United States by visiting the <a onClick={() => window.open('_blank', 'https://optout.networkadvertising.org/?c=1')}>National Advertising Initiative's (NAI) opt-out page</a> and the <a onClick={() => window.open('_blank', 'https://optout.aboutads.info/?lang=EN&c=2')}>Digital Advertising Alliance's (DAA) opt-out page</a>.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            Your browser settings also may allow you to transmit a "Do Not Track" signal when you visit various websites. Some of the Websites may not be configured to respond to "Do Not Track" signals received from browsers. To learn more about "Do Not Track" signals, you can visit here.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            If you have further questions regarding the specific information about you that we process or retain, as well as your choices regarding our collection and use practices, please contact us using the information listed below.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            CHILDREN'S PRIVACY
          </Text>
          <Paragraph className="privacy-paragraph">
            Our Websites are not intended for children under 16 years of age, and we do not knowingly collect or sell Personal Information from children under 16. If you are under 16, do not use or provide any information on these Websites or through any of its features. If we learn we have collected or received Personal Information from a child under 16 without verification of parental consent, we will delete it. If you are the parent or guardian of a child under 16 years of age whom you believe might have provided us with their Personal Information, you may contact us using the below information to request that it be deleted.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            SECURITY OF PERSONAL INFORMATION
          </Text>
          <Paragraph className="privacy-paragraph">
            We take steps to secure Personal Information through administrative, technical, and physical safeguards designed to protect against the risk of accidental, unlawful, or unauthorized destruction, loss, alteration, access, disclosure, or use. Unfortunately, we cannot guarantee the security of information transmitted through the Internet, and where we have given you (or where you have chosen) a password, you are responsible for keeping this password confidential.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            RETENTION OF PERSONAL INFORMATION
          </Text>
          <Paragraph className="privacy-paragraph">
            We generally retain records only as long as necessary and as required for our business operations, for archival purposes, and/or to satisfy legal requirements. When determining the appropriate retention period for Personal Information, we take into account various criteria, such as the amount, nature, and sensitivity of the Personal Information; potential risk of harm from unauthorized use or disclosure; purposes for which we process your Personal Information; whether we can achieve those purposes through other means; and business operations and legal requirements. Because we maintain our Websites to protect from accidental or malicious loss and destruction, residual copies of your Personal Information may be retained in our backup and archival systems for a limited period of time, after which the information will be automatically deleted or put beyond use where deletion is not possible.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            INTERNATIONAL USERS
          </Text>
          <Paragraph className="privacy-paragraph">
            The Websites are controlled and operated by us from the United States and are not intended to subject us to the laws or jurisdiction of any state, country or territory other than that of the United States. Any information you provide to us through use of our Websites may be stored and processed, transferred between and accessed from the United States and other countries that may not guarantee the same level of protection of personal data as the one in which you reside. However, we will handle your Personal Information in accordance with this Privacy Policy regardless of where your Personal Information is stored/accessed.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            NOTICE TO CALIFORNIA RESIDENTS
          </Text>
          <Paragraph className="privacy-paragraph">
            If you are a California resident, certain Personal Information that we collect about you is subject to the California Consumer Privacy Act (CCPA).
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            Please note that the CCPA does not apply to, among other things,
          </Paragraph>
          <ul>
            <li>
              Information that is lawfully made available from federal, state, or local government records;
            </li>
            <li>
              Consumer information that is deidentified or aggregated;
            </li>
            <li>
              protected health information that is collected by a covered entity or business associate governed by HIPAA or
            </li>
            <li>
              Medical information maintained by a provider of health care governed the Confidentiality of Medical Information Act (CMIA)
            </li>
          </ul>
          <Text className="privacy-title">
            Collection of Personal Information – Currently and in the Preceding 12 Months
          </Text>
          <Paragraph className="privacy-paragraph">
            We collect Personal Information as defined by the CCPA, which is information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household. Personal Information does not include de-identified or aggregate information; publicly available information that is lawfully made available from federal, state, or local government records; and information covered by certain sector-specific privacy laws.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            Instalab currently collects and has collected the following categories of Personal Information about consumers in the 12 months preceding the date this Privacy Policy was last modified:
          </Paragraph>
          <ul>
            <li>
              Identifiers, Internet Protocol (IP) address, online identifier, device identifier.
            </li>
          </ul>
          
          <Paragraph className="privacy-paragraph">
            We may collect your name, email address, date of birth, phone number, and address only if you provided it to us via a form on the website or during an incomplete or failed registration.
          </Paragraph>
          <ul>
            <li>
              Commercial information, business contact information if you have provided it to us via a form (such to register for services, obtain more information, request a demo, request an RFP) on the website, including name, company name, physical address, email address, telephone, and fax number; information on actions taken on our Websites, which may include information about Websites considered and information about preferences and behavior that we collect on our Websites or purchase from third parties in order to target consumers for digital advertisements or to personalize content we deliver on our Websites.
            </li>
            <li>
              Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer's interaction with the Websites, applications, or advertisements.
            </li>
          </ul>
          
          <Paragraph className="privacy-paragraph">
            Instalab collects Personal Information from the following categories of sources:
          </Paragraph>
          <ul>
            <li>Directly and indirectly from you, including through your interaction with the Websites</li>
            <li>Social media platforms</li>
            <li>Third party partners such as analytics or marketing providers</li>
            <li>Automatically through tracking technologies.</li>
          </ul>
          <Paragraph className="privacy-paragraph">
            See <a onClick={() => window.open('_blank', 'https://teladochealth.com/privacy-policy/#2.-how-we-collect-personal-information')}>Section 2</a> for additional detail.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            We may use your Personal Information for the following purposes:
          </Paragraph>
          <ul>
            <li>Operate, maintain, supervise, administer, and enhance the Websites, including monitoring and analyzing the effectiveness of content on the Websites, aggregate site usage data, and other usage of the Websites such as checking your eligibility and assisting you in completing the registration process.</li>
            <li>Provide you with a tailored and user-friendly experience as you navigate our Websites.</li>
            <li>Promote and market our Websites to you.</li>
            <li>To complete the activity you specifically asked for, e.g., register on a Secure Platform, obtain more information, request a demo, or request an RFP.</li>
            <li>Conduct research on users’ demographics, interests, and behavior based upon information provided during use of our Websites.</li>
            <li>Anonymize and aggregate information for analytics and reporting.</li>
            <li>Respond to law enforcement requests and court orders and legal process and carry out our legal and contractual obligations and enforce our rights.</li>
            <li>Authenticate use, detect potential fraudulent use, and otherwise maintain the security of the Websites and safety of users.</li>
            <li>Develop, test, improve, and demonstrate the Websites.</li>
            <li>Any other purpose with your consent.</li>
          </ul>
          <Text className="privacy-title">
            Requests to Delete
          </Text>
          <Paragraph className="privacy-paragraph">
            You have the right to request that we delete Personal Information about you that we have collected, subject to certain exceptions.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            Instructions for submitting a verifiable request are provided below.
          </Paragraph>
          <Text className="privacy-title">
            Requests to not sell your Personal Information
          </Text>
          <Paragraph className="privacy-paragraph">
            Instalab does not sell your Personal Information.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            During the past 12 months, we may have engaged in delivering online advertising that was tailored to your interests through the use of analytics and/or targeting cookies. We did not disclose data that would identify you by name, address, or phone number. Going forward, and before using analytics and/or targeting cookies, the Websites will deploy a banner seeking your consent, or opt-in, to the use of such cookies.
          </Paragraph>
          <Text className="privacy-title">
            How to Exercise Your Rights
          </Text>
          <Paragraph className="privacy-paragraph">
            To make Requests to Know or Access and Requests to Delete, please send a written request via email to <a className="privacy-link" onClick={() => window.open(`mailto:privacy@instalab.com`)}>privacy@instalab.com</a>
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            As required under applicable law, we must take steps to verify your request before we can provide Personal Information to you, delete Personal Information, or otherwise process your request. To verify your request, we may require you to provide your name, physical address, email address, contact information, and information about your account or previous transactions with us. If you have only visited our public Websites and did not provide any Personal Information to us via a web form or through the registration process, we will need you to provide us with your IP address or device ID in order for us to determine if we have that information. We will not be able to determine if we have your IP address or device ID from your name, physical address, email address, contact information, or account information.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            We will further verify and respond to your request consistent with applicable law, taking into account the type and sensitivity of the Personal Information subject to the request. We may need to request additional Personal Information from you, such as your date of birth or government identifier, in order to protect against fraudulent requests.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            We will deliver Personal Information that we are required by law to disclose to you in the manner required by law within 45 days after receipt of a verifiable request, unless we notify you that we require additional time to respond, in which case we will respond within such additional period of time required by law. We may deliver the Personal Information to you electronically or by mail at your option. If electronically, then we will deliver the information in a portable and, to the extent technically feasible, in a readily useable format that allows you to transmit the information from one entity to another without hindrance.
          </Paragraph>
          <Paragraph className="privacy-paragraph">
            Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your Personal Information. You may designate an authorized agent to request any of the above rights on your behalf. You may make such a designation by providing the agent with written permission, signed by you, to act on your behalf. Your agent may contact us by the information provided in the "How to Contact Us" section below to make a request on your behalf. Even if you choose to use an agent, we may, as permitted by law, require verification of the agent's authorization to act on your behalf, require you to confirm you have authorized the agent to act on your behalf, or require you to verify your own identity.
          </Paragraph>
          <Text className="privacy-title">
            Right to Nondiscrimination
          </Text>
          <Paragraph className="privacy-paragraph">
            You have the right to be free from discriminatory treatment for exercising the privacy rights conferred by the CCPA, including not being: denied goods or services; charged different prices or rates for goods or services, including through the use of discounts or other benefits or imposing penalties; provided a different level or quality of goods or services; or suggested that you will receive a different price or rate for goods or services or a different level or quality of goods or services.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            UPDATING THIS PRIVACY POLICY
          </Text>
          <Paragraph className="privacy-paragraph">
            This Privacy Policy may be updated periodically to reflect changes in our privacy practices. It is your responsibility to review the Privacy Policy from time to time to view any such changes.
          </Paragraph>
        </li>

        <li>
          <Text className="privacy-section-title">
            HOW TO CONTACT US
          </Text>
          <Paragraph className="privacy-paragraph">
            Should you have any questions about our privacy practices or this Privacy Policy, please email us at <a className="privacy-link" onClick={() => window.open(`mailto:privacy@instalab.com`)}>privacy@instalab.com</a>.
          </Paragraph>
        </li>
      </ol>      
    </div>

    <PgFooter />
  </>
}