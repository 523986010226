import { useState, useEffect } from "react";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { ReportSection, LowercaseTest } from "../../../enums/index.enum";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

import "./hyponatremiaAdvice.scss";

const converter = require("number-to-words");
const { Paragraph } = Typography;

export const HyponatremiaAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [hyponatremiaTests, setHyponatremiaTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setHyponatremiaTests(getHyponatremiaTests());
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, tests, hyponatremiaTests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  const getHyponatremiaTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(report, ReportSection.HYPONATREMIA_ADVICE, tests);
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !tests || !hyponatremiaTests) return null;

    return  <>         
      {hyponatremiaTests.length === 1 
        ? <>Your <span>{LowercaseTest[hyponatremiaTests[0].code]}</span> level indicates</>
        : <>These{" "}<span>{converter.toWords(hyponatremiaTests.length)} biomarkers</span>{" "}indicate</>
      }{" "}possible <span>hyponatremia</span>, which can lead to neurologial issues if not treated.
    </>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report) return;

    return <>
      Sodium is essential for maintaining the balance of fluids in and around cells, as well as in the proper functioning of your nerves and muscles. 
      <br/><br/>
      When sodium levels in the blood get too low - a condition known as hyponatremia - it can prompt water to move into cells, causing them to swell. This swelling can be especially detrimental in the brain, where it may result in a range of symptoms. These can vary from mild, including nausea, headache, confusion, and fatigue, to severe, such as vomiting, muscle spasms or cramps, and seizures.

    </>

  }

  return (
    hyponatremiaTests && (
      <div className="low-wbc-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{description}</Paragraph>
        <TestView 
          report={report} 
          tests={hyponatremiaTests}
          results={results}
          reports={reports}
          user={user}
        />
        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};