import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Typography, Button, message } from 'antd'
import "./cardModal.scss"
import Color from '../../colors.scss'
import { loadStripe } from '@stripe/stripe-js/pure'
import { CardElement, Elements, ElementsConsumer,  } from '@stripe/react-stripe-js';
import { addCard } from '../../services/card.service'
import { listCharges } from '../../services/charge.service'
import ChargeStatus from '../../enums/chargeStatus.enum'
import { flow } from 'lodash'

const { Item } = Form
const { Paragraph } = Typography

export const CardModal = (props) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({stripe, elements}) => (
          <CardModalChild 
            stripe={stripe}
            elements={elements}
            {...props}
          />
        )}
      </ElementsConsumer>
    </Elements>
  )
}

export const CardModalChild = ({ open, setOpen, onSuccess, elements, stripe, select, populate, flow }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [overdue, setOverdue] = useState()
  const [reset, setReset] = useState(false);

  useEffect(() => {
    getOverdue()
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const getOverdue = async () => {
    const charges = await listCharges({
      select,
      populate,
    })
    setOverdue(charges.reduce((acc, { amount, status }) => {
      if (status === ChargeStatus.OVERDUE) {
        acc += amount
      }
      return acc
    }, 0))
  }

  const resetCardElement = () => {
    setReset((prevReset) => !prevReset);
  };

  const getCardParams = async () => {
    const cardElement = elements.getElement(CardElement)

    if (!cardElement) return {}

    const stripeResponse = await stripe.createSource(cardElement, { type: 'card' })
    const { error, source } = stripeResponse

    if (error) {
      message.error(error.message)
      throw new Error(error.message)
    }

    const {
      exp_month: expMonth,
      exp_year: expYear,
      country,
      last4,
      brand
    } = source.card

    return {
      stripeId: source.id,
      expMonth,
      expYear,
      country,
      last4,
      brand,
    }
  }

  const onOk = async () => {
    setIsSubmitting(true);
    try {
      const fields = await getCardParams()
      const card = await addCard({
        fields,
        flow: flow,
        select: '_id last4 brand',
        populate: []
      })
      setOpen(false)
      onSuccess(card)
      resetCardElement()
      form.resetFields()
      message.info('Card saved')
    } catch (err) {
      console.log('err', err)
      const error = err?.response?.data?.err || 'Unable to save card'
      message.error(error)
    }
    setIsSubmitting(false);
  }

  return (
    <Modal 
      open={open} 
      onCancel={onCancel}
      onOk={onOk}
      okText='Save'
      footer={null}
      className="card-modal"
    >
      <h2>Add Card</h2>
      {overdue > 0 && (
        <Paragraph className="overdue">
          Updating your payment method will pay for your outstanding balance: <span className="overdue-amount">${overdue.toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
        </Paragraph>
      )}
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
      >
        <div key={reset}>
          <Item
            className="pay-item"
          >
              <CardElement 
                options={{
                  disableLink: true,
                  style: {
                    base: {
                      iconColor: Color.primary_text,
                      color: Color.primary_text,
                      fontFamily: 'Lato, sans-serif',
                      margin: 30,
                      fontSize: '18px',
                      fontWeight: '400',
                      fontSmoothing: 'antialiased',
                      ':-webkit-autofill': {
                        color: Color.secondary_text,
                      },
                      '::placeholder': {
                        color: Color.secondary_text,
                      },
                    },
                    invalid: {
                      iconColor: Color.error,
                      color: Color.error,
                    },
                  }
                }}
              />
          </Item>
        </div>

        <Button
          className="submit-btn"
          type='primary'
          onClick={onOk}
          loading={isSubmitting}
        >
          Save Payment Method
        </Button>
      </Form>
    </Modal>
  )
}